import React, { useEffect, useState } from "react";
import { Progress } from "reactstrap";
import { Link } from "react-router-dom";
import sidikJari from "../../../../assets/images/FutureLink/sidikJari.png";
import dWhorl from "../../../../assets/images/FutureLink/Whorl.png";
import axios from "axios";

import { decodeToken } from "react-jwt";

const PolaSidikJari = (haisayang) => {
  if (haisayang.history.location.state === undefined) {
    window.location.href = "/report";
  }

  const data = haisayang.history.location.state.data;
  //   console.log(haisayang.history.location.state.data);

  const packages = haisayang.history.location.state.package_id;
  // console.log(haisayang.history.location.state.package_id)

  const code = sessionStorage.getItem("user");
  const decode = decodeToken(code);
  // console.log(decode.userId)

  const fingerType = [
    {
      name: "Tidak Diketahui",
      value: "nothing",
    },
    {
      name: "Whorl Target",
      value: "W_centric",
      nick: "WT",
    },
    {
      name: "Whorl Spiral",
      value: "W_spiral",
      nick: "WS",
    },
    {
      name: "Whorl Elongated Centric",
      value: "W_elongated_centric",
      nick: "WTE",
    },
    {
      name: "Whorl Elongated Spiral",
      value: "W_elongated_spiral",
      nick: "WSE",
    },
    {
      name: "Whorl Imploding",
      value: "W_imploding",
      nick: "WI",
    },
    {
      name: "Whorl Composite",
      value: "W_composite",
      nick: "WC",
    },
    {
      name: "Whorl Target Ulnar Peacock Eye",
      value: "W_peacock_centric_ulnar",
      nick: "WTP",
    },
    {
      name: "Whorl Spiral Ulnar Peacock Eye",
      value: "W_peacock_spiral_ulnar",
      nick: "WSP",
    },
    {
      name: "Whorl Target Radial Peacock Eye",
      value: "W_peacock_centric_radial",
      nick: "RWTP",
    },
    {
      name: "Whorl Spiral Radial Peacock Eye",
      value: "W_peacock_spiral_radial",
      nick: "RWSP",
    },
    {
      name: "Whorl Double Loop",
      value: "L_double",
      nick: "WDL",
    },
    {
      name: "Ulnar Loop",
      value: "L_ulnar",
      nick: "L",
    },
    {
      name: "Radial Loop",
      value: "L_radial",
      nick: "RL",
    },
    {
      name: "Ulnar Falling Loop",
      value: "L_fallen_ulnar",
      nick: "LW",
    },
    {
      name: "Radial Falling Loop",
      value: "L_fallen_radial",
      nick: "RLW",
    },
    {
      name: "Plain Arch",
      value: "A_simple",
      nick: "AS",
    },
    {
      name: "Tented Arch",
      value: "A_tented",
      nick: "TA",
    },
    {
      name: "Tented Arch with Ulnar Loop",
      value: "A_w_L_ulnar",
      nick: "LAS",
    },
    {
      name: "Tented Arch with Radial Loop",
      value: "A_w_L_radial",
      nick: "RLAS",
    },
    {
      name: "Accidental Whorl",
      value: "accidental",
      nick: "WX",
    },
  ];

  const [l1, setL1] = useState("");
  const [l2, setL2] = useState("");
  const [l3, setL3] = useState("");
  const [l4, setL4] = useState("");
  const [l5, setL5] = useState("");
  const [r1, setR1] = useState("");
  const [r2, setR2] = useState("");
  const [r3, setR3] = useState("");
  const [r4, setR4] = useState("");
  const [r5, setR5] = useState("");
  const [l1Shape, setL1Shape] = useState("");
  const [l2Shape, setL2Shape] = useState("");
  const [l3Shape, setL3Shape] = useState("");
  const [l4Shape, setL4Shape] = useState("");
  const [l5Shape, setL5Shape] = useState("");
  const [r1Shape, setR1Shape] = useState("");
  const [r2Shape, setR2Shape] = useState("");
  const [r3Shape, setR3Shape] = useState("");
  const [r4Shape, setR4Shape] = useState("");
  const [r5Shape, setR5Shape] = useState("");

  useEffect(() => {
    getAllFingerprint();
  }, []);

  const getAllFingerprint = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-by-id?userId=${decode.userId}`,
      headers: {
        Authorization: `Bearer ${code}`,
      },
    };

    axios(config)
      .then((response) => {
        // console.log(response.results[0]);

        getImage(response.results[0].l1, "l1");
        getImage(response.results[0].l2, "l2");
        getImage(response.results[0].l3, "l3");
        getImage(response.results[0].l4, "l4");
        getImage(response.results[0].l5, "l5");
        getImage(response.results[0].r1, "r1");
        getImage(response.results[0].r2, "r2");
        getImage(response.results[0].r3, "r3");
        getImage(response.results[0].r4, "r4");
        getImage(response.results[0].r5, "r5");

        setL1Shape(
          fingerType[
            fingerType
              .map((item) => item.value)
              .indexOf(response.results[0].l1_shape)
          ] === undefined
            ? "XX"
            : fingerType[
                fingerType
                  .map((item) => item.value)
                  .indexOf(response.results[0].l1_shape)
              ].nick
        );
        setL2Shape(
          fingerType[
            fingerType
              .map((item) => item.value)
              .indexOf(response.results[0].l2_shape)
          ] === undefined
            ? "XX"
            : fingerType[
                fingerType
                  .map((item) => item.value)
                  .indexOf(response.results[0].l2_shape)
              ].nick
        );
        setL3Shape(
          fingerType[
            fingerType
              .map((item) => item.value)
              .indexOf(response.results[0].l3_shape)
          ] === undefined
            ? "XX"
            : fingerType[
                fingerType
                  .map((item) => item.value)
                  .indexOf(response.results[0].l3_shape)
              ].nick
        );
        setL4Shape(
          fingerType[
            fingerType
              .map((item) => item.value)
              .indexOf(response.results[0].l4_shape)
          ] === undefined
            ? "XX"
            : fingerType[
                fingerType
                  .map((item) => item.value)
                  .indexOf(response.results[0].l4_shape)
              ].nick
        );
        setL5Shape(
          fingerType[
            fingerType
              .map((item) => item.value)
              .indexOf(response.results[0].l5_shape)
          ] === undefined
            ? "XX"
            : fingerType[
                fingerType
                  .map((item) => item.value)
                  .indexOf(response.results[0].l5_shape)
              ].nick
        );
        setR1Shape(
          fingerType[
            fingerType
              .map((item) => item.value)
              .indexOf(response.results[0].r1_shape)
          ] === undefined
            ? "XX"
            : fingerType[
                fingerType
                  .map((item) => item.value)
                  .indexOf(response.results[0].r1_shape)
              ].nick
        );
        setR2Shape(
          fingerType[
            fingerType
              .map((item) => item.value)
              .indexOf(response.results[0].r2_shape)
          ] === undefined
            ? "XX"
            : fingerType[
                fingerType
                  .map((item) => item.value)
                  .indexOf(response.results[0].r2_shape)
              ].nick
        );
        setR3Shape(
          fingerType[
            fingerType
              .map((item) => item.value)
              .indexOf(response.results[0].r3_shape)
          ] === undefined
            ? "XX"
            : fingerType[
                fingerType
                  .map((item) => item.value)
                  .indexOf(response.results[0].r3_shape)
              ].nick
        );
        setR4Shape(
          fingerType[
            fingerType
              .map((item) => item.value)
              .indexOf(response.results[0].r4_shape)
          ] === undefined
            ? "XX"
            : fingerType[
                fingerType
                  .map((item) => item.value)
                  .indexOf(response.results[0].r4_shape)
              ].nick
        );
        setR5Shape(
          fingerType[
            fingerType
              .map((item) => item.value)
              .indexOf(response.results[0].r5_shape)
          ] === undefined
            ? "XX"
            : fingerType[
                fingerType
                  .map((item) => item.value)
                  .indexOf(response.results[0].r5_shape)
              ].nick
        );
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const getImage = (imageName, index) => {
    if (index === "l1") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setL1(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "l2") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setL2(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "l3") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setL3(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "l4") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setL4(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "l5") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setL5(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "r1") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setR1(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "r2") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setR2(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "r3") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setR3(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "r4") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setR4(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "r5") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setR5(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <p
            style={{
              margin: "0px",
              backgroundColor: "#1F599C",
              padding: "8px 80px",
              fontSize: "18px",
              fontWeight: "900",
              color: "white",
              borderRadius: "10px",
              width: "max-content",
            }}
          >
            Pola Sidik Jari
          </p>
        </div>

        <div className="polasidikjariList">
          <Link
            style={{ width: "max-content" }}
            className="btn buttonVersFutureLinkBorderGrey"
            to="/report"
          >
            Back
          </Link>

          <div className="polasidikjariItem">
            <div className="polasidikjariBoxImg">
              <p style={{margin: "0px", fontSize: "16px", fontWeight: "700", position: "absolute"}}>
                R1
              </p>
              <img src={r1} className="polasidikjariImg" />
            </div>
            <Progress className="progress-xl animated-progess progress-label"
              value={ data.intrapersonal < 3 ? data.intrapersonal * 8 : data.intrapersonal < 5 ? data.intrapersonal * 7 : data.intrapersonal < 9 ? data.intrapersonal * 6 : data.intrapersonal * 5.5 }
              color={ data.intrapersonal < 8 ? "red" : data.intrapersonal > 12 ? "green" : "yellow" }
            >
              <p style={{ margin: "0px", color: "white", fontSize: "16px", fontWeight: "700"}}>
                {r1Shape}
              </p>
              <div className="label">{data.intrapersonal}%</div>
            </Progress>
          </div>

          <div className="polasidikjariItem">
            <div className="polasidikjariBoxImg">
              <p
                style={{
                  margin: "0px",
                  fontSize: "16px",
                  fontWeight: "700",
                  position: "absolute",
                }}
              >
                L1
              </p>
              <img src={l1} className="polasidikjariImg" />
            </div>
            <Progress
              className="progress-xl animated-progess progress-label"
              value={
                data.interpersonal < 4
                  ? data.interpersonal * 8
                  : data.interpersonal < 6
                  ? data.interpersonal * 7
                  : data.interpersonal < 10
                  ? data.interpersonal * 6
                  : data.interpersonal * 5.5
              }
              color={
                data.interpersonal < 8
                  ? "red"
                  : data.interpersonal > 12
                  ? "green"
                  : "yellow"
              }
            >
              <p
                style={{
                  margin: "0px",
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                {l1Shape}
              </p>
              <div className="label">{data.interpersonal}%</div>
            </Progress>
          </div>

          <div className="polasidikjariItem">
            <div className="polasidikjariBoxImg">
              <p
                style={{
                  margin: "0px",
                  fontSize: "16px",
                  fontWeight: "700",
                  position: "absolute",
                }}
              >
                R2
              </p>
              <img src={r2} className="polasidikjariImg" />
            </div>
            <Progress
              className="progress-xl animated-progess progress-label"
              value={
                data.logical < 4
                  ? data.logical * 8
                  : data.logical < 6
                  ? data.logical * 7
                  : data.logical < 10
                  ? data.logical * 6
                  : data.logical * 5.5
              }
              color={
                data.logical < 8
                  ? "red"
                  : data.logical > 12
                  ? "green"
                  : "yellow"
              }
            >
              <p
                style={{
                  margin: "0px",
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                {r2Shape}
              </p>
              <div className="label">{data.logical}%</div>
            </Progress>
          </div>

          <div className="polasidikjariItem">
            <div className="polasidikjariBoxImg">
              <p
                style={{
                  margin: "0px",
                  fontSize: "16px",
                  fontWeight: "700",
                  position: "absolute",
                }}
              >
                L2
              </p>
              <img src={l2} className="polasidikjariImg" />
            </div>
            <Progress
              className="progress-xl animated-progess progress-label"
              value={
                data.imagination < 4
                  ? data.imagination * 8
                  : data.imagination < 6
                  ? data.imagination * 7
                  : data.imagination < 10
                  ? data.imagination * 6
                  : data.imagination * 5.5
              }
              color={
                data.imagination < 8
                  ? "red"
                  : data.imagination > 12
                  ? "green"
                  : "yellow"
              }
            >
              <p
                style={{
                  margin: "0px",
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                {l2Shape}
              </p>
              <div className="label">{data.imagination}%</div>
            </Progress>
          </div>

          <div className="polasidikjariItem">
            <div className="polasidikjariBoxImg">
              <p
                style={{
                  margin: "0px",
                  fontSize: "16px",
                  fontWeight: "700",
                  position: "absolute",
                }}
              >
                R3
              </p>
              <img src={r3} className="polasidikjariImg" />
            </div>
            <Progress
              className="progress-xl animated-progess progress-label"
              value={
                data.fine_motors < 4
                  ? data.fine_motors * 8
                  : data.fine_motors < 6
                  ? data.fine_motors * 7
                  : data.fine_motors < 10
                  ? data.fine_motors * 6
                  : data.fine_motors * 5.5
              }
              color={
                data.fine_motors < 8
                  ? "red"
                  : data.fine_motors > 12
                  ? "green"
                  : "yellow"
              }
            >
              <p
                style={{
                  margin: "0px",
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                {r3Shape}
              </p>
              <div className="label">{data.fine_motors}%</div>
            </Progress>
          </div>

          <div className="polasidikjariItem">
            <div className="polasidikjariBoxImg">
              <p
                style={{
                  margin: "0px",
                  fontSize: "16px",
                  fontWeight: "700",
                  position: "absolute",
                }}
              >
                L3
              </p>
              <img src={l3} className="polasidikjariImg" />
            </div>
            <Progress
              className="progress-xl animated-progess progress-label"
              value={
                data.gross_motors < 4
                  ? data.gross_motors * 8
                  : data.gross_motors < 6
                  ? data.gross_motors * 7
                  : data.gross_motors < 10
                  ? data.gross_motors * 6
                  : data.gross_motors * 5.5
              }
              color={
                data.gross_motors < 8
                  ? "red"
                  : data.gross_motors > 12
                  ? "green"
                  : "yellow"
              }
            >
              <p
                style={{
                  margin: "0px",
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                {l3Shape}
              </p>
              <div className="label">{data.gross_motors}%</div>
            </Progress>
          </div>

          <div className="polasidikjariItem">
            <div className="polasidikjariBoxImg">
              <p
                style={{
                  margin: "0px",
                  fontSize: "16px",
                  fontWeight: "700",
                  position: "absolute",
                }}
              >
                R4
              </p>
              <img src={r4} className="polasidikjariImg" />
            </div>
            <Progress
              className="progress-xl animated-progess progress-label"
              value={
                data.lingustic < 4
                  ? data.lingustic * 8
                  : data.lingustic < 6
                  ? data.lingustic * 7
                  : data.lingustic < 10
                  ? data.lingustic * 6
                  : data.lingustic * 5.5
              }
              color={
                data.lingustic < 8
                  ? "red"
                  : data.lingustic > 12
                  ? "green"
                  : "yellow"
              }
            >
              <p
                style={{
                  margin: "0px",
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                {r4Shape}
              </p>
              <div className="label">{data.lingustic}%</div>
            </Progress>
          </div>

          <div className="polasidikjariItem">
            <div className="polasidikjariBoxImg">
              <p
                style={{
                  margin: "0px",
                  fontSize: "16px",
                  fontWeight: "700",
                  position: "absolute",
                }}
              >
                L4
              </p>
              <img src={l4} className="polasidikjariImg" />
            </div>
            <Progress
              className="progress-xl animated-progess progress-label"
              value={
                data.musical < 4
                  ? data.musical * 8
                  : data.musical < 6
                  ? data.musical * 7
                  : data.musical < 10
                  ? data.musical * 6
                  : data.musical * 5.5
              }
              color={
                data.musical < 8
                  ? "red"
                  : data.musical > 12
                  ? "green"
                  : "yellow"
              }
            >
              <p
                style={{
                  margin: "0px",
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                {l4Shape}
              </p>
              <div className="label">{data.musical}%</div>
            </Progress>
          </div>

          <div className="polasidikjariItem">
            <div className="polasidikjariBoxImg">
              <p
                style={{
                  margin: "0px",
                  fontSize: "16px",
                  fontWeight: "700",
                  position: "absolute",
                }}
              >
                R5
              </p>
              <img src={r5} className="polasidikjariImg" />
            </div>
            <Progress
              className="progress-xl animated-progess progress-label"
              value={
                data.naturalist < 4
                  ? data.naturalist * 8
                  : data.naturalist < 6
                  ? data.naturalist * 7
                  : data.naturalist < 10
                  ? data.naturalist * 6
                  : data.naturalist * 5.5
              }
              color={
                data.naturalist < 8
                  ? "red"
                  : data.naturalist > 12
                  ? "green"
                  : "yellow"
              }
            >
              <p
                style={{
                  margin: "0px",
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                {r5Shape}
              </p>
              <div className="label">{data.naturalist}%</div>
            </Progress>
          </div>

          <div className="polasidikjariItem">
            <div className="polasidikjariBoxImg">
              <p
                style={{
                  margin: "0px",
                  fontSize: "16px",
                  fontWeight: "700",
                  position: "absolute",
                }}
              >
                L5
              </p>
              <img src={l5} className="polasidikjariImg" />
            </div>
            <Progress
              className="progress-xl animated-progess progress-label"
              value={
                data.visual_spatial < 4
                  ? data.visual_spatial * 8
                  : data.visual_spatial < 6
                  ? data.visual_spatial * 7
                  : data.visual_spatial < 10
                  ? data.visual_spatial * 6
                  : data.visual_spatial * 5.5
              }
              color={
                data.visual_spatial < 8
                  ? "red"
                  : data.visual_spatial > 12
                  ? "green"
                  : "yellow"
              }
            >
              <p
                style={{
                  margin: "0px",
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                {l5Shape}
              </p>
              <div className="label">{data.visual_spatial}%</div>
            </Progress>
          </div>

          <div>
            <p style={{ fontSize: "16px" }}>Keterangan:</p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  backgroundColor: "#10B981",
                }}
              ></div>
              <p style={{ margin: "0px", fontSize: "16px" }}>
                Diatas rata-rata
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  backgroundColor: "#F59E0B",
                }}
              ></div>
              <p style={{ margin: "0px", fontSize: "16px" }}>Rata - rata</p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  backgroundColor: "#F43F5E",
                }}
              ></div>
              <p style={{ margin: "0px", fontSize: "16px" }}>
                Dibawah Rata - rata
              </p>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "20px",
          }}
        >
          <Link
            className="bottomButton buttonVersFutureLink"
            to={{
              pathname: "/report/distribusi-neuron",
              state: { data: data, package_id: packages },
            }}
          >
            Distribusi Neuron {">"}
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PolaSidikJari;

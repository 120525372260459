import React, { useState, useEffect } from "react";
import {Row, Col, Modal, ModalBody, Alert, Label, Input} from "reactstrap";
import axios from 'axios';
import Flatpickr from "react-flatpickr";
import Lottie from 'react-lottie';
import ReactInputVerificationCode from 'react-input-verification-code';
import { decodeToken } from "react-jwt";

import avatar1 from "../../../../assets/images/FutureLink/pp.png";
import animateLoading from "../../../../assets/images/FutureLink/loading.json"


const ProfileClient = () => {
    const [identity, setIdentity] = useState({app: [], date_birth: "", email: "", email_verified: 0, fingerprint: false, first_name: "", last_name: "", number_verified: 0, package_id: 0, password_exist: 1, phone: "", privilege_id: 51, profile_image: avatar1, sex: "", updated_at: "", user_id: 0})
    const [modalCodeVerify, setModalCodeVerify] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);

    // Fungsi nya buat Tampilan Error
    const [alert, setAlert] = useState(true);
    const [alertVerify, setAlertVerify] = useState(true);
    const [fade, setFade] = useState('fadeout');
    const [kontenError, setKontenError] = useState("Check Again");
    const appearAlert = (e) => {
        setKontenError(e);
        setAlert(false);

        setTimeout(() => {
            setFade('fade')
        }, 200)

        setTimeout(() => {
            setFade('fadeout');
        }, 4500)

        setTimeout(() => {
            setAlert(true);
            setKontenError("Check Again");
        }, 5000);
    }
    const appearAlertVerify = (e) => {
        setKontenError(e);
        setAlertVerify(false);

        setTimeout(() => {
            setFade('fade')
        }, 200)

        setTimeout(() => {
            setFade('fadeout');
        }, 4500)

        setTimeout(() => {
            setAlertVerify(true);
            setKontenError("Check Again");
        }, 5000);
    }

    const [email, setEmail] = useState("");
    const [first_name, setFirstName] = useState("");
    const [last_name, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [file, setFile] = useState([]);
    const [picture, setPicture] = useState("");
    const [codeVerify, setCodeVerify] = useState("");

    const code = sessionStorage.getItem('user');
    const decode = decodeToken(code);

    useEffect(() => {
        getUser();
    },[])

    const getUser = () => {

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-user-token`,
            headers: { 
                'Authorization': `Bearer ${code}`
            }
        };
            
        axios.request(config)
            .then((response) => {
                console.log(response.results);
                setIdentity(response.results);
                setEmail(response.results.email);
                setFirstName(response.results.first_name);
                setLastName(response.results.last_name);
                setPicture(response.results.profile_image === null ? avatar1 : response.results.profile_image);
                setPhone(response.results.phone);
            })
            .catch((error) => {
                // console.log(error);
            });

    }

    const getVerify = () => {
        if(email === null || email  === "") {
            appearAlert("Email tidak di temukan")
        } else {        
        let data = JSON.stringify({
            "email": email
        });
          
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/verify-email`,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
        };
          
        axios(config)
            .then((response) => {
                console.log(response);
                setModalCodeVerify(true);

            })
            .catch((error) => {
                appearAlertVerify("Wrong OTP")
                // console.log(error);
                // console.log(error.response);
            });

        }

    }

    const verify = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/email-verified?email=${email}&code=${codeVerify}`,
            headers: { 
              'Authorization': `Bearer ${code}`
            }
        };
          
        axios(config)
            .then((response) => {
                console.log(response);
                setModalCodeVerify(false)
                // setModalAlertSuccess(true)
                setTimeout(() => {
                    window.location.reload()
                    // setModalAlertSuccess(false)
                }, 4000)
            })
            .catch((error) => {
                appearAlertVerify("Wrong OTP")
            });
    }

    const updateProfile = () => {
        // console.log("Phone", phone, email)
        let check = true
        if(phone != null){
            check = phone.match(/[a-zA-Z]/) === null
        }

        if(first_name === "" || last_name === "" || email === "" || email === null) {
            appearAlert("Tidak Boleh Kosong")
        } else if (email.includes("@") === false || email.includes(".") === false) {
            appearAlert("Email tidak di temukan")
        } else if(check === false) {
            appearAlert("Masukan Nomor dengan benar")
        } else {

            if(identity.first_name == first_name && identity.last_name == last_name && identity.email == email && identity.phone == phone && file.length == 0) {
                console.log("Hei")
            } else {

                setModalLoading(true)

                let data = new FormData();
                if(identity.first_name !== first_name){
                    data.append('firstName', first_name)
                }
                if(identity.last_name !== last_name){
                    data.append('lastName', last_name);
                }
                if(identity.email !== email) {
                    data.append('email', email);
                }
                if(identity.phone !== phone && phone !== "" && check === true){
                    data.append('phone', phone);
                }
                if(file.length !== 0) {
                    data.append('profileImage', file)
                }
            
                let config = {
                    method: 'patch',
                    maxBodyLength: Infinity,
                    url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/update-user-token`,
                    headers: { 
                        'Authorization': `Bearer ${code}`, 
                    },
                    data : data
                };
            
                axios.request(config)
                    .then((response) => {
                    console.log(response);

                    let config = {
                        method: 'get',
                        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-user?userId=${decode.userId}`,
                        headers: { 
                            'Authorization': `Bearer ${code}`,
                        }
                    };
                        
                    axios(config)
                        .then((response) => {
                            // console.log(response)
                            setModalLoading(false)
                            sessionStorage.setItem("profile", JSON.stringify({fullname: response.results.first_name + " " + response.results.last_name, pp: response.results.profile_image === null ? 'https://ibb.co/C5D55zZ' : response.results.profile_image}))
                            window.location.reload()
                        })
                        .catch((error) => {
                            console.log(error);
                            setModalLoading(false)
                        });
                    })
                    .catch((error) => {
                        setModalLoading(false)
                        appearAlert("Check Again")
                    });
            }

        }
    
    }
            
    return(
    <>
        <Row className="corev2 repCen">
            <Col lg={12} className="cardProfile">
                    <Label htmlFor='photo' style={{margin: '0px', cursor: 'pointer', display: 'flex', justifyContent: 'center'}}>
                        {file === undefined || file.length === 0 ? (
                            <>
                            <img src={picture} style={{width: '128px', height: '128px', borderRadius: '100%', objectFit: 'cover'}} />
                            </>
                        ) : (
                            <>
                            <img src={URL.createObjectURL(file)} style={{width: '128px', height: '128px', borderRadius: '100%', objectFit: 'cover'}} />
                            </>
                        )}

                        <input type="file" id="photo" accept=".jpg , .jpeg , .png" onChange={(event) => {setFile(event.target.files[0])}} hidden/>
                    </Label>

                <div style={{marginTop: '24px'}}>
                    <Alert className={'alertRed ' + fade} hidden={alert}>{kontenError}</Alert>
                </div>
                <Row className="proLi">
                    <Col lg={4}>
                        <p>Nama Depan</p>
                        <input type="text" placeholder="Masukan Nama Depan" value={first_name} onChange={(e) => {setFirstName(e.target.value)}}></input>
                    </Col>
                    <Col lg={4}>
                        <p>Nama Belakang</p>
                        <input type="text" placeholder="Masukkan Nama Belakang" value={last_name} onChange={(e) => {setLastName(e.target.value)}}></input>
                    </Col>
                    <Col lg={4}>                        
                        <p>Nomor Ponsel</p>
                        <div className="ve">
                            <input type="number" placeholder="Masukkan Nomor" value={phone} onChange={(e) => {setPhone(e.target.value)}}></input>

                        </div>
                    </Col>
                    <Col lg={4}>
                        <p>Email</p>
                        <div className="ve">
                            <input type="text" placeholder="Masukkan Email" value={email} onChange={(e) => {setEmail(e.target.value)}}></input>
                            {identity.email_verified === 0 && (
                                <>
                                 <div className='verifikasi' onClick={() => {getVerify()}}>Verify Now</div>
                                </>
                            )}
                            {identity.email_verified  === 1 && (
                                <>
                                <div className='verifikasiValid'>Verified!</div>
                                </>
                            )}
                        </div>
                    </Col>
                </Row>
                <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '24px'}}>
                    <button className="buttonTest" onClick={() => {updateProfile()}}>Simpan</button>
                </div>

            </Col>
        </Row>
        <Modal isOpen={modalCodeVerify} toggle={() => {setModalCodeVerify(!modalCodeVerify)}} centered>
            <ModalBody className='modalCenter' style={{margin: '0px'}}>
                <div style={{padding: '40px 0px'}}>
                    <Alert className={'alertRed ' + fade} hidden={alertVerify}>{kontenError}</Alert>
                    <p style={{fontSize: '16px', fontWeight: '600', color: '#4B5563'}}>Enter your verification code</p>
                    <p style={{fontSize: '14px', fontWeight: '400', color: '#4B5563', marginBottom: '30px'}}>We send a verification code to <br/> {email}</p>
                    <ReactInputVerificationCode onChange={(event) => {setCodeVerify(event)}}/>
                    <button style={{marginTop: '30px', fontWeight: '900', fontSize: '16px'}} className='btn buttonVersFutureLink' onClick={() => {verify()}}> Verify Now </button>
                </div>
            </ModalBody>
        </Modal>
        <Modal isOpen={modalLoading} toggle={() => {setModalLoading(!modalLoading)}} centered>
            <ModalBody className='modalCenter' style={{margin: '0px'}}>
                <Lottie options={{loop: true, autoplay: true, animationData: animateLoading, rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} height={350} width={350} isClickToPauseDisabled={true} />
            </ModalBody>
        </Modal>
    </>

    )
}

export default ProfileClient
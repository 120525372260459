import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody, ModalFooter, Row, Alert } from 'reactstrap';

import axios from 'axios';

import deleteData from '../../../assets/images/FutureLink/deleteData.png';
import accessDenied from '../../../assets/images/FutureLink/accessDenied.png';

import Lottie from 'react-lottie';
import animateSuccess from '../../../assets/images/FutureLink/success.json';

// Import React FilePond
import { FilePond,  registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

const ModulPackage = () => {
    const [modalAccessDenied, setModalAccessDenied] = useState(false);
    const logOut = () => {
      sessionStorage.removeItem('user');
      window.location.href = '/login'
    }
    // document.title="Widgets | Velzon - React Admin & Dashboard Template";
    const code = sessionStorage.getItem('user');


    const [fade, setFade] = useState('fadeout');
    const [alert, setAlert] = useState(true);
    const [kontenError, setKontenError] = useState("Check Again");

    const [packages, setPackages] = useState([]);
    const [idPackages, setIdPackages] = useState("");

    const [name, setName] = useState("");
    const [price, setPrice] = useState("");
    const [detail, setDetail] = useState("");
    const [status, setStatus] = useState("");
    const [description, setDescription] = useState("");
    const [counselor, setCounselor] = useState("");
    const [foto, setFoto] = useState("");

    //modal
    const [modalAdd, setModalAdd] = useState(false);
    const [modalUpdate, setModalUpdate] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);

    const [modalAlertSuccess, setModalAlertSuccess] = useState(false);
    //end modal

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0,
        }).format(amount);
    };

    useEffect(() => {
        getAllPackages()
    },[])

    const getAllPackages = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-packages`,
            headers: { 
              'Authorization': `Bearer ${code}`
            }
        };
          
        axios.request(config)
            .then((response) => {
                // console.log(response);
                setPackages(response.results)
            })
            .catch((error) => {
                // console.log(error);

                if(error.response.data.statusCode === 403) {
                    setModalAccessDenied(true)
                }
            });
    }

    const deletePackages = () => {
        let data = JSON.stringify({
            "packageId": idPackages
        });
          
        let config = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/delete-package`,
            headers: { 
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${code}`
            },
            data : data
        };
          
        axios(config)
            .then((response) => {
                // console.log(response);
                getAllPackages();
                setModalDelete(false)
            })
            .catch((error) => {
                // console.log(error);

                if(error.response.data.statusCode === 403) {
                    setModalAccessDenied(true)
                }
            });
    }

    const updatePackages = () => {

        if(name === "" || price === "" || description === "" || status === "" || counselor === "") {

            setAlert(false);
            setKontenError("Field cannot be empty");
    
            setTimeout(() => {
                setFade('fade');
            }, 200)
    
            setTimeout(() => {
                setFade('fadeout');
            }, 4500)
    
            setTimeout(() => {
                setAlert(true);
                setKontenError("Check Again");
            }, 5000);

        } else {

            let data = new FormData();
            data.append('packageId', idPackages);
            data.append('name', name);
            data.append('price', price);
            data.append('description', description);
            data.append('status', status);
            if(foto !== "") {
                data.append('image', foto);
            }
            data.append('counselor', counselor);
              
            let config = {
                method: 'patch',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/update-package`,
                headers: { 
                  'Authorization': `Bearer ${code}`
                },
                data : data
            };
              
            axios.request(config)
                .then((response) => {
                // console.log(response);

                    getAllPackages()
                    setModalUpdate()
    
                    setModalAlertSuccess(true)
                    setTimeout(() => {
                        setModalAlertSuccess(false)
                    }, 4000)

                
                })
                .catch((error) => {
                // console.log(error);
                if(error.response.data.statusCode === 403) {
                    setModalAccessDenied(true)
                }
                });

        }

    }

    const addPackages = () => {

        if(idPackages === "" || name === "" || price === "" || description === "" || status === "" || foto === "" || counselor === "") {

            setAlert(false);
            setKontenError("Field cannot be empty");
    
            setTimeout(() => {
                setFade('fade');
            }, 200)
    
            setTimeout(() => {
                setFade('fadeout');
            }, 4500)
    
            setTimeout(() => {
                setAlert(true);
                setKontenError("Check Again");
            }, 5000);

        } else {

            let data = new FormData();
            data.append('id', idPackages);
            data.append('name', name);
            data.append('price', price);
            data.append('description', description);
            data.append('status', status);
            data.append('image', foto);
            data.append('counselor', counselor);
              
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/add-package`,
                headers: { 
                  'Authorization': `Bearer ${code}`
                },
                data : data
            };
              
            axios.request(config)
                .then((response) => {
                // console.log(response);

                    getAllPackages();
                    setModalAdd(false);
    
                    setModalAlertSuccess(true);
                    setTimeout(() => {
                        setModalAlertSuccess(false);
                    }, 4000)
                
              })
              .catch((error) => {
                // console.log(error);
                if(error.response.data.statusCode === 403) {
                    setModalAccessDenied(true)
                }

                setAlert(false);
        
                setTimeout(() => {
                    setFade('fade');
                }, 200)
        
                setTimeout(() => {
                    setFade('fadeout');
                }, 4500)
        
                setTimeout(() => {
                    setAlert(true);
                }, 5000);
              });

        }

    }

    return ( 
        <React.Fragment>
            <Container fluid>
            <div className="page-content">

            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader>
                            <h4 className="card-title mb-0">Package Table</h4>
                        </CardHeader>

                        <CardBody>
                            <div id="customerList">

                                <Row className="g-4 mb-3">
                                    <Col className="col-sm-auto">
                                        <div>
                                            <button className="btn buttonVersFutureLinkGreen"
                                                onClick={() => {
                                                setModalAdd(true);
                                                setName("");
                                                setPrice("");
                                                setDescription("");
                                                setStatus("");
                                                setCounselor("");
                                                setFoto("");
                                                }}
                                            ><i className="ri-add-line align-bottom me-1"></i> Add</button>
                                        </div>
                                    </Col>
                                </Row>

                                <div className="table-responsive table-card mt-3 mb-1">
                                    <table className="table align-middle table-nowrap" id="customerTable">
                                        <thead className="table-light">
                                            <tr>
                                                <th scope="col" style={{ width: "50px" }}>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                    </div>
                                                </th>
                                                <th className="sort" data-sort="no">No</th>
                                                <th className="sort" data-sort="nama">Package ID</th>
                                                <th className="sort" data-sort="nama">Package Name</th>
                                                <th className="sort" data-sort="status">Price</th>
                                                <th className="sort" data-sort="images">Detail</th>
                                                <th className="sort" data-sort="status_anotasi">Status</th>
                                                <th className="sort" data-sort="action">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="list form-check-all">
                                            {packages.map((item, index) => (
                                            <tr key={index}>
                                                <th scope="row">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="checkAll" value="option1" />
                                                    </div>
                                                </th>
                                                <td className="no">{index+1}</td>
                                                <td className="packageId">{item.package_id}</td>
                                                <td className="nama">{item.name}</td>
                                                <td className="price">{formatCurrency(item.price)}</td>
                                                <td className="detail">{item.detail}</td>
                                                <td className="status">{item.status}</td>
                                                <td>
                                                    <div className="d-flex gap-2">
                                                        <div className="edit">
                                                            <button className="btn buttonVersFutureLinkGreen" 
                                                                onClick={()=>{
                                                                    setModalUpdate(true);
                                                                    setIdPackages(item.package_id)
                                                                    setName(item.name);
                                                                    setPrice(item.price);
                                                                    setDescription(item.description);
                                                                    setStatus(item.status);
                                                                    setCounselor(item.counselor);
                                                                    setFoto("");
                                                                }}>Edit</button>
                                                        </div>
                                                        <div className="remove">
                                                            <button className="btn buttonVersFutureLinkRed" onClick={() => {setModalDelete(true); setIdPackages(item.package_id)}}>Remove</button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            </div>

            <Modal isOpen={modalAccessDenied} toggle={() => {}} centered>
                <ModalBody className='modalCenter'> 
                    <img src={accessDenied} style={{width: '30%'}}/>
                    <p style={{fontSize: '24px', fontWeight: '700', marginTop: '20px'}}>Access Denied</p>
                    <p style={{margin: '0px', color: 'grey'}}>You currently does not have access to this page. <br/> Please Login Again</p>
                </ModalBody>
                <ModalFooter style={{display: 'flex', justifyContent: 'center', gap: '80px'}}>
                    <button className='btn buttonVersFutureLink' style={{fontSize: '14px', fontWeight: '500'}} onClick={() => {logOut()}}>Login Again</button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalAdd} toggle={() => { setModalAdd(!modalAdd) }} centered >
                <ModalHeader toggle={() => {setModalAdd(!modalAdd)}}>
                    <h4>Add Package</h4>
                </ModalHeader>
                <ModalBody>
                    <Alert className={'alertRed ' + fade} hidden={alert}>{kontenError}</Alert>

                    <div className="mb-3">
                        <label htmlFor="packageId-field" className="form-label">Package ID <span style={{color: 'red'}}>*</span></label>
                        <input value={idPackages} onChange={(event) => {setIdPackages(event.target.value)}} type="text" id="packageId-field" className="form-control" placeholder="Enter package ID" required />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="packagename-field" className="form-label">Package Name <span style={{color: 'red'}}>*</span></label>
                        <input value={name} onChange={(event) => {setName(event.target.value)}} type="text" id="packagename-field" className="form-control" placeholder="Enter package name" required />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="price-field" className="form-label">Price <span style={{color: 'red'}}>*</span></label>
                        <input value={price} onChange={(event) => {setPrice(event.target.value)}} type="number" id="price-field" className="form-control" placeholder="Enter price" required />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="price-field" className="form-label">Description <span style={{color: 'red'}}>*</span></label>
                        <textarea value={description} onChange={(event) => {setDescription(event.target.value)}} className="form-control" id="message-text" rows="4"></textarea>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="status-field" className="form-label">Status <span style={{color: 'red'}}>*</span></label>
                        <select value={status} onChange={(event) => {setStatus(event.target.value)}} className="form-control" data-trigger name="status-field" id="status-field" >
                            <option value="">Choose Status</option>
                            <option value="active">Active</option>
                            <option value="nonactive">Nonactive</option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="status-field" className="form-label">Counselor <span style={{color: 'red'}}>*</span></label>
                        <select value={counselor} onChange={(event) => {setCounselor(event.target.value)}} className="form-control" data-trigger name="status-field" id="status-field" >
                            <option value="">Choose Status</option>
                            <option value="1">Any Counselor</option>
                            <option value="0">Nothing Counselor</option>
                        </select>
                    </div>

                    <div className='mb-3'>
                    <span style={{color: 'red'}}>*</span>
                        <FilePond
                            onupdatefiles={(event) => {setFoto(event[0].file)}}
                            allowMultiple={false}
                            maxFiles={1}
                            name="files"
                            className="filepond filepond-input-multiple"
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="hstack gap-2 justify-content-end">
                        <button className='btn buttonVersFutureLinkBorderGrey ' onClick={() => {setModalAdd(false)}}>Close</button>
                        <button className='btn buttonVersFutureLink' onClick={() => {addPackages()}}>Create</button>
                    </div>
                </ModalFooter>
            </Modal>

            {/* update Modal */}
            <Modal isOpen={modalUpdate} toggle={() => { setModalUpdate(!modalUpdate) }} centered >
                <ModalHeader toggle={() => {setModalUpdate(!modalUpdate)}}>
                    <h4>Update Package</h4>
                </ModalHeader>
                <ModalBody>
                    <Alert className={'alertRed ' + fade} hidden={alert}>{kontenError}</Alert>
                    <div className="mb-3">
                        <label htmlFor="packagename-field" className="form-label">Package Name <span style={{color: 'red'}}>*</span></label>
                        <input value={name} onChange={(event) => {setName(event.target.value)}} type="text" id="packagename-field" className="form-control" placeholder="Enter package name" required />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="price-field" className="form-label">Price <span style={{color: 'red'}}>*</span></label>
                        <input value={price} onChange={(event) => {setPrice(event.target.value)}} type="text" id="price-field" className="form-control" placeholder="Enter price" required />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="price-field" className="form-label">Description <span style={{color: 'red'}}>*</span></label>
                        <textarea value={description} onChange={(event) => {setDescription(event.target.value)}} className="form-control" id="message-text" rows="4"></textarea>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="status-field" className="form-label">Status <span style={{color: 'red'}}>*</span></label>
                        <select value={status} onChange={(event) => {setStatus(event.target.value)}} className="form-control" data-trigger name="status-field" id="status-field" >
                            <option value="">Choose Status</option>
                            <option value="active">Active</option>
                            <option value="nonactive">Nonactive</option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="status-field" className="form-label">Counselor <span style={{color: 'red'}}>*</span></label>
                        <select value={counselor} onChange={(event) => {setCounselor(event.target.value)}} className="form-control" data-trigger name="status-field" id="status-field" >
                            <option value="">Choose Status</option>
                            <option value="1">Any Counselor</option>
                            <option value="0">Nothing Counselor</option>
                        </select>
                    </div>

                    <div className='mb-3'>
                        <FilePond
                            onupdatefiles={(event) => {setFoto(event[0].file)}}
                            allowMultiple={false}
                            maxFiles={1}
                            name="files"
                            className="filepond filepond-input-multiple"
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="hstack gap-2 justify-content-end">
                        <button className='btn buttonVersFutureLinkBorderGrey ' onClick={() => {setModalUpdate(false)}}>Close</button>
                        <button className='btn buttonVersFutureLink' onClick={() => {updatePackages()}}>Update</button>
                    </div>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalDelete} toggle={() => {setModalDelete(!modalDelete)}} centered>
                <ModalBody className='modalCenter'> 
                    <img src={deleteData}/>
                    <p style={{fontSize: '24px', fontWeight: '700', marginTop: '20px'}}>You are about to delete a Package </p>
                    <p style={{margin: '0px', color: 'grey'}}>This we will delete your Package from FutureLink <br/> Are you sure ?</p>
                </ModalBody>
                <ModalFooter style={{display: 'flex', justifyContent: 'center', gap: '80px'}}>
                    <button className='btn buttonVersFutureLinkBorderGrey ' onClick={() => {setModalDelete(false)}}>Close</button>
                    <button className='btn buttonVersFutureLinkRed' onClick={() => {deletePackages()}}>Delete</button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalAlertSuccess} toggle={() => {setModalAlertSuccess(!modalAlertSuccess)}} centered>
                <ModalBody className='modalCenter' style={{margin: '0px'}}>
                    <Lottie options={{loop: true,autoplay: true,animationData: animateSuccess,rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} height={350} width={350} isClickToPauseDisabled={true} />
                </ModalBody>
            </Modal>

            </Container>
        </React.Fragment>
    );
};

export default ModulPackage;
import React, { useState, useEffect } from "react";

import SidebarDashboard from "./TemplateDashboard/sidebarDashboard.js";
import HeaderDashboard from "./TemplateDashboard/headerDashboard.js";

import ReportClient from "./MenuDashboard/report.js";

const ClientReport = () => {
  const title = "Report";

  return (
    <div className="dashboardv2">
      <SidebarDashboard title={title} />
      <section className="fullwidth">
        <HeaderDashboard title={title} gate1={false} gate2={false} menu={""} submenu={""} />
        <main className="mainv2">
          <ReportClient />
        </main>
      </section>
    </div>
  );
};

export default ClientReport;

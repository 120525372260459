import React, { useState, useEffect } from "react";
import {Row, Col} from "reactstrap";
import ReactStars from 'react-stars';
import {Link} from 'react-router-dom';


import mit1 from "../../../../assets/images/FutureLink/mitra1.png"
import mit2 from "../../../../assets/images/FutureLink/mitra2.png"
import mit3 from "../../../../assets/images/FutureLink/mitra3.png"
import mit4 from "../../../../assets/images/FutureLink/mitra4.png"
import mit5 from "../../../../assets/images/FutureLink/mitra5.png"
import mit6 from "../../../../assets/images/FutureLink/mitra6.png"
import mit7 from "../../../../assets/images/FutureLink/mitra7.png"
import mit8 from "../../../../assets/images/FutureLink/mitra8.png"
import mit9 from "../../../../assets/images/FutureLink/mitra9.png"
import mit10 from "../../../../assets/images/FutureLink/mitra10.png"
import mit11 from "../../../../assets/images/FutureLink/mitra11.png"

import tes1 from "../../../../assets/images/FutureLink/testimoni1.png"
import tes2 from "../../../../assets/images/FutureLink/testimoni2.png"
import tes3 from "../../../../assets/images/FutureLink/testimoni3.png"
import tes4 from "../../../../assets/images/FutureLink/testimoni4.png"

import promow from "../../../../assets/images/FutureLink/samplePromo.jpg"
import arrow from "../../../../assets/images/FutureLink/arrowbutton.svg"

import axios from 'axios'

const DashboardClient = () => {
    const [listArticle, setlistArticle] = useState([])
    const [listVideo, setlistVideo] = useState([{clickbait: "", created_at: "", id: 0, title: "", updated_at: "", url: ""}])

    const getArticle = () => {
        let config = {
        method: 'get',
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-article?page=0&limit=3&orderDate=desc`,
        };

        axios.request(config)
        .then((response) => {
            const array = response.results.map((item) => (
                {
                    article_content: new DOMParser().parseFromString(item.article_content, 'text/html').body.textContent.length < 75 ? new DOMParser().parseFromString(item.article_content, 'text/html').body.textContent : new DOMParser().parseFromString(item.article_content, 'text/html').body.textContent.slice(0, 75) + "...", 
                    article_title: item.article_title.length < 25 ? item.article_title : item.article_title.slice(0, 25) + '...',
                    author: item.author,
                    created_at: new Date(item.created_at).toLocaleDateString('id-Id', { day: 'numeric', month: 'long', year: 'numeric' }),
                    linkMinio: item.linkMinio,
                    url: item.url
                }))
            setlistArticle(array)
        })
        .catch((error) => {
            console.log(error);
            setlistArticle([])
        });
    }
    const getVideo = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-videos?page=0&limit=1&orderDate=desc`,
          };
          
          axios.request(config)
          .then((response) => {
            setlistVideo(response.results);
          })
          .catch((error) => {
            console.log(error);
          });
    }

    useEffect(() => {
        getArticle();
        getVideo();
    },[])
    
    return (
    <Row className="corev2">
        <Col lg={8} className="partLeft">
            <h2 className="appearOnMobile">Dashboard</h2>
            <Row>
                <h2 className="goneOnMobile">Promo</h2>
                <img src={promow} alt="promo"/>
            </Row>
            <Row className="space">
                <Col lg={6}>
                    <div className='dastit'>
                        <h2>Edukasi</h2>
                        <Link to={{pathname:"/client/listReading", state: { data: "video" }}}>
                            <p>View More</p>
                            <img src={arrow} /> 
                        </Link>
                    </div>
                
                <iframe src={listVideo[0].url} alt="promo" className="mooooIm"
                title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-i
                n-picture; web-share" allowFullScreen
                //  onClick={()=>{window.location.href=listVideo[0].url}} 
                style={{cursor: "pointer", width: '100%', height: '100%', borderRadius: '8px'}}
                 />


                </Col>
                {/* SEMENTARA DIMATIKAN */}
                {/* <Col lg={5} className="goneOnMobile">
                <h2>Kerjasama Kami</h2>
                <Row className="kerjasamaKami">
                    <Col lg={4}>
                        <img src={mit1}/>
                    </Col>
                    <Col lg={4}><div style={{width: '100%'}} ><img src={mit2}/></div></Col>
                    <Col lg={4}><div style={{width: '100%'}} ><img src={mit3}/></div></Col>
                    <Col lg={3}><div style={{width: '100%'}} ><img style={{objectFit: 'none'}} src={mit4}/></div></Col>
                    <Col lg={3}><div style={{width: '100%'}} ><img style={{objectFit: 'none'}} src={mit5}/></div></Col>
                    <Col lg={3}><div style={{width: '100%'}} ><img style={{objectFit: 'none'}} src={mit6}/></div></Col>
                    <Col lg={3}><div style={{width: '100%'}} ><img src={mit7}/></div></Col>
                    <Col lg={3}><div style={{width: '100%'}} ><img style={{objectFit: 'none'}} src={mit8}/></div></Col>
                    <Col lg={3}><div style={{width: '100%'}} ><img style={{objectFit: 'none'}} src={mit9}/></div></Col>
                    <Col lg={3}><div style={{width: '100%'}} ><img style={{objectFit: 'none'}} src={mit10}/></div></Col>
                    <Col lg={3}><div style={{width: '100%'}} ><img src={mit11}/></div></Col>
                </Row>

                </Col> */}
            </Row >

            <section className="appearOnMobile" style={{gap: '14px', flexDirection: 'column'}}>
                <div className='dastit' style={{marginBottom: '10px'}}>
                    <h2 >Artikel</h2>
                    <Link to={{pathname:"/client/listReading", state: { data: "article" }}}>
                        <p>View More</p>
                        <img src={arrow} /> 
                    </Link>
                </div>
                {listArticle != [] && (
                    <>
                    {listArticle.map((item, index) => (
                        <div style={{flex: '1',cursor: 'pointer'}} key={index} onClick={()=>{window.open(item.url, "_blank")}}>
                            <section>
                                <img src={item.linkMinio} className="dasArIm"/>
                                <p style={{display: 'flex', gap: '8px'}}>{item.author} <span> • </span> <span>{item.created_at}</span></p>
                            </section>
                            <div style={{marginTop: '18px'}}>
                                <h2 style={{margin: '0px', fontSize: '14px'}}>{item.article_title}</h2>
                                <p>{item.article_content}</p>
                            </div>
                        </div>
                    ))}
                    </>
                )}
            </section>
            {/* SEMENTARA DI MATIKAN */}
            {/* <section className="appearOnMobile dashTesti">
                <div className="dsJu">
                    <p style={{marginTop: '8px', marginBottom: '0px'}}>Join the </p>
                    <div style={{display: 'flex'}}>
                        <p style={{marginTop: '5px', marginBottom: '0px', marginLeft: '22px'}}>20+</p>
                        <svg className="dsC" xmlns="http://www.w3.org/2000/svg" width="79" height="38" viewBox="0 0 79 38" fill="none">
                        <path d="M0.969557 28.3964C7.99349 45.6981 75.4858 36.7712 78.3418 20.7119C82.8428 -4.59639 21.434 -2.29564 12.6813 5.98708C12.3571 6.29384 12.4102 6.86051 12.6813 6.73866C36.4007 -3.9215 79.6034 2.81205 75.9434 20.7119C71.7228 34.3477 2.57369 44.1029 2.29335 25.3287C2.10877 12.9674 33.0678 1.32422 55.4634 5.03609C56.1331 4.88271 55.6059 4.212 54.8716 4.06977C30.8563 0.108371 -4.33118 11.8891 0.969557 28.3964Z" fill="#FF533F"/>
                        </svg>
                    </div>
                </div>

                <p>Companies Using the Future Link Platform</p>

                <ol>
                    <li>TK Islam Arafah</li>
                    <li>TK Aisyiyah Bustanul Athfal 29</li>
                    <li>TK Islam Syofia Cipedak</li>
                    <li>PAUD Rambutan</li>
                    <li>TK Nurul Ihsan</li>
                    <li>TK An-Nuriyah</li>
                    <li>RA Bina Mutiara</li>
                    <li>TK Aisyiyah Bustanul Athfal 32</li>
                    <li>TK Melur</li>
                    <li>Etc</li>
                </ol>
            </section> */}

            <div>
                <h2>Testimoni</h2>
                <Row className="space" style={{gap: '24px'}}>
                    <Col lg={6}>
                        <Row className="cardTestimoni maBoMo">
                            <Col lg={3} style={{textAlign: 'center'}}>
                                <img src={tes1} alt="promo" style={{width: '50px'}}/>
                            </Col>
                            <Col lg={9}>
                                <ReactStars
                                    value={5}
                                    count={5}
                                    size={24}
                                    color2={'#ffd700'} 
                                    edit={false}
                                />
                                <h2 style={{margin: '0px'}}>Gaitsha Azzahra D</h2>
                                <h2 style={{color: '#2B34489C', marginBottom: '5px', fontSize: '1.5rem'}}>Mahasiswi</h2>
                                <p style={{marginBottom: '5px'}}>Terimakasih kakk, future link sangat membantu aku yang hampir bingung mengenali potensi akan jurusan untuk kuliah, laporannya sangat kompleks.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6} >
                        <Row className="cardTestimoni maBoMo">
                            <Col lg={3} style={{textAlign: 'center'}}>
                                <img src={tes2} alt="promo" style={{width: '50px'}}/>
                            </Col>
                            <Col lg={9}>
                                <ReactStars
                                    value={5}
                                    count={5}
                                    size={24}
                                    color2={'#ffd700'} 
                                    edit={false}
                                />
                                <h2 style={{margin: '0px'}}>Yoga Bramantya</h2>
                                <h2 style={{color: '#2B34489C', marginBottom: '5px', fontSize: '1.5rem'}}>Pegawai Negeri</h2>
                                <p style={{marginBottom: '5px'}}>Kini saya dapat lebih menata pekerjaan yang saya lakukan dan lebih mudah berinteraksi dengan rekan kerja.</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={6} >
                        <Row className="cardTestimoni maBoMo">
                            <Col lg={3} style={{textAlign: 'center'}}>
                                <img src={tes4} alt="promo" style={{width: '50px'}}/>
                            </Col>
                            <Col lg={9}>
                                <ReactStars
                                    value={5}
                                    count={5}
                                    size={24}
                                    color2={'#ffd700'} 
                                    edit={false}
                                />
                                <h2 style={{margin: '0px'}}>Prabu Dzidni Al Falaq</h2>
                                <h2 style={{color: '#2B34489C', marginBottom: '5px', fontSize: '1.5rem'}}>Siswa SMP</h2>
                                <p style={{marginBottom: '5px'}}>Terimakasih future link udah bantu aku mengenali potensi yang aku punya, selama ini aku kesulitan belajar dari segi akademik.</p>
                            </Col>
                        </Row>
                    </Col>
                    {/* <Col lg={6} className="goneOnMobile">
                        <Row style={{gap: '10px', justifyContent: 'space-around'}}>

                        <Col lg={5}>
                            <Row className="cardTestimoniChild">
                                <Col lg={3} style={{textAlign: 'center'}}>
                                    <img src={tes2} alt="promo" style={{width: '32px'}}/>
                                </Col>
                                <Col lg={9}>
                                <ReactStars
                                    value={5}
                                    count={5}
                                    size={12}
                                    color2={'#ffd700'} 
                                    edit={false}
                                />
                                <h2 style={{margin: '0px', fontSize: '11px'}}>Yoga Bramantya</h2>
                                <h2 style={{color: '#2B34489C', marginBottom: '5px', fontSize: '1.5rem', fontSize: '11px'}}>Pegawai Negeri</h2>
                                <p style={{marginBottom: '5px', fontSize: '8px'}}>Kini saya dapat lebih menata pekerjaan yang saya lakukan dan lebih mudah berinteraksi dengan rekan kerja.</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={5}>
                            <Row className="cardTestimoniChild">
                                <Col lg={3} style={{textAlign: 'center'}}>
                                    <img src={tes3} alt="promo" style={{width: '32px'}}/>
                                </Col>
                                <Col lg={9}>
                                <ReactStars
                                    value={5}
                                    count={5}
                                    size={12}
                                    color2={'#ffd700'} 
                                    edit={false}
                                />
                                <h2 style={{margin: '0px', fontSize: '11px'}}>Surya Alam</h2>
                                <h2 style={{color: '#2B34489C', marginBottom: '5px', fontSize: '1.5rem', fontSize: '11px'}}>Pegawai Negeri</h2>
                                <p style={{marginBottom: '5px', fontSize: '8px'}}>Kini saya dapat lebih menata pekerjaan yang saya lakukan dan lebih mudah berinteraksi dengan rekan kerja.</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6}>
                            <Row className="cardTestimoniChild">
                                <Col lg={3} style={{textAlign: 'center'}}>
                                    <img src={tes4} alt="promo" style={{width: '32px'}}/>
                                </Col>
                                <Col lg={9}>
                                <ReactStars
                                    value={5}
                                    count={5}
                                    size={12}
                                    color2={'#ffd700'} 
                                    edit={false}
                                />
                                <h2 style={{margin: '0px', fontSize: '11px'}}>Prabu Dzidni Al Falaq</h2>
                                <h2 style={{color: '#2B34489C', marginBottom: '5px', fontSize: '1.5rem', fontSize: '11px'}}>Siswa SMP</h2>
                                <p style={{marginBottom: '5px', fontSize: '8px'}}>Terimakasih future link udah bantu aku mengenali potensi yang aku punya, selama ini aku kesulitan belajar dari segi akademik.</p>
                                </Col>
                            </Row>
                        </Col>

                        </Row>



                    
                    </Col> */}
                </Row>

            </div>
            
        </Col>

        <Col lg={3} className="partRight goneOnMobile">
            <div className='dastit' style={{marginBottom: '10px'}}>
                <h2 className="goneOnMobile">Artikel</h2>
                <Link to={{pathname:"/client/listReading", state: { data: "article" }}}>
                    <p>View More</p>
                    <img src={arrow} /> 
                </Link>
            </div>
            <section style={{display: 'flex', flexDirection: 'column', gap: '18px'}}>
                {listArticle != [] && (
                    <>
                    {listArticle.map((item, index) => (
                        <div key={index} onClick={()=>{window.open(item.url, "_blank")}} style={{cursor: 'pointer'}}>
                            <section>
                                <img src={item.linkMinio} className="dasArIm"/>
                                <p style={{display: 'flex', gap: '8px'}}>{item.author} <span> • </span> <span>{item.created_at}</span></p>
                            </section>
                            <div style={{marginTop: '18px'}}>
                                <h2 style={{margin: '0px', fontSize: '14px'}}>{item.article_title}</h2>
                                <p>{item.article_content}</p>
                            </div>
                        </div>
                    ))}
                    </>
                )}
            </section>
        </Col>
    </Row>
    )
}

export default DashboardClient
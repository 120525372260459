import React, { useEffect, useState } from "react";
import { Progress } from "reactstrap";
import { Link } from "react-router-dom";
import whorl from "../../../../../assets/images/FutureLink/whorl.png";
import loop from "../../../../../assets/images/FutureLink/loop.png";
import arch from "../../../../../assets/images/FutureLink/arch.png";
import hand from "../../../../../assets/images/FutureLink/hand.png";
import axios from "axios";

import { decodeToken } from "react-jwt";

const PolaSidikJari = (haisayang) => {
  // const [data , setData] = useState(haisayang.history.location.state.data)
  const data = haisayang.history.location.state.data;
  // console.log(haisayang.history.location.state.data);

  const packages = haisayang.history.location.state.package_id;
  // console.log(haisayang.history.location.state.package_id)

  const token = haisayang.history.location.state.params;
  const decode = decodeToken(token);
  // console.log(decode.userId)

  const fingerType = [
    {
      name: "Tidak Diketahui",
      value: "nothing",
    },
    {
      name: "Whorl Target",
      value: "W_centric",
      nick: "WT",
    },
    {
      name: "Whorl Spiral",
      value: "W_spiral",
      nick: "WS",
    },
    {
      name: "Whorl Elongated Centric",
      value: "W_elongated_centric",
      nick: "WTE",
    },
    {
      name: "Whorl Elongated Spiral",
      value: "W_elongated_spiral",
      nick: "WSE",
    },
    {
      name: "Whorl Imploding",
      value: "W_imploding",
      nick: "WI",
    },
    {
      name: "Whorl Composite",
      value: "W_composite",
      nick: "WC",
    },
    {
      name: "Whorl Target Ulnar Peacock Eye",
      value: "W_peacock_centric_ulnar",
      nick: "WTP",
    },
    {
      name: "Whorl Spiral Ulnar Peacock Eye",
      value: "W_peacock_spiral_ulnar",
      nick: "WSP",
    },
    {
      name: "Whorl Target Radial Peacock Eye",
      value: "W_peacock_centric_radial",
      nick: "RWTP",
    },
    {
      name: "Whorl Spiral Radial Peacock Eye",
      value: "W_peacock_spiral_radial",
      nick: "RWSP",
    },
    {
      name: "Whorl Double Loop",
      value: "L_double",
      nick: "WDL",
    },
    {
      name: "Ulnar Loop",
      value: "L_ulnar",
      nick: "L",
    },
    {
      name: "Radial Loop",
      value: "L_radial",
      nick: "RL",
    },
    {
      name: "Ulnar Falling Loop",
      value: "L_fallen_ulnar",
      nick: "LW",
    },
    {
      name: "Radial Falling Loop",
      value: "L_fallen_radial",
      nick: "RLW",
    },
    {
      name: "Plain Arch",
      value: "A_simple",
      nick: "AS",
    },
    {
      name: "Tented Arch",
      value: "A_tented",
      nick: "TA",
    },
    {
      name: "Tented Arch with Ulnar Loop",
      value: "A_w_L_ulnar",
      nick: "LAS",
    },
    {
      name: "Tented Arch with Radial Loop",
      value: "A_w_L_radial",
      nick: "RLAS",
    },
    {
      name: "Accidental Whorl",
      value: "accidental",
      nick: "WX",
    },
  ];

  const [l1, setL1] = useState("");
  const [l2, setL2] = useState("");
  const [l3, setL3] = useState("");
  const [l4, setL4] = useState("");
  const [l5, setL5] = useState("");
  const [r1, setR1] = useState("");
  const [r2, setR2] = useState("");
  const [r3, setR3] = useState("");
  const [r4, setR4] = useState("");
  const [r5, setR5] = useState("");
  const [l1Shape, setL1Shape] = useState("");
  const [l2Shape, setL2Shape] = useState("");
  const [l3Shape, setL3Shape] = useState("");
  const [l4Shape, setL4Shape] = useState("");
  const [l5Shape, setL5Shape] = useState("");
  const [r1Shape, setR1Shape] = useState("");
  const [r2Shape, setR2Shape] = useState("");
  const [r3Shape, setR3Shape] = useState("");
  const [r4Shape, setR4Shape] = useState("");
  const [r5Shape, setR5Shape] = useState("");
  const [dominant, setDominant] = useState("");
  const [dominantImage, setDominantImage] = useState();
  const [dominantText, setDominantText] = useState([]);

  useEffect(() => {
    getAllFingerprint();
    let dominant = getDominant();
    setDominant(dominant);
    if (dominant == "Whorl") {
      setDominantImage(whorl);
      setDominantText([
        "Emosi: tidak stabil, meledak-ledak, sensitif",
        "Karakter: dominan, suka memimpin, suka menjadi pusat perhatian",
        "Kebiasaan: tidak bisa diam, mudah bosan.",
        "Kelebihan: bisa menjadi pemimpin, berpendirian teguh, sosok yang kuat, sangat bertanggung jawab, mandiri, gesit ketika bertindak, serta dapat menganalisa situasi dengan cermat.",
        "Kekurangan: pemikir, terobsesi pada kekuasaan, sukar menyesuaikan diri, memiliki egosentris yang tinggi, kaku, dan memiliki kontrol emosi yang buruk.",
      ]);
    } else if (dominant == "Loop") {
      setDominantImage(loop);
      setDominantText([
        "Emosi: ramah, optimis, terbuka",
        "Karakter: simpatinya tinggi, tulus, optimis, kurang inisiatif",
        "Kebiasaan: rajin dan bertanggung jawab, pekerja keras.",
        "Kelebihan: memiliki empati tinggi, senang membantu orang lain, tulus, rajin dan bertanggung jawab, terbuka, ramah, murah senyum, optimis, dan pekerja keras.",
        "Kekurangan: kurang memiliki inisiatif, tidak menyukai aturan yang keras, tidak bisa berdiam diri, dan bergantung pada orang lain.",
      ]);
    } else {
      setDominantImage(arch);
      setDominantText([
        "Emosi: sabar, sulit mengekspresikan perasaan",
        "Karakter: sederhana, praktis, sulit beradaptasi",
        "Kebiasaan: impulsive, selalu bersemangat.",
        "Kelebihan: praktis, berkepala dingin, bersahaja, selalu bersemangat, memiliki kepercayaan diri yang tinggi, tegas, dan tidak mudah menyerah.",
        "Kekurangan: berkepala dingin, sukar mengutarakan perasaan, impulsive, kurang mampu menerima pendapat orang lain, dan sulit beradaptasi.",
      ]);
    }
  }, []);

  const getAllFingerprint = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-by-id?userId=${decode.userId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        // console.log(response.results[0]);

        getImage(response.results[0].l1, "l1");
        getImage(response.results[0].l2, "l2");
        getImage(response.results[0].l3, "l3");
        getImage(response.results[0].l4, "l4");
        getImage(response.results[0].l5, "l5");
        getImage(response.results[0].r1, "r1");
        getImage(response.results[0].r2, "r2");
        getImage(response.results[0].r3, "r3");
        getImage(response.results[0].r4, "r4");
        getImage(response.results[0].r5, "r5");

        setL1Shape(
          fingerType[
            fingerType
              .map((item) => item.value)
              .indexOf(response.results[0].l1_shape)
          ].nick
        );
        setL2Shape(
          fingerType[
            fingerType
              .map((item) => item.value)
              .indexOf(response.results[0].l2_shape)
          ].nick
        );
        setL3Shape(
          fingerType[
            fingerType
              .map((item) => item.value)
              .indexOf(response.results[0].l3_shape)
          ].nick
        );
        setL4Shape(
          fingerType[
            fingerType
              .map((item) => item.value)
              .indexOf(response.results[0].l4_shape)
          ].nick
        );
        setL5Shape(
          fingerType[
            fingerType
              .map((item) => item.value)
              .indexOf(response.results[0].l5_shape)
          ].nick
        );
        setR1Shape(
          fingerType[
            fingerType
              .map((item) => item.value)
              .indexOf(response.results[0].r1_shape)
          ].nick
        );
        setR2Shape(
          fingerType[
            fingerType
              .map((item) => item.value)
              .indexOf(response.results[0].r2_shape)
          ].nick
        );
        setR3Shape(
          fingerType[
            fingerType
              .map((item) => item.value)
              .indexOf(response.results[0].r3_shape)
          ].nick
        );
        setR4Shape(
          fingerType[
            fingerType
              .map((item) => item.value)
              .indexOf(response.results[0].r4_shape)
          ].nick
        );
        setR5Shape(
          fingerType[
            fingerType
              .map((item) => item.value)
              .indexOf(response.results[0].r5_shape)
          ].nick
        );
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const getImage = (imageName, index) => {
    if (index === "l1") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setL1(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "l2") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setL2(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "l3") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setL3(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "l4") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setL4(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "l5") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setL5(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "r1") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setR1(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "r2") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setR2(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "r3") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setR3(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "r4") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setR4(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "r5") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setR5(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  const getDominant = () => {
    var cognitive = parseInt(data.cognitive);
    var affective = parseInt(data.affective);
    var reverse = parseInt(data.reverse);
    var reflective = parseInt(data.reflective);
    if (
      (cognitive > reflective && cognitive > affective) ||
      cognitive == reflective
    ) {
      return "Whorl";
    } else if (
      (reflective > cognitive && reflective > affective) ||
      reflective == affective
    ) {
      return "Loop";
    } else {
      return "Arch";
    }
  };

  const listFingerprint = [
    {
      title: "R1",
      img: r1,
      progress: data.intrapersonal < 3 ? data.intrapersonal * 8 : data.intrapersonal < 5 ? data.intrapersonal * 7 : data.intrapersonal < 9 ? data.intrapersonal * 6 : data.intrapersonal * 5.5,
      color: data.intrapersonal < 8? "ohno" : data.intrapersonal > 12? "good" : "okay",
      shape: r1Shape,
      value: data.intrapersonal
    },
    {
      title: "L1",
      img: l1,
      progress: data.interpersonal < 3 ? data.interpersonal * 8 : data.interpersonal < 5 ? data.interpersonal * 7 : data.interpersonal < 9 ? data.interpersonal * 6 : data.interpersonal * 5.5,
      color: data.interpersonal < 8? "ohno" : data.interpersonal > 12? "good" : "okay",
      shape: l1Shape,
      value: data.interpersonal
    },
    {
      title: "R2",
      img: r2,
      progress: data.logical < 3 ? data.logical * 8 : data.logical < 5 ? data.logical * 7 : data.logical < 9 ? data.logical * 6 : data.logical * 5.5,
      color: data.logical < 8? "ohno" : data.logical > 12? "good" : "okay",
      shape: r2Shape,
      value: data.logical
    },
    {
      title: "L2",
      img: l2,
      progress: data.imagination < 3 ? data.imagination * 8 : data.imagination < 5 ? data.imagination * 7 : data.imagination < 9 ? data.imagination * 6 : data.imagination * 5.5,
      color: data.imagination < 8? "ohno" : data.imagination > 12? "good" : "okay",
      shape: l2Shape,
      value: data.imagination
    },
    {
      title: "R3",
      img: r3,
      progress: data.fine_motors < 3 ? data.fine_motors * 8 : data.fine_motors < 5 ? data.fine_motors * 7 : data.fine_motors < 9 ? data.fine_motors * 6 : data.fine_motors * 5.5,
      color: data.fine_motors < 8? "ohno" : data.fine_motors > 12? "good" : "okay",
      shape: r3Shape,
      value: data.fine_motors
    },
    {
      title: "L3",
      img: l3,
      progress: data.gross_motors < 3 ? data.gross_motors * 8 : data.gross_motors < 5 ? data.gross_motors * 7 : data.gross_motors < 9 ? data.gross_motors * 6 : data.gross_motors * 5.5,
      color: data.gross_motors < 8? "ohno" : data.gross_motors > 12? "good" : "okay",
      shape: l3Shape,
      value: data.gross_motors
    },
    {
      title: "R4",
      img: r4,
      progress: data.lingustic < 3 ? data.lingustic * 8 : data.lingustic < 5 ? data.lingustic * 7 : data.lingustic < 9 ? data.lingustic * 6 : data.lingustic * 5.5,
      color: data.lingustic < 8? "ohno" : data.lingustic > 12? "good" : "okay",
      shape: r4Shape,
      value: data.lingustic
    },
    {
      title: "L4",
      img: l4,
      progress: data.musical < 3 ? data.musical * 8 : data.musical < 5 ? data.musical * 7 : data.musical < 9 ? data.musical * 6 : data.musical * 5.5,
      color: data.musical < 8? "ohno" : data.musical > 12? "good" : "okay",
      shape: l4Shape,
      value: data.musical
    },
    {
      title: "R5",
      img: r5,
      progress: data.naturalist < 3 ? data.naturalist * 8 : data.naturalist < 5 ? data.naturalist * 7 : data.naturalist < 9 ? data.naturalist * 6 : data.naturalist * 5.5,
      color: data.naturalist < 8? "ohno" : data.naturalist > 12? "good" : "okay",
      shape: r5Shape,
      value: data.naturalist
    },
    {
      title: "L5",
      img: l5,
      progress: data.visual_spatial < 3 ? data.visual_spatial * 8 : data.visual_spatial < 5 ? data.visual_spatial * 7 : data.visual_spatial < 9 ? data.visual_spatial * 6 : data.visual_spatial * 5.5,
      color: data.visual_spatial < 8? "ohno" : data.visual_spatial > 12? "good" : "okay",
      shape: l5Shape,
      value: data.visual_spatial
    },
  ];

  const listKeterangan = [
    {
      color: "#007F5F",
      text: "Diatas rata-rata",
    },
    {
      color: "#AACC00",
      text: "Rata-rata",
    },
    {
      color: "#FF7518",
      text: "Dibawah Rata-rata",
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
          <p style={{ margin: "0px", backgroundColor: "#1F599C",padding: "8px 80px",fontSize: "18px",fontWeight: "900",color: "white",borderRadius: "10px",width: "max-content"}}>
            Hasil Pola Sidik Jari
          </p>
        </div>

        <div className="grid-container">
          <div className="grid-item noPadding">
            <div className="polasidikjariList">
              <Link style={{ width: "max-content" }} className="btn buttonVersFutureLinkBorderGrey" to={`/report-mobile/${token}`}>
                Back
              </Link>

              {listFingerprint.map((item, index) => (
                <div key={index} className="polasidikjariItem">
                  <div className="polasidikjariBoxImg">
                    <p style={{margin: "0px",fontSize: "16px",fontWeight: "700",position: "absolute",color: 'white'}}>
                      {item.title}
                    </p>
                    <img src={item.img} className="polasidikjariImg" />
                  </div>
                  <Progress
                    className="progress-xl animated-progess progress-label width175"
                    value={item.progress}
                    color={item.color}
                  >
                    <p style={{margin: "0px",color: "white",fontSize: "16px",fontWeight: "700"}}>
                      {item.shape}
                    </p>
                    <div className="label">{item.value}%</div>
                  </Progress>
                </div>
              ))}

              <div>
                <p style={{fontSize: "16px",display: "flex",justifyContent: "flex-start", fontWeight: '600'}}>
                  Keterangan:
                </p>
                {listKeterangan.map((item, index) => (
                  <div key={index} style={{display: "flex",alignItems: "center",gap: "15px",marginBottom: "10px",}}>
                    <div style={{width: "25px",height: "25px",backgroundColor: item.color,}}></div>
                    <p style={{ margin: "0px", fontSize: "16px" }}>
                      {item.text}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="grid-item noPadding">
            <h4>Bentuk Dominan Sidik Jarimu</h4>
            <div className="grid-container-3 dFlexColumn noPadding">
              <img src={dominantImage} width={100} style={{ margin: "18px 0px", alignSelf: 'center' }} />
              <div>
                <h4>{dominant}</h4>
                <ul
                  style={{
                    fontSize: "16px",
                    lineHeight: '28px'
                  }}
                >
                  {dominantText.map((row) => {
                    return <><li>{row}</li></>;
                  })}
                </ul>
              </div>
              {/* <img
                className="hand-pattern"
                src={hand}
                style={{ margin: "auto" }}
              /> */}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Link
            className="bottomButton buttonVersFutureLink"
            to={{
              pathname: "/report-mobiles/distribusi-neuron",
              state: { data: data, package_id: packages, params: token },
            }}
            onClick={() => {document.body.scrollIntoView({behavior: "smooth"})}}
          >
            Distribusi Neuron {">"}
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PolaSidikJari;

import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";

import bg from "../../../../assets/images/FutureLink/bg.png";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom.js";

const ReportSPMClient = () => {
  const [spmData, setSpmData] = useState({
    spmGrade: null,
    spmCategory: null,
    spmStats: null,
    identity: null,
  });

  let { testId, token } = useParams();
  let currentTestId = 20;
  const code = token ? token : sessionStorage.getItem("user");
  console.log(code);

  useEffect(() => {
    if (!spmData.spmStats) {
      getSpmData();
    } else {
      console.log("Triggered");
    }
  }, [spmData]);

  const getSpmData = () => {
    let urlDetail = "/v1/finish-user-test-result";
    let urlPayload = { testId: currentTestId, generatePdf: false };
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}${urlDetail}`,
      headers: {
        Authorization: `Bearer ${code}`,
      },
      data: urlPayload,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response);
        let responseData = response.results;

        if (responseData != null) {
          let identityData = JSON.parse(responseData.identity_data);
          let point = responseData.point;
          const [spmGrade, spmCategory] = classifySpmCategory(point);
          setSpmData({
            spmGrade: spmGrade,
            spmCategory: spmCategory,
            spmStats: {
              correctAnswer: responseData.correct_answer,
              incorrectAnswer: responseData.incorrect_answer,
              noAnswer: responseData.no_answer,
              point: responseData.point,
            },
            identity: identityData,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const classifySpmCategory = (point) => {
    let spmGrade = "",
      spmCategory = "";
    if (point > 95) {
      spmGrade = "I";
      spmCategory = "  Superior";
    } else if (point >= 75) {
      spmGrade = "II";
      spmCategory = "  Diatas Rata-Rata";
    } else if (point >= 25) {
      spmGrade = "III";
      spmCategory = "  Rata-Rata";
    } else if (point >= 5) {
      spmGrade = "IV";
      spmCategory = "  Dibawah Rata-Rata";
    } else {
      spmGrade = "V";
      spmCategory = "  Terhambat";
    }

    return [spmGrade, spmCategory];
  };

  return (
    <>
      <Row className="corev2 repCen spmReport">
        <Row className="rowReport">
          <Col lg={5}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="spmTable" style={{ width: "100%" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="spmTbl title" style={{ flex: "1" }}>
                    <p>Grade</p>
                  </div>
                  <div className="spmTbl title" style={{ flex: "1" }}>
                    <p>Range Nilai</p>
                  </div>
                  <div className="spmTbl title" style={{ flex: "2" }}>
                    <p>Kategori</p>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="spmTbl lvl1" style={{ flex: "1" }}>
                    <p>I</p>
                  </div>
                  <div className="spmTbl lvl1" style={{ flex: "1" }}>
                    <p>{"> 95"}</p>
                  </div>
                  <div className="spmTbl lvl1" style={{ flex: "2" }}>
                    <p className="spmKet">Superior</p>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="spmTbl lvl2" style={{ flex: "1" }}>
                    <p>II</p>
                  </div>
                  <div className="spmTbl lvl2" style={{ flex: "1" }}>
                    <p>{"75 - 95"}</p>
                  </div>
                  <div className="spmTbl lvl2" style={{ flex: "2" }}>
                    <p className="spmKet">Diatas Rata-Rata</p>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="spmTbl lvl3" style={{ flex: "1" }}>
                    <p>III</p>
                  </div>
                  <div className="spmTbl lvl3" style={{ flex: "1" }}>
                    <p>{"25 - 75"}</p>
                  </div>
                  <div className="spmTbl lvl3" style={{ flex: "2" }}>
                    <p className="spmKet">Rata-Rata</p>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="spmTbl lvl4" style={{ flex: "1" }}>
                    <p>IV</p>
                  </div>
                  <div className="spmTbl lvl4" style={{ flex: "1" }}>
                    <p>{"5 - 25"}</p>
                  </div>
                  <div className="spmTbl lvl4" style={{ flex: "2" }}>
                    <p className="spmKet">Dibawah Rata-Rata</p>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="spmTbl lvl5" style={{ flex: "1" }}>
                    <p>V</p>
                  </div>
                  <div className="spmTbl lvl5" style={{ flex: "1" }}>
                    <p>{"< 5"}</p>
                  </div>
                  <div className="spmTbl lvl5" style={{ flex: "2" }}>
                    <p className="spmKet">Terhambat</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col lg={7} style={{ marginTop: "56px" }}>
            <p className={"spm"}>Kategori</p>
            <p className={"spmScore"}>
              {spmData.spmStats ? spmData.spmStats.point : 0}
            </p>
            <p className={"spmKat"}>
              {spmData ? "Grade " + spmData.spmGrade : ""}
            </p>
            <p className="spmKeterangan">
              Pada usia {spmData.identity ? spmData.identity.age : 0} tahun,{" "}
              {spmData.identity ? spmData.identity.name : ""} mengerjakan Tes
              SPM dengan menjawab soal dengan jawaban benar sebanyak{" "}
              {spmData.spmStats ? spmData.spmStats.correctAnswer : 0} soal,
              jawaban salah sebanyak{" "}
              {spmData.spmStats ? spmData.spmStats.incorrectAnswer : 0} soal,
              dan tidak ada jawaban sebanyak{" "}
              {spmData.spmStats ? spmData.spmStats.noAnswer : 0} soal.
              Berdasarkan jumlah nilai tersebut maka{" "}
              {spmData.identity ? spmData.identity.name : ""} memiliki total
              nilai {spmData.spmStats ? spmData.spmStats.point : 0} dan dapat
              digolongkan masuk grade {spmData ? spmData.spmGrade : ""} yaitu
              kategori potensi kecerdasan{" "}
              <b>{spmData ? spmData.spmCategory : ""}</b>.
            </p>
          </Col>
        </Row>
      </Row>

      <div
        style={{
          width: "-webkit-fill-available",
          position: "absolute",
          bottom: "0",
        }}
      >
        <img style={{ width: "-webkit-fill-available" }} src={bg} />
      </div>
    </>
  );
};

export default ReportSPMClient;

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useInterval } from "usehooks-ts";
import axios from "axios";

import Lottie from "react-lottie";
import accessDenied from "../../../../../assets/images/FutureLink/accessDenied.json";
import accessLoading from "../../../../../assets/images/FutureLink/loading.json";

import { decodeToken } from "react-jwt";

import no1 from "../../../../../assets/images/FutureLink/no1.png";
import no2 from "../../../../../assets/images/FutureLink/no2.png";
import no3 from "../../../../../assets/images/FutureLink/no3.png";
import no4 from "../../../../../assets/images/FutureLink/no4.png";
import no5 from "../../../../../assets/images/FutureLink/no5.png";
import no6 from "../../../../../assets/images/FutureLink/no6.png";
import no7 from "../../../../../assets/images/FutureLink/no7.png";
import no8 from "../../../../../assets/images/FutureLink/no8.png";
import no9 from "../../../../../assets/images/FutureLink/no9.png";
import no10 from "../../../../../assets/images/FutureLink/no10.png";
import no11 from "../../../../../assets/images/FutureLink/no11.png";
import no12 from "../../../../../assets/images/FutureLink/no12.png";
import no13 from "../../../../../assets/images/FutureLink/no13.png";
import no14 from "../../../../../assets/images/FutureLink/no14.png";
import no15 from "../../../../../assets/images/FutureLink/no15.png";
import no16 from "../../../../../assets/images/FutureLink/no16.png";
import no17 from "../../../../../assets/images/FutureLink/no17.png";
import no23 from "../../../../../assets/images/FutureLink/no23.png";

const ReportMobile = () => {
  let { token } = useParams();

  const [pdf, setPdf] = useState("");
  const [statusCode, setStatusCode] = useState(0);
  const [data, setData] = useState([]);
  const [packages, setPackages] = useState(1);
  const [loading, setLoading] = useState(true);


  const [status, setStatus] = useState("pending");
  const [isPlaying, setPlaying] = useState(true);


  useEffect(() => {
    getReport();
  }, []);

  useInterval(
    () => {
      getReport();
      // console.log(status)
      if (status == "success") {
        setPlaying(false);
      }
    },
    isPlaying ? 60000 : null
  );

  const getReport = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-latest-report`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        // console.log(response); // {statusCode: ... , message: ... , results: {created_at: ... , package_id: ... , pdf: ... , report: {~databanyak~}}}
        setStatus(response.results.status);
        setPdf(response.results.pdf);
        setStatusCode(response.statusCode);
        setData(response.results.report);
        setPackages(response.results.package_id);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      });
  };


  return (
    <React.Fragment>
      {loading === true ? (
        <>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: accessLoading,
              rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
            }}
            isClickToPauseDisabled={true}
          />
        </>
      ) : (
        <>
          {statusCode !== 200 || data === null ? (
            <div className="page-content">
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "60px",
                  }}
                >
                  <p
                    style={{
                      margin: "0px",
                      backgroundColor: "#1F599C",
                      padding: "8px 0px",
                      fontSize: "18px",
                      fontWeight: "900",
                      color: "white",
                      borderRadius: "10px",
                      width: "250px",
                      textAlign: "center",
                    }}
                  >
                    Report Tidak Tersedia
                  </p>
                </div>
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: accessDenied,
                    rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
                  }}
                  isClickToPauseDisabled={true}
                />
                <p className="upgradePlease" style={{ width: "100%" }}>
                  {" "}
                  Sorry, the report is not available.
                </p>
              </>
            </div>
          ) : (
            <div className="page-content">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "absolute",
                  right: "50px",
                }}
              >
                {status === "pending" ? (
                  <>
                    <a
                      style={{
                        backgroundColor: "#1F599C",
                        padding: "5px 10px",
                        borderRadius: "8px",
                      }}
                      onClick={() => {
                        alert("Currently in progress, please wait a moment.");
                      }}
                    >
                      <i
                        className="ri-download-fill"
                        style={{ color: "white", fontSize: "22px" }}
                      />
                    </a>
                  </>
                ) : (
                  <>
                    <a
                      href={pdf}
                      download
                      style={{
                        backgroundColor: "#1F599C",
                        padding: "5px 10px",
                        borderRadius: "8px",
                      }}
                      onClick={() => {
                        alert("Download Started");
                      }}
                    >
                      <i
                        className="ri-download-fill"
                        style={{ color: "white", fontSize: "22px" }}
                      />
                    </a>
                  </>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "60px",
                }}
              >
                <p className="titleReport">Report</p>
              </div>
              <div className="reportList">
                {packages === 1 ? (
                  <>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/pola-sidik-jari",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no1} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Hasil Pola Sidik Jari</p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/distribusi-neuron",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no2} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Distribusi Neuron</p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/dominasi-otak",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no4} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Dominasi Otak</p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/gaya-belajar",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no5} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Gaya Belajar</p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/ekstrakulikuler",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no6} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Ekstrakulikuler</p>
                    </Link>
                    {/* <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/summary",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no23} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Ringkasan</p>
                    </Link> */}
                  </>
                ) :  packages.toString().includes("22") ? (
                  <>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/pola-sidik-jari",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no1} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Hasil Pola Sidik Jari</p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/distribusi-neuron",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no2} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Distribusi Neuron</p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/dominasi-otak",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no4} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Dominasi Otak</p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/gaya-belajar",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no5} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Gaya Belajar</p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/ekstrakulikuler",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no6} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Ekstrakulikuler</p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/penjurusan",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no7} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>
                        Pemilihan Jurusan
                      </p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/kecerdasan",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no9} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Kecerdasan</p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/domain-kecerdasan",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no14} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Domain Kecerdasan</p>
                    </Link>
                    {/* <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/summary",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no23} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Ringkasan</p>
                    </Link> */}
                  </>
                ) :  packages.toString().includes("33") ? (
                  <>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/pola-sidik-jari",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no1} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Hasil Pola Sidik Jari</p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/distribusi-neuron",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no2} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Distribusi Neuron</p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/tfrc",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no3} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>
                        Total Finger Ridge Count
                      </p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/dominasi-otak",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no4} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Dominasi Otak</p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/gaya-belajar",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no5} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Gaya Belajar</p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/ekstrakulikuler",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no6} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Ekstrakulikuler</p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/penjurusan",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no7} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>
                        Pemilihan Jurusan
                      </p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/lobus-otak",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no8} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Lobus Otak</p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/kecerdasan",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no9} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Kecerdasan</p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/indra-dasar",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no10} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Indra Dasar</p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/gaya-berpikir",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no11} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Gaya Berpikir</p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/minat-kejuruan",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no12} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Minat Studi (RIASEC)</p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/kecerdasan-majemuk",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no13} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Kecerdasan Majemuk</p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/domain-kecerdasan",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no14} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Domain Kecerdasan</p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/disc",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no15} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>
                        DISC
                      </p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/dope",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no16} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>
                        DOPE
                      </p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/konsep-pemikiran",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no17} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Perbandingan Konsep Pemikiran</p>
                    </Link>
                    {/* <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/summary",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no23} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Ringkasan</p>
                    </Link> */}
                  </>
                ) : (
                  <>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/pola-sidik-jari",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no1} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Hasil Pola Sidik Jari</p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/distribusi-neuron",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no2} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Distribusi Neuron</p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/dominasi-otak",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no4} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Dominasi Otak</p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/gaya-belajar",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no5} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Gaya Belajar</p>
                    </Link>
                    <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/ekstrakulikuler",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no6} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Ekstrakulikuler</p>
                    </Link>
                    {/* <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/summary",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no23} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Rangkuman</p>
                    </Link> */}
                  </>
                )}
              </div>

              <div className="reportListMobile">
                {packages === 1 ? (
                  <>
                  <div className="judul">
                    <p>Kemampuan Umum</p>
                    <div className="merah"></div>
                  </div>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/pola-sidik-jari",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no1} />
                      <p>Hasil Pola Sidik Jari</p>
                    </Link>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/distribusi-neuron",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no2} />
                      <p>Distribusi Neuron</p>
                    </Link>
                    <div className="judul">
                      <p>Kemampuan Penunjang Akademik</p>
                      <div className="hijau"></div>
                    </div>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/dominasi-otak",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no4} />
                      <p>Dominasi Otak</p>
                    </Link>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/gaya-belajar",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no5} />
                      <p>Gaya Belajar</p>
                    </Link>
                    <div className="judul">
                      <p>Kemampuan Penunjang Minat & Bakat</p>
                      <div className="ungu"></div>
                    </div>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/ekstrakulikuler",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no6} />
                      <p>Ekstrakulikuler</p>
                    </Link>
                    {/* <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/summary",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no23} />
                      <p>Ringkasan</p>
                    </Link> */}
                  </>
                ) :  packages.toString().includes("22") ? (
                  <>
                  <div className="judul">
                    <p>Kemampuan Umum</p>
                    <div className="merah"></div>
                  </div>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/pola-sidik-jari",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no1} />
                      <p>Hasil Pola Sidik Jari</p>
                    </Link>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/distribusi-neuron",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no2} />
                      <p>Distribusi Neuron</p>
                    </Link>
                    <div className="judul">
                      <p>Kemampuan Penunjang Akademik</p>
                      <div className="hijau"></div>
                    </div>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/dominasi-otak",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no4} />
                      <p>Dominasi Otak</p>
                    </Link>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/gaya-belajar",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no5} />
                      <p>Gaya Belajar</p>
                    </Link>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/kecerdasan",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no9} />
                      <p>Kecerdasan</p>
                    </Link>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/domain-kecerdasan",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no14} />
                      <p>Domain Kecerdasan</p>
                    </Link>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/penjurusan",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no7} />
                      <p>Pemilihan Jurusan</p>
                    </Link>
                    <div className="judul">
                      <p>Kemampuan Penunjang Minat & Bakat</p>
                      <div className="ungu"></div>
                    </div>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/ekstrakulikuler",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no6} />
                      <p>Ekstrakulikuler</p>
                    </Link>
                    {/* <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/summary",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no23} />
                      <p>Ringkasan</p>
                    </Link> */}
                  </>
                ) :  packages.toString().includes("33") ? (
                  <>
                  <div className="judul">
                    <p>Kemampuan Umum</p>
                    <div className="merah"></div>
                  </div>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/pola-sidik-jari",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no1} />
                      <p>Hasil Pola Sidik Jari</p>
                    </Link>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/distribusi-neuron",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no2} />
                      <p>Distribusi Neuron</p>
                    </Link>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/tfrc",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no3} />
                      <p>Total Finger Ridge Count</p>
                    </Link>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/lobus-otak",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no8} />
                      <p>Lobus Otak</p>
                    </Link>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/indra-dasar",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no10} />
                      <p>Indra Dasar</p>
                    </Link>
                    <div className="judul">
                      <p>Kemampuan Penunjang Akademik</p>
                      <div className="hijau"></div>
                    </div>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/dominasi-otak",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no4} />
                      <p>Dominasi Otak</p>
                    </Link>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/gaya-belajar",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no5} />
                      <p>Gaya Belajar</p>
                    </Link>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/kecerdasan",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no9} />
                      <p>Kecerdasan</p>
                    </Link>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/domain-kecerdasan",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no14} />
                      <p>Domain Kecerdasan</p>
                    </Link>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/penjurusan",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no7} />
                      <p>Pemilihan Jurusan</p>
                    </Link>
                    <div className="judul">
                      <p>Kemampuan Penunjang Minat & Bakat</p>
                      <div className="ungu"></div>
                    </div>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/kecerdasan-majemuk",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no13} />
                      <p>Kecerdasan Majemuk</p>
                    </Link>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/ekstrakulikuler",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no6} />
                      <p>Ekstrakulikuler</p>
                    </Link>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/minat-kejuruan",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no12} />
                      <p>Minat Studi (RIASEC)</p>
                    </Link>
                    <div className="judul">
                      <p>Kemampuan Penunjang Kepribadian</p>
                      <div className="biru"></div>
                    </div>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/disc",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no15} />
                      <p>DISC</p>
                    </Link>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/dope",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no16} />
                      <p>DOPE</p>
                    </Link>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/konsep-pemikiran",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no17} />
                      <p>Perbandingan Konsep Pemikiran</p>
                    </Link>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/gaya-berpikir",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no11} />
                      <p>Gaya Berpikir</p>
                    </Link>
                    {/* <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/summary",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no23} />
                      <p>Ringkasan</p>
                    </Link> */}
                  </>
                ) : (
                  <>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/pola-sidik-jari",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no1} />
                      <p>Hasil Pola Sidik Jari</p>
                    </Link>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/distribusi-neuron",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no2} />
                      <p>Distribusi Neuron</p>
                    </Link>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/dominasi-otak",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no4} />
                      <p>Dominasi Otak</p>
                    </Link>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/gaya-belajar",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no5} />
                      <p>Gaya Belajar</p>
                    </Link>
                    <Link
                      className="item"
                      to={{
                        pathname: "/report-mobiles/ekstrakulikuler",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no6} />
                      <p>Ekstrakulikuler</p>
                    </Link>
                    {/* <Link
                      className="reportItem"
                      to={{
                        pathname: "/report-mobiles/summary",
                        state: {
                          data: data,
                          package_id: packages,
                          params: token,
                        },
                      }}
                    >
                      <img src={no23} style={{ width: "60px" }} />
                      <p style={{ textAlign: "center" }}>Rangkuman</p>
                    </Link> */}
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default ReportMobile;

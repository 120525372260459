import React, {useState} from "react";
import {Row, Col, NavItem} from 'reactstrap';
import {Link} from 'react-router-dom'

import cog from '../../../../../assets/images/FutureLink/SelfCognitive.png';
import aff from '../../../../../assets/images/FutureLink/Affective.png';
import ref from '../../../../../assets/images/FutureLink/Reflective.png';
import rev from '../../../../../assets/images/FutureLink/Reverse.png';

const GayaBerpikir = (e) => {
    const state = e.state.data
    const data = e.state.data
    const package_id = e.state.package_id
    const img_finger = e.state.img_finger
    const shape_finger = e.state.shape_finger
    const cognitive = state.cognitive
    const affective = state.affective
    const reflective = state.reflective
    const reverse = state.reverse

    const listGayaBerpikir = [
        {
            img: cog,
            title: "Self-Cognitive Learners",
            value: cognitive,
            text1: "Mandiri, berorientasi pada tujuan, fokus pada target, terbiasa memulai segalanya sendiri, cenderung keras kepala, memiliki kepercayaan diri yang tinggi, kurang fleksibel, berani, memiliki tekad yang kuat, agresif, bertanggung jawab, egois, dan suka memerintah.",
            text2: "Gaya berpikir ini dapat ditingkatkan dengan memberikan keterbatasan informasi agar mau mencari lebih banyak lagi, menempatkan di tempat dengan lingkungan yang kompetitif agar termotivasi, serta membiarkan diri menerima kekalahan ataupun jawaban yang salah agar dapat berkembang."
        },
        {
            img: aff,
            title: "Affective Learners",
            value: affective,
            text1: "Kemampuan belajarnya tinggi, fleksibel, berorientasi pada orang, membutuhkan motivasi dan dorongan dalam belajar, kooperatif, kurang percaya diri, peduli akan hubungan antar individu, sabar, suportif, dan cenderung emosional.",
            text2: "Gaya berpikir ini dapat ditingkatkan dengan memberikan materi pembelajaran berbasis contoh, menggunakan pengingat untuk belajar, memilih panutan yang bercita-cita tinggi sebagai inspirasi, membuat lingkungan yang disiplin secara ketat, dan lebih banyak melakukan demonstrasi."
        },
        {
            img: ref,
            title: "Reflective Learners",
            value: reflective,
            text1: "Memiliki potensi yang tidak terbatas, sangat fleksibel, konservatif, ramah namun cenderung banyak bicara, manajemen waktu dan kedisiplinannya kurang baik, membutuhkan dukungan dan panduan untuk melakukan yang terbaik, dan kurang suka mengambil risiko.",
            text2: "Gaya berpikir ini dapat ditingkatkan dengan menggunakan metode pembelajaran yang disederhanakan, merencanakan kegiatan sehari-hari, membiasakan penegasan positif terhadap diri sendiri, serta menggunakan hadiah dan hukuman untuk mendorong peningkatan dan mencapai tujuan."
        },
        {
            img: rev,
            title: "Reverse Thinking",
            value: reverse,
            text1: "Memiliki kreatifitas yang tinggi, pemikirannya sering kali “out of the box”, senang melakukan sesuatu yang baru dan berbeda, tidak suka berada di keramaian, tidak mengikuti tren dunia, memiliki pemikiran yang ilmiah, mudah penasaran, dan tidak suka aturan.",
            text2: "Gaya berpikir ini dapat ditingkatkan dengan menggunakan psikologi terbalik ketika belajar, memotivasi diri dengan tantangan, membiasakan diri untuk melakukan perencanaan dan manajemen diri, serta menggunakan sistem hadiah dan hukuman atas pencapaian diri sendiri."
        },
    ]

    return(
        <Row className="corev2" style={{gap: '60px'}}>
            {/* <Row className='indradasarList'> */}
            <div className="appearOnMobile tiwtiw" style={{marginBottom: '24px'}}>
                <Link to={{pathname: '/client/reportFP', state: { data: data, package_id: package_id, img_finger:img_finger, shape_finger:shape_finger },}} className="bBek">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M10.295 19.7155C10.3886 19.8077 10.4994 19.8806 10.6211 19.93C10.7429 19.9793 10.8731 20.0042 11.0045 20.0033C11.1358 20.0023 11.2657 19.9754 11.3867 19.9243C11.5076 19.8731 11.6173 19.7986 11.7095 19.705C11.8017 19.6115 11.8746 19.5007 11.9239 19.379C11.9733 19.2572 11.9982 19.127 11.9972 18.9956C11.9963 18.8643 11.9694 18.7344 11.9183 18.6134C11.8671 18.4925 11.7926 18.3827 11.699 18.2906L6.32903 13.0006H19.999C20.2642 13.0006 20.5186 12.8952 20.7061 12.7077C20.8937 12.5201 20.999 12.2658 20.999 12.0006C20.999 11.7353 20.8937 11.481 20.7061 11.2934C20.5186 11.1059 20.2642 11.0006 19.999 11.0006H6.33603L11.7 5.71355C11.884 5.52633 11.987 5.2743 11.9867 5.01183C11.9865 4.74936 11.8831 4.49751 11.6988 4.31061C11.5145 4.12371 11.2642 4.01675 11.0017 4.01281C10.7393 4.00887 10.4858 4.10827 10.296 4.28955L3.37203 11.1116C3.25411 11.2278 3.16048 11.3664 3.09657 11.5192C3.03267 11.672 2.99976 11.8359 2.99976 12.0016C2.99976 12.1672 3.03267 12.3311 3.09657 12.4839C3.16048 12.6367 3.25411 12.7753 3.37203 12.8916L10.296 19.7146L10.295 19.7155Z" fill="#374151"/>
                </svg>
                </Link>
                <p className="antitank" style={{textAlign: 'center'}}>Gaya Berpikir</p>
            </div>

            {listGayaBerpikir.map((item, index) => (
            <div key={index} className='gayaberpikirItemKiri'>
                <img src={item.img}/>
                <div className='itemBox'>
                    <p style={{margin: '0px', fontSize: '32px', fontWeight: '900'}}>{item.title}</p>
                    <p style={{marginBottom: '12px', fontSize: '24px', fontWeight: '900', color: '#05C38A'}}>{item.value}%</p>
                    <p style={{fontSize: '16px'}}>{item.text1}</p>
                    <p style={{fontSize: '16px'}}>{item.text2}</p>
                </div>
            </div>
            ))}



        </Row>
    )
}

export default GayaBerpikir
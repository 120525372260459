import React, { useState, useEffect } from "react";

import SidebarDashboard from "./TemplateDashboard/sidebarDashboard.js";
import HeaderDashboard from "./TemplateDashboard/headerDashboard.js";

import PurchaseClient from "./MenuDashboard/purchase.js";

const ClientPurchase = (e) => {
    console.log(e)
    const title = 'Purchase'
    

    return (

        <div className="dashboardv2">
            <SidebarDashboard title={title} />
            <section className="fullwidth">
                <HeaderDashboard  title={title} gate1={false} gate2={false} menu={""} submenu={""}  />

                <main className="mainv2 pan">

                <PurchaseClient/>

                </main>
            </section>

        </div>
    )

};

export default ClientPurchase
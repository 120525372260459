import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Input, Label, Row, Button, Alert,} from "reactstrap";
import AuthSlider from "../authCarousel";

import logoFutureLink from "../../../assets/images/FutureLink/logoFutureLink.png";

import axios from "axios";
import FormData from "form-data";
import { decodeToken } from "react-jwt";

const CoverSignIn = () => {
  useEffect(() => {
    // if (sessionStorage.getItem("user")) {
    //   sessionStorage.removeItem("user");
    // }
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("profile");
  }, []);

  document.title = "Login | Future Link";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [uniqueCode, setUniqueCode] = useState("");
  const [fade, setFade] = useState("fadeout");
  const [alert, setAlert] = useState(true);
  const [alertContent, setAlertContent] = useState("Check Again");

  const [step, setStep] = useState(0);

  const [show, setShow] = useState(false);

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleUniqueCode = (event) => {
    setUniqueCode(event.target.value);
  };

  const login = () => {
    if (email === "") {
      setAlertContent("Email cannot be empty");
      setAlert(false);

      setTimeout(() => {
        setFade("fade");
      }, 200);

      setTimeout(() => {
        setFade("fadeout");
      }, 4500);

      setTimeout(() => {
        setAlertContent("Check Again");
        setAlert(true);
      }, 5000);
    } else if (email.includes(".") === false || email.includes("@") === false) {
      setAlertContent("Email not found")
      setAlert(false);

      setTimeout(() => {
          setFade('fade')
      }, 200)

      setTimeout(() => {
          setFade('fadeout');
      }, 4500)

      setTimeout(() => {
          setAlert(true);
          setAlertContent("Check Again")
      }, 5000);

    } else {

      if(password === "") {

        setAlertContent("Password cannot be empty");
        setAlert(false);
  
        setTimeout(() => {
          setFade("fade");
        }, 200);
  
        setTimeout(() => {
          setFade("fadeout");
        }, 4500);
  
        setTimeout(() => {
          setAlertContent("Check Again");
          setAlert(true);
        }, 5000);

      }else{

        let data = new FormData();
        data.append("email", email);
        data.append("password", password);
  
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/login`,
          data: data,
        };
  
        axios.request(config)
          .then((response) => {
            const token = response.results.accessToken
            const myDecodedToken = decodeToken(token);
            console.log(myDecodedToken)
            if((myDecodedToken.admin === false && myDecodedToken.privilegeId === 51)){

              let config = {
                  method: 'get',
                  url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-user?userId=${myDecodedToken.userId}`,
                  headers: { 
                    'Authorization': `Bearer ${token}`
                  }
              };
                
              axios(config)
                  .then((response) => {
                      // console.log(response)
                      sessionStorage.setItem("user", token);
                      sessionStorage.setItem("profile", JSON.stringify({fullname: response.results.first_name + " " + response.results.last_name, pp: response.results.profile_image === null ? 'https://imgbb.host/images/cl.png' : response.results.profile_image
                      }))
                      window.location.href = "/client/dashboard";
                  })
                  .catch((error) => {
                      console.log(error);
                  });
            } else {
              sessionStorage.setItem("user", token);
              window.location.href = "/home";
            }
          })
          .catch((error) => {
            if(error.response === undefined){
              setAlertContent("Please call admin")
              setAlert(false);

              setTimeout(() => {
                  setFade('fade')
              }, 200)
  
              setTimeout(() => {
                  setFade('fadeout');
              }, 4500)
  
              setTimeout(() => {
                setAlertContent("Check Again")
                setAlert(true);
              }, 5000);
            }
            if(error.response.data.statusCode === 401) {
              setAlertContent("Im Sorry, Not Found")
              setAlert(false);

              setTimeout(() => {
                  setFade('fade')
              }, 200)
  
              setTimeout(() => {
                  setFade('fadeout');
              }, 4500)
  
              setTimeout(() => {
                setAlertContent("Check Again")
                setAlert(true);
              }, 5000);
            }
            if(error.response.data.statusCode === 422) {
              setAlertContent("Im Sorry, Format wrong")
              setAlert(false);
  
              setTimeout(() => {
                  setFade('fade')
              }, 200)
  
              setTimeout(() => {
                  setFade('fadeout');
              }, 4500)
  
              setTimeout(() => {
                setAlertContent("Check Again")
                setAlert(true);
              }, 5000);
            }
          });

      }

    }
  };

  const loginUniqueCode = () => {
    if (uniqueCode === "") {
      setAlertContent("Unique Code cannot be empty");
      setAlert(false);

      setTimeout(() => {
        setFade("fade");
      }, 200);

      setTimeout(() => {
        setFade("fadeout");
      }, 4500);

      setTimeout(() => {
        setAlert(true);
      }, 5000);
    } else {
      let data = new FormData();
      data.append("uniqueCode", uniqueCode);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/login-unique-code`,
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response);

          if (response.statusCode === 200) {
            const token = response.results.accessToken
            const myDecodedToken = decodeToken(token);
            console.log(myDecodedToken)
            if((myDecodedToken.admin === false && myDecodedToken.privilegeId === 51)){

              let config = {
                  method: 'get',
                  url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-user?userId=${myDecodedToken.userId}`,
                  headers: { 
                    'Authorization': `Bearer ${token}`
                  }
              };
                
              axios(config)
                  .then((response) => {
                      // console.log(response)
                      sessionStorage.setItem("user", token);
                      sessionStorage.setItem("profile", JSON.stringify({fullname: response.results.first_name + " " + response.results.last_name, pp: response.results.profile_image === null ? 'https://imgbb.host/images/cl.png' : response.results.profile_image
                      }))
                      window.location.href = "/client/dashboard";
                  })
                  .catch((error) => {
                      console.log(error);
                  });
            }
          }
        })
        .catch((error) => {
          if(error.response === undefined) {

            setAlertContent("Please call admin");

            setAlert(false);
  
            setTimeout(() => {
              setFade("fade");
            }, 200);
  
            setTimeout(() => {
              setFade("fadeout");
            }, 4500);
  
            setTimeout(() => {
              setAlert(true);
            }, 5000);

          } else {
            setAlertContent("Unique Code not found");

            setAlert(false);
  
            setTimeout(() => {
              setFade("fade");
            }, 200);
  
            setTimeout(() => {
              setFade("fadeout");
            }, 4500);
  
            setTimeout(() => {
              setAlert(true);
            }, 5000);
          }


        });
    }
  };

  return (
    <React.Fragment>
      <Row
        className="fontJakarta"
        style={{
          backgroundColor: "white",
          width: "100%",
          height: "100vh",
          margin: "0",
        }}
      >
        <AuthSlider />

        <Col
          lg={6}
          style={{
            paddingRight: "0",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ marginBottom: "50px", padding: "0px 25px" }}>
            <img src={logoFutureLink} fetchPriority="high" alt="Logo Future Link" title="Logo Future Link" loading="eager" width={215} height={95}/>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              padding: "0px 100px",
            }}
          >
            <div>
              <p style={{ fontSize: "34px", fontWeight: "600", margin: "0" }}>
                Login
              </p>
              <p style={{ fontSize: "16px" }} className="text-muted">
                Login to continue to Future Link.
              </p>
            </div>
            {step === 0 && (
              <>
                <div className="mt-4">
                  <Alert className={"alertRed " + fade} hidden={alert}>
                    {alertContent}
                  </Alert>
                  <div className="mb-3">
                    <Label htmlFor="email" className="form-label">
                      Email
                    </Label>
                    <Input
                      value={email}
                      onChange={handleEmail}
                      type="text"
                      className="form-control"
                      id="email"
                      placeholder="Enter email"
                      required
                      autoComplete="off"
                      onKeyPress={(event) => {
                        if (event.charCode === 13) {
                          step === 0 ? login() : loginUniqueCode();
                        }
                      }}
                    />
                  </div>

                  <div className="mb-3">
                    <div className="float-end">
                      <Link to="/forgot-password" className="text-muted">
                        Forgot password?
                      </Link>
                    </div>
                    <Label className="form-label" htmlFor="password-input" autoComplete="off">
                      Password
                    </Label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                      <Input
                        value={password}
                        onChange={handlePassword}
                        type={show === false ? "password" : "text"}
                        className="form-control pe-5"
                        placeholder="Enter password"
                        id="password-input"
                        required
                        onKeyPress={(event) => {
                          if (event.charCode === 13) {
                            step === 0 ? login() : loginUniqueCode();
                          }
                        }}
                      />
                      <button
                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                        type="button"
                        onClick={() => {
                          setShow(!show);
                        }}
                      >
                        <i className="ri-eye-fill align-middle"></i>
                      </button>
                    </div>
                  </div>
                  <div className="mt-4">
                    <Button
                      style={{
                        backgroundColor: "#1F599C",
                        borderColor: "#1F599C",
                        marginBottom: "10px",
                      }}
                      color="success"
                      className="w-100"
                      onClick={() => {
                        login();
                      }}
                    >
                      Login Testing HEHE
                    </Button>
                    <Button
                      className="w-100 buttonVersFutureLinkBorderGrey"
                      onClick={() => {
                        setStep(1);
                        setUniqueCode("");
                      }}
                    >
                      Login with Unique Code
                    </Button>
                  </div>
                </div>
              </>
            )}
            {step === 1 && (
              <>
                <div className="mt-4">
                  <Alert className={"alertRed " + fade} hidden={alert}>
                    {alertContent}
                  </Alert>
                  <div className="mb-3">
                    <Label htmlFor="uniqueCode" className="form-label" autoComplete="off">
                      Unique Code
                    </Label>
                    <Input
                      value={uniqueCode}
                      onChange={handleUniqueCode}
                      type="text"
                      className="form-control"
                      id="uniqueCode"
                      placeholder="Enter Unique Code"
                      required
                      onKeyPress={(event) => {
                        if (event.charCode === 13) {
                          step === 0 ? login() : loginUniqueCode();
                        }
                      }}
                    />
                  </div>
                  <div className="mt-4">
                    <Button
                      style={{
                        backgroundColor: "#1F599C",
                        borderColor: "#1F599C",
                        marginBottom: "10px",
                      }}
                      color="success"
                      className="w-100"
                      onClick={loginUniqueCode}
                    >
                      Login with Unique Code
                    </Button>
                    <Button
                      className="w-100 buttonVersFutureLinkBorderGrey"
                      onClick={() => {
                        setStep(0);
                        setEmail("");
                        setPassword("");
                      }}
                    >
                      Login with Email
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CoverSignIn;

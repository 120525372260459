import React, {useState} from "react";
import {Row, Col, Progress} from 'reactstrap';
import {Link} from 'react-router-dom';

import whorl from '../../../../../assets/images/FutureLink/whorl.svg'
import loop from '../../../../../assets/images/FutureLink/loop.svg'
import arc from '../../../../../assets/images/FutureLink/arc.svg'

const Psj = (e) => {
    // console.log("Hasil Akhir", e)
    const state = e.state.data
    const data = e.state.data
    const package_id = e.state.package_id
    const img_finger = e.state.img_finger
    const shape_finger = e.state.shape_finger


    const array = [
    {
        title: "R1",
        img: img_finger[0],
        value: state.intrapersonal,
        sign: shape_finger[0],
    },
    {
        title: "L1",
        img: img_finger[1],
        value: state.interpersonal,
        sign: shape_finger[1],
    },
    {
        title: "R2",
        img: img_finger[2],
        value: state.logical,
        sign: shape_finger[2],
    },
    {
        title: "L2",
        img: img_finger[3],
        value: state.imagination,
        sign: shape_finger[3],
    },
    {
        title: "R3",
        img: img_finger[4],
        value: state.fine_motors,
        sign: shape_finger[4],
    },
    {
        title: "L3",
        img: img_finger[5],
        value: state.gross_motors,
        sign: shape_finger[5],
    },
    {
        title: "R4",
        img: img_finger[6],
        value: state.lingustic,
        sign: shape_finger[6],
    },
    {
        title: "L4",
        img: img_finger[7],
        value: state.musical,
        sign: shape_finger[7],
    },
    {
        title: "R5",
        img: img_finger[8],
        value: state.naturalist,
        sign: shape_finger[8],
    },
    {
        title: "L5",
        img: img_finger[9],
        value: state.visual_spatial,
        sign: shape_finger[9],
    }]
    console.log(array)
    const uwu = ["Action", "Think", "Tactile", "Auditory", "Visual"]
    const fingerType = [
        {
          name: "Tidak Diketahui",
          value: "nothing",
        },
        {
          name: "Whorl Target",
          value: "W_centric",
          nick: "WT",
          ket: "Whorl",
        },
        {
          name: "Whorl Spiral",
          value: "W_spiral",
          nick: "WS",
          ket: "Whorl",
        },
        {
          name: "Whorl Elongated Centric",
          value: "W_elongated_centric",
          nick: "WTE",
          ket: "Whorl",
        },
        {
          name: "Whorl Elongated Spiral",
          value: "W_elongated_spiral",
          nick: "WSE",
          ket: "Whorl",
        },
        {
          name: "Whorl Imploding",
          value: "W_imploding",
          nick: "WI",
          ket: "Whorl",
        },
        {
          name: "Whorl Composite",
          value: "W_composite",
          nick: "WC",
          ket: "Whorl",
        },
        {
          name: "Whorl Target Ulnar Peacock Eye",
          value: "W_peacock_centric_ulnar",
          nick: "WTP",
          ket: "Whorl",
        },
        {
          name: "Whorl Spiral Ulnar Peacock Eye",
          value: "W_peacock_spiral_ulnar",
          nick: "WSP",
          ket: "Whorl",
        },
        {
          name: "Whorl Target Radial Peacock Eye",
          value: "W_peacock_centric_radial",
          nick: "RWTP",
          ket: "Whorl",
        },
        {
          name: "Whorl Spiral Radial Peacock Eye",
          value: "W_peacock_spiral_radial",
          nick: "RWSP",
          ket: "Whorl",
        },
        {
          name: "Whorl Double Loop",
          value: "L_double",
          nick: "WDL",
          ket: "Whorl",
        },
        {
          name: "Ulnar Loop",
          value: "L_ulnar",
          nick: "L",
          ket: "Loop",
        },
        {
          name: "Radial Loop",
          value: "L_radial",
          nick: "RL",
          ket: "Loop",
        },
        {
          name: "Ulnar Falling Loop",
          value: "L_fallen_ulnar",
          nick: "LW",
          ket: "Loop",
        },
        {
          name: "Radial Falling Loop",
          value: "L_fallen_radial",
          nick: "RLW",
          ket: "Loop",
        },
        {
          name: "Plain Arch",
          value: "A_simple",
          nick: "AS",
          ket: "Arch",
        },
        {
          name: "Tented Arch",
          value: "A_tented",
          nick: "TA",
          ket: "Arch",
        },
        {
          name: "Tented Arch with Ulnar Loop",
          value: "A_w_L_ulnar",
          nick: "LAS",
          ket: "Loop",
        },
        {
          name: "Tented Arch with Radial Loop",
          value: "A_w_L_radial",
          nick: "RLAS",
          ket: "Loop",
        },
        {
          name: "Accidental Whorl",
          value: "accidental",
          nick: "WX",
          ket: "Whorl",
        },
    ];



    const findType = shape_finger.reduce(function(acc, nilai) {
      // Cari nilai yang sesuai dalam array2
      var item = fingerType.find(function(item) {
        return item.nick === nilai;
      });

      // Tambahkan nilai ke array1
      acc.push(item.ket);

      // Kembalikan accumulator
      return acc;
    }, []);


    const findDominance = (myArray) =>
      myArray.reduce(
        (a,b,i,arr)=>
        (arr.filter(v=>v===a).length>=arr.filter(v=>v===b).length?a:b),
        null)

    const dominan = findDominance(findType)


    return(
        <Row className="corev2 polaris">
            <div className="appearOnMobile tiwtiw">
                <Link to={{pathname: '/client/reportFP', state: { data: data, package_id: package_id, img_finger:img_finger, shape_finger:shape_finger },}} className="bBek">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M10.295 19.7155C10.3886 19.8077 10.4994 19.8806 10.6211 19.93C10.7429 19.9793 10.8731 20.0042 11.0045 20.0033C11.1358 20.0023 11.2657 19.9754 11.3867 19.9243C11.5076 19.8731 11.6173 19.7986 11.7095 19.705C11.8017 19.6115 11.8746 19.5007 11.9239 19.379C11.9733 19.2572 11.9982 19.127 11.9972 18.9956C11.9963 18.8643 11.9694 18.7344 11.9183 18.6134C11.8671 18.4925 11.7926 18.3827 11.699 18.2906L6.32903 13.0006H19.999C20.2642 13.0006 20.5186 12.8952 20.7061 12.7077C20.8937 12.5201 20.999 12.2658 20.999 12.0006C20.999 11.7353 20.8937 11.481 20.7061 11.2934C20.5186 11.1059 20.2642 11.0006 19.999 11.0006H6.33603L11.7 5.71355C11.884 5.52633 11.987 5.2743 11.9867 5.01183C11.9865 4.74936 11.8831 4.49751 11.6988 4.31061C11.5145 4.12371 11.2642 4.01675 11.0017 4.01281C10.7393 4.00887 10.4858 4.10827 10.296 4.28955L3.37203 11.1116C3.25411 11.2278 3.16048 11.3664 3.09657 11.5192C3.03267 11.672 2.99976 11.8359 2.99976 12.0016C2.99976 12.1672 3.03267 12.3311 3.09657 12.4839C3.16048 12.6367 3.25411 12.7753 3.37203 12.8916L10.296 19.7146L10.295 19.7155Z" fill="#374151"/>
                </svg>
                </Link>
                <p className="antitank" style={{textAlign: 'center'}}>Hasil Pola Sidik Jari</p>
            </div>
            <Col className="polaLeft">
                <div className="polaW">
                    <div className="polaList">
                        {array.map((item, index) => (

                        <div key={index} className="polasidikjariItem">
                            <div className="polasidikjariBoxImg">
                            <p style={{margin: "0px", fontSize: "16px", fontWeight: "700", position: "absolute", color: 'white'}}>
                                {item.title}
                            </p>
                            <img src={item.img} className="polasImg" />
                            </div>
                            <Progress className="polaprog animated-progess progress-label"
                                value={item.value < 4
                                  ? item.value * 8
                                  : item.value < 6
                                  ? item.value * 7
                                  : item.value < 10
                                  ? item.value * 6
                                  : item.value * 5.5
                              }
                                color={item.value < 8
                                  ? "red"
                                  : item.value > 12
                                  ? "green"
                                  : "yellow"
                              }
                                >
                                <p style={{ margin: "0px", color: "white", fontSize: "14px", fontWeight: "700"}}>
                                    {item.sign}
                                </p>
                                <div className="label">{item.value}%</div>
                            </Progress>
                        </div>

                        ))}

                    </div>
                    <div className="polaLine goneOnMobile">
                        {uwu.map((item, index) => (
                        <div key={index} className="polaLineItem">
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="147" viewBox="0 0 21 147" fill="none">
                            <path d="M0 1H20V146H0" stroke="black" strokeWidth="0.7"/>
                            </svg>
                            <p className="antitank bold sizeWeight">{item}</p>
                        </div>
                        ))}

                    </div>
                </div>
                <div className="pK goneOnMobile">
                    <div className="plKt">
                        <p className="antitank bold">Keterangan</p>
                        <div className="polKet">
                            <div className="hijauw"></div>
                            <p className="antitank bold">Diatas rata-rata</p>
                        </div>
                        <div className="polKet">
                            <div className="kuningw"></div>
                            <p className="antitank bold">Rata-rata</p>
                        </div>
                        <div className="polKet">
                            <div className="merahw"></div>
                            <p className="antitank bold">Dibawah rata-rata</p>
                        </div>
                    </div>
                </div>
            </Col>
            <Col className="polaRight">
                <h2 style={{marginBottom: '40px', marginLeft: '30px'}}> Bentuk Dominan Sidik Jarimu </h2>
                <div className="polTB">
                  {dominan === 'Whorl' && (
                    <>
                    <div className="polT">
                        <div>
                            <img src={whorl} />
                        </div>
                        <div>
                            <h2>Whorl</h2>
                            <ul>
                                <li>Emosi: tidak stabil, meledak-ledak, sensitif</li>
                                <li>Karakter: dominan, suka memimpin, suka menjadi pusat perhatian</li>
                                <li>Kebiasaan: tidak bisa diam, mudah bosan.</li>
                                <li>Kelebihan: bisa menjadi pemimpin, berpendirian teguh, sosok yang kuat, sangat bertanggung jawab, mandiri, gesit ketika bertindak, serta dapat menganalisa situasi dengan cermat</li>
                                <li>Kekurangan: pemikir, terobsesi pada kekuasaan, sukar menyesuaikan diri, memiliki egosentris yang tinggi, kaku, dan memiliki kontrol emosi yang buruk.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="polB">
                        <div className="pU">
                            <div>
                                <img src={loop} />
                            </div>
                            <div>
                                <h2>Loop</h2>
                                <p className="antitank">Karakter yang memiliki rasa empati tinggi, ramah, optimis, bertanggung jawab dan pekerja keras. Namun tidak menyukai aturan yang keras dan bergantung pada orang lain.</p>
                            </div>
                        </div>
                        <div className="polLi"></div>
                        <div className="pU">
                            <div>
                                <img src={arc} />
                            </div>
                            <div>
                                <h2>Arch</h2>
                                <p className="antitank">Karakter yang memiliki kesederhanaan, praktis, selalu bersemangat dan tidak mudah menyerah. Namun sulit mengekspresikan perasaan, sulit beradaptasi, dan kurang mampu menerima pendapat orang lain.</p>
                            </div>
                        </div>
                    </div>
                    </>
                  )}
                  {dominan === 'Loop' && (
                    <>
                    <div className="polT">
                        <div>
                            <img src={loop} />
                        </div>
                        <div>
                            <h2>Loop</h2>
                            <ul>
                                <li>Emosi: ramah, optimis, terbuka</li>
                                <li>Karakter: simpatinya tinggi, tulus, optimis, kurang inisiatif</li>
                                <li>Kebiasaan: rajin dan bertanggung jawab, pekerja keras</li>
                                <li>Kelebihan: memiliki empati tinggi, senang, membantu orang lain, tulus, rajin, dan bertanggung jawab, terbuka, ramah, murah senyum, optimis, dan pekerja keras.</li>
                                <li>Kekurangan: kurang memiliki inisiatif, tidak menyukai aturan yang keras, tidak bisa berdiam diri, dan bergantung pada orang lain</li>
                            </ul>
                        </div>
                    </div>
                    <div className="polB">
                        <div className="pU">
                            <div>
                                <img src={whorl} />
                            </div>
                            <div>
                                <h2>Whorl</h2>
                                <p className="antitank">Karakter yang memiliki jiwa kepemimpinan, mandiri, suka menjadi pusat perhatian dan gesit ketika bertindak. Namun emosinya meledak-ledak, sensitif, dan tidak bsia diam</p>
                            </div>
                        </div>
                        <div className="polLi"></div>
                        <div className="pU">
                            <div>
                                <img src={arc} />
                            </div>
                            <div>
                                <h2>Arch</h2>
                                <p className="antitank">Karakter yang memiliki kesederhanaan, praktis, selalu bersemangat dan tidak mudah menyerah. Namun sulit mengekspresikan perasaan, sulit beradaptasi, dan kurang mampu menerima pendapat orang lain.</p>
                            </div>
                        </div>
                    </div>
                    </>
                  )}
                  {dominan === 'Arch' && (
                    <>
                    <div className="polT">
                        <div>
                            <img src={arc} />
                        </div>
                        <div>
                            <h2>Loop</h2>
                            <ul>
                                <li>Emosi: sabar, sulit mengekspresikan perasaan</li>
                                <li>Karakter: sederhana, praktis, sulit beradaptasi</li>
                                <li>Kebiasaan: implusive, selalu bersemangat</li>
                                <li>Kelebihan: praktis, berkepala dingin, bersahaja, selalu bersemangat, memiliki kepercayaan diri yang tinggi, tegas, dan tidak mudah menyerah.</li>
                                <li>Kekurangan: berkepala dingin, sukar mengutarakan perasaan, impulsive, kurang mampu menerima pendapat orang lain, dan sulit beradaptasi.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="polB">
                        <div className="pU">
                            <div>
                                <img src={whorl} />
                            </div>
                            <div>
                                <h2>Whorl</h2>
                                <p className="antitank">Karakter yang memiliki jiwa kepemimpinan, mandiri, suka menjadi pusat perhatian dan gesit ketika bertindak. Namun emosinya meledak-ledak, sensitif, dan tidak bsia diam</p>
                            </div>
                        </div>
                        <div className="polLi"></div>
                        <div className="pU">
                            <div>
                                <img src={loop} />
                            </div>
                            <div>
                                <h2>Arch</h2>
                                <p className="antitank">Karakter yang memliki rasa empati tinggi, ramah, optimis, bertanggung jawab dan pekerja keras. Namun tidak menyukai aturan yang keras dan bergantung pada orang lain.</p>
                            </div>
                        </div>
                    </div>
                    </>
                  )}

                </div>
            </Col>
            
        </Row>
    )
}

export default Psj

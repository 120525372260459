import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import DOMPurify from "dompurify";

import logo from '../../../../assets/images/FutureLink/logoFutureLink.png'

const ReadArticle = () => {
    let { token } = useParams();
    const [judul, setJudul] = useState("");
    const [author, setAuthor] = useState("");
    const [cover, setCover] = useState("");
    const [content, setContent] = useState("");


    useEffect(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-article-by-id?articleId=${token}`,
          };
          
          axios(config)
          .then((response) => {
            // console.log(response.results[0]);
            setJudul(response.results[0].article_title);
            setAuthor(response.results[0].author);
            setCover(response.results[0].linkMinio);
            setContent(response.results[0].article_content);
          })
          .catch((error) => {
            // console.log(error);
          });
    },[])

    return(
        <React.Fragment>
            <header style={{margin: '25px 0px'}}>
                <img src={logo} style={{width: '160px', marginLeft: '15px'}}/>
            </header>
            <body className="readArticle">
                <h1>{judul}</h1>
                <h5>Author : <em>{author}</em></h5>
                <img src={cover}/>
                <div style={{textAlign: 'justify', margin: '30px 0px', fontSize: '18px'}} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(content)}}>
                </div>
            </body>

        </React.Fragment>
    );
};

export default ReadArticle;
import React, {useState} from "react";
import {Row, Col, Progress} from 'reactstrap';
import {Link} from 'react-router-dom';

import lobus from '../../../../../assets/images/FutureLink/lobus.png';

const LobusOtak = (e) => {
    const state = e.state.data
    const data = e.state.data
    const package_id = e.state.package_id
    const img_finger = e.state.img_finger
    const shape_finger = e.state.shape_finger

    return(
        <div className="corev2 dom" style={{height: '90vh'}}>
            <div className="appearOnMobile tiwtiw" style={{marginBottom: '24px'}}>
                <Link to={{pathname: '/client/reportFP', state: { data: data, package_id: package_id, img_finger:img_finger, shape_finger:shape_finger },}} className="bBek">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M10.295 19.7155C10.3886 19.8077 10.4994 19.8806 10.6211 19.93C10.7429 19.9793 10.8731 20.0042 11.0045 20.0033C11.1358 20.0023 11.2657 19.9754 11.3867 19.9243C11.5076 19.8731 11.6173 19.7986 11.7095 19.705C11.8017 19.6115 11.8746 19.5007 11.9239 19.379C11.9733 19.2572 11.9982 19.127 11.9972 18.9956C11.9963 18.8643 11.9694 18.7344 11.9183 18.6134C11.8671 18.4925 11.7926 18.3827 11.699 18.2906L6.32903 13.0006H19.999C20.2642 13.0006 20.5186 12.8952 20.7061 12.7077C20.8937 12.5201 20.999 12.2658 20.999 12.0006C20.999 11.7353 20.8937 11.481 20.7061 11.2934C20.5186 11.1059 20.2642 11.0006 19.999 11.0006H6.33603L11.7 5.71355C11.884 5.52633 11.987 5.2743 11.9867 5.01183C11.9865 4.74936 11.8831 4.49751 11.6988 4.31061C11.5145 4.12371 11.2642 4.01675 11.0017 4.01281C10.7393 4.00887 10.4858 4.10827 10.296 4.28955L3.37203 11.1116C3.25411 11.2278 3.16048 11.3664 3.09657 11.5192C3.03267 11.672 2.99976 11.8359 2.99976 12.0016C2.99976 12.1672 3.03267 12.3311 3.09657 12.4839C3.16048 12.6367 3.25411 12.7753 3.37203 12.8916L10.296 19.7146L10.295 19.7155Z" fill="#374151"/>
                </svg>
                </Link>
                <p className="antitank" style={{textAlign: 'center'}}>Lobus Otak</p>
            </div>

            <div className='lobusotak' >
                <div className='lobusotakGraph'>
                    <div className='lobusotakKiri'>
                        <div className='lobusotakKiriText'>
                            <p className='kiriText1'>LOBUS PREFRONTAL</p>
                            <p className='kiriText2'>{data.action}%</p>
                        </div>
                        <div className='lobusotakKiriLine'>
                            <div className='rounds' ></div>
                            <div className='lineLobusPrefrontal'></div>
                        </div>
                    </div>
                    <div className='lobusotakTengah'>
                        <div className='lobusotakAtas'>
                            <div className='lobusotakAtas1'>
                                <p className='atas1Text1'>{data.think}%</p>
                                <p className='atas1Text2'>LOBUS FRONTAL</p>
                                <div className='lobusotakAtas1Line'>
                                    <div className='rounds'></div>
                                    <div className='lineLobusFrontal'></div>
                                </div>
                            </div>
                            <div className='lobusotakAtas2'>
                                <p className='atas2Text1'>{data.tactile}%</p>
                                <p className='atas2Text2'>LOBUS PARIETAL</p>
                                <div className='lobusotakAtas2Line'>
                                    <div className='rounds'></div>
                                    <div style={{width: '2px', height: '30px', backgroundColor: '#6E9B00'}}></div>
                                </div>

                            </div>
                        </div>
                        <img src={lobus} className='lobusotakImg'/>
                        <div style={{position: 'relative', display: 'flex'}}>
                            <div className='lobusotakBawah'>
                                <div className='lobusotakBawahLine'>
                                    <div className='lineLobusTemporal'></div>
                                    <div className='rounds'></div>
                                </div>
                                <p className='bawahText1'>LOBUS TEMPORAL</p>
                                <p className='bawahText2'>{data.auditory}%</p>
                            </div>
                        </div>

                    </div>
                    <div className='lobusotakKanan'>
                        <div className='lobusotakKananLine'>
                            <div className='lineLobusOksipital'></div>
                            <div className='rounds'></div>
                        </div>
                        <div className='lobusotakKananText'>
                            <p className='kananText1'>LOBUS OKSIPITAL</p>
                            <p className='kananText2'>{data.visual}%</p>
                        </div>
                    </div>
                </div>

                <div className='lobusotakContent' style={{paddingBottom: "128px"}}>
                    <p style={{fontSize: '16px', textAlign: 'justify'}}>Para ahli neouroscience percaya bahwa perkembangan akhir struktur otak adalah neo-cortex. Korteks serebral otak adalah lapisan terluar yang memberikan karakteristik penampilan keriput pada otak. Korteks serebral dibagi memanjang menjadi dua belahan otak yang dihubungkan oleh saraf yang disebut Corpus Callosum. Secara tradisional, masing-masing belahan telah dibagi menjadi lima bagian yang disebut lobus, yaitu lobus prefrontal, lobus frontal, lobus temporal, lobus parietal, dan lobus oksipital. Lobus ini berfungsi mengendalikan gerakan, ucapan, perilaku, memori, emosi, kepribadian, dan berperan dalam fungsi intelektual, seperti proses berpikir, penalaran, pemecahan masalah, pengambilan keputusan, dan perencanaan. Namun setiap lobus otak besar memiliki fungsinya masing-masing.</p>

                    <div>
                        <div style={{display: 'flex'}}>
                            <p style={{fontSize: '24px', fontWeight: '900', color: '#6431B1', width: '250px'}}>Lobus Prefrontal</p>
                            <p style={{fontSize: '24px', fontWeight: '900', color: '#6431B1'}}>{data.action}%</p>
                        </div>

                        <p style={{fontSize: '16px', textAlign: 'justify'}}><span style={{fontWeight: '900'}}>Lobus prefrontal</span> bertanggung jawab untuk menganalisis, membayangkan, memvisualisasikan, menilai, dan berpikir kreatif. Mengembangkan pendekatan yang mandiri terhadap pekerjaan dapat membantu meningkatkan kinerja lobus ini. Selain itu mengatur dan membuat prioritas, mempelajari penggunaan strategi, menghafal informasi dengan menggunakan akronim, dan meningkatkan fleksibilitas kognitif juga dapat membantu peningkatan lobus ini.
                        </p>
                        <p style={{fontSize: '16px', textAlign: 'justify', margin: '0px'}}>Lobus ini berperan penting dalam Kemampuan Kognitif dan Kepribadian.</p>
                        <ul style={{fontSize: '16px', textAlign: 'justify', margin: '0px'}}>
                            <li>Lobus prefrontal pada otak sebelah kiri berperan dalam merencanakan, mengelola, berkomunikasi, melakukan koordinasi, serta mengendalikan perilaku dan emosi.</li>
                            <li>Lobus prefrontal pada otak sebelah kanan berperan dalam kreatifitas, interpersonal dan keterampilan kepemimpinan, biasanya dimotivasi oleh tujuan, intuisi dan visi.</li>
                        </ul>
                    </div>
                    <div style={{marginTop: '10px'}}>
                        <div style={{display: 'flex'}}>
                            <p style={{fontSize: '24px', fontWeight: '900', color: '#7129AA', width: '250px'}}>Lobus Frontal</p>
                            <p style={{fontSize: '24px', fontWeight: '900', color: '#7129AA'}}>{data.think}%</p>
                        </div>

                        <p style={{fontSize: '16px', textAlign: 'justify'}}><span style={{fontWeight: '900'}}>Lobus frontal </span>bertanggung jawab dalam mengontrol impuls, mengambil keputusan, merencanakan sesuatu, dan konsentrasi terkait koordinasi dan perilaku setiap orang. Mengerjakan ide kreatif, melakukan aktivitas fisik, bermeditasi pada konsep tertentu sepeti empati, fokus pada proses sosialisasi, serta memberi afirmasi positif dapat meningkatkan kinerja lobus ini. Selain itu melakukan permainan latihan otak dan ingatan seperti teka-teki silang dan permainan asah otak juga dapat meningkatkan daya pikir.
                        </p>
                        <p style={{fontSize: '16px', textAlign: 'justify', margin: '0px'}}>Lobus ini berperan penting dalam Kemampuan Berpikir dan Imajinatif. </p>
                        <ul style={{fontSize: '16px', textAlign: 'justify', margin: '0px'}}>
                            <li>Lobus frontal pada otak sebelah kiri berperan dalam berpikir secara logis, kemampuan bahasa, pemecahan masalah, proses komputasi dan rasionalitas.</li>
                            <li>Lobus frontal pada otak sebelah kanan berperan dalam konsep artistik, imajinasi spasial visual, pembentukan ide dan konseptualisasi ide.</li>
                        </ul>
                    </div>
                    <div style={{marginTop: '10px'}}>
                        <div style={{display: 'flex'}}>
                            <p style={{fontSize: '24px', fontWeight: '900', color: '#6E9B00', width: '250px'}}>Lobus Parietal</p>
                            <p style={{fontSize: '24px', fontWeight: '900', color: '#6E9B00'}}>{data.tactile}%</p>
                        </div>

                        <p style={{fontSize: '16px', textAlign: 'justify'}}><span style={{fontWeight: '900'}}>Lobus parietal</span> bertanggung jawab untuk membantu mengendalikan berbagai aktivitas, memproses dan mengkoordinasikan informasi sensorik yang ditunjukkan melalui gerakan, perbuatan, dan sentuhan. Menyalurkan arah gerakan dengan pendelegasian tugas, belajar sambil bergerak, melakukan kunjungan lapangan untuk pembelajaran, melakukan pembelajaran dalam bentuk percobaan dan mengeksplorasi aktivitas yang dapat mendukung pembelajaran dapat meningkatkan kinerja lobus ini. Selain itu penggunaan sempoa, garis bilangan. pemodelan tanah liat, dan lain sebagainya untuk media pembelajaran juga dapat meningkatkan kinerja lobus ini.</p>
                        <p style={{fontSize: '16px', textAlign: 'justify', margin: '0px'}}>Lobus ini berperan penting dalam Kemampuan Kinestetik. </p>
                        <ul style={{fontSize: '16px', textAlign: 'justify', margin: '0px'}}>
                            <li>Lobus parietal pada otak sebelah kiri berperan dalam diferensiasi gerakan, kemampuan mengendalikan gerakan motorik halus dan pemahaman pengoperasian.</li>
                            <li>Lobus parietal pada otak sebelah kanan berperan dalam mengontrol kemampuan motorik kasar, mobilitas tubuh, gerakan berirama, melakukan koordinasi otot dan apresiasi fisik.</li>
                        </ul>
                    </div>
                    <div style={{marginTop: '10px'}}>
                        <div style={{display: 'flex'}}>
                            <p style={{fontSize: '24px', fontWeight: '900', color: '#3939AA', width: '250px'}}>Lobus Oksipital</p>
                            <p style={{fontSize: '24px', fontWeight: '900', color: '#3939AA'}}>{data.visual}%</p>
                        </div>

                        <p style={{fontSize: '16px', textAlign: 'justify'}}><span style={{fontWeight: '900'}}>Lobus oksipital</span> bertanggung jawab untuk membantu dalam pemrosesan dan interpretasi visual, membantu memahami bagan atau grafik, memanipulasi dan menafsirkan gambar visual. Menggunakan diagram alir atau peta pikiran, memvisualisasikan ejaan kata-kata, menggunakan kode warna untuk menyorot poin penting dan mengatur catatan, serta menggunakan representasi bergambar dapat membantu meningkatkan kinerja lobus ini. Selain itu, belajar di meja yang rapi dan latar belakang yang tenang juga dapat membantu peningkatan lobus ini.</p>
                        <p style={{fontSize: '16px', textAlign: 'justify', margin: '0px'}}>Lobus ini berperan penting dalam Kemampuan Pemrosesan Visual. </p>
                        <ul style={{fontSize: '16px', textAlign: 'justify', margin: '0px'}}>
                            <li>Lobus oksipital pada otak sebelah kiri berperan dalam mengidentifikasi visual, observasi, membaca dan kemampuan pemahaman.</li>
                            <li>Lobus oksipital pada otak sebelah kanan berperan dalam menentukan pola abstrak, visual, grafik, dan gambar.</li>
                        </ul>
                    </div>
                    <div style={{marginTop: '10px'}}>
                        <div style={{display: 'flex'}}>
                            <p style={{fontSize: '24px', fontWeight: '900', color: '#0966DA', width: '250px'}}>Lobus Temporal</p>
                            <p style={{fontSize: '24px', fontWeight: '900', color: '#0966DA'}}>{data.auditory}%</p>
                        </div>

                        <p style={{fontSize: '16px', textAlign: 'justify'}}><span style={{fontWeight: '900'}}>Lobus temporal </span> bertanggung jawab untuk memahami musik dan merespon emosi, menghasilkan keterampilan pendengaran dan berbicara, serta membantu berpikir dengan kata-kata. Merekam dan memutar ulang pembelajaran, melakukan diskusi, menggunakan metode bercerita atau asosiasi dalam pembelajaran, membaca dengan keras dan menggunakan pengulangan kata berima akan meningkatkan kinerja lobus ini. Selain itu membuat jingle musik juga dapat membantu peningkatan lobus ini.</p>
                        <p style={{fontSize: '16px', textAlign: 'justify', margin: '0px'}}>Lobus ini berperan penting dalam Kemampuan Pemrosesan Suara. </p>
                        <ul style={{fontSize: '16px', textAlign: 'justify', margin: '0px'}}>
                            <li>Lobus temporal pada otak sebelah kiri berperan dalam diferensiasi fonetik, pemahaman bahasa dan identifikasi suara.</li>
                            <li>Lobus temporal pada otak sebelah kanan berperan dalam mengolah dan mengapresiasi melodi dan musik.</li>
                        </ul>
                    </div>
                    
                </div>
            </div>

        
        </div>
    )
}

export default LobusOtak
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import fingerprintReport from "../../../../../assets/images/FutureLink/fingerprintReport.png";
import SPMReport from "../../../../../assets/images/FutureLink/SPMReport.png";

const CardBox = (props) => {
  return (
    <div className="grid-item">
      <div className="card">
        <div className="card-title">
          <h4 style={{ color: "white" }}>{props.title}</h4>
        </div>
        <img src={props.img} className="img-avail" />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <Link
            className="buttonVersFutureLinkOrange"
            to={{
              pathname: `${props.pathname}`,
            }}
          >
            Lihat
          </Link>
        </div>
      </div>
    </div>
  );
};

const Report = () => {
  let { token } = useParams();

  useEffect(() => {
    getAvailability();
  }, []);

  const [report, setReport] = useState();
  const [test, setTest] = useState();
  const [expired, setExpired] = useState(false);

  const getAvailability = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-report-availability`,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        let res = response.results;
        setReport(!!res.exists_report);
        setTest(!!res.exists_test);
      })
      .catch((error) => {
        console.log("err", error);
        setExpired(true);
      });
  };

  if (expired) {
    return <p>token expired</p>;
  } else {
    return (
      <div className="grid-container-avail">
        {report && (
          <CardBox
            title="Laporan Sidik Jari"
            img={fingerprintReport}
            pathname={`/report-mobile/${token}`}
          />
        )}
        {/* {test && (
          <CardBox
            title="Laporan SPM"
            img={SPMReport}
            pathname="/client/reportSPM"
          />
        )} */}
      </div>
    );
  }
};

export default Report;

import React, {useEffect, useState} from 'react';
import Lottie from 'react-lottie';

import paymentSuccess from '../../../../assets/images/FutureLink/payment-success.json'
import paymentFailed from '../../../../assets/images/FutureLink/payment-failed.json'

const Success = (haisayang) => {
    const status = haisayang.location.search
    // console.log(status)
    // console.log(status.includes("pending"))
    // console.log(haisayang)


    setTimeout(() => {
        sessionStorage.removeItem('user');
        window.location.href = '/login'
    }, 4000)
    return ( 
        <React.Fragment>
            <div className="page-content">
            {status.includes("pending") === true ? (
                <>
                <Lottie options={{ loop: true, autoplay: true, animationData: paymentFailed, rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} height={350} width={350} isClickToPauseDisabled={true} />
                <div style={{textAlign: 'center'}}>
                <p style={{fontSize: '34px', fontWeight: '500', color: '#F43F5E'}}>Ooopss.. Pembayaran Batal</p>
                </div>
                </>
            ) : (
                <>
                <Lottie options={{ loop: true, autoplay: true, animationData: paymentSuccess, rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} height={350} width={350} isClickToPauseDisabled={true} />
                <div style={{textAlign: 'center'}}>
                <p style={{fontSize: '34px', fontWeight: '500', color: '#10B981'}}>Pembayaran telah berhasil</p>
                <p style={{fontSize: '18px', fontWeight: '400', color: '#737373'}}>Terima kasih telah menggunakan layanan Future Link, <br/>Silahkan login kembali!</p>
                </div>
                </>
            )}
            </div>
        </React.Fragment>
    );
};

export default Success;
import React, {useState} from 'react';
import {Link} from 'react-router-dom';

const Ekstrakulikuler= (haisayang) => {
    const data = haisayang.history.location.state.data
    // console.log(haisayang.history.location.state.data)

    const packages = haisayang.history.location.state.package_id
    // console.log(haisayang.history.location.state.package_id)

    const token = haisayang.history.location.state.params

    return(
        <React.Fragment>
            <div className="page-content">
                <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                    <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 40px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Ekstrakulikuler</p>
                </div>

                <div className='ekstrakulikuler'>
                    <Link style={{marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to={`/report-mobile/${token}`}>Back</Link>

                    <p style={{margin: '0px', textAlign: 'justify', fontSize: '16px', lineHeight: '28px'}}>Kegiatan ekstrakurikuler atau ekskul adalah wadah kegiatan pengembangan karakter dalam rangka perluasan potensi, bakat, minat, kemampuan, kepribadian, kerja sama, dan kemandirian seseorang secara optimal. </p>

                    <div className='ekstrakulikulerList'>
                        <div className='ekstrakulikulerListItem'>
                            <p className='ekstrakulikulerListText'>Menari </p>
                            <div className='ekstrakulikulerListCollection'>
                            {
                                data.dance*5 < 11 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                </>) : data.dance*5 < 21 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                </>) : data.dance*5 < 31 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                </>) : data.dance*5 < 41 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                </>) : data.dance*5 < 51 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                </>) : data.dance*5 < 61 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                </>) : data.dance*5 < 71 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                </>) : data.dance*5 < 81 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                </>) : data.dance*5 < 91 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                </>) : (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                <div className='ekstrakulikulerBox level1'></div>
                                </>)
                            }
                            <p style={{margin: '0px', fontWeight: '700'}}>{data.dance}%</p>
                            </div>
                        </div>
                        <div className='ekstrakulikulerListItem'>
                            <p className='ekstrakulikulerListText'>Menyanyi </p>
                            <div className='ekstrakulikulerListCollection'>
                            {
                                data.singing*5 < 11 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                </>) : data.singing*5 < 21 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                </>) : data.singing*5 < 31 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                </>) : data.singing*5 < 41 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                </>) : data.singing*5 < 51 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                </>) : data.singing*5 < 61 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                </>) : data.singing*5 < 71 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                </>) : data.singing*5 < 81 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                </>) : data.singing*5 < 91 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                </>) : (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                <div className='ekstrakulikulerBox level1'></div>
                                </>)
                            }
                            <p style={{margin: '0px', fontWeight: '700'}}>{data.singing}%</p>
                            </div>
                        </div>
                        <div className='ekstrakulikulerListItem'>
                            <p className='ekstrakulikulerListText'>Akting </p>
                            <div className='ekstrakulikulerListCollection'>
                            {
                                data.acting*5 < 11 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                </>) : data.acting*5 < 21 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                </>) : data.acting*5 < 31 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                </>) : data.acting*5 < 41 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                </>) : data.acting*5 < 51 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                </>) : data.acting*5 < 61 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                </>) : data.acting*5 < 71 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                </>) : data.acting*5 < 81 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                </>) : data.acting*5 < 91 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                </>) : (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                <div className='ekstrakulikulerBox level1'></div>
                                </>)
                            }
                            <p style={{margin: '0px', fontWeight: '700'}}>{data.acting}%</p>
                            </div>
                        </div>
                        <div className='ekstrakulikulerListItem'>
                            <p className='ekstrakulikulerListText'>Melukis </p>
                            <div className='ekstrakulikulerListCollection'>
                            {
                                data.painting*5 < 11 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                </>) : data.painting*5 < 21 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                </>) : data.painting*5 < 31 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                </>) : data.painting*5 < 41 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                </>) : data.painting*5 < 51 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                </>) : data.painting*5 < 61 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                </>) : data.painting*5 < 71 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                </>) : data.painting*5 < 81 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                </>) : data.painting*5 < 91 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                </>) : (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                <div className='ekstrakulikulerBox level1'></div>
                                </>)
                            }
                            <p style={{margin: '0px', fontWeight: '700'}}>{data.painting}%</p>
                            </div>
                        </div>
                        <div className='ekstrakulikulerListItem'>
                            <p className='ekstrakulikulerListText'>Memasak </p>
                            <div className='ekstrakulikulerListCollection'>
                            {
                                data.cooking*5 < 11 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                </>) : data.cooking*5 < 21 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                </>) : data.cooking*5 < 31 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                </>) : data.cooking*5 < 41 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                </>) : data.cooking*5 < 51 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                </>) : data.cooking*5 < 61 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                </>) : data.cooking*5 < 71 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                </>) : data.cooking*5 < 81 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                </>) : data.cooking*5 < 91 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                </>) : (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                <div className='ekstrakulikulerBox level1'></div>
                                </>)
                            }
                            <p style={{margin: '0px', fontWeight: '700'}}>{data.cooking}%</p>
                            </div>
                        </div>
                        <div className='ekstrakulikulerListItem'>
                            <p className='ekstrakulikulerListText'>Renang </p>
                            <div className='ekstrakulikulerListCollection'>
                            {
                                data.swimming*5 < 11 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                </>) : data.swimming*5 < 21 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                </>) : data.swimming*5 < 31 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                </>) : data.swimming*5 < 41 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                </>) : data.swimming*5 < 51 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                </>) : data.swimming*5 < 61 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                </>) : data.swimming*5 < 71 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                </>) : data.swimming*5 < 81 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                </>) : data.swimming*5 < 91 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                </>) : (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                <div className='ekstrakulikulerBox level1'></div>
                                </>)
                            }
                            <p style={{margin: '0px', fontWeight: '700'}}>{data.swimming}%</p>
                            </div>
                        </div>
                        <div className='ekstrakulikulerListItem'>
                            <p className='ekstrakulikulerListText'>Sepakbola </p>
                            <div className='ekstrakulikulerListCollection'>
                            {
                                data.soccer*5 < 11 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                </>) : data.soccer*5 < 21 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                </>) : data.soccer*5 < 31 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                </>) : data.soccer*5 < 41 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                </>) : data.soccer*5 < 51 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                </>) : data.soccer*5 < 61 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                </>) : data.soccer*5 < 71 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                </>) : data.soccer*5 < 81 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                </>) : data.soccer*5 < 91 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                </>) : (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                <div className='ekstrakulikulerBox level1'></div>
                                </>)
                            }
                            <p style={{margin: '0px', fontWeight: '700'}}>{data.soccer}%</p>
                            </div>
                        </div>
                        <div className='ekstrakulikulerListItem'>
                            <p className='ekstrakulikulerListText'>Catur </p>
                            <div className='ekstrakulikulerListCollection'>
                            {
                                data.chess*5 < 11 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                </>) : data.chess*5 < 21 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                </>) : data.chess*5 < 31 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                </>) : data.chess*5 < 41 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                </>) : data.chess*5 < 51 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                </>) : data.chess*5 < 61 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                </>) : data.chess*5 < 71 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                </>) : data.chess*5 < 81 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                </>) : data.chess*5 < 91 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                </>) : (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                <div className='ekstrakulikulerBox level1'></div>
                                </>)
                            }
                            <p style={{margin: '0px', fontWeight: '700'}}>{data.chess}%</p>
                            </div>
                        </div>
                        <div className='ekstrakulikulerListItem'>
                            <p className='ekstrakulikulerListText'>Bahasa Asing </p>
                            <div className='ekstrakulikulerListCollection'>
                            {
                                data.foreign_language*5 < 11 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                </>) : data.foreign_language*5 < 21 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                </>) : data.foreign_language*5 < 31 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                </>) : data.foreign_language*5 < 41 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                </>) : data.foreign_language*5 < 51 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                </>) : data.foreign_language*5 < 61 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                </>) : data.foreign_language*5 < 71 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                </>) : data.foreign_language*5 < 81 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                </>) : data.foreign_language*5 < 91 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                </>) : (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                <div className='ekstrakulikulerBox level1'></div>
                                </>)
                            }
                            <p style={{margin: '0px', fontWeight: '700'}}>{data.foreign_language}%</p>
                            </div>
                        </div>
                        <div className='ekstrakulikulerListItem'>
                            <p className='ekstrakulikulerListText'>Karya Tulis </p>
                            <div className='ekstrakulikulerListCollection'>
                            {
                                data.paper*5 < 11 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                </>) : data.paper*5 < 21 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                </>) : data.paper*5 < 31 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                </>) : data.paper*5 < 41 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                </>) : data.paper*5 < 51 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                </>) : data.paper*5 < 61 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                </>) : data.paper*5 < 71 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                </>) : data.paper*5 < 81 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                </>) : data.paper*5 < 91 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                </>) : (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                <div className='ekstrakulikulerBox level1'></div>
                                </>)
                            }
                            <p style={{margin: '0px', fontWeight: '700'}}>{data.paper}%</p>
                            </div>
                        </div>
                        <div className='ekstrakulikulerListItem'>
                            <p className='ekstrakulikulerListText'>Programming </p>
                            <div className='ekstrakulikulerListCollection'>
                            {
                                data.programming*5 < 11 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                </>) : data.programming*5 < 21 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                </>) : data.programming*5 < 31 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                </>) : data.programming*5 < 41 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                </>) : data.programming*5 < 51 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                </>) : data.programming*5 < 61 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                </>) : data.programming*5 < 71 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                </>) : data.programming*5 < 81 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                </>) : data.programming*5 < 91 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                </>) : (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                <div className='ekstrakulikulerBox level1'></div>
                                </>)
                            }
                            <p style={{margin: '0px', fontWeight: '700'}}>{data.programming}%</p>
                            </div>
                        </div>
                    
                    </div>

                    <div style={{marginTop: '30px'}}>
                        <p style={{fontSize: '16px', fontWeight: '700'}}>Keterangan:</p>
                        <ul style={{textAlign: 'justify', fontSize: '16px', width: '100%', lineHeight: '28px'}}>
                            <li>Warna hijau menunjukkan kecocokan yang tinggi antara ekstrakulikuler dengan kecerdasan majemuk yang dimiliki. </li>
                            <li>Warna kuning menunjukkan kecocokan yang menengah antara ekstrakulikuler dengan kecerdasan majemuk yang dimiliki.</li>
                            <li>Warna merah menunjukkan kecocokan yang rendah antara ekstrakulikuler dengan kecerdasan majemuk yang dimiliki.</li>
                        </ul>
                    </div>
                </div>

                <div style={{display: 'flex', justifyContent:'space-between', paddingTop: '32px'}}>
                    {packages === 1 && (
                        <>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report-mobiles/gaya-belajar", state: { data: data, package_id: packages, params: token }}} onClick={() => {document.body.scrollIntoView({behavior: "smooth"})}}>{"<"} Gaya Belajar</Link>
                        </>
                    )}
                    {packages === 22 && (
                        <>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report-mobiles/penjurusan", state: { data: data, package_id: packages, params: token }}} onClick={() => {document.body.scrollIntoView({behavior: "smooth"})}}>{"<"} Pemilihan Jurusan</Link>
                        </>
                    )}
                    {packages === 33 && (
                        <>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report-mobiles/kecerdasan-majemuk", state: { data: data, package_id: packages, params: token }}} onClick={() => {document.body.scrollIntoView({behavior: "smooth"})}}>{"<"} Kecerdasan Majemuk</Link>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report-mobiles/minat-kejuruan", state: { data: data, package_id: packages, params: token }}} onClick={() => {document.body.scrollIntoView({behavior: "smooth"})}}>Minat Studi {">"}</Link>
                        </>
                    )}
                </div>
            </div>
        </React.Fragment>
    )

}

export default Ekstrakulikuler;
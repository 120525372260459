import React, { useState, useEffect } from "react";

import SidebarDashboard from "./TemplateDashboard/sidebarDashboard.js";
import HeaderDashboard from "./TemplateDashboard/headerDashboard.js";

import ComplementaryClient from "./MenuDashboard/complementary.js";

const ClientComplementary = () => {
    const title = 'Complementary'

    return (

        <div className="dashboardv2">
            <SidebarDashboard title={title} />
            <section className="fullwidth">
                <HeaderDashboard title={title} gate1={false} gate2={false} menu={""} submenu={""} />

                <main className="mainv2">

                <ComplementaryClient/>

                </main>
            </section>

        </div>
    )

};

export default ClientComplementary
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Progress} from 'reactstrap';

import Lottie from 'react-lottie';
import accessDenied from '../../../../assets/images/FutureLink/accessDenied.json';

import objectDriven from '../../../../assets/images/FutureLink/objectDriven.png';
import conceptDriven from '../../../../assets/images/FutureLink/conceptDriven.png';
import executor from '../../../../assets/images/FutureLink/executor.png';
import thinker from '../../../../assets/images/FutureLink/thinker.png';

const KonsepPemikiran = (haisayang) => {
    if(haisayang.history.location.state === undefined){
        window.location.href = "/report"
    }

    const data = haisayang.history.location.state.data
    // console.log(haisayang.history.location.state.data)

    const packages = haisayang.history.location.state.package_id
    // console.log(haisayang.history.location.state.package_id)

    return(
        <React.Fragment>
            <div className="page-content">
                {packages === 33 ? (
                    <>
                <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                    <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 40px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Konsep Pemikiran</p>
                </div>

                <div className='konseppemikiran'>
                    <Link style={{marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to='/report'>Back</Link>

                    <div className='konseppemikiranWeb'>

                        <div style={{marginBottom: '80px'}}>
                            <div style={{display: 'flex', gap: '25%', justifyContent: 'center', marginBottom: '20px'}}>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <p style={{fontSize: '18px', fontWeight: '700', textAlign: 'center'}}>Object Driven</p>
                                    <img src={objectDriven} style={{width: '80px', height: '80px'}}/>
                                </div>
                                
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <p style={{fontSize: '18px', fontWeight: '700', textAlign: 'center'}}>Concept Driven</p>
                                    <img src={conceptDriven} style={{width: '80px', height: '80px'}}/>
                                </div>

                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <p style={{margin:'0px' , flex: '1', textAlign: 'end', paddingRight: '10px', fontSize: '14px', fontWeight: '900', color: '#05C38A'}}>{data.object}%</p>
                                <div style={{flex: '4'}}>
                                    <Progress multi style={{height: '30px'}}>
                                        <Progress bar value={data.object} color='info'/>
                                        <Progress bar value={data.concept} color='danger'/>
                                    </Progress>
                                </div>
                                <p style={{margin:'0px' , flex: '1', textAlign: 'start', paddingLeft: '10px', fontSize: '14px', fontWeight: '900', color: '#FB5607'}}>{data.concept}%</p>
                            </div>
                        </div>


                        <div style={{marginBottom: '40px'}}>
                            <p style={{fontSize: '18px', fontWeight: '700', marginBottom: '10px'}}>Object Driven</p>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{width: '190px', height: '3px', backgroundColor: '#05C38A'}}></div>
                                <div style={{width: '12px', height: '12px', borderRadius: '200px', backgroundColor: '#05C38A'}}></div>
                            </div>
                            <p style={{fontSize: '14px', textAlign: 'justify', marginTop: '10px'}}>Orang-orang yang nilai <span style={{fontWeight: '700'}}>object driven</span>-nya lebih tinggi digerakkan oleh objek dan pandai menganalisis situasi apapun. Pemikirannya didasarkan pada fakta, realita dan memiliki pendekatan praktis. Terbiasa untuk bertindak kemudian berpikir. Cenderung cepat merespon dan menyelesaikan tugas serta pandai menganalisis situasi. Orang-orang ini termotivasi oleh tujuan, sasaran dan memenangkan tantangan. Proses berpikirnya rasional dan senang mengambil risiko yang telah diperhitungkan.</p>
                        </div>
                        <div>
                            <p style={{fontSize: '18px', fontWeight: '700', marginBottom: '10px'}}>Concept Driven</p>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{width: '190px', height: '3px', backgroundColor: '#FB5607'}}></div>
                                <div style={{width: '12px', height: '12px', borderRadius: '200px', backgroundColor: '#FB5607'}}></div>
                            </div>
                            <p style={{fontSize: '14px', textAlign: 'justify', marginTop: '10px'}}>Orang-orang yang nilai concept <span style={{fontWeight: '700'}}>driven</span>-nya lebih tinggi didorong oleh konsep dan pandai memvisualisasikan sesuatu atau situasi. Pemikirannya didasarkan pada mimpi, daya tarik dan memiliki pendekatan yang tidak praktis. Terbiasa untuk berpikir dulu dan kemudian bertindak. Memiliki banyak pertanyaan dan sulit untuk fokus. Termotivasi oleh imbalan emosional, hubungan, dan rasa memiliki. Proses berpikirnya emosional dan senang mengambil resiko yang tinggi.</p>
                        </div>

                        <div style={{marginBottom: '80px'}}>

                            <div style={{display: 'flex', gap: '25%', justifyContent: 'center', marginBottom: '20px', marginTop: '100px'}}>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <p style={{fontSize: '18px', fontWeight: '700', textAlign: 'center'}}>Executor</p>
                                    <img src={executor} style={{width: '80px', height: '80px'}}/>
                                </div>
                                
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <p style={{fontSize: '18px', fontWeight: '700', textAlign: 'center'}}>Thinker</p>
                                    <img src={thinker} style={{width: '80px', height: '80px'}}/>
                                </div>

                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <p style={{margin:'0px' , flex: '1', textAlign: 'end', paddingRight: '10px', fontSize: '14px', fontWeight: '900', color: '#05C38A'}}>{data.executor}%</p>
                                <div style={{flex: '4'}}>
                                    <Progress multi style={{height: '30px'}}>
                                        <Progress bar value={data.executor} color='info'/>
                                        <Progress bar value={data.thinker} color='danger'/>
                                    </Progress>                        
                                </div>
                                <p style={{margin:'0px' , flex: '1', textAlign: 'start', paddingLeft: '10px', fontSize: '14px', fontWeight: '900', color: '#FB5607'}}>{data.thinker}%</p>
                            </div>

                        </div>


                        <div style={{marginBottom: '40px'}}>
                            <p style={{fontSize: '18px', fontWeight: '700', marginBottom: '10px'}}>Executor</p>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{width: '190px', height: '3px', backgroundColor: '#05C38A'}}></div>
                                <div style={{width: '12px', height: '12px', borderRadius: '200px', backgroundColor: '#05C38A'}}></div>
                            </div>
                            <p style={{fontSize: '14px', textAlign: 'justify', marginTop: '10px'}}>Orang-orang yang nilai <span style={{fontWeight: '700'}}>executor</span>-nya lebih tinggi didorong oleh tujuan, target, dan pengakuan. Pandai merencanakan dan melaksanakan sesuatu, serta terbiasa untuk mendorong tim mendapatkan hasil kerja yang maksimal. Orang ini dapat memotivasi orang lain dan memimpin dengan teladan mereka sendiri.</p>
                        </div>
                        <div>
                            <p style={{fontSize: '18px', fontWeight: '700', marginBottom: '10px'}}>Thinker</p>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div style={{width: '190px', height: '3px', backgroundColor: '#FB5607'}}></div>
                                <div style={{width: '12px', height: '12px', borderRadius: '200px', backgroundColor: '#FB5607'}}></div>
                            </div>
                            <p style={{fontSize: '14px', textAlign: 'justify', marginTop: '10px'}}>Orang-orang yang nilai <span style={{fontWeight: '700'}}>thinker</span>-nya lebih tinggi didorong oleh organisasi dan visi serta nilai-nilainya. Pandai melakukan pra-peluncuran proyek, dan dapat menganalisis situasi serta risiko yang terkait. Terbiasa menetapkan aturan, sistem, proses, dan kepatuhan di lingkungannya.</p>
                        </div>

                    </div>

                    <div className='konseppemikiranMobile'>
                        <div className='konseppemikiranMobileList'>
                            <div className='konseppemikiranMobileItem'>
                                <p className='judul'>Object Driven</p>
                                <img src={objectDriven}/>
                                <Progress value={data.object} className='progress-xl animated-progess progress-label' color={data.object < 50 ? "red" : "green"}>                      
                                    <div className='label' style={{right: '-60px'}}>{data.object}%</div>
                                </Progress>
                                <p className='isi'>Orang-orang yang nilai <span style={{fontWeight: '700'}}>object driven</span>-nya lebih tinggi digerakkan oleh objek dan pandai menganalisis situasi apapun. Pemikirannya didasarkan pada fakta, realita dan memiliki pendekatan praktis. Terbiasa untuk bertindak kemudian berpikir. Cenderung cepat merespon dan menyelesaikan tugas serta pandai menganalisis situasi. Orang-orang ini termotivasi oleh tujuan, sasaran dan memenangkan tantangan. Proses berpikirnya rasional dan senang mengambil risiko yang telah diperhitungkan.</p>
                            </div>
                            <div className='konseppemikiranMobileItem'>
                                <p className='judul'>Concept Driven</p>
                                <img src={conceptDriven}/>
                                <Progress value={data.concept} className='progress-xl animated-progess progress-label'color={data.concept < 50 ? "red" : "green"}>                     
                                    <div className='label' style={{right: '-60px'}}>{data.concept}%</div>
                                </Progress>
                                <p className='isi'>Orang-orang yang nilai <span style={{fontWeight: '700'}}>concept driven</span>-nya lebih tinggi didorong oleh konsep dan pandai memvisualisasikan sesuatu atau situasi. Pemikirannya didasarkan pada mimpi, daya tarik dan memiliki pendekatan yang tidak praktis. Terbiasa untuk berpikir dulu dan kemudian bertindak. Memiliki banyak pertanyaan dan sulit untuk fokus. Termotivasi oleh imbalan emosional, hubungan, dan rasa memiliki. Proses berpikirnya emosional dan senang mengambil resiko yang tinggi.</p>
                            </div>
                            <div className='konseppemikiranMobileItem'>
                                <p className='judul'>Executor</p>
                                <img src={executor}/>
                                <Progress value={data.executor} className='progress-xl animated-progess progress-label'color={data.executor < 50 ? "red" : "green"}>                    
                                    <div className='label' style={{right: '-60px'}}>{data.executor}%</div>
                                </Progress>
                                <p className='isi'>Orang-orang yang nilai <span style={{fontWeight: '700'}}>executor</span>-nya lebih tinggi didorong oleh tujuan, target, dan pengakuan. Pandai merencanakan dan melaksanakan sesuatu, serta terbiasa untuk mendorong tim mendapatkan hasil kerja yang maksimal. Orang ini dapat memotivasi orang lain dan memimpin dengan teladan mereka sendiri.</p>
                            </div>
                            <div className='konseppemikiranMobileItem'>
                                <p className='judul'>Thinker</p>
                                <img src={thinker}/>
                                <Progress value={data.thinker} className='progress-xl animated-progess progress-label'color={data.thinker < 50 ? "red" : "green"}>                      
                                    <div className='label' style={{right: '-60px'}}>{data.thinker}%</div>
                                </Progress>
                                <p className='isi'>Orang-orang yang nilai <span style={{fontWeight: '700'}}>thinker</span>-nya lebih tinggi didorong oleh organisasi dan visi serta nilai-nilainya. Pandai melakukan pra-peluncuran proyek, dan dapat menganalisis situasi serta risiko yang terkait. Terbiasa menetapkan aturan, sistem, proses, dan kepatuhan di lingkungannya.</p>
                            </div>

                        </div>

                    </div>

                </div>

                <div className='prevNext'>
                    <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report/dope", state: { data: data, package_id: packages }}}>{"<"} DOPE</Link>
                </div>
                    
                    </>
                ) : (
                    <>
                        <Link style={{marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to='/report'>Back</Link>
                        <Lottie options={{loop: true,autoplay: true,animationData: accessDenied,rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} isClickToPauseDisabled={true} />
                        <p className='upgradePlease'> Maaf, Anda belum melakukan Upgrade yang lebih tinggi</p>
                    </>
                )}


            </div>
        </React.Fragment>
    )

}

export default KonsepPemikiran;
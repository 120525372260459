import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import axios from 'axios';
import {decodeToken} from "react-jwt";

import ls from "../../../../assets/images/FutureLink/laporansidikjari.png";
import lz from "../../../../assets/images/FutureLink/laporanspm.svg";

const ReportClient = () => {
  const [data, setData] = useState([])
  const [packages, setPackages] = useState(0);
  const [pdf, setPdf] = useState("");
  const [modalNotFinish, setModalNotFinish] = useState(false)

  const fingerType = [
    {
      name: "Tidak Diketahui",
      value: "nothing",
    },
    {
      name: "Whorl Target",
      value: "W_centric",
      nick: "WT",
    },
    {
      name: "Whorl Spiral",
      value: "W_spiral",
      nick: "WS",
    },
    {
      name: "Whorl Elongated Centric",
      value: "W_elongated_centric",
      nick: "WTE",
    },
    {
      name: "Whorl Elongated Spiral",
      value: "W_elongated_spiral",
      nick: "WSE",
    },
    {
      name: "Whorl Imploding",
      value: "W_imploding",
      nick: "WI",
    },
    {
      name: "Whorl Composite",
      value: "W_composite",
      nick: "WC",
    },
    {
      name: "Whorl Target Ulnar Peacock Eye",
      value: "W_peacock_centric_ulnar",
      nick: "WTP",
    },
    {
      name: "Whorl Spiral Ulnar Peacock Eye",
      value: "W_peacock_spiral_ulnar",
      nick: "WSP",
    },
    {
      name: "Whorl Target Radial Peacock Eye",
      value: "W_peacock_centric_radial",
      nick: "RWTP",
    },
    {
      name: "Whorl Spiral Radial Peacock Eye",
      value: "W_peacock_spiral_radial",
      nick: "RWSP",
    },
    {
      name: "Whorl Double Loop",
      value: "L_double",
      nick: "WDL",
    },
    {
      name: "Ulnar Loop",
      value: "L_ulnar",
      nick: "L",
    },
    {
      name: "Radial Loop",
      value: "L_radial",
      nick: "RL",
    },
    {
      name: "Ulnar Falling Loop",
      value: "L_fallen_ulnar",
      nick: "LW",
    },
    {
      name: "Radial Falling Loop",
      value: "L_fallen_radial",
      nick: "RLW",
    },
    {
      name: "Plain Arch",
      value: "A_simple",
      nick: "AS",
    },
    {
      name: "Tented Arch",
      value: "A_tented",
      nick: "TA",
    },
    {
      name: "Tented Arch with Ulnar Loop",
      value: "A_w_L_ulnar",
      nick: "LAS",
    },
    {
      name: "Tented Arch with Radial Loop",
      value: "A_w_L_radial",
      nick: "RLAS",
    },
    {
      name: "Accidental Whorl",
      value: "accidental",
      nick: "WX",
    },
  ];

  const [l1, setL1] = useState("");
  const [l2, setL2] = useState("");
  const [l3, setL3] = useState("");
  const [l4, setL4] = useState("");
  const [l5, setL5] = useState("");
  const [r1, setR1] = useState("");
  const [r2, setR2] = useState("");
  const [r3, setR3] = useState("");
  const [r4, setR4] = useState("");
  const [r5, setR5] = useState("");
  const [l1Shape, setL1Shape] = useState("");
  const [l2Shape, setL2Shape] = useState("");
  const [l3Shape, setL3Shape] = useState("");
  const [l4Shape, setL4Shape] = useState("");
  const [l5Shape, setL5Shape] = useState("");
  const [r1Shape, setR1Shape] = useState("");
  const [r2Shape, setR2Shape] = useState("");
  const [r3Shape, setR3Shape] = useState("");
  const [r4Shape, setR4Shape] = useState("");
  const [r5Shape, setR5Shape] = useState("");

  const code = sessionStorage.getItem('user');
  const decode = decodeToken(code);
  console.log(packages)

  const logout = () => {
    sessionStorage.clear();
    window.location.href = '/login';
  }

  useEffect(() => {
    getReport()
    getAllFingerprint();
  }, [])

  const getReport = () => {
    let config = {
      method: 'get',
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-latest-report`,
      headers: { 
        'Authorization': `Bearer ${code}`
      }
    };
    
    axios.request(config)
    .then((response) => {
      console.log(response);
      setData(response.results.report);
      setPackages(response.results.package_id);
      setPdf(response.results.pdf)
    })
    .catch((error) => {
      console.log(error);
      console.log(error.response.data);
      if(error.response.data.message == "report not finish yet" ){
        setModalNotFinish(true);
      }
      // if(error.response.data.statusCode === 401 && error.response.data.message === "jwt expired" ){
      //   logout()
      // }
    });
  }

  const getAllFingerprint = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-by-id?userId=${decode.userId}`,
      headers: {
        Authorization: `Bearer ${code}`,
      },
    };

    axios(config)
      .then((response) => {
        // console.log(response.results[0]);

        getImage(response.results[0].l1, "l1");
        getImage(response.results[0].l2, "l2");
        getImage(response.results[0].l3, "l3");
        getImage(response.results[0].l4, "l4");
        getImage(response.results[0].l5, "l5");
        getImage(response.results[0].r1, "r1");
        getImage(response.results[0].r2, "r2");
        getImage(response.results[0].r3, "r3");
        getImage(response.results[0].r4, "r4");
        getImage(response.results[0].r5, "r5");

        setL1Shape(
          fingerType[fingerType.map((item) => item.value).indexOf(response.results[0].l1_shape)] === undefined 
            ? "XX" 
            : fingerType[fingerType.map((item) => item.value).indexOf(response.results[0].l1_shape)].nick
        );
        setL2Shape(
          fingerType[fingerType.map((item) => item.value).indexOf(response.results[0].l2_shape)] === undefined
            ? "XX"
            : fingerType[fingerType.map((item) => item.value).indexOf(response.results[0].l2_shape)].nick
        );
        setL3Shape(
          fingerType[fingerType.map((item) => item.value).indexOf(response.results[0].l3_shape)] === undefined
            ? "XX"
            : fingerType[fingerType.map((item) => item.value).indexOf(response.results[0].l3_shape)].nick
        );
        setL4Shape(
          fingerType[fingerType.map((item) => item.value).indexOf(response.results[0].l4_shape)] === undefined
            ? "XX"
            : fingerType[fingerType.map((item) => item.value).indexOf(response.results[0].l4_shape)].nick
        );
        setL5Shape(
          fingerType[
            fingerType
              .map((item) => item.value)
              .indexOf(response.results[0].l5_shape)
          ] === undefined
            ? "XX"
            : fingerType[
                fingerType
                  .map((item) => item.value)
                  .indexOf(response.results[0].l5_shape)
              ].nick
        );
        setR1Shape(
          fingerType[
            fingerType
              .map((item) => item.value)
              .indexOf(response.results[0].r1_shape)
          ] === undefined
            ? "XX"
            : fingerType[
                fingerType
                  .map((item) => item.value)
                  .indexOf(response.results[0].r1_shape)
              ].nick
        );
        setR2Shape(
          fingerType[
            fingerType
              .map((item) => item.value)
              .indexOf(response.results[0].r2_shape)
          ] === undefined
            ? "XX"
            : fingerType[
                fingerType
                  .map((item) => item.value)
                  .indexOf(response.results[0].r2_shape)
              ].nick
        );
        setR3Shape(
          fingerType[
            fingerType
              .map((item) => item.value)
              .indexOf(response.results[0].r3_shape)
          ] === undefined
            ? "XX"
            : fingerType[
                fingerType
                  .map((item) => item.value)
                  .indexOf(response.results[0].r3_shape)
              ].nick
        );
        setR4Shape(
          fingerType[
            fingerType
              .map((item) => item.value)
              .indexOf(response.results[0].r4_shape)
          ] === undefined
            ? "XX"
            : fingerType[
                fingerType
                  .map((item) => item.value)
                  .indexOf(response.results[0].r4_shape)
              ].nick
        );
        setR5Shape(
          fingerType[
            fingerType
              .map((item) => item.value)
              .indexOf(response.results[0].r5_shape)
          ] === undefined
            ? "XX"
            : fingerType[
                fingerType
                  .map((item) => item.value)
                  .indexOf(response.results[0].r5_shape)
              ].nick
        );
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const getImage = (imageName, index) => {
    if (index === "l1") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setL1(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "l2") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setL2(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "l3") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setL3(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "l4") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setL4(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "l5") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setL5(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "r1") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setR1(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "r2") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setR2(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "r3") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setR3(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "r4") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setR4(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "r5") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setR5(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };


  return (
    <Row className="corev2 repCen">
      <Col lg={5}>
        <div className="rlHeader">
          <p className="antitank">Laporan Sidik Jari</p>
        </div>
        <div className="rlContent">
          <img src={ls} style={{ width: "100%" }} />
          {packages === 0 ? (
            <>
            <Link className="buttonTest" style={{ padding: "12px 34%" }} >
              Lihat
            </Link>
            </>
          ) : (
            <>
            <Link className="buttonTest" style={{ padding: "12px 34%" }}
              to={{
                pathname:"/client/reportFp",
                state: { data: data, package_id: packages, img_finger:[r1, l1, r2, l2, r3, l3, r4, l4, r5, l5], shape_finger:[r1Shape, l1Shape, r2Shape, l2Shape, r3Shape, l3Shape, r4Shape, l4Shape, r5Shape, l5Shape], pdf: pdf },
              }}
            >
              Lihat
            </Link>
            </>
          )}

        </div>
      </Col>
      {/* SEMENTARA DI MATIKAN */}
      {/* <Col lg={5}>
        <div className="rlHeader">
          <p className="antitank">Laporan SPM</p>
        </div>
        <div className="rlContent">
          <img src={lz} style={{ width: "100%" }} />
          <Link
            to={{pathname:"/client/reportSpm", state: {data: data} }}
            className="buttonTest"
            style={{ padding: "12px 34%" }}
          >
            Lihat
          </Link>
        </div>
      </Col> */}
    </Row>
  );
};

export default ReportClient;

import React, { useState, useEffect } from "react";

import SidebarDashboard from "./TemplateDashboard/sidebarDashboard.js";
import HeaderDashboard from "./TemplateDashboard/headerDashboard.js";

import ProfileClient from "./MenuDashboard/profile.js";

const ClientProfile = () => {
    const title = 'Profile'

    return (

        <div className="dashboardv2">
            <SidebarDashboard title={title} />
            <section className="fullwidth">
                <HeaderDashboard  title={title} gate1={false} gate2={false} menu={""} submenu={""} />

                <main className="mainv2">

                <ProfileClient/>

                </main>
            </section>
        </div>
    )

};

export default ClientProfile
import React, { useState } from "react";
import { Row, Col, Progress } from "reactstrap";
import {Link} from 'react-router-dom'

const Tfrc = (e) => {
  const state = e.state.data
  const data = e.state.data
  const package_id = e.state.package_id
  const img_finger = e.state.img_finger
  const shape_finger = e.state.shape_finger

  const array = [
    {
      c: 'level1',
      v: '> 238',
      t: 'Sangat Tinggi',
      k: 'Memiliki kemampuan untuk mendobrak batasan dari keterbatasan diri sendiri',
    },
    {
      c: 'level2',
      v: '205 - 238',
      t: 'Tinggi',
      k: 'Memiliki kemampuan tersembunyi yang berpotensi menjadi suatu kekuatan besar',
    },
    {
      c: 'level3',
      v: '171 - 204',
      t: 'Diatas rataa-rata',
      k: 'Memiliki kemampuan untuk memimpin orang lain untuk mencapai harapannya',
    },
    {
      c: 'level4',
      v: '136 - 170',
      t: 'Rata-rata',
      k: 'Memiliki kemampuan untuk memotivasi orang lain',
    },
    {
      c: 'level5tfrc',
      v: '102 - 135',
      t: 'Dibawah rata-rata',
      k: 'Memiliki wawasan mengenai diri sendiri dan memiliki ekspektasi yang realistis',
    },
    {
      c: 'level6tfrc',
      v: '68 - 101',
      t: 'Cukup Rendah',
      k: 'Membutuhkan bimbingan untuk kinerja yang optimal',
    },
    {
      c: 'level7tfrc',
      v: '< 68',
      t: 'Rendah',
      k: 'Membutuhkan bantuan dan pengangan khusus untuk kinerja yang lebih baik',
    },
  ]


  return (
    <Row className="corev2">
      <div className="appearOnMobile tiwtiw" style={{marginBottom: '24px'}}>
      <Link to={{pathname: '/client/reportFP', state: { data: data, package_id: package_id, img_finger:img_finger, shape_finger:shape_finger },}} className="bBek">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M10.295 19.7155C10.3886 19.8077 10.4994 19.8806 10.6211 19.93C10.7429 19.9793 10.8731 20.0042 11.0045 20.0033C11.1358 20.0023 11.2657 19.9754 11.3867 19.9243C11.5076 19.8731 11.6173 19.7986 11.7095 19.705C11.8017 19.6115 11.8746 19.5007 11.9239 19.379C11.9733 19.2572 11.9982 19.127 11.9972 18.9956C11.9963 18.8643 11.9694 18.7344 11.9183 18.6134C11.8671 18.4925 11.7926 18.3827 11.699 18.2906L6.32903 13.0006H19.999C20.2642 13.0006 20.5186 12.8952 20.7061 12.7077C20.8937 12.5201 20.999 12.2658 20.999 12.0006C20.999 11.7353 20.8937 11.481 20.7061 11.2934C20.5186 11.1059 20.2642 11.0006 19.999 11.0006H6.33603L11.7 5.71355C11.884 5.52633 11.987 5.2743 11.9867 5.01183C11.9865 4.74936 11.8831 4.49751 11.6988 4.31061C11.5145 4.12371 11.2642 4.01675 11.0017 4.01281C10.7393 4.00887 10.4858 4.10827 10.296 4.28955L3.37203 11.1116C3.25411 11.2278 3.16048 11.3664 3.09657 11.5192C3.03267 11.672 2.99976 11.8359 2.99976 12.0016C2.99976 12.1672 3.03267 12.3311 3.09657 12.4839C3.16048 12.6367 3.25411 12.7753 3.37203 12.8916L10.296 19.7146L10.295 19.7155Z" fill="#374151"/>
      </svg>
      </Link>
        <p className="antitank" style={{textAlign: 'center'}}>TFRC</p>
      </div>

      <Col lg={6}>
        <div className="trVa appearOnMobile" style={{justifyContent: 'center', marginBottom: '24px'}}>
          <div className={
            state.tfrc > 237 ? 'trVaB  level1b' :
            state.tfrc > 204 ? 'trVaB  level2b' :
            state.tfrc > 170 ? 'trVaB  level3b' :
            state.tfrc > 135 ? 'trVaB  level4b' :
            state.tfrc > 101 ? 'trVaB  level5b' :
            state.tfrc > 67 ? 'trVaB  level6b' : 'trVaB  level7b'
          }>
            <p className={
            state.tfrc > 237 ? 'trVaBT  level1p' :
            state.tfrc > 204 ? 'trVaBT  level2p' :
            state.tfrc > 170 ? 'trVaBT  level3p' :
            state.tfrc > 135 ? 'trVaBT  level4p' :
            state.tfrc > 101 ? 'trVaBT  level5p' :
            state.tfrc > 67 ? 'trVaBT  level6p' : 'trVaBT  level7p'
          }>Nilai TFRC :</p>
            <p className={
            state.tfrc > 237 ? 'trVaBN  level1' :
            state.tfrc > 204 ? 'trVaBN  level2' :
            state.tfrc > 170 ? 'trVaBN  level3' :
            state.tfrc > 135 ? 'trVaBN  level4' :
            state.tfrc > 101 ? 'trVaBN  level5tfrc' :
            state.tfrc > 67 ? 'trVaBN  level6tfrc' : 'trVaBN  level7tfrc'
          }>{Math.trunc(state.tfrc)}</p>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: "100%" }}>
            <div className="goneOnMobile" style={{display: "flex", alignItems: "center"}}>
              <p className="tfWB" style={{ flex: "1"}}>
                Point
              </p>
              <p className="tfWB" style={{ flex: "1"}}>
                Indikator
              </p>
              <p className="tfWB" style={{ flex: "2"}}>
                Keterangan
              </p>
            </div>

            {array.map((item, index) => (
            <div key={index} style={{ display: "flex", alignItems: "center" }}>
              <div className={"trLv " + item.c} style={{ flex: "1" }}>
                <p style={{ margin: "0px" }}>{item.v}</p>
              </div>
              <div className={"trLv " + item.c} style={{ flex: "1" }}>
                <p style={{ margin: "0px" }}>{item.t}</p>
              </div>
              <div className={"trfcLevelKeterangan "} style={{ flex: "2" }}>
                <p className="trLvKet">
                  {item.k}
                </p>
              </div>
            </div>
            ))}

          </div>
        </div>
      </Col>

      <Col lg={6} style={{ marginTop: "56px" }}>
        <p className="tewKet">
          <span style={{ fontWeight: "900" }}>
            Total Finger Ridge Count (TFRC)
          </span>{" "}
          adalah suatu angka yang menunjukkan jumlah sulur dari semua jari
          setiap orang. Menurut Ifa H. Misbach dalam bukunya yang berjudul
          “Dahsyatnya Sidik Jari: Menguak Bakat dan Potensi untuk Merancang Masa
          Depan Melalui Fingerprint Analysis”, menyebutkan bahwa penghitungan
          banyaknya rigi dilakukan mulai dari triradius sampai ke pusat dari
          pola sulur jari. TFRC merupakan cerminan dari kapasitas belajar bawaan
          seseorang, umumnya dikenal sebagai “Kapasitas Sel Otak Neo-korteks”.
          Saat lahir, 100 miliar sel saraf di korteks selebral mengatur
          rangkaian kabel yang sangat rumit (sekitar 5.000 hingga 10.000 koneksi
          ke setiap sel saraf). Melalui mekanisme pembelajaran di otak, otak
          terus menyambung ulang dan mengubah sirkuitnya sepanjang hidup
          seseorang. Oleh karena itu, belajar penting untuk merangsang
          keterkaitan neuron diri kita sendiri.{" "}
        </p>

        <div className="trVa goneOnMobile">
        <div className={
            state.tfrc > 237 ? 'trVaB  level1b' :
            state.tfrc > 204 ? 'trVaB  level2b' :
            state.tfrc > 170 ? 'trVaB  level3b' :
            state.tfrc > 135 ? 'trVaB  level4b' :
            state.tfrc > 101 ? 'trVaB  level5b' :
            state.tfrc > 67 ? 'trVaB  level6b' : 'trVaB  level7b'
          }>
            <p className={
            state.tfrc > 237 ? 'trVaBT  level1p' :
            state.tfrc > 204 ? 'trVaBT  level2p' :
            state.tfrc > 170 ? 'trVaBT  level3p' :
            state.tfrc > 135 ? 'trVaBT  level4p' :
            state.tfrc > 101 ? 'trVaBT  level5p' :
            state.tfrc > 67 ? 'trVaBT  level6p' : 'trVaBT  level7p'
          }>Nilai TFRC :</p>
            <p className={
            state.tfrc > 237 ? 'trVaBN  level1' :
            state.tfrc > 204 ? 'trVaBN  level2' :
            state.tfrc > 170 ? 'trVaBN  level3' :
            state.tfrc > 135 ? 'trVaBN  level4' :
            state.tfrc > 101 ? 'trVaBN  level5tfrc' :
            state.tfrc > 67 ? 'trVaBN  level6tfrc' : 'trVaBN  level7tfrc'
          }>{Math.trunc(state.tfrc)}</p>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Tfrc;

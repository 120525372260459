import React, { useState } from "react";
import { Card, Col, Container, Input, Label, Row, Button, Alert } from "reactstrap";

import logoFutureLink from "../../../assets/images/FutureLink/logoFutureLink.png";
import forgetImg from "../../../assets/images/FutureLink/forget.png";
import axios from "axios";

const NewPassword = () => {
  document.title = "Forget Password | Future Link";
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [show, setShow] = useState(false);
  const [fade, setFade] = useState('fadeout');
  const [alert, setAlert] = useState(true);
  const [alertContent, setAlertContent] = useState("")

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let resetCode = params.get("resetCode");

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const resetPassword = () => {

    if(password === "" || confirmPassword === "") {

      setAlertContent("Field cannot be empty")
      setAlert(false);

      setTimeout(() => {
        setFade("fade");
      }, 200);

      setTimeout(() => {
        setFade("fadeout");
      }, 4500);

      setTimeout(() => {
        setAlert(true);
      }, 5000);

    } else if(password !== confirmPassword) {

      setAlertContent("Password not match")
      setAlert(false);

      setTimeout(() => {
        setFade("fade");
      }, 200);

      setTimeout(() => {
        setFade("fadeout");
      }, 4500);

      setTimeout(() => {
        setAlert(true);
      }, 5000);

    } else {

      let data = new FormData();
      data.append("resetCode", resetCode);
      data.append("password", password);
      data.append("confirmPassword", confirmPassword);
  
      // console.log(resetCode, password);
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/forgot-new-password`,
        data: data,
      };
  
      axios.request(config)
        .then((response) => {
          console.log(response);
          if (response.statusCode === 200) {
            window.location.href = "/login"
          } else {
            console.log("FAILED");
          }
        })
        .catch((error) => {
          // console.log(error);
          // console.log(error.response);
          // console.log(error.response.data.statusCode)
          // console.log(error.response.data.message);

          if(error.response.data.statusCode === 422){
            setAlertContent("New password cant be identical to old password")
            setAlert(false);

            setTimeout(() => {
              setFade("fade");
            }, 200);

            setTimeout(() => {
              setFade("fadeout");
            }, 4500);

            setTimeout(() => {
              setAlert(true);
            }, 5000);
          }

          if(error.response.data.statusCode === 500){
            setAlertContent("Expired Code, Please Re-send again")
            setAlert(false);

            setTimeout(() => {
              setFade("fade");
            }, 200);

            setTimeout(() => {
              setFade("fadeout");
            }, 4500);

            setTimeout(() => {
              setAlert(true);
            }, 5000);
          }

          if(error.response === undefined) {

            setAlertContent("Please call Admin")
            setAlert(false);

            setTimeout(() => {
              setFade("fade");
            }, 200);

            setTimeout(() => {
              setFade("fadeout");
            }, 4500);

            setTimeout(() => {
              setAlert(true);
            }, 5000);
          }
        });
    }
  };

  return (
    <React.Fragment>
      <Row style={{ backgroundColor: "white", width: "100%", height: "100vh", margin: "0"}}>
        <Col className="responsiveGone" lg={6} style={{ paddingLeft: "0" }}>
          <div className="h-100" style={{ padding: "20% 45px" }}>
            <div className="position-relative h-100 d-flex flex-column" style={{ justifyContent: "center" }}>
              <img src={forgetImg} alt="" style={{backgroundPosition: "center", backgroundRepeat: "no-repeat"}}/>
            </div>
          </div>
        </Col>

        <Col lg={6} style={{ paddingRight: "0", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center"}}>
          <div style={{ marginBottom: "50px", padding: "0px 25px" }}>
            <img src={logoFutureLink} alt="logoFutureLink" />
          </div>
          <div style={{display: "flex", width: "100%", flexDirection: "column", padding: "0px 100px"}}>
            <div>
              <p style={{ fontSize: "34px", fontWeight: "600", margin: "0" }}>
                Change Password
              </p>
            </div>

            <div className="mt-4">
            <Alert className={'alertRed ' + fade} hidden={alert}>{alertContent}</Alert>
                <div className="mb-3">
                    <Label className="form-label" htmlFor="password-input">New Password</Label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                      <Input onChange={handlePassword} type={show === false ? "password" : "text"} className="form-control pe-5" placeholder="Enter password" id="password-input" required/>
                      <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" onClick={() => {setShow(!show)}}><i className="ri-eye-fill align-middle"></i></button>
                    </div>
                </div>

                <div className="mb-3">
                    <Label className="form-label" htmlFor="password-input">Confirm Password</Label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                      <Input onChange={handleConfirmPassword} type={show === false ? "password" : "text"} className="form-control pe-5" placeholder="Enter password" id="password-input" required/>
                      <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" onClick={() => {setShow(!show)}}><i className="ri-eye-fill align-middle"></i></button>
                    </div>
                </div>

                <div className="mt-4">
                  <Button style={{backgroundColor: "#1F599C",borderColor: "#1F599C"}} color="success" className="w-100" onClick={resetPassword}>
                    Change Password
                  </Button>
                </div>
              
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default NewPassword;

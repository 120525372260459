import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import SamplePromo from "../../../../assets/images/FutureLink/samplePromo.jpg";
import SampleCard from "../../../../assets/images/FutureLink/sampleCard.jpg";

const Complementary = () => {


    return (
        <React.Fragment>
            <div className="page-content">

                <div className='layoutComplementary'>
                    <div className='layoutList'>
                        <p className='textH2 pe'>Promo</p>
                        <img src={SamplePromo}/>
                    </div>

                    <div className='layoutList'>
                        <p className='textH2'>Pilih jenis tes yang pengen kamu coba! </p>
                        <p className='textComp'>Nggak cuman test sidik jari aja, future link masih punya jenis test yang bisa kamu coba secara GRATIS!</p>
                        <div className='listCardTest'>

                            <div className='cardTest'>
                                <div className='innerCardTest'>
                                    <div className='informationTest'>
                                        <img src={SampleCard} className='imageTest'/>
                                        <p className='titleCardTest'>Standar Progressive Matrices Test</p>
                                        <p className='contentCardTest'>Standard Progressive Matrices (SPM) Test digunakan untuk mengukur kemampuan seseorang dalam mengerti dan melihat hubungan antara bagian-bagian gambar yang disajikan serta mengembangkan pola berpikir yang sistematis.</p>
                                    </div>
                                    <Link to={{pathname: "/complementary/spm"}} className='buttonTest'>Cobain Sekarang!</Link>
                                </div>
                            </div>

                            <div className='cardTest'>
                                <div className='disabler'></div>
                                <div className='innerCardTest'>
                                    <div className='informationTest'>
                                        <img src={SampleCard} className='imageTest'/>
                                        <p className='titleCardTest'>Standar Progressive Matrices Test</p>
                                        <p className='contentCardTest'>Standard Progressive Matrices (SPM) Test digunakan untuk mengukur kemampuan seseorang dalam mengerti dan melihat hubungan antara bagian-bagian gambar yang disajikan serta mengembangkan pola berpikir yang sistematis.</p>
                                    </div>
                                    <Link className='buttonTest'>Cobain Sekarang!</Link>
                                </div>
                            </div>

                            <div className='cardTest'>
                                <div className='disabler'></div>
                                <div className='innerCardTest'>
                                    <div className='informationTest'>
                                        <img src={SampleCard} className='imageTest'/>
                                        <p className='titleCardTest'>Standar Progressive Matrices Test</p>
                                        <p className='contentCardTest'>Standard Progressive Matrices (SPM) Test digunakan untuk mengukur kemampuan seseorang dalam mengerti dan melihat hubungan antara bagian-bagian gambar yang disajikan serta mengembangkan pola berpikir yang sistematis.</p>
                                    </div>
                                    <Link className='buttonTest'>Cobain Sekarang!</Link>
                                </div>
                            </div>

                            <div className='cardTest'>
                                <div className='disabler'></div>
                                <div className='innerCardTest'>
                                    <div className='informationTest'>
                                        <img src={SampleCard} className='imageTest'/>
                                        <p className='titleCardTest'>Standar Progressive Matrices Test</p>
                                        <p className='contentCardTest'>Standard Progressive Matrices (SPM) Test digunakan untuk mengukur kemampuan seseorang dalam mengerti dan melihat hubungan antara bagian-bagian gambar yang disajikan serta mengembangkan pola berpikir yang sistematis.</p>
                                    </div>
                                    <Link className='buttonTest'>Cobain Sekarang!</Link>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>


            </div>
        </React.Fragment>
    )

}

export default Complementary
import React, {useState} from "react";
import {Row, Col} from 'reactstrap';
import {Link} from 'react-router-dom';

import penglihatan from '../../../../../assets/images/FutureLink/penglihatan.png';
import pendengar from '../../../../../assets/images/FutureLink/pendengar.png';
import peraba from '../../../../../assets/images/FutureLink/peraba.png';
import pengecap from '../../../../../assets/images/FutureLink/pengecap.png';
import pencium from '../../../../../assets/images/FutureLink/pencium.png';

const IndraDasar = (e) => {
    const state = e.state.data
    const data = e.state.data
    const package_id = e.state.package_id
    const img_finger = e.state.img_finger
    const shape_finger = e.state.shape_finger
    const sight = state.sight
    const hear = state.hear
    const touch = state.touch
    const taste = state.taste
    const smell = state.smell

    return(
        <Row className="corev2 in">
            <div className="appearOnMobile tiwtiw" style={{marginBottom: '24px'}}>
                <Link to={{pathname: '/client/reportFP', state: { data: data, package_id: package_id, img_finger:img_finger, shape_finger:shape_finger },}} className="bBek">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M10.295 19.7155C10.3886 19.8077 10.4994 19.8806 10.6211 19.93C10.7429 19.9793 10.8731 20.0042 11.0045 20.0033C11.1358 20.0023 11.2657 19.9754 11.3867 19.9243C11.5076 19.8731 11.6173 19.7986 11.7095 19.705C11.8017 19.6115 11.8746 19.5007 11.9239 19.379C11.9733 19.2572 11.9982 19.127 11.9972 18.9956C11.9963 18.8643 11.9694 18.7344 11.9183 18.6134C11.8671 18.4925 11.7926 18.3827 11.699 18.2906L6.32903 13.0006H19.999C20.2642 13.0006 20.5186 12.8952 20.7061 12.7077C20.8937 12.5201 20.999 12.2658 20.999 12.0006C20.999 11.7353 20.8937 11.481 20.7061 11.2934C20.5186 11.1059 20.2642 11.0006 19.999 11.0006H6.33603L11.7 5.71355C11.884 5.52633 11.987 5.2743 11.9867 5.01183C11.9865 4.74936 11.8831 4.49751 11.6988 4.31061C11.5145 4.12371 11.2642 4.01675 11.0017 4.01281C10.7393 4.00887 10.4858 4.10827 10.296 4.28955L3.37203 11.1116C3.25411 11.2278 3.16048 11.3664 3.09657 11.5192C3.03267 11.672 2.99976 11.8359 2.99976 12.0016C2.99976 12.1672 3.03267 12.3311 3.09657 12.4839C3.16048 12.6367 3.25411 12.7753 3.37203 12.8916L10.296 19.7146L10.295 19.7155Z" fill="#374151"/>
                </svg>
                </Link>
                <p className="antitank" style={{textAlign: 'center'}}>Indra Dasar</p>
            </div>
            {/* <Row className='indradasarList'> */}
                <Col lg={5} className='indradasarItem'>
                    <img src={penglihatan} className='inIm'/>
                    <div className='itm'>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'max-content'}}>
                            <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Penglihatan</p>
                            <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{sight}%</p>
                        </div>

                        <p style={{margin: '0px', fontSize: '16px', textAlign: 'justify'}}><span style={{fontWeight: '900'}}>Indra penglihatan atau mata</span> merupakan alat yang mengirim rangsangan yang berasal dari apa yang kita lihat menuju ke otak. Mata berfungsi untuk memusatkan perhatian pada suatu objek, menyesuaikan cahaya yang masuk dan dapat digunakan sebagai media komunikasi.</p>
                    </div>
                </Col>

                <Col lg={5} className='indradasarItem'>
                    <img src={pendengar} className='inIm'/>
                    <div className='itm'>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'max-content'}}>
                            <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Pendengar</p>
                            <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{hear}%</p>
                        </div>

                        <p style={{margin: '0px', fontSize: '16px', textAlign: 'justify'}}><span style={{fontWeight: '900'}}>Indra pendengar atau telinga</span> merupakan alat yang memiliki kemampuan untuk mengetahui berbagai macam getaran bunyi. Telinga berfungsi untuk mendengarkan bunyi dan menjaga keseimbangan.</p>
                    </div>
                </Col>

                <Col lg={5} className='indradasarItem'>
                    <img src={peraba} className='inIm'/>
                    <div className='itm'>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'max-content'}}>
                            <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Peraba</p>
                            <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{touch}%</p>
                        </div>
                        <p style={{margin: '0px', fontSize: '16px', textAlign: 'justify'}}><span style={{fontWeight: '900'}}>Indra peraba atau kulit</span> merupakan alat untuk merasakan berbagai macam hal melalui sentuhan. Kulit berfungsi untuk jalan keluar keringat, tempat penyimpanan lemak, mengatur suhu tubuh, dan melindungi bagian tubuh di dalamnya.</p>
                    </div>
                </Col>

                <Col lg={5} className='indradasarItem'>
                    <img src={pengecap} className='inIm'/>
                    <div className='itm'>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'max-content'}}>
                            <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Pengecap</p>
                            <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{taste}%</p>
                        </div>

                        <p style={{margin: '0px', fontSize: '16px', textAlign: 'justify'}}><span style={{fontWeight: '900'}}>Indra pengecap atau lidah</span> merupakan alat yang digunakan untuk merasakan segala sesuatu yang masuk melalui mulut. Lidah berfungsi sebagai perasa, pembentuk huruf ketika berbicara, dan membantu proses pengunyahan dan menelan makanan.</p>
                    </div>
                </Col>

                <Col lg={5} className='indradasarItem'>
                    <img src={pencium} className='inIm'/>
                    <div className='itm'>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'max-content'}}>
                            <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Pencium</p>
                            <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{smell}%</p>
                        </div>

                        <p style={{margin: '0px', fontSize: '16px', textAlign: 'justify'}}><span style={{fontWeight: '900'}}>Indra pencium atau hidung</span> merupakan alat yang dapat menangkap berbagai macam aroma. Hidung berfungsi untuk mencium bau, menyaring udara, dan sebagai alat pernafasan.</p>
                    </div>
                </Col>
            {/* </Row> */}

        </Row>
    )
}

export default IndraDasar
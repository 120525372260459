import React, {useEffect, useState} from "react";
import axios from 'axios';

const ListVideo = () => {
    const [data, setData] = useState([])
    useEffect(() => {

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-videos`,
        };
          
        axios.request(config)
        .then((response) => {
            // console.log(response);
            setData(response.results);
        })
        .catch((error) => {
            console.log(error);
        });

    },[])

    return(
        <React.Fragment>
            <div className="page-content">
            <div className='news'>
                <p style={{fontSize: '18px', fontWeight: '600'}}>Video</p>
                <div className='newsList'>
                    {data.map((item, index) => (
                        <>
                            <div className='newsItem' key={index} onClick={()=>{window.open(item.url, "_blank")}}>
                                <img style={{}} src={item.clickbait}/>
                                <div className='articleKeterangan'>
                                    <p className='articleJudul'>{item.title}</p>
                                    <p className='articleTanggal'>{new Date(item.created_at).toLocaleDateString('id-ID', {day: 'numeric', month: 'long', year: 'numeric'})}</p>
                                </div>
                            </div>
                        </>
                    ))}
                </div>
            </div>

            </div>
        </React.Fragment>
    );
};

export default ListVideo;
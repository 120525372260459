import React, { useState, useEffect } from "react";

import SidebarDashboard from "./TemplateDashboard/sidebarDashboard.js";
import HeaderDashboard from "./TemplateDashboard/headerDashboard.js";

import ReportFPClient from "./MenuDashboard/reportFP.js";

const ClientReport = (e) => {
  const title = "Report";
  const state = e.location.state

  return (
    <div className="dashboardv2">
      <SidebarDashboard title={title} />
      <section className="fullwidth">
        <HeaderDashboard
          title={title}
          gate1={true}
          gate2={false}
          menu={"Hasil Report Sidik Jari"}
          submenu={""}
          state={state}
        />

        <main className="mainv2">
          <ReportFPClient state={state} />
        </main>
      </section>
    </div>
  );
};

export default ClientReport;

import React, {useState} from 'react';
import {Progress} from 'reactstrap';
import {Link} from 'react-router-dom';
import ReactApexChart from "react-apexcharts";

import distribusi from '../../../../assets/images/FutureLink/distribusi.png';

import LThumb from '../../../../assets/images/FutureLink/LThumb.png';
import LPoint from '../../../../assets/images/FutureLink/LPoint.png';
import LMiddle from '../../../../assets/images/FutureLink/LMiddle.png';
import LRing from '../../../../assets/images/FutureLink/LRing.png';
import LPinky from '../../../../assets/images/FutureLink/LPinky.png';
import RThumb from '../../../../assets/images/FutureLink/RThumb.png';
import RPoint from '../../../../assets/images/FutureLink/RPoint.png';
import RMiddle from '../../../../assets/images/FutureLink/RMiddle.png';
import RRing from '../../../../assets/images/FutureLink/RRing.png';
import RPinky from '../../../../assets/images/FutureLink/RPinky.png';


const DistribusiNeuron = (haisayang) => {
    if(haisayang.history.location.state === undefined){
        window.location.href = "/report"
    }

    const data = haisayang.history.location.state.data
    // console.log(haisayang.history.location.state.data)

    const packages = haisayang.history.location.state.package_id
    // console.log(haisayang.history.location.state.package_id)

    const think = Math.abs(data.think);
    const auditory = Math.abs(data.auditory);
    const action = Math.abs(data.action);
    const tactile = Math.abs(data.tactile);
    const visual = Math.abs(data.visual);
    
    return(
        <React.Fragment>
            <div className="page-content">
                <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                    <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 80px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Distribusi Neuron</p>
                </div>

                <div className='distribusineuron'>
                    <Link style={{width: 'max-content', marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to='/report'>Back</Link>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <img src={distribusi} className='distribusineuronMap' />
                    </div>

                    <div className='distribusineuronList'>
                        <div className='collection'>

                            <div className='item'>
                                <div className='value'>
                                    <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                        <img src={RThumb} />
                                        <p className='distribusineuronItemTitle'> Ibu Jari Kanan (R1) </p>
                                    </div>
                                    <p style={{fontSize: '24px', fontWeight: '900', color: '#05C38A', margin: '0px'}}>{data.intrapersonal}%</p>
                                </div>

                                <p className='keterangan'>Keterampilan intrapersonal merupakan kemampuan untuk memahami kekuatan serta kelemahan diri sendiri yang meliputi keterampilan untuk berpikir secara rasional serta mengatur koordinasi, pengendalian, dan pencapaian diri.</p>
                            </div>

                            <div className='item'>
                                <div className='value'>
                                    <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                        <img src={RPoint} />
                                        <p className='distribusineuronItemTitle'>Jari Telunjuk Kanan (R2) </p>
                                    </div>
                                    <p style={{fontSize: '24px', fontWeight: '900', color: '#05C38A', margin: '0px'}}>{data.logical}%</p>
                                </div>

                                <p className='keterangan'>Keterampilan logika merupakan kemampuan untuk menarik kesimpulan berdasarkan logika dan dapat dibuktikan dengan ilmu yang sudah diketahui yang meliputi kemampuan logika dan penalaran, serta analisis dan proses komputasi.</p>
                            </div>

                            <div className='item'>
                                <div className='value'>
                                    <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                        <img src={RMiddle} />
                                        <p className='distribusineuronItemTitle'>Jari Tengah Kanan (R3) </p>
                                    </div>
                                    <p style={{fontSize: '24px', fontWeight: '900', color: '#05C38A', margin: '0px'}}>{data.fine_motors}%</p>
                                </div>

                                <p className='keterangan'>Keterampilan motorik halus merupakan kemampuan yang melibatkan otot-otot kecil yang lebih menuntut koordinasi mata dan tangan yang meliputi keterampilan jari, mengidentifikasi tindakan, serta kontrol tangan.</p>
                            </div>

                            <div className='item'>
                                <div className='value'>
                                    <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                        <img src={RRing} />
                                        <p className='distribusineuronItemTitle'>Jari Manis Kanan (R4) </p>
                                    </div>
                                    <p style={{fontSize: '24px', fontWeight: '900', color: '#05C38A', margin: '0px'}}>{data.lingustic}%</p>
                                </div>

                                <p className='keterangan'>Keterampilan linguistik merupakan kemampuan untuk menyusun pikiran dan mampu mengungkapkan melalui kata-kata yang meliputi kemampuan dan pemahaman bahasa, pembentukan dan memori kata, serta komunikasi verbal.</p>
                            </div>

                            <div className='item'>
                                <div className='value'>
                                    <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                        <img src={RPinky} />
                                        <p className='distribusineuronItemTitle'>Jari Kelingking Kanan (R5) </p>
                                    </div>
                                    <p style={{fontSize: '24px', fontWeight: '900', color: '#05C38A', margin: '0px'}}>{data.naturalist}%</p>
                                </div>

                                <p className='keterangan'>Naturalistik merupakan kemampuan untuk mengenali, melihat perbedaan, menggolongkan, dan mengkategorikan apa yang dilihat atau dijumpai di alam dan lingkungan sekitarnya.</p>
                            </div>

                        </div>

                        <div className='collection'>

                            <div className='item'>
                                <div className='value'>
                                    <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                        <img src={LThumb} />
                                        <p className='distribusineuronItemTitle'> Ibu Jari Kiri (L1) </p>
                                    </div>
                                    <p style={{fontSize: '24px', fontWeight: '900', color: '#05C38A', margin: '0px'}}>{data.interpersonal}%</p>
                                </div>

                                <p className='keterangan'>Keterampilan interpersonal merupakan kemampuan untuk berinteraksi secara efektif dengan orang lain yang meliputi keterampilan dalam memimpin, belajar dalam kelompok, dan bekerja secara tim.</p>
                            </div>

                            <div className='item'>
                                <div className='value'>
                                    <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                        <img src={LPoint} />
                                        <p className='distribusineuronItemTitle'>Jari Telunjuk Kiri (L2) </p>
                                    </div>
                                    <p style={{fontSize: '24px', fontWeight: '900', color: '#05C38A', margin: '0px'}}>{data.imagination}%</p>
                                </div>

                                <p className='keterangan'>Keterampilan spasial merupakan kemampuan untuk menciptakan gambaran sesuatu berdasarkan kenyataan yang  meliputi imajinasi, pembentukan ide, visualisasi, serta berpikir kreatif.</p>
                            </div>

                            <div className='item'>
                                <div className='value'>
                                    <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                        <img src={LMiddle} />
                                        <p className='distribusineuronItemTitle'>Jari Tengah Kiri (L3) </p>
                                    </div>
                                    <p style={{fontSize: '24px', fontWeight: '900', color: '#05C38A', margin: '0px'}}>{data.gross_motors}%</p>
                                </div>

                                <p className='keterangan'>Keterampilan motorik kasar merupakan kemampuan yang melibatkan gerakan otot besar seperti otot lengan, kaki, atau bahkan otot di seluruh tubuhnya yang meliputi keterampilan dalam melakukan koordinasi gerakan serta bahasa tubuh.</p>
                            </div>

                            <div className='item'>
                                <div className='value'>
                                    <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                        <img src={LRing} />
                                        <p className='distribusineuronItemTitle'>Jari Manis Kiri (L4) </p>
                                    </div>
                                    <p style={{fontSize: '24px', fontWeight: '900', color: '#05C38A', margin: '0px'}}>{data.musical}%</p>
                                </div>

                                <p className='keterangan'>Keterampilan musikal merupakan kemampuan untuk mengelola musik serta emosi dari diri sendiri dan orang-orang di sekitar yang  meliputi kepekaan terhadap suara, ritme, nada, emosi dan perasaan, serta komunikasi non-verbal.</p>
                            </div>

                            <div className='item'>
                                <div className='value'>
                                    <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                        <img src={LPinky} />
                                        <p className='distribusineuronItemTitle'>Jari Kelingking Kiri (L5) </p>
                                    </div>
                                    <p style={{fontSize: '24px', fontWeight: '900', color: '#05C38A', margin: '0px'}}>{data.visual_spatial}%</p>
                                </div>

                                <p className='keterangan'>Keterampilan visual merupakan kemampuan seseorang untuk memahami, menyimpan, mengingat, dan menciptakan gambaran yang meliputi apresiasi visual, interpretasi estetika visual, seni visualisasi, serta introspeksi.</p>
                            </div>

                        </div>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                        <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 80px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Proses Berpikir</p>
                    </div>

                    <ReactApexChart
                        series={[ think, auditory, action, tactile, visual]}
                        options={{        
                            chart: {
                                height: 320,
                                type: 'donut',
                            },
                            labels: ['Think', 'Auditory', 'Action', 'Tactile', 'Visual'],
                            dataLabels: {
                                enabled: false
                            },
                            legend: {
                                position: 'bottom'
                            },
                            // colors: chartDonutupdatingColors
                            colors: ['#9C89B8', '#F0A6CA', '#EFC3E6', '#F0E6EF', '#B8BEDD'],
                        }}
                        type="donut"
                        height={320}
                    />

                    <div className='prosesberpikirList'>
                        <div>
                            <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                <div style={{width: '24px', height: '24px', backgroundColor: '#EFC3E6', borderRadius: '50px'}}></div>
                                <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>Action <span style={{marginLeft: '30px'}}>{action}%</span></p>
                            </div>

                            <div style={{marginTop: '10px'}}>
                                <p style={{margin: '0px', fontSize: '14px', textAlign: 'justify'}}>Proses bertindak ditunjukkan oleh <span style={{fontWeight: '900'}}>ibu jari</span> yang menggambarkan kemampuan untuk merencanakan dan melaksanakan tujuan. Selain itu juga menggambarkan cara menghadapi diri sendiri dan orang lain.</p>
                            </div>
                        </div>
                        <div>
                            <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                <div style={{width: '24px', height: '24px', backgroundColor: '#9C89B8', borderRadius: '50px'}}></div>
                                <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>Think <span style={{marginLeft: '30px'}}>{think}%</span></p>
                            </div>

                            <div style={{marginTop: '10px'}}>
                                <p style={{margin: '0px', fontSize: '14px', textAlign: 'justify'}}>Proses berpikir ditunjukkan oleh <span style={{fontWeight: '900'}}>jari telunjuk</span> yang menggambarkan kemampuan untuk menerapkan pemikiran logis dan imajiner. Selain itu juga menunjukkan cara berpikir setiap orang.</p>
                            </div>
                        </div>
                        <div>
                            <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                <div style={{width: '24px', height: '24px', backgroundColor: '#F0E6EF', borderRadius: '50px'}}></div>
                                <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>Tactile  <span style={{marginLeft: '30px'}}>{tactile}%</span></p>
                            </div>

                            <div style={{marginTop: '10px'}}>
                                <p style={{margin: '0px', fontSize: '14px', textAlign: 'justify'}}>Proses taktil ditunjukkan oleh <span style={{fontWeight: '900'}}>jari tengah</span> yang menggambarkan kemampuan dalam mengkoordinasikan gerak tubuh, gerakan tangan, dan koordinasi mata-tangan diri sendiri.</p>
                            </div>
                        </div>
                        <div>
                            <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                <div style={{width: '24px', height: '24px', backgroundColor: '#F0A6CA', borderRadius: '50px'}}></div>
                                <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>Auditory <span style={{marginLeft: '30px'}}>{auditory}%</span></p>
                            </div>

                            <div style={{marginTop: '10px'}}>
                                <p style={{margin: '0px', fontSize: '14px', textAlign: 'justify'}}>Proses auditori ditunjukkan oleh <span style={{fontWeight: '900'}}>jari manis</span> yang menggambarkan kemampuan untuk memproses informasi dengan mendengarkan dan berbicara. </p>
                            </div>
                        </div>
                        <div>
                            <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                <div style={{width: '24px', height: '24px', backgroundColor: '#B8BEDD', borderRadius: '50px'}}></div>
                                <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>Visual <span style={{marginLeft: '30px'}}>{visual}%</span></p>
                            </div>

                            <div style={{marginTop: '10px'}}>
                                <p style={{margin: '0px', fontSize: '14px', textAlign: 'justify'}}>Proses visual ditunjukkan oleh <span style={{fontWeight: '900'}}>jari kelingking</span> yang menggambarkan kemampuan untuk memvisualisasikan, membayangkan dan memahami berbagai hal. Selain itu juga berkaitan dengan pemrosesan informasi melalui mata setiap orang.</p>
                            </div>
                        </div>
                    </div>

                </div>

                <div style={{display: 'flex', justifyContent:'space-between', padding: '20px'}}>

                    {packages === 1 && (
                        <>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report/pola-sidik-jari", state: { data: data, package_id: packages }}}>{"<"} Pola Sidik Jari</Link>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report/dominasi-otak", state: { data: data, package_id: packages }}}>Dominasi Otak {">"}</Link>
                        </>
                    )}
                    {packages === 22 && (
                        <>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report/pola-sidik-jari", state: { data: data, package_id: packages }}}>{"<"} Pola Sidik Jari</Link>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report/dominasi-otak", state: { data: data, package_id: packages }}}>Dominasi Otak {">"}</Link>
                        </>
                    )}
                    {packages === 33 && (
                        <>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report/pola-sidik-jari", state: { data: data, package_id: packages }}}>{"<"} Pola Sidik Jari</Link>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report/tfrc", state: { data: data, package_id: packages }}}>TFRC {">"}</Link>
                        </>
                    )}
                    
                </div>


            </div>
        </React.Fragment>
    )

}

export default DistribusiNeuron;
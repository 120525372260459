import React, { useEffect, useState } from 'react';
import avatar1 from '../../../assets/images/FutureLink/pp.png'
import blanker from '../../../assets/images/FutureLink/blanker.png'
import { Card, Col, Container, Input, Label, Row ,Button, Alert, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import ReactInputVerificationCode from 'react-input-verification-code';

import axios from 'axios';
import FormData from 'form-data';

import {decodeToken} from "react-jwt";

import Lottie from 'react-lottie';
import animateSuccess from '../../../assets/images/FutureLink/success.json'

import accessDenied from '../../../assets/images/FutureLink/accessDenied.png'

const Profile = () => {
    // document.title="Widgets | Velzon - React Admin & Dashboard Template";
    const [modalAccessDenied, setModalAccessDenied] = useState(false);
    const logOut = () => {
      sessionStorage.removeItem('user');
      window.location.href = '/login'
    }

    const code = sessionStorage.getItem('user')
    const decode = decodeToken(code)
    // console.log(decode)

    const [fade, setFade] = useState('fadeout');
    const [alert, setAlert] = useState(true);
    const [kontenError, setKontenError] = useState("Check Again");

    const [firstName, setFirstName] = useState("");
    const [surName, setSurName] = useState("");
    const [file, setFile] = useState([]);
    const [picture, setPicture] = useState(blanker);
    const [email, setEmail] = useState("");
    const [checkEmail, setCheckEmail] = useState("");
    const [emailVerified, setEmailVerified] = useState("");
    const [phone, setPhone] = useState("");
    const [codeVerify, setCodeVerify] = useState("");

    const [modalCodeVerify, setModalCodeVerify] = useState(false);
    const [modalAlertSuccess, setModalAlertSuccess] = useState(false);

    useEffect(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-user?userId=${decode.userId}`,
            headers: { 
                'Authorization': `Bearer ${code}`
            }
        };
          
        axios(config)
            .then((response) => {
                // console.log(response);
                setFirstName(response.results.first_name)
                setSurName(response.results.last_name)
                setEmail(response.results.email)
                setCheckEmail(response.results.email)
                setEmailVerified(response.results.email_verified)
                setPhone(response.results.phone === "0000" || response.results.phone === "null" ? "" : response.results.phone)
                setPicture(response.results.profile_image === null ? avatar1 : response.results.profile_image)
            })
            .catch((error) => {
                // console.log(error);

                if(error.response.data.statusCode === 403) {
                    setModalAccessDenied(true)
                }
            });
    },[])

    const getVerify = () => {
        let data = JSON.stringify({
            "email": email
        });
          
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/verify-email`,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
        };
          
        axios(config)
            .then((response) => {
                // console.log(response);
                setModalCodeVerify(true)
            })
            .catch((error) => {
                // console.log(error);
                // console.log(error.response);
            });
    }

    const verify = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/email-verified?email=${email}&code=${codeVerify}`,
            headers: { 
              'Authorization': `Bearer ${code}`
            }
        };
          
        axios(config)
            .then((response) => {
                // console.log(response);
                setModalCodeVerify(false)
                setModalAlertSuccess(true)
                setTimeout(() => {
                    window.location.reload()
                    setModalAlertSuccess(false)
                }, 4000)
            })
            .catch((error) => {
                // console.log(error.response);
                if(error.response.data.statusCode === 404) {
                    setKontenError(error.response.data.message)
                    setAlert(false);
                    setTimeout(() => {
                        setFade('fade')
                    }, 200)
                    setTimeout(() => {
                        setFade('fadeout');
                    }, 4500)
                    setTimeout(() => {
                        setAlert(true);
                        setKontenError("Check Again")
                    }, 5000);
                }
                if(error.response.data.statusCode === 403) {
                    setModalAccessDenied(true)
                }
            });
    }

    const updateProfile = () => {
        // console.log(typeof phone)
        const check = phone.match(/[a-zA-Z]/) === null
        // console.log(check)

        if(firstName === "" || surName === "" || email === "" ) {
            setKontenError("Field cannot be empty");
            setAlert(false);

            setTimeout(() => {
                setFade('fade')
            }, 200)
    
            setTimeout(() => {
                setFade('fadeout');
            }, 4500)
    
            setTimeout(() => {
                setAlert(true);
                setKontenError("Check Again");
            }, 5000);


        } else if(email.includes("@") === false || email.includes(".") === false) {

            setKontenError("Email not found");
            setAlert(false);

            setTimeout(() => {
                setFade('fade')
            }, 200)
    
            setTimeout(() => {
                setFade('fadeout');
            }, 4500)
    
            setTimeout(() => {
                setAlert(true);
                setKontenError("Check Again");
            }, 5000);

        } else if(check === false) {

            setKontenError("Enter Phone Number");
            setAlert(false);

            setTimeout(() => {
                setFade('fade')
            }, 200)
    
            setTimeout(() => {
                setFade('fadeout');
            }, 4500)
    
            setTimeout(() => {
                setAlert(true);
                setKontenError("Check Again");
            }, 5000);
 
        } else { 
            let data = new FormData();
            data.append('firstName', firstName);
            data.append('lastName', surName);
            if(checkEmail !== email) {
                data.append('email', email);
            }
            if(phone !== "" && check === true){
                data.append('phone', phone);
            }
            if(file.length !== 0) {
                data.append('profileImage', file)
            }
    
            let config = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/update-user-token`,
            headers: { 
                'Authorization': `Bearer ${code}`, 
            },
            data : data
            };
    
            axios.request(config)
            .then((response) => {
            console.log(response);
            setModalAlertSuccess(true);
            setTimeout(() => {
                setModalAlertSuccess(false);
                window.location.reload();
            }, 4000)


            })
            .catch((error) => {
            // console.log(error);
            // console.log(error.response)
            if(error.response.data.statusCode === 403) {
                setModalAccessDenied(true)
            }
            setAlert(false);
    
            setTimeout(() => {
                setFade('fade')
            }, 200)
    
            setTimeout(() => {
                setFade('fadeout');
            }, 4500)
    
            setTimeout(() => {
                setAlert(true);
            }, 5000);
            });
        }
    }

    return ( 
        <React.Fragment>
            <div className="page-content">
                <div className='profileCover'>
                    <img className="rounded-circle profilePicture" src={picture} style={{width: '150px', height: '150px', backgroundColor: 'white'}}/>
                </div>
                <div className='profileWhiteSpace'>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                        <div className='profileTitle'>
                            <div>
                                <p style={{margin: '0px', fontSize: '30px', fontWeight: '600'}}>Profile</p>
                                <p style={{fontSize: '12px', color: '#555555'}}>Update your photo and personal details.</p>
                            </div>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{width: '40%'}}>
                                <p style={{margin: '0px', fontSize: '12px',fontWeight: '600'}}>Your Photo</p>
                                <p style={{margin: '0px', fontSize: '11px',fontWeight: '400'}}>This will be displayed on your profile</p>
                            </div>
                            <div style={{width: '60%'}}>
                                <div>
                                    <Alert className={'alertRed ' + fade} hidden={alert}>{kontenError}</Alert>
                                    <Label htmlFor='photo' style={{margin: '0px', cursor: 'pointer'}}>
                                        {file === undefined || file.length === 0 ? (
                                            <>
                                            <img src={picture} style={{width: '42px', height: '42px', borderRadius: '100%', objectFit: 'cover'}} />
                                            </>
                                        ) : (
                                            <>
                                            <img src={URL.createObjectURL(file)} style={{width: '42px', height: '42px', borderRadius: '100%', objectFit: 'cover'}} />
                                            </>
                                        )}

                                        <input type="file" id="photo" onChange={(event) => {setFile(event.target.files[0])}} hidden/>
                                    </Label>
                                </div>
                            </div>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{width: '40%'}}>
                                <p style={{margin: '0px', fontSize: '12px',fontWeight: '600'}}>Firstname</p>
                            </div>
                            <div style={{width: '60%'}}>
                                <div>
                                    <Input type="text" value={firstName} onChange={(event) => {setFirstName(event.target.value)}} className="form-control" id="name" placeholder="Enter Name" required/>
                                </div>
                            </div>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{width: '40%'}}>
                                <p style={{margin: '0px', fontSize: '12px',fontWeight: '600'}}>Surname</p>
                            </div>
                            <div style={{width: '60%'}}>
                                <div>
                                    <Input type="text" value={surName} onChange={(event) => {setSurName(event.target.value)}} className="form-control" id="name" placeholder="Enter Name" required/>
                                </div>
                            </div>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{width: '40%'}}>
                                <p style={{margin: '0px', fontSize: '12px',fontWeight: '600'}}>Email</p>
                            </div>
                            <div style={{width: '60%'}}>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                                    <Input type="text" value={email} onChange={(event) => {setEmail(event.target.value)}} className="form-control" id="email" placeholder="Enter Email" required/>
                                    {emailVerified === 0 && (
                                        <>
                                        <div className='verifikasi' onClick={() => {getVerify()}}>Verification !</div>
                                        </>
                                    )}
                                    {emailVerified === 1 && (
                                        <>
                                        <div className='verifikasiValid'>Verified!</div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{width: '40%'}}>
                                <p style={{margin: '0px', fontSize: '12px',fontWeight: '600'}}>Phone</p>
                            </div>
                            <div style={{width: '60%'}}>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                                    <Input type="text" value={phone} onChange={(event) => {setPhone(event.target.value)}}  className="form-control" id="email" placeholder="Enter Number Phone" required/>
                                </div>
                            </div>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <div>
                                <button className='btn buttonVersFutureLink' onClick={() => {updateProfile()}}>Update Profile</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <Modal isOpen={modalAccessDenied} toggle={() => {}} centered>
                <ModalBody className='modalCenter'> 
                    <img src={accessDenied} style={{width: '30%'}}/>
                    <p style={{fontSize: '24px', fontWeight: '700', marginTop: '20px'}}>Access Denied</p>
                    <p style={{margin: '0px', color: 'grey'}}>You currently does not have access to this page. <br/> Please Login Again</p>
                </ModalBody>
                <ModalFooter style={{display: 'flex', justifyContent: 'center', gap: '80px'}}>
                    <button className='btn buttonVersFutureLink' style={{fontSize: '14px', fontWeight: '500'}} onClick={() => {logOut()}}>Login Again</button>
                </ModalFooter>
            </Modal>


            <Modal isOpen={modalCodeVerify} toggle={() => {setModalCodeVerify(!modalCodeVerify)}} centered>
                <ModalBody className='modalCenter' style={{margin: '0px'}}>
                    <div style={{padding: '40px 0px'}}>
                        <Alert className={'alertRed ' + fade} hidden={alert}>{kontenError}</Alert>
                        <p style={{fontSize: '16px', fontWeight: '600', color: '#4B5563'}}>Enter your verification code</p>
                        <p style={{fontSize: '14px', fontWeight: '400', color: '#4B5563', marginBottom: '30px'}}>We send a verification code to <br/> {email}</p>
                        <ReactInputVerificationCode onChange={(event) => {setCodeVerify(event)}}/>
                        <button style={{marginTop: '30px', fontWeight: '900', fontSize: '16px'}} className='btn buttonVersFutureLink' onClick={() => {verify()}}> Verify </button>
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={modalAlertSuccess} toggle={() => {setModalAlertSuccess(!modalAlertSuccess)}} centered>
                <ModalBody className='modalCenter' style={{margin: '0px'}}>
                    <Lottie options={{loop: true, autoplay: true, animationData: animateSuccess, rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} height={350} width={350} isClickToPauseDisabled={true} />
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default Profile;
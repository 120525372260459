import React , {useEffect, useState} from 'react'
import HeaderDashboard from "./TemplateDashboard/headerDashboard.js";
import {Input, Label, Modal, ModalHeader, ModalBody} from 'reactstrap';
import Lottie from 'react-lottie';
import axios from 'axios';
import Flatpickr from "react-flatpickr";
import { decodeToken } from "react-jwt";

import o from '../../../assets/images/FutureLink/ornamen.svg';

import p from '../../../assets/images/FutureLink/pay3d.png';
import cw from '../../../assets/images/FutureLink/purA.svg';
import m from '../../../assets/images/FutureLink/mastercard.svg';

import accessLoading from '../../../assets/images/FutureLink/loading.json';


const ProcessPurchase = (ew) => {
    const state = ew.location.state
    const pack = state.pack
    const pack_id = state.pack_id
    const harga_pack = state.harga
    const is_counselor = state.is_counselor
    const email = state.email
    const phone = state.phone
    const title = "Pesanan"
    const code = sessionStorage.getItem('user');
    const breakCode = decodeToken(code);

    const [confirm, setConfirm] = useState(false);

    /* ----- Counselor Box ----- */
    const [configCounselor, setConfigCounselor] = useState(0);
    const [jaringan, setJaringan] = useState("Offline");
    const [counselor, setCounselor] = useState([]);
    const [counselorId, setCounselorId] = useState("");
    // const [loadingPackage, setLoadingPackage] = useState(true);
    const [loadingCounselor, setLoadingCounselor] = useState(true);
    const [loadingDate, setLoadingDate] = useState(true);
    const [loadingTime, setLoadingTime] = useState(true);
    const [loadingList, setLoadingList] = useState(true);

    const [checkbox, setCheckbox] = useState(false);
    const [modalCheckbox, setModalCheckbox] = useState(false);

    const [nama, setNama] = useState("");
    const [harga, setHarga] = useState(0);
    const [image, setImage] = useState("");
    const [selectDate, setSelectDate] = useState("");
    const [selectTime, setSelectTime] = useState("");

    const [morning1, setMorning1] = useState(true);
    const [morning2, setMorning2] = useState(true);
    const [morning3, setMorning3] = useState(true);
    const [afternoon1, setAfternoon1] = useState(true);
    const [afternoon2, setAfternoon2] = useState(true);
    const [evening1, setEvening1] = useState(true);
    const [evening2, setEvening2] = useState(true);
    const [h, setH] = useState(true);
    const [i, setI] = useState(true);

    const [cuti, setCuti] = useState([]);

    function toggleStatus() {
        const toggleButton = document.getElementById("toggleButton");
        const status = document.getElementById("statuse");
        if (toggleButton.checked) {
            status.innerText = "Online";
            getAllCounselor("online");
            setLoadingList(true);
            setJaringan("Online")
        } else {
            status.innerText = "Offline";
            getAllCounselor("offline");
            setLoadingList(true);
            setJaringan("Offline")
        }
    }

    const [randomNumber, setRandomNumber] = useState("");
    function random_kode(string_length) {
        var random_string = "";
        var characters = "01234567890123456789";
        for (var i = 0; i < string_length; i++) {
            random_string += characters.charAt(
            Math.floor(Math.random() * characters.length)
          );
        }
        // setRandomNumber(random_string);

        return random_string
    }
    const generate = () => {
        random_kode(7);
    };
    const coba = (event) => {
        setCheckbox(event.target.checked);
        setConfigCounselor(0);
        setSelectDate("");
        setJaringan('Offline');
        getAllCounselor('offline');
        setSelectTime("");
        setNama("");
        setHarga(0)

        if(event.target.checked === true) {
            setModalCheckbox(true);
        }
    }

    useEffect(() => {
        const midtransScriptUrl = process.env.REACT_APP_MIDTRANS_URL
        const myMidtransClientKey = process.env.REACT_APP_MIDTRANS_CLIENT_KEY

        let scriptTag = document.createElement('script');
        scriptTag.src = midtransScriptUrl;
        scriptTag.setAttribute('data-client-key', myMidtransClientKey);
      
        document.body.appendChild(scriptTag);
      
        return () => {
          document.body.removeChild(scriptTag);
        }
    },[])

    useEffect(() => {
        getAllCounselor("offline");
    },[])

    const getAllCounselor = (jaringan) => {
        setLoadingCounselor(true)
        
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-counselor?via=${jaringan}&status=active`,
            headers: { 
                'Authorization': `Bearer ${code}`
            }
        };
          
        axios.request(config)
            .then((response) => {
            // console.log(response.results);
            setCounselor(response.results);
            setTimeout(() => {
                setLoadingCounselor(false)
            },1000)
            
            })
            .catch((error) => {
            // console.log(error);
            });
    }

    const getScheduleCounselor = (counselorId) => {
        setLoadingDate(true);
        setSelectDate("");

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-counselor-schedule?counselorId=${counselorId}`,
            headers: { 
              'Authorization': `Bearer ${code}`
            }
        };
          
        axios.request(config)
            .then((response) => {
            // console.log(response);
            setCuti(response.results.leaves)
            setTimeout(() => {
                setLoadingDate(false);
            }, 2000)
            })
            .catch((error) => {
            // console.log(error);
            });
    }

    const getScheduleCounselorOnDate = (ondate) => {
        if(ondate){
            setLoadingTime(true);

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-counselor-schedule-on-date?counselorId=${counselorId}&date=${ondate}`,
                headers: { 
                  'Authorization': `Bearer ${code}`
                }
            };
              
            axios.request(config)
                .then((response) => {
                    // console.log(response.results);
                    setMorning1(response.results.morning.includes(1));
                    setMorning2(response.results.morning.includes(2));
                    setMorning3(response.results.morning.includes(3));
                    setAfternoon1(response.results.afternoon.includes(1));
                    setAfternoon2(response.results.afternoon.includes(2));
                    setEvening1(response.results.evening.includes(1));
                    setEvening2(response.results.evening.includes(2));
                    setH(response.results.night.includes(1));
                    setI(response.results.night.includes(2));

                    setTimeout(() => {
                        setLoadingTime(false);
                    }, 2000)
                    
                })
                .catch((error) => {
                    // console.log(error);
                });
        }
    }

    const dor = () => {
        if(checkbox === false) {

            let data = JSON.stringify({
                transaction_details: {
                    order_id: pack_id === 3 && nama !== "" ? `${breakCode.userId}-${pack_id}-manual-${random_kode(7)}` : `${breakCode.userId}-${pack_id}-AI-${random_kode(7)}`,
                    gross_amount: harga_pack+harga,
                },
                customer_details: {
                    first_name: breakCode.firstName,
                    last_name: breakCode.lastName,
                    email: email,
                    phone: phone === null || phone === "null" || phone === "0000" ? "" : phone,
                }
            });


            // console.log(data)
    
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                // url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/charge`,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/charge`,
                headers: { 
                    'Content-Type': 'application/json'
                },
                data: data
            };
    
            axios.request(config)
                .then((response) => {
                    window.snap.pay(response.token);
                })
                .catch((error) => {
                    // console.log(error);
                });

        } else {

            let data = new URLSearchParams();
            data.append('counselorId', counselorId);
            data.append('date', new Date(selectDate).getFullYear() + "-" + (new Date(selectDate).getMonth()+1 > 9 ? new Date(selectDate).getMonth()+1 : "0"+(new Date(selectDate).getMonth()+1)) + "-" + (new Date(selectDate).getDate() > 9 ? new Date(selectDate).getDate() : "0"+new Date(selectDate).getDate()))
            data.append('time', selectTime);
            data.append('via', jaringan.toLowerCase())
                
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/make-schedule`,
                headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded', 
                    'Authorization': `Bearer ${code}`
                },
                data : data
            };
            
            axios.request(config)
                .then((response) => {
                // console.log(response);

                let data = JSON.stringify({
                    transaction_details: {
                        order_id: pack_id === 3 && nama !== "" ? `${breakCode.userId}-${pack_id}-manual-${random_kode(7)}` : `${breakCode.userId}-${pack_id}-AI-${random_kode(7)}`,
                        gross_amount: harga_pack+harga,
                    },
                    customer_details: {
                        first_name: breakCode.firstName,
                        last_name: breakCode.lastName,
                        email: email,
                        phone: phone === null || phone === "null" || phone === "0000" ? "" : phone,
                    }
                });
                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    // url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/charge`,
                    url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/charge`,
                    headers: { 
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                axios.request(config)
                    .then((response) => {
                        // console.log(response);
                        window.snap.pay(response.token);
                    })
                    .catch((error) => {
                        // console.log(error);
                    });
                })
                .catch((error) => {
                // console.log(error);
                });

        }

    }

    const getVoucher = () => {

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-voucher?voucher=${code}`,
            headers: { 
                'Authorization': `Bearer ${code}`
            }
        };

        axios.request(config)
        .then((response) => {
            // console.log(JSON.stringify(response.data));
            console.log(response)
        })
        .catch((error) => {
            console.log(error);
        });
    }

    return(
        <>
            <HeaderDashboard  title={title} gate1={false} gate2={false} menu={""} submenu={""} />
            <div className={confirm == false ?'backgroundPurchase' : 'backgroundConfirm'}>
                {confirm == false ? (
                    <>
                    <div className='pLe'>
                        <div className='pCrd'>
                            <img src={cw}></img>
                            <div className='pCK'>
                                <p>Paket {pack}</p>
                                <span>Rp. {harga_pack.toLocaleString('id-ID')},00</span>
                            </div>
                        </div>
                        <div className={is_counselor === true ? 'mw': 'dontsee'}>
                            <div className='linCheckbox'>
                                <Input className='form-check-input' type='checkbox' id='check' checked={checkbox} onClick={coba} />
                                <Label className='form-check-label' for='check' >Tambah sesi konsultasi  dengan estimasi waktu 40-60 menit</Label>
                            </div>
                            <p className='blueunderline'>Pilih Jadwal</p>
                        </div>
                        {/* <div className='linKodeDiskon'> */}
                        <div className='linKodeDiskon'>
                            <h5>Kode Promo</h5>
                            <div className='butDis'>
                                <div className='bds'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M21.3 10.8399C21.69 10.8399 22 10.5299 22 10.1399V9.20986C22 5.10986 20.75 3.85986 16.65 3.85986H7.35C3.25 3.85986 2 5.10986 2 9.20986V9.67986C2 10.0699 2.31 10.3799 2.7 10.3799C3.6 10.3799 4.33 11.1099 4.33 12.0099C4.33 12.9099 3.6 13.6299 2.7 13.6299C2.31 13.6299 2 13.9399 2 14.3299V14.7999C2 18.8999 3.25 20.1499 7.35 20.1499H16.65C20.75 20.1499 22 18.8999 22 14.7999C22 14.4099 21.69 14.0999 21.3 14.0999C20.4 14.0999 19.67 13.3699 19.67 12.4699C19.67 11.5699 20.4 10.8399 21.3 10.8399ZM9 8.87986C9.55 8.87986 10 9.32986 10 9.87986C10 10.4299 9.56 10.8799 9 10.8799C8.45 10.8799 8 10.4299 8 9.87986C8 9.32986 8.44 8.87986 9 8.87986ZM15 15.8799C14.44 15.8799 13.99 15.4299 13.99 14.8799C13.99 14.3299 14.44 13.8799 14.99 13.8799C15.54 13.8799 15.99 14.3299 15.99 14.8799C15.99 15.4299 15.56 15.8799 15 15.8799ZM15.9 9.47986L9.17 16.2099C9.02 16.3599 8.83 16.4299 8.64 16.4299C8.45 16.4299 8.26 16.3599 8.11 16.2099C7.82 15.9199 7.82 15.4399 8.11 15.1499L14.84 8.41986C15.13 8.12986 15.61 8.12986 15.9 8.41986C16.19 8.70986 16.19 9.18986 15.9 9.47986Z" fill="#10B981"/>
                                </svg>
                                    <p>GRATISDONG24</p>
                                </div>
                                <p className='gC'>Apply</p>
                            </div>
                        </div>
                        <div className='linKodeDiskon'>
                            <h5>Kode Promo</h5>
                            <div className='butDis'>
                                <div className='bds'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M21.3 10.8399C21.69 10.8399 22 10.5299 22 10.1399V9.20986C22 5.10986 20.75 3.85986 16.65 3.85986H7.35C3.25 3.85986 2 5.10986 2 9.20986V9.67986C2 10.0699 2.31 10.3799 2.7 10.3799C3.6 10.3799 4.33 11.1099 4.33 12.0099C4.33 12.9099 3.6 13.6299 2.7 13.6299C2.31 13.6299 2 13.9399 2 14.3299V14.7999C2 18.8999 3.25 20.1499 7.35 20.1499H16.65C20.75 20.1499 22 18.8999 22 14.7999C22 14.4099 21.69 14.0999 21.3 14.0999C20.4 14.0999 19.67 13.3699 19.67 12.4699C19.67 11.5699 20.4 10.8399 21.3 10.8399ZM9 8.87986C9.55 8.87986 10 9.32986 10 9.87986C10 10.4299 9.56 10.8799 9 10.8799C8.45 10.8799 8 10.4299 8 9.87986C8 9.32986 8.44 8.87986 9 8.87986ZM15 15.8799C14.44 15.8799 13.99 15.4299 13.99 14.8799C13.99 14.3299 14.44 13.8799 14.99 13.8799C15.54 13.8799 15.99 14.3299 15.99 14.8799C15.99 15.4299 15.56 15.8799 15 15.8799ZM15.9 9.47986L9.17 16.2099C9.02 16.3599 8.83 16.4299 8.64 16.4299C8.45 16.4299 8.26 16.3599 8.11 16.2099C7.82 15.9199 7.82 15.4399 8.11 15.1499L14.84 8.41986C15.13 8.12986 15.61 8.12986 15.9 8.41986C16.19 8.70986 16.19 9.18986 15.9 9.47986Z" fill="#10B981"/>
                                </svg>
                                    <p>GRATISDONG24</p>
                                </div>
                                <p className='gC'>Apply</p>
                            </div>
                        </div>
                        <div className='linTo'>
                            <div className='linTo1'>
                                <p>Subtotal</p>
                                <p className='p'>Rp {harga_pack.toLocaleString('id-ID')},00</p>
                            </div>
                            <div className={checkbox === true ? 'linTo1' : 'dontsee'}>
                                <p>Biaya Konsultasi</p>
                                <p className='p'>Rp {harga.toLocaleString('id-ID')},00</p>
                            </div>
                            <div className='dontsee'>
                                <p>Diskon (50%)</p>
                                <p className='p'>Rp 114.500,00</p>
                            </div>

                            <div className='linToS'>
                                <p>Total</p>
                                <p className='p'>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(harga_pack + harga)}</p>
                            </div>
                        </div>

                        <button className="buttonTest" onClick={() => {dor(); generate()}}>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(harga_pack + harga)}</button>
                    </div>
                    <div className='pRi dontsee'>

                        <div className='jf'>
                            <div className='inE'>
                                <p>Email</p>
                                <input type='text'/>
                            </div>
                            <div className='inE'>
                                <p>Nomor Ponsel</p>
                                <input type='text'/>
                            </div>
                            <div className='paybayar'>
                                <p className='pw'>Metode Pembayaran</p>
                                <div className='listPayBay'>
                                    <div className='listPBIt'>
                                        <input type='radio'></input>
                                        <img src={m}/>
                                        <div>
                                            <p className='p1'>Credit/Debit Card</p>
                                            <p>Bayar dengan Visa, MasterCard, JCB, or Amex</p>
                                        </div>
                                    </div>
                                    <div className='listPBIt'>
                                        <input type='radio'></input>
                                        <img src={m}/>
                                        <div>
                                            <p className='p1'>Credit/Debit Card</p>
                                            <p>Bayar dengan Visa, MasterCard, JCB, or Amex</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="buttonTest" onClick={() => {setConfirm(true)}}>Nominal angka</button>
                    </div>
                    </>
                ) : (
                    <>
                    <div className='menungguBayar'>
                        <img src={p}/>
                        <p>Menunggu Pembayaran...</p>
                    </div>
                    <div className='pisahhan' style={{zIndex: '10'}}>
                        <div>
                            <div className='pCrd'>
                                <img src={cw}></img>
                                <div className='pCK'>
                                    <p>Paket {pack}</p>
                                    <span>Rp. {harga_pack.toLocaleString('id-ID')},00</span>
                                </div>
                            </div>
                            <div className={is_counselor === true ? 'mw': 'dontsee'}>
                                <div className='linCheckbox'>
                                    <input type="checkbox"/>
                                    <p>Tambah sesi konsultasi  dengan estimasi waktu 40-60 menit</p>
                                </div>
                                <p className='blueunderline'>Pilih Jadwal</p>
                            </div>
                        </div>
                        <div className='piw2'>
                            <div className='linTo'>
                                <div>
                                    <p>Subtotal</p>
                                    <p className='p'>Rp {harga_pack.toLocaleString('id-ID')},00</p>
                                </div>
                                <div>
                                    <p>Biaya Konsultasi</p>
                                    <p className='p'>Rp 100.000,00</p>
                                </div>
                                <div>
                                    <p>Diskon (50%)</p>
                                    <p className='p'>Rp 114.500,00</p>
                                </div>

                                <div className='s'>
                                    <p>Ket</p>
                                    <p className='p'>Rp 114.500,00</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="buttonTest" style={{zIndex: '10'}}>Konfirmasi Pembayaran</button>
                    </>
                )}

                <img src={o} style={confirm == false ? {position: 'absolute', width: '100%'} : {position: 'absolute', bottom: '0', width: '100%'}}/>
            <Modal isOpen={modalCheckbox} toggle={() => {setModalCheckbox(!modalCheckbox); setCheckbox(false); setHarga(0)}} centered>
                <ModalHeader toggle={()=> {setModalCheckbox(false); setCheckbox(false); setHarga(0)}}><h5 className='modal-title fontJakarta' style={{fontSize: '18px', color: '#4B5563'}}>Choose Conselor</h5></ModalHeader>
                <ModalBody>

                    {configCounselor === 0 && (
                        <>

                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '30px'}}>

                            <label className="switch">
                                <input type="checkbox" onChange={() => {toggleStatus()}} id="toggleButton"/>
                                <span className="slider round"></span>
                            </label>
                            <p style={{marginBottom: '0px', fontSize: '14px'}} id="statuse">Offline</p>

                        </div>

                        <div>

                    {loadingCounselor === true ? (
                        <>
                            <Lottie width={'30%'} options={{loop: true, autoplay: true,animationData: accessLoading,rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} isClickToPauseDisabled={true} />
                        </>
                    ) : (
                        <>

                        {counselor !== undefined && counselor.map((item, index) => (
                            <>
                                <div key={index} style={{display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '10px', cursor: 'pointer'}} onClick={() => {setConfigCounselor(1); setNama(item.name); setHarga(item.price); setImage(item.image); setCounselorId(item.counselors_id); getScheduleCounselor(item.counselors_id)}}>
                                    <img src={item.image} style={{borderRadius: '50%', width: '64px', height: '64px', objectFit: 'cover'}}/>
                                    <p style={{marginBottom: '0px', fontSize: '18px'}}>{item.name}</p>
                                </div>
                            </>
                        ))}
                        
                        </>
                    )}
                        </div>
                        
                        </>
                    )}

                    {configCounselor === 1 && (
                        <>
                        <button className='btn buttonVersFutureLinkBorderGrey' onClick={() => {setConfigCounselor(0); getAllCounselor("offline");}}>Back</button>
                        <div style={{display: 'flex', margin: '20px 5px', alignItems: 'center', gap: '20px'}}>
                            <img src={image} style={{borderRadius: '50%', width: '64px', height: '64px', objectFit: 'cover'}} />
                            <div>
                            <p style={{marginBottom: '0px', fontSize: '18px', fontWeight: '600'}}>{jaringan}</p>
                            <p style={{marginBottom: '0px', fontSize: '18px'}}>{nama}</p>
                            <p style={{marginBottom: '0px', fontSize: '18px'}}>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(harga,)}</p>
                            </div>
                        </div>


                        {loadingDate === true ? (
                            <>
                            <Lottie width={'20%'} options={{loop: true, autoplay: true,animationData: accessLoading,rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} isClickToPauseDisabled={true} />
                            </>
                        ) : (
                            <>
                            <div className="mb-3">
                                <Label className="form-label" style={{fontSize: '14px'}}>Choose Date Counselor</Label>
                                <Flatpickr
                                    className="form-control"
                                    options={{
                                        disable: cuti,
                                        dateFormat: "Y-m-d",
                                        minDate: new Date().fp_incr(1),
                                        maxDate: new Date().fp_incr(30),
                                    }}
                                    onChange={(event, dateStr) => {
                                        setSelectDate(event[0]);
                                        //  console.log(event); 
                                         getScheduleCounselorOnDate(dateStr);
                                        }}
                                    placeholder="Select Date"
                                />
                            </div>
                            </>
                        )}


                        {selectDate !== "" && (
                            <>
                            <p style={{margin:'10px 0px', fontSize: '14px', fontWeight: '500'}}>Pilih Waktu Counselor</p>

                            {loadingTime === true ? (
                                <>
                                    <Lottie width={'20%'} options={{loop: true, autoplay: true,animationData: accessLoading,rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} isClickToPauseDisabled={true} />
                                </>
                            ) : (
                                <>

                                <div style={{padding: '0px 10px'}}>

                                <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Pagi:</p>
                                <div style={{display: 'flex', gap: '20px'}}>
                                    <button className='btn buttonVersFutureLink waktuCouns' onClick={() => {setModalCheckbox(false); setSelectTime("morning-1")}} disabled={morning1 === false ? true : false}>09.00 - 10.00</button>
                                    <button className='btn buttonVersFutureLink waktuCouns' onClick={() => {setModalCheckbox(false); setSelectTime("morning-2")}} disabled={morning2 === false ? true : false}>10.00 - 11.00</button>
                                    <button className='btn buttonVersFutureLink waktuCouns' onClick={() => {setModalCheckbox(false); setSelectTime("morning-3")}} disabled={morning3 === false ? true : false}>11.00 - 12.00</button>
                                </div>
                                <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Siang:</p>
                                <div style={{display: 'flex', gap: '20px'}}>
                                    <button className='btn buttonVersFutureLink waktuCouns' onClick={() => {setModalCheckbox(false); setSelectTime("afternoon-1")}} disabled={afternoon1 === false ? true : false}>13.00 - 14.00</button>
                                    <button className='btn buttonVersFutureLink waktuCouns' onClick={() => {setModalCheckbox(false); setSelectTime("afternoon-2")}} disabled={afternoon2 === false ? true : false}>14.00 - 15.00</button>
                                </div>
                                <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Sore:</p>
                                <div style={{display: 'flex', gap: '20px'}}>
                                    <button className='btn buttonVersFutureLink waktuCouns' onClick={() => {setModalCheckbox(false); setSelectTime("evening-1")}} disabled={evening1 === false ? true : false}>15.00 - 16.00</button>
                                    <button className='btn buttonVersFutureLink waktuCouns' onClick={() => {setModalCheckbox(false); setSelectTime("evening-2")}} disabled={evening2 === false ? true : false}>16.00 - 17.00</button>
                                </div>
                                <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Malam:</p>
                                <div style={{display: 'flex', gap: '20px'}}>
                                    <button className='btn buttonVersFutureLink waktuCouns' onClick={() => {setModalCheckbox(false); setSelectTime("night-1")}} disabled={h === false ? true : false}>19.00 - 20.00</button>
                                    <button className='btn buttonVersFutureLink waktuCouns' onClick={() => {setModalCheckbox(false); setSelectTime("night-2")}} disabled={i === false ? true : false}>20.00 - 21.00</button>
                                </div>

                                </div>

                                </>
                            )}

                            </>
                        )}
                        </>
                    )}

                </ModalBody>
            </Modal>

            </div>


        </>
    )
}

export default ProcessPurchase
import React, { useEffect, useState } from "react";
import axios from "axios";

const ListComplementary = () => {
  const [data, setData] = useState([]);
  const code = sessionStorage.getItem("user");

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-test`,
      headers: {
        Authorization: `Bearer ${code}`,
      },
      params: { orderBy: "DESC" },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response);
        setData(response.results);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="news">
          <p style={{ fontSize: "18px", fontWeight: "600" }}>Video</p>
          <div className="newsList">
            {data.map((item, index) => (
              <>
                <div
                  className="newsItem"
                  key={index}
                  onClick={() => {
                    window.open(
                      "complementary/spm-trial/" + item.test_id + "/" + code,
                      "_blank"
                    );
                  }}
                >
                  {/* <img style={{}} src={item.clickbait} /> */}
                  <div className="articleKeterangan">
                    <p className="articleJudul">{item.name}</p>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ListComplementary;

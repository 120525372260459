import React, {useState} from "react";
import {Row, Col, Progress} from 'reactstrap';
import {Link} from 'react-router-dom';
import ReactApexChart from "react-apexcharts";

import whorl from '../../../../../assets/images/FutureLink/whorl.svg'
import loop from '../../../../../assets/images/FutureLink/loop.svg'
import arc from '../../../../../assets/images/FutureLink/arc.svg'
import dove from '../../../../../assets/images/FutureLink/dove_dope.png'
import eagle from '../../../../../assets/images/FutureLink/eagle_dope.png'
import peacock from '../../../../../assets/images/FutureLink/peacock_dope.png'
import owl from '../../../../../assets/images/FutureLink/owl_dope.png'


const Summary = (e) => {
    const [next, setNext] = useState(1)
    const state = e.state.data
    const data = e.state.data
    const package_id = e.state.package_id
    // const package_id = 1
    const img_finger = e.state.img_finger
    const shape_finger = e.state.shape_finger

    // Dominasi Sidik Jari
    const fingerType = [
        {
            name: "Tidak Diketahui",
            value: "nothing",
        },
        {
            name: "Whorl Target",
            value: "W_centric",
            nick: "WT",
            ket: "Whorl",
        },
        {
            name: "Whorl Spiral",
            value: "W_spiral",
            nick: "WS",
            ket: "Whorl",
        },
        {
            name: "Whorl Elongated Centric",
            value: "W_elongated_centric",
            nick: "WTE",
            ket: "Whorl",
        },
        {
            name: "Whorl Elongated Spiral",
            value: "W_elongated_spiral",
            nick: "WSE",
            ket: "Whorl",
        },
        {
            name: "Whorl Imploding",
            value: "W_imploding",
            nick: "WI",
            ket: "Whorl",
        },
        {
            name: "Whorl Composite",
            value: "W_composite",
            nick: "WC",
            ket: "Whorl",
        },
        {
            name: "Whorl Target Ulnar Peacock Eye",
            value: "W_peacock_centric_ulnar",
            nick: "WTP",
            ket: "Whorl",
        },
        {
            name: "Whorl Spiral Ulnar Peacock Eye",
            value: "W_peacock_spiral_ulnar",
            nick: "WSP",
            ket: "Whorl",
        },
        {
            name: "Whorl Target Radial Peacock Eye",
            value: "W_peacock_centric_radial",
            nick: "RWTP",
            ket: "Whorl",
        },
        {
            name: "Whorl Spiral Radial Peacock Eye",
            value: "W_peacock_spiral_radial",
            nick: "RWSP",
            ket: "Whorl",
        },
        {
            name: "Whorl Double Loop",
            value: "L_double",
            nick: "WDL",
            ket: "Whorl",
        },
        {
            name: "Ulnar Loop",
            value: "L_ulnar",
            nick: "L",
            ket: "Loop",
        },
        {
            name: "Radial Loop",
            value: "L_radial",
            nick: "RL",
            ket: "Loop",
        },
        {
            name: "Ulnar Falling Loop",
            value: "L_fallen_ulnar",
            nick: "LW",
            ket: "Loop",
        },
        {
            name: "Radial Falling Loop",
            value: "L_fallen_radial",
            nick: "RLW",
            ket: "Loop",
        },
        {
            name: "Plain Arch",
            value: "A_simple",
            nick: "AS",
            ket: "Arch",
        },
        {
            name: "Tented Arch",
            value: "A_tented",
            nick: "TA",
            ket: "Arch",
        },
        {
            name: "Tented Arch with Ulnar Loop",
            value: "A_w_L_ulnar",
            nick: "LAS",
            ket: "Loop",
        },
        {
            name: "Tented Arch with Radial Loop",
            value: "A_w_L_radial",
            nick: "RLAS",
            ket: "Loop",
        },
        {
            name: "Accidental Whorl",
            value: "accidental",
            nick: "WX",
            ket: "Whorl",
        },
    ];
    const findType = shape_finger.reduce(function(acc, nilai) {
        // Cari nilai yang sesuai dalam array2
        var item = fingerType.find(function(item) {
        return item.nick === nilai;
        });

        // Tambahkan nilai ke array1
        acc.push(item.ket);

        // Kembalikan accumulator
        return acc;
    }, []);
    const findDominance = (myArray) =>
        myArray.reduce(
        (a,b,i,arr)=>
        (arr.filter(v=>v===a).length>=arr.filter(v=>v===b).length?a:b),
    null)
    const dominan = findDominance(findType)

    // TFRC
    const tfrc = state.tfrc 

    // Gaya Belajar
    const visual = state.visual_learning
    const kinesthetic = state.kinesthetic_learning
    const auditory = state.auditory_learning

    // Dominasi Otak
    const left_brain = Math.abs(state.left_brain)
    const right_brain = Math.abs(state.right_brain)

    // Kecerdasan
    const IQ = state.iq
    const EQ = state.eq

    // Kecerdasan Majemuk
    const intra = state["8_intra"]
    const inter = state["8_inter"]
    const log = state["8_logical"]
    const vis = state["8_visual"]
    const kin = state["8_bodily_kinesthetic"]
    const lin = state["8_linguistic"]
    const mus = state["8_musical"]
    const nat = state["8_naturalist"]
    const exi = state.existential

    // Minat Studi
    const riasec_r = state.riasec_r
    const riasec_i = state.riasec_i
    const riasec_a = state.riasec_a
    const riasec_s = state.riasec_c
    const riasec_e = state.riasec_e
    const riasec_c = state.riasec_c

    const objek = [
        {nilai: riasec_r, index: 'Realistic'},
        {nilai: riasec_i, index: 'Investigative'},
        {nilai: riasec_a, index: 'Artistic'},
        {nilai: riasec_s, index: 'Social'},
        {nilai: riasec_e, index: 'Enterprising'},
        {nilai: riasec_c, index: 'Convensional'},
    ]
    objek.sort(function(a, b){return b.nilai - a.nilai})
    const hasil = objek.map((item, index) => ({item: item.nilai, index: item.index}))
    
    function ketRi1(){
        return hasil[0].index
    }
    function nilaiRi1(){
        return hasil[0].item
    }
    function ketRi2(){
        return hasil[1].index
    }
    function nilaiRi2(){
        return hasil[1].item
    }
    // DOPE
    const disc = state.disc

    // Konsep Pemikiran
    const executor = state.executor
    const thinker = state.thinker

    const level1 = "#013226";
    const level2 = "#01513D";
    const level3 = "#00674D";
    const level4 = "#007F5F";
    const level5 = "#2B9348";
    const level6 = "#55A630";
    const level7 = "#80B918";
    const level8 = "#AACC00";
    const level9 = "#BFD200";
    const level10 = "#D4D700";
    const colors = [
      level1,
      level2,
      level3,
      level4,
      level5,
      level6,
      level7,
      level8,
      level9,
      level10,
    ];
    const warnaGaya = ["#4C7AB0", "#1F599C", "#19477D"];

    function urutinWarna(value, colors) {
        if (value <= 10) {
          return colors[9];
        }
        if (value <= 20) {
          return colors[7];
        }
        if (value <= 30) {
          return colors[5];
        }
        if (value <= 40) {
          return colors[3];
        }
        if (value <= 50) {
          return colors[1];
        }
        if (value <= 60) {
          return colors[0];
        }
        if (value <= 70) {
          return colors[0];
        }
        if (value <= 80) {
          return colors[0];
        }
        if (value <= 90) {
          return colors[0];
        }
        if (value <= 100) {
          return colors[0];
        }
      }
    
      function warnain(value, color) {
        if (value[0] < value[1] && value[0] < value[2] && value[1] < value[2]) {
          return [color[0], color[1], color[2]];
        } else if (
          value[0] < value[1] &&
          value[0] < value[2] &&
          value[1] > value[2]
        ) {
          return [color[0], color[2], color[1]];
        } else if (
          value[0] > value[1] &&
          value[0] < value[2] &&
          value[1] < value[2]
        ) {
          return [color[1], color[0], color[2]];
        } else if (
          value[0] < value[1] &&
          value[0] > value[2] &&
          value[1] > value[2]
        ) {
          return [color[1], color[2], color[0]];
        } else if (
          value[0] > value[1] &&
          value[0] > value[2] &&
          value[1] > value[2]
        ) {
          return [color[2], color[1], color[0]];
        } else if (
          value[0] > value[1] &&
          value[0] > value[2] &&
          value[1] < value[2]
        ) {
          return [color[2], color[0], color[1]];
        }
      }

    return(
        <Row className="corev2 qx" style={{height: '85vh'}}>
            {next == 1 && (
                <>
                <p className="sumJu">Kemampuan Umum</p>
                <p className="smKt">Having a dominant loop fingerprint pattern indicates a person with a friendly character, excelling in both intrapersonal and interpersonal skills. With a left-brain dominance, your thought process leans towards action, despite a low IQ and average EQ scores. Your auditory learning style and introspective intelligence make the arts field a suitable fit, although fine motor skills present a challenge.</p>
                <div className="sumS1">
                    {dominan === "Whorl" && (
                        <>
                        <img src={whorl}/>
                        <div>
                            <p className="smJ">Whorl</p>
                            <ul className="smUl">
                                <li>Emosis: tidak stabil, meledak-ledak, sensitif</li>
                                <li>Karakter: dominan, suka memimpin, suka menjadi pusat perhatian</li>
                                <li>Kebiasaan: tidak bisa diam, mudah bosan.</li>
                                <li>Kelebihan: bisa menjadi pemimpin, berpendirian teguh, sosok yang kuat, sangat bertanggung jawab, mandiri, gesit ketika bertindak, serta dapat menganalisa situasi dengan cermat</li>
                                <li>Kekurangan: pemikir, terobsesi pada kekuasaan, sukar menyesuaikan diri, memiliki egosentris yang tinggi, kaku, dan memiliki kontrol emosi yang buruk.</li>
                            </ul>
                        </div>
                        </>
                    )}
                    {dominan === "Loop" && (
                        <>
                        <img src={loop}/>
                        <div>
                            <p className="smJ">Loop</p>
                            <ul className="smUl">
                                <li>Emosi: ramah, optimis, terbuka</li>
                                <li>Karakter: simpatinya tinggi, tulus, optimis, kurang inisiatif</li>
                                <li>Kebiasaan: rajin dan bertanggung jawab, pekerja keras</li>
                                <li>Kelebihan: memiliki empati tinggi, senang, membantu orang lain, tulus, rajin, dan bertanggung jawab, terbuka, ramah, murah senyum, optimis, dan pekerja keras.</li>
                                <li>Kekurangan: kurang memiliki inisiatif, tidak menyukai aturan yang keras, tidak bisa berdiam diri, dan bergantung pada orang lain</li>
                            </ul>
                        </div>
                        </>
                    )}
                    {dominan === "Arch" && (
                        <>
                        <img src={arc}/>
                        <div>
                            <p className="smJ">Arch</p>
                            <ul className="smUl">
                                <li>Emosi: sabar, sulit mengekspresikan perasaan</li>
                                <li>Karakter: sederhana, praktis, sulit beradaptasi</li>
                                <li>Kebiasaan: implusive, selalu bersemangat</li>
                                <li>Kelebihan: praktis, berkepala dingin, bersahaja, selalu bersemangat, memiliki kepercayaan diri yang tinggi, tegas, dan tidak mudah menyerah.</li>
                                <li>Kekurangan: berkepala dingin, sukar mengutarakan perasaan, impulsive, kurang mampu menerima pendapat orang lain, dan sulit beradaptasi.</li>
                            </ul>
                        </div>

                        </>
                    )}

                </div>
                {package_id === 333 ? (
                    <>
                    <div className="smBox">
                        <div className={tfrc > 237 ? 'sBx level1b' :
                        tfrc > 204 ? 'sBx level2b' :
                        tfrc > 170 ? 'sBx level3b' :
                        tfrc > 135 ? 'sBx level4b' :
                        tfrc > 101 ? 'sBx level5b' :
                        tfrc > 67 ? 'sBx level6b' : 'sBx level7b'}>
                        <p className={tfrc > 237 ? 'smTit level1' :
                        tfrc > 204 ? 'smTit level2' :
                        tfrc > 170 ? 'smTit level3' :
                        tfrc > 135 ? 'smTit level4' :
                        tfrc > 101 ? 'smTit level5' :
                        tfrc > 67 ? 'smTit level6' : 'smTit level7p'}>
                        {tfrc > 237 ? 'Sangat Tinggi' :
                        tfrc > 204 ? 'Tinggi' :
                        tfrc > 170 ? 'Diatas rataa-rata' :
                        tfrc > 135 ? 'Rata-rata' :
                        tfrc > 101 ? 'Dibawah rata-rata' :
                        tfrc > 67 ? 'Cukup Rendah' : 'Rendah'}
                        </p>
                        <p className="smTrf">Total Finger Ridge Count (TFRC)</p>
                        <p className={tfrc > 237 ? 'smTrV level1p' :
                        tfrc > 204 ? 'smTrV level2p' :
                        tfrc > 170 ? 'smTrV level3p' :
                        tfrc > 135 ? 'smTrV level4p' :
                        tfrc > 101 ? 'smTrV level5p' :
                        tfrc > 67 ? 'smTrV level6p' : 'smTrV level7p'}>{Math.trunc(tfrc)}</p>
                        <p className="smTrKt">
                        {tfrc > 237 ? 'Memiliki kemampuan untuk mendobrak batasan dari keterbatasan diri sendiri' :
                        tfrc > 204 ? 'Memiliki kemampuan tersembunyi yang berpotensi menjadi suatu kekuatan besar' :
                        tfrc > 170 ? 'Memiliki kemampuan untuk memimpin orang lain untuk mencapai harapannya' :
                        tfrc > 135 ? 'Memiliki kemampuan untuk memotivasi orang lain' :
                        tfrc > 101 ? 'Memiliki wawasan mengenai diri sendiri dan memiliki ekspektasi yang realistis':
                        tfrc > 67 ? 'Membutuhkan bimbingan untuk kinerja yang optimal' : 'Membutuhkan bantuan dan pengangan khusus untuk kinerja yang lebih baik'}
                        </p>
                        </div>
                    </div>
                    </>
                ) : package_id === 222 ? (
                    <>
                    <div className="sumBo" style={{gap: '24px', marginBottom: '32px'}}>
                        <div className="sumRiBox">
                            <p className="sumRip1">Self-Development Suggestions:</p>
                            <p className="sumRip2">Work on improving fine motor skills through activities related to your artistic interests, practicing regularly to enhance dexterity. Engage in mindfulness exercises to strengthen introspective intelligence and self-awareness. Join art-related clubs or workshops to develop your skills further and connect with like-minded individuals. Celebrate your unique strengths within the arts, and remember that personal growth often comes from both challenges and successes.</p>
                        </div>
                        <div className="sumRiBox">
                            <p className="sumRip1">Social Relationship Tips:</p>
                            <p className="sumRip2">Leverage your strong interpersonal skills to build genuine connections within the arts community. Engage in group activities and collaborate on projects that align with your creative interests. Communicate openly about any challenges with fine motor skills, allowing others to understand and support you. Embrace the collaborative nature of the arts to foster positive social relationships.</p>
                        </div>
                    </div>

                    </>
                ) : (
                    <>
                    <div className="sumBo" style={{marginBottom: '32px'}}>
                        <div className="sumRiBox">
                            <p className="sumRip1">Social Relationship Tips:</p>
                            <p className="sumRip2">Leverage your strong interpersonal skills to build genuine connections within the arts community. Engage in group activities and collaborate on projects that align with your creative interests. Communicate openly about any challenges with fine motor skills, allowing others to understand and support you. Embrace the collaborative nature of the arts to foster positive social relationships.</p>
                        </div>
                    </div>

                    </>
                )}

                <div className="suBt">
                    <button className="buttonTest" style={{height: 'min-content'}} onClick={() => {setNext(2)}}>Selanjutnya</button>
                </div>


                </>
            )}
            {next == 2 && (
                <>
                
                <p className="sumJu">Kemampuan Penunjang Akademik</p>
                <div className="sumS1">
                    <div className="sumLe">
                        <div className="sumTo">
                            <div className="sumx1">
                                <ReactApexChart 
                                series={[{ data: [auditory, visual, kinesthetic] }]} 
                                options={{
                                    chart: {
                                        height: 240,
                                        type: 'bar',
                                        toolbar: {
                                            show: false,
                                        },
                                    },
                                    colors: warnain([auditory, visual, kinesthetic], warnaGaya),
                                    plotOptions: {
                                        bar: {
                                            columnWidth: '40%',
                                            distributed: true,
                                        }
                                    },
                                    dataLabels: {
                                        enabled: false
                                    },
                                    legend: {
                                        show: false
                                    },
                                    xaxis: {
                                        categories: [
                                            'Auditory',
                                            'Visual',
                                            'Kinesthetic',
                                        ],
                                        labels: {
                                            style: {
                                                colors: [
                                                    '#4C7AB0',
                                                    '#1F599C',
                                                    '#19477D',
                                                ],
                                                fontSize: '12px'
                                            }
                                        }
                                    }
                                }} 
                                height= {240}
                                type= 'bar'
                                />
                            </div>
                            <div className="sumx1">
                            <ReactApexChart
                                series={[ left_brain, right_brain ]}
                                options={{        
                                    chart: {
                                        height: 240,
                                        type: 'donut',
                                    },
                                    labels: ['Otak Kiri', 'Otak Kanan'],
                                    dataLabels: {
                                        enabled: false
                                    },
                                    legend: {
                                        show: true,
                                        position: 'bottom',
                                    },
                                    colors: ['#799BC4', '#19477D'],
                                }}
                                type="donut"
                                height={240}
                            />
                            </div>
                        </div>
                        <div className="sumBo">
                            {package_id === 33 || package_id === 22 ? (
                                <>
                                <div className="sumx1">
                                    <div className="scus">
                                        <p className="scusp1">Kecerdasan</p>
                                        <p className="scusp2">Kemampuan seseorang untuk menghadapi sesuatu yang dapat diukur.</p>
                                    </div>
                                </div>
                                <div className="sumx2">
                                    <div  className='sumx1 scX'>
                                        <div className="scxa">
                                        <p className="scxap1">IQ Anda</p>
                                        <p className="scxap2">{IQ}</p>
                                        </div>
                                    </div>
                                    <div  className='sumx1'>
                                        <div  className="scxa">
                                        <p className="scxap1">EQ Anda</p>
                                        <p className="scxap2">{EQ}</p>
                                        </div>
                                    </div>
                                </div>
                                </>
                            ) : (
                                <>
                                
                                </>
                            )}
                            
                        </div>
                    </div>
                    <div className="sumRi">
                        <div className="sumRiBox">
                            <p className="sumRip1">Academic and Career Advice:</p>
                            <p className="sumRip2">Pursue academic paths in the arts, such as visual arts, music, or literature, where your auditory learning style and introspective intelligence can thrive. Embrace courses that allow for creativity and self-expression. While fine motor skills may pose difficulties, focus on art forms that accommodate your strengths, such as conceptual or digital art.</p>
                        </div>
                    </div>
                </div>
                <div className="suBt">
                    <button className="buttonTest" style={{height: 'min-content'}} onClick={() => {setNext(1)}}>Sebelumnya</button>
                    {package_id === 333 && (
                        <button className="buttonTest" style={{height: 'min-content'}} onClick={() => {setNext(3)}}>Selanjutnya</button>
                    )}
                </div>

                </>
            )}
            {next == 3 && (
                <>
                
                <p className="sumJu">Kemampuan Penunjang Minat Bakat</p>
                <div className="sumS1">
                    <div className="sumLe">
                        <div className="sumTo">
                            <ReactApexChart 
                            style={{width: ['-webkit-fill-available']}}
                            series={[{ data: [intra, inter, log, vis, kin, lin, mus, nat, exi] }]} 
                            options={{
                                chart: {
                                    height: 240,
                                    type: 'bar',
                                    toolbar: {
                                        show: false,
                                    },
                                },
                                colors: [
                                    urutinWarna(intra, colors),
                                    urutinWarna(inter, colors),
                                    urutinWarna(log, colors),
                                    urutinWarna(vis, colors),
                                    urutinWarna(kin, colors),
                                    urutinWarna(lin, colors),
                                    urutinWarna(mus, colors),
                                    urutinWarna(nat, colors),
                                    urutinWarna(exi, colors),
                                  ],
                                plotOptions: {
                                    bar: {
                                        columnWidth: '40%',
                                        distributed: true,
                                    }
                                },
                                dataLabels: {
                                    enabled: false
                                },
                                legend: {
                                    show: false
                                },
                                xaxis: {
                                    categories: [
                                        'Intrapersonal',
                                        'Interpersonal',
                                        'Logical',
                                        'Visual',
                                        'Kinestetik',
                                        'Linguistik',
                                        'Musikal',
                                        'Naturalistik',
                                        'Eksistensial',
                                    ],
                                    labels: {
                                        style: {
                                            colors: [
                                                '#01513D',
                                                '#007F5F',
                                                '#00674D',
                                                '#2B9348',
                                                '#01513D',
                                                '#5FAA3D',
                                                '#AC0',
                                                '#AC0',
                                                '#D4D700',
                                            ],
                                            fontSize: '12px'
                                        }
                                    }
                                }
                            }} 
                            height= {240}
                            type= 'bar'
                            />
                        </div>
                        <div className="sumBo">
                            <div className="sumx1">
                                <div className="scus">
                                <p className="scusp1">RIASEC</p>
                                <p className="scusp2">Taksonomi minat berdasarkan teori karir dan pilihan kejuruan yang dikembangkan oleh John L. Holland.</p>
                                </div>
                            </div>
                            <div className="sumx2">
                                <div className="sumx1 scX">
                                    <div className="scxa">
                                        <p className="scxap1">{ketRi1()}</p>
                                        <p className="scxap2">{nilaiRi1()}</p>
                                    </div>
                                </div>
                                <div className="sumx1">
                                    <div className="scxa">
                                        <p className="scxap1">{ketRi2()}</p>
                                        <p className="scxap2">{nilaiRi2()}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sumRi">
                        <div className="sumRiBox">
                            <p className="sumRip1">Social Relationship Tips:</p>
                            <p className="sumRip2">Leverage your strong interpersonal skills to build genuine connections within the arts community. Engage in group activities and collaborate on projects that align with your creative interests. Communicate openly about any challenges with fine motor skills, allowing others to understand and support you. Embrace the collaborative nature of the arts to foster positive social relationships.</p>
                        </div>
                    </div>
                </div>
                <div className="suBt">
                    <button className="buttonTest" style={{height: 'min-content'}} onClick={() => {setNext(2)}}>Sebelumnya</button>
                    <button className="buttonTest" style={{height: 'min-content'}} onClick={() => {setNext(4)}}>Selanjutnya</button>
                </div>

                </>
            )}
            {next == 4 && (
                <>
                
                <p className="sumJu">Kemampuan Penunjang Kepribadian</p>
                <div className="sumS1">
                    <div  className="sumLe">
                        <div className="sumTo" style={{gap: '24px'}}>
                            <div className="sumToc">
                                <p>DOPE</p>
                                <p style={{fontSize: '24px', fontWeight: '700'}}>
                                    {disc === "domination" ? "Eagle" :
                                    disc === "influential" ? "Peacock" :
                                    disc === "steady" ? "Dove" : "Owl"
                                    }
                                </p>
                                <p style={{fontSize: '12px'}}>Ramah dan suportif, suka membantu dan memberi, santai dan cenderung lambat bergerak, suka tergantung pada orang lain</p>
                            </div>
                            <div style={{width: '50%'}}>
                                <img style={{width: '100%'}} src= {
                                    disc === "domination" ? eagle :
                                    disc === "influential" ? peacock :
                                    disc === "steady" ? dove : owl
                                    }/>
                            </div>
                        </div>
                        <div>
                            <div>
                                <p style={{fontSize: '12px', fontWeight: '600', margin: '0px'}}>Konsep Pemikiran</p>
                                <p style={{fontSize: '12px'}}>Perbandingan sikap dominan yang dilakukan untuk menyelesaikan suatu permasalahan.</p>
                            </div>
                        <div style={{width: '100%'}}>
                            <div style={{display: 'flex', gap: '25%', justifyContent: 'center', marginBottom: '20px'}}>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <p style={{fontSize: '18px', fontWeight: '700', textAlign: 'center'}}>Executor</p>
                                    {/* <img src={objectDriven} style={{width: '80px', height: '80px'}}/> */}
                                </div>
                                
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <p style={{fontSize: '18px', fontWeight: '700', textAlign: 'center'}}>Thinker</p>
                                    {/* <img src={conceptDriven} style={{width: '80px', height: '80px'}}/> */}
                                </div>

                            </div>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <p style={{margin:'0px' , flex: '1', textAlign: 'end', paddingRight: '10px', fontSize: '16px', fontWeight: '900', color: '#05C38A'}}>{executor}%</p>
                                <div style={{flex: '4'}}>
                                    <Progress multi style={{height: '30px'}}>
                                        <Progress bar value={executor} color='info'/>
                                        <Progress bar value={thinker} color='danger'/>
                                    </Progress>
                                </div>
                                <p style={{margin:'0px' , flex: '1', textAlign: 'start', paddingLeft: '10px', fontSize: '16px', fontWeight: '900', color: '#FB5607'}}>{thinker}%</p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div  className="sumRi">
                        <div className="sumRiBox">
                            <p className="sumRip1">Self-Development Suggestions:</p>
                            <p className="sumRip2">Work on improving fine motor skills through activities related to your artistic interests, practicing regularly to enhance dexterity. Engage in mindfulness exercises to strengthen introspective intelligence and self-awareness. Join art-related clubs or workshops to develop your skills further and connect with like-minded individuals. Celebrate your unique strengths within the arts, and remember that personal growth often comes from both challenges and successes.</p>
                        </div>
                    </div>
                </div>
                <div className="suBt">
                    <button className="buttonTest" style={{height: 'min-content'}} onClick={() => {setNext(3)}}>Sebelumnya</button>
                </div>

                </>
            )}
        </Row>
    )
}

export default Summary
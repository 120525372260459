import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import Lottie from 'react-lottie';
import accessDenied from '../../../../../assets/images/FutureLink/accessDenied.json';

import dove from '../../../../../assets/images/FutureLink/dove.png';
import owl from '../../../../../assets/images/FutureLink/owl.png';
import eagle from '../../../../../assets/images/FutureLink/eagle.png';
import peacock from '../../../../../assets/images/FutureLink/peacock.png';

const DOPE= (haisayang) => {
    const data = haisayang.history.location.state.data
    // console.log(haisayang.history.location.state.data)

    const packages = haisayang.history.location.state.package_id
    // console.log(haisayang.history.location.state.package_id)

    const token = haisayang.history.location.state.params

    return(
        <React.Fragment>
            <div className="page-content">
                {packages === 33 ? (
                    <>
                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                        <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 40px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>DOPE</p>
                    </div>

                    <div className='dope'>
                        <Link style={{marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to={`/report-mobile/${token}`}>Back</Link>

                        <div className='dopeWeb'>

                            <div className='dopeIntro'>
                                <div className='listImg'>
                                    <img src={dove} style={{width: '150px'}} />
                                    <img src={owl} style={{width: '150px'}} />
                                </div>
                                <div className='listImg'>
                                    <img src={eagle} style={{width: '150px'}} />
                                    <img src={peacock} style={{width: '150px'}} />
                                </div>

                                <div style={{margin: '40px 0px'}}>
                                    <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>Bird Personality Dr. Gary Couture</p>
                                </div>
                                <div className='valueBox'>
                                    {data.disc === 'compliant' && (
                                        <>
                                        <img src={owl} style={{width: '100px'}} />
                                        <p style={{margin: '0px', fontSize: '24px', fontWeight: '900', color: '#1F599C'}}>OWL</p>
                                        </>
                                    )}
                                    {data.disc === 'steady' && (
                                        <>
                                        <img src={dove} style={{width: '100px'}} />
                                        <p style={{margin: '0px', fontSize: '24px', fontWeight: '900', color: '#1F599C'}}>DOVE</p>
                                        </>
                                    )}
                                    {data.disc === 'influential' && (
                                        <>
                                        <img src={peacock} style={{width: '100px'}} />
                                        <p style={{margin: '0px', fontSize: '24px', fontWeight: '900', color: '#1F599C'}}>PEACOCK</p>
                                        </>
                                    )}
                                    {data.disc === 'domination' && (
                                        <>
                                        <img src={eagle} style={{width: '100px'}} />
                                        <p style={{margin: '0px', fontSize: '24px', fontWeight: '900', color: '#1F599C'}}>EAGLE</p>
                                        </>
                                    )}

                                </div>

                                <p style={{fontSize: '16px', lineHeight: '28px', textAlign: 'justify', width: '90%'}}><span style={{fontWeight: '700'}}>DOPE Personality</span> merupakan tes kepribadian yang ditentukan berdasarkan sifat-sifat dan karakteristik dari 4 burung. Burung-burung yang menjadi acuan tersebut adalah Merpati (Dove), Burung Hantu (Owl), Burung Merak (Peacock), dan Burung Elang (Eagle). Setiap karakter burung mewakili gaya kepribadian yang berbeda mulai dari keinginan dasarnya, kebutuhan emosional, hingga karakteristiknya. Teori kepribadian DOPE ini dikembangkan oleh Dr. Gary Couture.</p>
                            </div>

                            <div className='dopeItem'>
                                <div style={{display: 'flex', alignItems: 'center', gap: '30px'}}>
                                    <img src={dove} style={{width: '100px'}} />
                                    <p style={{margin: '0px', fontSize: '24px', fontWeight: '900', color: '#1F599C'}}>DOVE</p>
                                </div>
                                <p style={{fontSize: '16px', lineHeight: '28px', textAlign: 'justify', width: '90%'}}>
                                    Orang yang kepribadiannya <span style={{fontWeight: '700'}}>merpati</span> berorientasi pada orang, setia, ramah, pekerja keras, suportif, sensitif, bergerak lambat, santai, pendengar yang baik dan simpatik, cinta damai, suka membantu, ramah, peduli, mengasuh, pengertian dan sabar, dermawan, senang memberi, berpusat pada proses, baik hati, sederhana, dan anggota tim yang hebat.
                                    Orang yang kepribadiannya merpati dimotivasi oleh berbagi hal yang mereka yakini benar, proses demokrasi, rasa kesatuan dalam tim, bekerja sama, kesempatan untuk membantu dengan tulus, dan instruksi komprehensif.
                                    Kekuatan orang ini adalah menghindari pengambilan risiko, dapat dipercaya, santai, senang memberi, jujur, sabar, introvert, serta dapat diandalkan. Sedangkan kelemahannya adalah terlalu pengikut, mudah tertipu, tergantung pada orang lain, dan dapat diprediksi.
                                </p>

                            </div>

                            <div className='dopeItem'>
                                <div style={{display: 'flex', alignItems: 'center', gap: '30px'}}>
                                    <img src={owl} style={{width: '100px'}} />
                                    <p style={{margin: '0px', fontSize: '24px', fontWeight: '900', color: '#1F599C'}}>OWL</p>
                                </div>
                                <p style={{fontSize: '16px', lineHeight: '28px', textAlign: 'justify', width: '90%'}}>
                                Orang yang kepribadiannya <span style={{fontWeight: '700'}}>burung hantu</span> biasanya orang yang logis, berpikiran matematis, metodis dan analitis serta terkadang terlihat perfeksionis. Terbiasa fokus pada kualitas, selalu menyelesaikan tugas yang dijalankan, membutuhkan aturan, mampu menerapkan keterampilan diri sendiri, memiliki kemampuan multitasking, dapat dengan mudah beradaptasi, tidak akan langsung menjawab ketika ditanya (terbiasa untuk menunggu, menganalisis, dan kemudian menjawab).
                                Orang yang berkepribadian burung hantu dimotivasi oleh ketersediaan waktu yang cukup untuk menyelesaikan tugas, privasi, kesempatan untuk merencanakan masa depan secara detail, dikelilingi oleh orang-orang dengan karakteristik positif, orang yang membutuhkan dan menghargai, membantu orang lain, serta mencari solusi konflik.
                                Kekuatan orang ini adalah berorientasi pada detail, penuh perhatian dan rasa ingin tahu, teliti, tenang, introvert, berhati-hati, serta konservatif. Serta kelemahannya adalah sulit percaya, egois, bimbang, pendendam, dan picik.
                                </p>

                            </div>

                            <div className='dopeItem'>
                                <div style={{display: 'flex', alignItems: 'center', gap: '30px'}}>
                                    <img src={peacock} style={{width: '100px'}} />
                                    <p style={{margin: '0px', fontSize: '24px', fontWeight: '900', color: '#1F599C'}}>PEACOCK</p>
                                </div>
                                <p style={{fontSize: '16px', lineHeight: '28px', textAlign: 'justify', width: '90%'}}>
                                Orang yang kepribadiannya <span style={{fontWeight: '700'}}>merak</span> biasanya pembicara yang antusias, senang menjadi pusat perhatian, memiliki antusiasme yang tinggi, optimis, cepat bergerak, energik, senang berinovasi, kreatif, mudah beradaptasi, spontan, memiliki selera humor yang baik, jujur dan setia, emosional & sensitif, serta nikmati tantangan.
                                Orang yang kepribadiannya merak dapat dimotivasi oleh ide-ide baru, ragam karya, pemikiran kreatif dan inovatif, kebebasan, sensasi dan tantangan yang dihadapi, spontanitas dan fleksibilitas untuk perubahan cepat.
                                Kekuatan yang dimiliki orang ini adalah jiwa antusias, sosial, karismatik, berpikiran terbuka, emosional, banyak bicara, energik, kompetitif, san suka petualangan. Sedangkan kelemahannya adalah emosional, tidak teratur, tidak bertanggung jawab, tidak realistis, tidak mahir dengan detail atau kontrol waktu.
                                </p>

                            </div>

                            <div className='dopeItem'>
                                <div style={{display: 'flex', alignItems: 'center', gap: '30px'}}>
                                    <img src={eagle} style={{width: '100px'}} />
                                    <p style={{margin: '0px', fontSize: '24px', fontWeight: '900', color: '#1F599C'}}>EAGLE</p>
                                </div>
                                <p style={{fontSize: '16px', lineHeight: '28px', textAlign: 'justify', width: '90%'}}>
                                Orang yang kepribadiannya <span style={{fontWeight: '700'}}>elang</span> cenderung dominan, berorientasi pada pekerjaan/tugas, egosentris, mandiri, berorientasi pada hasil, berani, bersedia menerima tantangan, agresif, sadar akan citra diri, senang mengontrol, memiliki daya tahan dan semangat kegigihan, setia kepada teman, tidak mudah dipengaruhi tetapi setia kepada orang yang dikagumi.
                                Orang yang kepribadiannya elang dapat dimotivasi dengan target yang harus dicapai sebelum tenggat waktu, peraturan yang membuat sibuk dan tertantang, kewenangan yang diberikan untuk bernegosiasi dan mengambil keputusan, persaingan nyata atau persaingan yang berasal dari imajinasi.
                                Kekuatan dari orang ini adalah mudah termotivasi, mandiri, senang berinisiatif, tak kenal takut, agresif, dan menarik. Sedangkan kelemahannya adalah keras kepala, paranoid, mudah tersinggung, egois, tidak sabar, tidak simpatik, senang memaksa, dan kontroversial.
                                </p>

                            </div>

                        </div>

                        <div className='dopeMobile'>

                            <div className='dopeMobileDeskripsi'>
                                <p style={{fontSize: '16px', lineHeight: '28px', textAlign: 'justify'}}><span style={{fontWeight: '700'}}>DOPE Personality</span> merupakan tes kepribadian yang ditentukan berdasarkan sifat-sifat dan karakteristik dari 4 burung. Burung-burung yang menjadi acuan tersebut adalah Merpati (Dove), Burung Hantu (Owl), Burung Merak (Peacock), dan Burung Elang (Eagle). Setiap karakter burung mewakili gaya kepribadian yang berbeda mulai dari keinginan dasarnya, kebutuhan emosional, hingga karakteristiknya. Teori kepribadian DOPE ini dikembangkan oleh Dr. Gary Couture.</p>
                            </div>

                            <div className='dopeMobileType'>
                                <p className='p1'>Kepribadianmu adalah</p>
                                {data.disc === 'compliant' && (
                                    <>
                                    <img src={owl} className='dopeMobileImg'/>
                                    <p className='p2 blue'>OWL</p>
                                    <p style={{fontSize: '16px', lineHeight: '28px', textAlign: 'justify'}}>
                                    Orang yang kepribadiannya <span style={{fontWeight: '700'}}>Owl ( Burung Hantu )</span> biasanya orang yang logis, berpikiran matematis, metodis dan analitis serta terkadang terlihat perfeksionis. Terbiasa fokus pada kualitas, selalu menyelesaikan tugas yang dijalankan, membutuhkan aturan, mampu menerapkan keterampilan diri sendiri, memiliki kemampuan multitasking, dapat dengan mudah beradaptasi, tidak akan langsung menjawab ketika ditanya (terbiasa untuk menunggu, menganalisis, dan kemudian menjawab).
                                    Orang yang berkepribadian burung hantu dimotivasi oleh ketersediaan waktu yang cukup untuk menyelesaikan tugas, privasi, kesempatan untuk merencanakan masa depan secara detail, dikelilingi oleh orang-orang dengan karakteristik positif, orang yang membutuhkan dan menghargai, membantu orang lain, serta mencari solusi konflik.
                                    Kekuatan orang ini adalah berorientasi pada detail, penuh perhatian dan rasa ingin tahu, teliti, tenang, introvert, berhati-hati, serta konservatif. Serta kelemahannya adalah sulit percaya, egois, bimbang, pendendam, dan picik.
                                    </p>
                                    </>
                                )}
                                {data.disc === 'steady' && (
                                    <>
                                    <img src={dove} className='dopeMobileImg'/>
                                    <p className='p2 blue'>DOVE</p>
                                    <p style={{fontSize: '16px', lineHeight: '28px', textAlign: 'justify'}}>
                                        Orang yang kepribadiannya <span style={{fontWeight: '700'}}>Dove ( Merpati )</span> berorientasi pada orang, setia, ramah, pekerja keras, suportif, sensitif, bergerak lambat, santai, pendengar yang baik dan simpatik, cinta damai, suka membantu, ramah, peduli, mengasuh, pengertian dan sabar, dermawan, senang memberi, berpusat pada proses, baik hati, sederhana, dan anggota tim yang hebat.
                                        Orang yang kepribadiannya merpati dimotivasi oleh berbagi hal yang mereka yakini benar, proses demokrasi, rasa kesatuan dalam tim, bekerja sama, kesempatan untuk membantu dengan tulus, dan instruksi komprehensif.
                                        Kekuatan orang ini adalah menghindari pengambilan risiko, dapat dipercaya, santai, senang memberi, jujur, sabar, introvert, serta dapat diandalkan. Sedangkan kelemahannya adalah terlalu pengikut, mudah tertipu, tergantung pada orang lain, dan dapat diprediksi.
                                    </p>
                                    </>
                                )}
                                {data.disc === 'influential' && (
                                    <>
                                    <img src={peacock} className='dopeMobileImg'/>
                                    <p className='p2 blue'>PEACOCK</p>
                                    <p style={{fontSize: '16px', lineHeight: '28px', textAlign: 'justify'}}>
                                    Orang yang kepribadiannya <span style={{fontWeight: '700'}}>Peacock ( Merak )</span> biasanya pembicara yang antusias, senang menjadi pusat perhatian, memiliki antusiasme yang tinggi, optimis, cepat bergerak, energik, senang berinovasi, kreatif, mudah beradaptasi, spontan, memiliki selera humor yang baik, jujur dan setia, emosional & sensitif, serta nikmati tantangan.
                                    Orang yang kepribadiannya merak dapat dimotivasi oleh ide-ide baru, ragam karya, pemikiran kreatif dan inovatif, kebebasan, sensasi dan tantangan yang dihadapi, spontanitas dan fleksibilitas untuk perubahan cepat.
                                    Kekuatan yang dimiliki orang ini adalah jiwa antusias, sosial, karismatik, berpikiran terbuka, emosional, banyak bicara, energik, kompetitif, san suka petualangan. Sedangkan kelemahannya adalah emosional, tidak teratur, tidak bertanggung jawab, tidak realistis, tidak mahir dengan detail atau kontrol waktu.
                                    </p>
                                    </>
                                )}
                                {data.disc === 'domination' && (
                                    <>
                                    <img src={eagle} className='dopeMobileImg'/>
                                    <p className='p2 blue'>EAGLE</p>
                                    <p style={{fontSize: '16px', lineHeight: '28px', textAlign: 'justify'}}>
                                    Orang yang kepribadiannya <span style={{fontWeight: '700'}}>Eagle ( Elang )</span> cenderung dominan, berorientasi pada pekerjaan/tugas, egosentris, mandiri, berorientasi pada hasil, berani, bersedia menerima tantangan, agresif, sadar akan citra diri, senang mengontrol, memiliki daya tahan dan semangat kegigihan, setia kepada teman, tidak mudah dipengaruhi tetapi setia kepada orang yang dikagumi.
                                    Orang yang kepribadiannya elang dapat dimotivasi dengan target yang harus dicapai sebelum tenggat waktu, peraturan yang membuat sibuk dan tertantang, kewenangan yang diberikan untuk bernegosiasi dan mengambil keputusan, persaingan nyata atau persaingan yang berasal dari imajinasi.
                                    Kekuatan dari orang ini adalah mudah termotivasi, mandiri, senang berinisiatif, tak kenal takut, agresif, dan menarik. Sedangkan kelemahannya adalah keras kepala, paranoid, mudah tersinggung, egois, tidak sabar, tidak simpatik, senang memaksa, dan kontroversial.
                                    </p>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    <div style={{display: 'flex', justifyContent:'space-between', paddingTop: '32px'}}>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report-mobiles/disc", state: { data: data, package_id: packages, params: token }}} onClick={() => {document.body.scrollIntoView({behavior: "smooth"})}}>{"<"} DISC</Link>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report-mobiles/konsep-pemikiran", state: { data: data, package_id: packages, params: token }}} onClick={() => {document.body.scrollIntoView({behavior: "smooth"})}}>Konsep Pemikiran {">"}</Link>
                    </div>
                    </>
                ) : (
                    <>
                        <Link style={{marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to={`/report-mobile/${token}`}>Back</Link>
                        <Lottie options={{loop: true,autoplay: true,animationData: accessDenied,rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} isClickToPauseDisabled={true} />
                        <p className='upgradePlease'> Maaf, Anda belum melakukan Upgrade yang lebih tinggi</p>
                    </>
                )}

            </div>
        </React.Fragment>
    )

}

export default DOPE;
import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Label,
  Input,
  Alert,
  UncontrolledCarousel,
  ButtonGroup,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import axios from "axios";
import FormData from "form-data";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import DOMPurify from "dompurify";

import deleteData from "../../../assets/images/FutureLink/deleteData.png";
import accessDenied from "../../../assets/images/FutureLink/accessDenied.png";

import Lottie from "react-lottie";
import animateSuccess from "../../../assets/images/FutureLink/success.json";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const modulTestState = {
  test: "Test",
  question: "Question",
  answer: "Answer",
  section: "Section",
  subsection: "Subsection",
};

const testStatus = ["nonactive", "active"];

const ModulTest = () => {
  // document.title="Widgets | Velzon - React Admin & Dashboard Template";
  const [modalAccessDenied, setModalAccessDenied] = useState(false);
  const logOut = () => {
    sessionStorage.removeItem("user");
    window.location.href = "/login";
  };

  const [currentState, setCurrentState] = useState(modulTestState.test);
  const [tests, setTests] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [sections, setSections] = useState([]);
  const [subsections, setSubsections] = useState([]);

  const [currentTest, setCurrentTest] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentAnswer, setCurrentAnswer] = useState(null);
  const [currentSection, setCurrentSection] = useState(null);
  const [currentSubsection, setCurrentSubsection] = useState(null);

  const [modalUpdate, setModalUpdate] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState("Alert aja");
  const [currentImage, setCurrentImage] = useState(null);

  const code = sessionStorage.getItem("user");

  var currentFunction = null;

  //modal
  const [modalDeleteArticle, setModalDeleteArticle] = useState(false);
  const [modalDeleteVideo, setModalDeleteVideo] = useState(false);
  const [modalAlertSuccess, setModalAlertSuccess] = useState(false);
  const [articleId, setArticleId] = useState();
  const [fade, setFade] = useState("fadeout");
  const [kontenError, setKontenError] = useState("Check Again");
  const [counselor, setCounselor] = useState("");

  const generate = () => {
    random_kode(11);
  };

  function random_kode(string_length) {
    var random_string = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < string_length; i++) {
      random_string += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    setArticleId(random_string);
  }

  useEffect(() => {
    let id = 0;
    currentState == modulTestState.answer
      ? (id = currentQuestion.qId)
      : currentState == modulTestState.subsection
      ? (id = currentSection.testSectionId)
      : (id = currentTest ? currentTest.testId : 0);
    getAllTestInfo(currentState, id);
  }, [currentState]);

  useEffect(() => {
    if (alert) window.setTimeout(() => setAlert(false), 5000);
  }, [alert]);

  useEffect(() => {
    if (alertModal) window.setTimeout(() => setAlert(false), 5000);
  }, [alertModal]);

  useEffect(() => {
    if (currentFunction && currentTest != null) {
      currentFunction();
    } else {
      console.log(currentFunction);
      console.log(currentTest);
    }
  }, [currentTest]);

  const getAllTestInfo = (cs, id = 0) => {
    let requestValidation = true;
    let hasSection = currentTest ? currentTest.hasSection : null;
    if (cs == modulTestState.section) {
      if (!hasSection) requestValidation = false;
    }

    let urlDetail =
      cs == modulTestState.test
        ? "/v1/get-all-test"
        : cs == modulTestState.question
        ? "/v1/get-test-question-and-answer"
        : cs == modulTestState.answer
        ? "/v1/get-test-answer"
        : cs == modulTestState.section
        ? "/v1/get-all-test-section"
        : cs == modulTestState.subsection
        ? "/v1/get-all-test-subsection"
        : "";

    let urlPayload =
      cs == modulTestState.test
        ? { orderBy: "DESC" }
        : cs == modulTestState.answer
        ? { qId: id }
        : cs == modulTestState.subsection
        ? { testSectionId: id }
        : {
            testId: id,
            sectionable: currentSubsection ? true : false,
          };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}${urlDetail}`,
      headers: {
        Authorization: `Bearer ${code}`,
      },
      params: urlPayload,
    };

    requestValidation
      ? axios
          .request(config)
          .then((response) => {
            setCurrentState(cs);
            if (cs == modulTestState.test) {
              let responseResult = response.results.map((item, index) => {
                return {
                  testId: item.test_id,
                  name: item.name,
                  duration: item.duration,
                  hasSection: Boolean(parseInt(item.has_section)),
                  status: item.status,
                  price: item.price,
                };
              });
              setTests(responseResult);
            } else if (cs == modulTestState.question) {
              let subsectionView = false;
              let selectedQuestions;
              if (currentSubsection != null) {
                subsectionView = true;
                let qSection = response.results.find(
                  (item) => item.testSectionId == currentSection.testSectionId
                );

                let qSubsection = qSection.testSubsections.find(
                  (subitem) =>
                    subitem.testSubsectionId ==
                    currentSubsection.testSubsectionId
                );

                selectedQuestions = qSubsection.questions;
              } else if (currentSubsection == null) {
                selectedQuestions = response.results;
              }

              setQuestions(
                selectedQuestions.map((item, index) => {
                  return {
                    qId: subsectionView ? item.qId : item.q_id,
                    aId: subsectionView ? item.aId : item.a_id,
                    questionText: subsectionView
                      ? item.questionText
                      : item.question_text,
                    questionImage: subsectionView
                      ? item.questionImage
                      : item.question_image,
                    questionType: item.type,
                    arrangement: item.arrangement,
                  };
                })
              );
            } else if (cs == modulTestState.answer) {
              setAnswers(
                response.results.map((item, index) => {
                  return {
                    qId: item.q_id,
                    aId: item.a_id,
                    answerText: item.answer_text,
                    answerImage: item.answer_image,
                    point: item.point,
                  };
                })
              );
            } else if (cs == modulTestState.section) {
              setSections(
                response.results.map((item, index) => {
                  return {
                    testSectionId: item.test_section_id,
                    name: item.name,
                    duration: item.duration,
                    arrangement: item.arrangement,
                  };
                })
              );
            } else if (cs == modulTestState.subsection) {
              setSubsections(
                response.results.map((item, index) => {
                  return {
                    testSubsectionId: item.test_subsection_id,
                    name: item.name,
                    duration: item.duration,
                    arrangement: item.arrangement,
                  };
                })
              );
            }
          })
          .catch((error) => {
            console.log(error);
            setCurrentState(cs);
            if (cs == modulTestState.test) {
              setTests([]);
            } else if (cs == modulTestState.question) {
              setQuestions([]);
            } else if (cs == modulTestState.answer) {
              setAnswers([]);
            } else if (cs == modulTestState.section) {
              setSections([]);
            } else if (cs == modulTestState.subsection) {
              setSubsections([]);
            }
          })
      : setAlertModal(true);
  };

  const updateAllTestInfo = (e, id = 0) => {
    e.preventDefault();
    let formElements = e.target.elements;
    let urlDetail =
      currentState == modulTestState.test
        ? "/v1/update-test"
        : currentState == modulTestState.question
        ? "/v1/update-test-question"
        : currentState == modulTestState.answer
        ? "/v1/update-test-answer"
        : currentState == modulTestState.section
        ? "/v1/update-test-section"
        : currentState == modulTestState.subsection
        ? "/v1/update-test-subsection"
        : "";

    let urlBody = new FormData();
    console.log(formElements.questionText);
    console.log(formElements.questionImage);
    console.log(formElements.arrangement);
    let currentId;
    if (currentState == modulTestState.test) {
      currentId = currentTest.testId;
      urlBody.append("testId", currentId);

      // Aku Perbarui biar gak error
      // if (formElements.name) {
      //   urlBody.append("name", formElements.name.value);
      // }
      // if (formElements.duration) {
      //   urlBody.append("duration", formElements.duration.value);
      // }
      // if (formElements.name) {
      //   urlBody.append("durationType", formElements.durationType.value);
      // }
      // if (formElements.name) {
      //   urlBody.append("hasSection", formElements.hasSection.value);
      // }
      // if (formElements.name) {
      //   urlBody.append("status", formElements.status.value);
      // }
      // if (formElements.name) {
      //   urlBody.append("price", formElements.name.value);
      // }

      // CODE LAWAS ni
      if (formElements.name.value)
        urlBody.append("name", formElements.name.value);
      if (formElements.duration.value)
        urlBody.append("duration", formElements.duration.value);
      if (formElements.durationType.value)
        urlBody.append("durationType", formElements.durationType.value);
      if (formElements.hasSection.value)
        urlBody.append("hasSection", formElements.hasSection.value);
      if (formElements.status.value)
        urlBody.append("status", formElements.status.value);
      if (formElements.price.value)
        urlBody.append("price", formElements.name.value);
    } else if (currentState == modulTestState.question) {
      currentId = currentQuestion.qId;
      urlBody.append("qId", currentId);

      // Aku Perbarui biar gak error
      // if (formElements.questionText.value) {
      //   urlBody.append("questionText", formElements.questionText.value);
      // }
      // if (formElements.arrangement.value) {
      //   urlBody.append("arrangement", formElements.arrangement.value);
      // }
      // if (currentImage) {
      //   urlBody.append("questionImage", currentImage);
      // }

      // CODE LAWAS ni
      if (formElements.questionText.value)
        urlBody.append("questionText", formElements.questionText.value);
      if (currentImage) urlBody.append("questionImage", currentImage);
      if (formElements.arrangement.value)
        urlBody.append("arrangement", formElements.arrangement.value);

      currentId = currentTest.testId;
    } else if (currentState == modulTestState.answer) {
      currentId = currentAnswer.aId;
      urlBody.append("aId", currentId);

      // Aku Perbarui biar gak error
      // if (formElements.answerText) {
      //   urlBody.append("answerText", formElements.answerText.value);
      // }
      // if (formElements.answerImage) {
      //   urlBody.append("answerImage", currentImage);
      // }
      // if (formElements.point) {
      //   urlBody.append("point", formElements.point.value);
      // }

      // CODE LAWAS ni
      if (formElements.answerText.value)
        urlBody.append("answerText", formElements.answerText.value);
      if (currentImage) urlBody.append("answerImage", currentImage);
      if (formElements.point.value)
        urlBody.append("point", formElements.point.value);

      currentId = currentQuestion.qId;
    } else if (currentState == modulTestState.section) {
      currentId = currentSection.testSectionId;
      urlBody.append("testSectionId", currentId);

      // Aku Perbarui biar gak error
      // if (formElements.name) {
      //   urlBody.append("name", formElements.name.value);
      // }
      // if (formElements.duration) {
      //   urlBody.append("duration", formElements.duration.value);
      // }
      // if (formElements.arrangement) {
      //   urlBody.append("arrangement", formElements.arrangement.value);
      // }

      // CODE LAWAS ni
      if (formElements.name.value)
        urlBody.append("name", formElements.name.value);
      if (formElements.duration.value)
        urlBody.append("duration", formElements.duration.value);
      if (formElements.arrangement.value)
        urlBody.append("arrangement", formElements.arrangement.value);
      currentId = currentTest.testId;
    } else if (currentState == modulTestState.subsection) {
      currentId = currentSubsection.testSubsectionId;
      urlBody.append("testSectionId", currentId);

      // if (formElements.name) {
      //   urlBody.append("name", formElements.name.value);
      // }
      // if (formElements.duration) {
      //   urlBody.append("duration", formElements.duration.value);
      // }
      // if (formElements.arrangement) {
      //   urlBody.append("arrangement", formElements.arrangement.value);
      // }

      // CODE LAWAS ni
      if (formElements.name.value)
        urlBody.append("name", formElements.name.value);
      if (formElements.duration.value)
        urlBody.append("duration", formElements.duration.value);
      if (formElements.arrangement.value)
        urlBody.append("arrangement", formElements.arrangement.value);
      currentId = currentSection.testSectionId;
    }

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}${urlDetail}`,
      headers: {
        Authorization: `Bearer ${code}`,
      },
      data: urlBody,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.statusCode == 200) {
          console.log("berhasil");
          getAllTestInfo(currentState, currentId);
        }

        setCurrentImage(null);
        setModalUpdate(false);
      })
      .catch((err) => {
        if (err) console.log("gagal");
      });
  };

  const addAllTestInfo = async (e, addTarget = null, addComponent = null) => {
    e.preventDefault();
    let formElements = e.target.elements;
    let addCurrentState = currentState;
    if (addTarget) addCurrentState = addTarget;

    let urlDetail =
      addCurrentState == modulTestState.test
        ? "/v1/create-test"
        : addCurrentState == modulTestState.question
        ? "/v1/create-test-question"
        : addCurrentState == modulTestState.answer
        ? "/v1/create-test-answer"
        : addCurrentState == modulTestState.section
        ? "/v1/create-test-section"
        : addCurrentState == modulTestState.subsection
        ? "/v1/create-test-subsection"
        : "";

    let urlBody = new FormData();

    let currentId = 0;
    if (addCurrentState == modulTestState.test) {
      if (formElements.name.value)
        urlBody.append("name", formElements.name.value);
      if (formElements.price.value)
        urlBody.append("price", formElements.price.value);
      if (formElements.hasSection.value)
        urlBody.append("hasSection", formElements.hasSection.value);
      if (formElements.duration.value)
        urlBody.append("duration", formElements.duration.value);
      urlBody.append("durationType", "test");

      // urlBody.append(
      //   "duration",
      //   formElements.duration ? formElements.duration.value : null
      // );
      // urlBody.append(
      //   "price",
      //   formElements.price ? formElements.price.value : null
      // );
      // urlBody.append(
      //   "hasSection",
      //   formElements.hasSection ? parseInt(formElements.hasSection.value) : null
      // );
    } else if (addCurrentState == modulTestState.question) {
      currentId = currentTest.testId;
      urlBody.append("testId", currentId);

      if (currentTest.hasSection) {
        if (currentSubsection)
          urlBody.append(
            "testSubsectionId",
            currentSubsection.testSubsectionId
          );
        // urlBody.append(
        //   "testSubsectionId",
        //   currentSubsection ? currentSubsection.testSubsectionId : null
        // );
      }
      if (formElements.questionText.value)
        urlBody.append("questionText", formElements.questionText.value);
      if (currentImage) urlBody.append("questionImage", currentImage);
      if (formElements.arrangement.value)
        urlBody.append("arrangement", formElements.arrangement.value);

      // urlBody.append(
      //   "questionText",
      //   formElements.questionText ? formElements.questionText.value : null
      // );
      // urlBody.append("questionImage", currentImage);
      // urlBody.append(
      //   "arrangement",
      //   formElements.arrangement ? formElements.arrangement.value : null
      // );
    } else if (addCurrentState == modulTestState.answer) {
      currentId = currentQuestion.qId;
      urlBody.append("qId", currentId);

      if (formElements.answerText.value)
        urlBody.append("answerText", formElements.answerText.value);
      if (currentImage) urlBody.append("answerImage", currentImage);
      if (formElements.point.value)
        urlBody.append("point", formElements.point.value);

      // formElements.answerText.value
      //   ? urlBody.append("answerText", formElements.answerText.value)
      //   : null;
      // currentImage ? urlBody.append("answerImage", currentImage) : null;
      // formElements.point.value
      //   ? urlBody.append("point", formElements.point.value)
      //   : null;
    } else if (addCurrentState == modulTestState.section) {
      currentId = addComponent ? addComponent.testId : currentTest.testId;
      urlBody.append("testId", currentId);
      if (addComponent.name || formElements.name.value)
        urlBody.append(
          "name",
          addComponent ? addComponent.name : formElements.name.value
        );
      if (addComponent.duration || formElements.duration.value)
        urlBody.append(
          "duration",
          addComponent ? addComponent.duration : formElements.duration.value
        );
      if (addComponent.arrangement || formElements.arrangement.value)
        urlBody.append(
          "arrangement",
          addComponent
            ? addComponent.arrangement
            : formElements.arrangement.value
        );
    } else if (addCurrentState == modulTestState.subsection) {
      currentId = addComponent
        ? addComponent.testSectionId
        : currentSection.testSectionId;
      urlBody.append("testSectionId", currentId);
      if (addComponent.name || formElements.name.value)
        urlBody.append(
          "name",
          addComponent ? addComponent.name : formElements.name.value
        );
      if (addComponent.duration || formElements.duration.value)
        urlBody.append(
          "duration",
          addComponent ? addComponent.duration : formElements.duration.value
        );
      if (addComponent.arrangement || formElements.arrangement.value)
        urlBody.append(
          "arrangement",
          addComponent
            ? addComponent.arrangement
            : formElements.arrangement.value
        );
    }

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}${urlDetail}`,
      headers: {
        Authorization: `Bearer ${code}`,
      },
      data: urlBody,
    };

    try {
      const response = await axios.request(config);
      if (response) {
        if (addCurrentState == modulTestState.test) {
          let hasSection = parseInt(formElements.hasSection.value);
          if (hasSection) {
            console.log("EXECUTED");
            let addResponse = await addAllTestInfo(e, modulTestState.section, {
              testId: response.results.insertId,
              name: "Main",
              duration: 0,
              arrangement: 1,
            });
          }
        } else if (addCurrentState == modulTestState.section) {
          console.log("EXECUTED SUBSECTION");
          let addResponse = await addAllTestInfo(e, modulTestState.subsection, {
            testSectionId: response.results.insertId,
            name: "Main",
            duration: 0,
            arrangement: 1,
          });
        }

        if (!addTarget) getAllTestInfo(currentState, currentId);
      }

      if (!addTarget) setModalAdd(false);
    } catch (err) {
      console.log("gagal");
      console.log(err);
    }
  };

  const deleteAllTestInfo = (e) => {
    e.preventDefault();
    let urlDetail =
      currentState == modulTestState.test
        ? "/v1/delete-test"
        : currentState == modulTestState.question
        ? "/v1/delete-test-question"
        : currentState == modulTestState.answer
        ? "/v1/delete-test-answer"
        : currentState == modulTestState.section
        ? "/v1/delete-test-section"
        : currentState == modulTestState.subsection
        ? "/v1/delete-test-subsection"
        : "";

    if (urlDetail) {
      let urlBody;

      let currentId;
      if (currentState == modulTestState.test) {
        currentId = currentTest.testId;
        urlBody = { testId: currentId };
      } else if (currentState == modulTestState.question) {
        currentId = currentTest.testId;
        urlBody = { qId: currentQuestion.qId };
      } else if (currentState == modulTestState.answer) {
        currentId = currentQuestion.qId;
        urlBody = { aId: currentAnswer.aId };
      } else if (currentState == modulTestState.section) {
        currentId = currentTest.testId;
        urlBody = { testSectionId: currentSection.testSectionId };
      } else if (currentState == modulTestState.subsection) {
        currentId = currentSection.testSectionId;
        urlBody = { testSubsectionId: currentSubsection.testSubsectionId };
      }

      let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}${urlDetail}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
        data: urlBody,
      };

      axios
        .request(config)
        .then((response) => {
          if (response.statusCode == 200) {
            console.log("berhasil");
            getAllTestInfo(currentState, currentId);
          }

          setModalDelete(!modalDelete);
        })
        .catch((err) => {
          console.log(err);
          if (err) console.log("gagal");
        });
    }
  };

  const RenderForm = (
    currentItem,
    label,
    name,
    type,
    isEmptyForm = false,
    optional = []
  ) => {
    let finalForm = [];
    label.map((label, idx) => {
      finalForm.push(
        <div className="mb-3">
          <label htmlFor="packagename-field" className="form-label">
            {label}
            {optional.length > 0 && (
              <>{optional[idx] && <span style={{ color: "red" }}>*</span>}</>
            )}
          </label>
          {type[idx] == "textarea" ? (
            <textarea
              defaultValue={
                isEmptyForm ? (currentItem ? currentItem[name[idx]] : "") : ""
              }
              type={type[idx]}
              key={name[idx]}
              name={name[idx]}
              id={name[idx] + "-field"}
              rows={4}
              className="form-control"
              placeholder={"Enter " + name[idx]}
            />
          ) : (
            <input
              defaultValue={
                isEmptyForm ? (currentItem ? currentItem[name[idx]] : "") : ""
              }
              type={type[idx]}
              key={name[idx]}
              name={name[idx]}
              id={name[idx] + "-field"}
              className="form-control"
              placeholder={"Enter " + name[idx]}
            />
          )}
        </div>
      );
    });
    return finalForm;
  };

  const RenderFormSelect = (label, name, valueList) => {
    let finalForm = [];
    label.map((label, idx) => {
      finalForm.push(
        <>
          <label htmlFor="status-field" className="form-label">
            {label} <span style={{ color: "red" }}>*</span>
          </label>
          <select
            value={counselor}
            onChange={(event) => {
              setCounselor(event.target.value);
            }}
            className="form-control"
            key={name}
            name={name}
            id="status-field"
            data-trigger
          >
            {valueList.map((option, idx) => {
              return (
                <>
                  <option value={option[1]}>{option[0]}</option>
                </>
              );
            })}
          </select>
        </>
      );
    });

    return finalForm;
  };

  const RenderFormFile = (label, name) => {
    return (
      <div className="mb-3">
        <label htmlFor="status-field" className="form-label">
          {label} <span style={{ color: "red" }}>*</span>
        </label>
        <FilePond
          onupdatefiles={(e) => {
            if (e) setCurrentImage(e[0].file);
          }}
          allowMultiple={false}
          maxFiles={1}
          name={name}
          className="filepond filepond-input-multiple"
        />
      </div>
    );
  };

  const RenderAlert = (message, color = "info") => {
    // return (
    // );
  };

  const RenderTableHead = (name) => {
    let finalForm = [];
    name.map((name, idx) => {
      let nameHtml = name.split(" ");
      nameHtml[0] = nameHtml[0].toLowerCase();
      nameHtml = nameHtml.join("");
      finalForm.push(
        <th className="sort" data-sort={nameHtml}>
          {name}
        </th>
      );
    });

    return finalForm;
  };

  const RenderTableBody = (tableContents, columnList, actionList) => {
    if (tableContents.length <= 0)
      return (
        <>
          <tr>
            <td>There's nothing here</td>
          </tr>
        </>
      );

    return tableContents.map((contentItem, index) => (
      <tr key={index}>
        <th scope="row">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              name="checkAll"
              value="option1"
            />
          </div>
        </th>
        <td className="no">{index + 1}</td>
        {Object.entries(columnList).map(([key, value], idx) => (
          <>
            <td className={key}>
              {contentItem[key] != null ? (
                !key.includes("Image") ? (
                  contentItem[key].toString()
                ) : (
                  <img
                    src={contentItem[key]}
                    style={{ width: "200px", height: "auto" }}
                  />
                )
              ) : (
                "-"
              )}
            </td>
          </>
        ))}
        <td>
          <div className="d-flex gap-2">
            <ButtonGroup className="edit">
              {actionList.map((action, idx) => (
                <>
                  {action[2] ? (
                    contentItem[action[2]] ? (
                      <button
                        className={
                          action[0].toString() == "Remove"
                            ? "btn buttonVersFutureLinkRed"
                            : "btn buttonVersFutureLinkTeal"
                        }
                        onClick={() => {
                          //console.log(contentItem);
                          action[1](contentItem);
                        }}
                      >
                        {action[0]}
                      </button>
                    ) : (
                      <></>
                    )
                  ) : (
                    <button
                      className={
                        action[0].toString() == "Remove"
                          ? "btn buttonVersFutureLinkRed"
                          : "btn buttonVersFutureLinkTeal"
                      }
                      onClick={() => {
                        //console.log(contentItem);
                        action[1](contentItem);
                      }}
                    >
                      {action[0]}
                    </button>
                  )}
                </>
              ))}
            </ButtonGroup>
          </div>
        </td>
      </tr>
    ));
  };

  return (
    <React.Fragment>
      <Container fluid>
        <div className="page-content">
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">{currentState} Table</h4>
                </CardHeader>

                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <ButtonGroup>
                          <button
                            className="btn buttonVersFutureLinkGreen"
                            onClick={() => {
                              setModalAdd(true);
                            }}
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add
                          </button>
                          {currentState != modulTestState.test && (
                            <>
                              <button
                                className="btn buttonVersFutureLink"
                                onClick={() => {
                                  setCurrentSubsection(null);
                                  setCurrentSection(null);
                                  setCurrentState(modulTestState.test);
                                }}
                              >
                                <i className="align-bottom me-1"></i> Back to
                                Test
                              </button>
                            </>
                          )}

                          {currentState == modulTestState.answer && (
                            <>
                              <button
                                className="btn buttonVersFutureLink"
                                onClick={() => {
                                  setCurrentState(modulTestState.question);
                                }}
                              >
                                <i className="align-bottom me-1"></i> Back to
                                Question
                              </button>
                            </>
                          )}

                          {currentState == modulTestState.subsection && (
                            <>
                              <button
                                className="btn buttonVersFutureLink"
                                onClick={() => {
                                  setCurrentState(modulTestState.section);
                                }}
                              >
                                <i className="align-bottom me-1"></i> Back to
                                Section
                              </button>
                            </>
                          )}
                        </ButtonGroup>
                      </Col>
                    </Row>

                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="customerTable"
                      >
                        <thead className="table-light">
                          <tr>
                            <th scope="col" style={{ width: "50px" }}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="checkAll"
                                  value="option"
                                />
                              </div>
                            </th>
                            {currentState == modulTestState.test && (
                              <>
                                {RenderTableHead([
                                  "No",
                                  "Name",
                                  "Duration",
                                  "Price",
                                  "Has Section",
                                  "Status",
                                  "Actions",
                                ])}
                              </>
                            )}
                            {currentState == modulTestState.question && (
                              <>
                                {RenderTableHead([
                                  "No",
                                  "Question Text",
                                  "Question Image",
                                  "Arrangement",
                                  "Type",
                                  "Actions",
                                ])}
                              </>
                            )}
                            {currentState == modulTestState.answer && (
                              <>
                                {RenderTableHead([
                                  "No",
                                  "Answer Text",
                                  "Answer Image",
                                  "Point(s)",
                                  "Actions",
                                ])}
                              </>
                            )}
                            {(currentState == modulTestState.section ||
                              currentState == modulTestState.subsection) && (
                              <>
                                {RenderTableHead([
                                  "No",
                                  currentState + " Name",
                                  "Duration",
                                  "Arrangement",
                                  "Actions",
                                ])}
                              </>
                            )}
                          </tr>
                        </thead>
                        <tbody className="list form-check-all">
                          {currentState == modulTestState.test && (
                            <>
                              {RenderTableBody(
                                tests,
                                {
                                  name: "string",
                                  duration: "string",
                                  price: "string",
                                  hasSection: "string",
                                  status: "string",
                                },
                                [
                                  [
                                    "Edit",
                                    (item) => {
                                      setCurrentTest(item);
                                      setModalUpdate(!modalUpdate);
                                    },
                                  ],
                                  [
                                    "View Questions",
                                    (item) => {
                                      currentFunction = getAllTestInfo(
                                        modulTestState.question,
                                        item.testId
                                      );
                                      setCurrentTest(item);
                                      setCurrentSection(null);
                                      setCurrentSubsection(null);
                                    },
                                  ],
                                  [
                                    "View Sections",
                                    (item) => {
                                      currentFunction = getAllTestInfo(
                                        modulTestState.section,
                                        item.testId
                                      );
                                      setCurrentTest(item);
                                    },
                                    "hasSection",
                                  ],
                                  [
                                    "Remove",
                                    (item) => {
                                      setCurrentTest(item);
                                      setModalDelete(!modalDelete);
                                    },
                                  ],
                                ]
                              )}
                            </>
                          )}
                          {currentState == modulTestState.question && (
                            <>
                              {RenderTableBody(
                                questions,
                                {
                                  questionText: "string",
                                  questionImage: "image",
                                  arrangement: "string",
                                  questionType: "string",
                                },
                                [
                                  [
                                    "Edit",
                                    (item) => {
                                      setCurrentQuestion(item);
                                      setModalUpdate(!modalUpdate);
                                    },
                                  ],
                                  [
                                    "View Answers",
                                    (item) => {
                                      setCurrentQuestion(item);
                                      getAllTestInfo(
                                        modulTestState.answer,
                                        item.qId
                                      );
                                    },
                                  ],
                                  [
                                    "Remove",
                                    (item) => {
                                      setCurrentQuestion(item);
                                      setModalDelete(!modalDelete);
                                    },
                                  ],
                                ]
                              )}
                            </>
                          )}
                          {currentState == modulTestState.answer && (
                            <>
                              {RenderTableBody(
                                answers,
                                {
                                  answerText: "string",
                                  answerImage: "image",
                                  point: "string",
                                },
                                [
                                  [
                                    "Edit",
                                    (item) => {
                                      setCurrentAnswer(item);
                                      setModalUpdate(!modalUpdate);
                                    },
                                  ],
                                  [
                                    "Remove",
                                    (item) => {
                                      setCurrentAnswer(item);
                                      setModalDelete(!modalDelete);
                                    },
                                  ],
                                ]
                              )}
                            </>
                          )}
                          {(currentState == modulTestState.section ||
                            currentState == modulTestState.subsection) && (
                            <>
                              {RenderTableBody(
                                currentState == modulTestState.section
                                  ? sections
                                  : subsections,
                                {
                                  name: "string",
                                  duration: "string",
                                  arrangement: "string",
                                },
                                [
                                  [
                                    "Edit",
                                    (item) => {
                                      currentState == modulTestState.section
                                        ? setCurrentSection(item)
                                        : setCurrentSubsection(item);
                                      setModalUpdate(!modalUpdate);
                                    },
                                  ],
                                  [
                                    currentState == modulTestState.section
                                      ? "View Subsection"
                                      : "View Question",
                                    (item) => {
                                      currentState == modulTestState.section
                                        ? setCurrentSection(item)
                                        : setCurrentSubsection(item);
                                      getAllTestInfo(
                                        currentState == modulTestState.section
                                          ? modulTestState.subsection
                                          : modulTestState.question,
                                        currentState == modulTestState.section
                                          ? item.testSectionId
                                          : currentTest.testId
                                      );
                                    },
                                  ],
                                  [
                                    "Remove",
                                    (item) => {
                                      currentState == modulTestState.section
                                        ? setCurrentSection(item)
                                        : setCurrentSubsection(item);
                                      setModalDelete(!modalDelete);
                                      // deleteAllTestInfo(
                                      //   currentState == modulTestState.section
                                      //     ? item.testSectionId
                                      //     : item.testSubsectionId
                                      // );
                                    },
                                  ],
                                ]
                              )}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        <Modal isOpen={modalAdd} toggle={() => setModalAdd(!modalAdd)}>
          <ModalHeader
            toggle={() => {
              setModalAdd(!modalAdd);
            }}
          >
            Add {currentState}
          </ModalHeader>
          <form
            onSubmit={(e) => {
              addAllTestInfo(e);
            }}
          >
            <ModalBody>
              <Alert className={"alertRed " + fade} isOpen={alertModal}>
                {kontenError}
              </Alert>
              {currentState == modulTestState.test && (
                <>
                  {RenderForm(
                    currentTest,
                    ["Name", "Duration", "Price"],
                    ["name", "duration", "price"],
                    ["text", "text", "text"],
                    true
                  )}
                  {RenderFormSelect(
                    ["Has Section"],
                    ["hasSection"],
                    [
                      ["True", 1],
                      ["False", 0],
                    ]
                  )}
                </>
              )}

              {currentState == modulTestState.question && (
                <>
                  {RenderForm(
                    currentQuestion,
                    ["Text", "Arrangement"],
                    ["questionText", "arrangement"],
                    ["text", "text"],
                    true
                  )}
                  {RenderFormFile("Image", "questionImage")}
                </>
              )}

              {currentState == modulTestState.answer && (
                <>
                  {RenderForm(
                    currentAnswer,
                    ["Text"],
                    ["answerText"],
                    ["text"],
                    true
                  )}
                  {RenderFormFile("Image", "questionImage")}
                  {RenderForm(currentAnswer, ["Point"], ["point"], ["text"])}
                </>
              )}

              {(currentState == modulTestState.section ||
                currentState == modulTestState.subsection) && (
                <>
                  {RenderForm(
                    currentState == modulTestState.section
                      ? currentSection
                      : currentSubsection,
                    ["Name", "Duration", "Arrangement"],
                    ["name", "duration", "arrangement"],
                    ["text", "text", "text"],
                    true
                  )}
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <div className="hstack gap-2 justify-content-end">
                <button
                  className="btn buttonVersFutureLinkBorderGrey "
                  onClick={() => {
                    setModalAdd(false);
                  }}
                >
                  Close
                </button>
                <input
                  className="btn buttonVersFutureLink"
                  type="submit"
                  value={"Add " + currentState}
                />
              </div>
            </ModalFooter>
          </form>
        </Modal>

        <Modal isOpen={modalUpdate} toggle={() => setModalUpdate(!modalUpdate)}>
          <ModalHeader
            toggle={() => {
              setModalUpdate(!modalUpdate);
            }}
          >
            Update {currentState}
          </ModalHeader>
          <form
            onSubmit={(e) =>
              updateAllTestInfo(e, currentTest ? currentTest.testId : 0)
            }
          >
            <ModalBody>
              <Alert className={"alertRed " + fade} isOpen={alertModal}>
                {kontenError}
              </Alert>
              {currentState == modulTestState.test && (
                <>
                  {RenderForm(
                    currentTest,
                    ["Name", "Duration", "Price"],
                    ["name", "duration", "price"],
                    ["text", "text", "text"]
                  )}
                </>
              )}

              {currentState == modulTestState.question && (
                <>
                  {RenderForm(
                    currentQuestion,
                    ["Question Text"],
                    ["questionText"],
                    ["textarea"]
                  )}
                  {RenderFormFile("Question Image", "questionImage")}
                  {RenderForm(
                    currentQuestion,
                    ["Arrangement"],
                    ["arrangement"],
                    ["text"]
                  )}
                </>
              )}

              {currentState == modulTestState.answer && (
                <>
                  {RenderForm(
                    currentAnswer,
                    ["Answer Text"],
                    ["answerText"],
                    ["textarea"]
                  )}
                  {RenderFormFile("Answer Image", "answerImage")}
                  {RenderForm(currentAnswer, ["Point"], ["point"], ["text"])}
                </>
              )}

              {(currentState == modulTestState.section ||
                currentState == modulTestState.subsection) && (
                <>
                  {RenderForm(
                    currentState == modulTestState.section
                      ? currentSection
                      : currentSubsection,
                    ["Name", "Duration", "Arrangement"],
                    ["name", "duration", "arrangement"],
                    ["text", "text", "text"]
                  )}
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <div className="hstack gap-2 justify-content-end">
                <button
                  className="btn buttonVersFutureLinkBorderGrey "
                  onClick={() => {
                    setModalUpdate(false);
                  }}
                >
                  Close
                </button>
                <input
                  className="btn buttonVersFutureLink"
                  type="submit"
                  value={"Update " + currentState}
                />
              </div>
            </ModalFooter>
          </form>
        </Modal>

        <Modal isOpen={modalDelete} toggle={() => setModalDelete(!modalDelete)}>
          <ModalHeader
            toggle={() => {
              setModalDelete(!modalDelete);
            }}
          >
            Delete {currentState}
          </ModalHeader>
          <form onSubmit={(e) => deleteAllTestInfo(e)}>
            <ModalBody>
              <Alert className={"alertRed " + fade} isOpen={alertModal}>
                {kontenError}
              </Alert>
              Are you sure to delete this {currentState.toLocaleLowerCase()}
            </ModalBody>
            <ModalFooter>
              <div className="hstack gap-2 justify-content-end">
                <button
                  className="btn buttonVersFutureLinkBorderGrey "
                  onClick={() => {
                    setModalDelete(false);
                  }}
                >
                  Close
                </button>
                <input
                  className="btn buttonVersFutureLink"
                  type="submit"
                  value={"Delete " + currentState}
                />
              </div>
            </ModalFooter>
          </form>
        </Modal>

        <Modal isOpen={alert}>
          <ModalHeader
            toggle={() => {
              setAlertModal(!alertModal);
            }}
          >
            Alert {currentState}
          </ModalHeader>
          <Alert color="info">{alertMessage}</Alert>
        </Modal>
      </Container>
    </React.Fragment>
  );
};

export default ModulTest;

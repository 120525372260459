import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import otakKanan from '../../../../../assets/images/FutureLink/otakKanan.png';
import otakKiri from '../../../../../assets/images/FutureLink/otakKiri.png';
import otakTengah from '../../../../../assets/images/FutureLink/otakTengah.png';

const DominasiOtak= (haisayang) => {
    const data = haisayang.history.location.state.data
    // console.log(haisayang.history.location.state.data)

    const packages = haisayang.history.location.state.package_id
    // console.log(haisayang.history.location.state.package_id)

    const token = haisayang.history.location.state.params

    return(
        <React.Fragment>
            <div className="page-content">

                <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                    <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 80px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Dominasi Otak</p>
                </div>

                <div className='dominasiotak'>
                    <Link style={{marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to={`/report-mobile/${token}`}>Back</Link>

                        {data.left_brain === data.right_brain ? (
                        <>
                        <div className='dominasiotakContainter'>
                            <div className='dominasiotakContainterBox'>
                                <img src={otakTengah} style={{width: '80%'}}/>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px'}}>
                                    <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>50%</p>
                                    <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Otak Kiri & Otak Kanan</p>
                                </div>
                            </div>
                        </div>
                        </>) : data.left_brain > data.right_brain ? (
                        <>
                        <div className='dominasiotakContainter'>
                            <div className='dominasiotakContainterBox'>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px'}}>
                                    <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Otak Kiri</p>
                                    <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{data.left_brain}%</p>
                                </div>
                                <img src={otakKiri} style={{width: '100%'}}/>
                            </div>
                            
                            <div className='dominasiotakContainterBox'>
                                <img src={otakKanan} style={{width: '80%'}}/>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px'}}>
                                    <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#EF6806'}}>{data.right_brain}%</p>
                                    <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Otak Kanan</p>
                                </div>
                            </div>
                        </div>
                        </>) : (
                        <>
                        <div className='dominasiotakContainterReverse'>
                            <div className='dominasiotakContainterBox'>
                                <img src={otakKiri} style={{width: '80%'}}/>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px'}}>
                                    <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#EF6806'}}>{data.left_brain}%</p>
                                    <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Otak Kiri</p>
                                </div>
                            </div>
                            
                            <div className='dominasiotakContainterBox'>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px'}}>
                                    <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Otak Kanan</p>
                                    <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{data.right_brain}%</p>
                                </div>
                                <img src={otakKanan} style={{width: '100%'}}/>
                            </div>
                        </div>
                        </>) }

                    <p className='dominasiotakKeterangan'>
                        Pada sekitar tahun 1960, ahli saraf Roger W. Sperry menemukan bahwa kedua sisi otak melakukan tugas yang berbeda. Ada dua belahan otak yaitu belahan kiri dan belahan kanan. Belahan kiri otak berperan khusus dalam menangani tugas-tugas logis, faktual, analitis dan verbal. Sedangkan belahan kanan otak berperan untuk menangani tugas persepsi ruang dan musik, kreativitas, serta emosi.
                    </p>

                </div>

                <div style={{display: 'flex', justifyContent:'space-between', paddingTop: '32px'}}>
                    {packages === 1 && (
                        <>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report-mobiles/distribusi-neuron", state: { data: data, package_id: packages, params: token }}} onClick={() => {document.body.scrollIntoView({behavior: "smooth"})}}>{"<"} Distribusi Neuron</Link>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report-mobiles/gaya-belajar", state: { data: data, package_id: packages, params: token }}} onClick={() => {document.body.scrollIntoView({behavior: "smooth"})}}>Gaya Belajar {">"}</Link>
                        </>
                    )}
                    {packages === 22 && (
                        <>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report-mobiles/distribusi-neuron", state: { data: data, package_id: packages, params: token }}} onClick={() => {document.body.scrollIntoView({behavior: "smooth"})}}>{"<"} Distribusi Neuron</Link>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report-mobiles/gaya-belajar", state: { data: data, package_id: packages, params: token }}} onClick={() => {document.body.scrollIntoView({behavior: "smooth"})}}>Gaya Belajar {">"}</Link>
                        </>
                    )}
                    {packages === 33 && (
                        <>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report-mobiles/indra-dasar", state: { data: data, package_id: packages, params: token }}} onClick={() => {document.body.scrollIntoView({behavior: "smooth"})}}>{"<"} Indra Dasar</Link>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report-mobiles/gaya-belajar", state: { data: data, package_id: packages, params: token }}} onClick={() => {document.body.scrollIntoView({behavior: "smooth"})}}>Gaya Belajar {">"}</Link>
                        </>
                    )}
                </div>


            </div>
        </React.Fragment>
    )

}

export default DominasiOtak;
import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody, ModalFooter, Row, Input } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Flatpickr from "react-flatpickr";
import Masonry from "react-masonry-component";

import axios from 'axios';
import FormData from 'form-data';

import accessDenied from '../../../assets/images/FutureLink/accessDenied.png'

import Lottie from 'react-lottie';
import animateSuccess from '../../../assets/images/FutureLink/success.json'

//import images
import KiJempol from '../../../assets/images/FutureLink/KiJempol.png';
import KiTelunjuk from '../../../assets/images/FutureLink/KiTelunjuk.png';
import KiTengah from '../../../assets/images/FutureLink/KiTengah.png';
import KiManis from '../../../assets/images/FutureLink/KiManis.png';
import KiKelingking from '../../../assets/images/FutureLink/KiKelingking.png';
import KaJempol from '../../../assets/images/FutureLink/KaJempol.png';
import KaTelunjuk from '../../../assets/images/FutureLink/KaTelunjuk.png';
import KaTengah from '../../../assets/images/FutureLink/KaTengah.png';
import KaManis from '../../../assets/images/FutureLink/KaManis.png';
import KaKelingking from '../../../assets/images/FutureLink/KaKelingking.png';

import RThumb from '../../../assets/images/FutureLink/RThumb.png'
import RPoint from '../../../assets/images/FutureLink/RPoint.png'
import RMiddle from '../../../assets/images/FutureLink/RMiddle.png'
import RRing from '../../../assets/images/FutureLink/RRing.png'
import RPinky from '../../../assets/images/FutureLink/RPinky.png'
import LThumb from '../../../assets/images/FutureLink/LThumb.png'
import LPoint from '../../../assets/images/FutureLink/LPoint.png'
import LMiddle from '../../../assets/images/FutureLink/LMiddle.png'
import LRing from '../../../assets/images/FutureLink/LRing.png'
import LPinky from '../../../assets/images/FutureLink/LPinky.png'


const TableUserDefault = () => {
    // document.title="Widgets | Velzon - React Admin & Dashboard Template";
    const [modalAccessDenied, setModalAccessDenied] = useState(false);
    const logOut = () => {
        sessionStorage.removeItem('user');
        window.location.href = '/login'
    }

    const [modalAlertSuccess, setModalAlertSuccess] = useState(false); 
    const [modalAlertAnnotator, setModalAlertAnnotator] = useState(false); 

    const code = sessionStorage.getItem('user');
    const fingerType = [
        {
          name: "Tidak diketahui",
          value: "nothing",
        },
        {
          name: "Whorl Centric",
          value: "W_centric",
        },
        {
          name: "Whorl Spiral",
          value: "W_spiral",
        },
        {
          name: "Whorl Elongated Centric",
          value: "W_elongated_centric",
        },
        {
          name: "Whorl Elongated Spiral",
          value: "W_elongated_spiral",
        },
        {
          name: "Whorl Imploding",
          value: "W_imploding",
        },
        {
          name: "Whorl Composite",
          value: "W_composite",
        },
        {
          name: "Target Centric Ulnar Peacock Eye",
          value: "W_peacock_centric_ulnar",
        },
        {
          name: "Spiral Ulnar Peacock Eye",
          value: "W_peacock_spiral_ulnar",
        },
        {
          name: "Target Centric Radial Peacock Eye",
          value: "W_peacock_centric_radial",
        },
        {
          name: "Spiral Radial Peacock Eye",
          value: "W_peacock_spiral_radial",
        },
        {
          name: "Loop Double",
          value: "L_double",
        },
        {
          name: "Loop Ulnar",
          value: "L_ulnar",
        },
        {
          name: "Loop Radial",
          value: "L_radial",
        },
        {
          name: "Loop Falling Ulnar",
          value: "L_fallen_ulnar",
        },
        {
          name: "Loop Falling Radial",
          value: "L_fallen_radial",
        },
        {
          name: "Arch Simple",
          value: "A_simple",
        },
        {
          name: "Arch Tented",
          value: "A_tented",
        },
        {
          name: "Arch Tented with Ulnar Loop",
          value: "A_w_L_ulnar",
        },
        {
          name: "Arch Tented with Radial Loop",
          value: "A_w_L_radial",
        },
        {
          name: "Accidental Whorl",
          value: "accidental",
        },
    ];
    const [step, setStep] = useState(0);

    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState("");
    const [imageFingerprint, setImageFingerprint] = useState("");
    const [noted, setNoted] = useState("");

    const [nama, setNama] = useState("");
    const [kata, setKata] = useState('')

    const [l1, setL1] = useState("")
    const [l2, setL2] = useState("")
    const [l3, setL3] = useState("")
    const [l4, setL4] = useState("")
    const [l5, setL5] = useState("")
    const [l1Catatan, setL1Catatan] = useState("")
    const [l2Catatan, setL2Catatan] = useState("")
    const [l3Catatan, setL3Catatan] = useState("")
    const [l4Catatan, setL4Catatan] = useState("")
    const [l5Catatan, setL5Catatan] = useState("")
    const [l1Rcs, setL1Rcs] = useState("")
    const [l2Rcs, setL2Rcs] = useState("")
    const [l3Rcs, setL3Rcs] = useState("")
    const [l4Rcs, setL4Rcs] = useState("")
    const [l5Rcs, setL5Rcs] = useState("")
    const [l1Shape, setL1Shape] = useState("")
    const [l2Shape, setL2Shape] = useState("")
    const [l3Shape, setL3Shape] = useState("")
    const [l4Shape, setL4Shape] = useState("")
    const [l5Shape, setL5Shape] = useState("")
    const [l1Status, setL1Status] = useState("")
    const [l2Status, setL2Status] = useState("")
    const [l3Status, setL3Status] = useState("")
    const [l4Status, setL4Status] = useState("")
    const [l5Status, setL5Status] = useState("")

    const [r1, setR1] = useState("")
    const [r2, setR2] = useState("")
    const [r3, setR3] = useState("")
    const [r4, setR4] = useState("")
    const [r5, setR5] = useState("")
    const [r1Catatan, setR1Catatan] = useState("")
    const [r2Catatan, setR2Catatan] = useState("")
    const [r3Catatan, setR3Catatan] = useState("")
    const [r4Catatan, setR4Catatan] = useState("")
    const [r5Catatan, setR5Catatan] = useState("")
    const [r1Rcs, setR1Rcs] = useState("")
    const [r2Rcs, setR2Rcs] = useState("")
    const [r3Rcs, setR3Rcs] = useState("")
    const [r4Rcs, setR4Rcs] = useState("")
    const [r5Rcs, setR5Rcs] = useState("")
    const [r1Shape, setR1Shape] = useState("")
    const [r2Shape, setR2Shape] = useState("")
    const [r3Shape, setR3Shape] = useState("")
    const [r4Shape, setR4Shape] = useState("")
    const [r5Shape, setR5Shape] = useState("")
    const [r1Status, setR1Status] = useState("")
    const [r2Status, setR2Status] = useState("")
    const [r3Status, setR3Status] = useState("")
    const [r4Status, setR4Status] = useState("")
    const [r5Status, setR5Status] = useState("")
    const [checkLog, setCheckLog] = useState("")

    //modal
    const [modalImage, setModalImage] = useState(false);
    const [modalCatatan, setModalCatatan] = useState(false);
    //end modal

    // Flownya gini... Patternnya buat array dulu... terus di filter sesuai nama pattern dan hitung jumlahnya ada berapa dan dikurangin dengan 10... terus di check apakah sesuai dengan pattern khusus ? jika kena nama pattern khusus dan belum diisi Rcsnya jadi 0, selain itu diparseInt (karena string)...  terus totalRCS / pengurang
    const array = [l1Shape, l2Shape, l3Shape, l4Shape, l5Shape, r1Shape, r2Shape, r3Shape, r4Shape, r5Shape];
    const jumlahArchSimple = array.filter((item) => item === "A_simple").length;
    const jumlahArchTented = array.filter((item) => item === "A_tented").length;
    const jumlahAccidental = array.filter((item) => item === "accidental").length;

    const q1 = l1Shape === "A_simple" || l1Shape === "A_tented" || l1Shape === "accidental" || l1Shape === "" || l1Shape === null || l1Rcs === "" || l1Rcs === null ? 0 : parseInt(l1Rcs);
    const q2 = l2Shape === "A_simple" || l2Shape === "A_tented" || l2Shape === "accidental" || l2Shape === "" || l2Shape === null || l2Rcs === "" || l2Rcs === null ? 0 : parseInt(l2Rcs);
    const q3 = l3Shape === "A_simple" || l3Shape === "A_tented" || l3Shape === "accidental" || l3Shape === "" || l3Shape === null || l3Rcs === "" || l3Rcs === null ? 0 : parseInt(l3Rcs);
    const q4 = l4Shape === "A_simple" || l4Shape === "A_tented" || l4Shape === "accidental" || l4Shape === "" || l4Shape === null || l4Rcs === "" || l4Rcs === null ? 0 : parseInt(l4Rcs);
    const q5 = l5Shape === "A_simple" || l5Shape === "A_tented" || l5Shape === "accidental" || l5Shape === "" || l5Shape === null || l5Rcs === "" || l5Rcs === null ? 0 : parseInt(l5Rcs);
    const w1 = r1Shape === "A_simple" || r1Shape === "A_tented" || r1Shape === "accidental" || r1Shape === "" || r1Shape === null || r1Rcs === "" || r1Rcs === null ? 0 : parseInt(r1Rcs);
    const w2 = r2Shape === "A_simple" || r2Shape === "A_tented" || r2Shape === "accidental" || r2Shape === "" || r2Shape === null || r2Rcs === "" || r2Rcs === null ? 0 : parseInt(r2Rcs);
    const w3 = r3Shape === "A_simple" || r3Shape === "A_tented" || r3Shape === "accidental" || r3Shape === "" || r3Shape === null || r3Rcs === "" || r3Rcs === null ? 0 : parseInt(r3Rcs);
    const w4 = r4Shape === "A_simple" || r4Shape === "A_tented" || r4Shape === "accidental" || r4Shape === "" || r4Shape === null || r4Rcs === "" || r4Rcs === null ? 0 : parseInt(r4Rcs);
    const w5 = r5Shape === "A_simple" || r5Shape === "A_tented" || r5Shape === "accidental" || r5Shape === "" || r5Shape === null || r5Rcs === "" || r5Rcs === null ? 0 : parseInt(r5Rcs);
    const pengurang = 10 - jumlahAccidental - jumlahArchSimple - jumlahArchTented;
    const jumlah = (q1+q2+q3+q4+q5+w1+w2+w3+w4+w5)/pengurang;

    
    const handleModalImage = (img) => {
        setModalImage(!modalImage);
        setImageFingerprint(img);
    }

    const handleModalCatatan = (catatan) => {
        setModalCatatan(!modalCatatan);
        setNoted(catatan);
    }

    const [sorting, setSorting] = useState(false)
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [nothing, setNothing] = useState(false);
    const [orderDate, setOrderDate] = useState('asc');
    const [modalNoData, setModalNoData] = useState(false);
    const [row, setRow] = useState(10)

    useEffect(() => {
        getAllFingerprint()
    },[page, limit, orderDate])

    const getAllFingerprint = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-fingerprint?page=${page}&limit=${limit}&orderDate=${orderDate}`,
            headers: { 
              'Authorization': `Bearer ${code}`, 
            },
        };
          
        axios(config)
            .then((response) => {
                setUsers(response.results);
                setRow(response.results.length)
            })
            .catch((error) => {
                // console.log(error);
                if(error.response.data.statusCode === 403) {
                    setModalAccessDenied(true)
                }
                if(error.response.data.statusCode === 404) {
                    setModalNoData(true);
                    setPage(1)
                }
            });
    }

    const getFingerprintByID = (user_id) => {
        let data = new FormData();

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-by-id?userId=${user_id}`,
            headers: { 
                'Authorization': `Bearer ${code}`
            },
            data : data
        };

        axios(config)
        .then((response) => {
            // console.log(response)
                getImage(response.results[0].l1, "l1")
                getImage(response.results[0].l2, "l2")
                getImage(response.results[0].l3, "l3")
                getImage(response.results[0].l4, "l4")
                getImage(response.results[0].l5, "l5")
                getImage(response.results[0].r1, "r1")
                getImage(response.results[0].r2, "r2")
                getImage(response.results[0].r3, "r3")
                getImage(response.results[0].r4, "r4")
                getImage(response.results[0].r5, "r5")

                setL1Catatan(response.results[0].l1_catatan)
                setL2Catatan(response.results[0].l2_catatan)
                setL3Catatan(response.results[0].l3_catatan)
                setL4Catatan(response.results[0].l4_catatan)
                setL5Catatan(response.results[0].l5_catatan)
                setR1Catatan(response.results[0].r1_catatan)
                setR2Catatan(response.results[0].r2_catatan)
                setR3Catatan(response.results[0].r3_catatan)
                setR4Catatan(response.results[0].r4_catatan)
                setR5Catatan(response.results[0].r5_catatan)

                setL1Rcs(response.results[0].l1_rcs)
                setL2Rcs(response.results[0].l2_rcs)
                setL3Rcs(response.results[0].l3_rcs)
                setL4Rcs(response.results[0].l4_rcs)
                setL5Rcs(response.results[0].l5_rcs)
                setR1Rcs(response.results[0].r1_rcs)
                setR2Rcs(response.results[0].r2_rcs)
                setR3Rcs(response.results[0].r3_rcs)
                setR4Rcs(response.results[0].r4_rcs)
                setR5Rcs(response.results[0].r5_rcs)

                setL1Shape(response.results[0].l1_shape)
                setL2Shape(response.results[0].l2_shape)
                setL3Shape(response.results[0].l3_shape)
                setL4Shape(response.results[0].l4_shape)
                setL5Shape(response.results[0].l5_shape)
                setR1Shape(response.results[0].r1_shape)
                setR2Shape(response.results[0].r2_shape)
                setR3Shape(response.results[0].r3_shape)
                setR4Shape(response.results[0].r4_shape)
                setR5Shape(response.results[0].r5_shape)

                setL1Status(response.results[0].l1_status)
                setL2Status(response.results[0].l2_status)
                setL3Status(response.results[0].l3_status)
                setL4Status(response.results[0].l4_status)
                setL5Status(response.results[0].l5_status)
                setR1Status(response.results[0].r1_status)
                setR2Status(response.results[0].r2_status)
                setR3Status(response.results[0].r3_status)
                setR4Status(response.results[0].r4_status)
                setR5Status(response.results[0].r5_status)

                setCheckLog(response.results[0].checked_log)
        })
        .catch((error) => {
            // console.log(error);
            if(error.response.data.statusCode === 403) {
                setModalAccessDenied(true)
            }
        });
    }

    const getImage = (imageName, index) => {

        if(index === "l1"){
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
                headers: { 
                  'Authorization': `Bearer ${code}`
                }
            };
              
            axios.request(config)
                .then((response) => {
                    // console.log(response)
                    setL1(response.results.image)
                })
                .catch((error) => {
                    // console.log(error);
                });
        }
        if(index === "l2"){
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
                headers: { 
                  'Authorization': `Bearer ${code}`
                }
            };
              
            axios.request(config)
                .then((response) => {
                    // console.log(response)
                    setL2(response.results.image)
                })
                .catch((error) => {
                    // console.log(error);
                });
        }
        if(index === "l3"){
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
                headers: { 
                  'Authorization': `Bearer ${code}`
                }
            };
              
            axios.request(config)
                .then((response) => {
                    // console.log(response)
                    setL3(response.results.image)
                })
                .catch((error) => {
                    // console.log(error);
                });
        }
        if(index === "l4"){
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
                headers: { 
                  'Authorization': `Bearer ${code}`
                }
            };
              
            axios.request(config)
                .then((response) => {
                    // console.log(response)
                    setL4(response.results.image)
                })
                .catch((error) => {
                    // console.log(error);
                });
        }
        if(index === "l5"){
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
                headers: { 
                  'Authorization': `Bearer ${code}`
                }
            };
              
            axios.request(config)
                .then((response) => {
                    // console.log(response)
                    setL5(response.results.image)
                })
                .catch((error) => {
                    // console.log(error);
                });
        }
        if(index === "r1"){
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
                headers: { 
                  'Authorization': `Bearer ${code}`
                }
            };
              
            axios.request(config)
                .then((response) => {
                    // console.log(response)
                    setR1(response.results.image)
                })
                .catch((error) => {
                    // console.log(error);
                });
        }
        if(index === "r2"){
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
                headers: { 
                  'Authorization': `Bearer ${code}`
                }
            };
              
            axios.request(config)
                .then((response) => {
                    // console.log(response)
                    setR2(response.results.image)
                })
                .catch((error) => {
                    // console.log(error);
                });
        }
        if(index === "r3"){
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
                headers: { 
                  'Authorization': `Bearer ${code}`
                }
            };
              
            axios.request(config)
                .then((response) => {
                    // console.log(response)
                    setR3(response.results.image)
                })
                .catch((error) => {
                    // console.log(error);
                });
        }
        if(index === "r4"){
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
                headers: { 
                  'Authorization': `Bearer ${code}`
                }
            };
              
            axios.request(config)
                .then((response) => {
                    // console.log(response)
                    setR4(response.results.image)
                })
                .catch((error) => {
                    // console.log(error);
                });
        }
        if(index === "r5"){
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
                headers: { 
                  'Authorization': `Bearer ${code}`
                }
            };
              
            axios.request(config)
                .then((response) => {
                    // console.log(response)
                    setR5(response.results.image)
                })
                .catch((error) => {
                    // console.log(error);
                });
        }


    }

    const updateFingerprint = () => {

        if(r1Status === "valid" && r2Status === "valid" && r3Status === "valid" && r4Status === "valid" && r5Status === "valid" && l1Status === "valid" && l2Status === "valid" && l3Status === "valid" && l4Status === "valid" && l5Status === "valid"){
            setModalAlertAnnotator(true);

            setTimeout(() => {
                setModalAlertAnnotator(false);
            }, 4000)
        } else {
            let data = new FormData();
            data.append('userId', userId);
            data.append('l1Rcs', l1Shape === "A_simple" || l1Shape === "A_tented" || l1Shape === "accidental" ? jumlah.toFixed(1): l1Rcs);
            data.append('l2Rcs', l2Shape === "A_simple" || l2Shape === "A_tented" || l2Shape === "accidental" ? jumlah.toFixed(1): l2Rcs);
            data.append('l3Rcs', l3Shape === "A_simple" || l3Shape === "A_tented" || l3Shape === "accidental" ? jumlah.toFixed(1): l3Rcs);
            data.append('l4Rcs', l4Shape === "A_simple" || l4Shape === "A_tented" || l4Shape === "accidental" ? jumlah.toFixed(1): l4Rcs);
            data.append('l5Rcs', l5Shape === "A_simple" || l5Shape === "A_tented" || l5Shape === "accidental" ? jumlah.toFixed(1): l5Rcs);
            data.append('r1Rcs', r1Shape === "A_simple" || r1Shape === "A_tented" || r1Shape === "accidental" ? jumlah.toFixed(1): r1Rcs);
            data.append('r2Rcs', r2Shape === "A_simple" || r2Shape === "A_tented" || r2Shape === "accidental" ? jumlah.toFixed(1): r2Rcs);
            data.append('r3Rcs', r3Shape === "A_simple" || r3Shape === "A_tented" || r3Shape === "accidental" ? jumlah.toFixed(1): r3Rcs);
            data.append('r4Rcs', r4Shape === "A_simple" || r4Shape === "A_tented" || r4Shape === "accidental" ? jumlah.toFixed(1): r4Rcs);
            data.append('r5Rcs', r5Shape === "A_simple" || r5Shape === "A_tented" || r5Shape === "accidental" ? jumlah.toFixed(1): r5Rcs);
            data.append('r1Shape', r1Shape);
            data.append('r2Shape', r2Shape);
            data.append('r3Shape', r3Shape);
            data.append('r4Shape', r4Shape);
            data.append('r5Shape', r5Shape);
            data.append('l1Shape', l1Shape);
            data.append('l2Shape', l2Shape);
            data.append('l3Shape', l3Shape);
            data.append('l4Shape', l4Shape);
            data.append('l5Shape', l5Shape);
    
            let config = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/update-fingerprint/from-annotator`,
            headers: { 
                'Authorization': `Bearer ${code}`, 
            },
            data : data
            };
    
            axios(config)
            .then((response) => {
                // console.log(response);
                setModalAlertSuccess(true);
                setTimeout(() => {
                    setModalAlertSuccess(false);
                    window.location.reload()
                }, 4000)
            })
            .catch((error) => {
                // console.log(error);
                if(error.response.data.statusCode === 403) {
                    setModalAccessDenied(true)
                }
            });
        }
    }

    return ( 
        <React.Fragment>
            <Container fluid>
            <div className="page-content">

            <Row>
                <Col lg={12}>
                    {step === 0 && (
                        <>
                    <Card>
                        <CardHeader>
                            <h4 className="card-title mb-0">Annotation Table</h4>
                        </CardHeader>

                        <CardBody>

                            <div id="customerList">
                                <Row className="g-4 mb-3">
                                    <Col style={{padding: '0px'}} className='col-sm' >    
                                        <div className="d-flex justify-content-sm-end">
                                            <div style={{marginRight: '10px'}}>
                                                <select className="form-control"  value={limit} onChange={(event) => {setLimit(event.target.value); setPage(1)}}>
                                                    <option value={10} selected="selected">10</option>
                                                    <option value={20}>20</option>
                                                    <option value={50}>50</option>
                                                    <option value={100}>100</option>
                                                    <option value={200}>200</option>
                                                </select>
                                            </div>
                                            <div style={{marginRight: '10px'}}>
                                                {sorting === true ? (
                                                    <>
                                                    <button className='btn buttonVersFutureLinkBorderGrey' onClick={() => {setOrderDate('asc'); setSorting(!sorting)}}>
                                                        <i className='ri-sort-asc'></i>  
                                                    </button>
                                                    </>
                                                    ) : (
                                                    <>
                                                    <button className='btn buttonVersFutureLinkBorderGrey' onClick={() => {setOrderDate('desc'); setSorting(!sorting)}}>
                                                        <i className='ri-sort-desc'></i>  
                                                    </button>
                                                    </>
                                                )}
                                            </div>
                                            <div className="search-box">
                                                <input type="text" className="form-control search" placeholder="Search..." onChange={(e) => setKata(e.target.value)}/>
                                                <i className="ri-search-line search-icon"></i>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <div className="pagination-wrap hstack gap-2">
                                                    {page !== 1 && (
                                                        <>
                                                        <Link className="page-item pagination-prev" onClick={() => {setPage(page-1)}}>
                                                            Previous
                                                        </Link>
                                                        </>
                                                    )}
                                                    {row === parseInt(limit) && (
                                                        <>
                                                        <Link className="page-item pagination-next" onClick={() => {setPage(page+1)}}>
                                                            Next
                                                        </Link>
                                                        </>
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <div className="table-responsive table-card mt-3 mb-1">
                                    <table className="table align-middle table-nowrap" id="customerTable">
                                        <thead className="table-light">
                                            <tr>
                                                <th scope="col" style={{ width: "50px" }}>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                    </div>
                                                </th>
                                                <th className="sort" data-sort="no">No</th>
                                                <th className="sort" data-sort="nama">Name</th>
                                                <th className="sort" data-sort="email">Email</th>
                                                <th className="sort" data-sort="status">Package</th>
                                                <th className="sort" data-sort="images">Images</th>
                                                <th className="sort" data-sort="status_anotasi">Status Annotasi</th>
                                                {/* <th className="sort" data-sort="action">Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody className="list form-check-all">
                                            {users.filter((item) => 
                                            // item.name.toLowerCase().includes(kata.toLowerCase()) ||
                                            item.first_name.toLowerCase().includes(kata.toLowerCase()) ||
                                            item.last_name.toLowerCase().includes(kata.toLowerCase())
                                            ).map((item, index) => (
                                            <>
                                            <tr key={index}>
                                                <th scope="row">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="checkAll" value="option1" />
                                                    </div>
                                                </th>
                                                <td className="no">{index+1}</td>
                                                <td className="nama">{item.first_name} {item.last_name}</td>
                                                <td className="email">{item.email}</td>
                                                <td className="status"><span className="badge badge-soft-success text-uppercase">{item.package_name}</span></td>
                                                <td className="status">
                                                    <div className="edit">
                                                            <button className="btn btn-sm btn-success edit-item-btn" onClick={() => {setStep(1); getFingerprintByID(item.user_id); setUserId(item.user_id); setNama(`${item.first_name} ${item.last_name}`) }}>View Images</button>
                                                        </div>
                                                </td>
                                                {
                                                    item.r1_status == null &&
                                                    item.r2_status == null &&
                                                    item.r3_status == null &&
                                                    item.r4_status == null &&
                                                    item.r5_status == null &&
                                                    item.l1_status == null &&
                                                    item.l2_status == null &&
                                                    item.l3_status == null &&
                                                    item.l4_status == null &&
                                                    item.l5_status == null ? (
                                                        <>
                                                        <td className="status"><span style={{padding: '7px 15px', fontSize: '12px', fontWeight: '600', borderRadius: '30px', color: 'white', backgroundColor: '#3B82F6'}}>Waiting</span></td>
                                                        </>
                                                    ) :  
                                                    item.r1_status == null ||
                                                    item.r2_status == null ||
                                                    item.r3_status == null ||
                                                    item.r4_status == null ||
                                                    item.r5_status == null ||
                                                    item.l1_status == null ||
                                                    item.l2_status == null ||
                                                    item.l3_status == null ||
                                                    item.l4_status == null ||
                                                    item.l5_status == null ||
                                                    item.r1_status == "not valid" ||
                                                    item.r2_status == "not valid" ||
                                                    item.r3_status == "not valid" ||
                                                    item.r4_status == "not valid" ||
                                                    item.r5_status == "not valid" ||
                                                    item.l1_status == "not valid" ||
                                                    item.l2_status == "not valid" ||
                                                    item.l3_status == "not valid" ||
                                                    item.l4_status == "not valid" ||
                                                    item.l5_status == "not valid" ? (
                                                        <>
                                                        <td className="status"><span style={{padding: '7px 19px', fontSize: '12px', fontWeight: '600', borderRadius: '30px', color: 'white', backgroundColor: '#F43F5E'}}>Reject</span></td>
                                                        </>
                                                    ) : (
                                                        <>
                                                        <td className="status"><span style={{padding: '7px 17px', fontSize: '12px', fontWeight: '600', borderRadius: '30px', color: 'white', backgroundColor: '#10B981'}}>Accept</span></td>
                                                        </>
                                                    )
                                                }
                                            </tr>
                                            </>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="d-flex justify-content-end">
                                    <div className="pagination-wrap hstack gap-2">
                                         {page !== 1 && (
                                            <>
                                            <Link className="page-item pagination-prev" onClick={() => {setPage(page-1)}}>
                                                Previous
                                            </Link>
                                            </>
                                        )}
                                        {row === parseInt(limit) && (
                                            <>
                                            <Link className="page-item pagination-next" onClick={() => {setPage(page+1)}}>
                                                Next
                                            </Link>
                                            </>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                        </>
                    )}
                    {step === 1 && (
                        <>
                        <button className='btn buttonVersFutureLinkBorderGrey' style={{width : '70px', marginLeft: '20px'}} 
                            onClick={() => {
                                setStep(0);
                                setR1("");
                                setR2("");
                                setR3("");
                                setR4("");
                                setR5("");
                                setL1("");
                                setL2("");
                                setL3("");
                                setL4("");
                                setL5("");
                                setR1Status("");
                                setR2Status("");
                                setR3Status("");
                                setR4Status("");
                                setR5Status("");
                                setL1Status("");
                                setL2Status("");
                                setL3Status("");
                                setL4Status("");
                                setL5Status("");
                                setR1Catatan("");
                                setR2Catatan("");
                                setR3Catatan("");
                                setR4Catatan("");
                                setR5Catatan("");
                                setL1Catatan("");
                                setL2Catatan("");
                                setL3Catatan("");
                                setL4Catatan("");
                                setL5Catatan("");
                                setR1Shape("");
                                setR2Shape("");
                                setR3Shape("");
                                setR4Shape("");
                                setR5Shape("");
                                setL1Shape("");
                                setL2Shape("");
                                setL3Shape("");
                                setL4Shape("");
                                setL5Shape("");
                                setR1Rcs("");
                                setR2Rcs("");
                                setR3Rcs("");
                                setR4Rcs("");
                                setR5Rcs("");
                                setL1Rcs("");
                                setL2Rcs("");
                                setL3Rcs("");
                                setL4Rcs("");
                                setL5Rcs("");
                                setNama("");
                                setKata("");
                            }}
                        > Back </button>

                        <p style={{margin: '20px 0px', fontSize: '24px', fontWeight: '500', textAlign: 'center'}}>{nama}</p>

                        <Row>

                            <Col xxl={3} xl={4} sm={6} className="element-item project designing development" >
                                <Card className="gallery-box">
                                    <div className='text-center'>
                                        <h3>L1</h3>
                                    </div>

                                    <div className="gallery-container" onClick={() => {handleModalImage(l1)}} style={{cursor: "pointer", display: 'flex'}}>
                                        <img className="gallery-img img-fluid mx-auto" style={{maxWidth: '300px', maxHeight: '220px'}} src={l1} alt="" />
                                        <div className="gallery-overlay">
                                            <h5 className="overlay-caption">Jari Jempol Kiri</h5>
                                        </div>
                                    </div>

                                    <div className="box-content">
                                        <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
                                            <img src={KiJempol} alt="" style={{width: '100px', border: '1px solid white', borderRadius: '10px'}}/>
                                        </div>

                                        <div className="d-flex align-items-center mt-1">
                                            <div className="flex-grow-1 text-muted"> 
                                            Checked by <Link to="#" className="text-body text-truncate">{checkLog === null ? "Hai Ayah" : checkLog}</Link>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <div className="d-flex gap-3">
                                                    <button type="button" className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0">
                                                        status: {l1Status}
                                                    </button>
                                                    <button type="button" className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0">
                                                        <i className="ri-question-answer-fill text-muted align-bottom me-1" onClick={()=> {handleModalCatatan(l1Catatan)}}></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                                                
                                        <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                                            <div>
                                                <select className="form-control" data-trigger value={l1Shape} onChange={(event) => {setL1Shape(event.target.value)}}>
                                                    <option value="0" selected="selected" hidden="hidden">
                                                        Choose here
                                                    </option>
                                                    {fingerType.map((type, index) => (
                                                        <option key={index} value={type.value}>{type.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div>
                                                    <Input type="text" className="form-control" placeholder='Input Redge Count'value={l1Shape === "A_simple" || l1Shape === "A_tented" || l1Shape === "accidental" ? jumlah.toFixed(1): l1Rcs} 
                                                    onChange={(event) => {setL1Rcs(event.target.value)}} 
                                                    disabled={l1Shape === "A_simple" || l1Shape === "A_tented" || l1Shape === "accidental" || l1Shape === "" || l1Shape === null ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                </Card>
                            </Col>

                            <Col xxl={3} xl={4} sm={6} className="element-item project designing development" >
                                <Card className="gallery-box">
                                    <div className='text-center'>
                                        <h3>L2</h3>
                                    </div>

                                    <div className="gallery-container" onClick={() => {handleModalImage(l2)}} style={{cursor: "pointer", display: 'flex'}}>
                                        <img className="gallery-img img-fluid mx-auto" style={{maxWidth: '300px', maxHeight: '220px'}} src={l2} alt="" />
                                        <div className="gallery-overlay">
                                            <h5 className="overlay-caption">Jari Telunjuk Kiri</h5>
                                        </div>
                                    </div>

                                    <div className="box-content">
                                        <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
                                            <img src={KiTelunjuk} alt="" style={{width: '100px', border: '1px solid white', borderRadius: '10px'}}/>
                                        </div>

                                        <div className="d-flex align-items-center mt-1">
                                            <div className="flex-grow-1 text-muted">Checked by <Link to="#" className="text-body text-truncate">{checkLog === null ? "Hai Ayah" : checkLog}</Link></div>
                                            <div className="flex-shrink-0">
                                                <div className="d-flex gap-3">
                                                    <button type="button" className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0">
                                                        status: {l2Status}
                                                    </button>
                                                    <button type="button" className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0">
                                                        <i className="ri-question-answer-fill text-muted align-bottom me-1" onClick={()=> {handleModalCatatan(l2Catatan)}}></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                                                
                                        <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                                            <div>
                                                <select className="form-control" data-trigger value={l2Shape} onChange={(event) => {setL2Shape(event.target.value)}} >
                                                    <option value="0" selected="selected" hidden="hidden">
                                                        Choose here
                                                    </option>
                                                    {fingerType.map((type, index) => (
                                                        <option key={index} value={type.value}>{type.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div>
                                                <Input type="text" className="form-control" placeholder='Input Redge Count'
                                                    value={l2Shape === "A_simple" || l2Shape === "A_tented" || l2Shape === "accidental" ? jumlah.toFixed(1): l2Rcs} 
                                                    onChange={(event) => {setL2Rcs(event.target.value)}} 
                                                    disabled={l2Shape === "A_simple" || l2Shape === "A_tented" || l2Shape === "accidental" || l2Shape === "" || l2Shape === null ? true : false}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </Card>
                            </Col>   

                            <Col xxl={3} xl={4} sm={6} className="element-item project designing development" >
                                <Card className="gallery-box">
                                    <div className='text-center'>
                                        <h3>L3</h3>
                                    </div>
                                    <div className="gallery-container" onClick={() => {handleModalImage(l3)}} style={{cursor: "pointer", display: 'flex'}}>
                                        <img className="gallery-img img-fluid mx-auto" style={{maxWidth: '300px', maxHeight: '220px'}} src={l3} alt="" />
                                        <div className="gallery-overlay">
                                            <h5 className="overlay-caption">Jari Tengah Kiri</h5>
                                        </div>
                                    </div>

                                    <div className="box-content">
                                        <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
                                            <img src={KiTengah} alt="" style={{width: '100px', border: '1px solid white', borderRadius: '10px'}}/>
                                        </div>

                                        <div className="d-flex align-items-center mt-1">
                                            <div className="flex-grow-1 text-muted">Checked by <Link to="#" className="text-body text-truncate">{checkLog === null ? "Hai Ayah" : checkLog}</Link></div>
                                            <div className="flex-shrink-0">
                                                <div className="d-flex gap-3">
                                                    <button type="button" className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0">
                                                        status: {l3Status}
                                                    </button>
                                                    <button type="button" className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0">
                                                        <i className="ri-question-answer-fill text-muted align-bottom me-1" onClick={()=> {handleModalCatatan(l3Catatan)}}></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                                                
                                        <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                                            <div>
                                                <select className="form-control" data-trigger value={l3Shape} onChange={(event) => {setL3Shape(event.target.value)}}>
                                                    <option value="0" selected="selected" hidden="hidden">
                                                        Choose here
                                                    </option>
                                                    {fingerType.map((type, index) => (
                                                        <option key={index} value={type.value}>{type.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div>
                                                <Input type="text" className="form-control" placeholder='Input Redge Count'
                                                value={l3Shape === "A_simple" || l3Shape === "A_tented" || l3Shape === "accidental" ? jumlah.toFixed(1): l3Rcs} 
                                                onChange={(event) => {setL3Rcs(event.target.value)}} 
                                                disabled={l3Shape === "A_simple" || l3Shape === "A_tented" || l3Shape === "accidental" || l3Shape === "" || l3Shape === null ? true : false}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </Card>                    
                            </Col> 

                            <Col xxl={3} xl={4} sm={6} className="element-item project designing development" >
                                <Card className="gallery-box">
                                    <div className='text-center'>
                                        <h3>L4</h3>
                                    </div>
                                    <div className="gallery-container" onClick={() => {handleModalImage(l4)}} style={{cursor: "pointer", display: 'flex'}}>
                                        <img className="gallery-img img-fluid mx-auto" style={{maxWidth: '300px', maxHeight: '220px'}} src={l4} alt="" />
                                        <div className="gallery-overlay">
                                            <h5 className="overlay-caption">Jari Manis Kiri</h5>
                                        </div>
                                    </div>

                                    <div className="box-content">
                                        <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
                                            <img src={KiManis} alt="" style={{width: '100px', border: '1px solid white', borderRadius: '10px'}}/>
                                        </div>

                                        <div className="d-flex align-items-center mt-1">
                                            <div className="flex-grow-1 text-muted">Checked by <Link to="#" className="text-body text-truncate">{checkLog === null ? "Hai Ayah" : checkLog}</Link></div>
                                            <div className="flex-shrink-0">
                                                <div className="d-flex gap-3">
                                                    <button type="button" className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0">
                                                        status: {l4Status}
                                                    </button>
                                                    <button type="button" className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0">
                                                        <i className="ri-question-answer-fill text-muted align-bottom me-1" onClick={()=> {handleModalCatatan(l4Catatan)}}></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                                                
                                        <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                                            <div>
                                                <select className="form-control" data-trigger value={l4Shape} onChange={(event) => {setL4Shape(event.target.value)}} >
                                                    <option value="0" selected="selected" hidden="hidden">
                                                        Choose here
                                                    </option>
                                                    {fingerType.map((type, index) => (
                                                        <option key={index} value={type.value}>{type.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                                            
                                            <div>
                                                <Input type="text" className="form-control" placeholder='Input Redge Count'
                                                    value={l4Shape === "A_simple" || l4Shape === "A_tented" || l4Shape === "accidental" ? jumlah.toFixed(1): l4Rcs} 
                                                    onChange={(event) => {setL4Rcs(event.target.value)}} 
                                                    disabled={l4Shape === "A_simple" || l4Shape === "A_tented" || l4Shape === "accidental" || l4Shape === "" || l4Shape === null ? true : false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Card>                    
                            </Col>

                                                    <Col xxl={3} xl={4} sm={6} className="element-item project designing development" >
                                                        <Card className="gallery-box" id="r5">
                                                            <div className='text-center'>
                                                                <h3>
                                                                    L5
                                                                </h3>
                                                            </div>
                                                            <div className="gallery-container" onClick={() => {handleModalImage(l5)}} style={{cursor: "pointer", display: 'flex'}}>
                                                                <img className="gallery-img img-fluid mx-auto" style={{maxWidth: '300px', maxHeight: '220px'}} src={l5} alt="" />
                                                                <div className="gallery-overlay">
                                                                <h5 className="overlay-caption">Jari Kelingking Kiri</h5>
                                                                </div>
                                                            </div>

                                                            <div className="box-content">
                                                                <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
                                                                    <img src={KiKelingking} alt="" style={{width: '100px', border: '1px solid white', borderRadius: '10px'}}/>
                                                                </div>

                                                                <div className="d-flex align-items-center mt-1">
                                                                    <div className="flex-grow-1 text-muted">Checked by <Link to="#" className="text-body text-truncate">{checkLog === null ? "Hai Ayah" : checkLog}</Link></div>
                                                                    <div className="flex-shrink-0">
                                                                        <div className="d-flex gap-3">
                                                                            <button type="button" className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0">
                                                                                status: {l5Status}
                                                                            </button>
                                                                            <button type="button" className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0">
                                                                                <i className="ri-question-answer-fill text-muted align-bottom me-1" onClick={()=> {handleModalCatatan(l5Catatan)}}></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                                                                    <div>
                                                                        <select className="form-control" data-trigger value={l5Shape} onChange={(event) => {setL5Shape(event.target.value)}} >
                                                                            <option value="0" selected="selected" hidden="hidden">
                                                                            Choose here
                                                                            </option>
                                                                            {fingerType.map((type, index) => (
                                                                                <option key={index} value={type.value}>{type.name}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                <div>
                                                                <Input type="text" className="form-control" placeholder='Input Redge Count'
                                                                value={l5Shape === "A_simple" || l5Shape === "A_tented" || l5Shape === "accidental" ? jumlah.toFixed(1): l5Rcs} 
                                                                onChange={(event) => {setL5Rcs(event.target.value)}} 
                                                                disabled={l5Shape === "A_simple" || l5Shape === "A_tented" || l5Shape === "accidental" || l5Shape === "" || l5Shape === null ? true : false}
                                                                />
                                                                </div>
                                                            </div>

                                                            </div>
                                                        </Card>                    
                                                    </Col> 

                            <hr/>

                                                    <Col xxl={3} xl={4} sm={6} className="element-item project designing development" >
                                                        <Card className="gallery-box">
                                                            <div className='text-center'>
                                                                <h3>
                                                                    R1
                                                                </h3>
                                                            </div>
                                                            <div className="gallery-container" onClick={() => {handleModalImage(r1)}} style={{cursor: "pointer", display: 'flex'}}>
                                                                <img className="gallery-img img-fluid mx-auto" style={{maxWidth: '300px', maxHeight: '220px'}} src={r1} alt="" />
                                                                <div className="gallery-overlay">
                                                                <h5 className="overlay-caption">Ibu Jari Kanan</h5>
                                                                </div>
                                                            </div>

                                                            <div className="box-content">
                                                                <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
                                                                    <img src={KaJempol} alt="" style={{width: '100px', border: '1px solid white', borderRadius: '10px'}}/>
                                                                </div>

                                                                <div className="d-flex align-items-center mt-1">
                                                                    <div className="flex-grow-1 text-muted">Checked by <Link to="#" className="text-body text-truncate">{checkLog === null ? "Hai Ayah" : checkLog}</Link></div>
                                                                    <div className="flex-shrink-0">
                                                                        <div className="d-flex gap-3">
                                                                            <button type="button" className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0">
                                                                                status: {r1Status}
                                                                            </button>
                                                                            <button type="button" className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0">
                                                                                <i className="ri-question-answer-fill text-muted align-bottom me-1" onClick={()=> {handleModalCatatan(r1Catatan)}}></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                                                                    <div>
                                                                        <select className="form-control" data-trigger value={r1Shape} onChange={(event) => {setR1Shape(event.target.value)}} >
                                                                            <option value="0" selected="selected" hidden="hidden">
                                                                            Choose here
                                                                            </option>
                                                                            {fingerType.map((type, index) => (
                                                                                <option key={index} value={type.value}>{type.name}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                <div>
                                                                <Input type="text" className="form-control" placeholder='Input Redge Count'
                                                                value={r1Shape === "A_simple" || r1Shape === "A_tented" || r1Shape === "accidental" ? jumlah.toFixed(1): r1Rcs} 
                                                                onChange={(event) => {setR1Rcs(event.target.value)}} 
                                                                disabled={r1Shape === "A_simple" || r1Shape === "A_tented" || r1Shape === "accidental" || r1Shape === "" || r1Shape === null ? true : false}
                                                                />
                                                                </div>
                                                            </div>

                                                            </div>
                                                        </Card>
                                                    </Col>

                                                    <Col xxl={3} xl={4} sm={6} className="element-item project designing development" >
                                                        <Card className="gallery-box">
                                                            <div className='text-center'>
                                                                <h3>
                                                                    R2
                                                                </h3>
                                                            </div>
                                                            <div className="gallery-container" onClick={() => {handleModalImage(r2)}} style={{cursor: "pointer", display: 'flex'}}>
                                                                <img className="gallery-img img-fluid mx-auto" style={{maxWidth: '300px', maxHeight: '220px'}} src={r2} alt="" />
                                                                <div className="gallery-overlay">
                                                                <h5 className="overlay-caption">Jari Telunjuk Kanan</h5>
                                                                </div>
                                                            </div>

                                                            <div className="box-content">
                                                                <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
                                                                    <img src={KaTelunjuk} alt="" style={{width: '100px', border: '1px solid white', borderRadius: '10px'}}/>
                                                                </div>

                                                                <div className="d-flex align-items-center mt-1">
                                                                    <div className="flex-grow-1 text-muted">Checked by <Link to="#" className="text-body text-truncate">{checkLog === null ? "Hai Ayah" : checkLog}</Link></div>
                                                                    <div className="flex-shrink-0">
                                                                        <div className="d-flex gap-3">
                                                                            <button type="button" className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0">
                                                                                status: {r2Status}
                                                                            </button>
                                                                            <button type="button" className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0">
                                                                                <i className="ri-question-answer-fill text-muted align-bottom me-1" onClick={()=> {handleModalCatatan(r2Catatan)}}></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                                                                    <div>
                                                                    <select className="form-control" data-trigger value={r2Shape} onChange={(event) => {setR2Shape(event.target.value)}} >
                                                                        <option value="0" selected="selected" hidden="hidden">
                                                                        Choose here
                                                                        </option>
                                                                        {fingerType.map((type, index) => (
                                                                            <option key={index} value={type.value}>{type.name}</option>
                                                                        ))}
                                                                    </select>
                                                                    </div>
                                                                <div>
                                                                <Input type="text" className="form-control" placeholder='Input Redge Count'
                                                                value={r2Shape === "A_simple" || r2Shape === "A_tented" || r2Shape === "accidental" ? jumlah.toFixed(1): r2Rcs} 
                                                                onChange={(event) => {setR2Rcs(event.target.value)}} 
                                                                disabled={r2Shape === "A_simple" || r2Shape === "A_tented" || r2Shape === "accidental" || r2Shape === "" || r2Shape === null ? true : false}
                                                                />
                                                                </div>
                                                            </div>

                                                            </div>
                                                        </Card>
                                                    </Col>   

                                                    <Col xxl={3} xl={4} sm={6} className="element-item project designing development" >
                                                        <Card className="gallery-box">
                                                            <div className='text-center'>
                                                                <h3>
                                                                    R3
                                                                </h3>
                                                            </div>
                                                            <div className="gallery-container" onClick={() => {handleModalImage(r3)}} style={{cursor: "pointer", display: 'flex'}}>
                                                                <img className="gallery-img img-fluid mx-auto" style={{maxWidth: '300px', maxHeight: '220px'}} src={r3} alt="" />
                                                                <div className="gallery-overlay">
                                                                <h5 className="overlay-caption">Jari Tengah Kanan</h5>
                                                                </div>
                                                            </div>

                                                            <div className="box-content">
                                                                <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
                                                                    <img src={KaTengah} alt="" style={{width: '100px', border: '1px solid white', borderRadius: '10px'}}/>
                                                                </div>

                                                                <div className="d-flex align-items-center mt-1">
                                                                    <div className="flex-grow-1 text-muted">Checked by <Link to="#" className="text-body text-truncate">{checkLog === null ? "Hai Ayah" : checkLog}</Link></div>
                                                                    <div className="flex-shrink-0">
                                                                        <div className="d-flex gap-3">
                                                                            <button type="button" className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0">
                                                                                status: {r3Status}
                                                                            </button>
                                                                            <button type="button" className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0">
                                                                                <i className="ri-question-answer-fill text-muted align-bottom me-1" onClick={()=> {handleModalCatatan(r3Catatan)}}></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                                                                    <div>
                                                                        <select className="form-control" data-trigger value={r3Shape} onChange={(event) => {setR3Shape(event.target.value)}} >
                                                                            <option value="0" selected="selected" hidden="hidden">
                                                                            Choose here
                                                                            </option>
                                                                            {fingerType.map((type, index) => (
                                                                                <option key={index} value={type.value}>{type.name}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                <div>
                                                                <Input type="text" className="form-control" placeholder='Input Redge Count'
                                                                value={r3Shape === "A_simple" || r3Shape === "A_tented" || r3Shape === "accidental" ? jumlah.toFixed(1): r3Rcs} 
                                                                onChange={(event) => {setR3Rcs(event.target.value)}} 
                                                                disabled={r3Shape === "A_simple" || r3Shape === "A_tented" || r3Shape === "accidental" || r3Shape === "" || r3Shape === null ? true : false}
                                                                />
                                                                </div>
                                                            </div>

                                                            </div>
                                                        </Card>                    
                                                    </Col> 

                                                    <Col xxl={3} xl={4} sm={6} className="element-item project designing development" >
                                                        <Card className="gallery-box" id="l4">
                                                            <div className='text-center'>
                                                                <h3>
                                                                    R4
                                                                </h3>
                                                            </div>
                                                            <div className="gallery-container" onClick={() => {handleModalImage(r4)}} style={{cursor: "pointer", display: 'flex'}}>
                                                                <img className="gallery-img img-fluid mx-auto" style={{maxWidth: '300px', maxHeight: '220px'}} src={r4} alt="" />
                                                                <div className="gallery-overlay">
                                                                <h5 className="overlay-caption">Jari Manis Kanan</h5>
                                                                </div>
                                                            </div>

                                                            <div className="box-content">
                                                                <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
                                                                    <img src={KaManis} alt="" style={{width: '100px', border: '1px solid white', borderRadius: '10px'}}/>
                                                                </div>

                                                                <div className="d-flex align-items-center mt-1">
                                                                    <div className="flex-grow-1 text-muted">Checked by <Link to="#" className="text-body text-truncate">{checkLog === null ? "Hai Ayah" : checkLog}</Link></div>
                                                                    <div className="flex-shrink-0">
                                                                        <div className="d-flex gap-3">
                                                                            <button type="button" className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0">
                                                                                status: {r4Status}
                                                                            </button>
                                                                            <button type="button" className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0">
                                                                                <i className="ri-question-answer-fill text-muted align-bottom me-1" onClick={()=> {handleModalCatatan(r4Catatan)}}></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                                                                    <div>
                                                                        <select className="form-control" data-trigger value={r4Shape} onChange={(event) => {setR4Shape(event.target.value)}} >
                                                                            <option value="0" selected="selected" hidden="hidden">
                                                                            Choose here
                                                                            </option>
                                                                            {fingerType.map((type, index) => (
                                                                                <option key={index} value={type.value}>{type.name}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                <div>
                                                                <Input type="text" className="form-control" placeholder='Input Redge Count'
                                                                value={r4Shape === "A_simple" || r4Shape === "A_tented" || r4Shape === "accidental" ? jumlah.toFixed(1): r4Rcs} 
                                                                onChange={(event) => {setR4Rcs(event.target.value)}} 
                                                                disabled={r4Shape === "A_simple" || r4Shape === "A_tented" || r4Shape === "accidental" || r4Shape === "" || r4Shape === null ? true : false}
                                                                />
                                                                </div>
                                                            </div>

                                                            </div>
                                                        </Card>                    
                                                    </Col>

                                                    <Col xxl={3} xl={4} sm={6} className="element-item project designing development" >
                                                        <Card className="gallery-box">
                                                            <div className='text-center'>
                                                                <h3>
                                                                    R5
                                                                </h3>
                                                            </div>
                                                            <div className="gallery-container" onClick={() => {handleModalImage(r5)}} style={{cursor: "pointer", display: 'flex'}}>
                                                                <img className="gallery-img img-fluid mx-auto" style={{maxWidth: '300px', maxHeight: '220px'}} src={r5} alt="" />
                                                                <div className="gallery-overlay">
                                                                <h5 className="overlay-caption">Jari Kelingking Kanan</h5>
                                                                </div>
                                                            </div>

                                                            <div className="box-content">
                                                                <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
                                                                    <img src={KaKelingking} alt="" style={{width: '100px', border: '1px solid white', borderRadius: '10px'}}/>
                                                                </div>

                                                                <div className="d-flex align-items-center mt-1">
                                                                    <div className="flex-grow-1 text-muted">Checked by <Link to="#" className="text-body text-truncate">{checkLog === null ? "Hai Ayah" : checkLog}</Link></div>
                                                                    <div className="flex-shrink-0">
                                                                        <div className="d-flex gap-3">
                                                                            <button type="button" className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0">
                                                                                status: {r5Status}
                                                                            </button>
                                                                            <button type="button" className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0">
                                                                                <i className="ri-question-answer-fill text-muted align-bottom me-1" onClick={()=> {handleModalCatatan(r5Catatan)}}></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                                                                    <div>
                                                                        <select className="form-control" data-trigger value={r5Shape} onChange={(event) => {setR5Shape(event.target.value)}} >
                                                                            <option value="0" selected="selected" hidden="hidden">
                                                                            Choose here
                                                                            </option>
                                                                            {fingerType.map((type, index) => (
                                                                                <option key={index} value={type.value}>{type.name}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                <div>
                                                                <Input type="text" className="form-control" placeholder='Input Redge Count'
                                                                value={r5Shape === "A_simple" || r5Shape === "A_tented" || r5Shape === "accidental" ? jumlah.toFixed(1): r5Rcs} 
                                                                onChange={(event) => {setR5Rcs(event.target.value)}} 
                                                                disabled={r5Shape === "A_simple" || r5Shape === "A_tented" || r5Shape === "accidental" || r5Shape === "" || r5Shape === null ? true : false}
                                                                />
                                                                </div>
                                                            </div>

                                                            </div>
                                                        </Card>                    
                                                    </Col>

                        </Row>

                        <div className="text-center my-2">
                            <Button color="dark" className="rounded-pill" onClick={() => {updateFingerprint()}} > Submit </Button>
                        </div>
                        </>
                    )}
                    
                </Col>
            </Row>

            </div>

            <Modal isOpen={modalAccessDenied} toggle={() => {}} centered>
                <ModalBody className='modalCenter'> 
                    <img src={accessDenied} style={{width: '30%'}}/>
                    <p style={{fontSize: '24px', fontWeight: '700', marginTop: '20px'}}>Access Denied</p>
                    <p style={{margin: '0px', color: 'grey'}}>You currently does not have access to this page. <br/> Please Login Again</p>
                </ModalBody>
                <ModalFooter style={{display: 'flex', justifyContent: 'center', gap: '80px'}}>
                    <button className='btn buttonVersFutureLink' style={{fontSize: '14px', fontWeight: '500'}} onClick={() => {logOut()}}>Login Again</button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalNoData} toggle={() => {setModalNoData(!modalNoData)}} centered>
                <ModalBody className='modalCenter'> 
                    <img src={accessDenied} style={{width: '30%'}}/>
                    <p style={{fontSize: '24px', fontWeight: '700', marginTop: '20px'}}>Nothing Data</p>
                </ModalBody>
            </Modal>


            <Modal isOpen={modalAlertSuccess} toggle={() => {setModalAlertSuccess(!modalAlertSuccess)}} centered>
                <ModalBody className='modalCenter' style={{margin: '0px'}}>
                    <Lottie options={{loop: true, autoplay: true,animationData: animateSuccess,rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} height={350} width={350} isClickToPauseDisabled={true} />
                </ModalBody>
            </Modal>

            <Modal isOpen={modalAlertAnnotator} toggle={() => {setModalAlertSuccess(!modalAlertAnnotator)}} centered>
                <ModalBody className='modalCenter' style={{margin: '0px'}}>
                    <h1>Sorry, can no longer perform updates</h1>
                    <h5>If you wish to perform an update, please contact the admin immediately.</h5>
                </ModalBody>
            </Modal>

            <Modal className='modal-xl' isOpen={modalImage} toggle={() => {handleModalImage()}} centered >
                <ModalHeader toggle={() => {handleModalImage();}}>

                </ModalHeader>
                <ModalBody className="text-center">
                    <img className="overlay" src={imageFingerprint} style={{width: '100%'}} />
                </ModalBody>
            </Modal>

            <Modal className='modal-md' isOpen={modalCatatan} toggle={() => {setModalCatatan(!modalCatatan)}} centered>
                <ModalHeader toggle={() => {setModalCatatan(!modalCatatan)}}>
                    Catatan dari admin
                </ModalHeader>
                <ModalBody className="text-center">
                    <div className="mb-1">
                        <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="4"
                        value={noted}
                        disabled={true}
                        ></textarea>
                    </div>
                </ModalBody>
            </Modal>

            </Container>
        </React.Fragment>
    );
};

export default TableUserDefault;
import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, Alert} from 'reactstrap';
import { isExpired, decodeToken } from "react-jwt";
import Flatpickr from "react-flatpickr";
import axios from 'axios';
import accessDenied from "../../../assets/images/FutureLink/accessDenied.png";

const CounselorPlayground = () => {
    const [modalAccessDenied, setModalAccessDenied] = useState(false);
    const logOut = () => {
      sessionStorage.removeItem("user");
      window.location.href = "/login";
    };

    const code = sessionStorage.getItem('user');
    const breakCode = decodeToken(code);

    const [fade, setFade] = useState('fadeout');
    const [alert, setAlert] = useState(true);
    const [kontenError, setKontenError] = useState("Check Again");

    
    const [langkah, setLangkah] = useState(0);
    const [modalConfigCounselor, setModalConfigCounselor] = useState(false);
    const [cuti, setCuti] = useState([]);

    const [hariSeninPagi, setHariSeninPagi] = useState([]);
    const [hariSeninSiang, setHariSeninSiang] = useState([]);
    const [hariSeninSore, setHariSeninSore] = useState([]);
    const [hariSeninMalam, setHariSeninMalam] = useState([]);

    const [hariSelasaPagi, setHariSelasaPagi] = useState([]);
    const [hariSelasaSiang, setHariSelasaSiang] = useState([]);
    const [hariSelasaSore, setHariSelasaSore] = useState([]);
    const [hariSelasaMalam, setHariSelasaMalam] = useState([]);

    const [hariRabuPagi, setHariRabuPagi] = useState([]);
    const [hariRabuSiang, setHariRabuSiang] = useState([]);
    const [hariRabuSore, setHariRabuSore] = useState([]);
    const [hariRabuMalam, setHariRabuMalam] = useState([]);

    const [hariKamisPagi, setHariKamisPagi] = useState([]);
    const [hariKamisSiang, setHariKamisSiang] = useState([]);
    const [hariKamisSore, setHariKamisSore] = useState([]);
    const [hariKamisMalam, setHariKamisMalam] = useState([]);

    const [hariJumatPagi, setHariJumatPagi] = useState([]);
    const [hariJumatSiang, setHariJumatSiang] = useState([]);
    const [hariJumatSore, setHariJumatSore] = useState([]);
    const [hariJumatMalam, setHariJumatMalam] = useState([]);

    const [hariSabtuPagi, setHariSabtuPagi] = useState([]);
    const [hariSabtuSiang, setHariSabtuSiang] = useState([]);
    const [hariSabtuSore, setHariSabtuSore] = useState([]);
    const [hariSabtuMalam, setHariSabtuMalam] = useState([]);

    const [hariMingguPagi, setHariMingguPagi] = useState([]);
    const [hariMingguSiang, setHariMingguSiang] = useState([]);
    const [hariMingguSore, setHariMingguSore] = useState([]);
    const [hariMingguMalam, setHariMingguMalam] = useState([]);

    const [a1, setA1] = useState(false);
    const [b1, setB1] = useState(false);
    const [c1, setC1] = useState(false);
    const [d1, setD1] = useState(false);
    const [e1, setE1] = useState(false);
    const [f1, setF1] = useState(false);
    const [g1, setG1] = useState(false);
    const [h1, setH1] = useState(false);
    const [i1, setI1] = useState(false);

    const [a2, setA2] = useState(false);
    const [b2, setB2] = useState(false);
    const [c2, setC2] = useState(false);
    const [d2, setD2] = useState(false);
    const [e2, setE2] = useState(false);
    const [f2, setF2] = useState(false);
    const [g2, setG2] = useState(false);
    const [h2, setH2] = useState(false);
    const [i2, setI2] = useState(false);

    const [a3, setA3] = useState(false);
    const [b3, setB3] = useState(false);
    const [c3, setC3] = useState(false);
    const [d3, setD3] = useState(false);
    const [e3, setE3] = useState(false);
    const [f3, setF3] = useState(false);
    const [g3, setG3] = useState(false);
    const [h3, setH3] = useState(false);
    const [i3, setI3] = useState(false);

    const [a4, setA4] = useState(false);
    const [b4, setB4] = useState(false);
    const [c4, setC4] = useState(false);
    const [d4, setD4] = useState(false);
    const [e4, setE4] = useState(false);
    const [f4, setF4] = useState(false);
    const [g4, setG4] = useState(false);
    const [h4, setH4] = useState(false);
    const [i4, setI4] = useState(false);

    const [a5, setA5] = useState(false);
    const [b5, setB5] = useState(false);
    const [c5, setC5] = useState(false);
    const [d5, setD5] = useState(false);
    const [e5, setE5] = useState(false);
    const [f5, setF5] = useState(false);
    const [g5, setG5] = useState(false);
    const [h5, setH5] = useState(false);
    const [i5, setI5] = useState(false);

    const [a6, setA6] = useState(false);
    const [b6, setB6] = useState(false);
    const [c6, setC6] = useState(false);
    const [d6, setD6] = useState(false);
    const [e6, setE6] = useState(false);
    const [f6, setF6] = useState(false);
    const [g6, setG6] = useState(false);
    const [h6, setH6] = useState(false);
    const [i6, setI6] = useState(false);

    const [a7, setA7] = useState(false);
    const [b7, setB7] = useState(false);
    const [c7, setC7] = useState(false);
    const [d7, setD7] = useState(false);
    const [e7, setE7] = useState(false);
    const [f7, setF7] = useState(false);
    const [g7, setG7] = useState(false);
    const [h7, setH7] = useState(false);
    const [i7, setI7] = useState(false);

    useEffect(() => {
        getCounselor()
    },[])

    const getCounselor = () => {

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-counselor-schedule?counselorId=${breakCode.counselorId}`,
            headers: { 
                'Authorization': `Bearer ${code}`, 
            }
        };
          
        axios.request(config)
            .then((response) => {
            // console.log(response);
            setCuti(response.results.leaves);

            setHariSeninPagi(response.results.schedules.monday.morning);
            setHariSeninSiang(response.results.schedules.monday.afternoon);
            setHariSeninSore(response.results.schedules.monday.evening);
            setHariSeninMalam(response.results.schedules.monday.night);

            setHariSelasaPagi(response.results.schedules.tuesday.morning);
            setHariSelasaSiang(response.results.schedules.tuesday.afternoon);
            setHariSelasaSore(response.results.schedules.tuesday.evening);
            setHariSelasaMalam(response.results.schedules.tuesday.night);

            setHariRabuPagi(response.results.schedules.wednesday.morning);
            setHariRabuSiang(response.results.schedules.wednesday.afternoon);
            setHariRabuSore(response.results.schedules.wednesday.evening);
            setHariRabuMalam(response.results.schedules.wednesday.night);

            setHariKamisPagi(response.results.schedules.thursday.morning);
            setHariKamisSiang(response.results.schedules.thursday.afternoon);
            setHariKamisSore(response.results.schedules.thursday.evening);
            setHariKamisMalam(response.results.schedules.thursday.night);

            setHariJumatPagi(response.results.schedules.friday.morning);
            setHariJumatSiang(response.results.schedules.friday.afternoon);
            setHariJumatSore(response.results.schedules.friday.evening);
            setHariJumatMalam(response.results.schedules.friday.night);

            setHariSabtuPagi(response.results.schedules.saturday.morning);
            setHariSabtuSiang(response.results.schedules.saturday.afternoon);
            setHariSabtuSore(response.results.schedules.saturday.evening);
            setHariSabtuMalam(response.results.schedules.saturday.night);

            setHariMingguPagi(response.results.schedules.sunday.morning);
            setHariMingguSiang(response.results.schedules.sunday.afternoon);
            setHariMingguSore(response.results.schedules.sunday.evening);
            setHariMingguMalam(response.results.schedules.sunday.night);

            setA1(response.results.schedules.monday.morning.includes(1))
            setB1(response.results.schedules.monday.morning.includes(2))
            setC1(response.results.schedules.monday.morning.includes(3))
            setD1(response.results.schedules.monday.afternoon.includes(1))
            setE1(response.results.schedules.monday.afternoon.includes(2))
            setF1(response.results.schedules.monday.evening.includes(1))
            setG1(response.results.schedules.monday.evening.includes(2))
            setH1(response.results.schedules.monday.night.includes(1))
            setI1(response.results.schedules.monday.night.includes(2))

            setA2(response.results.schedules.tuesday.morning.includes(1))
            setB2(response.results.schedules.tuesday.morning.includes(2))
            setC2(response.results.schedules.tuesday.morning.includes(3))
            setD2(response.results.schedules.tuesday.afternoon.includes(1))
            setE2(response.results.schedules.tuesday.afternoon.includes(2))
            setF2(response.results.schedules.tuesday.evening.includes(1))
            setG2(response.results.schedules.tuesday.evening.includes(2))
            setH2(response.results.schedules.tuesday.night.includes(1))
            setI2(response.results.schedules.tuesday.night.includes(2))

            setA3(response.results.schedules.wednesday.morning.includes(1))
            setB3(response.results.schedules.wednesday.morning.includes(2))
            setC3(response.results.schedules.wednesday.morning.includes(3))
            setD3(response.results.schedules.wednesday.afternoon.includes(1))
            setE3(response.results.schedules.wednesday.afternoon.includes(2))
            setF3(response.results.schedules.wednesday.evening.includes(1))
            setG3(response.results.schedules.wednesday.evening.includes(2))
            setH3(response.results.schedules.wednesday.night.includes(1))
            setI3(response.results.schedules.wednesday.night.includes(2))

            setA4(response.results.schedules.thursday.morning.includes(1))
            setB4(response.results.schedules.thursday.morning.includes(2))
            setC4(response.results.schedules.thursday.morning.includes(3))
            setD4(response.results.schedules.thursday.afternoon.includes(1))
            setE4(response.results.schedules.thursday.afternoon.includes(2))
            setF4(response.results.schedules.thursday.evening.includes(1))
            setG4(response.results.schedules.thursday.evening.includes(2))
            setH4(response.results.schedules.thursday.night.includes(1))
            setI4(response.results.schedules.thursday.night.includes(2))

            setA5(response.results.schedules.friday.morning.includes(1))
            setB5(response.results.schedules.friday.morning.includes(2))
            setC5(response.results.schedules.friday.morning.includes(3))
            setD5(response.results.schedules.friday.afternoon.includes(1))
            setE5(response.results.schedules.friday.afternoon.includes(2))
            setF5(response.results.schedules.friday.evening.includes(1))
            setG5(response.results.schedules.friday.evening.includes(2))
            setH5(response.results.schedules.friday.night.includes(1))
            setI5(response.results.schedules.friday.night.includes(2))

            setA6(response.results.schedules.saturday.morning.includes(1))
            setB6(response.results.schedules.saturday.morning.includes(2))
            setC6(response.results.schedules.saturday.morning.includes(3))
            setD6(response.results.schedules.saturday.afternoon.includes(1))
            setE6(response.results.schedules.saturday.afternoon.includes(2))
            setF6(response.results.schedules.saturday.evening.includes(1))
            setG6(response.results.schedules.saturday.evening.includes(2))
            setH6(response.results.schedules.saturday.night.includes(1))
            setI6(response.results.schedules.saturday.night.includes(2))

            setA7(response.results.schedules.sunday.morning.includes(1))
            setB7(response.results.schedules.sunday.morning.includes(2))
            setC7(response.results.schedules.sunday.morning.includes(3))
            setD7(response.results.schedules.sunday.afternoon.includes(1))
            setE7(response.results.schedules.sunday.afternoon.includes(2))
            setF7(response.results.schedules.sunday.evening.includes(1))
            setG7(response.results.schedules.sunday.evening.includes(2))
            setH7(response.results.schedules.sunday.night.includes(1))
            setI7(response.results.schedules.sunday.night.includes(2))
            })
            .catch((error) => {
            // console.log(error.response);
            if(error.response.data.statusCode === 403){
                setModalAccessDenied(true)
            }
            });

    }

    const updateSetting = () => {
        const hai = {
            "monday":{"morning":[a1 === true ? 1 : "", b1 === true ? 2 : "", c1 === true ? 3 : ""].filter((item) => item !== ""), "afternoon":[d1 === true ? 1 : "", e1 === true ? 2 : ""].filter((item) => item !== ""), "evening":[f1 === true ? 1 : "", g1 === true ? 2 : ""].filter((item) => item !== ""), "night":[h1 === true ? 1 : "", i1 === true ? 2 : ""].filter((item) => item !== "")},
            "tuesday":{"morning":[a2 === true ? 1 : "", b2 === true ? 2 : "", c2 === true ? 3 : ""].filter((item) => item !== ""), "afternoon":[d2 === true ? 1 : "", e2 === true ? 2 : ""].filter((item) => item !== ""), "evening":[f2 === true ? 1 : "", g2 === true ? 2 : ""].filter((item) => item !== ""), "night":[h2 === true ? 1 : "", i2 === true ? 2 : ""].filter((item) => item !== "")},
            "wednesday":{"morning":[a3 === true ? 1 : "", b3 === true ? 2 : "", c3 === true ? 3 : ""].filter((item) => item !== ""), "afternoon":[d3 === true ? 1 : "", e3 === true ? 2 : ""].filter((item) => item !== ""), "evening":[f3 === true ? 1 : "", g3 === true ? 2 : ""].filter((item) => item !== ""), "night":[h3 === true ? 1 : "", i3 === true ? 2 : ""].filter((item) => item !== "")},
            "thursday":{"morning":[a4 === true ? 1 : "", b4 === true ? 2 : "", c4 === true ? 3 : ""].filter((item) => item !== ""), "afternoon":[d4 === true ? 1 : "", e4 === true ? 2 : ""].filter((item) => item !== ""), "evening":[f4 === true ? 1 : "", g4 === true ? 2 : ""].filter((item) => item !== ""), "night":[h4 === true ? 1 : "", i4 === true ? 2 : ""].filter((item) => item !== "")},
            "friday":{"morning":[a5 === true ? 1 : "", b5 === true ? 2 : "", c5 === true ? 3 : ""].filter((item) => item !== ""), "afternoon":[d5 === true ? 1 : "", e5 === true ? 2 : ""].filter((item) => item !== ""), "evening":[f5 === true ? 1 : "", g5 === true ? 2 : ""].filter((item) => item !== ""), "night":[h5 === true ? 1 : "", i5 === true ? 2 : ""].filter((item) => item !== "")},
            "saturday":{"morning":[a6 === true ? 1 : "", b6 === true ? 2 : "", c6 === true ? 3 : ""].filter((item) => item !== ""), "afternoon":[d6 === true ? 1 : "", e6 === true ? 2 : ""].filter((item) => item !== ""), "evening":[f6 === true ? 1 : "", g6 === true ? 2 : ""].filter((item) => item !== ""), "night":[h6 === true ? 1 : "", i6 === true ? 2 : ""].filter((item) => item !== "")},
            "sunday":{"morning":[a7 === true ? 1 : "", b7 === true ? 2 : "", c7 === true ? 3 : ""].filter((item) => item !== ""), "afternoon":[d7 === true ? 1 : "", e7 === true ? 2 : ""].filter((item) => item !== ""), "evening":[f7 === true ? 1 : "", g7 === true ? 2 : ""].filter((item) => item !== ""), "night":[h7 === true ? 1 : "", i7 === true ? 2 : ""].filter((item) => item !== "")},
        }

        let data = new FormData();
        data.append('counselorId', breakCode.counselorId);
        data.append('schedules', JSON.stringify(hai));
        data.append('leaves', cuti.length === 0 ? "[]" : typeof cuti === "string" ? JSON.stringify(cuti.split(", ")) : JSON.stringify(cuti));

        let config = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/update-counselor-schedule`,
            headers: { 
                'Authorization': `Bearer ${code}`, 
            },
            data : data
        };

        axios.request(config)
        .then((response) => {
            // console.log(response);
            setModalConfigCounselor(false);
            getCounselor();
        })
        .catch((error) => {
            // console.log(error.response);
            if(error.response.data.statusCode === 422){
                setAlert(false);
                setKontenError("Sorry, cant update leave on existing meeting schedule with user")
      
                setTimeout(() => {
                    setFade('fade')
                }, 200)
        
                setTimeout(() => {
                    setFade('fadeout');
                }, 4500)
        
                setTimeout(() => {
                    setAlert(true);
                    setKontenError("Check Again")
                }, 5000);
            }
        });
    }

    const tombolKhusus = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-counselor-schedule?counselorId=${breakCode.counselorId}`,
            headers: { 
                'Authorization': `Bearer ${code}`, 
            }
        };
          
        axios.request(config)
            .then((response) => {
            // console.log(response);
            setCuti(response.results.leaves);
            setModalConfigCounselor(true); 
            setLangkah(0);

            setHariSeninPagi(response.results.schedules.monday.morning);
            setHariSeninSiang(response.results.schedules.monday.afternoon);
            setHariSeninSore(response.results.schedules.monday.evening);
            setHariSeninMalam(response.results.schedules.monday.night);

            setHariSelasaPagi(response.results.schedules.tuesday.morning);
            setHariSelasaSiang(response.results.schedules.tuesday.afternoon);
            setHariSelasaSore(response.results.schedules.tuesday.evening);
            setHariSelasaMalam(response.results.schedules.tuesday.night);

            setHariRabuPagi(response.results.schedules.wednesday.morning);
            setHariRabuSiang(response.results.schedules.wednesday.afternoon);
            setHariRabuSore(response.results.schedules.wednesday.evening);
            setHariRabuMalam(response.results.schedules.wednesday.night);

            setHariKamisPagi(response.results.schedules.thursday.morning);
            setHariKamisSiang(response.results.schedules.thursday.afternoon);
            setHariKamisSore(response.results.schedules.thursday.evening);
            setHariKamisMalam(response.results.schedules.thursday.night);

            setHariJumatPagi(response.results.schedules.friday.morning);
            setHariJumatSiang(response.results.schedules.friday.afternoon);
            setHariJumatSore(response.results.schedules.friday.evening);
            setHariJumatMalam(response.results.schedules.friday.night);

            setHariSabtuPagi(response.results.schedules.saturday.morning);
            setHariSabtuSiang(response.results.schedules.saturday.afternoon);
            setHariSabtuSore(response.results.schedules.saturday.evening);
            setHariSabtuMalam(response.results.schedules.saturday.night);

            setHariMingguPagi(response.results.schedules.sunday.morning);
            setHariMingguSiang(response.results.schedules.sunday.afternoon);
            setHariMingguSore(response.results.schedules.sunday.evening);
            setHariMingguMalam(response.results.schedules.sunday.night);

            setA1(response.results.schedules.monday.morning.includes(1))
            setB1(response.results.schedules.monday.morning.includes(2))
            setC1(response.results.schedules.monday.morning.includes(3))
            setD1(response.results.schedules.monday.afternoon.includes(1))
            setE1(response.results.schedules.monday.afternoon.includes(2))
            setF1(response.results.schedules.monday.evening.includes(1))
            setG1(response.results.schedules.monday.evening.includes(2))
            setH1(response.results.schedules.monday.night.includes(1))
            setI1(response.results.schedules.monday.night.includes(2))

            setA2(response.results.schedules.tuesday.morning.includes(1))
            setB2(response.results.schedules.tuesday.morning.includes(2))
            setC2(response.results.schedules.tuesday.morning.includes(3))
            setD2(response.results.schedules.tuesday.afternoon.includes(1))
            setE2(response.results.schedules.tuesday.afternoon.includes(2))
            setF2(response.results.schedules.tuesday.evening.includes(1))
            setG2(response.results.schedules.tuesday.evening.includes(2))
            setH2(response.results.schedules.tuesday.night.includes(1))
            setI2(response.results.schedules.tuesday.night.includes(2))

            setA3(response.results.schedules.wednesday.morning.includes(1))
            setB3(response.results.schedules.wednesday.morning.includes(2))
            setC3(response.results.schedules.wednesday.morning.includes(3))
            setD3(response.results.schedules.wednesday.afternoon.includes(1))
            setE3(response.results.schedules.wednesday.afternoon.includes(2))
            setF3(response.results.schedules.wednesday.evening.includes(1))
            setG3(response.results.schedules.wednesday.evening.includes(2))
            setH3(response.results.schedules.wednesday.night.includes(1))
            setI3(response.results.schedules.wednesday.night.includes(2))

            setA4(response.results.schedules.thursday.morning.includes(1))
            setB4(response.results.schedules.thursday.morning.includes(2))
            setC4(response.results.schedules.thursday.morning.includes(3))
            setD4(response.results.schedules.thursday.afternoon.includes(1))
            setE4(response.results.schedules.thursday.afternoon.includes(2))
            setF4(response.results.schedules.thursday.evening.includes(1))
            setG4(response.results.schedules.thursday.evening.includes(2))
            setH4(response.results.schedules.thursday.night.includes(1))
            setI4(response.results.schedules.thursday.night.includes(2))

            setA5(response.results.schedules.friday.morning.includes(1))
            setB5(response.results.schedules.friday.morning.includes(2))
            setC5(response.results.schedules.friday.morning.includes(3))
            setD5(response.results.schedules.friday.afternoon.includes(1))
            setE5(response.results.schedules.friday.afternoon.includes(2))
            setF5(response.results.schedules.friday.evening.includes(1))
            setG5(response.results.schedules.friday.evening.includes(2))
            setH5(response.results.schedules.friday.night.includes(1))
            setI5(response.results.schedules.friday.night.includes(2))

            setA6(response.results.schedules.saturday.morning.includes(1))
            setB6(response.results.schedules.saturday.morning.includes(2))
            setC6(response.results.schedules.saturday.morning.includes(3))
            setD6(response.results.schedules.saturday.afternoon.includes(1))
            setE6(response.results.schedules.saturday.afternoon.includes(2))
            setF6(response.results.schedules.saturday.evening.includes(1))
            setG6(response.results.schedules.saturday.evening.includes(2))
            setH6(response.results.schedules.saturday.night.includes(1))
            setI6(response.results.schedules.saturday.night.includes(2))

            setA7(response.results.schedules.sunday.morning.includes(1))
            setB7(response.results.schedules.sunday.morning.includes(2))
            setC7(response.results.schedules.sunday.morning.includes(3))
            setD7(response.results.schedules.sunday.afternoon.includes(1))
            setE7(response.results.schedules.sunday.afternoon.includes(2))
            setF7(response.results.schedules.sunday.evening.includes(1))
            setG7(response.results.schedules.sunday.evening.includes(2))
            setH7(response.results.schedules.sunday.night.includes(1))
            setI7(response.results.schedules.sunday.night.includes(2))
            })
            .catch((error) => {
            // console.log(error.response);
            if(error.response.data.statusCode === 403){
                setModalAccessDenied(true)
            }
            });

    }


    return ( 
        <React.Fragment>
                <div className="page-content">
                    <div>
                        <div className='mainConfigCounselor'>
                    <div>
                        <div className='headerConfigCounselor'>
                            <p className='headerConfigCounselorText'>Setting</p>
                            <button className='btn buttonVersFutureLinkGreen' 
                            onClick={(event) => {
                                tombolKhusus();
                            }}>Edit</button>
                        </div>
                        <div className='mainConfigCounselor'>
                            
                            {/* Bisa mensetting Jadwal Avaliable Tanggal dan jam,  
                            Ada List Jadwal Konsultasi dengan Client 
                            Tanggal: tanggalMeeting
                            Waktu: waktuMeeting
                            Client: namaClient
                            linkMeeting: www.com
                            */}
                            <p className='hariHari'>Monday</p>
                            <div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Morning</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                        
                                    {hariSeninPagi && hariSeninPagi.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "09.00" : item === 2 ? "10.00" : "11.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Afternoon</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                    {hariSeninSiang && hariSeninSiang.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "13.00" : "14.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Evening</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                        
                                    {hariSeninSore && hariSeninSore.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "15.00" : "16.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Night</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                    {hariSeninMalam && hariSeninMalam.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "19.00" : "20.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                            </div>

                            <p className='hariHari'>Tuesday</p>
                            <div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Morning</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                        
                                    {hariSelasaPagi && hariSelasaPagi.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "09.00" : item === 2 ? "10.00" : "11.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Afternoon</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                    {hariSelasaSiang && hariSelasaSiang.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "13.00" : "14.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Evening</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                        
                                    {hariSelasaSore && hariSelasaSore.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "15.00" : "16.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Night</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                    {hariSelasaMalam && hariSelasaMalam.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "19.00" : "20.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                            </div>

                            <p className='hariHari'>Wednesday</p>
                            <div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Morning</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                        
                                    {hariRabuPagi && hariRabuPagi.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "09.00" : item === 2 ? "10.00" : "11.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Afternoon</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                    {hariRabuSiang && hariRabuSiang.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "13.00" : "14.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Evening</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                        
                                    {hariRabuSore && hariRabuSore.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "15.00" : "16.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Night</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                    {hariRabuMalam && hariRabuMalam.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "19.00" : "20.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                            </div>

                            <p className='hariHari'>Thursday</p>
                            <div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Morning</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                        
                                    {hariKamisPagi && hariKamisPagi.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "09.00" : item === 2 ? "10.00" : "11.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Afternoon</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                    {hariKamisSiang && hariKamisSiang.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "13.00" : "14.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Evening</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                        
                                    {hariKamisSore && hariKamisSore.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "15.00" : "16.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Night</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                    {hariKamisMalam && hariKamisMalam.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "19.00" : "20.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                            </div>

                            <p className='hariHari'>Friday</p>
                            <div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Morning</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                        
                                    {hariJumatPagi && hariJumatPagi.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "09.00" : item === 2 ? "10.00" : "11.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Afternoon</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                    {hariJumatSiang && hariJumatSiang.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "13.00" : "14.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Evening</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                        
                                    {hariJumatSore && hariJumatSore.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "15.00" : "16.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Night</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                    {hariJumatMalam && hariJumatMalam.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "19.00" : "20.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                            </div>

                            <p className='hariHari'>Sabtu</p>
                            <div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Morning</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                        
                                    {hariSabtuPagi && hariSabtuPagi.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "09.00" : item === 2 ? "10.00" : "11.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Afternoon</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                    {hariSabtuSiang && hariSabtuSiang.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "13.00" : "14.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Evening</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                        
                                    {hariSabtuSore && hariSabtuSore.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "15.00" : "16.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Night</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                    {hariSabtuMalam && hariSabtuMalam.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "19.00" : "20.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                            </div>

                            <p className='hariHari'>Sunday</p>
                            <div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Morning</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                        
                                    {hariMingguPagi && hariMingguPagi.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "09.00" : item === 2 ? "10.00" : "11.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Afternoon</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                    {hariMingguSiang && hariMingguSiang.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "13.00" : "14.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Evening</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                        
                                    {hariMingguSore && hariMingguSore.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "15.00" : "16.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                                <div className='mainList'>
                                    <p style={{width: '75px'}}>Night</p>
                                    <p style={{width: '10px'}}>:</p>
                                    <div className='mainWaktu'>
                                    {hariMingguMalam && hariMingguMalam.map((item, index) => (
                                        <>
                                            <p key={index}>{item === 1 ? "19.00" : "20.00"}</p>
                                        </>
                                    ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                        </div>
                    </div>

                <Modal isOpen={modalConfigCounselor} toggle={() => {setModalConfigCounselor(!modalConfigCounselor)}} centered>
                    <ModalBody > 
                        {langkah === 0 && (
                            <>
                                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '30px', gap: '10px', flexWrap: 'wrap'}}>

                                    <button className='btn buttonVersFutureLink' onClick={() => {setLangkah("Senin")}}>Monday</button>

                                    <button className='btn buttonVersFutureLink' onClick={() => {setLangkah("Selasa")}}>Tuesday</button>

                                    <button className='btn buttonVersFutureLink' onClick={() => {setLangkah("Rabu")}}>Wednesday</button>

                                    <button className='btn buttonVersFutureLink' onClick={() => {setLangkah("Kamis")}}>Thursday</button>

                                    <button className='btn buttonVersFutureLink' onClick={() => {setLangkah("Jumat")}}>Friday</button>

                                    <button className='btn buttonVersFutureLink' onClick={() => {setLangkah("Sabtu")}}>Saturday</button>

                                    <button className='btn buttonVersFutureLink' onClick={() => {setLangkah("Minggu")}}>Sunday</button>

                                </div>

                                <div className="mb-3">
                                    <Alert className={'alertRed ' + fade} hidden={alert}>{kontenError}</Alert>
                                    <label htmlFor="date-field" className="form-label" style={{fontSize: '14px'}}>Choose Leave</label>
                                    <Flatpickr
                                        className="form-control"
                                        options={{
                                            dateFormat: "Y-m-d",
                                            mode: "multiple",
                                            defaultDate: cuti,
                                        }}
                                        onChange={(selectedDates, dateStr, instance) => {
                                            setCuti(dateStr); 
                                            // console.log(dateStr);
                                        }}
                                        placeholder="Select Date"
                                    />
                                </div>
                            </>
                        )}
                        {langkah === "Senin" && (
                            <>
                            <button className='btn buttonVersFutureLinkBorderGrey' onClick={() => {setLangkah(0)}}>Back</button>

                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Morning:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={a1 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setA1(!a1)}}>09.00 - 10.00</button>
                                <button className={b1 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setB1(!b1)}}>10.00 - 11.00</button>
                                <button className={c1 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setC1(!c1)}}>11.00 - 12.00</button>
                            </div>
                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Afternoon:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={d1 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setD1(!d1)}}>13.00 - 14.00</button>
                                <button className={e1 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setE1(!e1)}}>14.00 - 15.00</button>
                            </div>
                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Evening:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={f1 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setF1(!f1)}}>15.00 - 16.00</button>
                                <button className={g1 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setG1(!g1)}}>16.00 - 17.00</button>
                            </div>
                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Night:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={h1 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setH1(!h1)}}>19.00 - 20.00</button>
                                <button className={i1 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setI1(!i1)}}>20.00 - 21.00</button>
                            </div>

                            </>
                        )}
                        {langkah === "Selasa" && (
                            <>
                            <button className='btn buttonVersFutureLinkBorderGrey' onClick={() => {setLangkah(0)}}>Back</button>

                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Morning:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={a2 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setA2(!a2)}}>09.00 - 10.00</button>
                                <button className={b2 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setB2(!b2)}}>10.00 - 11.00</button>
                                <button className={c2 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setC2(!c2)}}>11.00 - 12.00</button>
                            </div>
                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Afternoon:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={d2 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setD2(!d2)}}>13.00 - 14.00</button>
                                <button className={e2 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setE2(!e2)}}>14.00 - 15.00</button>
                            </div>
                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Evening:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={f2 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setF2(!f2)}}>15.00 - 16.00</button>
                                <button className={g2 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setG2(!g2)}}>16.00 - 17.00</button>
                            </div>
                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Night:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={h2 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setH2(!h2)}}>19.00 - 20.00</button>
                                <button className={i2 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setI2(!i2)}}>20.00 - 21.00</button>
                            </div>

                            </>
                        )}
                        {langkah === "Rabu" && (
                            <>
                            <button className='btn buttonVersFutureLinkBorderGrey' onClick={() => {setLangkah(0)}}>Back</button>

                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Morning:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={a3 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setA3(!a3)}}>09.00 - 10.00</button>
                                <button className={b3 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setB3(!b3)}}>10.00 - 11.00</button>
                                <button className={c3 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setC3(!c3)}}>11.00 - 12.00</button>
                            </div>
                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Afternoon:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={d3 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setD3(!d3)}}>13.00 - 14.00</button>
                                <button className={e3 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setE3(!e3)}}>14.00 - 15.00</button>
                            </div>
                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Evening:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={f3 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setF3(!f3)}}>15.00 - 16.00</button>
                                <button className={g3 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setG3(!g3)}}>16.00 - 17.00</button>
                            </div>
                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Night:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={h3 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setH3(!h3)}}>19.00 - 20.00</button>
                                <button className={i3 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setI3(!i3)}}>20.00 - 21.00</button>
                            </div>

                            </>
                        )}
                        {langkah === "Kamis" && (
                            <>
                            <button className='btn buttonVersFutureLinkBorderGrey' onClick={() => {setLangkah(0)}}>Back</button>

                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Morning:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={a4 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setA4(!a4)}}>09.00 - 10.00</button>
                                <button className={b4 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setB4(!b4)}}>10.00 - 11.00</button>
                                <button className={c4 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setC4(!c4)}}>11.00 - 12.00</button>
                            </div>
                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Afternoon:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={d4 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setD4(!d4)}}>13.00 - 14.00</button>
                                <button className={e4 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setE4(!e4)}}>14.00 - 15.00</button>
                            </div>
                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Evening:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={f4 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setF4(!f4)}}>15.00 - 16.00</button>
                                <button className={g4 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setG4(!g4)}}>16.00 - 17.00</button>
                            </div>
                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Night:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={h4 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setH4(!h4)}}>19.00 - 20.00</button>
                                <button className={i4 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setI4(!i4)}}>20.00 - 21.00</button>
                            </div>

                            </>
                        )}
                        {langkah === "Jumat" && (
                            <>
                            <button className='btn buttonVersFutureLinkBorderGrey' onClick={() => {setLangkah(0)}}>Back</button>

                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Morning:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={a5 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setA5(!a5)}}>09.00 - 10.00</button>
                                <button className={b5 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setB5(!b5)}}>10.00 - 11.00</button>
                                <button className={c5 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setC5(!c5)}}>11.00 - 12.00</button>
                            </div>
                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Afternoon:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={d5 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setD5(!d5)}}>13.00 - 14.00</button>
                                <button className={e5 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setE5(!e5)}}>14.00 - 15.00</button>
                            </div>
                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Evening:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={f5 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setF5(!f5)}}>15.00 - 16.00</button>
                                <button className={g5 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setG5(!g5)}}>16.00 - 17.00</button>
                            </div>
                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Night:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={h5 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setH5(!h5)}}>19.00 - 20.00</button>
                                <button className={i5 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setI5(!i5)}}>20.00 - 21.00</button>
                            </div>

                            </>
                        )}
                        {langkah === "Sabtu" && (
                            <>
                            <button className='btn buttonVersFutureLinkBorderGrey' onClick={() => {setLangkah(0)}}>Back</button>

                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Morning:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={a6 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setA6(!a6)}}>09.00 - 10.00</button>
                                <button className={b6 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setB6(!b6)}}>10.00 - 11.00</button>
                                <button className={c6 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setC6(!c6)}}>11.00 - 12.00</button>
                            </div>
                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Afternoon:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={d6 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setD6(!d6)}}>13.00 - 14.00</button>
                                <button className={e6 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setE6(!e6)}}>14.00 - 15.00</button>
                            </div>
                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Evening:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={f6 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setF6(!f6)}}>15.00 - 16.00</button>
                                <button className={g6 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setG6(!g6)}}>16.00 - 17.00</button>
                            </div>
                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Night:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={h6 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setH6(!h6)}}>19.00 - 20.00</button>
                                <button className={i6 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setI6(!i6)}}>20.00 - 21.00</button>
                            </div>

                            </>
                        )}
                        {langkah === "Minggu" && (
                            <>
                            <button className='btn buttonVersFutureLinkBorderGrey' onClick={() => {setLangkah(0)}}>Back</button>

                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Morning:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={a7 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setA7(!a7)}}>09.00 - 10.00</button>
                                <button className={b7 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setB7(!b7)}}>10.00 - 11.00</button>
                                <button className={c7 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setC7(!c7)}}>11.00 - 12.00</button>
                            </div>
                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Afternoon:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={d7 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setD7(!d7)}}>13.00 - 14.00</button>
                                <button className={e7 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setE7(!e7)}}>14.00 - 15.00</button>
                            </div>
                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Evening:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={f7 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setF7(!f7)}}>15.00 - 16.00</button>
                                <button className={g7 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setG7(!g7)}}>16.00 - 17.00</button>
                            </div>
                            <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Night:</p>
                            <div style={{display: 'flex', gap: '20px'}}>
                                <button className={h7 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setH7(!h7)}}>19.00 - 20.00</button>
                                <button className={i7 === true ? 'btn buttonVersFutureLinkGreen waktuCouns' : 'btn buttonVersFutureLinkRed waktuCouns'} onClick={() => {setI7(!i7)}}>20.00 - 21.00</button>
                            </div>

                            </>
                        )}

                    </ModalBody>
                    <ModalFooter style={{display: 'flex', justifyContent: 'center', gap: '80px'}}>
                        <button className='btn buttonVersFutureLinkGreen' style={{fontSize: '14px', fontWeight: '500'}} onClick={() => {updateSetting()}}>Update Setting</button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={modalAccessDenied} toggle={() => {}} centered>
                    <ModalBody className="modalCenter">
                        <img src={accessDenied} style={{ width: "30%" }} />
                        <p style={{ fontSize: "24px", fontWeight: "700", marginTop: "20px" }}>
                            Access Denied
                        </p>
                        <p style={{ margin: "0px", color: "grey" }}>
                            You currently does not have access to this page. 
                            <br /> 
                            Please Login Again
                        </p>
                    </ModalBody>
                    <ModalFooter style={{ display: "flex", justifyContent: "center", gap: "80px" }}>
                        <button className="btn buttonVersFutureLink" style={{ fontSize: "14px", fontWeight: "500" }} onClick={() => { logOut()}}>
                        Login Again
                        </button>
                    </ModalFooter>
                </Modal>

                
                </div>
        </React.Fragment>
    );
};

export default CounselorPlayground;
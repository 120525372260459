import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody, ModalFooter, Row, Label, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import Flatpickr from "react-flatpickr";

import deleteData from '../../../../assets/images/FutureLink/deleteData.png'



const SuperAdmin = () => {
    // document.title="Widgets | Velzon - React Admin & Dashboard Template";

    //modal
    const [modalAdd, setModalAdd] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [modalUpdate, setModalUpdate] = useState(false);
    //end modal 

    const [dataArray, setDataArray] = useState([]);
    useEffect(() => {
        setDataArray(superAdmin.filter((item, index) => item.role === 1))
    },[])

    const superAdmin = [
        {
            user_id : 1,
            nama_lengkap: "Harajuki",
            email : "harajuki@gmail.com",
            role : 1,
            agency_id : 1,
        },
        {
            user_id : 2,
            nama_lengkap: "Kirana",
            email : "kirana@gmail.com",
            role : 1,
            agency_id : 1,
        },
        {
            user_id : 3,
            nama_lengkap: "Longitudinal",
            email : "longitudinal@gmail.com",
            role : 1,
            agency_id : 1,
        },
        {
            user_id : 4,
            nama_lengkap: "Romano",
            email : "romano@gmail.com",
            role : 2,
            agency_id : 1,
        },
        {
            user_id : 5,
            nama_lengkap: "Julieto",
            email : "julieto@gmail.com",
            role : 2,
            agency_id : 1,
        },
        {
            user_id : 6,
            nama_lengkap: "Missy",
            email : "missy@gmail.com",
            role : 3,
            agency_id : 1,
        },
        {
            user_id : 7,
            nama_lengkap: "Archaro",
            email : "archaro@gmail.com",
            role : 3,
            agency_id : 1,
        },
        {
            user_id : 8,
            nama_lengkap: "Jaka Prakasa",
            email : "jakaprakasa@gmail.com",
            role : 4,
            agency_id : 1,
        },
        {
            user_id : 9,
            nama_lengkap: "Adinama",
            email : "adinama@gmail.com",
            role : 4,
            agency_id : 1,
        },
        {
            user_id : 7,
            nama_lengkap: "Richii",
            email : "richii@gmail.com",
            role : 5,
            agency_id : 2,
        },
        {
            user_id : 8,
            nama_lengkap: "Michii",
            email : "michii@gmail.com",
            role : 5,
            agency_id : 1,
        },
        {
            user_id : 9,
            nama_lengkap: "Kichii",
            email : "kichii@gmail.com",
            role : 5,
            agency_id : 2,
        },
    ]

    

    // const sortingData = () => {
    //     let filteredArray = superAdmin.filter((obj, index) => obj.role === 1);
    //     let indexArray = filteredArray.map(obj => superAdmin.indexOf(obj));
    //     // console.log(indexArray)

    //     const hasilAkhir = filteredArray.map((item, key) => 
    //         supe
    //     );
    //     console.log(hasilAkhir)
    // }

    return ( 
        <React.Fragment>
            <div>

                    <Row>
                        <Col lg={12}>

                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Super Admin Table</h4>
                                </CardHeader>

                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div>
                                                    <button className="btn buttonVersFutureLinkGreen" id="create-btn" onClick={() => {setModalAdd(true)}}><i className="ri-add-line align-bottom me-1"></i> Add</button>
                                                    {/* <Button className="btn btn-soft-danger"><i className="ri-delete-bin-2-line"></i></Button> */}
                                                </div>
                                            </Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input type="text" className="form-control search" placeholder="Search..." />
                                                        <i className="ri-search-line search-icon"></i>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <table className="table align-middle table-nowrap" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                            </div>
                                                        </th>
                                                        <th className="sort" data-sort="no">No</th>
                                                        <th className="sort" data-sort="nama">Nama</th>
                                                        <th className="sort" data-sort="email">Email</th>
                                                        <th className="sort" data-sort="status">Gender</th>
                                                        <th className="sort" data-sort="date-birthday">Date Birthday</th>
                                                        <th className="sort" data-sort="action">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {dataArray.map((item, index) => (
                                                    <tr key={index}>
                                                        <th scope="row">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="checkAll" value="option1" />
                                                            </div>
                                                        </th>
                                                        <td className="no">{index+1}</td>
                                                        <td className="nama">{item.nama_lengkap}</td>
                                                        <td className="email">{item.email}</td>
                                                        <td className="status"><span className="badge badge-soft-success text-uppercase">Perempuan</span></td>
                                                        <td className="date-birthday">12-10-2022</td>
                                                        <td>
                                                            <div className="d-flex gap-2">
                                                                <div className="edit">
                                                                    <button className="btn btn-sm edit-item-btn buttonVersFutureLinkGreen"
                                                                        data-bs-toggle="modal" data-bs-target="#showModal" onClick={() => {setModalUpdate(true)}}>Edit</button>
                                                                </div>
                                                                <div className="remove">
                                                                    <button className="btn btn-sm remove-item-btn buttonVersFutureLinkRed" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" onClick={() => {setModalDelete(true)}}>Remove</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <div className="noresult" style={{ display: "none" }}>
                                                <div className="text-center">
                                                    <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                        colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                                    </lord-icon>
                                                    <h5 className="mt-2">Sorry! No Result Found</h5>
                                                    <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                        orders for you search.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-end">
                                            <div className="pagination-wrap hstack gap-2">
                                                <Link className="page-item pagination-prev disabled" to="#">
                                                    Previous
                                                </Link>
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                                <Link className="page-item pagination-next" to="#">
                                                    Next
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

            </div>

            {/* Add Modal */}
            <Modal isOpen={modalAdd} toggle={() => {setModalAdd(!modalAdd)}} centered>
                <ModalHeader toggle={() => {setModalAdd(false)}}>
                    <h4>Add Super Admin</h4>
                </ModalHeader>
                <ModalBody>
                    <div className="mb-3">
                        <Label htmlFor="name" className="form-label">First Name</Label>
                        <Input type="text" className="form-control" id="first_name" placeholder="Enter your name" required/>
                    </div>

                    <div className="mb-3">
                        <Label htmlFor="name" className="form-label">Surname</Label>
                        <Input type="text" className="form-control" id="last_name" placeholder="Enter your name" required/>
                    </div>

                    <div className="mb-3">
                        <Label htmlFor="email" className="form-label">Email</Label>
                        <Input type="text" className="form-control" id="email" placeholder="Enter your email" required/>
                    </div>

                    <div className="mb-3">
                        <Label htmlFor="password" className="form-label">Password</Label>
                        <Input type="text" className="form-control" id="password" placeholder="Enter your password" required/>
                    </div>

                    <div className="mb-3">
                        <Label htmlFor="confPassword" className="form-label">Confirm Password</Label>
                        <Input type="text" className="form-control" id="confPassword" placeholder="Enter your confirm password" required/>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="gender-field" className="form-label">Gender</label>
                        <select className="form-control" data-trigger name="Gender-field" id="Gender-field" >
                            <option value="">Choose Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="date-field" className="form-label">Date Birthday</label>
                        <Flatpickr
                        className="form-control"
                        options={{
                            dateFormat: "d M, Y"
                        }}
                        placeholder="Select Date"
                    />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className='btn buttonVersFutureLinkGreen' onClick={() => {setModalAdd=(false)}}>Save</button>
                </ModalFooter>
            </Modal>
            {/* end add Modal */}

            {/* update Modal */}
            <Modal isOpen={modalUpdate} toggle={() => {setModalUpdate(!modalUpdate)}} centered>
                <ModalHeader toggle={() => {setModalUpdate(false)}}>
                    <h4>Update Super Admin</h4>
                </ModalHeader>
                <ModalBody>
                    <div className="mb-3">
                        <Label htmlFor="name" className="form-label">First Name</Label>
                        <Input type="text" className="form-control" id="first_name" placeholder="Enter your name" required/>
                    </div>

                    <div className="mb-3">
                        <Label htmlFor="name" className="form-label">Last Name</Label>
                        <Input type="text" className="form-control" id="last_name" placeholder="Enter your name" required/>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="gender-field" className="form-label">Gender</label>
                        <select className="form-control" data-trigger name="Gender-field" id="Gender-field" >
                            <option value="">Choose Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="date-field" className="form-label">Date Birthday</label>
                        <Flatpickr
                        className="form-control"
                        options={{
                            dateFormat: "d M, Y"
                        }}
                        placeholder="Select Date"
                    />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className='btn buttonVersFutureLinkGreen' onClick={() => {setModalUpdate=(false)}}>Save</button>
                </ModalFooter>
            </Modal>
            {/* end update Modal */}

            {/* end delete Modal */}                                        
            <Modal isOpen={modalDelete} toggle={() => {setModalDelete(!modalDelete)}} centered>
                <ModalBody style={{display: 'flex', justifyContent: 'center', margin: '35px 0px'}}> 
                    <img src={deleteData}/>
                </ModalBody>
                <ModalFooter style={{display: 'flex', justifyContent: 'center', gap: '80px'}}>
                    <button className='btn buttonVersFutureLinkBorderGrey ' onClick={() => {setModalDelete(false)}}>Close</button>
                    <button className='btn buttonVersFutureLinkRed' onClick={() => {setModalDelete(false)}}>Delete</button>
                </ModalFooter>
            </Modal>
            {/* end delete Modal */}

        </React.Fragment>
    );
};

export default SuperAdmin;
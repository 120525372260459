import React, {useState} from "react";
import {Link} from 'react-router-dom'


const Ekstrakulikuler = (e) => {
    const state = e.state.data
    const data = e.state.data
    const package_id = e.state.package_id
    const img_finger = e.state.img_finger
    const shape_finger = e.state.shape_finger

    const dance = state.dance
    const singing = state.singing
    const acting = state.acting
    const painting = state.painting
    const cooking = state.cooking
    const swimming = state.swimming
    const soccer = state.soccer
    const chess = state.chess
    const foreign_language = state.foreign_language
    const paper = state.paper
    const programming = state.programming


    return(
        <div className="corev2 dom">
            <div className="appearOnMobile tiwtiw" style={{marginBottom: '24px'}}>
                <Link to={{pathname: '/client/reportFP', state: { data: data, package_id: package_id, img_finger:img_finger, shape_finger:shape_finger },}} className="bBek">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M10.295 19.7155C10.3886 19.8077 10.4994 19.8806 10.6211 19.93C10.7429 19.9793 10.8731 20.0042 11.0045 20.0033C11.1358 20.0023 11.2657 19.9754 11.3867 19.9243C11.5076 19.8731 11.6173 19.7986 11.7095 19.705C11.8017 19.6115 11.8746 19.5007 11.9239 19.379C11.9733 19.2572 11.9982 19.127 11.9972 18.9956C11.9963 18.8643 11.9694 18.7344 11.9183 18.6134C11.8671 18.4925 11.7926 18.3827 11.699 18.2906L6.32903 13.0006H19.999C20.2642 13.0006 20.5186 12.8952 20.7061 12.7077C20.8937 12.5201 20.999 12.2658 20.999 12.0006C20.999 11.7353 20.8937 11.481 20.7061 11.2934C20.5186 11.1059 20.2642 11.0006 19.999 11.0006H6.33603L11.7 5.71355C11.884 5.52633 11.987 5.2743 11.9867 5.01183C11.9865 4.74936 11.8831 4.49751 11.6988 4.31061C11.5145 4.12371 11.2642 4.01675 11.0017 4.01281C10.7393 4.00887 10.4858 4.10827 10.296 4.28955L3.37203 11.1116C3.25411 11.2278 3.16048 11.3664 3.09657 11.5192C3.03267 11.672 2.99976 11.8359 2.99976 12.0016C2.99976 12.1672 3.03267 12.3311 3.09657 12.4839C3.16048 12.6367 3.25411 12.7753 3.37203 12.8916L10.296 19.7146L10.295 19.7155Z" fill="#374151"/>
                </svg>
                </Link>
                <p className="antitank" style={{textAlign: 'center'}}>Ekstrakulikuler</p>
            </div>

                <div className='ekstrakulikuler'>

                    <p style={{margin: '0px', textAlign: 'justify', fontSize: '16px'}}>Ekstrakulikuler dapat disusun dan dicocokkan berdasarkan pada <span style={{fontWeight: '700'}}>kecerdasan majemuk</span> atau multiple intelligence yang dimiliki semua orang. <span style={{fontWeight: '700'}}>Ekstrakurikuler</span> atau ekskul itu sendiri adalah wadah kegiatan pengembangan karakter dalam rangka perluasan potensi, bakat, minat, kemampuan, kepribadian, kerja sama, dan kemandirian seseorang secara optimal. Ekstrakulikuler memiliki empat fungsi yaitu fungsi pengembangan personal melalui pemberian kesempatan untuk mengembangkan potensi, fungsi sosial untuk menumbuhkan rasa tanggung jawab kepada diri sendiri dan orang lain, fungsi rekreatif yaitu mengembangkan situasi yang menyenangkan, dan fungsi persiapan karir untuk menunjang kapasitas yang dibutuhkan di kemudian hari.</p>

                    <div className="eklis">

                    <div className='ekstrakulikulerList'>
                        <div className='ekstrakulikulerListItem'>
                            <p className='ekstrakulikulerListText'>Menari </p>
                            <div className='ekstrakulikulerListCollection'>
                            {
                                 dance*5 < 11 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                </>) :  dance*5 < 21 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                </>) :  dance*5 < 31 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                </>) :  dance*5 < 41 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                </>) :  dance*5 < 51 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                </>) :  dance*5 < 61 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                </>) :  dance*5 < 71 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                </>) :  dance*5 < 81 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                </>) :  dance*5 < 91 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                </>) : (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                <div className='ekstrakulikulerBox level1'></div>
                                </>)
                            }
                            <p style={{margin: '0px', fontWeight: '700'}}>{ dance}%</p>
                            </div>
                        </div>
                        <div className='ekstrakulikulerListItem'>
                            <p className='ekstrakulikulerListText'>Menyanyi </p>
                            <div className='ekstrakulikulerListCollection'>
                            {
                                 singing*5 < 11 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                </>) :  singing*5 < 21 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                </>) :  singing*5 < 31 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                </>) :  singing*5 < 41 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                </>) :  singing*5 < 51 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                </>) :  singing*5 < 61 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                </>) :  singing*5 < 71 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                </>) :  singing*5 < 81 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                </>) :  singing*5 < 91 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                </>) : (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                <div className='ekstrakulikulerBox level1'></div>
                                </>)
                            }
                            <p style={{margin: '0px', fontWeight: '700'}}>{ singing}%</p>
                            </div>
                        </div>
                        <div className='ekstrakulikulerListItem'>
                            <p className='ekstrakulikulerListText'>Akting </p>
                            <div className='ekstrakulikulerListCollection'>
                            {
                                 acting*5 < 11 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                </>) :  acting*5 < 21 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                </>) :  acting*5 < 31 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                </>) :  acting*5 < 41 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                </>) :  acting*5 < 51 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                </>) :  acting*5 < 61 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                </>) :  acting*5 < 71 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                </>) :  acting*5 < 81 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                </>) :  acting*5 < 91 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                </>) : (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                <div className='ekstrakulikulerBox level1'></div>
                                </>)
                            }
                            <p style={{margin: '0px', fontWeight: '700'}}>{ acting}%</p>
                            </div>
                        </div>
                        <div className='ekstrakulikulerListItem'>
                            <p className='ekstrakulikulerListText'>Melukis </p>
                            <div className='ekstrakulikulerListCollection'>
                            {
                                 painting*5 < 11 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                </>) :  painting*5 < 21 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                </>) :  painting*5 < 31 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                </>) :  painting*5 < 41 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                </>) :  painting*5 < 51 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                </>) :  painting*5 < 61 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                </>) :  painting*5 < 71 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                </>) :  painting*5 < 81 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                </>) :  painting*5 < 91 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                </>) : (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                <div className='ekstrakulikulerBox level1'></div>
                                </>)
                            }
                            <p style={{margin: '0px', fontWeight: '700'}}>{ painting}%</p>
                            </div>
                        </div>
                        <div className='ekstrakulikulerListItem'>
                            <p className='ekstrakulikulerListText'>Memasak </p>
                            <div className='ekstrakulikulerListCollection'>
                            {
                                 cooking*5 < 11 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                </>) :  cooking*5 < 21 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                </>) :  cooking*5 < 31 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                </>) :  cooking*5 < 41 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                </>) :  cooking*5 < 51 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                </>) :  cooking*5 < 61 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                </>) :  cooking*5 < 71 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                </>) :  cooking*5 < 81 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                </>) :  cooking*5 < 91 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                </>) : (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                <div className='ekstrakulikulerBox level1'></div>
                                </>)
                            }
                            <p style={{margin: '0px', fontWeight: '700'}}>{ cooking}%</p>
                            </div>
                        </div>
                        <div className='ekstrakulikulerListItem'>
                            <p className='ekstrakulikulerListText'>Renang </p>
                            <div className='ekstrakulikulerListCollection'>
                            {
                                 swimming*5 < 11 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                </>) :  swimming*5 < 21 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                </>) :  swimming*5 < 31 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                </>) :  swimming*5 < 41 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                </>) :  swimming*5 < 51 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                </>) :  swimming*5 < 61 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                </>) :  swimming*5 < 71 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                </>) :  swimming*5 < 81 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                </>) :  swimming*5 < 91 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                </>) : (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                <div className='ekstrakulikulerBox level1'></div>
                                </>)
                            }
                            <p style={{margin: '0px', fontWeight: '700'}}>{ swimming}%</p>
                            </div>
                        </div>
                        <div className='ekstrakulikulerListItem'>
                            <p className='ekstrakulikulerListText'>Sepakbola </p>
                            <div className='ekstrakulikulerListCollection'>
                            {
                                 soccer*5 < 11 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                </>) :  soccer*5 < 21 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                </>) :  soccer*5 < 31 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                </>) :  soccer*5 < 41 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                </>) :  soccer*5 < 51 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                </>) :  soccer*5 < 61 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                </>) :  soccer*5 < 71 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                </>) :  soccer*5 < 81 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                </>) :  soccer*5 < 91 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                </>) : (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                <div className='ekstrakulikulerBox level1'></div>
                                </>)
                            }
                            <p style={{margin: '0px', fontWeight: '700'}}>{ soccer}%</p>
                            </div>
                        </div>
                        <div className='ekstrakulikulerListItem'>
                            <p className='ekstrakulikulerListText'>Catur </p>
                            <div className='ekstrakulikulerListCollection'>
                            {
                                 chess*5 < 11 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                </>) :  chess*5 < 21 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                </>) :  chess*5 < 31 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                </>) :  chess*5 < 41 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                </>) :  chess*5 < 51 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                </>) :  chess*5 < 61 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                </>) :  chess*5 < 71 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                </>) :  chess*5 < 81 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                </>) :  chess*5 < 91 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                </>) : (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                <div className='ekstrakulikulerBox level1'></div>
                                </>)
                            }
                            <p style={{margin: '0px', fontWeight: '700'}}>{ chess}%</p>
                            </div>
                        </div>
                        <div className='ekstrakulikulerListItem'>
                            <p className='ekstrakulikulerListText'>Bahasa Asing </p>
                            <div className='ekstrakulikulerListCollection'>
                            {
                                 foreign_language*5 < 11 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                </>) :  foreign_language*5 < 21 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                </>) :  foreign_language*5 < 31 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                </>) :  foreign_language*5 < 41 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                </>) :  foreign_language*5 < 51 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                </>) :  foreign_language*5 < 61 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                </>) :  foreign_language*5 < 71 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                </>) :  foreign_language*5 < 81 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                </>) :  foreign_language*5 < 91 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                </>) : (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                <div className='ekstrakulikulerBox level1'></div>
                                </>)
                            }
                            <p style={{margin: '0px', fontWeight: '700'}}>{ foreign_language}%</p>
                            </div>
                        </div>
                        <div className='ekstrakulikulerListItem'>
                            <p className='ekstrakulikulerListText'>Karya Tulis </p>
                            <div className='ekstrakulikulerListCollection'>
                            {
                                 paper*5 < 11 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                </>) :  paper*5 < 21 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                </>) :  paper*5 < 31 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                </>) :  paper*5 < 41 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                </>) :  paper*5 < 51 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                </>) :  paper*5 < 61 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                </>) :  paper*5 < 71 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                </>) :  paper*5 < 81 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                </>) :  paper*5 < 91 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                </>) : (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                <div className='ekstrakulikulerBox level1'></div>
                                </>)
                            }
                            <p style={{margin: '0px', fontWeight: '700'}}>{ paper}%</p>
                            </div>
                        </div>
                        <div className='ekstrakulikulerListItem'>
                            <p className='ekstrakulikulerListText'>Programming </p>
                            <div className='ekstrakulikulerListCollection'>
                            {
                                 programming*5 < 11 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                </>) :  programming*5 < 21 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                </>) :  programming*5 < 31 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                </>) :  programming*5 < 41 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                </>) :  programming*5 < 51 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                </>) :  programming*5 < 61 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                </>) :  programming*5 < 71 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                </>) :  programming*5 < 81 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                </>) :  programming*5 < 91 ? (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                </>) : (
                                <>
                                <div className='ekstrakulikulerBox level10'></div>
                                <div className='ekstrakulikulerBox level9'></div>
                                <div className='ekstrakulikulerBox level8'></div>
                                <div className='ekstrakulikulerBox level7'></div>
                                <div className='ekstrakulikulerBox level6'></div>
                                <div className='ekstrakulikulerBox level5'></div>
                                <div className='ekstrakulikulerBox level4'></div>
                                <div className='ekstrakulikulerBox level3'></div>
                                <div className='ekstrakulikulerBox level2'></div>
                                <div className='ekstrakulikulerBox level1'></div>
                                </>)
                            }
                            <p style={{margin: '0px', fontWeight: '700'}}>{ programming}%</p>
                            </div>
                        </div>
                    
                    </div>

                    <div className="goneOnMobile">
                        <p style={{fontSize: '16px', fontWeight: '700'}}>Keterangan:</p>
                        <ul style={{textAlign: 'justify', fontSize: '16px', width: '100%'}}>
                            <li>Warna hijau menunjukkan kecocokan yang tinggi antara ekstrakulikuler dengan kecerdasan majemuk yang dimiliki. </li>
                            <li>Warna kuning menunjukkan kecocokan yang menengah antara ekstrakulikuler dengan kecerdasan majemuk yang dimiliki.</li>
                            <li>Warna merah menunjukkan kecocokan yang rendah antara ekstrakulikuler dengan kecerdasan majemuk yang dimiliki.</li>
                        </ul>
                    </div>

                    </div>

                </div>
        </div>
    )
}

export default Ekstrakulikuler
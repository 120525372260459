import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import Lottie from 'react-lottie';
import accessDenied from '../../../../assets/images/FutureLink/accessDenied.json';

import analytical1 from '../../../../assets/images/FutureLink/analytical1.png';
import analytical2 from '../../../../assets/images/FutureLink/analytical2.png';
import analytical3 from '../../../../assets/images/FutureLink/analytical3.png';
import introspective1 from '../../../../assets/images/FutureLink/introspective1.png';
import introspective2 from '../../../../assets/images/FutureLink/introspective2.png';
import introspective3 from '../../../../assets/images/FutureLink/introspective3.png';
import interactive1 from '../../../../assets/images/FutureLink/interactive1.png';
import interactive2 from '../../../../assets/images/FutureLink/interactive2.png';
import interactive3 from '../../../../assets/images/FutureLink/interactive3.png';


const DomainKecerdasan= (haisayang) => {
    if(haisayang.history.location.state === undefined){
        window.location.href = "/report"
    }

    const data = haisayang.history.location.state.data
    // console.log(haisayang.history.location.state.data)

    const packages = haisayang.history.location.state.package_id
    // console.log(haisayang.history.location.state.package_id)

    return(
        <React.Fragment>
            <div className="page-content">
                {packages === 22 || packages === 33 ? (
                    <>
                <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                    <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 40px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Domaian Kecerdasan</p>
                </div>

                <div className='domainkecerdasan'>

                    <Link style={{marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to='/report'>Back</Link>

                    <div>
                        <div>
                            <p style={{margin:'0px', fontSize: '14px', textAlign: 'justify'}}>Walter L. McKenzie, Jr. (Survei Kecerdasan Berganda: 1999 - 2013) menguraikan sembilan kecerdasan yang disarankan oleh Dr. Howard Gardner dan mengkategorikan mereka ke dalam tiga domain yang berfungsi sebagai organisator untuk memahami hubungan dari kecerdasan dan bagaimana kecerdasan bekerja dalam kombinasi satu sama lain.</p>
                        </div>

                        <div style={{display: 'flex', gap: '80px', justifyContent: 'center', margin: '50px 0px'}}>
                            <div style={{width: 'max-content'}}>
                                <div style={{padding: '10px', backgroundColor: '#006D52', borderRadius: '3px', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)', width: 'max-content'}}>
                                    <p style={{fontSize: '24px', fontWeight: '900', color: 'white', textAlign: 'center'}}>Analyical</p>
                                    <div style={{display: 'flex', gap: '10px'}}>
                                        <img src={analytical1} style={{width: '60px', height: '60px'}}/>
                                        <img src={analytical2} style={{width: '60px', height: '60px'}}/>
                                        <img src={analytical3} style={{width: '60px', height: '60px'}}/>
                                    </div>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', padding: '20px 0px'}}>
                                    <p style={{margin: '0px', fontSize: '36px', fontWeight: '700', color: '#05C38A', textAlign: 'center'}}>28.4%</p>
                                </div>
                            </div>
                            <div style={{width: 'max-content'}}>
                                <div style={{padding: '10px', backgroundColor: '#FB5607', borderRadius: '3px', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)', width: 'max-content'}}>
                                    <p style={{fontSize: '24px', fontWeight: '900', color: 'white', textAlign: 'center'}}>Introspective</p>
                                    <div style={{display: 'flex', gap: '10px'}}>
                                        <img src={introspective1} style={{width: '60px', height: '60px'}}/>
                                        <img src={introspective2} style={{width: '60px', height: '60px'}}/>
                                        <img src={introspective3} style={{width: '60px', height: '60px'}}/>
                                    </div>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', padding: '20px 0px'}}>
                                    <p style={{margin: '0px', fontSize: '36px', fontWeight: '700', color: '#05C38A', textAlign: 'center'}}>31.5%</p>
                                </div>
                            </div>
                            <div style={{width: 'max-content'}}>
                                <div style={{padding: '10px', backgroundColor: '#FF006E', borderRadius: '3px', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)', width: 'max-content'}}>
                                    <p style={{fontSize: '24px', fontWeight: '900', color: 'white', textAlign: 'center'}}>Interactive</p>
                                    <div style={{display: 'flex', gap: '10px'}}>
                                        <img src={interactive1} style={{width: '60px', height: '60px'}}/>
                                        <img src={interactive2} style={{width: '60px', height: '60px'}}/>
                                        <img src={interactive3} style={{width: '60px', height: '60px'}}/>
                                    </div>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center', padding: '20px 0px'}}>
                                    <p style={{margin: '0px', fontSize: '36px', fontWeight: '700', color: '#05C38A', textAlign: 'center'}}>40.1%</p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div>
                                <p style={{fontSize: '18px', fontWeight: '700', marginBottom: '10px'}}>Kecerdasan Analitikal</p>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div style={{width: '190px', height: '3px', backgroundColor: '#006D52'}}></div>
                                    <div style={{width: '12px', height: '12px', borderRadius: '200px', backgroundColor: '#006D52'}}></div>
                                </div>
                                <p style={{fontSize: '14px', textAlign: 'justify', marginTop: '10px'}}> Kecerdasan ini biasanya mengekspresikan diri mereka dan menjelajahi lingkungan mereka. Kecerdasan logis, musikal, dan naturalis dianggap sebagai kecerdasan analitis karena mereka biasanya mengundang dan mendorong interaksi untuk mencapai pemahaman (sesuai dengan sifat alami proses sosial).</p>
                            </div>
                            <div>
                                <p style={{fontSize: '18px', fontWeight: '700', marginBottom: '10px'}}>Kecerdasan Introspektif</p>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div style={{width: '210px', height: '3px', backgroundColor: '#FB5607'}}></div>
                                    <div style={{width: '12px', height: '12px', borderRadius: '200px', backgroundColor: '#FB5607'}}></div>
                                </div>
                                <p style={{fontSize: '14px', textAlign: 'justify', marginTop: '10px'}}> Domain interaktif terdiri dari kecerdasan verbal, interpersonal, dan kinestetik. Ini adalah kecerdasan yang biasanya mengekspresikan diri dan mengeksplorasi lingkungan mereka. Ketiga kecerdasan ini bersifat interaktif karena meskipun dapat distimulasi melalui aktivitas pasif, ketiganya biasanya mengundang dan mendorong interaksi untuk mencapai pemahaman.</p>
                            </div>
                            <div>
                                <p style={{fontSize: '18px', fontWeight: '700', marginBottom: '10px'}}>Kecerdasan Interaktif</p>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div style={{width: '185px', height: '3px', backgroundColor: '#FF006E'}}></div>
                                    <div style={{width: '12px', height: '12px', borderRadius: '200px', backgroundColor: '#FF006E'}}></div>
                                </div>
                                <p style={{fontSize: '14px', textAlign: 'justify', marginTop: '10px'}}>Kecerdasan eksistensial, visual, dan intrapersonal merupakan bagian dari kecerdasan introspektif. Ini adalah kecerdasan yang dicirikan karena mereka membutuhkan melihat ke dalam hubungan emosional dengan pengalaman dan keyakinan mereka sendiri untuk memahami pembelajaran baru (proses afektif alami).</p>
                            </div>
                        </div>

                    </div>

                </div>

                <div className='domainkecerdasanMobile'>
                    <Link style={{marginBottom: '30px', width: 'max-content'}} className='btn buttonVersFutureLinkBorderGrey' to='/report'>Back</Link>

                    <div className='item'>
                        <div className='rounds'>
                            <img src={analytical1}/>
                            <img src={analytical2}/>
                            <img src={analytical3}/>
                        </div>
                        <div className='valueBox'>
                            <p className='p1'>Analytical</p>
                            <p className='p2'>{data.mckenzie_analytical_final}%</p>
                        </div>
                        <div className='keterangan'>
                            <p>Kecerdasan ini biasanya mengekspresikan diri mereka dan menjelajahi lingkungan mereka. Kecerdasan <span style={{fontWeight: '700'}}>logis, musikal, dan naturalis</span> dianggap sebagai kecerdasan analitis karena mereka biasanya mengundang dan mendorong interaksi untuk mencapai pemahaman (sesuai dengan sifat alami proses sosial).</p>
                        </div>
                    </div>
                    <div className='item'>
                        <div className='rounds'>
                            <img src={introspective1}/>
                            <img src={introspective2}/>
                            <img src={introspective3}/>
                        </div>
                        <div className='valueBox'>
                            <p className='p1'>Introspective</p>
                            <p className='p2'>{data.mckenzie_introspective_final}%</p>
                        </div>
                        <div className='keterangan'>
                            <p>Domain interaktif terdiri dari kecerdasan <span style={{fontWeight: '700'}}>verbal, interpersonal, dan kinestetik</span>. Ini adalah kecerdasan yang biasanya mengekspresikan diri dan mengeksplorasi lingkungan mereka. Ketiga kecerdasan ini bersifat interaktif karena meskipun dapat distimulasi melalui aktivitas pasif, ketiganya biasanya mengundang dan mendorong interaksi untuk mencapai pemahaman.</p>
                        </div>
                    </div>
                    <div className='item'>
                        <div className='rounds'>
                            <img src={interactive1}/>
                            <img src={interactive2}/>
                            <img src={interactive3}/>
                        </div>
                        <div className='valueBox'>
                            <p className='p1'>Interactive</p>
                            <p className='p2'>{data.mckenzie_interactive_final}%</p>
                        </div>
                        <div className='keterangan'>
                            <p>Kecerdasan <span style={{fontWeight: '700'}}>eksistensial, visual, dan intrapersonal</span> merupakan bagian dari kecerdasan introspektif. Ini adalah kecerdasan yang dicirikan karena mereka membutuhkan melihat ke dalam hubungan emosional dengan pengalaman dan keyakinan mereka sendiri untuk memahami pembelajaran baru (proses afektif alami).</p>
                        </div>
                    </div>
                </div>

                <div className='prevNext'>
                    {packages === 22 && (
                        <>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report/kecerdasan", state: { data: data, package_id: packages }}}>{"<"} Kecerdasan</Link>
                        </>
                    )}
                    {packages === 33 && (
                        <>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report/kecerdasan-majemuk", state: { data: data, package_id: packages }}}>{"<"} Kecerdasan Majemuk</Link>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report/disc", state: { data: data, package_id: packages }}}>DISC {">"}</Link>
                        </>
                    )}
                </div>
                    </>
                ) : (
                    <>
                        <Link style={{marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to='/report'>Back</Link>
                        <Lottie options={{loop: true,autoplay: true,animationData: accessDenied,rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} isClickToPauseDisabled={true} />
                        <p className='upgradePlease'> Maaf, Anda belum melakukan Upgrade yang lebih tinggi</p>
                    </>
                )}

            </div>
        </React.Fragment>
    )

}

export default DomainKecerdasan;
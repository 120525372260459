import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useInterval } from "usehooks-ts";
import axios from "axios";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

import Lottie from "react-lottie";
import accessDenied from "../../../../assets/images/FutureLink/accessDenied.json";
import accessLoading from "../../../../assets/images/FutureLink/loading.json";
import imgDenied from "../../../../assets/images/FutureLink/accessDenied.png";

import { decodeToken } from "react-jwt";

import no1 from "../../../../assets/images/FutureLink/no1.png";
import no2 from "../../../../assets/images/FutureLink/no2.png";
import no3 from "../../../../assets/images/FutureLink/no3.png";
import no4 from "../../../../assets/images/FutureLink/no4.png";
import no5 from "../../../../assets/images/FutureLink/no5.png";
import no6 from "../../../../assets/images/FutureLink/no6.png";
import no7 from "../../../../assets/images/FutureLink/no7.png";
import no8 from "../../../../assets/images/FutureLink/no8.png";
import no9 from "../../../../assets/images/FutureLink/no9.png";
import no10 from "../../../../assets/images/FutureLink/no10.png";
import no11 from "../../../../assets/images/FutureLink/no11.png";
import no12 from "../../../../assets/images/FutureLink/no12.png";
import no13 from "../../../../assets/images/FutureLink/no13.png";
import no14 from "../../../../assets/images/FutureLink/no14.png";
import no15 from "../../../../assets/images/FutureLink/no15.png";
import no16 from "../../../../assets/images/FutureLink/no16.png";
import no17 from "../../../../assets/images/FutureLink/no17.png";
import no23 from "../../../../assets/images/FutureLink/no23.png";

const Report = () => {
  const [modalAccessDenied, setModalAccessDenied] = useState(false);
  const logOut = () => {
    sessionStorage.removeItem("user");
    window.location.href = "/login";
  };

  const [pdf, setPdf] = useState("");
  const [statusCode, setStatusCode] = useState(0);
  const [data, setData] = useState([]);
  const [packages, setPackages] = useState(0);
  const [loading, setLoading] = useState(true);
  const code = sessionStorage.getItem("user");
  const decode = decodeToken(code);

  const [status, setStatus] = useState("pending");
  const [isPlaying, setPlaying] = useState(true);
  // console.log(decode)

  useEffect(() => {
    getReport();
  }, []);

  useInterval(
    () => {
      getReport();
      // console.log(status)
      if (status == "success") {
        setPlaying(false);
      }
    },
    isPlaying ? 60000 : null
  );

  const getReport = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-latest-report`,
      headers: {
        Authorization: `Bearer ${code}`,
      },
    };

    axios(config)
      .then((response) => {
        // console.log(response) // {statusCode: ... , message: ... , results: {created_at: ... , package_id: ... , pdf: ... , report: {~databanyak~}}}
        console.log(response);
        setStatus(response.results.status);
        setPdf(response.results.pdf);
        setStatusCode(response.statusCode);
        setData(response.results.report);
        setPackages(response.results.package_id);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      })
      .catch((error) => {
        // console.log(error);
        // console.log(error.response)
        if (error.response.data.statusCode === 403) {
          setModalAccessDenied(true);
        }
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      });
  };

  // console.log(statusCode, data)

  return (
    <React.Fragment>
      {loading === true ? (
        <>
          <div className="page-content" style={{ margin: "0px 20%" }}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: accessLoading,
                rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
              }}
              isClickToPauseDisabled={true}
            />
          </div>
        </>
      ) : (
        <>
          {/* 
            MAINNANNYA DISINI YAAWWW 
          */}

          {statusCode !== 200 || data === null ? (
            <div className="page-content">
              <>
                <Lottie
                  width={"55%"}
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: accessDenied,
                    rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
                  }}
                  isClickToPauseDisabled={true}
                />
                <p className="upgradePlease" style={{ width: "100%" }}>
                  {" "}
                  Sorry, the report is not available.
                </p>
              </>
            </div>
          ) : (
            <>
              <div className="page-content">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "absolute",
                    right: "50px",
                  }}
                >
                  {status === "pending" ? (
                    <>
                      <a
                        style={{
                          backgroundColor: "#1F599C",
                          padding: "5px 10px",
                          borderRadius: "8px",
                        }}
                        onClick={() => {
                          alert("Currently in progress, please wait a moment.");
                        }}
                      >
                        <i
                          className="ri-download-fill"
                          style={{ color: "white", fontSize: "22px" }}
                        />
                      </a>
                    </>
                  ) : (
                    <>
                      <a
                        href={pdf}
                        download
                        style={{
                          backgroundColor: "#1F599C",
                          padding: "5px 10px",
                          borderRadius: "8px",
                        }}
                        onClick={() => {
                          alert("Download Started");
                        }}
                      >
                        <i
                          className="ri-download-fill"
                          style={{ color: "white", fontSize: "22px" }}
                        />
                      </a>
                    </>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "60px",
                  }}
                >
                  <p className="titleReport">Report</p>
                </div>

                <div className="reportList">
                  {packages === 1 ? (
                    <>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/pola-sidik-jari",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no1} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>Pola Sidik Jari</p>
                      </Link>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/distribusi-neuron",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no2} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>Distribusi Neuron</p>
                      </Link>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/dominasi-otak",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no4} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>Dominasi Otak</p>
                      </Link>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/gaya-belajar",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no5} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>Gaya Belajar</p>
                      </Link>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/ekstrakulikuler",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no6} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>Ekstrakulikuler</p>
                      </Link>
                      {/* <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/summary",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no23} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>Summary</p>
                      </Link> */}
                    </>
                  ) : packages.toString().includes("22") ? (
                    <>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/pola-sidik-jari",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no1} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>Pola Sidik Jari</p>
                      </Link>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/distribusi-neuron",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no2} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>Distribusi Neuron</p>
                      </Link>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/dominasi-otak",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no4} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>Dominasi Otak</p>
                      </Link>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/gaya-belajar",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no5} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>Gaya Belajar</p>
                      </Link>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/ekstrakulikuler",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no6} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>Ekstrakulikuler</p>
                      </Link>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/penjurusan",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no7} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>
                          Rekomendasi Penjurusan
                        </p>
                      </Link>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/kecerdasan",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no9} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>Kecerdasan</p>
                      </Link>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/domain-kecerdasan",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no14} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>Domain Kecerdasan</p>
                      </Link>
                      {/* <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/summary",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no23} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>Summary</p>
                      </Link> */}
                    </>
                  ) : packages.toString().includes("33") ? (
                    <>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/pola-sidik-jari",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no1} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>Pola Sidik Jari</p>
                      </Link>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/distribusi-neuron",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no2} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>Distribusi Neuron</p>
                      </Link>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/tfrc",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no3} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>
                          Total Finger Ridge Count
                        </p>
                      </Link>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/dominasi-otak",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no4} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>Dominasi Otak</p>
                      </Link>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/gaya-belajar",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no5} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>Gaya Belajar</p>
                      </Link>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/ekstrakulikuler",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no6} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>Ekstrakulikuler</p>
                      </Link>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/penjurusan",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no7} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>
                          Rekomendasi Penjurusan
                        </p>
                      </Link>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/lobus-otak",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no8} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>Lobus Otak</p>
                      </Link>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/kecerdasan",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no9} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>Kecerdasan</p>
                      </Link>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/indra-dasar",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no10} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>Indra Dasar</p>
                      </Link>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/gaya-berpikir",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no11} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>Gaya Berpikir</p>
                      </Link>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/minat-kejuruan",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no12} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>Minat Studi</p>
                      </Link>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/kecerdasan-majemuk",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no13} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>
                          Kecerdasan Majemuk
                        </p>
                      </Link>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/domain-kecerdasan",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no14} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>Domain Kecerdasan</p>
                      </Link>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/disc",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no15} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>
                          Tipe Kepribadian DISC
                        </p>
                      </Link>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/dope",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no16} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>
                          Tipe Kepribadian DOPE
                        </p>
                      </Link>
                      <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/konsep-pemikiran",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no17} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>Konsep Pemikiran</p>
                      </Link>
                      {/* <Link
                        className="reportItem"
                        to={{
                          pathname: "/report/summary",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no23} style={{ width: "60px" }} />
                        <p style={{ textAlign: "center" }}>Summary</p>
                      </Link> */}
                    </>
                  ) : (
                    <>
                      <Lottie
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: accessDenied,
                          rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                          },
                        }}
                        isClickToPauseDisabled={true}
                      />
                      <p className="upgradePlease" style={{ width: "100%" }}>
                        {" "}
                        Sorry, the report is not available.
                      </p>
                    </>
                  )}
                </div>

                <div className="reportListMobile">
                  {packages === 1 ? (
                    <>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/pola-sidik-jari",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no1} />
                        <p>Pola Sidik Jari</p>
                      </Link>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/distribusi-neuron",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no2} />
                        <p>Distribusi Neuron</p>
                      </Link>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/dominasi-otak",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no4} />
                        <p>Dominasi Otak</p>
                      </Link>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/gaya-belajar",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no5} />
                        <p>Gaya Belajar</p>
                      </Link>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/ekstrakulikuler",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no6} />
                        <p>Ekstrakulikuler</p>
                      </Link>
                      {/* <Link
                        className="item"
                        to={{
                          pathname: "/report/summary",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no23} />
                        <p>Summary</p>
                      </Link> */}
                    </>
                  ) : packages.toString().includes("22") ? (
                    <>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/pola-sidik-jari",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no1} />
                        <p>Pola Sidik Jari</p>
                      </Link>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/distribusi-neuron",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no2} />
                        <p>Distribusi Neuron</p>
                      </Link>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/dominasi-otak",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no4} />
                        <p>Dominasi Otak</p>
                      </Link>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/gaya-belajar",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no5} />
                        <p>Gaya Belajar</p>
                      </Link>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/ekstrakulikuler",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no6} />
                        <p>Ekstrakulikuler</p>
                      </Link>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/penjurusan",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no7} />
                        <p>Rekomendasi Penjurusan</p>
                      </Link>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/kecerdasan",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no9} />
                        <p>Kecerdasan</p>
                      </Link>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/domain-kecerdasan",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no14} />
                        <p>Domain Kecerdasan</p>
                      </Link>
                      {/* <Link
                        className="item"
                        to={{
                          pathname: "/report/summary",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no23} />
                        <p>Summary</p>
                      </Link> */}
                    </>
                  ) : packages.toString().includes("33") ? (
                    <>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/pola-sidik-jari",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no1} />
                        <p>Pola Sidik Jari</p>
                      </Link>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/distribusi-neuron",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no2} />
                        <p>Distribusi Neuron</p>
                      </Link>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/tfrc",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no3} />
                        <p>Total Finger Ridge Count</p>
                      </Link>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/dominasi-otak",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no4} />
                        <p>Dominasi Otak</p>
                      </Link>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/gaya-belajar",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no5} />
                        <p>Gaya Belajar</p>
                      </Link>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/ekstrakulikuler",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no6} />
                        <p>Ekstrakulikuler</p>
                      </Link>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/penjurusan",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no7} />
                        <p>Rekomendasi Penjurusan</p>
                      </Link>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/lobus-otak",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no8} />
                        <p>Lobus Otak</p>
                      </Link>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/kecerdasan",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no9} />
                        <p>Kecerdasan</p>
                      </Link>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/indra-dasar",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no10} />
                        <p>Indra Dasar</p>
                      </Link>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/gaya-berpikir",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no11} />
                        <p>Gaya Berpikir</p>
                      </Link>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/minat-kejuruan",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no12} />
                        <p>Minat Studi</p>
                      </Link>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/kecerdasan-majemuk",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no13} />
                        <p>Kecerdasan Majemuk</p>
                      </Link>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/domain-kecerdasan",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no14} />
                        <p>Domain Kecerdasan</p>
                      </Link>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/disc",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no15} />
                        <p>Tipe Kepribadian DISC</p>
                      </Link>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/dope",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no16} />
                        <p>Tipe Kepribadian DOPE</p>
                      </Link>
                      <Link
                        className="item"
                        to={{
                          pathname: "/report/konsep-pemikiran",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no17} />
                        <p>Konsep Pemikiran</p>
                      </Link>
                      {/* <Link
                        className="item"
                        to={{
                          pathname: "/report/summary",
                          state: { data: data, package_id: packages },
                        }}
                      >
                        <img src={no23} />
                        <p>Summary</p>
                      </Link> */}
                    </>
                  ) : (
                    <>
                      <Lottie
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: accessDenied,
                          rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                          },
                        }}
                        isClickToPauseDisabled={true}
                      />
                      <p className="upgradePlease" style={{ width: "100%" }}>
                        {" "}
                        Sorry, the report is not available.
                      </p>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}

      <Modal isOpen={modalAccessDenied} toggle={() => {}} centered>
        <ModalBody className="modalCenter">
          <img src={imgDenied} style={{ width: "30%" }} />
          <p style={{ fontSize: "24px", fontWeight: "700", marginTop: "20px" }}>
            Access Denied
          </p>
          <p style={{ margin: "0px", color: "grey" }}>
            You currently does not have access to this page. <br /> Please Login
            Again
          </p>
        </ModalBody>
        <ModalFooter
          style={{ display: "flex", justifyContent: "center", gap: "80px" }}
        >
          <button
            className="btn buttonVersFutureLink"
            style={{ fontSize: "14px", fontWeight: "500" }}
            onClick={() => {
              logOut();
            }}
          >
            Login Again
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Report;

import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, Input, Label, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, Row, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, ModalHeader, Nav, NavItem, NavLink, TabPane, TabContent, activeVerticalTab, toggleVerticalTab, Alert } from 'reactstrap';
import classnames from "classnames";
import { Link } from 'react-router-dom';
import Flatpickr from "react-flatpickr";
import axios from 'axios';
import FormData from 'form-data';

import deleteData from '../../../assets/images/FutureLink/deleteData.png'

import Lottie from 'react-lottie';
import animateSuccess from '../../../assets/images/FutureLink/success.json'
import animateDelete from '../../../assets/images/FutureLink/delete.json'

import accessDenied from '../../../assets/images/FutureLink/accessDenied.png'

const UsersGroup = () => {
    // document.title="Widgets | Velzon - React Admin & Dashboard Template";
    const [modalAccessDenied, setModalAccessDenied] = useState(false);
    const logOut = () => {
      sessionStorage.removeItem('user');
      window.location.href = '/login'
    }

    const code = sessionStorage.getItem('user')
    const [step, setStep] = useState(0);
    const [usersGroup, setUsersGroup] = useState([]);

    const [fade, setFade] = useState('fadeout');
    const [alert, setAlert] = useState(true);
    const [modalAlertSuccess, setModalAlertSuccess] = useState(false);
    const [modalAlertDelete, setModalAlertDelete] = useState(false);
    const animationSuccess = {
      loop: true,
      autoplay: true,
      animationData: animateSuccess,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    const animationDelete = {
      loop: true,
      autoplay: true,
      animationData: animateDelete,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    // ------- MODAL ------- //
    const [modalAddUsersGroup, setModalAddUsersGroup] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    // ----- END MODAL ------//

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    const [getName, setGetName] = useState("");
    const [getDescription, setGetDescription] = useState("");

    const [idPrivilege, setIdPrivilege] = useState("");

    const [cAgency, setCAgency] = useState(false);
    const [rAgency, setRAgency] = useState(false);
    const [uAgency, setUAgency] = useState(false);
    const [dAgency, setDAgency] = useState(false);
    const [cUserRole, setCUserRole] = useState(false);
    const [rUserRole, setRUserRole] = useState(false);
    const [uUserRole, setUUserRole] = useState(false);
    const [dUserRole, setDUserRole] = useState(false);
    const [cAnnotator, setCAnnotator] = useState(false);
    const [rAnnotator, setRAnnotator] = useState(false);
    const [uAnnotator, setUAnnotator] = useState(false);
    const [dAnnotator, setDAnnotator] = useState(false);
    const [cUserDetail, setCUserDetail] = useState(false);
    const [rUserDetail, setRUserDetail] = useState(false);
    const [uUserDetail, setUUserDetail] = useState(false);
    const [dUserDetail, setDUserDetail] = useState(false);
    const [cPackage, setCPackage] = useState(false);
    const [rPackage, setRPackage] = useState(false);
    const [uPackage, setUPackage] = useState(false);
    const [dPackage, setDPackage] = useState(false);

    const [users, setUsers] = useState([]);
    const [modalAdd, setModalAdd] = useState(false);
    const [modalUserDelete, setModalUserDelete] = useState(false);
    const [modalUpdate, setModalUpdate] = useState(false);

    const [firstName, setFirstName] = useState("");
    const [surName, setSurName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confPassword, setConfPassword] = useState("");
    const [gender, setGender] = useState("");
    const [dateBirthday, setDateBirthday] = useState("");
    const [userId, setUserId] = useState("");

    const [kontenError, setKontenError] = useState("Check Again");
    const [modalNoDelete, setModalNoDelete] = useState(false);

    const [show, setShow] = useState(false);

    useEffect(() => {
      getAllPrivilege();
    },[])

    const getAllPrivilege = () => {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-privilege`,
        headers: {
          'Authorization': `Bearer ${code}`
        }
      };

      axios.request(config)
      .then((response) => {
        // console.log(response)
        if(response.statusCode === 401) {
          setModalAccessDenied(true)
      }else{
          setUsersGroup(response.results)
      }

      })
      .catch((error) => {
        // console.log(error)
        // console.log(error.response)
        if(error.response.data.statusCode === 403) {
          setModalAccessDenied(true)
        }
      })
    }

    const getPrivilege = (privilege_id) => {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-privilege?privilegeId=${privilege_id}`,
        headers: {
          'Authorization': `Bearer ${code}`,
        }
      };
      
      axios(config)
      .then((response) => {
        // console.log(response);
          // console.log(response.results[0]);
          setGetName(response.results[0].name)
          setGetDescription(response.results[0].description)

          let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-permission?privilegeId=${privilege_id}`,
            headers: { 
              'Authorization': `Bearer ${code}`, 
            }
          };
          
          axios.request(config)
          .then((response) => {
            // console.log(response.results[0].privilege_permission);
            // console.log(response.results.length)
        //     console.log(response.results[0].privilege_permission);
        //     console.log(response.results[0].privilege_permission.create[0]);
        //     console.log(response.results[0].privilege_permission[privilege_id][0]);
            if(response.results.length === 0 ) {
              setCAgency(false)
              setRAgency(false)
              setUAgency(false)
              setDAgency(false)
  
              setCUserRole(false)
              setRUserRole(false)
              setUUserRole(false)
              setDUserRole(false)
  
              setCAnnotator(false)
              setRAnnotator(false)
              setUAnnotator(false)
              setDAnnotator(false)
  
              setCUserDetail(false)
              setRUserDetail(false)
              setUUserDetail(false)
              setDUserDetail(false)
  
              setCPackage(false)
              setRPackage(false)
              setUPackage(false)
              setDPackage(false)

            } else {

              setCAgency(response.results[0].agency[0] === "c" ? true : false)
              setRAgency(response.results[0].agency[1] === "r" ? true : false)
              setUAgency(response.results[0].agency[2] === "u" ? true : false)
              setDAgency(response.results[0].agency[3] === "d" ? true : false)
  
              setCUserRole(response.results[0].privilege_permission.create[0] === "c" ? true : false)
              setRUserRole(response.results[0].privilege_permission[privilege_id][0] === "r" ? true : false)
              setUUserRole(response.results[0].privilege_permission[privilege_id][1] === "u" ? true : false)
              setDUserRole(response.results[0].privilege_permission[privilege_id][2] === "d" ? true : false)
  
              setCAnnotator(response.results[0].fingerprints[0] === "c" ? true : false)
              setRAnnotator(response.results[0].fingerprints[1] === "r" ? true : false)
              setUAnnotator(response.results[0].fingerprints[2] === "u" ? true : false)
              setDAnnotator(response.results[0].fingerprints[3] === "d" ? true : false)
  
              setCUserDetail(response.results[0].user_payment[0] === "c" ? true : false)
              setRUserDetail(response.results[0].user_payment[1] === "r" ? true : false)
              setUUserDetail(response.results[0].user_payment[2] === "u" ? true : false)
              setDUserDetail(response.results[0].user_payment[3] === "d" ? true : false)
  
              setCPackage(response.results[0].package_counselor[0] === "c" ? true : false)
              setRPackage(response.results[0].package_counselor[1] === "r" ? true : false)
              setUPackage(response.results[0].package_counselor[2] === "u" ? true : false)
              setDPackage(response.results[0].package_counselor[3] === "d" ? true : false)

            }

          })
          .catch((error) => {
            // console.log(error);
            if(error.response.data.statusCode === 403) {
              setModalAccessDenied(true)
            }
          });



      })
      .catch((error) => {
        // console.log(error);
        if(error.response.data.statusCode === 403) {
          setModalAccessDenied(true)
        }
      });
    }

    const getAllUserPrivilege = (privilege_id) => {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-user`,
        headers: {
          'Authorization': `Bearer ${code}`, 
        }
      };

      axios.request(config)
      .then((response) => {
        // console.log(response)
        const data = response.results
        setUsers(data.filter((item, index) => item.privilege_id === privilege_id))
      })
      .catch((error) => {
        // console.log(error)
        if(error.response.data.statusCode === 403) {
          setModalAccessDenied(true)
        }
      })
    }

    const getUserPrivilege = (userId) => {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-user?userId=${userId}`,
        headers: {
          'Authorization': `Bearer ${code}`, 
        }
      };
      
      axios(config)
      .then((response) => {
        // console.log(response)
        // console.log(response.results);
        setFirstName(response.results.first_name);
        setSurName(response.results.last_name);
        setPhone(response.results.phone);
        setEmail(response.results.email);
        setGender(response.results.sex);
        
        const formatDate = new Date(response.results.date_birth)
        const year = formatDate.getFullYear();
        const month = formatDate.getMonth()+1 > 9 ? formatDate.getMonth()+1 : "0"+(formatDate.getMonth()+1);
        const day = formatDate.getDate() > 9 ? formatDate.getDate() : "0"+formatDate.getDate();
        setDateBirthday(year+"-"+month+"-"+day);
        
      })
      .catch((error) => {
        // console.log(error);
        if(error.response.data.statusCode === 403) {
          setModalAccessDenied(true)
        }
      });
    }

    const updatePrivilege = () => {

      if (getName === "" || getDescription === "") {
        setAlert(false);
        setKontenError(" Field cannot be empty ");

        setTimeout(() => {
            setFade('fade')
        }, 200)

        setTimeout(() => {
            setFade('fadeout');
        }, 4500)

        setTimeout(() => {
            setAlert(true);
            setKontenError("Check Again");
        }, 5000);
      } else {

        let data = new FormData();
        data.append('privilegeId', idPrivilege);
        data.append('name', getName);
        data.append('description', getDescription);
  
        let config = {
          method: 'patch',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/update-privilege`,
          headers: {
            'Authorization': `Bearer ${code}`, 
          },
          data : data
        };
  
        axios.request(config)
        .then((response) => {
          // console.log(response)
          if(response.statusCode !== 403){
            setModalAlertSuccess(true)
            setTimeout(() => {
              setModalAlertSuccess(false);
              toggleVerticalTab(2);
            }, 4000)
  
          }else{
            setModalAccessDenied(true)
          }
        })
        .catch((error) => {
          // console.log(error);
          if(error.response.data.statusCode === 403) {
            setModalAccessDenied(true)
          }
  
          setAlert(false);
  
          setTimeout(() => {
              setFade('fade')
          }, 200)
  
          setTimeout(() => {
              setFade('fadeout');
          }, 4500)
  
          setTimeout(() => {
              setAlert(true);
          }, 5000);
  
        });

      }
    }

    const updatePermission = (cI, rI, uI, dI, cUr, rUr, uUr, dUr, cA, rA, uA, dA, cU, rU, uU, dU, cP, rP, uP, dP) => {
      const json = {create: [cUr], [idPrivilege]: [rUr,uUr,dUr]}
      let data = new FormData();
      data.append('privilegeId', idPrivilege);
      data.append('agency', JSON.stringify([cI, rI, uI, dI]));
      data.append('privilegePermission', JSON.stringify(json));
      data.append('fingerprints', JSON.stringify([cA, rA, uA, dA]));
      data.append('userPayment', JSON.stringify([cU, rU, uU, dU]));
      data.append('packageCounselor', JSON.stringify([cP, rP, uP, dP]));

      let config = {
        method: 'patch',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/update-permission`,
        headers: { 
          'Authorization': `Bearer ${code}`, 
        },
        data : data
      };

      axios.request(config)
      .then((response) => {
        // console.log(response);
          getAllPrivilege()
          setModalEdit(false)
          setModalAlertSuccess(true)
          setTimeout(() => {
            setModalAlertSuccess(false)
          }, 4000)
      })
      .catch((error) => {
        // console.log(error);
        if(error.response.data.statusCode === 403) {
          setModalAccessDenied(true)
        }
      });
    }

    const updateUserPrivilege = () => {

      if (email.includes(".") === false || email.includes("@") === false ) {
        setAlert(false);
        setKontenError("Email not found")

        setTimeout(() => {
            setFade('fade')
        }, 200)

        setTimeout(() => {
            setFade('fadeout');
        }, 4500)

        setTimeout(() => {
            setAlert(true);
            setKontenError("Check Again")
        }, 5000);
      } else {
        const formatDate = new Date(dateBirthday)
        const year = formatDate.getFullYear();
        const month = formatDate.getMonth()+1 > 9 ? formatDate.getMonth()+1 : "0"+(formatDate.getMonth()+1);
        const day = formatDate.getDate() > 9 ? formatDate.getDate() : "0"+formatDate.getDate();

        let data = new FormData();
        data.append('userId', userId);
        data.append('firstName', firstName);
        data.append('lastName', surName);
        data.append('sex', gender);
        data.append('email', email);
        if(dateBirthday !== undefined){
          data.append('dateBirth', year+"-"+month+"-"+day);
        }
        
  
        let config = {
          method: 'patch',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/update-user`,
          headers: { 
            'Authorization': `Bearer ${code}`, 
          },
          data : data
        };
  
        axios.request(config)
        .then((response) => {
          // console.log(response);
            getAllUserPrivilege(idPrivilege)
            setModalAlertSuccess(true)
            setTimeout(() => {
              setModalAlertSuccess(false)
            }, 4000)
        })
        .catch((error) => {
          // console.log(error);

          if(error.response.data.statusCode === 403) {
            setModalAccessDenied(true)
          }
  
          setAlert(false);
  
          setTimeout(() => {
              setFade('fade')
          }, 200)
  
          setTimeout(() => {
              setFade('fadeout');
          }, 4500)
  
          setTimeout(() => {
              setAlert(true);
          }, 5000);
        });

      }


    }

    const deletePrivilege = () => {
      let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/delete-privilege?privilegeId=${idPrivilege}`,
        headers: {
          'Authorization': `Bearer ${code}`, 
        }
      };
      
      axios(config).then((response) => {
        // console.log(response)
        setModalDelete(false);
        getAllPrivilege()

        setModalAlertDelete(true)
        setTimeout(() => {
          setModalAlertDelete(false)
        },4000)
      })
      .catch((error) => {
        // console.log(error);
        // console.log(error.response)
        if(error.response.data.statusCode === 500) {
          setModalDelete(false);
          setModalNoDelete(true);
          setIdPrivilege("");
          setTimeout(() => {
            setModalNoDelete(false);
          }, 4000)
        }

        if(error.response.data.statusCode === 403) {
          setModalAccessDenied(true)
        }
      });
    
    }

    const deleteUserPrivilege = () => {
      let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/delete-user?userId=${userId}`,
        headers: { 
          'Authorization': `Bearer ${code}`
        }
      };
      
      axios.request(config)
      .then((response) => {
        // console.log(response);
          setModalUserDelete(false)
          getAllUserPrivilege(idPrivilege)

          setModalAlertDelete(true)
          setTimeout(() => {
            setModalAlertDelete(false)
          },4000)
      })
      .catch((error) => {
        // console.log(error);
        if(error.response.data.statusCode === 403) {
          setModalAccessDenied(true)
        }
      });
    }

    const addUsersGroup = () => {
      // let data = new FormData();
      // data.append('name', name);
      // data.append('description', description);
      
      // if (name === "" || description === "") {
      //   setAlert(false);
      //   setKontenError("Field cannot be empty");

      //   setTimeout(() => {
      //       setFade('fade')
      //   }, 200)

      //   setTimeout(() => {
      //       setFade('fadeout');
      //   }, 4500)

      //   setTimeout(() => {
      //       setAlert(true);
      //       setKontenError("Check Again");
      //   }, 5000);
      // } else {

      //   const config = {
      //     method: 'post',
      //     maxBodyLength: Infinity,
      //     url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/make-privilege`,
      //     headers: { 
      //       'Authorization': `Bearer ${code}`, 
      //     },
      //     data : data
      //   };
        
      //   axios(config).then((response) => {
      //     console.log(response);
      //     setModalAddUsersGroup(false)
  
      //     if(response.statusCode !== 403) {
  
      //       const config = {
      //         method: 'get',
      //         maxBodyLength: Infinity,
      //         url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-privilege`,
      //         headers: { 
      //           'Authorization': `Bearer ${code}`, 
      //         },
      //       };
            
      //       axios(config)
      //       .then((response) => {
      //         const coba = response.results;
      //         const index = response.results.length - 1
      //         const id = coba[index].privilege_id
  
      //         const json = {create: [""], [id]: ["","",""]}
    
      //         let data = new FormData();
      //         data.append('privilegeId', id);
      //         data.append('agency', '["","","",""]');
      //         data.append('privilegePermission', JSON.stringify(json));
      //         data.append('fingerprints', '["","","",""]');
      //         data.append('userPayment', '["","","",""]');
      //         data.append('packageCounselor', '["","","",""]');
  
      //         // console.log(json)
    
      //         let config = {
      //           method: 'post',
      //           maxBodyLength: Infinity,
      //           url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/make-permission`,
      //           headers: { 
      //             'Authorization': `Bearer ${code}`, 
      //           },
      //           data : data
      //         };
    
      //         axios(config)
      //         .then((response) => {
      //             console.log(response)
      //             setModalAddUsersGroup(false);
      //             getAllPrivilege()
      //             setModalAlertSuccess(true)
      //             setTimeout(() => {
      //               setModalAlertSuccess(false)
      //             }, 4000)
      //         })
      //         .catch((error) => {
      //           console.log(error);
      //           if(error.response.data.statusCode === 403) {
      //             setModalAccessDenied(true)
      //           }
  
      //           setAlert(false);
  
      //           setTimeout(() => {
      //               setFade('fade')
      //           }, 200)
        
      //           setTimeout(() => {
      //               setFade('fadeout');
      //           }, 4500)
        
      //           setTimeout(() => {
      //               setAlert(true);
      //           }, 5000);
      //         });
      //       })
      //       .catch((error) => {
      //         console.log(error);

      //         if(error.response.data.statusCode === 403) {
      //           setModalAccessDenied(true)
      //         }
  
      //         setAlert(false);
  
      //         setTimeout(() => {
      //             setFade('fade')
      //         }, 200)
      
      //         setTimeout(() => {
      //             setFade('fadeout');
      //         }, 4500)
      
      //         setTimeout(() => {
      //             setAlert(true);
      //         }, 5000);
      //       });
      //     } else {
      //       setModalAccessDenied(true)
      //     }
      //   }).catch((error) => {
      //     console.log(error);

      //     if(error.response.data.statusCode === 403) {
      //       setModalAccessDenied(true)
      //     }
  
      //     setAlert(false);
  
      //     setTimeout(() => {
      //         setFade('fade')
      //     }, 200)
  
      //     setTimeout(() => {
      //         setFade('fadeout');
      //     }, 4500)
  
      //     setTimeout(() => {
      //         setAlert(true);
      //     }, 5000);
      //   });

      // }

      let data = new FormData();
      data.append('name', name);
      data.append('description', description);
      data.append('agency', '["","","",""]');
      data.append('privilegePermission', '["","",""]');
      data.append('privilegeCreate', '[""]');
      data.append('fingerprints', '["","","",""]');
      data.append('userPayment', '["","","",""]');
      data.append('packageCounselor', '["","","",""]');

      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/make-privilege`,
        headers: { 
          'Authorization': `Bearer ${code}`, 
        },
        data : data
      };

      axios(config).then((response) => {
        // console.log(response)

      }).catch((error) => {
        // console.log(error);

        if(error.response.data.statusCode === 403) {
          setModalAccessDenied(true)
        }

        setAlert(false);

        setTimeout(() => {
            setFade('fade')
        }, 200)

        setTimeout(() => {
            setFade('fadeout');
        }, 4500)

        setTimeout(() => {
            setAlert(true);
        }, 5000);
      });
    }

    const addUserPrivilege = () => {

      if (email.includes(".") === false || email.includes("@") === false ) {
        setAlert(false);
        setKontenError("Email not found")

        setTimeout(() => {
            setFade('fade')
        }, 200)

        setTimeout(() => {
            setFade('fadeout');
        }, 4500)

        setTimeout(() => {
            setAlert(true);
            setKontenError("Check Again")
        }, 5000);
      } else if (email === "" || firstName === "" || surName === "" || password === "" || gender === "") {
        setAlert(false);
        setKontenError("Field cannot be empty")

        setTimeout(() => {
            setFade('fade')
        }, 200)

        setTimeout(() => {
            setFade('fadeout');
        }, 4500)

        setTimeout(() => {
            setAlert(true);
            setKontenError("Check Again")
        }, 5000);

      } else {

        if(password !== confPassword) {
          setAlert(false);
          setKontenError("Password not match with Confirm Password")
  
          setTimeout(() => {
              setFade('fade')
          }, 200)
  
          setTimeout(() => {
              setFade('fadeout');
          }, 4500)
  
          setTimeout(() => {
              setAlert(true);
              setKontenError("Check Again")
          }, 5000);
        } else {

          const formatDate = new Date(dateBirthday)
          const year = formatDate.getFullYear();
          const month = formatDate.getMonth()+1 > 9 ? formatDate.getMonth()+1 : "0"+(formatDate.getMonth()+1);
          const day = formatDate.getDate() > 9 ? formatDate.getDate() : "0"+formatDate.getDate();
    
          let data = new FormData();
          data.append('firstName', firstName);
          data.append('lastName', surName);
          data.append('phone', phone);
          data.append('email', email);
          data.append('password', password);
          if(year+"-"+month+"-"+day !== "NaN-0NaN-0NaN"){
            data.append('dateBirth', year+"-"+month+"-"+day);
          }
          data.append('sex', gender);
          data.append('privilegeId', idPrivilege);
    
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/make-custom-user`,
            headers: { 
              'Authorization': `Bearer ${code}`, 
            },
            data : data
          };
    
          axios(config)
          .then((response) => {
            // console.log(response)
            setModalAdd(false)
            getAllUserPrivilege(idPrivilege)
            setModalAlertSuccess(true)
            setTimeout(() => {
              setModalAlertSuccess(false)
            }, 4000)
          })
          .catch((error) => {
            // console.log(error);
            // console.log(error.response)

            if(error.response.data.statusCode === 403) {
              setModalAccessDenied(true)
            } else if (error.response.data.statusCode === 422) {
              setAlert(false);
              setKontenError("Im Sorry, Format Wrong")
    
              setTimeout(() => {
                  setFade('fade')
              }, 200)
      
              setTimeout(() => {
                  setFade('fadeout');
              }, 4500)
      
              setTimeout(() => {
                  setAlert(true);
                  setKontenError("Check Again")
              }, 5000);
            }else{
              if(error.response.data.statusCode === 500) {
                setAlert(false);
                setKontenError("Email is already registered")
      
                setTimeout(() => {
                    setFade('fade')
                }, 200)
        
                setTimeout(() => {
                    setFade('fadeout');
                }, 4500)
        
                setTimeout(() => {
                    setAlert(true);
                    setKontenError("Check Again")
                }, 5000);
              } else {
                setAlert(false);
    
                setTimeout(() => {
                    setFade('fade')
                }, 200)
        
                setTimeout(() => {
                    setFade('fadeout');
                }, 4500)
        
                setTimeout(() => {
                    setAlert(true);
                    setKontenError("Check Again")
                }, 5000);
              }
            }

          });

        }

      }

    }

    const [activeVerticalTab, setactiveVerticalTab] = useState(1);
    const [passedverticalSteps, setPassedverticalSteps] = useState([1]);
    function toggleVerticalTab(tab) {
      if (activeVerticalTab !== tab) {
        var modifiedSteps = [...passedverticalSteps, tab];

        if (tab >= 1 && tab <= 11) {
          setactiveVerticalTab(tab);
          setPassedverticalSteps(modifiedSteps);
        }
      }
    }


    return ( 
        <React.Fragment>
            <div className="page-content">

            <Row>
                <Col lg={12} style={{padding: '0px 25px'}}>
                    <h4 className='fontJakarta'>Users Group</h4>
                </Col>

                {step === 0 && (
                <>
                <Col lg={12} style={{padding: '0px 25px', margin: '20px 0px'}}>

                    <button className='btn buttonVersFutureLinkGreen' onClick={() => {setModalAddUsersGroup(true)}}> + Add Users Group </button>

                    {/* -------- MENU LIST -------- */}
                    <Row className='row-users-group'>

                      {usersGroup.map((item, index) => (
                        <Col key={index} xs={3} sm={4} xxl={4} className="col-users-group" >
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <p style={{ fontSize: "22px", fontWeight: "600", color: "#374151" }}>
                                    {item.name}
                                </p>
                                  <UncontrolledDropdown>
                                      <DropdownToggle tag="button" className="btn moreVert" id="dropdownMenuButton" >
                                        <span className="material-symbols-outlined">
                                        more_vert
                                        </span>
                                      </DropdownToggle>
                                      <DropdownMenu end>
                                          <DropdownItem style={{display: 'flex', gap: '10px', fontSize: '14px', color: '#10B981'}} onClick={() => {setModalEdit(true); setIdPrivilege(item.privilege_id); getPrivilege(item.privilege_id);}}><i className='ri-pencil-fill'></i>Edit</DropdownItem>
                                          <DropdownItem style={{display: 'flex', gap: '10px', fontSize: '14px', color: '#F43F5E'}} onClick={() => {setModalDelete(true); setIdPrivilege(item.privilege_id); }}><i className='ri-delete-bin-4-fill'></i>Delete</DropdownItem>
                                      </DropdownMenu>
                                  </UncontrolledDropdown>

                            </div>
                            <div className='mb-3'>
                              <p style={{ fontSize: "12px", fontWeight: "500", color: "#4B5563" }}>
                                {item.description}
                              </p>
                            </div>

                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}  >
                                <h1 style={{ margin: "0", color: "#2A4365", fontSize: "40px" }}>
                                    
                                </h1>
                                <div>
                                    
                                </div>
                            </div>
                            <hr />
                            <span style={{ fontSize: "16px", fontWeight: "700", color: "#3B82F6", cursor: "pointer"}} onClick={() => {getAllUserPrivilege(item.privilege_id); setIdPrivilege(item.privilege_id); getPrivilege(item.privilege_id); setStep(1)}} >
                            Check Data ...
                            </span>
                        </Col>
                      ))}
                    </Row>

                </Col>
                </>
                )}

                {step === 1 && (
                <>
                <Col lg={12} style={{padding: '0px 25px', margin: '20px 0px'}}>
                    <button className='btn buttonVersFutureLinkBorderGrey' onClick={() => {setStep(0)}}>{'<-'}  Back </button>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">{getName} Table</h4>
                                </CardHeader>

                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div>
                                                    <button className="btn buttonVersFutureLinkGreen" id="create-btn" 
                                                    onClick={() => {
                                                    setModalAdd(true); 
                                                    setFirstName("");
                                                    setSurName("");
                                                    setPhone("");
                                                    setEmail("");
                                                    setPassword("");
                                                    setConfPassword("");
                                                    setGender("");
                                                    setDateBirthday("");
                                                    }}
                                                    ><i className="ri-add-line align-bottom me-1"></i> Add</button>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <table className="table align-middle table-nowrap" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                            </div>
                                                        </th>
                                                        <th className="sort" data-sort="no">No</th>
                                                        <th className="sort" data-sort="nama">Name</th>
                                                        <th className="sort" data-sort="email">Email</th>
                                                        <th className="sort" data-sort="status">Gender</th>
                                                        <th className="sort" data-sort="date-birthday">Date Birthday</th>
                                                        <th className="sort" data-sort="action">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                  {users.map((item, index) => (
                                                    <tr key={index}>
                                                        <th scope="row">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="checkAll" value="option1" />
                                                            </div>
                                                        </th>
                                                        <td className="no">{index+1}</td>
                                                        <td className="nama">{item.first_name} {item.last_name}</td>
                                                        <td className="email">{item.email}</td>
                                                        <td className="status">{item.sex}</td>
                                                        <td className="date-birthday">{new Date(item.date_birth).getFullYear() + "-" + (new Date(item.date_birth).getMonth()+1 > 9 ? new Date(item.date_birth).getMonth()+1 : "0"+(new Date(item.date_birth).getMonth()+1)) + "-" + (new Date(item.date_birth).getDate() > 9 ? new Date(item.date_birth).getDate() : "0"+new Date(item.date_birth).getDate()) }</td>
                                                        <td>
                                                            <div className="d-flex gap-2">
                                                                <div className="edit">
                                                                    <button className="btn buttonVersFutureLinkGreen"
                                                                    onClick={() => {
                                                                      setModalUpdate(true); 
                                                                      setUserId(item.user_id);
                                                                      getUserPrivilege(item.user_id)
                                                                      setFirstName("");
                                                                      setSurName("");
                                                                      setPhone("");
                                                                      setEmail("");
                                                                      setPassword("");
                                                                      setConfPassword("");
                                                                      setGender("");
                                                                      setDateBirthday("");
                                                                    }}
                                                                    >Edit</button>
                                                                </div>
                                                                <div className="remove">
                                                                    <button className="btn buttonVersFutureLinkRed"
                                                                    onClick={() => {
                                                                      setModalUserDelete(true);
                                                                      setUserId(item.user_id);
                                                                    }}
                                                                    >Remove</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        {/* <div className="d-flex justify-content-end">
                                            <div className="pagination-wrap hstack gap-2">
                                                <Link className="page-item pagination-prev disabled" to="#">
                                                    Previous
                                                </Link>
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                                <Link className="page-item pagination-next" to="#">
                                                    Next
                                                </Link>
                                            </div>
                                        </div> */}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Col>
                </>
                )}

            </Row>

            </div>

            <Modal isOpen={modalAccessDenied} toggle={() => {}} centered>
                <ModalBody className='modalCenter'> 
                    <img src={accessDenied} style={{width: '30%'}}/>
                    <p style={{fontSize: '24px', fontWeight: '700', marginTop: '20px'}}>Access Denied</p>
                    <p style={{margin: '0px', color: 'grey'}}>You currently does not have access to this page. <br/> Please Login Again</p>
                </ModalBody>
                <ModalFooter style={{display: 'flex', justifyContent: 'center', gap: '80px'}}>
                    <button className='btn buttonVersFutureLink' style={{fontSize: '14px', fontWeight: '500'}} onClick={() => {logOut()}}>Login Again</button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalAddUsersGroup} toggle={() => {setModalAddUsersGroup(!modalAddUsersGroup)}} centered>
                <ModalHeader toggle={() => {setModalAddUsersGroup(false)}}>
                    <h4>Add Users Group</h4>
                </ModalHeader>
                <ModalBody>
                  <Alert className={'alertRed ' + fade} hidden={alert}>{kontenError}</Alert>

                  <div className="mb-3">
                    <Label htmlFor="name" className="form-label">Group Name <span style={{color: 'red'}}>*</span></Label>
                    <Input type="text" className="form-control" id="name" placeholder="Enter type privilage" required maxLength={50} onChange={(event) => {setName(event.target.value)}}/>
                  </div>

                  <div className="mb-3">
                    <Label htmlFor="description" className="form-label">Description <span style={{color: 'red'}}>*</span></Label>
                    <textarea className="form-control" id="message-text" rows="4" required maxLength={256} onChange={(event) => {setDescription(event.target.value)}}></textarea>
                  </div>
              </ModalBody>
              <ModalFooter>
                  <button className='btn buttonVersFutureLinkGreen' onClick={() => {addUsersGroup()}}>Create</button>
              </ModalFooter>
            </Modal>

            <Modal isOpen={modalEdit} toggle={() => {setModalEdit(!modalEdit); getAllPrivilege()}} size='xl' centered>
                <ModalHeader toggle={() => {setModalEdit(false)}}>
                    <h4>Update Users Group</h4>
                </ModalHeader>
                <ModalBody>
                <Card>
                  <CardBody className="form-steps">
                    <form className="vertical-navs-step">
                      <Row className="gy-5">
                        <Col lg={3}>
                          <Nav className="flex-column custom-nav nav-pills" >
                            <NavItem>
                              <NavLink href="#"
                                className={
                                  (classnames({
                                    active: activeVerticalTab === 1,
                                    done: (activeVerticalTab <= 11 && activeVerticalTab > 1)
                                  }))
                                }
                                onClick={() => {
                                  toggleVerticalTab(1);
                                }}
                              >
                                <span className="step-title me-2">
                                  <i className="ri-close-circle-fill step-icon me-2"></i>
                                  Data User Group
                                </span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                              href="#"
                                className={
                                  (classnames({
                                    active: activeVerticalTab === 2,
                                    done: (activeVerticalTab <= 11 && activeVerticalTab >= 2)
                                  }))
                                }
                                onClick={() => {
                                  toggleVerticalTab(2);
                                }}
                              >
                                <span className="step-title me-2">
                                  <i className="ri-close-circle-fill step-icon me-2"></i>
                                  Update Module
                                </span>
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </Col>
                        <Col lg={9}>
                          <div className="px-lg-4">
                            <TabContent activeTab={activeVerticalTab}>
                              <TabPane tabId={1}>
                                <div>
                                  <h5>Edit</h5>
                                  <p className="text-muted">
                                    Fill all information below
                                  </p>
                                </div>

                                <div>
                                  <Row className="g-3">
                                    <Alert className={'alertRed ' + fade} hidden={alert}>{kontenError}</Alert>
                                    <Col xs={12}>
                                      <Label htmlFor="address" className="form-label">
                                        Group Name <span style={{color: 'red'}}>*</span>
                                      </Label>
                                      <Input type="text" value={getName} maxLength={50} onChange={(event) => {setGetName(event.target.value)}} className="form-control" id="address" placeholder="Super Admin" />
                                    </Col>

                                    <Col xs={12}>
                                      <Label  htmlFor="address2" className="form-label">
                                        Deskripsi <span style={{color: 'red'}}>*</span>
                                      </Label>
                                      <textarea className="form-control" value={getDescription} maxLength={50} onChange={(event) => {setGetDescription(event.target.value)}} id="message-text" rows="6" required/>
                                    </Col>
                                  </Row>
                                </div>
                              </TabPane>
                              
                              <TabPane tabId={2}>
                                <div>
                                  <h5>Module Agency</h5>
                                  <p className="text-muted">
                                    Fill all information below
                                  </p>

                                  <div className="d-flex justify-content-between mt-1 mb-1">
                                  <div className="form-check form-switch form-switch-right form-switch-md">
                                      <Label className="form-label text-muted">Create Agency</Label>
                                      <Input checked={cAgency} onChange={(event) =>  setCAgency(event.target.checked)} className="form-check-input code-switcher" type="checkbox" />
                                  </div>

                                  <div className="form-check form-switch form-switch-right form-switch-md">
                                      <Label className="form-label text-muted">Read Agency</Label>
                                      <Input checked={rAgency} onChange={(event) =>  setRAgency(event.target.checked)}  className="form-check-input code-switcher" type="checkbox" />
                                  </div>

                                  <div className="form-check form-switch form-switch-right form-switch-md">
                                      <Label className="form-label text-muted">Update Agency</Label>
                                      <Input checked={uAgency} onChange={(event) =>  setUAgency(event.target.checked)}  className="form-check-input code-switcher" type="checkbox" />
                                  </div>

                                  <div className="form-check form-switch form-switch-right form-switch-md">
                                      <Label className="form-label text-muted">Delete Agency</Label>
                                      <Input checked={dAgency} onChange={(event) =>  setDAgency(event.target.checked)}  className="form-check-input code-switcher" type="checkbox" />
                                  </div>
                                </div>
                                </div>

                                <div className="mt-4">
                                  <h5>Modul User Role / User Group</h5>
                                  <p className="text-muted">
                                    Fill all information below
                                  </p>

                                  <div className="d-flex justify-content-between mt-1 mb-1">
                                  <div className="form-check form-switch form-switch-right form-switch-md">
                                      <Label className="form-label text-muted">Create User Role</Label>
                                      <Input checked={cUserRole} onChange={(event) => setCUserRole(event.target.checked)} className="form-check-input code-switcher" type="checkbox" />
                                  </div>

                                  <div className="form-check form-switch form-switch-right form-switch-md">
                                      <Label className="form-label text-muted">Read User Role</Label>
                                      <Input checked={rUserRole} onChange={(event) => setRUserRole(event.target.checked)} className="form-check-input code-switcher" type="checkbox" />
                                  </div>

                                  <div className="form-check form-switch form-switch-right form-switch-md">
                                      <Label className="form-label text-muted">Update User Role</Label>
                                      <Input checked={uUserRole} onChange={(event) => setUUserRole(event.target.checked)} className="form-check-input code-switcher" type="checkbox" />
                                  </div>

                                  <div className="form-check form-switch form-switch-right form-switch-md">
                                      <Label className="form-label text-muted">Delete User Role</Label>
                                      <Input checked={dUserRole} onChange={(event) => setDUserRole(event.target.checked)} className="form-check-input code-switcher" type="checkbox" />
                                  </div>
                                </div>
                                </div>

                                <div className="mt-4">
                                  <h5>Modul Annotator / Fingerprints</h5>
                                  <p className="text-muted">
                                    Fill all information below
                                  </p>

                                  <div className="d-flex justify-content-between mt-1 mb-1">
                                  <div className="form-check form-switch form-switch-right form-switch-md">
                                      <Label className="form-label text-muted">Create Annotator</Label>
                                      <Input checked={cAnnotator} onChange={(event) =>  setCAnnotator(event.target.checked)} className="form-check-input code-switcher" type="checkbox" />
                                  </div>

                                  <div className="form-check form-switch form-switch-right form-switch-md">
                                      <Label className="form-label text-muted">Read Annotator</Label>
                                      <Input checked={rAnnotator} onChange={(event) =>  setRAnnotator(event.target.checked)}  className="form-check-input code-switcher" type="checkbox" />
                                  </div>

                                  <div className="form-check form-switch form-switch-right form-switch-md">
                                      <Label className="form-label text-muted">Update Annotator</Label>
                                      <Input checked={uAnnotator} onChange={(event) =>  setUAnnotator(event.target.checked)}  className="form-check-input code-switcher" type="checkbox" />
                                  </div>

                                  <div className="form-check form-switch form-switch-right form-switch-md">
                                      <Label className="form-label text-muted">Delete Annotator</Label>
                                      <Input checked={dAnnotator} onChange={(event) =>  setDAnnotator(event.target.checked)}  className="form-check-input code-switcher" type="checkbox" />
                                  </div>
                                </div>
                                </div>

                                <div className="mt-4">
                                  <h5>Modul User Detail & Modul Payment</h5>
                                  <p className="text-muted">
                                    Fill all information below
                                  </p>

                                  <div className="d-flex justify-content-between mt-1 mb-1">
                                  <div className="form-check form-switch form-switch-right form-switch-md">
                                      <Label className="form-label text-muted">Create User Detail</Label>
                                      <Input checked={cUserDetail} onChange={(event) =>  setCUserDetail(event.target.checked)}  className="form-check-input code-switcher" type="checkbox" />
                                  </div>

                                  <div className="form-check form-switch form-switch-right form-switch-md">
                                      <Label className="form-label text-muted">Read User Detail</Label>
                                      <Input checked={rUserDetail} onChange={(event) =>  setRUserDetail(event.target.checked)}  className="form-check-input code-switcher" type="checkbox" />
                                  </div>

                                  <div className="form-check form-switch form-switch-right form-switch-md">
                                      <Label className="form-label text-muted">Update User Detail</Label>
                                      <Input checked={uUserDetail} onChange={(event) =>  setUUserDetail(event.target.checked)}   className="form-check-input code-switcher" type="checkbox" />
                                  </div>

                                  <div className="form-check form-switch form-switch-right form-switch-md">
                                      <Label className="form-label text-muted">Delete User Detail</Label>
                                      <Input checked={dUserDetail} onChange={(event) =>  setDUserDetail(event.target.checked)}  className="form-check-input code-switcher" type="checkbox" />
                                  </div>
                                </div>
                                </div>

                                <div className="mt-4">
                                  <h5>Modul Package & Modul Counselor</h5>
                                  <p className="text-muted">
                                    Fill all information below
                                  </p>

                                  <div className="d-flex justify-content-between mt-1 mb-1">
                                  <div className="form-check form-switch form-switch-right form-switch-md">
                                      <Label className="form-label text-muted">Create Package</Label>
                                      <Input checked={cPackage} onChange={(event) =>  setCPackage(event.target.checked)}   className="form-check-input code-switcher" type="checkbox" />
                                  </div>

                                  <div className="form-check form-switch form-switch-right form-switch-md">
                                      <Label className="form-label text-muted">Read Package</Label>
                                      <Input checked={rPackage} onChange={(event) =>  setRPackage(event.target.checked)} className="form-check-input code-switcher" type="checkbox" />
                                  </div>

                                  <div className="form-check form-switch form-switch-right form-switch-md">
                                      <Label className="form-label text-muted">Update Package</Label>
                                      <Input checked={uPackage} onChange={(event) =>  setUPackage(event.target.checked)} className="form-check-input code-switcher" type="checkbox" />
                                  </div>

                                  <div className="form-check form-switch form-switch-right form-switch-md">
                                      <Label className="form-label text-muted">Delete Package</Label>
                                      <Input checked={dPackage} onChange={(event) =>  setDPackage(event.target.checked)} className="form-check-input code-switcher" type="checkbox" />
                                  </div>
                                </div>
                                </div>

                              </TabPane>

                            </TabContent>
                          </div>
                        </Col>
                      </Row>
                    </form>
                  </CardBody>
                </Card>
                </ModalBody>
                <ModalFooter>
                    <button className='btn buttonVersFutureLinkGreen' onClick={activeVerticalTab === 1 ? () => {updatePrivilege()} 
                    : 
                    () => {
                      updatePermission(
                        cAgency == true ? "c" : "", 
                        rAgency == true ? "r" : "", 
                        uAgency == true ? "u" : "", 
                        dAgency == true ? "d" : "",
                        cUserRole == true ? "c" : "", 
                        rUserRole == true ? "r" : "", 
                        uUserRole == true ? "u" : "", 
                        dUserRole == true ? "d" : "",
                        cAnnotator == true ? "c" : "", 
                        rAnnotator == true ? "r" : "", 
                        uAnnotator == true ? "u" : "", 
                        dAnnotator == true ? "d" : "",
                        cUserDetail == true ? "c" : "", 
                        rUserDetail == true ? "r" : "", 
                        uUserDetail == true ? "u" : "", 
                        dUserDetail == true ? "d" : "",
                        cPackage == true ? "c" : "", 
                        rPackage == true ? "r" : "", 
                        uPackage == true ? "u" : "", 
                        dPackage == true ? "d" : "",
                      )}}
                    >Save</button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalDelete} toggle={() => {setModalDelete(!modalDelete)}} centered>
                <ModalBody className='modalCenter'> 
                    <img src={deleteData}/>
                    <p style={{fontSize: '24px', fontWeight: '700', marginTop: '20px'}}>You are about to delete a user group </p>
                    <p style={{margin: '0px', color: 'grey'}}>This we will delete your User Group from FutureLink <br/> Are you sure ?</p>
                </ModalBody>
                <ModalFooter style={{display: 'flex', justifyContent: 'center', gap: '80px'}}>
                    <button className='btn buttonVersFutureLinkBorderGrey ' onClick={() => {setModalDelete(false)}}>Close</button>
                    <button className='btn buttonVersFutureLinkRed' onClick={() => {deletePrivilege()}}>Delete</button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalAdd} toggle={() => {setModalAdd(!modalAdd)}} centered>
                <ModalHeader toggle={() => {setModalAdd(false)}}>
                    <h4>Add {getName}</h4>
                </ModalHeader>
                <ModalBody>
                    <Alert className={'alertRed ' + fade} hidden={alert}>{kontenError}</Alert>
                    <div className="mb-3">
                        <Label htmlFor="name" className="form-label">First Name <span style={{color: 'red'}}>*</span></Label>
                        <Input value={firstName} onChange={(event) => setFirstName(event.target.value)} type="text" className="form-control" id="first_name" placeholder="Enter your first name" required/>
                    </div>

                    <div className="mb-3">
                        <Label htmlFor="name" className="form-label">Surname <span style={{color: 'red'}}>*</span></Label>
                        <Input value={surName} onChange={(event) => setSurName(event.target.value)} type="text" className="form-control" id="last_name" placeholder="Enter your surname" required/>
                    </div>

                    <div className="mb-3">
                        <Label htmlFor="hp" className="form-label">Phone</Label>
                        <Input value={phone} onChange={(event) => setPhone(event.target.value)} type="number" className="form-control" id="hp" placeholder="Enter your number Phone" required/>
                    </div>

                    <div className="mb-3">
                        <Label htmlFor="email" className="form-label">Email <span style={{color: 'red'}}>*</span></Label>
                        <Input value={email} onChange={(event) => setEmail(event.target.value)} type="text" className="form-control" id="email" placeholder="Enter your email" required/>
                    </div>

                    <div className="mb-3">
                        <Label htmlFor="password" className="form-label">Password <span style={{color: 'red'}}>*</span></Label>
                        <div className="position-relative auth-pass-inputgroup">
                          <Input value={password} onChange={(event) => setPassword(event.target.value)} type={show === false ? "password" : "text"} className="form-control pe-5" placeholder="Enter password" required/>
                          <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" onClick={() => {setShow(!show);}}>
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                        <p style={{color: 'red', fontSize: '12px'}}>Password must contain uppercase, lowercase, numbers, symbols, and be more than 8 characters.</p>
                    </div>

                    <div className="mb-3">
                        <Label htmlFor="confPassword" className="form-label">Confirm Password <span style={{color: 'red'}}>*</span></Label>
                        <div className="position-relative auth-pass-inputgroup">
                          <Input value={confPassword} onChange={(event) => setConfPassword(event.target.value)} type={show === false ? "password" : "text"} className="form-control pe-5" placeholder="Enter password" required/>
                          <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" onClick={() => {setShow(!show);}}>
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="gender-field" className="form-label">Gender <span style={{color: 'red'}}>*</span></label>
                        <select value={gender} onChange={(event) => setGender(event.target.value)} className="form-control" data-trigger name="Gender-field" id="Gender-field" >
                        <option value="" selected="selected" hidden="hidden">
                          Choose gender
                        </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="date-field" className="form-label">Date Birthday</label>
                        <Flatpickr
                          className="form-control"
                          options={{
                            dateFormat: "Y-m-d",
                          }}
                          onChange={(event) => setDateBirthday(event[0])}
                          placeholder="Select Date"
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className='btn buttonVersFutureLinkGreen' 
                    onClick={() => {
                      addUserPrivilege();
                    }}
                    >Save</button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalUpdate} toggle={() => {setModalUpdate(!modalUpdate)}} centered>
                <ModalHeader toggle={() => {setModalUpdate(false)}}>
                    <h4>Update {getName}</h4>
                </ModalHeader>
                <ModalBody>
                    <Alert className={'alertRed ' + fade} hidden={alert}>{kontenError}</Alert>
                    <div className="mb-3">
                        <Label htmlFor="name" className="form-label">First Name <span style={{color: 'red'}}>*</span></Label>
                        <Input value={firstName} onChange={(event) => setFirstName(event.target.value)} type="text" className="form-control" id="first_name" placeholder="Enter your first name" required/>
                    </div>

                    <div className="mb-3">
                        <Label htmlFor="name" className="form-label">Surname <span style={{color: 'red'}}>*</span></Label>
                        <Input value={surName} onChange={(event) => setSurName(event.target.value)} type="text" className="form-control" id="last_name" placeholder="Enter your surname" required/>
                    </div>

                    <div className="mb-3">
                        <Label htmlFor="hp" className="form-label">Phone </Label>
                        <Input value={phone} onChange={(event) => setPhone(event.target.value)} type="number" className="form-control" id="hp" placeholder="Enter your number Phone" required/>
                    </div>

                    <div className="mb-3">
                        <Label htmlFor="email" className="form-label">Email <span style={{color: 'red'}}>*</span></Label>
                        <Input value={email} onChange={(event) => setEmail(event.target.value)} type="text" className="form-control" id="email" placeholder="Enter your email" required/>
                    </div>
                    
                    <div className="mb-3">
                        <label htmlFor="gender-field" className="form-label">Gender <span style={{color: 'red'}}>*</span></label>
                        <select value={gender} onChange={(event) => setGender(event.target.value)} className="form-control" data-trigger name="Gender-field" id="Gender-field" >
                        <option value="" selected="selected" hidden="hidden">
                          Choose gender
                        </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="date-field" className="form-label">Date Birthday</label>
                        <Flatpickr
                          className="form-control"
                          options={{
                            dateFormat: "Y-m-d",
                          }}
                          value={dateBirthday}
                          onChange={(event) => setDateBirthday(event[0])}
                          placeholder="Select Date"
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className='btn buttonVersFutureLinkGreen' 
                    onClick={() => {
                      updateUserPrivilege()
                    }}
                    >Save</button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalUserDelete} toggle={() => {setModalUserDelete(!modalUserDelete)}} centered>
                <ModalBody className='modalCenter'> 
                    <img src={deleteData}/>
                    <p style={{fontSize: '24px', fontWeight: '700', marginTop: '20px'}}>You are about to delete a user </p>
                    <p style={{margin: '0px', color: 'grey'}}>This we will delete your User from FutureLink <br/> Are you sure ?</p>
                </ModalBody>
                <ModalFooter style={{display: 'flex', justifyContent: 'center', gap: '80px'}}>
                    <button className='btn buttonVersFutureLinkBorderGrey ' onClick={() => {setModalUserDelete(false)}}>Close</button>
                    <button className='btn buttonVersFutureLinkRed' onClick={() => {deleteUserPrivilege()}}>Delete</button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalAlertSuccess} toggle={() => {setModalAlertSuccess(!modalAlertSuccess)}} centered>
                <ModalBody className='modalCenter' style={{margin: '0px'}}>
                    <Lottie options={animationSuccess} height={350} width={350} isClickToPauseDisabled={true} />
                </ModalBody>
            </Modal>

            <Modal isOpen={modalAlertDelete} toggle={() => {setModalAlertDelete(!modalAlertDelete)}} centered>
                <ModalBody className='modalCenter' style={{margin: '0px'}}>
                    <Lottie options={animationDelete} height={350} width={350} isClickToPauseDisabled={true} />
                </ModalBody>
            </Modal>

            <Modal isOpen={modalNoDelete} toggle={() => {setModalNoDelete(!modalNoDelete)}} centered>
                <ModalBody className='modalCenter' style={{margin: '0px'}}>
                    <h3>Cannot be deleted, there is still data users available.</h3>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default UsersGroup;
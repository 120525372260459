import React, {useState} from "react";
import {Row, Col, Progress} from 'reactstrap'
import {Link} from 'react-router-dom'

import vis from '../../../../../assets/images/FutureLink/vis.svg';
import kin from '../../../../../assets/images/FutureLink/kin.svg';
import aud from '../../../../../assets/images/FutureLink/aud.png';


const GayaBelajar = (e) => {
    const state = e.state.data
    const data = e.state.data
    const package_id = e.state.package_id
    const img_finger = e.state.img_finger
    const shape_finger = e.state.shape_finger
    const [dom, setDom] = useState('Visual')

    return(
        <div className="corev2 dom" style={{height: '90vh'}}>
            <div className="appearOnMobile tiwtiw" style={{marginBottom: '24px'}}>
                <Link to={{pathname: '/client/reportFP', state: { data: data, package_id: package_id, img_finger:img_finger, shape_finger:shape_finger },}} className="bBek">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M10.295 19.7155C10.3886 19.8077 10.4994 19.8806 10.6211 19.93C10.7429 19.9793 10.8731 20.0042 11.0045 20.0033C11.1358 20.0023 11.2657 19.9754 11.3867 19.9243C11.5076 19.8731 11.6173 19.7986 11.7095 19.705C11.8017 19.6115 11.8746 19.5007 11.9239 19.379C11.9733 19.2572 11.9982 19.127 11.9972 18.9956C11.9963 18.8643 11.9694 18.7344 11.9183 18.6134C11.8671 18.4925 11.7926 18.3827 11.699 18.2906L6.32903 13.0006H19.999C20.2642 13.0006 20.5186 12.8952 20.7061 12.7077C20.8937 12.5201 20.999 12.2658 20.999 12.0006C20.999 11.7353 20.8937 11.481 20.7061 11.2934C20.5186 11.1059 20.2642 11.0006 19.999 11.0006H6.33603L11.7 5.71355C11.884 5.52633 11.987 5.2743 11.9867 5.01183C11.9865 4.74936 11.8831 4.49751 11.6988 4.31061C11.5145 4.12371 11.2642 4.01675 11.0017 4.01281C10.7393 4.00887 10.4858 4.10827 10.296 4.28955L3.37203 11.1116C3.25411 11.2278 3.16048 11.3664 3.09657 11.5192C3.03267 11.672 2.99976 11.8359 2.99976 12.0016C2.99976 12.1672 3.03267 12.3311 3.09657 12.4839C3.16048 12.6367 3.25411 12.7753 3.37203 12.8916L10.296 19.7146L10.295 19.7155Z" fill="#374151"/>
                </svg>
                </Link>
                <p className="antitank" style={{textAlign: 'center'}}>Gaya Belajar</p>
            </div>
            {dom == 'Visual' && (
                <>
            <div className="doVi">
                <div className="gbD">
                    <div className="doNi appearOnMobile">
                        <div className='gbVx'>
                            <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Nilai Visual Anda</p>
                            <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{state.visual_learning}%</p>
                        </div>
                    </div>
                    <p><b>Visual</b> adalah gaya belajar dengan melihat. Orang yang gaya belajarnya visual memiliki kemampuan yang tinggi untuk     mengingat gambaran sesuatu. Mereka terbiasa menggunakan representasi visual seperti grafik, poster, peta, serta lain sebagainya.</p>
                    <ul className="goneOnMobile">
                        <li>Perbaikan <b>diri sendiri</b> untuk menunjang gaya belajar visual adalah dengan mencari sumber materi visual alternatif saat belajar, membuat catatan dengan pena berwarna, serta mencoba mengilustrasikan ide.</li>
                        <li>Cara mendukung seseorang yang tipe belajarnya visual dari sisi <b>guru</b> adalah dengan menghindari gangguan visual di tempat duduk di dalam kelas, belajar dengan peta konsep, membuat catatan terpadu atau dokumen ringkasan setelah belajar, serta mengilustrasikan catatan dengan gambar atau grafik.</li>
                        <li>Cara mendukung seseorang yang tipe belajarnya visual dari sisi <b>orang tua</b> adalah dengan menyediakan tempat yang tenang untuk belajar, terlibat dengan anak secara langsung dan melakukan kontak mata setiap berkomunikasi, serta memfasilitasi pembelajaran dengan memberikan alat pendukung (misalnya spidol warna atau stabilo).</li>
                    </ul>
                    <div className="doNi goneOnMobile" style={{marginTop: '50px'}}>
                        <div className='gayabelajarBoxValue'>
                            <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Nilai Visual Anda</p>
                            <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{state.visual_learning}%</p>
                        </div>
                    </div>
                </div>

                <div className="doBut">
                    <button className="buttonTest" onClick={() => {setDom('Kinestetik')}}>Selanjutnya </button>
                </div>

            </div>
            <img src={vis} className="visimg goneOnMobile"/>
                </>
            )}
            {dom == 'Kinestetik' && (
                <>
            <div className="doVi">
                <div className="gbD">
                    <div className="doNi appearOnMobile">
                        <div className='gbVx'>
                            <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Nilai Kinestetik Anda</p>
                            <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{state.kinesthetic_learning}%</p>
                        </div>
                    </div>
                    <p><b>Kinestetik</b> adalah gaya belajar dengan melakukan. Orang yang gaya belajarnya kinestetik sangat bergantung pada interaksi dalam lingkungan belajar terutama dengan tubuh mereka. Mereka terbiasa melakukan aktivitas fisik, memanipulasi objek, melakukan kunjungan lapangan dan lain sebagainya.</p>
                    <ul className="goneOnMobile">
                        <li>Perbaikan <b>diri sendiri</b> untuk menunjang gaya belajar kinestetik adalah dengan memperagakan sesuatu yang sedang dipelajari, menggunakan benda konkret untuk membantu memahami konsep, melakukan gerakan yang tidak mengganggu ketika belajar (misalnya menggoyangkan kaki atau memegang sesuatu), berdiskusi secara kelompok, serta pendapatkan pengalaman langsung di lapangan.</li>
                        <li>Cara mendukung seseorang yang tipe belajarnya kinestetik dari sisi <b>guru</b> adalah dengan melakukan pembelajaran di luar kelas, melakukan simulasi untuk pembelajaran tertentu, melakukan praktik atau kunjungan lapangan, serta menggunakan peralatan penunjang pembelajaran.</li>
                        <li>Cara mendukung seseorang yang tipe belajarnya kinestetik dari sisi <b>orang tua</b> adalah dengan mengajak anak untuk belajar sambil mengeksplorasi lingkungan sekitarnya, tidak memaksakan anak duduk berjam-jam untuk belajar, menggunakan alat peraga untuk membantu anak belajar, serta biarkan anak bergerak untuk memahami sendiri apa yang dapat dipelajarinya. </li>
                    </ul>
                    <div className="doNi goneOnMobile" style={{marginTop: '50px'}}>
                        <div className='gayabelajarBoxValue'>
                            <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Nilai Kinestetik Anda</p>
                            <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{state.kinesthetic_learning}%</p>
                        </div>
                    </div>
                </div>

                <div className="doBut">
                    <button className="buttonTest" style={{marginRight: '12px'}} onClick={() => {setDom('Visual')}}>Sebelumnya</button>
                    <button className="buttonTest" onClick={() => {setDom('Auditory')}}>Selanjutnya </button>
                </div>

            </div>
            <img src={kin} className="visimg goneOnMobile"/>
                </>
            )}
            {dom == 'Auditory' && (
                <>
            <div className="doVi">
                <div className="gbD">
                    <div className="doNi appearOnMobile">
                        <div className='gbVx'>
                            <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Nilai Auditory Anda</p>
                            <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{state.auditory_learning}%</p>
                        </div>
                    </div>
                    <p><b>Auditori</b> adalah gaya belajar dengan mendengarkan. Orang yang gaya belajarnya auditori memiliki kemampuan yang tinggi untuk mengingat apa yang didengarnya. Mereka terbiasa melakukan pengulangan dan membuat ringkasan, berdiskusi, bercerita, serta lain sebagainya.</p>
                    <ul className="goneOnMobile">
                        <li>Perbaikan <b>diri sendiri</b> untuk menunjang gaya belajar auditori adalah dengan berpartisipasi dalam kegiatan kelompok, mengulang pembelajaran dengan presentasi lisan, menggunakan recorder untuk merekam pembelajaran, serta menjelaskan ide kepada orang lain.</li>
                        <li>Cara mendukung seseorang yang tipe belajarnya auditori dari sisi <b>guru</b> adalah dengan menjelaskan pembelajaran secara lantang, menggunakan program komputer yang interaktif dengan siswa, membuat pembelajaran dalam bentuk video, serta memberikan waktu untuk diskusi dan kegiatan kelompok.</li>
                        <li>Cara mendukung seseorang yang tipe belajarnya auditori dari sisi <b>orang tua</b> adalah dengan menjawab semua pertanyaan anak secara lisan, mengucapkan informasi dengan lantang, berdiskusi dengan anak, serta membantu anak mengulangi pembelajaran dengan melakukan tanya-jawab. </li>
                    </ul>
                    <div className="doNi goneOnMobile" style={{marginTop: '50px'}}>
                        <div className='gayabelajarBoxValue'>
                            <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Nilai Auditory Anda</p>
                            <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{state.auditory_learning}%</p>
                        </div>
                    </div>
                </div>

                <div className="doBut">
                    <button className="buttonTest" onClick={() => {setDom('Kinestetik')}}>Sebelumnya</button>
                </div>

            </div>
            <img src={aud} className="visimg goneOnMobile"/>
                </>
            )}

        </div>
    )
}

export default GayaBelajar
import React from "react";
import { Redirect } from "react-router-dom";

// ------- Future Link ------- //
import Dashboard from "../pages/FutureLink/Dashboard";

import Profile from "../pages/FutureLink/Profile";

import UsersGroup from "../pages/FutureLink/UsersGroup";
import SuperAdmin from "../pages/FutureLink/UsersGroup/SuperAdmin";
import Admin from "../pages/FutureLink/UsersGroup/Admin";
import FPTaker from "../pages/FutureLink/UsersGroup/FPTaker";
import Annotator from "../pages/FutureLink/UsersGroup/Annotator";
import Client from "../pages/FutureLink/UsersGroup/Client";

import Home from "../pages/FutureLink/ForClient/Home/index.js";
import ListVideo from "../pages/FutureLink/ForClient/Home/listVideo.js";
import ListComplementary from "../pages/FutureLink/ForClient/Home/listComplementary.js";

import Report from "../pages/FutureLink/ForClient/Report/index.js";
import PolaSidikJari from "../pages/FutureLink/ForClient/Report/polaSidikJari.js";
import DistribusiNeuron from "../pages/FutureLink/ForClient/Report/distribusiNeuron.js";
import Tfrc from "../pages/FutureLink/ForClient/Report/tfrc.js";
import DominasiOtak from "../pages/FutureLink/ForClient/Report/dominasiOtak.js";
import GayaBelajar from "../pages/FutureLink/ForClient/Report/gayaBelajar.js";
import GayaBerpikir from "../pages/FutureLink/ForClient/Report/gayaBerpikir.js";
import Ekstrakulikuler from "../pages/FutureLink/ForClient/Report/ekstrakulikuler.js";
import Penjurusan from "../pages/FutureLink/ForClient/Report/penjurusan.js";
import IndraDasar from "../pages/FutureLink/ForClient/Report/indraDasar.js";
import MinatKejuruan from "../pages/FutureLink/ForClient/Report/minatKejuruan.js";
import KecerdasanMajemuk from "../pages/FutureLink/ForClient/Report/kecerdasanMajemuk.js";
import DomainKecerdasan from "../pages/FutureLink/ForClient/Report/domainKecerdasan.js";
import DISC from "../pages/FutureLink/ForClient/Report/disc.js";
import DOPE from "../pages/FutureLink/ForClient/Report/dope.js";
import KonsepPemikiran from "../pages/FutureLink/ForClient/Report/konsepPemikiran.js";
import Kecerdasan from "../pages/FutureLink/ForClient/Report/kecerdasan.js";
import LobusOtak from "../pages/FutureLink/ForClient/Report/lobusOtak.js";

import ReportAvailable from "../pages/FutureLink/ForClient/Report/Mobile/report.js";
import ReportMobile from "../pages/FutureLink/ForClient/Report/Mobile/mobileReport.js";
import PolaSidikJariM from "../pages/FutureLink/ForClient/Report/Mobile/polaSidikJari.js";
import DistribusiNeuronM from "../pages/FutureLink/ForClient/Report/Mobile/distribusiNeuron.js";
import TfrcM from "../pages/FutureLink/ForClient/Report/Mobile/tfrc.js";
import DominasiOtakM from "../pages/FutureLink/ForClient/Report/Mobile/dominasiOtak.js";
import GayaBelajarM from "../pages/FutureLink/ForClient/Report/Mobile/gayaBelajar.js";
import GayaBerpikirM from "../pages/FutureLink/ForClient/Report/Mobile/gayaBerpikir.js";
import EkstrakulikulerM from "../pages/FutureLink/ForClient/Report/Mobile/ekstrakulikuler.js";
import PenjurusanM from "../pages/FutureLink/ForClient/Report/Mobile/penjurusan.js";
import IndraDasarM from "../pages/FutureLink/ForClient/Report/Mobile/indraDasar.js";
import MinatKejuruanM from "../pages/FutureLink/ForClient/Report/Mobile/minatKejuruan.js";
import KecerdasanMajemukM from "../pages/FutureLink/ForClient/Report/Mobile/kecerdasanMajemuk.js";
import DomainKecerdasanM from "../pages/FutureLink/ForClient/Report/Mobile/domainKecerdasan.js";
import DISCM from "../pages/FutureLink/ForClient/Report/Mobile/disc.js";
import DOPEM from "../pages/FutureLink/ForClient/Report/Mobile/dope.js";
import KonsepPemikiranM from "../pages/FutureLink/ForClient/Report/Mobile/konsepPemikiran.js";
import KecerdasanM from "../pages/FutureLink/ForClient/Report/Mobile/kecerdasan.js";
import LobusOtakM from "../pages/FutureLink/ForClient/Report/Mobile/lobusOtak.js";
import Summary from "../pages/FutureLink/ForClient/Report/Mobile/summary.js";
import Summary2 from "../pages/FutureLink/ForClient/Report/Mobile/summary2.js";
import Summary3 from "../pages/FutureLink/ForClient/Report/Mobile/summary3.js";
import Summary4 from "../pages/FutureLink/ForClient/Report/Mobile/summary4.js";

import Purchase from "../pages/FutureLink/ForClient/Purchase/index.js";
import Success from "../pages/FutureLink/ForClient/Purchase/success.js";
import Failed from "../pages/FutureLink/ForClient/Purchase/failed.js";

//page default
import ModulTest from "../pages/FutureLink/Default/ModulTest.js";
import ModulAnnotator from "../pages/FutureLink/Default/ModulAnnotator.js";
import TableUserDefault from "../pages/FutureLink/Default/TableUserDefault";
import ListOfImages from "../pages/FutureLink/Default/ListOfImages";

import TableUserAccRetakeImage from "../pages/FutureLink/Default/TableUserAccRetakeImage";
import ListOfImagesAccRetake from "../pages/FutureLink/Default/ListOfImagesAccRetake";
import GenerateReportManual from "../pages/FutureLink/Default/GenerateReportManual.js";

import TableUserDefaultFpTaker from "../pages/FutureLink/Default/TableUserDefaultFpTaker";
import ListOfImagesFpTaker from "../pages/FutureLink/Default/ListOfImagesFpTaker";
import ListOfImagesFingerprintTaker from "../pages/FutureLink/Default/ListOfImageFingerprintTaker.js";

import ModulPackage from "../pages/FutureLink/Default/ModulPackage.js";
import ModulConcelors from "../pages/FutureLink/Default/ModulConcelors.js";
import ModulAgency from "../pages/FutureLink/Default/ModulAgency.js";
import ModulArticle from "../pages/FutureLink/Default/ModulArticle.js";

import ReadArticle from "../pages/FutureLink/ForClient/Home/readArticle.js";

// Complementary Test
import Complementary from "../pages/FutureLink/ForClient/Test/index.js";
import Soal from "../pages/FutureLink/ForClient/Test/soal.js";
import SoalTrial from "../pages/FutureLink/ForClient/Test/SoalTrial.js";
import SoalTrialNugi from "../pages/FutureLink/ForClient/Test/SoalTrialNugi.js";

// Dashboard V3 For Client
import ClientDashboard from "../pages/FutureLink/NewDashboardForClientV3/dashboardClient.js";
import ClientListReading from "../pages/FutureLink/NewDashboardForClientV3/listReadingClient.js";
import ClientReport from "../pages/FutureLink/NewDashboardForClientV3/reportClient.js";
import ClientPurchase from "../pages/FutureLink/NewDashboardForClientV3/purchaseClient.js";
import ClientComplementary from "../pages/FutureLink/NewDashboardForClientV3/complementaryClient.js";
import ClientProfile from "../pages/FutureLink/NewDashboardForClientV3/profileClient.js";

import ClientProcessPurchase from "../pages/FutureLink/NewDashboardForClientV3/processPurchaseClient.js";

import ClientFPReport from "../pages/FutureLink/NewDashboardForClientV3/reportFPClient.js";
import ClientSPMReport from "../pages/FutureLink/NewDashboardForClientV3/reportSPMClient.js";

import ReportPolaSidikJari from "../pages/FutureLink/NewDashboardForClientV3/HasilReportSidikJari/apolaSidikJari.js";
import ReportDistribusiNeuron from "../pages/FutureLink/NewDashboardForClientV3/HasilReportSidikJari/bdistribusiNeuron.js";
import ReportTfrc from "../pages/FutureLink/NewDashboardForClientV3/HasilReportSidikJari/cTfrc.js";
import ReportDominasiOtak from "../pages/FutureLink/NewDashboardForClientV3/HasilReportSidikJari/dDominasiOtak.js";
import ReportGayaBelajar from "../pages/FutureLink/NewDashboardForClientV3/HasilReportSidikJari/eGayaBelajar.js";
import ReportEkstrakulikuler from "../pages/FutureLink/NewDashboardForClientV3/HasilReportSidikJari/fEkstrakulikuler.js";
import ReportPemilihanJurusan from "../pages/FutureLink/NewDashboardForClientV3/HasilReportSidikJari/gPemilihanJurusan.js";
import ReportKecerdasan from "../pages/FutureLink/NewDashboardForClientV3/HasilReportSidikJari/hKecerdasan.js";
import ReportIndraDasar from "../pages/FutureLink/NewDashboardForClientV3/HasilReportSidikJari/iIndraDasar.js";
import ReportGayaBerpikir from "../pages/FutureLink/NewDashboardForClientV3/HasilReportSidikJari/jGayaBerpikir.js";
import ReportMinatStudi from "../pages/FutureLink/NewDashboardForClientV3/HasilReportSidikJari/kMinatStudi.js";
import ReportKecerdasanMajemuk from "../pages/FutureLink/NewDashboardForClientV3/HasilReportSidikJari/lKecerdasanMajemuk.js";
import ReportDomainKecerdasan from "../pages/FutureLink/NewDashboardForClientV3/HasilReportSidikJari/mDomainKecerdasan.js";
import ReportDisc from "../pages/FutureLink/NewDashboardForClientV3/HasilReportSidikJari/nDisc.js";
import ReportDope from "../pages/FutureLink/NewDashboardForClientV3/HasilReportSidikJari/oDope.js";
import ReportKonsepPemikiran from "../pages/FutureLink/NewDashboardForClientV3/HasilReportSidikJari/pKonsepPemikiran.js";
import ReportSummary from "../pages/FutureLink/NewDashboardForClientV3/HasilReportSidikJari/qSummary.js";
import ReportLobusOtak from "../pages/FutureLink/NewDashboardForClientV3/HasilReportSidikJari/rLobusOtak.js";
import ReportSPMClient from "../pages/FutureLink/NewDashboardForClientV3/MenuDashboard/reportSPM.js";



//page default

// //Dashboard
// import DashboardAnalytics from "../pages/DashboardAnalytics";
// import DashboardCrm from "../pages/DashboardCrm";
// import DashboardEcommerce from "../pages/DashboardEcommerce";

// import DashboardCrypto from "../pages/DashboardCrypto";
// import DashboardProject from "../pages/DashboardProject";

// //Calendar
// // Email box
// import MailInbox from "../pages/EmailInbox";

// //CHat
// import Chat from "../pages/Chat";
// import Calendar from "../pages/Calendar";

// // Project
// import ProjectList from "../pages/Projects/ProjectList";
// import ProjectOverview from "../pages/Projects/ProjectOverview";
// import CreateProject from "../pages/Projects/CreateProject";

// //Task
// import TaskDetails from "../pages/Tasks/TaskDetails";
// import TaskList from "../pages/Tasks/TaskList";
// import KanbanBoard from "../pages/Tasks/KanbanBoard/Index";

// //Transactions
// import Transactions from '../pages/Crypto/Transactions';
// import BuySell from '../pages/Crypto/BuySell';
// import CryproOrder from '../pages/Crypto/CryptoOrder';
// import MyWallet from '../pages/Crypto/MyWallet';
// import ICOList from '../pages/Crypto/ICOList';
// import KYCVerification from '../pages/Crypto/KYCVerification';

// //Crm Pages
// import CrmCompanies from "../pages/Crm/CrmCompanies";
// import CrmContacts from "../pages/Crm/CrmContacts";
// import CrmDeals from "../pages/Crm/CrmDeals/index";
// import CrmLeads from "../pages/Crm/CrmLeads/index";

// //Invoices
// import InvoiceList from "../pages/Invoices/InvoiceList";
// import InvoiceCreate from "../pages/Invoices/InvoiceCreate";
// import InvoiceDetails from "../pages/Invoices/InvoiceDetails";

// // Support Tickets
// import ListView from '../pages/SupportTickets/ListView';
// import TicketsDetails from '../pages/SupportTickets/TicketsDetails';

// // //Ecommerce Pages
// import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index";
// import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail";
// import EcommerceAddProduct from "../pages/Ecommerce/EcommerceProducts/EcommerceAddProduct";
// import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index";
// import EcommerceOrderDetail from "../pages/Ecommerce/EcommerceOrders/EcommerceOrderDetail";
// import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index";
// import EcommerceCart from "../pages/Ecommerce/EcommerceCart";
// import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout";
// import EcommerceSellers from "../pages/Ecommerce/EcommerceSellers/index";
// import EcommerceSellerDetail from "../pages/Ecommerce/EcommerceSellers/EcommerceSellerDetail";

// // Base Ui
// import UiAlerts from "../pages/BaseUi/UiAlerts/UiAlerts";
// import UiBadges from "../pages/BaseUi/UiBadges/UiBadges";
// import UiButtons from "../pages/BaseUi/UiButtons/UiButtons";
// import UiColors from "../pages/BaseUi/UiColors/UiColors";
// import UiCards from "../pages/BaseUi/UiCards/UiCards";
// import UiCarousel from "../pages/BaseUi/UiCarousel/UiCarousel";
// import UiDropdowns from "../pages/BaseUi/UiDropdowns/UiDropdowns";
// import UiGrid from "../pages/BaseUi/UiGrid/UiGrid";
// import UiImages from "../pages/BaseUi/UiImages/UiImages";
// import UiTabs from "../pages/BaseUi/UiTabs/UiTabs";
// import UiAccordions from "../pages/BaseUi/UiAccordion&Collapse/UiAccordion&Collapse";
// import UiModals from "../pages/BaseUi/UiModals/UiModals";
// import UiOffcanvas from "../pages/BaseUi/UiOffcanvas/UiOffcanvas";
// import UiPlaceholders from "../pages/BaseUi/UiPlaceholders/UiPlaceholders";
// import UiProgress from "../pages/BaseUi/UiProgress/UiProgress";
// import UiNotifications from "../pages/BaseUi/UiNotifications/UiNotifications";
// import UiMediaobject from "../pages/BaseUi/UiMediaobject/UiMediaobject";
// import UiEmbedVideo from "../pages/BaseUi/UiEmbedVideo/UiEmbedVideo";
// import UiTypography from "../pages/BaseUi/UiTypography/UiTypography";
// import UiList from "../pages/BaseUi/UiLists/UiLists";
// import UiGeneral from "../pages/BaseUi/UiGeneral/UiGeneral";
// import UiRibbons from "../pages/BaseUi/UiRibbons/UiRibbons";
// import UiUtilities from "../pages/BaseUi/UiUtilities/UiUtilities";

// // Advance Ui
// import UiNestableList from "../pages/AdvanceUi/UiNestableList/UiNestableList";
// import UiScrollbar from "../pages/AdvanceUi/UiScrollbar/UiScrollbar";
// import UiAnimation from "../pages/AdvanceUi/UiAnimation/UiAnimation";
// import UiTour from "../pages/AdvanceUi/UiTour/UiTour";
// import UiSwiperSlider from "../pages/AdvanceUi/UiSwiperSlider/UiSwiperSlider";
// import UiRatings from "../pages/AdvanceUi/UiRatings/UiRatings";
// import UiHighlight from "../pages/AdvanceUi/UiHighlight/UiHighlight";

// // Widgets
// import Widgets from '../pages/Widgets/Index';

// //Forms
// import BasicElements from "../pages/Forms/BasicElements/BasicElements";
// import FormSelect from "../pages/Forms/FormSelect/FormSelect";
// import FormEditor from "../pages/Forms/FormEditor/FormEditor";
// import CheckBoxAndRadio from "../pages/Forms/CheckboxAndRadio/CheckBoxAndRadio";
// import Masks from "../pages/Forms/Masks/Masks";
// import FileUpload from "../pages/Forms/FileUpload/FileUpload";
// import FormPickers from "../pages/Forms/FormPickers/FormPickers";
// import FormRangeSlider from "../pages/Forms/FormRangeSlider/FormRangeSlider";
// import Formlayouts from "../pages/Forms/FormLayouts/Formlayouts";
// import FormValidation from "../pages/Forms/FormValidation/FormValidation";
// import FormWizard from "../pages/Forms/FormWizard/FormWizard";
// import FormAdvanced from "../pages/Forms/FormAdvanced/FormAdvanced";

// //Tables
// import BasicTables from '../pages/Tables/BasicTables/BasicTables';
// import GridTables from '../pages/Tables/GridTables/GridTables';
// import ListTables from '../pages/Tables/ListTables/ListTables';

// //Icon pages
// import RemixIcons from "../pages/Icons/RemixIcons/RemixIcons";
// import BoxIcons from "../pages/Icons/BoxIcons/BoxIcons";
// import MaterialDesign from "../pages/Icons/MaterialDesign/MaterialDesign";
// import FeatherIcons from "../pages/Icons/FeatherIcons/FeatherIcons";
// import LineAwesomeIcons from "../pages/Icons/LineAwesomeIcons/LineAwesomeIcons";

// //Maps
// import GoogleMaps from "../pages/Maps/GoogleMaps/GoogleMaps";
// import VectorMaps from "../pages/Maps/VectorMaps/VectorMaps";
// import LeafletMaps from "../pages/Maps/LeafletMaps/LeafletMaps";

// //AuthenticationInner pages
import BasicSignIn from "../pages/AuthenticationInner/Login/BasicSignIn";
import CoverSignIn from "../pages/AuthenticationInner/Login/CoverSignIn";
import Forgot from "../pages/AuthenticationInner/Login/Forgot.js";
import BasicSignUp from "../pages/AuthenticationInner/Register/BasicSignUp";
import CoverSignUp from "../pages/AuthenticationInner/Register/CoverSignUp";
import BasicPasswReset from "../pages/AuthenticationInner/PasswordReset/BasicPasswReset";
// //pages
// import Starter from '../pages/Pages/Starter/Starter';
// import SimplePage from '../pages/Pages/Profile/SimplePage/SimplePage';
// import Settings from '../pages/Pages/Profile/Settings/Settings';
// import Team from '../pages/Pages/Team/Team';
// import Timeline from '../pages/Pages/Timeline/Timeline';
// import Faqs from '../pages/Pages/Faqs/Faqs';
// import Pricing from '../pages/Pages/Pricing/Pricing';
// import Gallery from '../pages/Pages/Gallery/Gallery';
// import Maintenance from '../pages/Pages/Maintenance/Maintenance';
// import ComingSoon from '../pages/Pages/ComingSoon/ComingSoon';
// import SiteMap from '../pages/Pages/SiteMap/SiteMap';
// import SearchResults from '../pages/Pages/SearchResults/SearchResults';

// import CoverPasswReset from '../pages/AuthenticationInner/PasswordReset/CoverPasswReset';
// import BasicLockScreen from '../pages/AuthenticationInner/LockScreen/BasicLockScr';
// import CoverLockScreen from '../pages/AuthenticationInner/LockScreen/CoverLockScr';
// import BasicLogout from '../pages/AuthenticationInner/Logout/BasicLogout';
// import CoverLogout from '../pages/AuthenticationInner/Logout/CoverLogout';
// import BasicSuccessMsg from '../pages/AuthenticationInner/SuccessMessage/BasicSuccessMsg';
// import CoverSuccessMsg from '../pages/AuthenticationInner/SuccessMessage/CoverSuccessMsg';
// import BasicTwosVerify from '../pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify';
// import CoverTwosVerify from '../pages/AuthenticationInner/TwoStepVerification/CoverTwosVerify';
// import Basic404 from '../pages/AuthenticationInner/Errors/Basic404';
// import Cover404 from '../pages/AuthenticationInner/Errors/Cover404';
// import Alt404 from '../pages/AuthenticationInner/Errors/Alt404';
// import Error500 from '../pages/AuthenticationInner/Errors/Error500';

// import BasicPasswCreate from "../pages/AuthenticationInner/PasswordCreate/BasicPasswCreate";
// import CoverPasswCreate from "../pages/AuthenticationInner/PasswordCreate/CoverPasswCreate";
// import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";

// //login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// //Charts
// import LineCharts from "../pages/Charts/ApexCharts/LineCharts";
// import AreaCharts from "../pages/Charts/ApexCharts/AreaCharts";
// import ColumnCharts from "../pages/Charts/ApexCharts/ColumnCharts";
// import BarCharts from "../pages/Charts/ApexCharts/BarCharts";
// import MixedCharts from "../pages/Charts/ApexCharts/MixedCharts";
// import TimelineCharts from "../pages/Charts/ApexCharts/TimelineCharts";
// import CandlestickChart from "../pages/Charts/ApexCharts/CandlestickChart";
// import BoxplotCharts from "../pages/Charts/ApexCharts/BoxplotCharts";
// import BubbleChart from "../pages/Charts/ApexCharts/BubbleChart";
// import ScatterCharts from "../pages/Charts/ApexCharts/ScatterCharts";
// import HeatmapCharts from "../pages/Charts/ApexCharts/HeatmapCharts";
// import TreemapCharts from "../pages/Charts/ApexCharts/TreemapCharts";
// import PieCharts from "../pages/Charts/ApexCharts/PieCharts";
// import RadialbarCharts from "../pages/Charts/ApexCharts/RadialbarCharts";
// import RadarCharts from "../pages/Charts/ApexCharts/RadarCharts";
// import PolarCharts from "../pages/Charts/ApexCharts/PolarCharts";

// import ChartsJs from "../pages/Charts/ChartsJs/index";
// import Echarts from "../pages/Charts/ECharts/index";

// Landing Index
import Index from "../pages/Landing";
import NewPassword from "../pages/AuthenticationInner/Login/NewPassword";
import ListArticle from "../pages/FutureLink/ForClient/Home/listArticle";
import CounselorPlayground from "../pages/FutureLink/Default/CounselorPlayground.js";

const authProtectedRoutes = [
  //new route
  { path: "/profile", component: Profile },
  { path: "/dashboard", component: Dashboard },
  { path: "/users-group", component: UsersGroup },
  { path: "/users-group-super-admin", component: SuperAdmin },
  { path: "/users-group-admin", component: Admin },
  { path: "/users-group-fp-taker", component: FPTaker },
  { path: "/users-group-annotator", component: Annotator },
  { path: "/users-group-client", component: Client },

  { path: "/playground-counselor", component: CounselorPlayground },

  { path: "/home", component: Home },
  { path: "/report", component: Report },
  { path: "/report/pola-sidik-jari", component: PolaSidikJari },
  { path: "/report/distribusi-neuron", component: DistribusiNeuron },
  { path: "/report/tfrc", component: Tfrc },
  { path: "/report/dominasi-otak", component: DominasiOtak },
  { path: "/report/gaya-belajar", component: GayaBelajar },
  { path: "/report/gaya-berpikir", component: GayaBerpikir },
  { path: "/report/ekstrakulikuler", component: Ekstrakulikuler },
  { path: "/report/penjurusan", component: Penjurusan },
  { path: "/report/indra-dasar", component: IndraDasar },
  { path: "/report/minat-kejuruan", component: MinatKejuruan },
  { path: "/report/kecerdasan-majemuk", component: KecerdasanMajemuk },
  { path: "/report/domain-kecerdasan", component: DomainKecerdasan },
  { path: "/report/disc", component: DISC },
  { path: "/report/dope", component: DOPE },
  { path: "/report/konsep-pemikiran", component: KonsepPemikiran },
  { path: "/report/kecerdasan", component: Kecerdasan },
  { path: "/report/lobus-otak", component: LobusOtak },
  { path: "/purchase", component: Purchase },

  { path: "/complementary", component: Complementary },
  // {path: "/kelola-agency", component: TableKelolaAgency},
  // {path: "/kelola-concelors", component: TableUserKelolaConcelors},

  //routePageDefault
  { path: "/users-group/modul-test", component: ModulTest },
  { path: "/users-group/modul-annotator", component: ModulAnnotator },
  { path: "/users-group/modul-package", component: ModulPackage },
  { path: "/users-group/modul-concelors", component: ModulConcelors },
  { path: "/users-group/modul-agency", component: ModulAgency },
  { path: "/users-group/modul-article", component: ModulArticle },
  { path: "/users-group/table-user-default", component: TableUserDefault },
  { path: "/users-group/list-of-images", component: ListOfImages },

  {
    path: "/users-group/table-user-acc-retake-image",
    component: TableUserAccRetakeImage,
  },
  {
    path: "/users-group/list-of-images-acc-retake",
    component: ListOfImagesAccRetake,
  },
  {
    path: "/users-group/generate-report-manual",
    component: GenerateReportManual,
  },

  {
    path: "/users-group/table-user-default-fp-taker",
    component: TableUserDefaultFpTaker,
  },
  {
    path: "/users-group/list-of-images-fp-taker",
    component: ListOfImagesFpTaker,
  },

  {
    path: "/users-group/list-of-images-fingerprint-taker",
    component: ListOfImagesFingerprintTaker,
  },

  {
    path: "/listVideo",
    component: ListVideo,
  },

  {
    path: "/listArticle",
    component: ListArticle,
  },

  {
    path: "/listComplementary",
    component: ListComplementary,
  },

  //end new route

  // { path: "/dashboard-analytics", component: DashboardAnalytics },
  // { path: "/dashboard-crm", component: DashboardCrm },
  // { path: "/dashboard", component: DashboardEcommerce },
  // { path: "/dashboard-crypto", component: DashboardCrypto },
  // { path: "/dashboard-projects", component: DashboardProject },
  // { path: "/apps-calendar", component: Calendar },
  // { path: "/apps-ecommerce-products", component: EcommerceProducts },
  // { path: "/apps-ecommerce-product-details", component: EcommerceProductDetail },
  // { path: "/apps-ecommerce-add-product", component: EcommerceAddProduct },
  // { path: "/apps-ecommerce-orders", component: EcommerceOrders },
  // { path: "/apps-ecommerce-order-details", component: EcommerceOrderDetail },
  // { path: "/apps-ecommerce-customers", component: EcommerceCustomers },
  // { path: "/apps-ecommerce-cart", component: EcommerceCart },
  // { path: "/apps-ecommerce-checkout", component: EcommerceCheckout },
  // { path: "/apps-ecommerce-sellers", component: EcommerceSellers },
  // { path: "/apps-ecommerce-seller-details", component: EcommerceSellerDetail },

  // //Chat
  // { path: "/apps-chat", component: Chat },

  // //EMail
  // { path: "/apps-mailbox", component: MailInbox },

  // //Projects
  // { path: "/apps-projects-list", component: ProjectList },
  // { path: "/apps-projects-overview", component: ProjectOverview },
  // { path: "/apps-projects-create", component: CreateProject },

  // //Task
  // { path: "/apps-tasks-list-view", component: TaskList },
  // { path: "/apps-tasks-details", component: TaskDetails },
  // { path: "/apps-tasks-kanban", component: KanbanBoard },
  // //Crm
  // { path: "/apps-crm-contacts", component: CrmContacts },
  // { path: "/apps-crm-companies", component: CrmCompanies },
  // { path: "/apps-crm-deals", component: CrmDeals },
  // { path: "/apps-crm-leads", component: CrmLeads },

  // //Invoices
  // { path: "/apps-invoices-list", component: InvoiceList },
  // { path: "/apps-invoices-details", component: InvoiceDetails },
  // { path: "/apps-invoices-create", component: InvoiceCreate },

  // //Supports Tickets
  // { path: "/apps-tickets-list", component: ListView },
  // { path: "/apps-tickets-details", component: TicketsDetails },

  // //transactions
  // { path: "/apps-crypto-transactions", component: Transactions },
  // { path: "/apps-crypto-buy-sell", component: BuySell },
  // { path: "/apps-crypto-orders", component: CryproOrder },
  // { path: "/apps-crypto-wallet", component: MyWallet },
  // { path: "/apps-crypto-ico", component: ICOList },
  // { path: "/apps-crypto-kyc", component: KYCVerification },

  // //charts
  // { path: "/charts-apex-line", component: LineCharts },
  // { path: "/charts-apex-area", component: AreaCharts },
  // { path: "/charts-apex-column", component: ColumnCharts },
  // { path: "/charts-apex-bar", component: BarCharts },
  // { path: "/charts-apex-mixed", component: MixedCharts },
  // { path: "/charts-apex-timeline", component: TimelineCharts },
  // { path: "/charts-apex-candlestick", component: CandlestickChart },
  // { path: "/charts-apex-boxplot", component: BoxplotCharts },
  // { path: "/charts-apex-bubble", component: BubbleChart },
  // { path: "/charts-apex-scatter", component: ScatterCharts },
  // { path: "/charts-apex-heatmap", component: HeatmapCharts },
  // { path: "/charts-apex-treemap", component: TreemapCharts },
  // { path: "/charts-apex-pie", component: PieCharts },
  // { path: "/charts-apex-radialbar", component: RadialbarCharts },
  // { path: "/charts-apex-radar", component: RadarCharts },
  // { path: "/charts-apex-polar", component: PolarCharts },

  // { path: "/charts-chartjs", component: ChartsJs },
  // { path: "/charts-echarts", component: Echarts },

  // // Base Ui
  // { path: "/ui-alerts", component: UiAlerts },
  // { path: "/ui-badges", component: UiBadges },
  // { path: "/ui-buttons", component: UiButtons },
  // { path: "/ui-colors", component: UiColors },
  // { path: "/ui-cards", component: UiCards },
  // { path: "/ui-carousel", component: UiCarousel },
  // { path: "/ui-dropdowns", component: UiDropdowns },
  // { path: "/ui-grid", component: UiGrid },
  // { path: "/ui-images", component: UiImages },
  // { path: "/ui-tabs", component: UiTabs },
  // { path: "/ui-accordions", component: UiAccordions },
  // { path: "/ui-modals", component: UiModals },
  // { path: "/ui-offcanvas", component: UiOffcanvas },
  // { path: "/ui-placeholders", component: UiPlaceholders },
  // { path: "/ui-progress", component: UiProgress },
  // { path: "/ui-notifications", component: UiNotifications },
  // { path: "/ui-media", component: UiMediaobject },
  // { path: "/ui-embed-video", component: UiEmbedVideo },
  // { path: "/ui-typography", component: UiTypography },
  // { path: "/ui-lists", component: UiList },
  // { path: "/ui-general", component: UiGeneral },
  // { path: "/ui-ribbons", component: UiRibbons },
  // { path: "/ui-utilities", component: UiUtilities },

  // // Advance Ui
  // { path: "/advance-ui-nestable", component: UiNestableList },
  // { path: "/advance-ui-scrollbar", component: UiScrollbar },
  // { path: "/advance-ui-animation", component: UiAnimation },
  // { path: "/advance-ui-tour", component: UiTour },
  // { path: "/advance-ui-swiper", component: UiSwiperSlider },
  // { path: "/advance-ui-ratings", component: UiRatings },
  // { path: "/advance-ui-highlight", component: UiHighlight },

  // // Widgets
  // { path: "/widgets", component: Widgets },

  // // Forms
  // { path: "/forms-elements", component: BasicElements },
  // { path: "/forms-select", component: FormSelect },
  // { path: "/forms-editors", component: FormEditor },
  // { path: "/forms-checkboxes-radios", component: CheckBoxAndRadio },
  // { path: "/forms-masks", component: Masks },
  // { path: "/forms-file-uploads", component: FileUpload },
  // { path: "/forms-pickers", component: FormPickers },
  // { path: "/forms-range-sliders", component: FormRangeSlider },
  // { path: "/forms-layouts", component: Formlayouts },
  // { path: "/forms-validation", component: FormValidation },
  // { path: "/forms-wizard", component: FormWizard },
  // { path: "/forms-advanced", component: FormAdvanced },

  // //Tables
  // { path: "/tables-basic", component: BasicTables },
  // { path: "/tables-gridjs", component: GridTables },
  // { path: "/tables-listjs", component: ListTables },

  // //Icons
  // { path: "/icons-remix", component: RemixIcons },
  // { path: "/icons-boxicons", component: BoxIcons },
  // { path: "/icons-materialdesign", component: MaterialDesign },
  // { path: "/icons-feather", component: FeatherIcons },
  // { path: "/icons-lineawesome", component: LineAwesomeIcons },

  // //Maps
  // { path: "/maps-google", component: GoogleMaps },
  // { path: "/maps-vector", component: VectorMaps },
  // { path: "/maps-leaflet", component: LeafletMaps },

  // //Pages
  // { path: "/pages-starter", component: Starter },
  // { path: "/pages-profile", component: SimplePage },
  // { path: "/pages-profile-settings", component: Settings },
  // { path: "/pages-team", component: Team },
  // { path: "/pages-timeline", component: Timeline },
  // { path: "/pages-faqs", component: Faqs },
  // { path: "/pages-gallery", component: Gallery },
  // { path: "/pages-pricing", component: Pricing },
  // { path: "/pages-sitemap", component: SiteMap },
  // { path: "/pages-search-results", component: SearchResults },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/login" />,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: Logout },
  { path: "/login", component: CoverSignIn },
  { path: "/forgot-password", component: Forgot },
  { path: "/forgot-new-password", component: NewPassword },
  { path: "/register", component: Register },

  //AuthenticationInner pages
  { path: "/auth-signin-basic", component: BasicSignIn },

  { path: "/payment-success", component: Success },
  { path: "/payment-failed", component: Failed },

  { path: "/report-mobile/:token", component: ReportMobile },
  { path: "/report-available/:token", component: ReportAvailable },
  { path: "/article/:token", component: ReadArticle },
  { path: "/complementary/spm", component: Soal },
  { path: "/complementary/spm-trial-nugi/", component: SoalTrialNugi },
  { path: "/complementary/spm-trial/:testId/:token", component: SoalTrial },
  { path: "/complementary/test/:testId/:token", component: SoalTrial },

  { path: "/report-mobiles/pola-sidik-jari", component: PolaSidikJariM },
  { path: "/report-mobiles/distribusi-neuron", component: DistribusiNeuronM },
  { path: "/report-mobiles/tfrc", component: TfrcM },
  { path: "/report-mobiles/dominasi-otak", component: DominasiOtakM },
  { path: "/report-mobiles/gaya-belajar", component: GayaBelajarM },
  { path: "/report-mobiles/gaya-berpikir", component: GayaBerpikirM },
  { path: "/report-mobiles/ekstrakulikuler", component: EkstrakulikulerM },
  { path: "/report-mobiles/penjurusan", component: PenjurusanM },
  { path: "/report-mobiles/indra-dasar", component: IndraDasarM },
  { path: "/report-mobiles/minat-kejuruan", component: MinatKejuruanM },
  { path: "/report-mobiles/kecerdasan-majemuk", component: KecerdasanMajemukM },
  { path: "/report-mobiles/domain-kecerdasan", component: DomainKecerdasanM },
  { path: "/report-mobiles/disc", component: DISCM },
  { path: "/report-mobiles/dope", component: DOPEM },
  { path: "/report-mobiles/konsep-pemikiran", component: KonsepPemikiranM },
  { path: "/report-mobiles/kecerdasan", component: KecerdasanM },
  { path: "/report-mobiles/lobus-otak", component: LobusOtakM },
  { path: "/report-mobiles/summary", component: Summary },
  { path: "/report-mobiles/summary/2", component: Summary2 },
  { path: "/report-mobiles/summary/3", component: Summary3 },
  { path: "/report-mobiles/summary/4", component: Summary4 },
  // { path: "/auth-signin-cover", component: CoverSignIn },
  // { path: "/auth-signup-basic", component: BasicSignUp },
  // { path: "/auth-signup-cover", component: CoverSignUp },
  // { path: "/auth-pass-reset-basic", component: BasicPasswReset },
  // { path: "/auth-pass-reset-cover", component: CoverPasswReset },
  // { path: "/auth-lockscreen-basic", component: BasicLockScreen },
  // { path: "/auth-lockscreen-cover", component: CoverLockScreen },
  // { path: "/auth-logout-basic", component: BasicLogout },
  // { path: "/auth-logout-cover", component: CoverLogout },
  // { path: "/auth-success-msg-basic", component: BasicSuccessMsg },
  // { path: "/auth-success-msg-cover", component: CoverSuccessMsg },
  // { path: "/auth-twostep-basic", component: BasicTwosVerify },
  // { path: "/auth-twostep-cover", component: CoverTwosVerify },
  // { path: "/auth-404-basic", component: Basic404 },
  // { path: "/auth-404-cover", component: Cover404 },
  // { path: "/auth-404-alt", component: Alt404 },
  // { path: "/auth-500", component: Error500 },
  // { path: "/pages-maintenance", component: Maintenance },
  // { path: "/pages-coming-soon", component: ComingSoon },

  // { path: "/landing", component: Index },
  // { path: "/auth-pass-change-basic", component: BasicPasswCreate },
  // { path: "/auth-pass-change-cover", component: CoverPasswCreate },
  // { path: "/auth-offline", component: Offlinepage },

  // Dashboard V2 For Client
  { path: "/client/dashboard", component: ClientDashboard },
  { path: "/client/listReading", component: ClientListReading },
  { path: "/client/report", component: ClientReport },
  { path: "/client/purchase", component: ClientPurchase },
  { path: "/client/complementary", component: ClientComplementary },
  { path: "/client/profile", component: ClientProfile },
  { path: "/client/purchase/process", component: ClientProcessPurchase },

  { path: "/client/reportFP", component: ClientFPReport },
  { path: "/client/reportSPM", component: ClientSPMReport },
  { path: "/client/reportSPM-mobile/:token", component: ReportSPMClient },
  { path: "/client/report-mobile/:testId/:token", component: ReportSPMClient },

  {
    path: "/client/report/hasilsidikjari/polasidikjari",
    component: ReportPolaSidikJari,
  },
  {
    path: "/client/report/hasilsidikjari/distribusineuron",
    component: ReportDistribusiNeuron,
  },
  {
    path: "/client/report/hasilsidikjari/tfrc",
    component: ReportTfrc,
  },
  {
    path: "/client/report/hasilsidikjari/dominasiotak",
    component: ReportDominasiOtak,
  },
  {
    path: "/client/report/hasilsidikjari/gayabelajar",
    component: ReportGayaBelajar,
  },
  {
    path: "/client/report/hasilsidikjari/ekstrakulikuler",
    component: ReportEkstrakulikuler,
  },
  {
    path: "/client/report/hasilsidikjari/pemilihanjurusan",
    component: ReportPemilihanJurusan,
  },
  {
    path: "/client/report/hasilsidikjari/kecerdasan",
    component: ReportKecerdasan,
  },
  {
    path: "/client/report/hasilsidikjari/indradasar",
    component: ReportIndraDasar,
  },
  {
    path: "/client/report/hasilsidikjari/gayaberpikir",
    component: ReportGayaBerpikir,
  },
  {
    path: "/client/report/hasilsidikjari/minatstudi",
    component: ReportMinatStudi,
  },
  {
    path: "/client/report/hasilsidikjari/kecerdasanmajemuk",
    component: ReportKecerdasanMajemuk,
  },
  {
    path: "/client/report/hasilsidikjari/domainkecerdasan",
    component: ReportDomainKecerdasan,
  },
  {
    path: "/client/report/hasilsidikjari/disc",
    component: ReportDisc,
  },
  {
    path: "/client/report/hasilsidikjari/dope",
    component: ReportDope,
  },
  {
    path: "/client/report/hasilsidikjari/konseppemikiran",
    component: ReportKonsepPemikiran,
  },
  {
    path: "/client/report/hasilsidikjari/summary",
    component: ReportSummary,
  },
  {
    path: "/client/report/hasilsidikjari/lobusotak",
    component: ReportLobusOtak,
  },
];

export { authProtectedRoutes, publicRoutes };

import React, {useEffect, useState} from 'react';
import {Row, Col, Input, Label, Modal, ModalHeader, ModalBody, ModalBottom} from 'reactstrap';

import axios from 'axios';
import Lottie from 'react-lottie';
import { decodeToken } from "react-jwt";
import Flatpickr from "react-flatpickr";

import foto1 from '../../../../assets/images/FutureLink/foto1.png';
import foto2 from '../../../../assets/images/FutureLink/foto2.png';
import capture from '../../../../assets/images/FutureLink/capture.png';
import thankyou from '../../../../assets/images/FutureLink/thankyou.png';
import bestValue from '../../../../assets/images/FutureLink/bestValue.png';

import edu from '../../../../assets/images/FutureLink/educational.png';
import pro from '../../../../assets/images/FutureLink/professional.png';

import animateAlert from '../../../../assets/images/FutureLink/alert.json'
import accessLoading from '../../../../assets/images/FutureLink/loading.json';

const Purchase = () => {
    const code = sessionStorage.getItem('user');
    const breakCode = decodeToken(code);
    const [step, setStep] = useState(0);
    const [configCounselor, setConfigCounselor] = useState(0);
    const [clickEducational, setClickEducational] = useState(false);
    const [clickProfessional, setClickProfessional] = useState(false);

    const [checkbox, setCheckbox] = useState(false);
    const [modalCheckbox, setModalCheckbox] = useState(false);

    const [idPackages, setIdPackages] = useState("");
    const [name, setName] = useState("");
    const [price, setPrice] = useState("");
    const [description, setDescription] = useState("");

    const [idEducational, setIdEducational] = useState("");
    const [nameEducational, setNameEducational] = useState("");
    const [priceEducational, setPriceEducational] = useState("");
    const [descriptionEducational, setDescriptionEducational] = useState("");
    const [counselorEducational, setCounselorEducational] = useState("");
    const [imageEducational, setImageEducational] = useState("");

    const [idProfessional, setIdProfessional] = useState("");
    const [nameProfessional, setNameProfessional] = useState("");
    const [priceProfessional, setPriceProfessional] = useState("");
    const [descriptionProfessional, setDescriptionProfessional] = useState("");
    const [counselorProfessional, setCounselorProfessional] = useState("");
    const [imageProfessional, setImageProfessional] = useState("");

    const [boxCounselor, setBoxCounselor] = useState("");

    const [idBug, setIdBug] = useState("");
    const [nameBug, setNameBug] = useState("");
    const [priceBug, setPriceBug] = useState("");
    const [descriptionBug, setDescriptionBug] = useState("");

    const [randomNumber, setRandomNumber] = useState("");
    const [email, setEmail] = useState("");
    const [emailVerify, setEmailVerify] = useState(0)
    const [phone, setPhone] = useState("");

    const [modalAlertVerify, setModalAlertVerify] = useState(false);
    const [imagePur, setImagePur] = useState("");
    const [checkPackage, setCheckPackage] = useState("");

    const [jaringan, setJaringan] = useState("Offline");
    const [counselor, setCounselor] = useState([]);
    const [counselorId, setCounselorId] = useState("");
    const [loadingPackage, setLoadingPackage] = useState(true);
    const [loadingCounselor, setLoadingCounselor] = useState(true);
    const [loadingDate, setLoadingDate] = useState(true);
    const [loadingTime, setLoadingTime] = useState(true);
    const [loadingList, setLoadingList] = useState(true);

    const [nama, setNama] = useState("");
    const [harga, setHarga] = useState(0);
    const [image, setImage] = useState("");
    const [selectDate, setSelectDate] = useState("");
    const [selectTime, setSelectTime] = useState("");

    const [a, setA] = useState(true);
    const [b, setB] = useState(true);
    const [c, setC] = useState(true);
    const [d, setD] = useState(true);
    const [e, setE] = useState(true);
    const [f, setF] = useState(true);
    const [g, setG] = useState(true);
    const [h, setH] = useState(true);
    const [i, setI] = useState(true);

    const [cuti, setCuti] = useState([]);

    const [listPackages, setListPackages] = useState([]);
    const [click, setClick] = useState(0);
    const [cs, setCs] = useState(false);
    const [forbidden, setForbidden] = useState(false);

    const generate = () => {
        random_kode(7);
    };
    
    function random_kode(string_length) {
        var random_string = "";
        var characters = "01234567890123456789";
        for (var i = 0; i < string_length; i++) {
            random_string += characters.charAt(
            Math.floor(Math.random() * characters.length)
          );
        }
        setRandomNumber(random_string);
    }

    useEffect(() => {
        const midtransScriptUrl = process.env.REACT_APP_MIDTRANS_URL
        const myMidtransClientKey = process.env.REACT_APP_MIDTRANS_CLIENT_KEY

        let scriptTag = document.createElement('script');
        scriptTag.src = midtransScriptUrl;
        scriptTag.setAttribute('data-client-key', myMidtransClientKey);
        // console.log(scriptTag);
      
        document.body.appendChild(scriptTag);
      
        return () => {
          document.body.removeChild(scriptTag);
        }
    },[])

    useEffect(() => {
        // getAllPackages();
        getAllPackagesV2()
        getUser();
        getAllCounselor("offline");
    },[])

    const getUser = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-user-token`,
            headers: { 
                'Authorization': `Bearer ${code}`
            }
        };
          
        axios.request(config)
            .then((response) => {
                // console.log(response);
                setCheckPackage(response.results.package_id)
                setEmail(response.results.email)
                setPhone(response.results.phone)
                setEmailVerify(response.results.email_verified)
            })
            .catch((error) => {
                // console.log(error);
            });
    }

    const getAllPackages = () => {
        setLoadingPackage(true)

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-packages`,
            headers: { 
                'Authorization': `Bearer ${code}`
            }
        };
          
        axios.request(config)
            .then((response) => {
                // console.log(response.results);

                setIdEducational(response.results[1].package_id);
                setNameEducational(response.results[1].name);
                setPriceEducational(response.results[1].price);
                setDescriptionEducational(response.results[1].description);
                setCounselorEducational(response.results[1].counselor);
                setImageEducational(response.results[1].image);

                setIdProfessional(response.results[2].package_id);
                setNameProfessional(response.results[2].name);
                setPriceProfessional(response.results[2].price);
                setDescriptionProfessional(response.results[2].description);
                setCounselorProfessional(response.results[2].counselor);
                setImageProfessional(response.results[2].image);

                setIdBug(response.results[3].package_id);
                setNameBug(response.results[3].name);
                setPriceBug(response.results[3].price);
                setDescriptionBug(response.results[3].description);

                setTimeout(() => {
                    setLoadingPackage(false)
                }, 2000)
            })
            .catch((error) => {
                // console.log(error);
            });
    }

    const getAllPackagesV2 = () => {
        setLoadingPackage(true)

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-packages?status=1`,
            headers: { 
                'Authorization': `Bearer ${code}`
            }
        };
          
        axios.request(config)
            .then((response) => {
                // console.log(response.results);
                setListPackages(response.results)

                setTimeout(() => {
                    setLoadingPackage(false)
                }, 2000)
            })
            .catch((error) => {
                // console.log(error);
                setForbidden(true)
                setTimeout(() => {
                    setLoadingPackage(false)
                }, 2000)
            });

    }

    // const show = () => {
    //     if (clickEducational === true) {
    //         return {zIndex: '10', padding: '7px 12px', borderRadius: '30px', color: 'white', backgroundColor: '#799BC4', position: 'absolute', width: 'max-content',  height: 'max-content', marginLeft: '-15px', marginTop: '80px', transition: '0.35s', opacity:'1', visibility: 'visible', fontSize: '14px', fontWeight: '900', boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)'}
    //     } else {
    //         return {zIndex: '10', padding: '7px 12px', borderRadius: '30px', color: 'white', backgroundColor: '#799BC4', position: 'absolute', width: 'max-content',  height: 'max-content', marginLeft: '-15px', marginTop: '80px', transition: '0.35s', opacity:'0', visibility: 'hidden', fontSize: '14px', fontWeight: '900', boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)'}
    //     }
    // }

    // const show1 = () => {
    //     if (clickProfessional === true) {
    //         return {zIndex: '10', padding: '7px 12px', borderRadius: '30px', color: 'white', backgroundColor: '#799BC4', position: 'absolute', width: 'max-content',  height: 'max-content', marginLeft: '-15px', marginTop: '80px', transition: '0.35s', opacity:'1', visibility: 'visible', fontSize: '14px', fontWeight: '900', boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)'}
    //     } else {
    //         return {zIndex: '10', padding: '7px 12px', borderRadius: '30px', color: 'white', backgroundColor: '#799BC4', position: 'absolute', width: 'max-content',  height: 'max-content', marginLeft: '-15px', marginTop: '80px', transition: '0.35s', opacity:'0', visibility: 'hidden', fontSize: '14px', fontWeight: '900', boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)'}
    //     }
    // }

    const getAllCounselor = (jaringan) => {
        setLoadingCounselor(true)
        
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-counselor?via=${jaringan}&status=active`,
            headers: { 
                'Authorization': `Bearer ${code}`
            }
        };
          
        axios.request(config)
            .then((response) => {
            // console.log(response.results);
            setCounselor(response.results);
            setTimeout(() => {
                setLoadingCounselor(false)
            },1000)
            
            })
            .catch((error) => {
            // console.log(error);
            });
    }

    const getScheduleCounselor = (counselorId) => {
        setLoadingDate(true);
        setSelectDate("");

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-counselor-schedule?counselorId=${counselorId}`,
            headers: { 
              'Authorization': `Bearer ${code}`
            }
        };
          
        axios.request(config)
            .then((response) => {
            // console.log(response);
            setCuti(response.results.leaves)
            setTimeout(() => {
                setLoadingDate(false);
            }, 2000)
            })
            .catch((error) => {
            // console.log(error);
            });
    }

    const getScheduleCounselorOnDate = (ondate) => {
        if(ondate){
            setLoadingTime(true);

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-counselor-schedule-on-date?counselorId=${counselorId}&date=${ondate}`,
                headers: { 
                  'Authorization': `Bearer ${code}`
                }
            };
              
            axios.request(config)
                .then((response) => {
                    // console.log(response.results);
                    setA(response.results.morning.includes(1));
                    setB(response.results.morning.includes(2));
                    setC(response.results.morning.includes(3));
                    setD(response.results.afternoon.includes(1));
                    setE(response.results.afternoon.includes(2));
                    setF(response.results.evening.includes(1));
                    setG(response.results.evening.includes(2));
                    setH(response.results.night.includes(1));
                    setI(response.results.night.includes(2));

                    setTimeout(() => {
                        setLoadingTime(false);
                    }, 2000)
                    
                })
                .catch((error) => {
                    // console.log(error);
                });
        }
    }

    const shadow = (index) => {
        if(cs) {

            if (click == index) {
                return { boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)', border: '3px solid #FF7518'}
            } else {
                return {gap: '0px'}
            }

        } else {

            return {gap: '0px'}

        }

    }

    const nambahShadow = () => {
        if (clickEducational === true) {
            return { boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)', border: '3px solid #FF7518'}
        } else {
            return {gap: '0px'}
        }
    }

    const nambahShadow1 = () => {
        if (clickProfessional === true) {
            return { boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)', border: '3px solid #FF7518'}
        } else {
            return {gap: '0px'}
        }
    }

    const coba = (event) => {
        setCheckbox(event.target.checked);
        setConfigCounselor(0);
        setSelectDate("");
        setJaringan('Offline');
        getAllCounselor('offline');
        setSelectTime("");
        setNama("");
        setHarga(0)

        if(event.target.checked === true) {
            setModalCheckbox(true);
        }
    }

    function toggleStatus() {
        const toggleButton = document.getElementById("toggleButton");
        const status = document.getElementById("statuse");
        if (toggleButton.checked) {
            status.innerText = "Online";
            getAllCounselor("online");
            setLoadingList(true);
            setJaringan("Online")
        } else {
            status.innerText = "Offline";
            getAllCounselor("offline");
            setLoadingList(true);
            setJaringan("Offline")
        }
    }

    const dor = () => {

        if(emailVerify === 0) {
            setModalAlertVerify(true)
        } else {

            if(checkbox === false) {

                let data = JSON.stringify({
                    transaction_details: {
                        order_id: idPackages === 3 && nama !== "" ? `${breakCode.userId}-${idPackages}-manual-${randomNumber}` : `${breakCode.userId}-${idPackages}-AI-${randomNumber}`,
                        gross_amount: price+harga,
                    },
                    customer_details: {
                        first_name: breakCode.firstName,
                        last_name: breakCode.lastName,
                        email: email,
                        phone: phone === null || phone === "null" || phone === "0000" ? "" : phone,
                    }
                });

                // console.log(data)
        
                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    // url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/charge`,
                    url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/charge`,
                    headers: { 
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
        
                axios.request(config)
                    .then((response) => {
                        // console.log(response);
                        // console.log(window)
                        // console.log(window.snap)
                        // console.log(window.snap.pay())
                        // console.log(window.snap.pay(response.token))
                        window.snap.pay(response.token);
                    })
                    .catch((error) => {
                        // console.log(error);
                    });

            } else {

                // console.log("Tanggal", new Date(selectDate).getFullYear() + "-" + (new Date(selectDate).getMonth()+1 > 9 ? new Date(selectDate).getMonth()+1 : "0"+(new Date(selectDate).getMonth()+1)) + "-" + (new Date(selectDate).getDate() > 9 ? new Date(selectDate).getDate() : "0"+new Date(selectDate).getDate()))
                // console.log('waktu = ', selectTime);
                // console.log('jaringan = ',jaringan.toLowerCase());
                // console.log('counselorId', counselorId)

                let data = new URLSearchParams();
                data.append('counselorId', counselorId);
                data.append('date', new Date(selectDate).getFullYear() + "-" + (new Date(selectDate).getMonth()+1 > 9 ? new Date(selectDate).getMonth()+1 : "0"+(new Date(selectDate).getMonth()+1)) + "-" + (new Date(selectDate).getDate() > 9 ? new Date(selectDate).getDate() : "0"+new Date(selectDate).getDate()))
                data.append('time', selectTime);
                data.append('via', jaringan.toLowerCase())
                  
                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/make-schedule`,
                    headers: { 
                      'Content-Type': 'application/x-www-form-urlencoded', 
                      'Authorization': `Bearer ${code}`
                    },
                    data : data
                };
                
                axios.request(config)
                    .then((response) => {
                    // console.log(response);
    
                    let data = JSON.stringify({
                        transaction_details: {
                            order_id: idPackages === 3 && nama !== "" ? `${breakCode.userId}-${idPackages}-manual-${randomNumber}` : `${breakCode.userId}-${idPackages}-AI-${randomNumber}`,
                            gross_amount: price+harga,
                        },
                        customer_details: {
                            first_name: breakCode.firstName,
                            last_name: breakCode.lastName,
                            email: email,
                            phone: phone === null || phone === "null" || phone === "0000" ? "" : phone,
                        }
                    });
                    let config = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        // url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/charge`,
                        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/charge`,
                        headers: { 
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };
                    axios.request(config)
                        .then((response) => {
                            // console.log(response);
                            window.snap.pay(response.token);
                        })
                        .catch((error) => {
                            // console.log(error);
                        });
                  })
                  .catch((error) => {
                    // console.log(error);
                  });

            }

        }
    }

    // console.log(jaringan, nama, harga, selectDate, selectTime, image);
    // console.log(price + parseInt(0))
    // console.log(price , harga, price+harga)
    
    return ( 
        <React.Fragment>
            <div className="page-content">
            {loadingPackage === true ? (
                <>
                    <Lottie width={'50%'} options={{loop: true, autoplay: true,animationData: accessLoading,rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} isClickToPauseDisabled={true} />
                </>
            ) : (
                <>

            {step === 0 && (
                <>
                <Row style={{marginLeft:'35px'}}>
                    <Col xs={6}>
                        <Row>
                            <h3 style={{fontSize: '24px'}}>Get Ready for Future</h3>
                            <p style={{fontSize: '14px'}}>Choose your package now, make sure it fits your needs.</p>
                        </Row>
                    </Col>
                </Row>

                    {/* {checkPackage === 0 ? (
                        <>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', gap: '30px', marginTop: '20px'}}>
                            <img src={capture} style={{width: '360px'}} />
                            <p style={{margin: '0px', fontSize: '28px', fontWeight: '600', color: '#FF7518'}}>Sorry, you haven't captured your fingerprint yet</p>
                            <p style={{margin: '0px', fontSize: '16px', fontWeight: '400'}}>Please capture your fingerprint through the mobile application.</p>
                        </div>
                        </>
                    ) : checkPackage === 1 ? (
                        <>

                    <Row style={{display: 'flex', marginTop: '30px'}}>
                        <Col xs={12} md={5} style={{display: 'flex', flexDirection: 'row', cursor: 'pointer', paddingLeft: '20px', marginTop: '20px'}}  onClick={() => {setClickEducational(!clickEducational); setClickProfessional(false); setName(nameEducational); setPrice(priceEducational); setDescription(descriptionEducational); setIdPackages(idEducational); generate(); setImagePur(imageEducational); setBoxCounselor(counselorEducational)}}>
                            <div style={nambahShadow()} className='purchase'>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <img src={imageEducational} className='imgPurchase' />
                                </div>
                                <div style={{padding: '30px 20px'}}>
                                    <p style={{fontSize: '20px', fontWeight: '900', color: '#FF7518', margin: '0px'}}>{nameEducational}</p>
                                    <p style={{fontSize: '22px', fontWeight: '700', color: '#374151'}}>Rp{priceEducational}</p>
                                    <p style={{fontSize: '12px', fontWeight: '500', color: '#374151', margin: '0px'}}>{descriptionEducational}</p>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={5} style={{display: 'flex', flexDirection: 'row', cursor: 'pointer', paddingLeft: '20px', marginTop: '20px'}}  onClick={() => {setClickProfessional(!clickProfessional); setClickEducational(false); setName(nameProfessional); setPrice(priceProfessional); setDescription(descriptionProfessional); setIdPackages(idProfessional); generate(); setImagePur(imageProfessional); setBoxCounselor(counselorProfessional)}}>
                            <div style={nambahShadow1()} className='purchase'>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <img src={imageProfessional} className='imgPurchase' />
                                </div>
                                <div style={{padding: '30px 20px'}}>
                                    <p style={{fontSize: '20px', fontWeight: '900', color: '#FF7518', margin: '0px'}}>{nameProfessional}</p>
                                    <p style={{fontSize: '22px', fontWeight: '700', color: '#374151'}}>Rp{priceProfessional}</p>
                                    <p style={{fontSize: '12px', fontWeight: '500', color: '#374151', margin: '0px'}}>{descriptionProfessional}</p>
                                </div>
                                <div style={{position: 'absolute', width: '120px', height: 'max-content', padding: '10px 0px', color: 'white', borderTopRightRadius: '10px', borderBottomLeftRadius: '20px', fontSize: '14px', fontWeight: '500', marginRight: '2%', display: 'flex', justifyContent: 'flex-end'}}>
                                    <img src={bestValue} style={{width: '60px'}} /> 
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <button style={{marginLeft: '35px', width: '300px', marginTop : '50px', marginLeft: '35px', fontSize: '14px', fontWeight: 'bolder'}} className='btn buttonVersFutureLink' onClick={() => {setStep(1)}} disabled={clickEducational === true || clickProfessional === true ? false : true}>Select Plan</button>

                        </>
                    ) : checkPackage === 2 ? (
                        <>

                    <Row style={{display: 'flex', marginTop: '30px'}}>
                        <Col xs={12} md={5} style={{display: 'flex', flexDirection: 'row', cursor: 'pointer', paddingLeft: '20px', marginTop: '20px'}}  onClick={() => {setClickProfessional(!clickProfessional); setClickEducational(false); setName(nameProfessional); setPrice(priceProfessional); setDescription(descriptionProfessional); setIdPackages(idProfessional); generate(); setImagePur(imageProfessional); setBoxCounselor(counselorProfessional)}}>
                            <div style={nambahShadow1()} className='purchase'>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <img src={imageProfessional} className='imgPurchase' />
                                </div>
                                <div style={{padding: '30px 20px'}}>
                                    <p style={{fontSize: '20px', fontWeight: '900', color: '#FF7518', margin: '0px'}}>{nameProfessional}</p>
                                    <p style={{fontSize: '22px', fontWeight: '700', color: '#374151'}}>Rp{priceProfessional}</p>
                                    <p style={{fontSize: '12px', fontWeight: '500', color: '#374151', margin: '0px'}}>{descriptionProfessional}</p>
                                </div>
                                <div style={{position: 'absolute', width: '120px', height: 'max-content', padding: '10px 0px', color: 'white', borderTopRightRadius: '10px', borderBottomLeftRadius: '20px', fontSize: '14px', fontWeight: '500', marginRight: '2%', display: 'flex', justifyContent: 'flex-end'}}>
                                    <img src={bestValue} style={{width: '60px'}} /> 
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <button style={{marginLeft: '35px', width: '300px', marginTop : '50px', marginLeft: '35px', fontSize: '14px', fontWeight: 'bolder'}} className='btn buttonVersFutureLink' onClick={() => {setStep(1)}} disabled={clickEducational === true || clickProfessional === true ? false : true}>Select Plan</button>

                        </>
                    ) : (
                        <>

                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', gap: '30px', marginTop: '20px'}}>
                            <img src={thankyou} style={{width: '360px'}} />
                            <p style={{margin: '0px', fontSize: '28px', fontWeight: '600', color: '#FF7518'}}>You have successfully purchased our package</p>
                            <p style={{margin: '0px', fontSize: '16px', fontWeight: '400'}}>Thank you for your support to Future Link!</p>
                        </div>

                        </>
                    )} */}

                    {checkPackage === 0 ? (
                        <>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', gap: '30px', marginTop: '20px'}}>
                            <img src={capture} style={{width: '360px'}} />
                            <p style={{margin: '0px', fontSize: '28px', fontWeight: '600', color: '#FF7518'}}>Sorry, you haven't captured your fingerprint yet</p>
                            <p style={{margin: '0px', fontSize: '16px', fontWeight: '400'}}>Please capture your fingerprint through the mobile application.</p>
                        </div>
                        </>
                    ) : (
                        <>

                    <Row style={{display: 'flex', marginTop: '30px'}}>
                        {listPackages.map((item, index) => (
                        <>
                        <Col key={index} xs={12} md={5} style={{display: 'flex', flexDirection: 'row', cursor: 'pointer', paddingLeft: '20px', marginTop: '20px'}}  onClick={() => {setName(item.name); setPrice(item.price); setDescription(item.description); setIdPackages(item.package_id); generate(); setImagePur(item.image); setBoxCounselor(item.counselor); setClick(index); setCs(!cs)}}>
                            {/* <div style={show()}> 🗸 </div> */}
                            <div style={shadow(index)} className='purchase'>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <img src={item.image} className='imgPurchase' />
                                </div>
                                <div style={{padding: '30px 20px'}}>
                                    <p style={{fontSize: '20px', fontWeight: '900', color: '#FF7518', margin: '0px'}}>{item.name}</p>
                                    <p style={{fontSize: '22px', fontWeight: '700', color: '#374151'}}>Rp{item.price}</p>
                                    <p style={{fontSize: '12px', fontWeight: '500', color: '#374151', margin: '0px'}}>{item.description}</p>
                                </div>
                            </div>
                        </Col>
                        </>
                        ))}
                    </Row>
                    <button style={{marginLeft: '35px', width: '300px', marginTop : '50px', marginLeft: '35px', fontSize: '14px', fontWeight: 'bolder'}} className='btn buttonVersFutureLink' onClick={() => {setStep(1)}} disabled={!cs}>Select Plan</button>

                        </>
                    )}

                </>
            )}

            {step === 1 && (
                <>
                    <button style={{marginLeft: '35px', marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' onClick={() => {setStep(0)}}>Back</button>
                    
                    <div style={{display: 'flex', flexDirection: 'row', paddingLeft: '20px', marginTop: '20px'}}>
                        {/* <div style={{zIndex: '10', padding: '7px 12px', borderRadius: '30px', color: 'white', backgroundColor: '#799BC4', position: 'absolute', width: 'max-content',  height: 'max-content', marginLeft: '-15px', marginTop: '80px', transition: '0.35s', opacity:'1', visibility: 'visible', fontSize: '14px', fontWeight: '900', boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)'}}> 🗸 </div> */}
                        <div style={{boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)', border: '3px solid #FF7518'}} className='purchase'>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img src={imagePur} className='imgPurchase' />
                            </div>
                            <div style={{padding: '30px 40px'}}>
                                <p style={{fontSize: '20px', fontWeight: '900', color: '#FF7518', margin: '0px'}}>{name}</p>
                                <p style={{fontSize: '22px', fontWeight: '700', color: '#374151'}}>Rp{price}</p>
                                <p style={{fontSize: '12px', fontWeight: '500', color: '#374151', margin: '0px'}}>{description}</p>
                            </div>
                        </div>
                    </div>

                    <div style={{marginLeft: '35px', marginTop: '30px'}}>
                        <p style={{fontSize: '18px', fontWeight: '700', color: '#374151'}}>Feature :</p>
                        {name === "Educational" && (
                            <>
                            <ul style={{fontSize: '14px', color: '#374151'}}>
                                <li>Pola Sidik Jari</li>
                                <li>Distribusi Neuron</li>
                                <li>Dominasi Otak</li>
                                <li>Gaya Belajar</li>
                                <li>Ekstrakulikuler</li>
                                <li>Pemilihan Jurusan</li>
                                <li>Kecerdasan (IQ, EQ, AQ, CQ)</li>
                                <li>Domain Kecerdasan</li>
                            </ul>
                            </>
                        )}
                        {name === "Professional" && (
                            <>
                            <ul style={{fontSize: '14px', color: '#374151'}}>
                                <li>Pola Sidik Jari</li>
                                <li>Distribusi Neuron</li>
                                <li>TFRC</li>
                                <li>Dominasi Otak</li>
                                <li>Gaya Belajar</li>
                                <li>Ekstrakulikuler</li>
                                <li>Pemilihan Jurusan</li>
                                <li>Lobus Otak</li>
                                <li>Kecerdasan (IQ, EQ, AQ, CQ)</li>
                                <li>Kecerdasan</li>
                                <li>Indra Dasar</li>
                                <li>Gaya Berpikir</li>
                                <li>Minat Studi</li>
                                <li>Kecerdasan Majemuk</li>
                                <li>Domain Kecerdasan</li>
                                <li>DISC</li>
                                <li>DOPE</li>
                                <li>Konsep Pemikiran</li>
                            </ul>
                            </>
                        )}
                    </div>

                    {boxCounselor == "1" && (
                        <>

                        <div className='form-check mb-2' style={{fontSize: '14px', marginLeft: '35px', marginTop: '20px', color: '#374151'}}>
                            <Input className='form-check-input' type='checkbox' id='check' checked={checkbox} onClick={coba} />
                            <Label className='form-check-label' for='check' >Plus consultation duration 40-60 minutes</Label>
                        </div>

                        </>
                    )}

                    {nama !== "" && harga !== "" && selectDate !== "" && selectTime !== "" && (
                        <>
                            <div style={{marginLeft: '35px'}}>
                                <p style={{fontSize: '18px', fontWeight: '600'}}>Counselor: </p>
                                <div style={{display: 'flex', gap: '30px' ,alignItems: 'center'}}>
                                    <div style={{display: 'flex', gap: '30px' ,alignItems: 'center'}}>
                                        <img style={{borderRadius: '50%', width: '128px', height: '128px', objectFit: 'cover'}} src={image}/>
                                        <div>
                                            <p style={{fontSize: '16px', fontWeight: '600'}}>{jaringan}</p>
                                            <p style={{fontSize: '14px', marginBottom: '5px'}}>{nama}</p>
                                            <p style={{fontSize: '14px'}}>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(harga)}</p>
                                        </div>
                                    </div>

                                    <div style={{height: '128px', width: '1px', backgroundColor: 'black'}}></div>

                                    <div>
                                        <p style={{fontSize: '16px', fontWeight: '600'}}>Schedule</p>
                                        <p style={{fontSize: '14px'}}>{new Date(selectDate).toLocaleDateString('id-ID', {weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'})}</p>
                                        <p style={{fontSize: '14px'}}> { 
                                        selectTime === "morning-1" ? "09.00 WIB" 
                                        : selectTime === "morning-2" ? "10.00 WIB"
                                        : selectTime === "morning-3" ? "11.00 WIB"
                                        : selectTime === "afternoon-1" ? "13.00 WIB"
                                        : selectTime === "afternoon-2" ? "14.00 WIB"
                                        : selectTime === "evening-1" ? "15.00 WIB"
                                        : selectTime === "evening-2" ?  "16.00 WIB"
                                        : selectTime === "night-1" ? "19.00 WIB"
                                        : selectTime === "night-2" ? "20.00 WIB"
                                        : "Tidak Tersedia"
                                        }</p>
                                    </div>
                                </div>

                            </div>
                        </>
                    )}

                    <p style={{fontSize: '22px', fontWeight: '700', color: '#374151', marginLeft: '35px', marginTop: '20px'}}>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(price + harga)}</p>

                    <button style={{marginLeft: '35px', width: '400px', marginTop : '10px', marginLeft: '35px', fontSize: '14px', fontWeight: 'bolder'}} className='btn buttonVersFutureLink' onClick={() => {dor()}} >Purchase</button>
                </>
            )}
                
                </>
            )}


            </div>

            <Modal isOpen={modalCheckbox} toggle={() => {setModalCheckbox(!modalCheckbox); setCheckbox(false); setHarga(0)}} centered>
                <ModalHeader toggle={()=> {setModalCheckbox(false); setCheckbox(false); setHarga(0)}}><h5 className='modal-title fontJakarta' style={{fontSize: '18px', color: '#4B5563'}}>Choose Conselor</h5></ModalHeader>
                <ModalBody>

                    {configCounselor === 0 && (
                        <>

                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '30px'}}>

                            <label className="switch">
                                <input type="checkbox" onChange={() => {toggleStatus()}} id="toggleButton"/>
                                <span className="slider round"></span>
                            </label>
                            <p style={{marginBottom: '0px', fontSize: '14px'}} id="statuse">Offline</p>

                        </div>

                        <div>

                    {loadingCounselor === true ? (
                        <>
                            <Lottie width={'30%'} options={{loop: true, autoplay: true,animationData: accessLoading,rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} isClickToPauseDisabled={true} />
                        </>
                    ) : (
                        <>

                        {counselor !== undefined && counselor.map((item, index) => (
                            <>
                                <div key={index} style={{display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '10px', cursor: 'pointer'}} onClick={() => {setConfigCounselor(1); setNama(item.name); setHarga(item.price); setImage(item.image); setCounselorId(item.counselors_id); getScheduleCounselor(item.counselors_id)}}>
                                    <img src={item.image} style={{borderRadius: '50%', width: '64px', height: '64px', objectFit: 'cover'}}/>
                                    <p style={{marginBottom: '0px', fontSize: '18px'}}>{item.name}</p>
                                </div>
                            </>
                        ))}
                        
                        </>
                    )}
                        </div>
                        
                        </>
                    )}

                    {configCounselor === 1 && (
                        <>
                        <button className='btn buttonVersFutureLinkBorderGrey' onClick={() => {setConfigCounselor(0); getAllCounselor("offline");}}>Back</button>
                        <div style={{display: 'flex', margin: '20px 5px', alignItems: 'center', gap: '20px'}}>
                            <img src={image} style={{borderRadius: '50%', width: '64px', height: '64px', objectFit: 'cover'}} />
                            <div>
                            <p style={{marginBottom: '0px', fontSize: '18px', fontWeight: '600'}}>{jaringan}</p>
                            <p style={{marginBottom: '0px', fontSize: '18px'}}>{nama}</p>
                            <p style={{marginBottom: '0px', fontSize: '18px'}}>{new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(harga,)}</p>
                            </div>
                        </div>


                        {loadingDate === true ? (
                            <>
                            <Lottie width={'20%'} options={{loop: true, autoplay: true,animationData: accessLoading,rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} isClickToPauseDisabled={true} />
                            </>
                        ) : (
                            <>
                            <div className="mb-3">
                                <label htmlFor="date-field" className="form-label" style={{fontSize: '14px'}}>Choose Date Counselor</label>
                                <Flatpickr
                                    className="form-control"
                                    options={{
                                        disable: cuti,
                                        dateFormat: "Y-m-d",
                                        minDate: new Date().fp_incr(1),
                                        maxDate: new Date().fp_incr(30),
                                    }}
                                    onChange={(event, dateStr) => {
                                        setSelectDate(event[0]);
                                        //  console.log(event); 
                                         getScheduleCounselorOnDate(dateStr);
                                        }}
                                    placeholder="Select Date"
                                />
                            </div>
                            </>
                        )}


                        {selectDate !== "" && (
                            <>
                            <p style={{margin:'10px 0px', fontSize: '14px', fontWeight: '500'}}>Pilih Waktu Counselor</p>

                            {loadingTime === true ? (
                                <>
                                    <Lottie width={'20%'} options={{loop: true, autoplay: true,animationData: accessLoading,rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} isClickToPauseDisabled={true} />
                                </>
                            ) : (
                                <>

                                <div style={{padding: '0px 10px'}}>

                                <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Pagi:</p>
                                <div style={{display: 'flex', gap: '20px'}}>
                                    <button className='btn buttonVersFutureLink waktuCouns' onClick={() => {setModalCheckbox(false); setSelectTime("morning-1")}} disabled={a === false ? true : false}>09.00 - 10.00</button>
                                    <button className='btn buttonVersFutureLink waktuCouns' onClick={() => {setModalCheckbox(false); setSelectTime("morning-2")}} disabled={b === false ? true : false}>10.00 - 11.00</button>
                                    <button className='btn buttonVersFutureLink waktuCouns' onClick={() => {setModalCheckbox(false); setSelectTime("morning-3")}} disabled={c === false ? true : false}>11.00 - 12.00</button>
                                </div>
                                <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Siang:</p>
                                <div style={{display: 'flex', gap: '20px'}}>
                                    <button className='btn buttonVersFutureLink waktuCouns' onClick={() => {setModalCheckbox(false); setSelectTime("afternoon-1")}} disabled={d === false ? true : false}>13.00 - 14.00</button>
                                    <button className='btn buttonVersFutureLink waktuCouns' onClick={() => {setModalCheckbox(false); setSelectTime("afternoon-2")}} disabled={e === false ? true : false}>14.00 - 15.00</button>
                                </div>
                                <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Sore:</p>
                                <div style={{display: 'flex', gap: '20px'}}>
                                    <button className='btn buttonVersFutureLink waktuCouns' onClick={() => {setModalCheckbox(false); setSelectTime("evening-1")}} disabled={f === false ? true : false}>15.00 - 16.00</button>
                                    <button className='btn buttonVersFutureLink waktuCouns' onClick={() => {setModalCheckbox(false); setSelectTime("evening-2")}} disabled={g === false ? true : false}>16.00 - 17.00</button>
                                </div>
                                <p style={{marginTop: '10px', marginBottom: '5px', fontSize: '12px'}}>Malam:</p>
                                <div style={{display: 'flex', gap: '20px'}}>
                                    <button className='btn buttonVersFutureLink waktuCouns' onClick={() => {setModalCheckbox(false); setSelectTime("night-1")}} disabled={h === false ? true : false}>19.00 - 20.00</button>
                                    <button className='btn buttonVersFutureLink waktuCouns' onClick={() => {setModalCheckbox(false); setSelectTime("night-2")}} disabled={i === false ? true : false}>20.00 - 21.00</button>
                                </div>

                                </div>

                                </>
                            )}

                            </>
                        )}
                        </>
                    )}



                </ModalBody>
            </Modal>

            <Modal isOpen={modalAlertVerify} toggle={() => {setModalAlertVerify(!modalAlertVerify)}} centered >
                <div style={{marginTop: '30px', marginBottom: '30px'}}>
                    <Lottie options={{loop: true,autoplay: true,animationData: animateAlert,rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} height={250} width={250} isClickToPauseDisabled={true} />
                </div>
                <div style={{marginBottom: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <p style={{textAlign: 'center', fontSize: '18px', fontWeight: '500', color: '#374151'}}>Anda Belum Verify Email <br/>Silahkan Pergi ke Halaman Profile</p>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default Purchase;
import React, { useState, useEffect } from "react";
import {Row, Col} from "reactstrap";
import {Link} from 'react-router-dom';

import axios from 'axios'

const ListReadingClient = (e) => {
    const state = e.state.data
    const [listArticle, setlistArticle] = useState([])
    const [listVideo, setlistVideo] = useState([])

    const getArticle = () => {
        let config = {
        method: 'get',
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-article?page=0&orderDate=desc`,
        };

        axios.request(config)
        .then((response) => {
            const array = response.results.map((item) => (
                {
                    article_content: new DOMParser().parseFromString(item.article_content, 'text/html').body.textContent.length < 75 ? new DOMParser().parseFromString(item.article_content, 'text/html').body.textContent : new DOMParser().parseFromString(item.article_content, 'text/html').body.textContent.slice(0, 75) + "...", 
                    article_title: item.article_title.length < 25 ? item.article_title : item.article_title.slice(0, 25) + '...',
                    author: item.author,
                    created_at: new Date(item.created_at).toLocaleDateString('id-Id', { day: 'numeric', month: 'long', year: 'numeric' }),
                    linkMinio: item.linkMinio,
                    url: item.url
                }))
            setlistArticle(array)
        })
        .catch((error) => {
            console.log(error);
            setlistArticle([])
        });
    }
    const getVideo = () => {
        let config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-videos?page=0&orderDate=desc`,
          };
          
          axios.request(config)
          .then((response) => {
            const array = response.results.map((item) => (
                {
                    title: item.title,
                    clickbait: item.clickbait,
                    url: item.url,
                    created_at: new Date(item.created_at).toLocaleDateString('id-Id', { day: 'numeric', month: 'long', year: 'numeric' }),
                }
            ))
            setlistVideo(array);
          })
          .catch((error) => {
            console.log(error);
          });
    }

    useEffect(() => {
        getArticle();
        getVideo();
    },[])
    
    return (
    <Row className="corev2">
        <Row>
            {state === "article" && (
                <>
                {listArticle.map((item, index) => (
                    <Col key={index} lg={4} >
                        <div  onClick={()=>{window.open(item.url, "_blank")}} style={{cursor: 'pointer'}}>
                            <section>
                                <img src={item.linkMinio} className="dasArIm"/>
                                <p style={{display: 'flex', gap: '8px'}}>{item.author} <span> • </span> <span>{item.created_at}</span></p>
                            </section>
                            <div style={{marginTop: '18px'}}>
                                <h2 style={{margin: '0px', fontSize: '14px'}}>{item.article_title}</h2>
                                <p>{item.article_content}</p>
                            </div>
                        </div>
                    </Col>
                ))}
                </>
            )}
            {state === "video" && (
                <>
                {listVideo.map((item, index) => (
                    <Col key={index} lg={4} >
                        <div  onClick={()=>{window.open(item.url, "_blank")}} style={{cursor: 'pointer'}}>
                            <section>
                                <img src={item.clickbait} className="dasArIm"/>
                                <p style={{display: 'flex', gap: '8px'}}><span>{item.created_at}</span></p>
                            </section>
                            <div style={{marginTop: '18px'}}>
                                <h2 style={{margin: '0px', fontSize: '14px'}}>{item.title}</h2>
                            </div>
                        </div>
                    </Col>
                ))}
                </>
            )}
        </Row>
    </Row>
    )
}

export default ListReadingClient
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Progress} from 'reactstrap';

import Lottie from 'react-lottie';
import accessDenied from '../../../../assets/images/FutureLink/accessDenied.json';

import hijau from '../../../../assets/images/FutureLink/hijau.png';
import kuning from '../../../../assets/images/FutureLink/kuning.png';
import oren from '../../../../assets/images/FutureLink/oren.png';
import merah from '../../../../assets/images/FutureLink/merah.png';
import ungu from '../../../../assets/images/FutureLink/ungu.png';
import biru from '../../../../assets/images/FutureLink/biru.png';

import minat from '../../../../assets/images/FutureLink/minat.png';


const MinatKejuruan= (haisayang) => {
    if(haisayang.history.location.state === undefined){
        window.location.href = "/report"
    }

    const data = haisayang.history.location.state.data
    // console.log(haisayang.history.location.state.data)

    const packages = haisayang.history.location.state.package_id
    // console.log(haisayang.history.location.state.package_id)

    return(
        <React.Fragment>
            <div className="page-content">

                {packages === 33 ? (
                    <>
                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                        <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 40px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Minat Kejuruan</p>
                    </div>

                    <div className='minatkejuruan'>
                        <Link style={{marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to='/report'>Back</Link>

                        <div className='minatkejuruanIntro'>

                            <p style={{fontSize: '18px', fontWeight: '700'}}>John L. Holland, RIASEC Theory</p>

                            <p style={{margin: '0px', textAlign: 'justify', fontSize: '14px'}}>Teori Holland menjelaskan bahwa orang dapat dikategorikan menurut enam tipe karakteristik (Realistic, Investigative, Artistic, Social, Enterprising, dan Conventional) yang dikenal secara kolektif sebagai RIASEC. Teori Holland tidak berasumsi bahwa seseorang hanya satu jenis atau hanya ada enam jenis orang di dunia, sebaliknya ia berasumsi bahwa setiap orang dapat digambarkan memiliki minat yang terkait dengan masing-masing dari enam jenis dalam urutan preferensi yang menurun.</p>

                            <div style={{display: 'flex', justifyContent: 'center', padding: '100px'}}>
                                <img src={minat} style={{width: '100%'}} />
                            </div>

                            <p style={{margin: '0px', textAlign: 'justify', fontSize: '14px'}}>Teori Holland menunjukkan bahwa setiap orang memiliki enam jenis minat yang persentasenya berbeda-beda setiap orang. Teori ini diterapkan dalam inventaris minat dan klasifikasi pekerjaan, biasanya hanya dua atau tiga kode paling dominan yang digunakan untuk bimbingan minat kejuruan. Berikut adalah perbandingan persentase dari keenam minat yang ada pada dirimu: </p>

                        </div>

                        <div>

                            <div style={{display: 'flex', flexDirection: 'column', gap: '30px', margin: '40px 0px'}}>
                                <div>
                                    <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>Realistic</p>
                                    <p style={{fontSize: '12px'}}>The “Do-ers”</p>
                                    <Progress value={data.riasec_r*2} className='progress-xl animated-progess progress-label'
                                        color={
                                            data.riasec_r < 11 ? "red" : 
                                            data.riasec_r < 17 ? "yellow" :"green"
                                        }
                                    >                      
                                        <div className='label' style={{right: '-60px'}} >{data.riasec_r}%</div>
                                    </Progress>
                                    <p className='minatkejuruanKeterangan'>
                                        <span style={{fontWeight: '900'}}>Bidang Minat: </span> Suka bekerja terutama menggunakan tangan, senang membuat, memperbaiki, merakit atau membangun sesuatu, dapat menggunakan dan mengoperasikan peralatan, perkakas atau mesin dengan mudah, serta suka bekerja di luar ruangan.
                                        <br/><br/>
                                        <span style={{fontWeight: '900'}}>Keterampilan Utama: </span> Menggunakan dan mengoperasikan peralatan, perkakas, dan mesin, merancang, membangun, memperbaiki, memelihara, bekerja secara manual, mengukur, bekerja secara detail, mengemudi, memindahkan, merawat hewan, bekerja dengan tumbuhan.
                                        <br/><br/>
                                        <span style={{fontWeight: '900'}}>Minat Studi: </span> Teknik elektro, teknik penerbangan, teknik metalurgi, teknik mesin, teknik pertambangan, teknik sipil, pertanian, peternakan atau ilmu komputer.
                                        <br/><br/>
                                        <span style={{fontWeight: '900'}}>Profesi: </span> ahli jaringan komputer, ahli drafter gambar arsitektur, teknisi mesin, teknisi komputer, pilot, dan lain sebagainya.
                                    </p>

                                </div>
                                <div>
                                    <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>Investigative</p>
                                    <p style={{fontSize: '12px'}}>The “Thinkers”</p>
                                    <Progress value={data.riasec_i*2} className='progress-xl animated-progess progress-label'
                                        color={
                                            data.riasec_i < 15.48 ? "red" : 
                                            data.riasec_i < 20.44 ? "yellow" :"green"
                                        }
                                    >                      
                                        <div className='label' style={{right: '-60px'}} >{data.riasec_i}%</div>
                                    </Progress>
                                    <p className='minatkejuruanKeterangan'>
                                        <span style={{fontWeight: '900'}}>Bidang Minat: </span> Suka menemukan dan meneliti ide, senang mengamati, menyelidiki dan bereksperimen, senang mengajukan pertanyaan dan memecahkan masalah.
                                        <br/><br/>
                                        <span style={{fontWeight: '900'}}>Keterampilan Utama: </span>  Berpikir analitis dan logis, komputasi, berkomunikasi dengan menulis dan berbicara, merancang, merumuskan, menghitung, mendiagnosis, bereksperimen, dan menyelidiki.
                                        <br/><br/>
                                        <span style={{fontWeight: '900'}}>Minat Studi: </span> Teknik informatika, teknik elektro, teknik kimia, teknik industri, farmasi, MIPA, oseanografi, statistik, akuntansi-perpajakan, psikologi, hukum, kedokteran umum dan gigi.
                                        <br/><br/>
                                        <span style={{fontWeight: '900'}}>Profesi: </span> Dokter, psikolog, intelijen, engineer, saintis, pharmacist, programmer, dokter gigi, analis komputer, aktuari, ahli statistik, professor, ekonom, akuntan, pengacara, dan lain sebagainya.
                                        </p>
                                </div>
                                <div>
                                    <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>Artistic</p>
                                    <p style={{fontSize: '12px'}}>The “Creators”</p>
                                    <Progress value={data.riasec_a*2} className='progress-xl animated-progess progress-label'
                                        color={
                                            data.riasec_a < 14.7 ? "red" : 
                                            data.riasec_a < 18.46 ? "yellow" :"green"
                                        }
                                    >                      
                                        <div className='label' style={{right: '-60px'}} >{data.riasec_a}%</div>
                                    </Progress>
                                    <p className='minatkejuruanKeterangan'>
                                        <span style={{fontWeight: '900'}}>Bidang Minat: </span> Suka menggunakan kata-kata, seni, musik atau drama untuk berkomunikasi, senang mengekspresikan diri, membuat dan merancang sesuatu, mandiri dan mengekspresikan diri secara bebas menggunakan imajinasi dan kreativitas mereka.
                                        <br/><br/>
                                        <span style={{fontWeight: '900'}}>Keterampilan Utama: </span> Mengekspresikan diri secara artistik atau fisik, berbicara, menulis, menyanyi, melakukan, merancang, menyajikan, merencanakan, mengarang, bermain, menari.
                                        <br/><br/>
                                        <span style={{fontWeight: '900'}}>Minat Studi: </span> Seni musik, teater, seni desain multimedia, teknik arsitektur dan planologi, jurnalistik, broadcasting, ilmu dan bahasa asing, dan ilmu kependidikan.
                                        <br/><br/>
                                        <span style={{fontWeight: '900'}}>Profesi: </span> Jurnalis, arsitek, seniman, desainer interior, desainer produk, desainer kriya, musikus, web desainer, aktor atau aktris, pengarang komik, penulis, penerjemah, penyaor, dan lain sebagainya.
                                    </p>
                                </div>
                                <div>
                                    <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>Social</p>
                                    <p style={{fontSize: '12px'}}>The “Helpers”</p>
                                    <Progress value={data.riasec_s*2} className='progress-xl animated-progess progress-label'
                                        color={
                                            data.riasec_s < 14.41 ? "red" : 
                                            data.riasec_s < 18.05 ? "yellow" :"green"
                                        }
                                    >                      
                                        <div className='label' style={{right: '-60px'}} >{data.riasec_s}%</div>
                                    </Progress>
                                    <p className='minatkejuruanKeterangan'>
                                        <span style={{fontWeight: '900'}}>Bidang Minat: </span> Suka bekerja dengan orang untuk mengajar, melatih dan menginformasikan sesuatu, senang membantu, mengobati, menyembuhkan, melayani dan menyapa, peduli terhadap kesejahteraan dan keselamatan orang lain, cenderung sangat humanistik dalam berinteraksi dan menikmati kebersamaan dengan orang lain.
                                        <br/><br/>
                                        <span style={{fontWeight: '900'}}>Keterampilan Utama: </span> Berkomunikasi secara lisan atau tertulis, merawat dan mendukung, melatih, bertemu, menyapa, membantu, mengajar, menginformasikan, wawancara, pembinaan.
                                        <br/><br/>
                                        <span style={{fontWeight: '900'}}>Minat Studi: </span> Antropologi, sosiologi budaya, ilmu politik, marketing, bisnis dan manajemen, ilmu komunikasi, ilmu kependidikan, psikologi sosial, perhotelan dan pariwisata, keperawatan.
                                        <br/><br/>
                                        <span style={{fontWeight: '900'}}>Profesi: </span> Guru, perawat, pekerja sosial, sosiolog, pustakawan, spiritualis, konselor, waitress, psikolog, receptionist, dan lain sebagainya.
                                    </p>
                                </div>
                                <div>
                                    <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>Enterpricing</p>
                                    <p style={{fontSize: '12px'}}>The “Persuaders”</p>
                                    <Progress value={data.riasec_e*2} className='progress-xl animated-progess progress-label'
                                        color={
                                            data.riasec_e < 17.03 ? "red" : 
                                            data.riasec_e < 19.91 ? "yellow" :"green"
                                        }
                                    >                      
                                        <div className='label' style={{right: '-60px'}} >{data.riasec_e}%</div>
                                    </Progress>
                                    <p className='minatkejuruanKeterangan'>
                                        <span style={{fontWeight: '900'}}>Bidang Minat: </span> Suka bertemu orang, memimpin, berbicara dan mempengaruhi orang lain, terbiasa mendorong orang lain, bekerja dalam bisnis, cenderung sangat berorientasi pada tujuan dan menginginkan posisi yang lebih tinggi dalam suatu organisasi.
                                        <br/><br/>
                                        <span style={{fontWeight: '900'}}>Keterampilan Utama: </span> Menjual, merencakan, mempromosikan dan membujuk, mengembangkan ide, berbicara di depan umum, mengelola, mengatur, memimpin dan menjadi pemimpin.
                                        <br/><br/>
                                        <span style={{fontWeight: '900'}}>Minat Studi: </span> Manajemen, perbankan, marketing, SDM industri, enterpreunership, ilmu kependidikan, dakwah, hubungan internasional, hukum.
                                        <br/><br/>
                                        <span style={{fontWeight: '900'}}>Profesi: </span> Wirausahawan, sales, politisi, tentara, polisi, manager perusahaan, CEO perusahaan, perencana keuangan, pengacara, konsulat, akuntan pajak, penceramah, kepala sekolah, dan lain sebagainya.
                                    </p>
                                </div>
                                <div>
                                    <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>Conventional</p>
                                    <p style={{fontSize: '12px'}}>The “Organizers”</p>
                                    <Progress value={data.riasec_c*2} className='progress-xl animated-progess progress-label'
                                        color={
                                            data.riasec_c < 15.6 ? "red" : 
                                            data.riasec_c < 17.86 ? "yellow" :"green"
                                        }
                                    >                      
                                        <div className='label' style={{right: '-60px'}} >{data.riasec_c}%</div>
                                    </Progress>
                                    <p className='minatkejuruanKeterangan'>
                                        <span style={{fontWeight: '900'}}>Bidang Minat: </span> Suka bekerja di dalam ruangan dan mengerjakan tugas-tugas yang melibatkan pengorganisasian dan ketelitian, mengikuti prosedur, bekerja dengan data atau angka, merencanakan pekerjaan dan acara, memiliki kemampuan klerikal dan numeriknya yang baik sehingga efisien dalam bekerja dengan data dalam jumlah besar.
                                        <br/><br/>
                                        <span style={{fontWeight: '900'}}>Keterampilan Utama: </span> Menghitung dan mengetik, merekam dan menyimpan catatan, memperhatikan detail, bertemu dan menyapa, melakukan perhitungan, menangani uang, mengatur, serta bekerja secara mandiri.
                                        <br/><br/>
                                        <span style={{fontWeight: '900'}}>Minat Studi: </span> Administrasi niaga, akuntansi, perpajakan, manajemen, ekonomi pembangunan, perbankan, kenotariatan, matematika terapan, aktuaria, statistik, teknik informatika.
                                        <br/><br/>
                                        <span style={{fontWeight: '900'}}>Profesi: </span> Banker, bendahara perusahaan, analis keuangan, pekerja pengolah data, budget analyst, tata usaha, kasir, administrator, sistem administrator, data manager, notaris, dan lain sebagainya.
                                    </p>
                                </div>
                            </div>

                            <div className='minatkejuruanIntro' style={{ gap: '30px'}}>
                                <div>
                                    <div style={{ display: 'flex' ,alignItems: 'center', justifyContent: 'flex-start', gap: '30px'}}>
                                        <div >
                                            <p style={{color: '#0F7173', fontWeight: '700', fontSize: '28px'}}>Realistic</p>
                                            <p style={{color: '#0F7173', fontWeight: '500', fontSize: '18px'}}>The “Do-ers”</p>
                                        </div>
                                        <img src={hijau} style={{width: '120px'}}/>
                                        <div style={{margin: '0px', width: '50px', height: '50px', backgroundColor: '#979797', color:'white', borderRadius: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', top: '40px'}}>
                                            <p style={{margin: '0px', fontSize: '24px', fontWeight: '700', paddingBottom: '2px'}}>R</p>
                                        </div>
                                    </div>
                                        <p style={{fontSize: '14px', marginTop: '30px'}}>
                                            <span style={{fontWeight: '900'}}>Bidang Minat: </span> Suka bekerja terutama menggunakan tangan, senang membuat, memperbaiki, merakit atau membangun sesuatu, dapat menggunakan dan mengoperasikan peralatan, perkakas atau mesin dengan mudah, serta suka bekerja di luar ruangan.
                                            <br/><br/>
                                            <span style={{fontWeight: '900'}}>Keterampilan Utama: </span> Menggunakan dan mengoperasikan peralatan, perkakas, dan mesin, merancang, membangun, memperbaiki, memelihara, bekerja secara manual, mengukur, bekerja secara detail, mengemudi, memindahkan, merawat hewan, bekerja dengan tumbuhan.
                                            <br/><br/>
                                            <span style={{fontWeight: '900'}}>Minat Studi: </span> Teknik elektro, teknik penerbangan, teknik metalurgi, teknik mesin, teknik pertambangan, teknik sipil, pertanian, peternakan atau ilmu komputer.
                                            <br/><br/>
                                            <span style={{fontWeight: '900'}}>Profesi: </span> ahli jaringan komputer, ahli drafter gambar arsitektur, teknisi mesin, teknisi komputer, pilot, dan lain sebagainya.
                                        </p>
                                </div>
                                <div>
                                    <div style={{ display: 'flex' ,alignItems: 'center', justifyContent: 'flex-start', gap: '30px'}}>
                                        <div >
                                            <p style={{color: '#FFBE0B', fontWeight: '700', fontSize: '28px'}}>Investigative</p>
                                            <p style={{color: '#FFBE0B', fontWeight: '500', fontSize: '18px'}}>The “Thinkers”</p>
                                        </div>
                                        <img src={kuning} style={{width: '85px'}}/>
                                        <div style={{margin: '0px', width: '50px', height: '50px', backgroundColor: '#979797', color:'white', borderRadius: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', top: '40px'}}>
                                            <p style={{margin: '0px', fontSize: '24px', fontWeight: '700', paddingBottom: '2px'}}>I</p>
                                        </div>
                                    </div>
                                        <p style={{fontSize: '14px', marginTop: '30px'}}>
                                            <span style={{fontWeight: '900'}}>Bidang Minat: </span> Suka menemukan dan meneliti ide, senang mengamati, menyelidiki dan bereksperimen, senang mengajukan pertanyaan dan memecahkan masalah.
                                            <br/><br/>
                                            <span style={{fontWeight: '900'}}>Keterampilan Utama: </span>  Berpikir analitis dan logis, komputasi, berkomunikasi dengan menulis dan berbicara, merancang, merumuskan, menghitung, mendiagnosis, bereksperimen, dan menyelidiki.
                                            <br/><br/>
                                            <span style={{fontWeight: '900'}}>Minat Studi: </span> Teknik informatika, teknik elektro, teknik kimia, teknik industri, farmasi, MIPA, oseanografi, statistik, akuntansi-perpajakan, psikologi, hukum, kedokteran umum dan gigi.
                                            <br/><br/>
                                            <span style={{fontWeight: '900'}}>Profesi: </span> Dokter, psikolog, intelijen, engineer, saintis, pharmacist, programmer, dokter gigi, analis komputer, aktuari, ahli statistik, professor, ekonom, akuntan, pengacara, dan lain sebagainya.
                                        </p>
                                </div>
                                <div>
                                    <div style={{ display: 'flex' ,alignItems: 'center', justifyContent: 'flex-start', gap: '30px'}}>
                                        <div >
                                            <p style={{color: '#FB5607', fontWeight: '700', fontSize: '28px'}}>Artistic</p>
                                            <p style={{color: '#FB5607', fontWeight: '500', fontSize: '18px'}}>The “Creators”</p>
                                        </div>
                                        <img src={oren} style={{width: '120px'}}/>
                                        <div style={{margin: '0px', width: '50px', height: '50px', backgroundColor: '#979797', color:'white', borderRadius: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', top: '40px'}}>
                                            <p style={{margin: '0px', fontSize: '24px', fontWeight: '700', paddingBottom: '2px'}}>A</p>
                                        </div>
                                    </div>
                                        <p style={{fontSize: '14px', marginTop: '30px'}}>
                                        <span style={{fontWeight: '900'}}>Bidang Minat: </span> Suka menggunakan kata-kata, seni, musik atau drama untuk berkomunikasi, senang mengekspresikan diri, membuat dan merancang sesuatu, mandiri dan mengekspresikan diri secara bebas menggunakan imajinasi dan kreativitas mereka.
                                        <br/><br/>
                                        <span style={{fontWeight: '900'}}>Keterampilan Utama: </span> Mengekspresikan diri secara artistik atau fisik, berbicara, menulis, menyanyi, melakukan, merancang, menyajikan, merencanakan, mengarang, bermain, menari.
                                        <br/><br/>
                                        <span style={{fontWeight: '900'}}>Minat Studi: </span> Seni musik, teater, seni desain multimedia, teknik arsitektur dan planologi, jurnalistik, broadcasting, ilmu dan bahasa asing, dan ilmu kependidikan.
                                        <br/><br/>
                                        <span style={{fontWeight: '900'}}>Profesi: </span> Jurnalis, arsitek, seniman, desainer interior, desainer produk, desainer kriya, musikus, web desainer, aktor atau aktris, pengarang komik, penulis, penerjemah, penyaor, dan lain sebagainya.
                                        </p>
                                </div>
                                <div>
                                    <div style={{ display: 'flex' ,alignItems: 'center', justifyContent: 'flex-start', gap: '30px'}}>
                                        <div >
                                            <p style={{color: '#FF006E', fontWeight: '700', fontSize: '28px'}}>Social</p>
                                            <p style={{color: '#FF006E', fontWeight: '500', fontSize: '18px'}}>The “Helpers”</p>
                                        </div>
                                        <img src={merah} style={{width: '85px'}}/>
                                        <div style={{margin: '0px', width: '50px', height: '50px', backgroundColor: '#979797', color:'white', borderRadius: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', top: '40px'}}>
                                            <p style={{margin: '0px', fontSize: '24px', fontWeight: '700', paddingBottom: '2px'}}>S</p>
                                        </div>
                                    </div>
                                        <p style={{fontSize: '14px', marginTop: '30px'}}>
                                            <span style={{fontWeight: '900'}}>Bidang Minat: </span> Suka bekerja dengan orang untuk mengajar, melatih dan menginformasikan sesuatu, senang membantu, mengobati, menyembuhkan, melayani dan menyapa, peduli terhadap kesejahteraan dan keselamatan orang lain, cenderung sangat humanistik dalam berinteraksi dan menikmati kebersamaan dengan orang lain.
                                            <br/><br/>
                                            <span style={{fontWeight: '900'}}>Keterampilan Utama: </span> Berkomunikasi secara lisan atau tertulis, merawat dan mendukung, melatih, bertemu, menyapa, membantu, mengajar, menginformasikan, wawancara, pembinaan.
                                            <br/><br/>
                                            <span style={{fontWeight: '900'}}>Minat Studi: </span> Antropologi, sosiologi budaya, ilmu politik, marketing, bisnis dan manajemen, ilmu komunikasi, ilmu kependidikan, psikologi sosial, perhotelan dan pariwisata, keperawatan.
                                            <br/><br/>
                                            <span style={{fontWeight: '900'}}>Profesi: </span> Guru, perawat, pekerja sosial, sosiolog, pustakawan, spiritualis, konselor, waitress, psikolog, receptionist, dan lain sebagainya.
                                        </p>
                                </div>
                                <div>
                                    <div style={{ display: 'flex' ,alignItems: 'center', justifyContent: 'flex-start', gap: '30px'}}>
                                        <div >
                                            <p style={{color: '#8338EC', fontWeight: '700', fontSize: '28px'}}>Enterpricing</p>
                                            <p style={{color: '#8338EC', fontWeight: '500', fontSize: '18px'}}>The “Persuaders”</p>
                                        </div>
                                        <img src={ungu} style={{width: '120px'}}/>
                                        <div style={{margin: '0px', width: '50px', height: '50px', backgroundColor: '#979797', color:'white', borderRadius: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', top: '40px'}}>
                                            <p style={{margin: '0px', fontSize: '24px', fontWeight: '700', paddingBottom: '2px'}}>E</p>
                                        </div>
                                    </div>
                                        <p style={{fontSize: '14px', marginTop: '30px'}}>
                                            <span style={{fontWeight: '900'}}>Bidang Minat: </span> Suka bertemu orang, memimpin, berbicara dan mempengaruhi orang lain, terbiasa mendorong orang lain, bekerja dalam bisnis, cenderung sangat berorientasi pada tujuan dan menginginkan posisi yang lebih tinggi dalam suatu organisasi.
                                            <br/><br/>
                                            <span style={{fontWeight: '900'}}>Keterampilan Utama: </span> Menjual, merencakan, mempromosikan dan membujuk, mengembangkan ide, berbicara di depan umum, mengelola, mengatur, memimpin dan menjadi pemimpin.
                                            <br/><br/>
                                            <span style={{fontWeight: '900'}}>Minat Studi: </span> Manajemen, perbankan, marketing, SDM industri, enterpreunership, ilmu kependidikan, dakwah, hubungan internasional, hukum.
                                            <br/><br/>
                                            <span style={{fontWeight: '900'}}>Profesi: </span> Wirausahawan, sales, politisi, tentara, polisi, manager perusahaan, CEO perusahaan, perencana keuangan, pengacara, konsulat, akuntan pajak, penceramah, kepala sekolah, dan lain sebagainya.
                                        </p>
                                </div>
                                <div>
                                    <div style={{ display: 'flex' ,alignItems: 'center', justifyContent: 'flex-start', gap: '30px'}}>
                                        <div >
                                            <p style={{color: '#3A86FF', fontWeight: '700', fontSize: '28px'}}>Conventional</p>
                                            <p style={{color: '#3A86FF', fontWeight: '500', fontSize: '18px'}}>The “Organizers”</p>
                                        </div>
                                        <img src={biru} style={{width: '85px'}}/>
                                        <div style={{margin: '0px', width: '50px', height: '50px', backgroundColor: '#979797', color:'white', borderRadius: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', top: '40px'}}>
                                            <p style={{margin: '0px', fontSize: '24px', fontWeight: '700', paddingBottom: '2px'}}>C</p>
                                        </div>
                                    </div>
                                        <p style={{fontSize: '14px', marginTop: '30px'}}>
                                            <span style={{fontWeight: '900'}}>Bidang Minat: </span> Suka bekerja di dalam ruangan dan mengerjakan tugas-tugas yang melibatkan pengorganisasian dan ketelitian, mengikuti prosedur, bekerja dengan data atau angka, merencanakan pekerjaan dan acara, memiliki kemampuan klerikal dan numeriknya yang baik sehingga efisien dalam bekerja dengan data dalam jumlah besar.
                                            <br/><br/>
                                            <span style={{fontWeight: '900'}}>Keterampilan Utama: </span> Menghitung dan mengetik, merekam dan menyimpan catatan, memperhatikan detail, bertemu dan menyapa, melakukan perhitungan, menangani uang, mengatur, serta bekerja secara mandiri.
                                            <br/><br/>
                                            <span style={{fontWeight: '900'}}>Minat Studi: </span> Administrasi niaga, akuntansi, perpajakan, manajemen, ekonomi pembangunan, perbankan, kenotariatan, matematika terapan, aktuaria, statistik, teknik informatika.
                                            <br/><br/>
                                            <span style={{fontWeight: '900'}}>Profesi: </span> Banker, bendahara perusahaan, analis keuangan, pekerja pengolah data, budget analyst, tata usaha, kasir, administrator, sistem administrator, data manager, notaris, dan lain sebagainya.
                                        </p>
                                </div>
                            </div>


                        </div>

                    </div>

                    <div className='prevNext'>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report/gaya-berpikir", state: { data: data, package_id: packages }}}>{"<"} Gaya Berpikir</Link>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report/kecerdasan-majemuk", state: { data: data, package_id: packages }}}>Kecerdasan Majemuk {">"}</Link>
                    </div>
                    </>
                ) : (
                    <>
                        <Link style={{marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to='/report'>Back</Link>
                        <Lottie options={{loop: true,autoplay: true,animationData: accessDenied,rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} isClickToPauseDisabled={true} />
                        <p className='upgradePlease'> Maaf, Anda belum melakukan Upgrade yang lebih tinggi</p>
                    </>
                )}


                {/* <div style={{display: 'flex', flexDirection: 'column', gap: '15px', marginTop: '180px'}}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='arrowHijau'>
                            <div className='kontenHijau'>
                                <div>
                                    <p style={{color: '#0F7173', fontWeight: '700', fontSize: '28px'}}>Realistic</p>
                                    <p style={{color: '#0F7173', fontWeight: '500', fontSize: '18px'}}>The “Do-ers”</p>
                                </div>
                                <img src={hijau} style={{width: '120px'}}/>
                            </div>
                        </div>
                        <div className='arrowKuning'>
                            <div className='kontenKuning'>
                                <div style={{width: 'max-content', marginBottom: '30px'}}>
                                    <p style={{color: '#FFBE0B', fontWeight: '700', fontSize: '28px'}}>Investigative</p>
                                    <p style={{color: '#FFBE0B', fontWeight: '500', fontSize: '18px'}}>The “Thinkers”</p>
                                </div>
                                <img src={kuning} style={{width: '90px', marginLeft:'60px'}}/>
                            </div>
                        </div>
                        <div className='arrowOrange'></div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div className='arrowBiru'></div>
                        <div className='arrowUngu'></div>
                        <div className='arrowMerah'></div>
                    </div>
                </div> */}

            </div>
        </React.Fragment>
    )

}

export default MinatKejuruan;
import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { Card, CardBody, Col, Row } from 'reactstrap';

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";
import { Pagination, EffectFade, Autoplay } from "swiper";


const Home = () => {
    const code = sessionStorage.getItem('user');

    const [modalAccessDenied, setModalAccessDenied] = useState(false);
    const [spotlight, setSpotlight] = useState([]);
    const [listVideo, setListVideo] = useState([]);
    const [listArticle, setListArticle] = useState([]);
    const [dataVideo, setDataVideo] = useState([]);
    const [dataArticle, setDataArticle] = useState([]);

    useEffect(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-spotlight`,
            headers: { 
              'Authorization': `Bearer ${code}`
            }
          };
          
        axios.request(config)
            .then((response) => {
            setSpotlight(response.results.map((item, index) => {return {id: item.id, src: item.linkMinio, key:index}}))
            })
            .catch((error) => {
            // console.log(error);
                    if(error.response.data.statusCode === 403) {
            setModalAccessDenied(true)
        }
            // setSpotlight(
            //     <>
            //     <SwiperSlide> <img src={img6} alt="" className="img-fluid" /> </SwiperSlide>
            //     <SwiperSlide> <img src={img7} alt="" className="img-fluid" /> </SwiperSlide>
            //     <SwiperSlide> <img src={img8} alt="" className="img-fluid" /> </SwiperSlide>
            //     </>
            // )
            });

        let configv = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-videos?page=1&limit=3&orderDate=desc`,
            headers: { 
                'Authorization': `Bearer ${code}`
            }
        };
              
        axios.request(configv)
            .then((response) => {
            setListVideo(response.results)
            // console.log(response.results);
            })
            .catch((error) => {
            // console.log(error);
            if(error.response.data.statusCode === 403) {
                setModalAccessDenied(true)
            }
            });

        let configo = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-videos`,
                headers: { 
                    'Authorization': `Bearer ${code}`
                }
            };
                  
        axios.request(configo)
                .then((response) => {
                setDataVideo(response.results)
                // console.log(response.results);
                })
                .catch((error) => {
                // console.log(error);
                if(error.response.data.statusCode === 403) {
                    setModalAccessDenied(true)
                }
                });

        let configc = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-article?page=1&limit=3&orderDate=desc`,
            headers: { 
                'Authorization': `Bearer ${code}`
            }
        };
                      
        axios.request(configc)
            .then((response) => {
                setListArticle(response.results)
                // console.log(response.results);
            })
            .catch((error) => {
                // console.log(error);
            });

        let configa = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-article`,
            headers: { 
                'Authorization': `Bearer ${code}`
            }
        };
              
        axios.request(configa)
            .then((response) => {
                setDataArticle(response.results)
                // console.log(response.results);
            })
            .catch((error) => {
                // console.log(error);
            });
    },[])

    return ( 
        <React.Fragment>
            <div className="page-content">
            <Row style={{display: 'flex', justifyContent: 'center'}}>
                <Col xl={6} lg={6}>
                    <Card>
                        <CardBody style={{padding: '0px'}}>

                            <Swiper spaceBetween={30} effect={"fade"} pagination={{ clickable: true, }} modules={[EffectFade, Pagination, Autoplay]} loop={true} autoplay={{ delay: 2500, disableOnInteraction: false }} className="mySwiper swiper effect-fade-swiper rounded" >
                                {spotlight.map((item, index) => (
                                    <>
                                    <SwiperSlide>
                                        <img key={index} src={item.src} style={{width: '100%', height: 'max-content'}} />
                                    </SwiperSlide>
                                    </>
                                ))}
                            </Swiper>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <div className='news'>
                <div style={{display:'flex', justifyContent: 'space-between'}}>
                    <p style={{fontSize: '18px', fontWeight: '600'}}>Video</p>
                    <Link to={{pathname: "/listVideo"}} style={{fontSize: '14px'}}>See more</Link>
                </div>
                
                <div className='newsList'>
                    {listVideo.map((item, index) => (
                        <>
                            <div className='newsMenu' key={index} onClick={()=>{window.open(item.url, "_blank")}}>
                                <img src={item.clickbait}/>
                                <div className='articleKeterangan'>
                                    <p className='articleJudul'>{item.title}</p>
                                    <p className='articleTanggal'>{new Date(item.created_at).toLocaleDateString('id-ID', {day: 'numeric', month: 'long', year: 'numeric'})}</p>
                                </div>
                            </div>
                        </>
                    ))}
                </div>
            </div>

            <div className='news'>
                 <div style={{display:'flex', justifyContent: 'space-between'}}>
                    <p style={{fontSize: '18px', fontWeight: '600'}}>Article</p>
                    <Link to={{pathname: "/listArticle"}} style={{fontSize: '14px'}}>See more</Link>
                </div>
                <div className='newsList'>
                    {listArticle.map((item, index) => (
                        <>
                            <div className='newsMenu' key={index} onClick={()=>{window.open(item.url, "_blank")}}>
                                <img src={item.linkMinio}/>
                                <div className='articleKeterangan'>
                                    <p className='articleJudul'>{item.article_title}</p>
                                    <p className='articleTanggal'>{new Date(item.created_at).toLocaleDateString('id-ID', {day: 'numeric', month: 'long', year: 'numeric'})}</p>
                                </div>
                            </div>
                        </>
                    ))}
                </div>
            </div>


            </div>
        </React.Fragment>
    );
};

export default Home;
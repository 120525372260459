import React, { useState, useEffect } from "react";

import SidebarDashboard from "../TemplateDashboard/sidebarDashboard.js";
import HeaderDashboard from "../TemplateDashboard/headerDashboard.js";

import Pj from "./MenuReport/gpj.js";

const PemilihanJurusanClient = (e) => {
    if(e.location.state === undefined){
        window.location.href = "/"
    }
    const title = 'Report'
    const gate1 = true
    const gate2 = true
    const menu = 'Hasil Report Sidik Jari'
    const submenu = 'Pemilihan Jurusan'
    const state = e.location.state

    return (

        <div className="dashboardv2">
            <SidebarDashboard title={title} />
            <section className="fullwidth">
                <HeaderDashboard title={title} gate1={gate1} gate2={gate2} menu={menu} submenu={submenu} state={state} />

                <main className="mainv2">

                <Pj state={state}/>

                </main>
            </section>

        </div>
    )

};

export default PemilihanJurusanClient
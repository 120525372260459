import React, {useState} from 'react';
import {Progress} from 'reactstrap';
import {Link} from 'react-router-dom';
import ReactApexChart from "react-apexcharts";

import distribusi from '../../../../../assets/images/FutureLink/distribusi.png';

import LThumb from '../../../../../assets/images/FutureLink/LThumb.png';
import LPoint from '../../../../../assets/images/FutureLink/LPoint.png';
import LMiddle from '../../../../../assets/images/FutureLink/LMiddle.png';
import LRing from '../../../../../assets/images/FutureLink/LRing.png';
import LPinky from '../../../../../assets/images/FutureLink/LPinky.png';
import RThumb from '../../../../../assets/images/FutureLink/RThumb.png';
import RPoint from '../../../../../assets/images/FutureLink/RPoint.png';
import RMiddle from '../../../../../assets/images/FutureLink/RMiddle.png';
import RRing from '../../../../../assets/images/FutureLink/RRing.png';
import RPinky from '../../../../../assets/images/FutureLink/RPinky.png';


const DistribusiNeuron = (haisayang) => {
    const data = haisayang.history.location.state.data
    // console.log(haisayang.history.location.state.data)

    const packages = haisayang.history.location.state.package_id
    // console.log(haisayang.history.location.state.package_id)

    const token = haisayang.history.location.state.params

    const think = Math.abs(data.think);
    const auditory = Math.abs(data.auditory);
    const action = Math.abs(data.action);
    const tactile = Math.abs(data.tactile);
    const visual = Math.abs(data.visual);

    const listR = [
        {
            img: RThumb,
            title: " Ibu Jari Kanan (R1) ",
            value: data.intrapersonal,
            text: "Keterampilan intrapersonal merupakan kemampuan untuk memahami kekuatan serta kelemahan diri sendiri yang meliputi keterampilan untuk berpikir secara rasional serta mengatur koordinasi, pengendalian, dan pencapaian diri.",
        },
        {
            img: RPoint,
            title: "Jari Telunjuk Kanan (R2) ",
            value: data.logical,
            text: "Keterampilan logika merupakan kemampuan untuk menarik kesimpulan berdasarkan logika dan dapat dibuktikan dengan ilmu yang sudah diketahui yang meliputi kemampuan logika dan penalaran, serta analisis dan proses komputasi.",
        },
        {
            img: RMiddle,
            title: " Jari Tengah Kanan (R3) ",
            value: data.fine_motors,
            text: "Keterampilan motorik halus merupakan kemampuan yang melibatkan otot-otot kecil yang lebih menuntut koordinasi mata dan tangan yang meliputi keterampilan jari, mengidentifikasi tindakan, serta kontrol tangan.",
        },
        {
            img: RRing,
            title: " Jari Manis Kanan (R4) ",
            value: data.lingustic,
            text: "Keterampilan linguistik merupakan kemampuan untuk menyusun pikiran dan mampu mengungkapkan melalui kata-kata yang meliputi kemampuan dan pemahaman bahasa, pembentukan dan memori kata, serta komunikasi verbal.",
        },
        {
            img: RPinky,
            title: " Jari Kelingking Kanan (R5) ",
            value: data.naturalist,
            text: "Naturalistik merupakan kemampuan untuk mengenali, melihat perbedaan, menggolongkan, dan mengkategorikan apa yang dilihat atau dijumpai di alam dan lingkungan sekitarnya.",
        },
    ]

    const listL = [
        {
            img: LThumb,
            title: " Ibu Jari Kiri (L1) ",
            value: data.interpersonal,
            text: "Keterampilan interpersonal merupakan kemampuan untuk berinteraksi secara efektif dengan orang lain yang meliputi keterampilan dalam memimpin, belajar dalam kelompok, dan bekerja secara tim.",
        },
        {
            img: LPoint,
            title: "Jari Telunjuk Kiri (L2) ",
            value: data.imagination,
            text: "Keterampilan spasial merupakan kemampuan untuk menciptakan gambaran sesuatu berdasarkan kenyataan yang  meliputi imajinasi, pembentukan ide, visualisasi, serta berpikir kreatif.",
        },
        {
            img: LMiddle,
            title: " Jari Tengah Kiri (L3) ",
            value: data.gross_motors,
            text: "Keterampilan motorik kasar merupakan kemampuan yang melibatkan gerakan otot besar seperti otot lengan, kaki, atau bahkan otot di seluruh tubuhnya yang meliputi keterampilan dalam melakukan koordinasi gerakan serta bahasa tubuh.",
        },
        {
            img: LRing,
            title: " Jari Manis Kiri (L4) ",
            value: data.musical,
            text: "Keterampilan musikal merupakan kemampuan untuk mengelola musik serta emosi dari diri sendiri dan orang-orang di sekitar yang  meliputi kepekaan terhadap suara, ritme, nada, emosi dan perasaan, serta komunikasi non-verbal.",
        },
        {
            img: LPinky,
            title: " Jari Kelingking Kiri (L5) ",
            value: data.visual_spatial,
            text: "Keterampilan visual merupakan kemampuan seseorang untuk memahami, menyimpan, mengingat, dan menciptakan gambaran yang meliputi apresiasi visual, interpretasi estetika visual, seni visualisasi, serta introspeksi.",
        },
    ]

    const listProsesBerpikir = [
        {
            color: '#EFC3E6',
            title: "Action",
            value: action,
            textBold: "ibu jari",
            text1: "Proses bertindak ditunjukkan oleh",
            text2: "yang menggambarkan kemampuan untuk merencanakan dan melaksanakan tujuan. Selain itu juga menggambarkan cara menghadapi diri sendiri dan orang lain.",
        },
        {
            color: '#9C89B8',
            title: "Think",
            value: think,
            textBold: "jari telunjuk",
            text1: "Proses berpikir ditunjukkan oleh",
            text2: "yang menggambarkan kemampuan untuk menerapkan pemikiran logis dan imajiner. Selain itu juga menunjukkan cara berpikir setiap orang.",
        },
        {
            color: "#F0E6E0",
            title: "Tactile",
            value: tactile,
            textBold: "jari tengah",
            text1: "Proses taktil ditunjukkan oleh",
            text2: "yang menggambarkan kemampuan dalam mengkoordinasikan gerak tubuh, gerakan tangan, dan koordinasi mata-tangan diri sendiri.",
        },
        {
            color: '#F0A6CA',
            title: "Auditory",
            value: auditory,
            textBold: "jari manis",
            text1: "Proses auditori ditunjukkan oleh",
            text2: "yang menggambarkan kemampuan untuk memproses informasi dengan mendengarkan dan berbicara.",
        },
        {
            color: "#B8BED0",
            title: "Visual",
            value: visual,
            textBold: "jari kelingking",
            text1: "Proses visual ditunjukkan oleh",
            text2: "yang menggambarkan kemampuan untuk memvisualisasikan, membayangkan dan memahami berbagai hal. Selain itu juga berkaitan dengan pemrosesan informasi melalui mata setiap orang.",
        },
    ]
    
    return(
        <React.Fragment>
            <div className="page-content">
                <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                    <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 80px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Distribusi Neuron</p>
                </div>

                <div className='distribusineuron'>
                    <Link style={{width: 'max-content', marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to={`/report-mobile/${token}`}>Back</Link>

                    <div className='distribusineuronList'>
                        <div className='collection'>

                            {listR.map((item, index) => (
                                <div key={index} className='item'>
                                    <div className='value'>
                                        <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                            <p className='distribusineuronItemTitle'>{item.title}</p>
                                        </div>
                                        <p style={{fontSize: '24px', fontWeight: '900', color: '#05C38A', margin: '0px'}}>{item.value}%</p>
                                    </div>

                                    <p className='keterangan'>{item.text}</p>
                                </div>
                            ))}

                        </div>

                        <div className='collection'>

                            {listL.map((item, index) => (
                                <div key={index} className='item'>
                                    <div className='value'>
                                        <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                            <p className='distribusineuronItemTitle'>{item.title}</p>
                                        </div>
                                        <p style={{fontSize: '24px', fontWeight: '900', color: '#05C38A', margin: '0px'}}>{item.value}%</p>
                                    </div>

                                    <p className='keterangan'>{item.text}</p>
                                </div>
                            ))}

                        </div>

                    </div>

                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                        <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 80px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Proses Berpikir</p>
                    </div>

                    <ReactApexChart
                        series={[ think, auditory, action, tactile, visual]}
                        options={{        
                            chart: {
                                height: 320,
                                type: 'donut',
                            },
                            labels: ['Think', 'Auditory', 'Action', 'Tactile', 'Visual'],
                            dataLabels: {
                                enabled: false
                            },
                            legend: {
                                position: 'bottom'
                            },
                            // colors: chartDonutupdatingColors
                            colors: ['#9C89B8', '#F0A6CA', '#EFC3E6', '#F0E6E0', '#B8BED0'],
                        }}
                        type="donut"
                        height={320}
                    />

                    <div className='prosesberpikirList'>

                        {listProsesBerpikir.map((item, index) => (
                            <div key={index}>
                                <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                    <div style={{width: '24px', height: '24px', backgroundColor: item.color, borderRadius: '50px'}}></div>
                                    <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>{item.title} <span style={{marginLeft: '30px'}}>{item.value}%</span></p>
                                </div>
    
                                <div style={{marginTop: '10px'}}>
                                    <p style={{margin: '0px', fontSize: '16px', textAlign: 'justify', lineHeight: '28px'}}> {item.text1} <span style={{fontWeight: '900'}}>{item.textBold}</span> {item.text2}</p>
                                </div>
                            </div>
                        ))}

                    </div>

                </div>

                <div style={{display: 'flex', justifyContent:'space-between', paddingTop: '32px'}}>

                    {packages === 1 && (
                        <>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report-mobiles/pola-sidik-jari", state: { data: data, package_id: packages, params: token }}} onClick={() => {document.body.scrollIntoView({behavior: "smooth"})}}>{"<"} Pola Sidik Jari</Link>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report-mobiles/dominasi-otak", state: { data: data, package_id: packages, params: token }}} onClick={() => {document.body.scrollIntoView({behavior: "smooth"})}}>Dominasi Otak {">"}</Link>
                        </>
                    )}
                    {packages === 22 && (
                        <>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report-mobiles/pola-sidik-jari", state: { data: data, package_id: packages, params: token }}} onClick={() => {document.body.scrollIntoView({behavior: "smooth"})}}>{"<"} Pola Sidik Jari</Link>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report-mobiles/dominasi-otak", state: { data: data, package_id: packages, params: token }}} onClick={() => {document.body.scrollIntoView({behavior: "smooth"})}}>Dominasi Otak {">"}</Link>
                        </>
                    )}
                    {packages === 33 && (
                        <>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report-mobiles/pola-sidik-jari", state: { data: data, package_id: packages, params: token }}} onClick={() => {document.body.scrollIntoView({behavior: "smooth"})}}>{"<"} Pola Sidik Jari</Link>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report-mobiles/tfrc", state: { data: data, package_id: packages, params: token }}} onClick={() => {document.body.scrollIntoView({behavior: "smooth"})}}    >TFRC {">"}</Link>
                        </>
                    )}
                    
                </div>


            </div>
        </React.Fragment>
    )

}

export default DistribusiNeuron;
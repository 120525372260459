import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Button, Col, Row, Image, InputGroup } from "react-bootstrap";

import leftImg from "../../../assets/images/FutureLink/left.png";
import rightImg from "../../../assets/images/FutureLink/right.png";

import { saveAs } from "file-saver";

const GenerateReportManual = () => {
  const fingerType = [
    {
      name: "Whorl Centric",
      value: "W_centric",
    },
    {
      name: "Whorl Spiral",
      value: "W_spiral",
    },
    {
      name: "Whorl Elongated Centric",
      value: "W_elongated_centric",
    },
    {
      name: "Whorl Elongated Spiral",
      value: "W_elongated_spiral",
    },
    {
      name: "Whorl Imploding",
      value: "W_imploding",
    },
    {
      name: "Whorl Composite",
      value: "W_composite",
    },
    {
      name: "Target Centric Ulnar Peacock Eye",
      value: "W_peacock_centric_ulnar",
    },
    {
      name: "Spiral Ulnar Peacock Eye",
      value: "W_peacock_spiral_ulnar",
    },
    {
      name: "Target Centric Radial Peacock Eye",
      value: "W_peacock_centric_radial",
    },
    {
      name: "Spiral Radial Peacock Eye",
      value: "W_peacock_spiral_radial",
    },
    {
      name: "Loop Double", //
      value: "L_double",
    },
    {
      name: "Loop Ulnar",
      value: "L_ulnar",
    },
    {
      name: "Loop Radial",
      value: "L_radial",
    },
    {
      name: "Loop Falling Ulnar",
      value: "L_fallen_ulnar",
    },
    {
      name: "Loop Falling Radial",
      value: "L_fallen_radial",
    },
    {
      name: "Arch Simple",
      value: "A_simple",
    },
    {
      name: "Arch Tented",
      value: "A_tented",
    },
    {
      name: "Arch Tented with Ulnar Loop",
      value: "A_w_L_ulnar",
    },
    {
      name: "Arch Tented with Radial Loop",
      value: "A_w_L_radial",
    },
    {
      name: "Accidental Whorl",
      value: "accidental",
    },
  ];
  // const fingerLeft = ["Thumb", "Index", "Middle", "Ring", "Pinky"];
  // const fingerRight = ["Pinky", "Ring", "Middle", "Index", "Thumb"];
  const [name, setName] = useState("");
  const [age, setAge] = useState(0);
  const [gender, setGender] = useState("male");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [school, setSchool] = useState("");
  const [grade, setGrade] = useState("");
  const [schoolAddress, setSchoolAddress] = useState("");

  const [leftPinky, setLeftPinky] = useState("W_centric");
  const [leftRing, setLeftRing] = useState("W_centric");
  const [leftMiddle, setLeftMiddle] = useState("W_centric");
  const [leftIndex, setLeftIndex] = useState("W_centric");
  const [leftThumb, setLeftThumb] = useState("W_centric");
  const [rightPinky, setRightPinky] = useState("W_centric");
  const [rightRing, setRightRing] = useState("W_centric");
  const [rightMiddle, setRightMiddle] = useState("W_centric");
  const [rightIndex, setRightIndex] = useState("W_centric");
  const [rightThumb, setRightThumb] = useState("W_centric");

  const [leftPinkyRCS, setLeftPinkyRCS] = useState(0);
  const [leftRingRCS, setLeftRingRCS] = useState(0);
  const [leftMiddleRCS, setLeftMiddleRCS] = useState(0);
  const [leftIndexRCS, setLeftIndexRCS] = useState(0);
  const [leftThumbRCS, setLeftThumbRCS] = useState(0);
  const [rightPinkyRCS, setRightPinkyRCS] = useState(0);
  const [rightRingRCS, setRightRingRCS] = useState(0);
  const [rightMiddleRCS, setRightMiddleRCS] = useState(0);
  const [rightIndexRCS, setRightIndexRCS] = useState(0);
  const [rightThumbRCS, setRightThumbRCS] = useState(0);

  const [leftPinkyImage, setLeftPinkyImage] = useState(null);
  const [leftRingImage, setLeftRingImage] = useState(null);
  const [leftMiddleImage, setLeftMiddleImage] = useState(null);
  const [leftIndexImage, setLeftIndexImage] = useState(null);
  const [leftThumbImage, setLeftThumbImage] = useState(null);
  const [rightPinkyImage, setRightPinkyImage] = useState(null);
  const [rightRingImage, setRightRingImage] = useState(null);
  const [rightMiddleImage, setRightMiddleImage] = useState(null);
  const [rightIndexImage, setRightIndexImage] = useState(null);
  const [rightThumbImage, setRightThumbImage] = useState(null);

  const [file, setFile] = useState();
  const [downloadAvailable, setDownloadAvailable] = useState(false);

  const fingerLeft = [
    {
      name: "Thumb",
      func: setLeftThumb,
      funcRCS: setLeftThumbRCS,
      funcImg: setLeftThumbImage,
      img: leftThumbImage,
      rcs: leftThumbRCS,
    },
    {
      name: "Index",
      func: setLeftIndex,
      funcRCS: setLeftIndexRCS,
      funcImg: setLeftIndexImage,
      img: leftIndexImage,
      rcs: leftIndexRCS,
    },
    {
      name: "Middle",
      func: setLeftMiddle,
      funcRCS: setLeftMiddleRCS,
      funcImg: setLeftMiddleImage,
      img: leftMiddleImage,
      rcs: leftMiddleRCS,
    },
    {
      name: "Ring",
      func: setLeftRing,
      funcRCS: setLeftRingRCS,
      funcImg: setLeftRingImage,
      img: leftRingImage,
      rcs: leftRingRCS,
    },
    {
      name: "Pinky",
      func: setLeftPinky,
      funcRCS: setLeftPinkyRCS,
      funcImg: setLeftPinkyImage,
      img: leftPinkyImage,
      rcs: leftPinkyRCS,
    },
  ];

  const fingerRight = [
    {
      name: "Thumb",
      func: setRightThumb,
      funcRCS: setRightThumbRCS,
      funcImg: setRightThumbImage,
      img: rightThumbImage,
      rcs: rightThumbRCS,
    },
    {
      name: "Index",
      func: setRightIndex,
      funcRCS: setRightIndexRCS,
      funcImg: setRightIndexImage,
      img: rightIndexImage,
      rcs: rightIndexRCS,
    },
    {
      name: "Middle",
      func: setRightMiddle,
      funcRCS: setRightMiddleRCS,
      funcImg: setRightMiddleImage,
      img: rightMiddleImage,
      rcs: rightMiddleRCS,
    },
    {
      name: "Ring",
      func: setRightRing,
      funcRCS: setRightRingRCS,
      funcImg: setRightRingImage,
      img: rightRingImage,
      rcs: rightRingRCS,
    },
    {
      name: "Pinky",
      func: setRightPinky,
      funcRCS: setRightPinkyRCS,
      funcImg: setRightPinkyImage,
      img: rightPinkyImage,
      rcs: rightPinkyRCS,
    },
  ];

  const [brainDominance, setBrainDominance] = useState(false);
  const [brainLobes, setBrainLobes] = useState(false);
  const [thoughtProcess, setThoughtProcess] = useState(false);
  const [neuronDist, setNeuronDist] = useState(false);
  const [tfrc, setTFRC] = useState(false);
  const [learningSensitivity, setLearningSensitivity] = useState(false);
  const [quotient, setQuotient] = useState(false);
  const [makingSense, setMakingSense] = useState(false);
  const [learningStyle, setLearningStyle] = useState(false);
  const [acquiringStyle, setAcquiringStyle] = useState(false);
  const [acuityDetails, setAcuityDetails] = useState(false);
  const [multiIntel, setMultiIntel] = useState(false);
  const [multiIntelDomain, setMultiIntelDomain] = useState(false);
  const [exSelection, setExSelection] = useState(false);
  const [streamSelection, setStreamSelection] = useState(false);
  const [careerSelection, setCareerSelection] = useState(false);
  const [hollandPersonality, setHollandPersonality] = useState(false);
  const [discPersonality, setDiscPersonality] = useState(false);
  const [dopePersonality, setDopePersonality] = useState(false);
  const [swotAnalysis, setSwotAnalysis] = useState(false);
  const [thoughtConcept, setThoughtConcept] = useState(false);
  const [leadershipStyle, setLeadershipStyle] = useState(false);

  const reportComponent = [
    {
      name: "Brain Dominance",
      value: "brainDominance",
      func: setBrainDominance,
    },
    { name: "Brain Lobes", value: "brainLobes", func: setBrainLobes },
    {
      name: "Thought Process",
      value: "thoughtProcess",
      func: setThoughtProcess,
    },
    { name: "Neuron Distribution", value: "neuronDist", func: setNeuronDist },
    { name: "Total Finger Ridge Count (TFRC)", value: "tfrc", func: setTFRC },
    {
      name: "Learning Sensitivity (ATD Degree)",
      value: "learningSensitivity",
      func: setLearningSensitivity,
    },
    { name: "Quotient", value: "quotient", func: setQuotient },
    { name: "Making Sense", value: "makingSense", func: setMakingSense },
    { name: "Learning Style", value: "learningStyle", func: setLearningStyle },
    {
      name: "Acquiring Style",
      value: "acquiringStyle",
      func: setAcquiringStyle,
    },
    // { name: "Acuity Details", value: "acuityDetails", func: setAcuityDetails },
    {
      name: "9 Multiple Intelligence",
      value: "multiIntel",
      func: setMultiIntel,
    },
    {
      name: "Multiple Intelligence Domain (Mc Kenzie's Theory)",
      value: "multiIntelDomain",
      func: setMultiIntelDomain,
    },
    {
      name: "Extra-CurriCular Selection based on MI",
      value: "exSelection",
      func: setExSelection,
    },
    {
      name: "Stream Selection based on MI",
      value: "streamSelection",
      func: setStreamSelection,
    },
    {
      name: "Career Selection based on MI",
      value: "careerSelection",
      func: setCareerSelection,
    },
    {
      name: "John L. Holland’s Theory of Personality",
      value: "hollandPersonality",
      func: setHollandPersonality,
    },
    {
      name: "DISC Type of Personality",
      value: "discPersonality",
      func: setDiscPersonality,
    },
    {
      name: "DOPE Type of Personality",
      value: "dopePersonality",
      func: setDopePersonality,
    },
    {
      name: "Thought Concept Comparison",
      value: "thoughtConcept",
      func: setThoughtConcept,
    },
    {
      name: "My Leadership Style",
      value: "leadershipStyle",
      func: setLeadershipStyle,
    },
  ];

  const [reportPackage, setReportPackage] = useState("a_free");
  const reportPackageList = [
    { name: "Free Package - A ", value: "a_free" },
    { name: "Educational Package - B", value: "b" },
    { name: "Professional Package - C", value: "c" },
    { name: "Preschool Package", value: "a" },
  ];

  let compiledIdentity;

  let submitManual = () => {
    setDownloadAvailable(false);

    let formData = baseFormData();

    let configCalculation = {
      url: `${process.env.REACT_APP_BASE_ENDPOINT_AI}/manual_input`,
      method: "post",
      data: formData,
    };

    let configManual = {
      url: "https://api.admin.futurelink.id/input_manual",
      method: "post",
      data: formData,
    };

    let configPDFAPI = {
      url: `${process.env.REACT_APP_BASE_ENDPOINT_AI}/manual_input_pdf_create`,
      method: "post",
      data: formData,
      responseType: "blob",
    };

    axios.request(configCalculation).then((response) => {
      // console.log(response);
      formData.append(
        "report_calculation",
        JSON.stringify(response.report_data)
      );
      formData.append(
        "report_full_data",
        JSON.stringify(response.report_full_data)
      );

      fingerLeft.forEach((fl) => {
        let ext = String(fl.img.name).split(".")[1];
        let filename =
          "left" + fl.name + name.replace(" ", "") + Date.now() + "." + ext;
        formData.append("image", fl.img, filename);
      });

      fingerRight.forEach((fr) => {
        let ext = String(fr.img.name).split(".")[1];
        let filename =
          "right" + fr.name + name.replace(" ", "") + Date.now() + "." + ext;
        formData.append("image", fr.img, filename);
      });

      axios.request(configManual).then((response) => {
        console.log("database added!");

        // PDF Creation Here
        // axios.request(configPDFAPI).then((response) => {
        //   setFile(URL.createObjectURL(response));
        //   setDownloadAvailable(true);
        // });
      });
    });
  };

  let submitReportOnly = () => {
    // console.log("call api ${process.env.REACT_APP_BASE_ENDPOINT_AI}/manual_input");
    // e.preventDefault();
    setDownloadAvailable(false);

    let formData = baseFormData();

    let configCalculation = {
      url: `${process.env.REACT_APP_BASE_ENDPOINT_AI}/manual_input`,
      method: "post",
      data: formData,
    };

    let configPDFAPI = {
      url: `${process.env.REACT_APP_BASE_ENDPOINT_AI}/manual_input_pdf_create`,
      method: "post",
      data: formData,
      responseType: "blob",
    };

    axios
      .request(configCalculation)
      .then((response) => {
        // console.log("response from manual_input=>", response);

        formData.append(
          "report_calculation",
          JSON.stringify(response.report_data)
        );
        formData.append(
          "report_full_data",
          JSON.stringify(response.report_full_data)
        );

        fingerLeft.forEach((fl) => {
          let ext = String(fl.img.name).split(".")[1];
          let filename =
            "left" + fl.name + name.replace(" ", "") + Date.now() + "." + ext;
          formData.append("image", fl.img, filename);
        });

        fingerRight.forEach((fr) => {
          let ext = String(fr.img.name).split(".")[1];
          let filename =
            "right" + fr.name + name.replace(" ", "") + Date.now() + "." + ext;
          formData.append("image", fr.img, filename);
        });

        // PDF Creation Here
        axios.request(configPDFAPI).then((response) => {
          // console.log("response create pdf ==>", typeof response);

          setFile(URL.createObjectURL(response));
          setDownloadAvailable(true);
        });
      })
      .catch((error) => {
        // console.log("error pdfnya ==>", error);
      });
  };

  let download = (e) => {
    const link = document.createElement("a");

    link.href = file;
    link.setAttribute("download", name + ".pptx"); //or any other extension
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(file);
  };

  let setNumberInput = (e, func, isFloat) => {
    let target = e.target.value;

    let re = /^[0-9\b]+$/;
    if (isFloat == true) {
      re = /^([0-9]{1,})?(\.)?([0-9]{1,})+$/;
    }

    if (re.test(target)) {
      if (target[0] == 0) {
        target = target.substring(1);
      }
      func(target);
    } else if (target == "") {
      func(0);
    } else console.log("Not a number");
  };

  function baseFormData() {
    compiledIdentity = {
      name: name,
      age: age,
      gender: gender,
      contact: contact,
      email: email,
      address: address,
      school: school,
      grade: grade,
      school_address: schoolAddress,
      package_type: reportPackage,
    };

    let formData = new FormData();
    formData.append("data", JSON.stringify(compiledIdentity));
    formData.append(
      "shapes",
      JSON.stringify([
        leftThumb,
        leftIndex,
        leftMiddle,
        leftRing,
        leftPinky,
        rightThumb,
        rightIndex,
        rightMiddle,
        rightRing,
        rightPinky,
      ])
    );

    formData.append(
      "rcs",
      JSON.stringify([
        leftThumbRCS,
        leftIndexRCS,
        leftMiddleRCS,
        leftRingRCS,
        leftPinkyRCS,
        rightThumbRCS,
        rightIndexRCS,
        rightMiddleRCS,
        rightRingRCS,
        rightPinkyRCS,
      ])
    );

    formData.append(
      "report",
      JSON.stringify({
        brainDominance: brainDominance,
        brainLobes: brainLobes,
        thoughtProcess: thoughtProcess,
        neuronDist: neuronDist,
        tfrc: tfrc,
        quotient: quotient,
        makingSense: makingSense,
        learningStyle: learningStyle,
        acquiringStyle: acquiringStyle,
        acuityDetails: acuityDetails,
        multiIntel: multiIntel,
        multiIntelDomain: multiIntelDomain,
        exSelection: exSelection,
        streamSelection: streamSelection,
        careerSelection: careerSelection,
        hollandPersonality: hollandPersonality,
        discPersonality: discPersonality,
        dopePersonality: dopePersonality,
        swotAnalysis: swotAnalysis,
        thoughtConcept: thoughtConcept,
        leadershipStyle: leadershipStyle,
      })
    );
    return formData;
  }

  useEffect(() => {
    document.addEventListener("wheel", function (event) {
      if (document.activeElement.type == "number") {
        document.activeElement.blur();
      }
    });
  }, []);

  useEffect(() => {
    console.warn(leftPinkyImage);
  }, [leftPinkyImage]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div style={{ padding: "0px 20px" }}>
          <div className="App my-4 mx-auto w-100">
            <Form onSubmit={submitManual}>
              {/* <Form> */}
              <div className="identity mx-4 text-start">
                <h2 className="mb-3">Identity</h2>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Age</Form.Label>
                  <Form.Control
                    type="text"
                    value={age}
                    onChange={(e) => setNumberInput(e, setAge, false)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Gender</Form.Label>
                  <Form.Select
                    defaultValue="male"
                    value={gender}
                    onChange={(e) => {
                      setGender(e.target.value);
                    }}
                  >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Contact</Form.Label>
                  <Form.Control
                    type="text"
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>School</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      setSchool(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Grade</Form.Label>
                  <Form.Control
                    type="text"
                    value={grade}
                    onChange={(e) => setGrade(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>School Address</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      setSchoolAddress(e.target.value);
                    }}
                  ></Form.Control>
                </Form.Group>
              </div>

              {/* Fingerprint Data */}
              <div className="fingerprints mx-4 text-start">
                <h2>Fingerprint Data</h2>
                <Row>
                  <Col className="left-hand">
                    <Row className="d-flex justify-content-md-center">
                      <Col className="col-xs-12 col-sm-8 col-l-8">
                        <Image className="mx-auto" src={leftImg} />
                      </Col>
                    </Row>
                    {fingerLeft.map((f) => (
                      <>
                        <Form.Group className="mb-3" id="">
                          <Form.Label className="fw-bold">
                            Left {f.name}
                          </Form.Label>
                          <Form.Select
                            defaultValue={fingerType[0].value}
                            onChange={(e) => {
                              f.func(e.target.value);
                            }}
                          >
                            {fingerType.map((ft) => (
                              <>
                                <option value={ft.value}>{ft.name}</option>
                              </>
                            ))}
                          </Form.Select>
                          <InputGroup>
                            <InputGroup.Text>Ridge Count</InputGroup.Text>
                            <Form.Control
                              type="number"
                              value={f.rcs}
                              step={0.1}
                              onChange={(e) =>
                                setNumberInput(e, f.funcRCS, true)
                              }
                            ></Form.Control>
                          </InputGroup>
                          <InputGroup>
                            <InputGroup.Text>Upload Image</InputGroup.Text>
                            <Form.Control
                              type="file"
                              onChange={(e) => {
                                f.funcImg(e.target.files[0]);
                              }}
                            />
                          </InputGroup>
                        </Form.Group>
                      </>
                    ))}
                  </Col>
                  <Col className="right-hand justify-content-md-center">
                    <Row className="d-flex justify-content-md-center">
                      <Col className="col-xs-12 col-sm-8 col-l-8">
                        <Image className="mx-auto" src={rightImg} />
                      </Col>
                    </Row>
                    {fingerRight.map((f) => (
                      <>
                        <Form.Group className="mb-3">
                          <Form.Label className="fw-bold">
                            Right {f.name}
                          </Form.Label>
                          <Form.Select
                            defaultValue={fingerType[0].value}
                            onChange={(e) => {
                              f.func(e.target.value);
                            }}
                          >
                            {fingerType.map((ft) => (
                              <>
                                <option value={ft.value}>{ft.name}</option>
                              </>
                            ))}
                          </Form.Select>
                          <InputGroup>
                            <InputGroup.Text>Ridge Count</InputGroup.Text>
                            <Form.Control
                              type="number"
                              value={f.rcs}
                              step={0.1}
                              onChange={(e) =>
                                setNumberInput(e, f.funcRCS, true)
                              }
                            ></Form.Control>
                          </InputGroup>
                          <InputGroup>
                            <InputGroup.Text>Upload Image</InputGroup.Text>
                            <Form.Control
                              type="file"
                              onChange={(e) => {
                                f.funcImg(e.target.files[0]);
                              }}
                            />
                          </InputGroup>
                        </Form.Group>
                      </>
                    ))}
                  </Col>
                </Row>
              </div>
              <div className="report mx-4 text-start">
                <h2 className="mb-3">Report Package Type</h2>
                <Row>
                  <Form.Select
                    value={reportPackage}
                    onChange={(e) => {
                      setReportPackage(e.target.value);
                    }}
                  >
                    {reportPackageList.map((reportPackage) => (
                      <>
                        <option value={reportPackage.value}>
                          {reportPackage.name}
                        </option>
                      </>
                    ))}
                  </Form.Select>
                </Row>
              </div>
              <Row className="mx-4 my-5">
                <Col className="ps-0">
                  {downloadAvailable ? (
                    <Button
                      className="w-100"
                      id="downloadBtn"
                      onClick={(e) => {
                        download(e);
                      }}
                    >
                      Download Result
                    </Button>
                  ) : (
                    <Button className="w-100" id="downloadBtn" disabled>
                      Download Result
                    </Button>
                  )}
                </Col>
                <Col className="ps-0 pe-0">
                  <Button
                    className="w-100"
                    id="submitBtnReport"
                    onClick={() => {
                      submitReportOnly();
                      // testSubmitReport();
                    }}
                  >
                    Generate Report Only
                  </Button>
                </Col>
                {/* <Col className="pe-0">
                        <Button className="w-100" id="submitBtn" type="submit">
                          Submit
                        </Button>
                      </Col> */}
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GenerateReportManual;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useInterval } from "usehooks-ts";

import axios from "axios";

import HalfO from "../../../../assets/images/FutureLink/paro.png";
import Spm from "../../../../assets/images/FutureLink/openingSpm.png";
import Logo from "../../../../assets/images/FutureLink/complementaryLogo.png";

import end from "../../../../assets/images/FutureLink/selesaiTest.png";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import FormData from "form-data";
import Lottie from "react-lottie";
import loading from "../../../../assets/images/FutureLink/loadingBasic.json";

const animationLoading = {
  loop: true,
  autoplay: true,
  animationData: loading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const SoalTrial = () => {
  let { testId, token } = useParams();

  const [halaman, setHalaman] = useState("home");
  const [nomor, setNomor] = useState(1);
  const [sembunyi, setSembunyi] = useState("");

  const [typeNow, setTypeNow] = useState("A");

  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  const [count, setCount] = useState(1800);
  const [benar, setBenar] = useState(false);
  const [berjalan, setBerjalan] = useState("jam");

  const code = token;
  const [isTestReady, setIsTestReady] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [questionLength, setQuestionLength] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [sections, setSections] = useState([]);
  const [currentTest, setCurrentTest] = useState(null);
  const [currentAnswer, setCurrentAnswer] = useState(null);
  const [questionSections, setQuestionSections] = useState([]);
  const [currentSection, setCurrentSection] = useState(0);
  const [currentSubsection, setCurrentSubsection] = useState(0);
  const [currentPosition, setCurrentPosition] = useState(0);
  const [currentSectionPosition, setCurrentSectionPosition] = useState(0);
  const [currentSubsectionPosition, setCurrentSubsectionPosition] = useState(0);
  const [cumulativeAnswer, setCumulativeAnswer] = useState([]);

  useInterval(
    () => {
      setCount(count - 1);
      setMin(Math.floor((count / 60) % 60));
      setSec(Math.floor(count % 60));

      if (count < 61) {
        setBerjalan("jam redTime");
      }

      if (count == -1) {
        setBenar(false);
        setHalaman("selesai");
        // hitMe();
        setCount(1800);
      }
    },
    benar ? 1000 : null
  );

  useEffect(() => {
    if (nomor == 1) {
      setSembunyi("trans");
    } else {
      setSembunyi("");
    }

    console.log("USE EFFECT");
    if (halaman == "mulai") {
      updateCumulativeAnswer(currentAnswer);
    }
  }, [currentPosition]);

  useEffect(() => {
    if (currentTest) {
      getQuestionLength();
      checkIsTestReady();
    }
  }, [questions, questionSections]);

  useEffect(() => {
    if (halaman == "selesai") {
      createTestResult(testId);
    }
  }, [halaman]);

  useEffect(() => {
    getTestInfo(testId);
  }, []);

  useEffect(() => {
    if (currentTest) {
      getQuestionAndAnswer(testId);
      checkIsTestReady();
    }
  }, [currentTest]);

  const updatePage = (curPos, condition) => {
    let curSecPos = currentSectionPosition;
    let curSubSecPos = currentSubsectionPosition;
    let futureNumber;
    let futurePos;

    if (condition == "next") {
      futurePos = curPos + 1;
      futureNumber = nomor + 1;

      if (currentTest.hasSection) {
        if (
          futurePos >=
          questionSections[currentSectionPosition].testSubsections[
            currentSubsectionPosition
          ].questions.length
        ) {
          console.log("SUBSECTION CHANGE");
          futurePos = 0;
          curSubSecPos = currentSubsectionPosition + 1;
          if (
            curSubSecPos >=
            questionSections[currentSectionPosition].testSubsections.length
          ) {
            console.log("SECTION CHANGE");
            curSubSecPos = 0;
            curSecPos = currentSectionPosition + 1;
            if (curSecPos >= questionSections.length) {
              setHalaman("selesai");
            }
          }
        }
      } else if (futurePos >= questionLength) {
        setHalaman("selesai");
      }
    } else if (condition == "prev") {
      futurePos = curPos - 1;
      futureNumber = nomor - 1;

      if (currentTest.hasSection) {
        if (futurePos < 0) {
          curSubSecPos = currentSubsectionPosition - 1;
          if (curSubSecPos < 0) {
            console.log("SECTION CHANGE");
            curSecPos = currentSectionPosition - 1;

            if (curSecPos < 0) {
              curSecPos = 0;
              curSubSecPos = 0;
              futurePos = 0;
              futureNumber = 1;
            } else {
              curSubSecPos =
                questionSections[curSecPos].testSubsections.length - 1;
              futurePos =
                questionSections[curSecPos].testSubsections[curSubSecPos]
                  .questions.length - 1;
            }
          } else {
            console.log("SUBSECTION CHANGE");
            futurePos =
              questionSections[curSecPos].testSubsections[curSubSecPos]
                .questions.length - 1;
          }
        }
      } else if (futurePos < 0) {
        futurePos = 0;
        futureNumber = 0;
      }
    }

    if (halaman != "selesai") {
      setCurrentSubsectionPosition(curSubSecPos);
      setCurrentSectionPosition(curSecPos);
      setCurrentPosition(futurePos);
      setNomor(futureNumber);
    }
  };

  const getTestInfo = (testId) => {
    let urlDetail = "/v1/get-test";

    let urlPayload = { testId: testId };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}${urlDetail}`,
      headers: {
        Authorization: `Bearer ${code}`,
      },
      params: urlPayload,
    };

    axios
      .request(config)
      .then((response) => {
        let currentTest = response.results[0];
        setCurrentTest({
          testId: currentTest.test_id,
          name: currentTest.name,
          duration: currentTest.duration,
          hasSection: Boolean(parseInt(currentTest.has_section)),
          status: currentTest.status,
          price: currentTest.price,
        });
      })
      .catch((error) => {
        // setAlert(true);
      });
  };

  const createTestResult = (testId) => {
    let urlDetail = "/v1/create-test-result";

    let urlPayload = {
      testId: testId,
      answer: cumulativeAnswer,
      status: "complete",
    };

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}${urlDetail}`,
      headers: {
        Authorization: `Bearer ${code}`,
      },
      data: urlPayload,
    };

    axios
      .request(config)
      .then((response) => {
        urlDetail = "/v1/finish-user-test-result";
        let newPayload = new FormData();
        newPayload.append("testId", testId);
        newPayload.append("generatePdf", false);

        config["method"] = "patch";
        config[
          "url"
        ] = `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}${urlDetail}`;
        config["data"] = newPayload;
        axios.request(config).then((responseUpdate) => {
          // console.log(responseUpdate.data);
          // if (responseUpdate.data.statusCode == 200) {}
          console.log("finished update");
        });
      })
      .catch((error) => {
        // setAlert(true);
      });
  };

  const getQuestionAndAnswer = (testId) => {
    let requestValidation = true;

    let urlDetail = "/v1/get-test-question-and-answer";

    let urlPayload = { testId: testId, sectionable: "true" };

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}${urlDetail}`,
      headers: {
        Authorization: `Bearer ${code}`,
      },
      params: urlPayload,
    };

    if (currentTest.hasSection) {
      requestValidation
        ? axios
            .request(config)
            .then((response) => {
              console.log(response);
              setQuestionSections(
                response.results.map((item, index) => {
                  return {
                    testSectionId: item.testSectionId,
                    testSectionName: item.testSectionName,
                    testSubsections: item.testSubsections,
                  };
                })
              );

              setCurrentSection(response.results[0].testSectionId);
              setCurrentSubsection(
                response.results[0].testSubsections[0].testSubsectionId
              );
            })

            .catch((error) => {
              console.log(error);
              // setAlert(true);
            })
        : // setAlert(true);
          console.log("Hai");
    }

    urlPayload = { testId: testId, sectionable: "false" };
    config.params = urlPayload;

    requestValidation
      ? axios
          .request(config)
          .then((response) => {
            setQuestions(
              response.results.map((item, index) => {
                return {
                  qId: item.q_id,
                  aId: item.a_id,
                  questionText: item.question_text,
                  questionImage: item.question_image,
                  questionType: item.type,
                  arrangement: item.arrangement,
                };
              })
            );
            setAnswers(
              response.results.map((item, index) => {
                return {
                  qId: item.q_id,
                  aId: item.a_id,
                  answerText: item.answer_text,
                  answerImage: item.answer_image,
                };
              })
            );
          })
          .catch((error) => {
            console.log(error);
            // setAlert(true);
          })
      : // setAlert(true);
        console.log("Hai");
  };

  const updateCumulativeAnswer = (chosenAnswer) => {
    // console.log("ANSWER CHOSEN");
    // console.log(chosenAnswer);
    if (chosenAnswer) {
      let currentIdx = 0;
      let questionCheck = cumulativeAnswer.find((answer, idx) => {
        if (answer.qId == chosenAnswer.qId) {
          currentIdx = idx;
          return answer;
        }
      });

      let currentCumulativeAnswer = cumulativeAnswer;
      if (cumulativeAnswer.length > 0) {
        if (questionCheck) currentCumulativeAnswer[currentIdx] = chosenAnswer;
        else currentCumulativeAnswer.push(chosenAnswer);
      } else currentCumulativeAnswer.push(chosenAnswer);

      setCumulativeAnswer(currentCumulativeAnswer);
      setCurrentAnswer(null);
    }

    console.log(cumulativeAnswer);
  };

  const getQuestionLength = () => {
    if (currentTest.hasSection) {
      let qLength = 0;
      questionSections.map((section) => {
        section.testSubsections.map((subsection) => {
          qLength += subsection.questions.length;
        });
      });

      setQuestionLength(qLength);
    } else {
      console.log("Question length ", questions);
      setQuestionLength(questions.length);
    }
  };

  const RenderQuestionSectionLegend = () => {
    let view = <></>;
    console.log(questionSections);
    if (currentTest)
      view = (
        <div className="tCLeftTopList">
          <>
            {currentTest?.hasSection &&
            currentSectionPosition < questionSections.length ? (
              <>
                <div
                  className="tCLeftTopLine"
                  style={{
                    height:
                      52 *
                      (questionSections.length
                        ? questionSections.length - 1
                        : 0),
                  }}
                ></div>
                {questionSections.map((section) => (
                  <>
                    <div className="tCLeftTopType">
                      {questionSections[currentSectionPosition]
                        .testSectionId === section.testSectionId ? (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <circle
                              cx="12"
                              cy="12"
                              r="10"
                              fill="#FCFCFC"
                              stroke="#10B981"
                              strokeWidth={1.6}
                            />
                            <path
                              d="M12 1.99902C17.524 1.99902 22.002 6.47702 22.002 12.001C22.002 17.524 17.524 22.002 12 22.002C6.47605 22.002 1.99805 17.524 1.99805 12.001C1.99805 6.47702 6.47605 1.99902 12 1.99902ZM12 3.49902C10.8759 3.48683 9.76041 3.69772 8.71827 4.1195C7.67612 4.54127 6.72798 5.16554 5.92871 5.95619C5.12944 6.74684 4.49492 7.68816 4.06187 8.72566C3.62882 9.76317 3.40584 10.8763 3.40584 12.0005C3.40584 13.1248 3.62882 14.2379 4.06187 15.2754C4.49492 16.3129 5.12944 17.2542 5.92871 18.0449C6.72798 18.8355 7.67612 19.4598 8.71827 19.8816C9.76041 20.3033 10.8759 20.5142 12 20.502C14.2321 20.4678 16.3611 19.5571 17.9274 17.9666C19.4937 16.376 20.3717 14.2333 20.3717 12.001C20.3717 9.76874 19.4937 7.626 17.9274 6.03548C16.3611 4.44496 14.2321 3.53326 12 3.49902ZM11.997 5.99902C13.5878 5.99902 15.1134 6.63095 16.2383 7.7558C17.3631 8.88064 17.995 10.4063 17.995 11.997C17.995 13.5878 17.3631 15.1134 16.2383 16.2382C15.1134 17.3631 13.5878 17.995 11.997 17.995C10.4063 17.995 8.88066 17.3631 7.75582 16.2382C6.63098 15.1134 5.99905 13.5878 5.99905 11.997C5.99905 10.4063 6.63098 8.88064 7.75582 7.7558C8.88066 6.63095 10.4063 5.99902 11.997 5.99902Z"
                              fill="#10B981"
                            />
                          </svg>
                        </>
                      ) : (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <circle
                              cx="12"
                              cy="12"
                              r="9.3"
                              fill="#FCFCFC"
                              stroke="#10B981"
                              strokeWidth={2.5}
                            />
                          </svg>
                        </>
                      )}
                      <p>{section.testSectionName}</p>
                    </div>
                  </>
                ))}
              </>
            ) : (
              <></>
            )}
          </>
        </div>
      );

    return view;
  };

  const RenderQuestionLegendMobile = () => {
    return (
      <div className="mobileTop">
        {questionSections.map((section, idx) => (
          <>
            <p
              className={
                questionSections[currentSectionPosition].testSectionId ===
                section.testSectionId
                  ? "clickable active"
                  : "clickable"
              }
            >
              <span
                className={
                  questionSections[currentSectionPosition].testSectionId ===
                  section.testSectionId
                    ? "whiter"
                    : "whiter active"
                }
              >
                {idx + 1}
              </span>
            </p>
          </>
        ))}
      </div>
    );
  };

  const RenderQuestionNumbering = () => {
    return (
      <>
        {currentTest.hasSection && questionSections != null ? (
          <div className="grupNumber">
            {questionSections[currentSectionPosition].testSubsections[
              currentSubsectionPosition
            ].questions.map((_, idx) => (
              <>
                <span
                  className={
                    currentPosition == idx ? "number enabled" : "number"
                  }
                >
                  {idx + 1}
                </span>
              </>
            ))}
          </div>
        ) : (
          <div className="grupNumber">
            {questions.map((_, idx) => (
              <>
                <span
                  className={
                    currentPosition == idx ? "number enabled" : "number"
                  }
                >
                  {idx + 1}
                </span>
              </>
            ))}
          </div>
        )}
      </>
    );
  };

  const RenderQuestionLegendTop = () => {
    return (
      <div className="top">
        <p>
          {" "}
          {nomor} / {questionLength}{" "}
        </p>
        <p className={berjalan}>
          {" "}
          <i className="bx bx-time" style={{ marginLeft: "4px" }}></i> {"  "}{" "}
          {min > 9 ? min : "0" + min}:{sec > 9 ? sec : "0" + sec}{" "}
        </p>
      </div>
    );
  };

  const RenderQuestionAndAnswer = (curPos) => {
    let currentQuestion = questions[curPos];
    let questionAnswer = {
      aId: answers[curPos].aId,
      qId: answers[curPos].qId,
      answerText: answers[curPos].answerText,
      answerImage: answers[curPos].answerImage,
    };
    if (currentTest.hasSection) {
      let sectionQuestion =
        questionSections[currentSectionPosition].testSubsections[
          currentSubsectionPosition
        ].questions[curPos];

      if (sectionQuestion) currentQuestion = sectionQuestion;

      questionAnswer = {
        aId: currentQuestion.aId,
        qId: currentQuestion.qId,
        answerText: currentQuestion.answerText,
        answerImage: currentQuestion.answerImage,
      };
    }

    // console.log(answers[curPos]);

    let defaultAnswer = cumulativeAnswer.find(
      (answer) => answer.qId == currentQuestion.qId
    );

    return (
      <>
        {currentQuestion.questionImage && (
          <img src={currentQuestion.questionImage} className="fotoSoal" />
        )}

        {currentQuestion.questionText && <p>{currentQuestion.questionText}</p>}

        <div className="listJawaban">
          {questionAnswer.aId != null ? (
            questionAnswer.aId.map((aId, i) => (
              <>
                {questionAnswer.qId == currentQuestion.qId && (
                  <div
                    className={
                      defaultAnswer
                        ? currentAnswer
                          ? currentAnswer.aId == aId
                            ? "jawaban click"
                            : "jawaban"
                          : defaultAnswer.aId == aId
                          ? "jawaban click"
                          : "jawaban"
                        : currentAnswer && currentAnswer.aId == aId
                        ? "jawaban click"
                        : "jawaban"
                    }
                  >
                    {/* {console.log(questionAnswer)} */}
                    <input
                      type="radio"
                      id={"a" + questionAnswer.qId + aId}
                      name={"a" + questionAnswer.qId}
                      value={aId}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setCurrentAnswer({
                            qId: questionAnswer.qId,
                            aId: aId,
                          });
                        }
                      }}
                    />
                    <label htmlFor={"a" + questionAnswer.qId + aId}>
                      {questionAnswer.answerImage[i] && (
                        <img src={questionAnswer.answerImage[i]} />
                      )}
                      {questionAnswer.answerText[i] && (
                        <p>{questionAnswer.answerText[i]}</p>
                      )}
                    </label>
                  </div>
                )}
              </>
            ))
          ) : (
            <>
              <div className="answerText">
                <textarea
                  defaultValue={defaultAnswer ? defaultAnswer.aText : ""}
                  type="textarea"
                  key={"a" + questionAnswer.qId}
                  name={"a" + questionAnswer.qId}
                  id={"a" + questionAnswer.qId}
                  rows={4}
                  onChange={(event) => {
                    console.log(event.target);
                    if (event.target.value) {
                      setCurrentAnswer({
                        qId: questionAnswer.qId,
                        aText: event.target.value,
                      });
                    }
                  }}
                  className="form-control"
                  placeholder="Enter Your Answer"
                />
              </div>
            </>
          )}
        </div>
        <div className="bottom">
          <div className="grup">
            <>
              <Link
                to="#"
                className={"buttonTest bola " + sembunyi}
                disabled={nomor == 1 ? true : false}
                onClick={() => {
                  updatePage(curPos, "prev");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M10.295 19.7155C10.3886 19.8077 10.4994 19.8806 10.6211 19.93C10.7429 19.9793 10.8731 20.0042 11.0045 20.0033C11.1358 20.0023 11.2657 19.9754 11.3867 19.9243C11.5076 19.8731 11.6173 19.7986 11.7095 19.705C11.8017 19.6115 11.8746 19.5007 11.9239 19.379C11.9733 19.2572 11.9982 19.127 11.9972 18.9956C11.9963 18.8643 11.9694 18.7344 11.9183 18.6134C11.8671 18.4925 11.7926 18.3827 11.699 18.2906L6.32903 13.0006H19.999C20.2642 13.0006 20.5186 12.8952 20.7061 12.7077C20.8937 12.5201 20.999 12.2658 20.999 12.0006C20.999 11.7353 20.8937 11.481 20.7061 11.2934C20.5186 11.1059 20.2642 11.0006 19.999 11.0006H6.33603L11.7 5.71355C11.884 5.52633 11.987 5.2743 11.9867 5.01183C11.9865 4.74936 11.8831 4.49751 11.6988 4.31061C11.5145 4.12371 11.2642 4.01675 11.0017 4.01281C10.7393 4.00887 10.4858 4.10827 10.296 4.28955L3.37203 11.1116C3.25411 11.2278 3.16048 11.3664 3.09657 11.5192C3.03267 11.672 2.99976 11.8359 2.99976 12.0016C2.99976 12.1672 3.03267 12.3311 3.09657 12.4839C3.16048 12.6367 3.25411 12.7753 3.37203 12.8916L10.296 19.7146L10.295 19.7155Z"
                    fill="#FCFCFC"
                  />
                </svg>{" "}
                <span className="disapearText">Sebelumnya</span>
              </Link>
              <Link
                to="#"
                className="buttonTest bola"
                onClick={() => {
                  updatePage(curPos, "next");
                }}
              >
                <span className="disapearText">Selanjutnya</span>{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M13.704 4.28428C13.6116 4.18739 13.5006 4.11006 13.3777 4.05687C13.2548 4.00368 13.1224 3.97571 12.9885 3.97464C12.8546 3.97357 12.7218 3.9994 12.5981 4.05062C12.4743 4.10183 12.3622 4.17738 12.2682 4.27277C12.1742 4.36817 12.1003 4.48148 12.0509 4.60597C12.0016 4.73046 11.9777 4.86359 11.9808 4.99748C11.9838 5.13137 12.0138 5.26327 12.0688 5.38537C12.1238 5.50748 12.2027 5.61728 12.301 5.70828L17.67 11.0003H4C3.73478 11.0003 3.48043 11.1056 3.29289 11.2932C3.10536 11.4807 3 11.7351 3 12.0003C3 12.2655 3.10536 12.5199 3.29289 12.7074C3.48043 12.8949 3.73478 13.0003 4 13.0003H17.665L12.3 18.2853C12.1206 18.4733 12.0214 18.7238 12.0235 18.9836C12.0256 19.2435 12.1288 19.4923 12.3112 19.6774C12.4935 19.8626 12.7408 19.9694 13.0006 19.9753C13.2604 19.9813 13.5123 19.8859 13.703 19.7093L20.628 12.8873C20.7459 12.771 20.8396 12.6324 20.9035 12.4796C20.9674 12.3269 21.0003 12.1629 21.0003 11.9973C21.0003 11.8317 20.9674 11.6677 20.9035 11.5149C20.8396 11.3621 20.7459 11.2236 20.628 11.1073L13.703 4.28428H13.704Z"
                    fill="#FCFCFC"
                  />
                </svg>
              </Link>
            </>
          </div>
        </div>
      </>
    );
  };

  const checkIsTestReady = () => {
    if (
      questionSections.length > 0 &&
      questions.length > 0 &&
      answers.length > 0
    )
      setIsTestReady(true);
  };

  return (
    <React.Fragment>
      <div className="tC">
        <div className="tCLeft">
          <div className="tCLeftTop">
            <i className="ri-arrow-left-line fz24"></i>
            <div className="tCLeftTopDesc">
              <p className="tCLeftTopKeterangan">
                Type {typeNow}: <br />
                Basic Spatial
              </p>
              {RenderQuestionSectionLegend()}
            </div>
          </div>
          <div className="tCLeftBottom">
            <img src={HalfO} />
            <div className="bg">
              <div>
                <p>Apa kegunaan Test ini?</p>
                <ul>
                  <li>Pengukuran Kecerdasan Non-Verbal</li>
                  <li>Pengukuran Kecerdasan Umum</li>
                  <li>Identifikasi Bakat dan Potensi</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="tCRight">
          <div className="tCTopbar">
            <div className="web">
              <img src={Logo} />
              <p className="title">Standart Progressive Matrices Test</p>
            </div>
            <div className="mob">
              <p className="title">SPM Test</p>
            </div>
          </div>
          <div className="tCMain">
            {halaman === "home" && (
              <>
                <img src={Spm} className="open" />
                <div className="info">
                  <div className="ket">
                    <p className="title">⚠️ Petunjuk Pengerjaan Soal</p>
                    <p>
                      Tes ini akan menguji kemampuan Anda untuk mengenali pola
                      dan hubungan dalam matriks. Cobalah untuk mengidentifikasi
                      pola umum atau aturan dalam matriks sebelum Anda mencoba
                      menjawab soal.
                    </p>
                  </div>
                </div>
                <p className="waktu">Waktu Pengerjaan: 30 Menit </p>

                <button
                  className="buttonTest"
                  onClick={() => {
                    if (isTestReady) {
                      setHalaman("mulai");
                      setBenar(true);
                    } else {
                      console.log("Nuuh");
                    }
                  }}
                >
                  {isTestReady ? (
                    "Mulai Test"
                  ) : (
                    <Lottie
                      options={animationLoading}
                      height={48}
                      isClickToPauseDisabled={true}
                    />
                  )}
                </button>
              </>
            )}
            {halaman === "mulai" && (
              <>
                <div className="soal">
                  {RenderQuestionLegendMobile()}

                  {RenderQuestionLegendTop()}

                  {RenderQuestionNumbering()}

                  {RenderQuestionAndAnswer(currentPosition)}
                </div>
              </>
            )}
            {halaman === "selesai" && (
              <>
                <div className="testBerakhir">
                  <p>
                    Semua soal udah selesai, <br />
                    yuk cek hasilnya ke halaman report!
                  </p>
                  <img src={end} />
                  <Link
                    className="buttonTest end"
                    to={{ pathname: "/complementary" }}
                  >
                    Kembali
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SoalTrial;

import React, {useState} from "react";
import {Row, Col, Progress} from 'reactstrap';
import {Link} from 'react-router-dom';

import intrapersonal from '../../../../../assets/images/FutureLink/intrapersonal.png';
import interpersonal from '../../../../../assets/images/FutureLink/interpersonal.png';
import logical from '../../../../../assets/images/FutureLink/logical.png';
import visual from '../../../../../assets/images/FutureLink/visual1.png';
import kinesthetic from '../../../../../assets/images/FutureLink/kinesthetic1.png';
import linguistik from '../../../../../assets/images/FutureLink/linguistik.png';
import musical from '../../../../../assets/images/FutureLink/musical.png';
import naturalist from '../../../../../assets/images/FutureLink/naturalist.png';
import existential from '../../../../../assets/images/FutureLink/existential.png';



const KecerdasanMajemuk = (e) => {
    const state = e.state.data
    const data = e.state.data
    const package_id = e.state.package_id
    const img_finger = e.state.img_finger
    const shape_finger = e.state.shape_finger


    const intra = state["8_intra"]
    const inter = state["8_inter"]
    const log = state["8_logical"]
    const vis = state["8_visual"]
    const kin = state["8_bodily_kinesthetic"]
    const lin = state["8_linguistic"]
    const mus = state["8_musical"]
    const nat = state["naturalist"]
    const exi = state.existential

    const array = [
        {
            j: 'Intrapersonal',
            k: 'Kemampuan untuk memahami diri sendiri',
            i: intrapersonal,
            v: intra,
        },
        {
            j: 'Interpersonal',
            k: 'Kemampuan untuk memahami orang lain.',
            i: interpersonal,
            v: inter,
        },
        {
            j: 'Logical',
            k: 'Kemampuan untuk bekerja dengan angka dan pemahaman logis.',
            i: logical,
            v: log,
        },
        {
            j: 'Visual',
            k: 'Kemampuan untuk memahami sesuatu dengan melihat atau membayangkan.',
            i: visual,
            v: vis,
        },
        {
            j: 'Kinesthetic',
            k: 'Kemampuan untuk beraktivitas dengan kekuatan tubuh dan tangan.',
            i: kinesthetic,
            v: kin,
        },
        {
            j: 'Linguistik',
            k: 'Kemampuan berekspresi secara verbal dan non-verbal dengan lancar',
            i: linguistik,
            v: lin,
        },
        {
            j: 'Musical',
            k: 'Kemampuan untuk memahami suara, serta kompatibilitas dengan emosi dan ekspresi.',
            i: musical,
            v: mus,
        },
        {
            j: 'Naturalist',
            k: 'Kemampuan untuk terhubung dengan lingkungan naturalistik',
            i: naturalist,
            v: nat,
        },
        {
            j: 'Existential',
            k: 'Kemampuan untuk memahami keberadaan manusia.',
            i: existential,
            v: exi,
        },
    ]


    return(
        <Row className="corev2">
            <div className="appearOnMobile tiwtiw" style={{marginBottom: '24px'}}>
                <Link to={{pathname: '/client/reportFP', state: { data: data, package_id: package_id, img_finger:img_finger, shape_finger:shape_finger },}} className="bBek">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M10.295 19.7155C10.3886 19.8077 10.4994 19.8806 10.6211 19.93C10.7429 19.9793 10.8731 20.0042 11.0045 20.0033C11.1358 20.0023 11.2657 19.9754 11.3867 19.9243C11.5076 19.8731 11.6173 19.7986 11.7095 19.705C11.8017 19.6115 11.8746 19.5007 11.9239 19.379C11.9733 19.2572 11.9982 19.127 11.9972 18.9956C11.9963 18.8643 11.9694 18.7344 11.9183 18.6134C11.8671 18.4925 11.7926 18.3827 11.699 18.2906L6.32903 13.0006H19.999C20.2642 13.0006 20.5186 12.8952 20.7061 12.7077C20.8937 12.5201 20.999 12.2658 20.999 12.0006C20.999 11.7353 20.8937 11.481 20.7061 11.2934C20.5186 11.1059 20.2642 11.0006 19.999 11.0006H6.33603L11.7 5.71355C11.884 5.52633 11.987 5.2743 11.9867 5.01183C11.9865 4.74936 11.8831 4.49751 11.6988 4.31061C11.5145 4.12371 11.2642 4.01675 11.0017 4.01281C10.7393 4.00887 10.4858 4.10827 10.296 4.28955L3.37203 11.1116C3.25411 11.2278 3.16048 11.3664 3.09657 11.5192C3.03267 11.672 2.99976 11.8359 2.99976 12.0016C2.99976 12.1672 3.03267 12.3311 3.09657 12.4839C3.16048 12.6367 3.25411 12.7753 3.37203 12.8916L10.296 19.7146L10.295 19.7155Z" fill="#374151"/>
                </svg>
                </Link>
                <p className="antitank" style={{textAlign: 'center'}}>Kecerdasan Majemuk</p>
            </div>

            <div className="kecerdasaanCSS goneOnMobile">
                <div className="itTop">
                    <p className="kecmaNum">01</p>
                    <div className="kecmaOrb"></div>
                    <div className="kecmaImg">
                        <div className="kecmaBox">
                            <p className="kecmaKet">Intrapersonal</p>
                            <p className="kecmaIsi">Kemampuan untuk memahami diri sendiri.</p>
                        </div>
                        <img src={intrapersonal}/>
                        <p className="kecP">{intra}%</p>
                    </div>
                </div>
                <div className="itBot">
                    <p className="kecmaNum">02</p>
                    <div className="kecmaOrb"></div>
                    <div className="kecmaImg">
                        <p className="kecP">{inter}%</p>
                        <img src={interpersonal}/>
                        <div className="kecmaBox">
                            <p className="kecmaIsi">Kemampuan untuk memahami orang lain.</p>
                            <p className="kecmaKet">Interpersonal</p>
                        </div>
                    </div>
                </div>
                <div className="itTop">
                    <p className="kecmaNum">03</p>
                    <div className="kecmaOrb"></div>
                    <div className="kecmaImg">
                        <div className="kecmaBox">
                            <p className="kecmaKet">Logical</p>
                            <p className="kecmaIsi">Kemampuan untuk bekerja dengan angka dan pemahaman logis.</p>
                        </div>
                        <img src={logical}/>
                        <p className="kecP">{log}%</p>
                    </div>
                </div>
                <div className="itBot">
                    <p className="kecmaNum">04</p>
                    <div className="kecmaOrb"></div>
                    <div className="kecmaImg">
                        <p className="kecP">{vis}%</p>
                        <img src={visual}/>
                        <div className="kecmaBox">
                            <p className="kecmaIsi">Kemampuan untuk memahami sesuatu dengan melihat atau membayangkan.</p>
                            <p className="kecmaKet">Visual</p>
                        </div>
                    </div>
                </div>
                <div className="itTop">
                    <p className="kecmaNum">05</p>
                    <div className="kecmaOrb"></div>
                    <div className="kecmaImg">
                        <div className="kecmaBox">
                            <p className="kecmaKet">Kinesthetic</p>
                            <p className="kecmaIsi">Kemampuan untuk beraktivitas dengan kekuatan tubuh dan tangan.</p>
                        </div>
                        <img src={kinesthetic}/>
                        <p className="kecP">{kin}%</p>
                    </div>
                </div>
                <div className="itBot">
                    <p className="kecmaNum">06</p>
                    <div className="kecmaOrb"></div>
                    <div className="kecmaImg">
                        <p className="kecP">{lin}%</p>
                        <img src={linguistik}/>
                        <div className="kecmaBox">
                            <p className="kecmaIsi">Kemampuan berekspresi secara verbal dan non-verbal dengan lancar.</p>
                            <p className="kecmaKet">Linguistik</p>
                        </div>
                    </div>
                </div>
                <div className="itTop">
                    <p className="kecmaNum">07</p>
                    <div className="kecmaOrb"></div>
                    <div className="kecmaImg">
                        <div className="kecmaBox">
                            <p className="kecmaKet">Musical</p>
                            <p className="kecmaIsi">Kemampuan untuk memahami suara, serta kompatibilitas dengan emosi dan ekspresi.</p>
                        </div>
                        <img src={musical}/>
                        <p className="kecP">{mus}%</p>
                    </div>
                </div>
                <div className="itBot">
                    <p className="kecmaNum">08</p>
                    <div className="kecmaOrb"></div>
                    <div className="kecmaImg">
                        <p className="kecP">{nat}%</p>
                        <img src={naturalist}/>
                        <div className="kecmaBox">
                            <p className="kecmaIsi">Kemampuan untuk terhubung dengan lingkungan naturalistik.</p>
                            <p className="kecmaKet">Naturalist</p>
                        </div>
                    </div>
                </div>
                <div className="itTop">
                    <p className="kecmaNum">09</p>
                    <div className="kecmaOrb"></div>
                    <div className="kecmaImg">
                        <div className="kecmaBox">
                            <p className="kecmaKet">Existential</p>
                            <p className="kecmaIsi">Kemampuan untuk memahami keberadaan manusia.</p>
                        </div>
                        <img src={existential}/>
                        <p className="kecP">{exi}%</p>
                    </div>
                </div>

            </div>
            <div className="appearOnMobile" style={{flexDirection: 'column', gap: '32px'}}>
                {array.map((item, index) => (
                <div key={index} className="kmLi">
                    <img src={item.i} />
                    <p className="kecPMo">{item.v}</p>
                    <p className="kecmaKetMo">{item.j}</p>
                    <p className="kecmaIsiMo">{item.k}</p>
                </div>
                ))}

            </div>
        </Row>
    )
}

export default KecerdasanMajemuk
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Alert,
} from "reactstrap";
import FormData from "form-data";
import axios from "axios";
import Lottie from "react-lottie";
import animateSuccess from "../../../assets/images/FutureLink/success.json";

import logoFutureLink from "../../../assets/images/FutureLink/logoFutureLink.png";
import forgetImg from "../../../assets/images/FutureLink/forget.png";

const Forgot = () => {
  document.title = "Forget Password | Future Link";
  const [email, setEmail] = useState("");
  const [modalSuccess, setModalSuccess] = useState(false);
  const [fade, setFade] = useState("fadeout");
  const [alert, setAlert] = useState(true);
  const [kontenError, setKontenError] = useState("");

  const login = async () => {
    if (email.includes(".") === false || email.includes("@") === false) {
      setAlert(false);
      setKontenError("Email not found");

      setTimeout(() => {
        setFade("fade");
      }, 200);

      setTimeout(() => {
        setFade("fadeout");
      }, 4500);

      setTimeout(() => {
        setAlert(true);
        setKontenError("Check Again");
      }, 5000);
    } else {
      let data = new FormData();
      data.append("email", email);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/forgot-password`,
        data: data,
        responseType: "json",
      };

      //   axios
      //     .request(config)
      //     .then((response) => {
      //       console.log(response);
      //       setModalSuccess(true);
      //       setTimeout(() => {
      //         setModalSuccess(false);
      //       }, 10000);
      //     })
      //     .then((data) => {
      //       console.log(data);
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //       console.log(error.response);
      //       console.log(error.request);
      //       setKontenError("Something wrong");
      //       setAlert(false);

      //       setTimeout(() => {
      //         setFade("fade");
      //       }, 200);

      //       setTimeout(() => {
      //         setFade("fadeout");
      //       }, 4500);

      //       setTimeout(() => {
      //         setAlert(true);
      //       }, 5000);
      //     });

      var requestOptions = {
        method: "POST",
        body: data,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/forgot-password`,
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            return response.json().then((text) => {
              throw text;
            });
          } else {
            return response.json();
          }
        })
        .then((result) => {
          console.log(result);
          //   if (response.statusCode == 400) {
          //     setModalSuccess(true);
          //     setTimeout(() => {
          //       setModalSuccess(false);
          //     }, 10000);
          //   } else {
          setModalSuccess(true);
          setTimeout(() => {
            setModalSuccess(false);
          }, 10000);
          // }
        })
        .catch((error) => {
          console.log("masuk error", error);
          setKontenError(error.message);
          setAlert(false);

          setTimeout(() => {
            setFade("fade");
          }, 200);

          setTimeout(() => {
            setFade("fadeout");
          }, 4500);

          setTimeout(() => {
            setAlert(true);
          }, 5000);
        });
    }
  };

  return (
    <React.Fragment>
      <Row
        style={{
          backgroundColor: "white",
          width: "100%",
          height: "100vh",
          margin: "0",
        }}
      >
        <Col className="responsiveGone" lg={6} style={{ paddingLeft: "0" }}>
          <div className="h-100" style={{ padding: "20% 45px" }}>
            <div
              className="position-relative h-100 d-flex flex-column"
              style={{ justifyContent: "center" }}
            >
              <img
                src={forgetImg}
                alt=""
                style={{
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              />
            </div>
          </div>
        </Col>

        <Col
          lg={6}
          style={{
            paddingRight: "0",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ marginBottom: "50px", padding: "0px 25px" }}>
            <img src={logoFutureLink} alt="logoFutureLink" />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              padding: "0px 100px",
            }}
          >
            <div>
              <p style={{ fontSize: "34px", fontWeight: "600", margin: "0" }}>
                Forgot Password
              </p>
            </div>

            <div className="mt-4">
              <Alert className={"alertRed " + fade} hidden={alert}>
                {kontenError}
              </Alert>
              <div className="mb-3">
                <Label htmlFor="email" className="form-label">
                  Email
                </Label>
                <Input
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  type="text"
                  className="form-control"
                  id="email"
                  placeholder="Enter email"
                  required
                />
              </div>
              <div className="mt-4">
                <Button
                  style={{ backgroundColor: "#1F599C", borderColor: "#1F599C" }}
                  color="success"
                  className="w-100"
                  onClick={login}
                >
                  Reset Password
                </Button>
              </div>
              <div
                className="w-100"
                style={{ padding: "10px 0px", textAlign: "center" }}
              >
                <Link to="/login">Sign In</Link>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Modal
        isOpen={modalSuccess}
        toggle={() => {
          setModalSuccess(!modalSuccess);
        }}
        centered
      >
        <ModalHeader
          toggle={() => {
            setModalSuccess(!modalSuccess);
          }}
        ></ModalHeader>
        <ModalBody className="modalCenter" style={{ margin: "0px" }}>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animateSuccess,
              rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
            }}
            height={200}
            width={200}
            isClickToPauseDisabled={true}
          />
          <p style={{ fontSize: "16px", marginBottom: "20px" }}>
            You've entered <strong>{email}</strong> as the email address for
            your account.
          </p>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Forgot;

import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import Lottie from 'react-lottie';
import accessDenied from '../../../../assets/images/FutureLink/accessDenied.json';

import lobus from '../../../../assets/images/FutureLink/lobus.png';


const LobusOtak= (haisayang) => {
    if(haisayang.history.location.state === undefined){
        window.location.href = "/report"
    }

    const data = haisayang.history.location.state.data
    // console.log(haisayang.history.location.state.data)

    const packages = haisayang.history.location.state.package_id
    // console.log(haisayang.history.location.state.package_id)


    return(
        <React.Fragment>
            <div className="page-content">

                {packages === 33 ? (
                    <>
                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                        <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 40px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Lobus Otak</p>
                    </div>

                    <div className='lobusotak' >
                        <Link style={{marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to='/report'>Back</Link>
                        <div className='lobusotakGraph'>
                            <div className='lobusotakKiri'>
                                <div className='lobusotakKiriText'>
                                    <p className='kiriText1'>LOBUS PREFRONTAL</p>
                                    <p className='kiriText2'>{data.action}%</p>
                                </div>
                                <div className='lobusotakKiriLine'>
                                    <div className='rounds' ></div>
                                    <div className='lineLobusPrefrontal'></div>
                                </div>
                            </div>
                            <div className='lobusotakTengah'>
                                <div className='lobusotakAtas'>
                                    <div className='lobusotakAtas1'>
                                        <p className='atas1Text1'>{data.think}%</p>
                                        <p className='atas1Text2'>LOBUS FRONTAL</p>
                                        <div className='lobusotakAtas1Line'>
                                            <div className='rounds'></div>
                                            <div className='lineLobusFrontal'></div>
                                        </div>
                                    </div>
                                    <div className='lobusotakAtas2'>
                                        <p className='atas2Text1'>{data.tactile}%</p>
                                        <p className='atas2Text2'>LOBUS PARIETAL</p>
                                        <div className='lobusotakAtas2Line'>
                                            <div className='rounds'></div>
                                            <div style={{width: '2px', height: '30px', backgroundColor: '#6E9B00'}}></div>
                                        </div>

                                    </div>
                                </div>
                                <img src={lobus} className='lobusotakImg'/>
                                <div className='lobusotakBawah'>
                                    <div className='lobusotakBawahLine'>
                                        <div className='lineLobusTemporal'></div>
                                        <div className='rounds'></div>
                                    </div>
                                    <p className='bawahText1'>LOBUS TEMPORAL</p>
                                    <p className='bawahText2'>{data.auditory}%</p>
                                </div>
                            </div>
                            <div className='lobusotakKanan'>
                                <div className='lobusotakKananLine'>
                                    <div className='lineLobusOksipital'></div>
                                    <div className='rounds'></div>
                                </div>
                                <div className='lobusotakKananText'>
                                    <p className='kananText1'>LOBUS OKSIPITAL</p>
                                    <p className='kananText2'>{data.visual}%</p>
                                </div>
                            </div>
                        </div>

                        <div className='lobusotakContent'>
                            <p style={{fontSize: '14px', textAlign: 'justify'}}>Para ahli neouroscience percaya bahwa perkembangan akhir struktur otak adalah neo-cortex. Korteks serebral otak adalah lapisan terluar yang memberikan karakteristik penampilan keriput pada otak. Korteks serebral dibagi memanjang menjadi dua belahan otak yang dihubungkan oleh saraf yang disebut Corpus Callosum. Secara tradisional, masing-masing belahan telah dibagi menjadi lima bagian yang disebut lobus, yaitu lobus prefrontal, lobus frontal, lobus temporal, lobus parietal, dan lobus oksipital. Lobus ini berfungsi mengendalikan gerakan, ucapan, perilaku, memori, emosi, kepribadian, dan berperan dalam fungsi intelektual, seperti proses berpikir, penalaran, pemecahan masalah, pengambilan keputusan, dan perencanaan. Namun setiap lobus otak besar memiliki fungsinya masing-masing.</p>

                            <div>
                                <div style={{display: 'flex'}}>
                                    <p style={{fontSize: '24px', fontWeight: '900', color: '#6431B1', width: '250px'}}>Lobus Prefrontal</p>
                                    <p style={{fontSize: '24px', fontWeight: '900', color: '#6431B1'}}>{data.action} %</p>
                                </div>

                                <p style={{fontSize: '14px', textAlign: 'justify'}}><span style={{fontWeight: '900'}}>Lobus prefrontal</span> bertanggung jawab untuk menganalisis, membayangkan, memvisualisasikan, menilai, dan berpikir kreatif. Mengembangkan pendekatan yang mandiri terhadap pekerjaan dapat membantu meningkatkan kinerja lobus ini. Selain itu mengatur dan membuat prioritas, mempelajari penggunaan strategi, menghafal informasi dengan menggunakan akronim, dan meningkatkan fleksibilitas kognitif juga dapat membantu peningkatan lobus ini.
                                </p>
                                <p style={{fontSize: '14px', textAlign: 'justify', margin: '0px'}}>Lobus ini berperan penting dalam Kemampuan Kognitif dan Kepribadian.</p>
                                <ul style={{fontSize: '14px', textAlign: 'justify', margin: '0px'}}>
                                    <li>Lobus prefrontal pada otak sebelah kiri berperan dalam merencanakan, mengelola, berkomunikasi, melakukan koordinasi, serta mengendalikan perilaku dan emosi.</li>
                                    <li>Lobus prefrontal pada otak sebelah kanan berperan dalam kreatifitas, interpersonal dan keterampilan kepemimpinan, biasanya dimotivasi oleh tujuan, intuisi dan visi.</li>
                                </ul>
                            </div>
                            <div style={{marginTop: '10px'}}>
                                <div style={{display: 'flex'}}>
                                    <p style={{fontSize: '24px', fontWeight: '900', color: '#7129AA', width: '250px'}}>Lobus Frontal</p>
                                    <p style={{fontSize: '24px', fontWeight: '900', color: '#7129AA'}}>{data.think} %</p>
                                </div>

                                <p style={{fontSize: '14px', textAlign: 'justify'}}><span style={{fontWeight: '900'}}>Lobus frontal </span>bertanggung jawab dalam mengontrol impuls, mengambil keputusan, merencanakan sesuatu, dan konsentrasi terkait koordinasi dan perilaku setiap orang. Mengerjakan ide kreatif, melakukan aktivitas fisik, bermeditasi pada konsep tertentu sepeti empati, fokus pada proses sosialisasi, serta memberi afirmasi positif dapat meningkatkan kinerja lobus ini. Selain itu melakukan permainan latihan otak dan ingatan seperti teka-teki silang dan permainan asah otak juga dapat meningkatkan daya pikir.
                                </p>
                                <p style={{fontSize: '14px', textAlign: 'justify', margin: '0px'}}>Lobus ini berperan penting dalam Kemampuan Berpikir dan Imajinatif. </p>
                                <ul style={{fontSize: '14px', textAlign: 'justify', margin: '0px'}}>
                                    <li>Lobus frontal pada otak sebelah kiri berperan dalam berpikir secara logis, kemampuan bahasa, pemecahan masalah, proses komputasi dan rasionalitas.</li>
                                    <li>Lobus frontal pada otak sebelah kanan berperan dalam konsep artistik, imajinasi spasial visual, pembentukan ide dan konseptualisasi ide.</li>
                                </ul>
                            </div>
                            <div style={{marginTop: '10px'}}>
                                <div style={{display: 'flex'}}>
                                    <p style={{fontSize: '24px', fontWeight: '900', color: '#6E9B00', width: '250px'}}>Lobus Parietal</p>
                                    <p style={{fontSize: '24px', fontWeight: '900', color: '#6E9B00'}}>{data.tactile} %</p>
                                </div>

                                <p style={{fontSize: '14px', textAlign: 'justify'}}><span style={{fontWeight: '900'}}>Lobus parietal</span> bertanggung jawab untuk membantu mengendalikan berbagai aktivitas, memproses dan mengkoordinasikan informasi sensorik yang ditunjukkan melalui gerakan, perbuatan, dan sentuhan. Menyalurkan arah gerakan dengan pendelegasian tugas, belajar sambil bergerak, melakukan kunjungan lapangan untuk pembelajaran, melakukan pembelajaran dalam bentuk percobaan dan mengeksplorasi aktivitas yang dapat mendukung pembelajaran dapat meningkatkan kinerja lobus ini. Selain itu penggunaan sempoa, garis bilangan. pemodelan tanah liat, dan lain sebagainya untuk media pembelajaran juga dapat meningkatkan kinerja lobus ini.</p>
                                <p style={{fontSize: '14px', textAlign: 'justify', margin: '0px'}}>Lobus ini berperan penting dalam Kemampuan Kinestetik. </p>
                                <ul style={{fontSize: '14px', textAlign: 'justify', margin: '0px'}}>
                                    <li>Lobus parietal pada otak sebelah kiri berperan dalam diferensiasi gerakan, kemampuan mengendalikan gerakan motorik halus dan pemahaman pengoperasian.</li>
                                    <li>Lobus parietal pada otak sebelah kanan berperan dalam mengontrol kemampuan motorik kasar, mobilitas tubuh, gerakan berirama, melakukan koordinasi otot dan apresiasi fisik.</li>
                                </ul>
                            </div>
                            <div style={{marginTop: '10px'}}>
                                <div style={{display: 'flex'}}>
                                    <p style={{fontSize: '24px', fontWeight: '900', color: '#3939AA', width: '250px'}}>Lobus Oksipital</p>
                                    <p style={{fontSize: '24px', fontWeight: '900', color: '#3939AA'}}>{data.visual} %</p>
                                </div>

                                <p style={{fontSize: '14px', textAlign: 'justify'}}><span style={{fontWeight: '900'}}>Lobus oksipital</span> bertanggung jawab untuk membantu dalam pemrosesan dan interpretasi visual, membantu memahami bagan atau grafik, memanipulasi dan menafsirkan gambar visual. Menggunakan diagram alir atau peta pikiran, memvisualisasikan ejaan kata-kata, menggunakan kode warna untuk menyorot poin penting dan mengatur catatan, serta menggunakan representasi bergambar dapat membantu meningkatkan kinerja lobus ini. Selain itu, belajar di meja yang rapi dan latar belakang yang tenang juga dapat membantu peningkatan lobus ini.</p>
                                <p style={{fontSize: '14px', textAlign: 'justify', margin: '0px'}}>Lobus ini berperan penting dalam Kemampuan Pemrosesan Visual. </p>
                                <ul style={{fontSize: '14px', textAlign: 'justify', margin: '0px'}}>
                                    <li>Lobus oksipital pada otak sebelah kiri berperan dalam mengidentifikasi visual, observasi, membaca dan kemampuan pemahaman.</li>
                                    <li>Lobus oksipital pada otak sebelah kanan berperan dalam menentukan pola abstrak, visual, grafik, dan gambar.</li>
                                </ul>
                            </div>
                            <div style={{marginTop: '10px'}}>
                                <div style={{display: 'flex'}}>
                                    <p style={{fontSize: '24px', fontWeight: '900', color: '#0966DA', width: '250px'}}>Lobus Temporal</p>
                                    <p style={{fontSize: '24px', fontWeight: '900', color: '#0966DA'}}>{data.auditory} %</p>
                                </div>

                                <p style={{fontSize: '14px', textAlign: 'justify'}}><span style={{fontWeight: '900'}}>Lobus temporal </span> bertanggung jawab untuk memahami musik dan merespon emosi, menghasilkan keterampilan pendengaran dan berbicara, serta membantu berpikir dengan kata-kata. Merekam dan memutar ulang pembelajaran, melakukan diskusi, menggunakan metode bercerita atau asosiasi dalam pembelajaran, membaca dengan keras dan menggunakan pengulangan kata berima akan meningkatkan kinerja lobus ini. Selain itu membuat jingle musik juga dapat membantu peningkatan lobus ini.</p>
                                <p style={{fontSize: '14px', textAlign: 'justify', margin: '0px'}}>Lobus ini berperan penting dalam Kemampuan Pemrosesan Suara. </p>
                                <ul style={{fontSize: '14px', textAlign: 'justify', margin: '0px'}}>
                                    <li>Lobus temporal pada otak sebelah kiri berperan dalam diferensiasi fonetik, pemahaman bahasa dan identifikasi suara.</li>
                                    <li>Lobus temporal pada otak sebelah kanan berperan dalam mengolah dan mengapresiasi melodi dan musik.</li>
                                </ul>
                            </div>
                            
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent:'space-between', padding: '20px'}}>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report/penjurusan", state: { data: data, package_id: packages }}}>{"<"} Penjurusan</Link>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report/kecerdasan", state: { data: data, package_id: packages }}}>Kecerdasan {">"}</Link>
                    </div>
                    </>
                ) : (
                    <>
                        <Link style={{marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to='/report'>Back</Link>
                        <Lottie options={{loop: true,autoplay: true,animationData: accessDenied,rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} isClickToPauseDisabled={true} />
                        <p className='upgradePlease'> Maaf, Anda belum melakukan Upgrade yang lebih tinggi</p>
                    </>
                )}



            </div>
            
        </React.Fragment>
    )

}

export default LobusOtak;
import React, {useState} from "react";
import {Link} from 'react-router-dom';

import iq from '../../../../../assets/images/FutureLink/iq.png';
import eq from '../../../../../assets/images/FutureLink/eq.png';
import aq from '../../../../../assets/images/FutureLink/aq.png';
import cq from '../../../../../assets/images/FutureLink/cq.png';

const Kecerdasan = (e) => {
    const state = e.state.data
    const data = e.state.data
    const package_id = e.state.package_id
    const img_finger = e.state.img_finger
    const shape_finger = e.state.shape_finger
    const IQ = Math.trunc(state.iq)
    const EQ = Math.trunc(state.eq)
    const AQ = Math.trunc(state.aq)
    const CQ = Math.trunc(state.cq)
    const [ker, setKer] = useState('IQ')

    return(
        <div className="corev2 dom" style={{height: '90vh'}}>
            <div className="appearOnMobile tiwtiw" style={{marginBottom: '24px'}}>
                <Link to={{pathname: '/client/reportFP', state: { data: data, package_id: package_id, img_finger:img_finger, shape_finger:shape_finger },}} className="bBek">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M10.295 19.7155C10.3886 19.8077 10.4994 19.8806 10.6211 19.93C10.7429 19.9793 10.8731 20.0042 11.0045 20.0033C11.1358 20.0023 11.2657 19.9754 11.3867 19.9243C11.5076 19.8731 11.6173 19.7986 11.7095 19.705C11.8017 19.6115 11.8746 19.5007 11.9239 19.379C11.9733 19.2572 11.9982 19.127 11.9972 18.9956C11.9963 18.8643 11.9694 18.7344 11.9183 18.6134C11.8671 18.4925 11.7926 18.3827 11.699 18.2906L6.32903 13.0006H19.999C20.2642 13.0006 20.5186 12.8952 20.7061 12.7077C20.8937 12.5201 20.999 12.2658 20.999 12.0006C20.999 11.7353 20.8937 11.481 20.7061 11.2934C20.5186 11.1059 20.2642 11.0006 19.999 11.0006H6.33603L11.7 5.71355C11.884 5.52633 11.987 5.2743 11.9867 5.01183C11.9865 4.74936 11.8831 4.49751 11.6988 4.31061C11.5145 4.12371 11.2642 4.01675 11.0017 4.01281C10.7393 4.00887 10.4858 4.10827 10.296 4.28955L3.37203 11.1116C3.25411 11.2278 3.16048 11.3664 3.09657 11.5192C3.03267 11.672 2.99976 11.8359 2.99976 12.0016C2.99976 12.1672 3.03267 12.3311 3.09657 12.4839C3.16048 12.6367 3.25411 12.7753 3.37203 12.8916L10.296 19.7146L10.295 19.7155Z" fill="#374151"/>
                </svg>
                </Link>
                <p className="antitank" style={{textAlign: 'center'}}>Kecerdasan</p>
            </div>
            {ker == "IQ" && (
                <>
                <div className="doVi">
                    <div className="gbD">
                        <div className="doNi appearOnMobile">
                            <div className='gbVx'>
                                <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>IQ</p>
                                <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{IQ}</p>
                            </div>
                        </div>
                        <p><b>Intelligence Quotient</b> atau IQ biasa disebut sebagai kecerdasan intelektual. IQ digunakan untuk menguraikan sifat pikiran manusia, mengukur kemampuan untuk menangkap, menyimpan, dan mengingat kembali mengenai pengetahuan faktual. Metode pengukuran kecerdasan ini ada sejak akhir abad ke-19, namun menurut psikolog terkenal IQ hanya menyumbang sekitar 20% faktor yang menentukan kesuksesan hidup.
                        <br/>
                        <br/>
                        Ciri-ciri orang yang nilai IQ-nya tinggi cenderung perfeksionis, memiliki keingintahuan yang tinggi, memiliki keterampilan pemecahan masalah, memiliki memori luar biasa, kemampuan logis dan analitisnya sangat baik.</p>
                        <div className="doNi goneOnMobile" style={{marginTop: '50px'}} >
                            <div className='gayabelajarBoxValue'>
                                <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>IQ Anda</p>
                                <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{IQ}</p>
                            </div>
                        </div>
                    </div>

                    <div className="doBut">
                        <button className="buttonTest" onClick={() => {setKer("EQ")}}>Selanjutnya </button>
                    </div>

                </div>
                <img src={iq} className="visimg goneOnMobile"/>
                </>
            )}
            {ker == "EQ" && (
                <>
                <div className="doVi">
                    <div className="gbD">
                        <div className="doNi appearOnMobile">
                            <div className='gbVx'>
                                <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>EQ</p>
                                <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{EQ}</p>
                            </div>
                        </div>
                        <p><b>Emotional Quotient</b> atau EQ biasa disebut sebagai kecerdasan emosi yang erat kaitannya dengan karakter seseorang. EQ digunakan untuk memahami perasaan orang lain, mengontrol emosi, kerja sama, komitmen, hingga disiplin, serta mengukur kemampuan untuk memahami dan mengelola orang dan tantangan secara matang. John D Mayer dan Peter Salovey menciptakan kerangka kerja untuk kecerdasan emosional pada tahun 1990-an.
                        <br/>
                        <br/>
                        Ciri-ciri orang yang nilai EQ-nya tinggi cenderung mengungkapkan perasaan mereka dengan jelas & langsung, mampu membaca komunikasi non-verbal, menyeimbangkan perasaan mereka dengan akal, logika, dan kenyataan, mandiri dan percaya pada diri sendiri, termotivasi secara intrinsik, dan tangguh secara emosional.
                        </p>
                        <div className="doNi goneOnMobile" style={{marginTop: '50px'}}>
                            <div className='gayabelajarBoxValue'>
                                <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>EQ Anda</p>
                                <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{EQ}</p>
                            </div>
                        </div>
                    </div>

                    <div className="doBut">
                        <button className="buttonTest" style={{marginRight: '12px'}} onClick={() => {setKer('IQ')}}>Sebelumnya</button>
                        <button className="buttonTest" onClick={() => {setKer("AQ")}}>Selanjutnya </button>
                    </div>

                </div>
                <img src={eq} className="visimg goneOnMobile"/>
                </>
            )}
            {ker == "AQ" && (
                <>
                <div className="doVi">
                    <div className="gbD">
                        <div className="doNi appearOnMobile">
                            <div className='gbVx'>
                                <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>AQ</p>
                                <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{AQ}</p>
                            </div>
                        </div>
                        <p>
                        <b>Adversity Quotient</b> atau AQ mengukur kemampuan untuk beradaptasi dengan baik terhadap tekanan, kesulitan, trauma, atau tragedi. Kecerdasan ini memerlukan kestabilan dan pertahanan kesehatan fisik dan fungsi psikologis, bahkan dalam menghadapi kekacauan.
                        <br/><br/>
                        Ciri-ciri orang yang nilai AQ-nya tinggi cenderung tampil maksimal dalam menghadapi kesulitan - tantangan besar dan kecil yang menghadang setiap hari, memanfaatkan kemampuan alami untuk belajar dan berubah, serta meningkatkan vitalitas, dapat ditingkatkan secara dramatis, diperbaiki dan diperkuat secara permanen.
                        </p>
                        <div className="doNi goneOnMobile" style={{marginTop: '50px'}}>
                            <div className='gayabelajarBoxValue'>
                                <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>AQ Anda</p>
                                <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{AQ}</p>
                            </div>
                        </div>
                    </div>

                    <div className="doBut">
                        <button className="buttonTest" style={{marginRight: '12px'}} onClick={() => {setKer('EQ')}}>Sebelumnya</button>
                        <button className="buttonTest" onClick={() => {setKer("CQ")}}>Selanjutnya </button>
                    </div>

                </div>
                <img src={aq} className="visimg goneOnMobile"/>
                </>
            )}
            {ker == "CQ" && (
                <>
                <div className="doVi">
                    <div className="gbD">
                        <div className="doNi appearOnMobile">
                            <div className='gbVx'>
                                <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>CQ</p>
                                <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{CQ}</p>
                            </div>
                        </div>
                        <p>
                        <b>Creative Quotient</b> atau CQ mengukur kemampuan kreativitas dan mempertimbangkan ide serta  kelancaran untuk menentukan tingkat kreativitas yang dimiliki seseorang. Kecerdasan ini dapat memunculkan ide, pemecahan masalah melalui cara yang berbeda dari yang sudah lazim, serta menguraikan sesuatu secara terperinci. 
                        <br/>
                        <br/>
                        Ciri-ciri orang yang memiliki CQ yang tinggi cenderung peka terhadap keindahan & tertarik pada nilai-nilai estetika, sering menampilkan keceriaan intelektual, suka berfantasi & berimajinasi, pemikir yang fasih (mampu menghasilkan kemungkinan, atau konsekuensi), pemikir yang fleksibel (mampu menggunakan banyak alternatif dan pendekatan berbeda untuk pemecahan masalah), pemikir orisinal (mencari kombinasi baru, tidak biasa, atau tidak konvensional).
                        </p>
                        <div className="doNi goneOnMobile" style={{marginTop: '50px'}}>
                            <div className='gayabelajarBoxValue'>
                                <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>CQ Anda</p>
                                <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{CQ}</p>
                            </div>
                        </div>
                    </div>

                    <div className="doBut">
                        <button className="buttonTest" onClick={() => {setKer("AQ")}}>Sebelumnya</button>
                    </div>

                </div>
                <img src={cq} className="visimg goneOnMobile"/>
                </>
            )}

        </div>
    )
}

export default Kecerdasan
import React, {useState} from "react";
import {Row, Col, Progress} from 'reactstrap';
import {Link} from 'react-router-dom';

const MinatStudi = (e) => {
    const state = e.state.data
    const data = e.state.data
    const package_id = e.state.package_id
    const img_finger = e.state.img_finger
    const shape_finger = e.state.shape_finger

    const riasec_r = state.riasec_r
    const riasec_i = state.riasec_i
    const riasec_a = state.riasec_a
    const riasec_s = state.riasec_s
    const riasec_e = state.riasec_e
    const riasec_c = state.riasec_c

    return(
        <Row className="corev2">
            <div className="appearOnMobile tiwtiw" style={{marginBottom: '24px'}}>
                <Link to={{pathname: '/client/reportFP', state: { data: data, package_id: package_id, img_finger:img_finger, shape_finger:shape_finger },}} className="bBek">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M10.295 19.7155C10.3886 19.8077 10.4994 19.8806 10.6211 19.93C10.7429 19.9793 10.8731 20.0042 11.0045 20.0033C11.1358 20.0023 11.2657 19.9754 11.3867 19.9243C11.5076 19.8731 11.6173 19.7986 11.7095 19.705C11.8017 19.6115 11.8746 19.5007 11.9239 19.379C11.9733 19.2572 11.9982 19.127 11.9972 18.9956C11.9963 18.8643 11.9694 18.7344 11.9183 18.6134C11.8671 18.4925 11.7926 18.3827 11.699 18.2906L6.32903 13.0006H19.999C20.2642 13.0006 20.5186 12.8952 20.7061 12.7077C20.8937 12.5201 20.999 12.2658 20.999 12.0006C20.999 11.7353 20.8937 11.481 20.7061 11.2934C20.5186 11.1059 20.2642 11.0006 19.999 11.0006H6.33603L11.7 5.71355C11.884 5.52633 11.987 5.2743 11.9867 5.01183C11.9865 4.74936 11.8831 4.49751 11.6988 4.31061C11.5145 4.12371 11.2642 4.01675 11.0017 4.01281C10.7393 4.00887 10.4858 4.10827 10.296 4.28955L3.37203 11.1116C3.25411 11.2278 3.16048 11.3664 3.09657 11.5192C3.03267 11.672 2.99976 11.8359 2.99976 12.0016C2.99976 12.1672 3.03267 12.3311 3.09657 12.4839C3.16048 12.6367 3.25411 12.7753 3.37203 12.8916L10.296 19.7146L10.295 19.7155Z" fill="#374151"/>
                </svg>
                </Link>
                <p className="antitank" style={{textAlign: 'center'}}>Minat Studi (RIASEC)</p>
            </div>

                <div className='minatkejuruanIntro'>

                    <p style={{margin: '0px', textAlign: 'justify', fontSize: '18px'}}>Teori Holland menjelaskan bahwa orang dapat dikategorikan menurut enam tipe karakteristik (Realistic, Investigative, Artistic, Social, Enterprising, dan Conventional) yang dikenal secara kolektif sebagai RIASEC. Teori Holland tidak berasumsi bahwa seseorang hanya satu jenis atau hanya ada enam jenis orang di dunia, sebaliknya ia berasumsi bahwa setiap orang dapat digambarkan memiliki minat yang terkait dengan masing-masing dari enam jenis dalam urutan preferensi yang menurun.</p>

                </div>

                <div>

                    <div style={{display: 'flex', flexDirection: 'column', gap: '30px', margin: '40px 0px'}}>
                        <div>
                            <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>Realistic</p>
                            <p style={{fontSize: '12px'}}>The “Do-ers”</p>
                            <Progress value={riasec_r*2} className=' pow animated-progess progress-label'
                                color={
                                     riasec_r < 11 ? "red" : 
                                     riasec_r < 17 ? "yellow" :"green"
                                }
                            >                      
                                <div className='label' style={{right: '-60px'}} >{riasec_r}%</div>
                            </Progress>
                        </div>
                        <div>
                            <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>Investigative</p>
                            <p style={{fontSize: '12px'}}>The “Thinkers”</p>
                            <Progress value={riasec_i*2} className=' pow animated-progess progress-label'
                                color={
                                     riasec_i < 15.48 ? "red" : 
                                     riasec_i < 20.44 ? "yellow" :"green"
                                }
                            >                      
                                <div className='label' style={{right: '-60px'}} >{riasec_i}%</div>
                            </Progress>
                        </div>
                        <div>
                            <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>Artistic</p>
                            <p style={{fontSize: '12px'}}>The “Creators”</p>
                            <Progress value={riasec_a*2} className=' pow animated-progess progress-label'
                                color={
                                     riasec_a < 14.7 ? "red" : 
                                     riasec_a < 18.46 ? "yellow" :"green"
                                }
                            >                      
                                <div className='label' style={{right: '-60px'}} >{riasec_a}%</div>
                            </Progress>
                        </div>
                        <div>
                            <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>Social</p>
                            <p style={{fontSize: '12px'}}>The “Helpers”</p>
                            <Progress value={riasec_s*2} className=' pow animated-progess progress-label'
                                color={
                                     riasec_s < 14.41 ? "red" : 
                                     riasec_s < 18.05 ? "yellow" :"green"
                                }
                            >                      
                                <div className='label' style={{right: '-60px'}} >{riasec_s}%</div>
                            </Progress>
                        </div>
                        <div>
                            <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>Enterpricing</p>
                            <p style={{fontSize: '12px'}}>The “Persuaders”</p>
                            <Progress value={riasec_e*2} className=' pow animated-progess progress-label'
                                color={
                                     riasec_e < 17.03 ? "red" : 
                                     riasec_e < 19.91 ? "yellow" :"green"
                                }
                            >                      
                                <div className='label' style={{right: '-60px'}} >{riasec_e}%</div>
                            </Progress>
                        </div>
                        <div>
                            <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>Conventional</p>
                            <p style={{fontSize: '12px'}}>The “Organizers”</p>
                            <Progress value={riasec_c*2} className=' pow animated-progess progress-label'
                                color={
                                     riasec_c < 15.6 ? "red" : 
                                     riasec_c < 17.86 ? "yellow" :"green"
                                }
                            >                      
                                <div className='label' style={{right: '-60px'}} >{riasec_c}%</div>
                            </Progress>
                        </div>
                    </div>

                    <div className="pK goneOnMobile">
                        <div className="plKt">
                            <p className="antitank bold">Keterangan</p>
                            <div className="polKet">
                                <div className="hijauw"></div>
                                <p className="antitank bold">Diatas rata-rata</p>
                            </div>
                            <div className="polKet">
                                <div className="kuningw"></div>
                                <p className="antitank bold">Rata-rata</p>
                            </div>
                            <div className="polKet">
                                <div className="merahw"></div>
                                <p className="antitank bold">Dibawah rata-rata</p>
                            </div>
                        </div>
                    </div>
            </div>

        </Row>
    )
}

export default MinatStudi
import React, {DOMAttributes, AriaAttributes } from "react";
import { Col } from "reactstrap";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";

// Import Images
import logoLight from "../../assets/images/FutureLink/kiri.png";

const AuthSlider = () => {
    return (
        <React.Fragment>

            <Col className="responsiveGone" lg={6} style={{paddingLeft: '0'}}>
                <div className="h-100" style={{padding: '20% 45px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <img src={logoLight} fetchPriority="high" alt="Logo Future Link" title="Logo Future Link" loading="eager" width={337} height={285}/>
                    {/* <div className="bg-overlay"></div> */}
                    <div className="position-relative h-100 d-flex flex-column">
                        {/* <div className="mb-4">
                            <Link to="/dashboard" className="d-block">
                                <img src={logoLight} alt="logoFutureLink"  />
                            </Link>
                        </div> */}
                        {/* <div className="mt-auto">
                            <div className="mb-3">
                                <i className="ri-double-quotes-l display-4 text-success"></i>
                            </div>

                            <Carousel showThumbs={false} autoPlay={true} showArrows={false} showStatus={false} infiniteLoop={true} className="carousel slide" id="qoutescarouselIndicators" >
                                <div className="carousel-inner text-center text-white pb-5">
                                    <div className="item">
                                        <p className="fs-15 fst-italic">" Great! Clean code, clean design, easy for customization. Thanks very much! "</p>
                                    </div>
                                </div>
                                <div className="carousel-inner text-center text-white pb-5">
                                    <div className="item">
                                        <p className="fs-15 fst-italic">" The theme is really great with an amazing customer support."</p>
                                    </div>
                                </div>
                                <div className="carousel-inner text-center text-white pb-5">
                                    <div className="item">
                                        <p className="fs-15 fst-italic">" Great! Clean code, clean design, easy for customization. Thanks very much! "</p>
                                    </div>
                                </div>
                            </Carousel>

                        </div> */}
                    </div>
                </div>
            </Col>
        </React.Fragment >
    );
};

export default AuthSlider;
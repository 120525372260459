import React, { useState, useEffect } from "react";

import SidebarDashboard from "./TemplateDashboard/sidebarDashboard.js";
import HeaderDashboard from "./TemplateDashboard/headerDashboard.js";

import ListReadingClient from "./MenuDashboard/listReading.js";

const ListReading = (e) => {
    const state = e.location.state
    const title = 'Dashboard'

    return (

        <div className="dashboardv2">
            <SidebarDashboard title={title} />
            <section className="fullwidth">
                <HeaderDashboard  title={title} gate1={false} gate2={false} menu={""} submenu={""}  />

                <main className="mainv2" style={{height: '100vh'}}>

                <ListReadingClient state={state}/>

                </main>
            </section>

        </div>
    )

};

export default ListReading
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";

const Summary3 = (haisayang) => {
  const data = haisayang.history.location.state.data;
  // console.log(haisayang.history.location.state.data)

  const packages = haisayang.history.location.state.package_id;
  // console.log(haisayang.history.location.state.package_id)

  const token = haisayang.history.location.state.params;
  const [existentialColor, setExistentialColor] = useState("");
  const [naturalistColor, setNaturalistColor] = useState("");
  const [musicalColor, setMusicalColor] = useState("");
  const [linguisticColor, setLinguisticColor] = useState("");
  const [kinestheticColor, setKinestheticColor] = useState("");
  const [visualColor, setVisualColor] = useState("");
  const [logicalColor, setLogicalColor] = useState("");
  const [interColor, setInterColor] = useState("");
  const [intraColor, setIntraColor] = useState("");

  useEffect(() => {
    if (data.existential >= 90) {
      setExistentialColor("#013226");
    } else if (data.existential >= 80) {
      setExistentialColor("#01513D");
    } else if (data.existential >= 70) {
      setExistentialColor("#00674D");
    } else if (data.existential >= 60) {
      setExistentialColor("#007F5F");
    } else if (data.existential >= 50) {
      setExistentialColor("#2B9348");
    } else if (data.existential >= 40) {
      setExistentialColor("#55A630");
    } else if (data.existential >= 30) {
      setExistentialColor("#80B918");
    } else if (data.existential >= 20) {
      setExistentialColor("#AACC00");
    } else if (data.existential >= 10) {
      setExistentialColor("#BFD200");
    } else if (data.existential >= 0) {
      setExistentialColor("#D4D700");
    } else {
      setExistentialColor("#013226");
    }

    if (data["8_naturalist"] >= 90) {
      setNaturalistColor("#013226");
    } else if (data["8_naturalist"] >= 80) {
      setNaturalistColor("#01513D");
    } else if (data["8_naturalist"] >= 70) {
      setNaturalistColor("#00674D");
    } else if (data["8_naturalist"] >= 60) {
      setNaturalistColor("#007F5F");
    } else if (data["8_naturalist"] >= 50) {
      setNaturalistColor("#2B9348");
    } else if (data["8_naturalist"] >= 40) {
      setNaturalistColor("#55A630");
    } else if (data["8_naturalist"] >= 30) {
      setNaturalistColor("#80B918");
    } else if (data["8_naturalist"] >= 20) {
      setNaturalistColor("#AACC00");
    } else if (data["8_naturalist"] >= 10) {
      setNaturalistColor("#BFD200");
    } else if (data["8_naturalist"] >= 0) {
      setNaturalistColor("#D4D700");
    } else {
      setNaturalistColor("#013226");
    }

    if (data["8_musical"] >= 90) {
      setMusicalColor("#013226");
    } else if (data["8_musical"] >= 80) {
      setMusicalColor("#01513D");
    } else if (data["8_musical"] >= 70) {
      setMusicalColor("#00674D");
    } else if (data["8_musical"] >= 60) {
      setMusicalColor("#007F5F");
    } else if (data["8_musical"] >= 50) {
      setMusicalColor("#2B9348");
    } else if (data["8_musical"] >= 40) {
      setMusicalColor("#55A630");
    } else if (data["8_musical"] >= 30) {
      setMusicalColor("#80B918");
    } else if (data["8_musical"] >= 20) {
      setMusicalColor("#AACC00");
    } else if (data["8_musical"] >= 10) {
      setMusicalColor("#BFD200");
    } else if (data["8_musical"] >= 0) {
      setMusicalColor("#D4D700");
    } else {
      setMusicalColor("#013226");
    }

    if (data["8_linguistic"] >= 90) {
      setLinguisticColor("#013226");
    } else if (data["8_linguistic"] >= 80) {
      setLinguisticColor("#01513D");
    } else if (data["8_linguistic"] >= 70) {
      setLinguisticColor("#00674D");
    } else if (data["8_linguistic"] >= 60) {
      setLinguisticColor("#007F5F");
    } else if (data["8_linguistic"] >= 50) {
      setLinguisticColor("#2B9348");
    } else if (data["8_linguistic"] >= 40) {
      setLinguisticColor("#55A630");
    } else if (data["8_linguistic"] >= 30) {
      setLinguisticColor("#80B918");
    } else if (data["8_linguistic"] >= 20) {
      setLinguisticColor("#AACC00");
    } else if (data["8_linguistic"] >= 10) {
      setLinguisticColor("#BFD200");
    } else if (data["8_linguistic"] >= 0) {
      setLinguisticColor("#D4D700");
    } else {
      setLinguisticColor("#013226");
    }

    if (data["8_bodily_kinesthetic"] >= 90) {
      setKinestheticColor("#013226");
    } else if (data["8_bodily_kinesthetic"] >= 80) {
      setKinestheticColor("#01513D");
    } else if (data["8_bodily_kinesthetic"] >= 70) {
      setKinestheticColor("#00674D");
    } else if (data["8_bodily_kinesthetic"] >= 60) {
      setKinestheticColor("#007F5F");
    } else if (data["8_bodily_kinesthetic"] >= 50) {
      setKinestheticColor("#2B9348");
    } else if (data["8_bodily_kinesthetic"] >= 40) {
      setKinestheticColor("#55A630");
    } else if (data["8_bodily_kinesthetic"] >= 30) {
      setKinestheticColor("#80B918");
    } else if (data["8_bodily_kinesthetic"] >= 20) {
      setKinestheticColor("#AACC00");
    } else if (data["8_bodily_kinesthetic"] >= 10) {
      setKinestheticColor("#BFD200");
    } else if (data["8_bodily_kinesthetic"] >= 0) {
      setKinestheticColor("#D4D700");
    } else {
      setKinestheticColor("#013226");
    }

    if (data["8_visual"] >= 90) {
      setVisualColor("#013226");
    } else if (data["8_visual"] >= 80) {
      setVisualColor("#01513D");
    } else if (data["8_visual"] >= 70) {
      setVisualColor("#00674D");
    } else if (data["8_visual"] >= 60) {
      setVisualColor("#007F5F");
    } else if (data["8_visual"] >= 50) {
      setVisualColor("#2B9348");
    } else if (data["8_visual"] >= 40) {
      setVisualColor("#55A630");
    } else if (data["8_visual"] >= 30) {
      setVisualColor("#80B918");
    } else if (data["8_visual"] >= 20) {
      setVisualColor("#AACC00");
    } else if (data["8_visual"] >= 10) {
      setVisualColor("#BFD200");
    } else if (data["8_visual"] >= 0) {
      setVisualColor("#D4D700");
    } else {
      setVisualColor("#013226");
    }

    if (data["8_logical"] >= 90) {
      setLogicalColor("#013226");
    } else if (data["8_logical"] >= 80) {
      setLogicalColor("#01513D");
    } else if (data["8_logical"] >= 70) {
      setLogicalColor("#00674D");
    } else if (data["8_logical"] >= 60) {
      setLogicalColor("#007F5F");
    } else if (data["8_logical"] >= 50) {
      setLogicalColor("#2B9348");
    } else if (data["8_logical"] >= 40) {
      setLogicalColor("#55A630");
    } else if (data["8_logical"] >= 30) {
      setLogicalColor("#80B918");
    } else if (data["8_logical"] >= 20) {
      setLogicalColor("#AACC00");
    } else if (data["8_logical"] >= 10) {
      setLogicalColor("#BFD200");
    } else if (data["8_logical"] >= 0) {
      setLogicalColor("#D4D700");
    } else {
      setLogicalColor("#013226");
    }

    if (data["8_inter"] >= 90) {
      setInterColor("#013226");
    } else if (data["8_inter"] >= 80) {
      setInterColor("#01513D");
    } else if (data["8_inter"] >= 70) {
      setInterColor("#00674D");
    } else if (data["8_inter"] >= 60) {
      setInterColor("#007F5F");
    } else if (data["8_inter"] >= 50) {
      setInterColor("#2B9348");
    } else if (data["8_inter"] >= 40) {
      setInterColor("#55A630");
    } else if (data["8_inter"] >= 30) {
      setInterColor("#80B918");
    } else if (data["8_inter"] >= 20) {
      setInterColor("#AACC00");
    } else if (data["8_inter"] >= 10) {
      setInterColor("#BFD200");
    } else if (data["8_inter"] >= 0) {
      setInterColor("#D4D700");
    } else {
      setInterColor("#013226");
    }

    if (data["8_intra"] >= 90) {
      setIntraColor("#013226");
    } else if (data["8_intra"] >= 80) {
      setIntraColor("#01513D");
    } else if (data["8_intra"] >= 70) {
      setIntraColor("#00674D");
    } else if (data["8_intra"] >= 60) {
      setIntraColor("#007F5F");
    } else if (data["8_intra"] >= 50) {
      setIntraColor("#2B9348");
    } else if (data["8_intra"] >= 40) {
      setIntraColor("#55A630");
    } else if (data["8_intra"] >= 30) {
      setIntraColor("#80B918");
    } else if (data["8_intra"] >= 20) {
      setIntraColor("#AACC00");
    } else if (data["8_intra"] >= 10) {
      setIntraColor("#BFD200");
    } else if (data["8_intra"] >= 0) {
      setIntraColor("#D4D700");
    } else {
      setIntraColor("#013226");
    }
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="polasidikjariList">
          <Link
            style={{ width: "max-content" }}
            className="btn buttonVersFutureLinkBorderGrey"
            to={`/report-mobile/${token}`}
          >
            Back
          </Link>
          <h4 style={{ display: "flex", justifyContent: "center" }}>
            Kemampuan Penunjang Minat Bakat
          </h4>
          <div className="grid-container">
            <div className="grid-item">
              <ReactApexChart
                series={[
                  {
                    data: [
                      {
                        x: "Kecerdasan Eksistensial",
                        y: data.existential,
                        fillColor: existentialColor,
                      },
                      {
                        x: "Kecerdasan Naturalistik",
                        y: data["8_naturalist"],
                        fillColor: naturalistColor,
                      },
                      {
                        x: "Kecerdasan Musikal",
                        y: data["8_musical"],
                        fillColor: musicalColor,
                      },
                      {
                        x: "Kecerdasan Linguistik",
                        y: data["8_linguistic"],
                        fillColor: linguisticColor,
                      },
                      {
                        x: "Kecerdasan Kinestetik",
                        y: data["8_bodily_kinesthetic"],
                        fillColor: kinestheticColor,
                      },
                      {
                        x: "Kecerdasan Visual",
                        y: data["8_visual"],
                        fillColor: visualColor,
                      },
                      {
                        x: "Kecerdasan Logical",
                        y: data["8_logical"],
                        fillColor: logicalColor,
                      },
                      {
                        x: "Kecerdasan Interpersonal",
                        y: data["8_inter"],
                        fillColor: interColor,
                      },
                      {
                        x: "Kecerdasan Intrapersonal",
                        y: data["8_intra"],
                        fillColor: intraColor,
                      },
                    ],
                  },
                ]}
                options={{
                  chart: {
                    id: "basic-bar",
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  legend: { show: true },
                  plotOptions: {
                    bar: {
                      horizontal: true,
                    },
                  },
                }}
                type="bar"
                width={600}
              />
              <div className="grid-container">
                <div className="grid-item">
                  <div className="card-gradient">
                    <h6 style={{ color: "white" }}>RIASEC</h6>
                    <p style={{ color: "white", fontSize: "11px" }}>
                      Taksonomi minat berdasarkan teori karir dan pilihan
                      kejuruan yang dikembangkan oleh John L. Holland.
                    </p>
                  </div>
                </div>
                <div className="grid-item">
                  <div className="grid-container">
                    <div className="grid-item center-item">
                      <div className="q-title">
                        <h5>Enterpricing</h5>
                      </div>
                      <h1 style={{ color: "green" }}>{data.riasec_e}</h1>
                    </div>
                    <div className="grid-item center-item">
                      <div className="q-title">
                        <h5>Conventional</h5>
                      </div>
                      <h1 style={{ color: "red" }}>{data.riasec_c}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-item">
              <div className="q-title center-item">
                <h6>Social Relationship Tips:</h6>
                <p style={{ fontSize: "11px" }}>
                  Leverage your strong interpersonal skills to build genuine
                  connections within the arts community. Engage in group
                  activities and collaborate on projects that align with your
                  creative interests. Communicate openly about any challenges
                  with fine motor skills, allowing others to understand and
                  support you. Embrace the collaborative nature of the arts to
                  foster positive social relationships.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "20px",
          }}
        >
          <Link
            className="bottomButton buttonVersFutureLink"
            to={{
              pathname: "/report-mobiles/summary/2",
              state: { data: data, package_id: packages, params: token },
            }}
            style={{ marginRight: 5 }}
          >
            {"<"} Sebelumnya
          </Link>
          <Link
            className="bottomButton buttonVersFutureLink"
            to={{
              pathname: "/report-mobiles/summary/4",
              state: { data: data, package_id: packages, params: token },
            }}
          >
            Selanjutnya {">"}
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Summary3;

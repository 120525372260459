import React, {useState} from 'react';
import {Link} from 'react-router-dom';



const Tfrc= (haisayang) => {
    if(haisayang.history.location.state === undefined){
        window.location.href = "/report"
    }

    const data = haisayang.history.location.state.data
    // console.log(haisayang.history.location.state.data)

    const packages = haisayang.history.location.state.package_id
    // console.log(haisayang.history.location.state.package_id)

    return(
        <React.Fragment>
            <div className="page-content">
            {packages === 33 ? (
                <>
                
                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                        <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 40px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Total Finger Ridge Count (TFRC)</p>
                    </div>

                    <div className='trfc'>
                        <Link style={{marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to='/report'>Back</Link>

                        <p className='trfcKeterangan'><span style={{fontWeight: '900'}}>Total Finger Ridge Count (TFRC)</span> adalah suatu angka yang menunjukkan jumlah sulur dari semua jari setiap orang. Menurut Ifa H. Misbach dalam bukunya yang berjudul “Dahsyatnya Sidik Jari: Menguak Bakat dan Potensi untuk Merancang Masa Depan Melalui Fingerprint Analysis”, menyebutkan bahwa penghitungan banyaknya rigi dilakukan mulai dari triradius sampai ke pusat dari pola sulur jari. TFRC merupakan cerminan dari kapasitas belajar bawaan seseorang, umumnya dikenal sebagai “Kapasitas Sel Otak Neo-korteks”. Saat lahir, 100 miliar sel saraf di korteks selebral mengatur rangkaian kabel yang sangat rumit (sekitar 5.000 hingga 10.000 koneksi ke setiap sel saraf). Melalui mekanisme pembelajaran di otak, otak terus menyambung ulang dan mengubah sirkuitnya sepanjang hidup seseorang. Oleh karena itu, belajar penting untuk merangsang keterkaitan neuron diri kita sendiri.  </p>

                        <div className='trfcValue'>
                            <div className={
                                data.tfrc > 237 ? 'trfcValueBox level1b' :
                                data.tfrc > 204 ? 'trfcValueBox level2b' :
                                data.tfrc > 170 ? 'trfcValueBox level3b' :
                                data.tfrc > 135 ? 'trfcValueBox level4b' :
                                data.tfrc > 101 ? 'trfcValueBox level5b' :
                                data.tfrc > 67 ? 'trfcValueBox level6b' : 'trfcValueBox level7b'
                                }>
                                <p className={
                                    data.tfrc > 237 ? 'trfcValueBoxTitle level1p' :
                                    data.tfrc > 204 ? 'trfcValueBoxTitle level2p' :
                                    data.tfrc > 170 ? 'trfcValueBoxTitle level3p' :
                                    data.tfrc > 135 ? 'trfcValueBoxTitle level4p' :
                                    data.tfrc > 101 ? 'trfcValueBoxTitle level5p' :
                                    data.tfrc > 67 ? 'trfcValueBoxTitle level6p' : 'trfcValueBoxTitle level7p'
                                    }
                                >Nilai TFRC : 
                                </p>
                                <p className={
                                    data.tfrc > 237 ? 'trfcValueBoxNumber level1' :
                                    data.tfrc > 204 ? 'trfcValueBoxNumber level2' :
                                    data.tfrc > 170 ? 'trfcValueBoxNumber level3' :
                                    data.tfrc > 135 ? 'trfcValueBoxNumber level4' :
                                    data.tfrc > 101 ? 'trfcValueBoxNumber level5' :
                                    data.tfrc > 67 ? 'trfcValueBoxNumber level6' : 'trfcValueBoxNumber level7'
                                    }
                                >{data.tfrc}</p>
                            </div>
                        </div>

                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <div style={{width: '80%'}}>
                                <div style={{display: 'flex', alignItems: 'center', marginBottom: '30px'}}>
                                    <p className='trfcWithBorder' style={{flex: '1', marginRight: '5px'}}>TFRC</p>
                                    <p className='trfcWithBorder' style={{flex: '1', marginLeft: '5px', marginRight: '5px'}}>Indikator</p>
                                    <p className='trfcWithBorderNone' style={{flex: '2' , marginLeft: '5px'}}>Keterangan</p>
                                </div>

                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div className='trfcLevel level1' style={{flex: '1'}}>
                                        <p style={{margin: '0px'}}>{'> 238'}</p>
                                    </div>
                                    <div className='trfcLevel level1' style={{flex: '1'}}>
                                        <p style={{margin: '0px'}}>Sangat Tinggi</p>
                                    </div>
                                    <div className='trfcLevelKeterangan level1' style={{flex: '2'}}>
                                        <p className='trfcLevelKeteranganText' >Memiliki kemampuan untuk mendobrak batasan dari keterbatasan diri sendiri.</p>
                                    </div>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div className='trfcLevel level2' style={{flex: '1'}}>
                                        <p style={{margin: '0px'}}>{'205-238'}</p>
                                    </div>
                                    <div className='trfcLevel level2' style={{flex: '1'}}>
                                        <p style={{margin: '0px'}}>Tinggi</p>
                                    </div>
                                    <div className='trfcLevelKeterangan level2' style={{flex: '2'}}>
                                        <p className='trfcLevelKeteranganText' >Memiliki kemampuan tersembunyi yang berpotensi menjadi suatu kekuatan besar.</p>
                                    </div>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div className='trfcLevel level3' style={{flex: '1'}}>
                                        <p style={{margin: '0px'}}>{'171-204'}</p>
                                    </div>
                                    <div className='trfcLevel level3' style={{flex: '1'}}>
                                        <p style={{margin: '0px'}}>Diatas rata-rata</p>
                                    </div>
                                    <div className='trfcLevelKeterangan level3' style={{flex: '2'}}>
                                        <p className='trfcLevelKeteranganText' >Memiliki kemampuan untuk memimpin orang lain untuk mencapai harapannya.</p>
                                    </div>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div className='trfcLevel level4' style={{flex: '1'}}>
                                        <p style={{margin: '0px'}}>{'136-170'}</p>
                                    </div>
                                    <div className='trfcLevel level4' style={{flex: '1'}}>
                                        <p style={{margin: '0px'}}>Rata-rata</p>
                                    </div>
                                    <div className='trfcLevelKeterangan level4' style={{flex: '2'}}>
                                        <p className='trfcLevelKeteranganText' >Memiliki kemampuan untuk memotivasi orang lain.</p>
                                    </div>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div className='trfcLevel level5' style={{flex: '1'}}>
                                        <p style={{margin: '0px'}}>{'102-135'}</p>
                                    </div>
                                    <div className='trfcLevel level5' style={{flex: '1'}}>
                                        <p style={{margin: '0px'}}>Dibawah rata-rata</p>
                                    </div>
                                    <div className='trfcLevelKeterangan level5' style={{flex: '2'}}>
                                        <p className='trfcLevelKeteranganText' >Memiliki wawasan mengenai diri sendiri dan memiliki ekspektasi yang realistis.</p>
                                    </div>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div className='trfcLevel level6' style={{flex: '1'}}>
                                        <p style={{margin: '0px'}}>{'68-101'}</p>
                                    </div>
                                    <div className='trfcLevel level6' style={{flex: '1'}}>
                                        <p style={{margin: '0px'}}>Cukup Rendah</p>
                                    </div>
                                    <div className='trfcLevelKeterangan level6' style={{flex: '2'}}>
                                        <p className='trfcLevelKeteranganText' >Membutuhkan bimbingan untuk kinerja yang optimal.</p>
                                    </div>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div className='trfcLevel level7' style={{flex: '1'}}>
                                        <p style={{margin: '0px'}}>{'< 68'}</p>
                                    </div>
                                    <div className='trfcLevel level7' style={{flex: '1'}}>
                                        <p style={{margin: '0px'}}>Rendah</p>
                                    </div>
                                    <div className='trfcLevelKeterangan level7' style={{flex: '2'}}>
                                        <p className='trfcLevelKeteranganText' >Membutuhkan bantuan dan penanganan khusus untuk kinerja yang lebih baik.</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='trfcMobile' >
                            <p style={{fontSize: '18px', fontWeight: '700', margin: '0px'}}>Keterangan:</p>
                            <p style={{fontSize: '14px', textAlign: 'justify', marginBottom: '10px'}}><span style={{fontWeight: '900'}}>Total Finger Ridge Count (TFRC)</span> adalah suatu angka yang menunjukkan jumlah sulur dari semua jari setiap orang. Menurut Ifa H. Misbach dalam bukunya yang berjudul “Dahsyatnya Sidik Jari: Menguak Bakat dan Potensi untuk Merancang Masa Depan Melalui Fingerprint Analysis”, menyebutkan bahwa penghitungan banyaknya rigi dilakukan mulai dari triradius sampai ke pusat dari pola sulur jari. TFRC merupakan cerminan dari kapasitas belajar bawaan seseorang, umumnya dikenal sebagai “Kapasitas Sel Otak Neo-korteks”. Saat lahir, 100 miliar sel saraf di korteks selebral mengatur rangkaian kabel yang sangat rumit (sekitar 5.000 hingga 10.000 koneksi ke setiap sel saraf). Melalui mekanisme pembelajaran di otak, otak terus menyambung ulang dan mengubah sirkuitnya sepanjang hidup seseorang. Oleh karena itu, belajar penting untuk merangsang keterkaitan neuron diri kita sendiri.
                            </p>

                            <p style={{fontSize: '18px', fontWeight: '700', margin: '0px', color: '#007F5F'}}>Sangat Tinggi</p>
                            <p style={{fontSize: '14px', textAlign: 'justify'}}>Memiliki kemampuan untuk mendobrak batasan dari keterbatasan diri sendiri dan mengalami kesuksesan eksponensial.</p>

                            <p style={{fontSize: '18px', fontWeight: '700', margin: '0px', color: '#2B9348'}}>Tinggi</p>
                            <p style={{fontSize: '14px', textAlign: 'justify'}}>Memiliki kemampuan tersembunyi yang berpotensi menjadi suatu kekuatan besar.</p>

                            <p style={{fontSize: '18px', fontWeight: '700', margin: '0px', color: '#55A630'}}>Diatas Rata-rata</p>
                            <p style={{fontSize: '14px', textAlign: 'justify'}}>Memiliki kemampuan untuk memimpin orang lain untuk mencapai harapan mereka.</p>

                            <p style={{fontSize: '18px', fontWeight: '700', margin: '0px', color: '#80B918'}}>Rata-rata</p>
                            <p style={{fontSize: '14px', textAlign: 'justify'}}>Memiliki kemampuan untuk memotivasi orang lain.</p>

                            <p style={{fontSize: '18px', fontWeight: '700', margin: '0px', color: '#AACC00'}}>Dibawah Rata-rata</p>
                            <p style={{fontSize: '14px', textAlign: 'justify'}}>Memiliki wawasan mengenai diri sendiri dan memiliki ekspektasi yang realistis.</p>

                            <p style={{fontSize: '18px', fontWeight: '700', margin: '0px', color: '#BFD200'}}>Cukup Rendah</p>
                            <p style={{fontSize: '14px', textAlign: 'justify'}}>Membutuhkan bimbingan untuk kinerja yang optimal.</p>

                            <p style={{fontSize: '18px', fontWeight: '700', margin: '0px', color: '#D4D700'}}>Rendah</p>
                            <p style={{fontSize: '14px', textAlign: 'justify'}}>Membutuhkan bantuan dan penanganan khusus untuk kinerja yang lebih baik.</p>

                        </div>
                    </div>

                    <div style={{display: 'flex', justifyContent:'space-between', padding: '20px'}}>
                    {packages === 33 && (
                        <>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report/distribusi-neuron", state: { data: data, package_id: packages }}}>{"<"} Distribusi Neuron</Link>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report/dominasi-otak", state: { data: data, package_id: packages }}}>Dominasi Otak {">"}</Link>
                        </>
                    )}

                    </div>
                </>
            ) : (
                <>
                <div>aku dah tau kar, kamu pasti mau seperti ini ! :v</div>
                </>
            )}
            </div>

        </React.Fragment>
    )

}

export default Tfrc;
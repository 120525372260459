import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import visual from '../../../../assets/images/FutureLink/visual.png';
import kinesthetic from '../../../../assets/images/FutureLink/kinesthetic.png';
import auditory from '../../../../assets/images/FutureLink/auditory.png';

const GayaBelajar= (haisayang) => {
    if(haisayang.history.location.state === undefined){
        window.location.href = "/report"
    }

    const data = haisayang.history.location.state.data
    // console.log(haisayang.history.location.state.data)

    const packages = haisayang.history.location.state.package_id
    // console.log(haisayang.history.location.state.package_id)

    return(
        <React.Fragment>
            <div className="page-content">
                <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                    <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 40px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Gaya Belajar</p>
                </div>

                <div className='gayabelajar'>
                    <Link style={{marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to='/report'>Back</Link>

                    <div className='gayabelajarList'>

                        <div className='gayabelajarVisual'>
                            <div className='gayabelajarBoxValueMobile'>
                                <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Nilai Visual Anda</p>
                                <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{data.visual_learning}%</p>
                            </div>
                            <img src={visual} style={{width: '300px'}}/>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '30px'}}>
                                <div className='gayabelajarBoxValue'>
                                    <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Nilai Visual Anda</p>
                                    <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{data.visual_learning}%</p>
                                </div>
                                <div>
                                    <p style={{fontSize: '16px', width: '400px', textAlign: 'justify'}}>Visual adalah gaya belajar dengan melihat. Orang yang gaya belajarnya visual memiliki kemampuan yang tinggi untuk mengingat gambaran sesuatu. Mereka terbiasa menggunakan representasi visual seperti grafik, poster, peta, serta lain sebagainya.</p>
                                </div>
                            </div>

                        </div>

                        <div className='gayabelajarKinesthetic'>
                            <div className='gayabelajarBoxValueMobile'>
                                <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Nilai Kinesthetic Anda</p>
                                <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{data.kinesthetic_learning}%</p>
                            </div>
                            <img src={kinesthetic} style={{width: '400px'}}/>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '30px'}}>
                                <div className='gayabelajarBoxValue'>
                                    <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Nilai Kinesthetic Anda</p>
                                    <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{data.kinesthetic_learning}%</p>
                                </div>
                                <div>
                                    <p style={{fontSize: '16px', width: '400px', textAlign: 'justify'}}>Kinestetik adalah gaya belajar dengan melakukan. Orang yang gaya belajarnya kinestetik sangat bergantung pada interaksi dalam lingkungan belajar terutama dengan tubuh mereka. Mereka terbiasa melakukan aktivitas fisik, memanipulasi objek, melakukan kunjungan lapangan dan lain sebagainya.</p>
                                </div>
                            </div>

                        </div>

                        <div className='gayabelajarAuditory'>
                            <div className='gayabelajarBoxValueMobile'>
                                <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Nilai Auditory Anda</p>
                                <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}> {data.auditory_learning}%</p>
                            </div>
                            <img src={auditory} style={{width: '435px'}}/>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '30px'}}>
                                <div className='gayabelajarBoxValue'>
                                    <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Nilai Auditory Anda</p>
                                    <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}> {data.auditory_learning}%</p>
                                </div>
                                <div>
                                    <p style={{fontSize: '16px', width: '400px', textAlign: 'justify'}}>Auditori adalah gaya belajar dengan mendengarkan. Orang yang gaya belajarnya auditori memiliki kemampuan yang tinggi untuk mengingat apa yang didengarnya. Mereka terbiasa melakukan pengulangan dan membuat ringkasan, berdiskusi, bercerita, serta lain sebagainya.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{display: 'flex', justifyContent:'space-between', padding: '20px'}}>
                    <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report/dominasi-otak", state: { data: data, package_id: packages }}}>{"<"} Dominasi Otak</Link>
                    <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report/ekstrakulikuler", state: { data: data, package_id: packages }}}>Ekstrakulikuler {">"}</Link>
                </div>
            </div>
        </React.Fragment>
    )

}

export default GayaBelajar;
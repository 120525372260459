import React, {useState} from "react";
import {Row, Col, Progress} from 'reactstrap';
import {Link} from 'react-router-dom';

const KonsepPemikiran = (e) => {
    const state = e.state.data
    const data = e.state.data
    const package_id = e.state.package_id
    const img_finger = e.state.img_finger
    const shape_finger = e.state.shape_finger
    const object = state.object
    const concept = state.concept

    const executor = state.executor
    const thinker = state.thinker

    return(
        <Row className="corev2 qx" style={{height: '85vh'}}>
            <div className="appearOnMobile tiwtiw" style={{marginBottom: '24px'}}>
                <Link to={{pathname: '/client/reportFP', state: { data: data, package_id: package_id, img_finger:img_finger, shape_finger:shape_finger },}} className="bBek">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M10.295 19.7155C10.3886 19.8077 10.4994 19.8806 10.6211 19.93C10.7429 19.9793 10.8731 20.0042 11.0045 20.0033C11.1358 20.0023 11.2657 19.9754 11.3867 19.9243C11.5076 19.8731 11.6173 19.7986 11.7095 19.705C11.8017 19.6115 11.8746 19.5007 11.9239 19.379C11.9733 19.2572 11.9982 19.127 11.9972 18.9956C11.9963 18.8643 11.9694 18.7344 11.9183 18.6134C11.8671 18.4925 11.7926 18.3827 11.699 18.2906L6.32903 13.0006H19.999C20.2642 13.0006 20.5186 12.8952 20.7061 12.7077C20.8937 12.5201 20.999 12.2658 20.999 12.0006C20.999 11.7353 20.8937 11.481 20.7061 11.2934C20.5186 11.1059 20.2642 11.0006 19.999 11.0006H6.33603L11.7 5.71355C11.884 5.52633 11.987 5.2743 11.9867 5.01183C11.9865 4.74936 11.8831 4.49751 11.6988 4.31061C11.5145 4.12371 11.2642 4.01675 11.0017 4.01281C10.7393 4.00887 10.4858 4.10827 10.296 4.28955L3.37203 11.1116C3.25411 11.2278 3.16048 11.3664 3.09657 11.5192C3.03267 11.672 2.99976 11.8359 2.99976 12.0016C2.99976 12.1672 3.03267 12.3311 3.09657 12.4839C3.16048 12.6367 3.25411 12.7753 3.37203 12.8916L10.296 19.7146L10.295 19.7155Z" fill="#374151"/>
                </svg>
                </Link>
                <p className="antitank" style={{textAlign: 'center'}}>Perbandingan Konsep Pemikiran</p>
            </div>

            <div className="koTi">
                <div className="koLef">
                    <p className="koJu">Object Driven</p>
                    <Progress value={object} className='koPro animated-progess progress-label' color={object < 50 ? "red" : "green"} style={{justifyContent: 'right'}}>                      
                        <div className='labeluwu' >{object}%</div>
                    </Progress>
                    <Progress value={concept} className='koPro animated-progess progress-label' color={concept < 50 ? "red" : "green"} style={{justifyContent: 'right'}}>                      
                        <div className='labeluwu' >{concept}%</div>
                    </Progress>
                    <p className="koJu">Concept Driven</p>
                </div>
                <div className="koRig">
                    <p className="koJu">Executor</p>
                    <Progress value={executor} className='koPro animated-progess progress-label' color={executor < 50 ? "red" : "green"}>                      
                        <div className='label' style={{right: '-60px'}}>{executor}%</div>
                    </Progress>
                    <Progress value={thinker} className='koPro animated-progess progress-label' color={thinker < 50 ? "red" : "green"}>                      
                        <div className='label' style={{right: '-60px'}}>{thinker}%</div>
                    </Progress>
                    <p className="koJu">Thinker</p>
                </div>
            </div>

            <div className="koKet">
                <div className="layer1">

                    <div className="layer2">
                    <p style={{fontSize: '18px', fontWeight: '700', marginBottom: '10px'}}>Object Driven</p>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: '190px', height: '3px', backgroundColor: '#05C38A'}}></div>
                        <div style={{width: '12px', height: '12px', borderRadius: '200px', backgroundColor: '#05C38A'}}></div>
                    </div>
                    <p style={{fontSize: '16px', textAlign: 'justify', marginTop: '10px'}}>Orang-orang yang nilai <span style={{fontWeight: '700'}}>object driven</span>-nya lebih tinggi digerakkan oleh objek dan pandai menganalisis situasi apapun. Pemikirannya didasarkan pada fakta, realita dan memiliki pendekatan praktis. Terbiasa untuk bertindak kemudian berpikir. Cenderung cepat merespon dan menyelesaikan tugas serta pandai menganalisis situasi. Orang-orang ini termotivasi oleh tujuan, sasaran dan memenangkan tantangan. Proses berpikirnya rasional dan senang mengambil risiko yang telah diperhitungkan.</p>
                    </div>
                    <div className="layer2">
                        <p style={{fontSize: '18px', fontWeight: '700', marginBottom: '10px'}}>Executor</p>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{width: '190px', height: '3px', backgroundColor: '#05C38A'}}></div>
                            <div style={{width: '12px', height: '12px', borderRadius: '200px', backgroundColor: '#05C38A'}}></div>
                        </div>
                        <p style={{fontSize: '16px', textAlign: 'justify', marginTop: '10px'}}>Orang-orang yang nilai <span style={{fontWeight: '700'}}>executor</span>-nya lebih tinggi didorong oleh tujuan, target, dan pengakuan. Pandai merencanakan dan melaksanakan sesuatu, serta terbiasa untuk mendorong tim mendapatkan hasil kerja yang maksimal. Orang ini dapat memotivasi orang lain dan memimpin dengan teladan mereka sendiri.</p>
                    </div>

                </div>
                <div className="layer1">
                    <div className="layer2">
                    <p style={{fontSize: '18px', fontWeight: '700', marginBottom: '10px'}}>Concept Driven</p>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: '190px', height: '3px', backgroundColor: '#FB5607'}}></div>
                        <div style={{width: '12px', height: '12px', borderRadius: '200px', backgroundColor: '#FB5607'}}></div>
                    </div>
                    <p style={{fontSize: '16px', textAlign: 'justify', marginTop: '10px'}}>Orang-orang yang nilai concept <span style={{fontWeight: '700'}}>driven</span>-nya lebih tinggi didorong oleh konsep dan pandai memvisualisasikan sesuatu atau situasi. Pemikirannya didasarkan pada mimpi, daya tarik dan memiliki pendekatan yang tidak praktis. Terbiasa untuk berpikir dulu dan kemudian bertindak. Memiliki banyak pertanyaan dan sulit untuk fokus. Termotivasi oleh imbalan emosional, hubungan, dan rasa memiliki. Proses berpikirnya emosional dan senang mengambil resiko yang tinggi.</p>
                    </div>
                    
                    <div className="layer2">
                        <p style={{fontSize: '18px', fontWeight: '700', marginBottom: '10px'}}>Thinker</p>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div style={{width: '190px', height: '3px', backgroundColor: '#FB5607'}}></div>
                            <div style={{width: '12px', height: '12px', borderRadius: '200px', backgroundColor: '#FB5607'}}></div>
                        </div>
                        <p style={{fontSize: '16px', textAlign: 'justify', marginTop: '10px'}}>Orang-orang yang nilai <span style={{fontWeight: '700'}}>thinker</span>-nya lebih tinggi didorong oleh organisasi dan visi serta nilai-nilainya. Pandai melakukan pra-peluncuran proyek, dan dapat menganalisis situasi serta risiko yang terkait. Terbiasa menetapkan aturan, sistem, proses, dan kepatuhan di lingkungannya.</p>
                    </div>

                </div>
            </div>

        </Row>
    )
}

export default KonsepPemikiran
import React, {useState} from "react";
import {Row, Col, Progress} from 'reactstrap';
import {Link} from 'react-router-dom';

import red from '../../../../../assets/images/FutureLink/red.png';
import yellow from '../../../../../assets/images/FutureLink/yellow.png';
import green from '../../../../../assets/images/FutureLink/green.png';
import blue from '../../../../../assets/images/FutureLink/blue.png';



const Disc = (e) => {
    const state = e.state.data
    const data = e.state.data
    const package_id = e.state.package_id
    const img_finger = e.state.img_finger
    const shape_finger = e.state.shape_finger
    const disc = state.disc

    return(
        <Row className="corev2 qx" style={{height: '85vh'}}>
            <div className="appearOnMobile tiwtiw" style={{marginBottom: '24px'}}>
                <Link to={{pathname: '/client/reportFP', state: { data: data, package_id: package_id, img_finger:img_finger, shape_finger:shape_finger },}} className="bBek">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M10.295 19.7155C10.3886 19.8077 10.4994 19.8806 10.6211 19.93C10.7429 19.9793 10.8731 20.0042 11.0045 20.0033C11.1358 20.0023 11.2657 19.9754 11.3867 19.9243C11.5076 19.8731 11.6173 19.7986 11.7095 19.705C11.8017 19.6115 11.8746 19.5007 11.9239 19.379C11.9733 19.2572 11.9982 19.127 11.9972 18.9956C11.9963 18.8643 11.9694 18.7344 11.9183 18.6134C11.8671 18.4925 11.7926 18.3827 11.699 18.2906L6.32903 13.0006H19.999C20.2642 13.0006 20.5186 12.8952 20.7061 12.7077C20.8937 12.5201 20.999 12.2658 20.999 12.0006C20.999 11.7353 20.8937 11.481 20.7061 11.2934C20.5186 11.1059 20.2642 11.0006 19.999 11.0006H6.33603L11.7 5.71355C11.884 5.52633 11.987 5.2743 11.9867 5.01183C11.9865 4.74936 11.8831 4.49751 11.6988 4.31061C11.5145 4.12371 11.2642 4.01675 11.0017 4.01281C10.7393 4.00887 10.4858 4.10827 10.296 4.28955L3.37203 11.1116C3.25411 11.2278 3.16048 11.3664 3.09657 11.5192C3.03267 11.672 2.99976 11.8359 2.99976 12.0016C2.99976 12.1672 3.03267 12.3311 3.09657 12.4839C3.16048 12.6367 3.25411 12.7753 3.37203 12.8916L10.296 19.7146L10.295 19.7155Z" fill="#374151"/>
                </svg>
                </Link>
                <p className="antitank" style={{textAlign: 'center'}}>DISC</p>
            </div>

            <div className='discMobileType'>
                { disc === 'domination' && (
                    <>
                        <div style={{margin: '50px'}}>
                            <p style={{fontSize: '36px', fontWeight: '700', color: '#A50E0A'}}>Dominant</p>
                            <div style={{overflow: 'hidden'}}>
                                <img src={red} style={{width: '220px', float: 'left', marginRight: '40px'}} className="discwd"/>
                                <p style={{fontSize: '16px', textAlign: 'justify', margin: '0px'}}>
                                Orang yang berkepribadian <span style={{fontWeight: '700'}}>dominan</span>  cenderung mengutarakan segala hal secara langsung, biasanya memiliki pengaruh yang kuat untuk menentukan sesuatu. Tidak hanya itu, orang yang dominan memiliki kekuatan ego yang tinggi namun dapat menjadi pemecah masalah yang ulung, berani mengambil risiko, dan pemula yang mandiri.
                                <br/><br/>
                                Orang yang dominan adalah seseorang yang dapat mengambil kesimpulan dari segala hal, sangat menghargai segala yang selesai dengan tepat waktu, dan orang-orang yang sangat berinovatif. Namun orang yang dominan memiliki sikap yang terlalu keras kepala, terlalu melebihi otoritas yang dimiliki, sangat tidak menyukai rutinitas, dan terlalu menyusahkan diri dengan melakukan banyak hal secara sekaligus.
                                <br/><br/>
                                Ketakutan terbesar orang yang dominan adalah sangat takut untuk dimanfaatkan. Sedangkan hal yang dapat memotivasi orang yang dominan adalah tantangan baru, menghadapi sesuatu yang terdiri dari kekuasaan dan wewenang untuk mengambil risiko, serta membuat keputusan.
                                </p>
                            </div>
                        </div>
                    </>
                )}
                { disc === 'influential' && (
                    <>
                        <div style={{margin: '50px'}}>
                            <p style={{fontSize: '36px', fontWeight: '700', color: '#F9A239'}}>Influence</p>
                            <div style={{overflow: 'hidden'}}>
                                <img src={yellow} style={{width: '170px', float: 'left', marginRight: '40px'}}  className="discwd"/>
                                <p style={{fontSize: '16px', textAlign: 'justify', margin: '0px'}}>
                                Orang yang berkepribadian <span style={{fontWeight: '700'}}>influence</span> biasa dikenal sebagai seorang yang berpengaruh. Karakteristiknya antusias, memiliki kepercayaan diri yang tinggi, selalu optimis, banyak bicara, impulsive, emosional dan persuasif (mudah mempengaruhi orang lain). Orang yang berpengaruh selalu memberi semangat yang besar kepada orang-orang di sekitarnya, memotivasi orang lain untuk mencapai tujuan, memiliki humor yang positif dan baik, hebat dalam bernegosiasi terutama saat terjadi konflik, serta berperan aktif dalam menciptakan perdamaian.
                                <br/><br/>
                                Namun orang yang berpengaruh ini biasanya lebih mementingkan popularitas daripada hasil yang nyata, tidak suka memperhatikan segala sesuatu secara detail, ekspresif dan cenderung hanya ingin mendengarkan sesuatu yang membuat nyaman dan bahagia.
                                <br/><br/>
                                Ketakutan terbesar dari orang yang berpengaruh adalah penolakan. Orang ini sangat takut dengan sebuah penolakan karena menganggap semua orang akan menerima dan menyukainya.
                                </p>
                            </div>
                        </div>
                    </>
                )}
                { disc === 'steady' && (
                    <>
                        <div style={{margin: '50px'}}>
                            <p style={{fontSize: '36px', fontWeight: '700', color: '#2F940F'}}>Steadiness</p>
                            <div style={{overflow: 'hidden'}}>
                                <img src={green} style={{width: '200px', float: 'left', marginRight: '40px'}}  className="discwd"/>
                                <p style={{fontSize: '16px', textAlign: 'justify', margin: '0px'}}>
                                Orang yang kepribadiannya <span style={{fontWeight: '700'}}>steadiness</span> adalah seorang pendengar yang baik, anggota tim yang unggul, seorang yang posesif dan menenangkan. Orang ini juga mudah untuk ditebak dan sangat ramah serta selalu berusaha memahami orang lain, dapat diandalkan, sangat setia, patuh terhadap pihak yang memiliki otoritas, penyabar, seorang yang memiliki empati tinggi dan sangat handal dalam mendamaikan konflik.
                                <br/><br/>
                                Namun orang berkepribadian steadiness biasanya suka menolak perubahan, sehingga membutuhkan waktu yang cukup lama untuk menyesuaikan diri dengan lingkungan baru. Selain itu biasanya juga merupakan seorang yang pendendam, peka terhadap kritik dan kesulitan untuk menetapkan prioritas.
                                <br/><br/>
                                Ketakutan dari kepribadian ini adalah takut kehilangan rasa aman. Sedangkan hal yang dapat memotivasinya adalah pengakuan atas kesetiaan, berada di lingkungan yang tidak memiliki perubahan secara mendadak, baik dalam prosedur atau gaya hidup serta hal-hal yang mudah untuk dikerjakan.
                                </p>
                            </div>
                        </div>
                    </>
                )}
                { disc === 'compliant' && (
                    <>
                        <div style={{margin: '50px'}}>
                            <p style={{fontSize: '36px', fontWeight: '700', color: '#0584E2'}}>Cautious</p>
                            <div style={{overflow: 'hidden'}}>
                                <img src={blue} style={{width: '170px', float: 'left', marginRight: '40px'}}  className="discwd"/>
                                <p style={{fontSize: '16px', textAlign: 'justify', margin: '0px'}}>
                                Orang yang berkepribadian <span style={{fontWeight: '700'}}>cautious</span> biasanya sangat akurat dan seorang yang analitis, sangat cermat dan teliti serta segalanya harus berdasarkan fakta dan tepat pada sasaran. Hal tersebut membuat orang ini memiliki standar yang tinggi dan sangat sistematis.
                                <br/><br/>
                                Orang yang berkepribadian cautious memiliki ketelitian yang sangat mendalam pada semua kegiatan, suka mendefinisikan situasi, mengumpulkan data, mengkritik dan menguji kebenaran dari informasi-informasi yang didapatkan.
                                <br/><br/>
                                Namun orang yang berkepribadian ini memerlukan batasan yang jelas dari setiap tindakan yang diperbuat, terlalu terikat oleh prosedur dan metode yang ada, sering mandek dalam hal-hal yang rinci, tidak suka mengungkapkan perasaan secara verbal, tidak suka berdebat. Sedangkan hal yang paling ditakutinya adalah kritik.
                                </p>
                            </div>
                        </div>
                    </>
                )}
            </div>

        </Row>
    )
}

export default Disc
import React, { useState, useEffect } from "react";
import {Row, Col} from "reactstrap";
import {Link} from "react-router-dom";
import axios from 'axios';

import promow from "../../../../assets/images/FutureLink/samplePromo.jpg";
import cs from "../../../../assets/images/FutureLink/comspm.png";
import cm from '../../../../assets/images/FutureLink/commbti.png';
import cr from '../../../../assets/images/FutureLink/comros.png';

const ComplementaryClient = () => {
    const code = sessionStorage.getItem("user");
    
    useEffect(() => {
        getAllTest()
    },[])

    const getAllTest = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-test?orderBy=DESC&status=active`,
            headers: { 
              'Authorization': `Bearer ${code}`
            }
          };
          
          axios.request(config)
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
    }

    const array = [
        {
            img: cs,
            tit: 'Standart Progressive Matrices Test',
            ket: 'Standard Progressive Matrices (SPM) Test digunakan untuk mengukur kemampuan seseorang dalam mengerti dan melihat hubungan antara bagian-bagian gambar yang disajikan serta mengembangkan pola berpikir yang sistematis.',
            path: `/complementary/spm-trial/20/${code}`,
            avail: true,
        },
        {
            img: cm,
            tit: 'MBTI test',
            ket: 'MBTI, atau Myers-Briggs Type Indicator, adalah alat penilaian kepribadian yang dirancang untuk membantu seseorang memahami aspek-aspek dasar dari kepribadiannya.',
            path: '',
            avail: false,
        },
        {
            img: cr,
            tit: 'Rorschash Test',
            ket: 'Rorschach Test adalah salah satu jenis tes proyektif yang digunakan dalam psikologi untuk mengevaluasi aspek-aspek tertentu dari psikologi seseorang, terutama yang berkaitan dengan pemikiran, perasaan, dan gambaran diri mereka.',
            path: '',
            avail: false,
        }
    ]

    return(
        <Row className="corev2">
            <Col lg={6}>
                <h2>Promo</h2>
                <img src={promow} alt="promo" style={{width: '100%'}}/>
            </Col>
            <Row style={{marginTop: '50px'}}>
                <h2>Pilih jenis tes yang pengen kamu coba! </h2>
                <p className="ketPur">Nggak cuman test sidik jari aja, future link masih punya jenis test yang bisa kamu coba secara GRATIS!</p>
                <Row style={{gap: '40px'}}>
                    {array.map((item, index) => (
                        <>
                        {item.avail === true && (
                            <Col key={index} lg={3} className="cardcom">
                            <div style={{marginBottom: '24px', width: '100%'}}>
                                <img src={item.img} alt="uwu" style={{width: '100%'}}/>
                            </div>
    
                            <div style={{display: 'flex', flexDirection:'column', justifyContent:'space-between', alignItems: 'center', height: '230px'}}>
                                <div>
                                    <h4 style={{width:'100%'}}>{item.tit}</h4>
                                    <p>{item.ket}</p>
                                </div>
                                <Link to={{pathname: item.path}} className="buttonTest width77" style={{textAlign: 'center'}}>Coba Sekarang</Link>
                            </div>
                        </Col>
                        )}
                        </>
                    ))}
                </Row>
            </Row>
        </Row>
    )
}

export default ComplementaryClient
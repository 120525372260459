import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, CardBody, CardHeader, CardFooter, Col, Container, ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader, ModalFooter, Row, Nav, NavItem, NavLink, TabContent, TabPane, Alert } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Flatpickr from "react-flatpickr";
import QRCode from "react-qr-code";
import Masonry from "react-masonry-component";
import classnames from "classnames";

import axios from 'axios';
import FormData from 'form-data';

import deleteData from '../../../assets/images/FutureLink/deleteData.png'
import accessDenied from '../../../assets/images/FutureLink/accessDenied.png'
import animateAlert from '../../../assets/images/FutureLink/alert.json'

import Refresh from "../../../assets/images/FutureLink/refresh.svg"
import KirimUlang from "../../../assets/images/FutureLink/refresh.png"

import KiJempol from '../../../assets/images/FutureLink/KiJempol.png';
import KiTelunjuk from '../../../assets/images/FutureLink/KiTelunjuk.png';
import KiTengah from '../../../assets/images/FutureLink/KiTengah.png';
import KiManis from '../../../assets/images/FutureLink/KiManis.png';
import KiKelingking from '../../../assets/images/FutureLink/KiKelingking.png';
import KaJempol from '../../../assets/images/FutureLink/KaJempol.png';
import KaTelunjuk from '../../../assets/images/FutureLink/KaTelunjuk.png';
import KaTengah from '../../../assets/images/FutureLink/KaTengah.png';
import KaManis from '../../../assets/images/FutureLink/KaManis.png';
import KaKelingking from '../../../assets/images/FutureLink/KaKelingking.png';

import Lottie from 'react-lottie';
import animateSuccess from '../../../assets/images/FutureLink/success.json'
import animateDelete from '../../../assets/images/FutureLink/delete.json'

// Import React FilePond
import { FilePond,  registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

const TableUserDefaultFpTaker = () => {
    const canvasRef = useRef(null)
    const code = sessionStorage.getItem('user')
    const history = useHistory()
    const [modalAccessDenied, setModalAccessDenied] = useState(false);
    const [step, setStep] = useState(0);

    const [fade, setFade] = useState('fadeout');
    const [alert, setAlert] = useState(true);
    const [modalAlertSuccess, setModalAlertSuccess] = useState(false);
    const [modalAlertDelete, setModalAlertDelete] = useState(false);
    const animationSuccess = {
      loop: true,
      autoplay: true,
      animationData: animateSuccess,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    const animationDelete = {
      loop: true,
      autoplay: true,
      animationData: animateDelete,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    const [kata, setKata] = useState('')

    const [getInstansi, setGetInstansi] = useState([]);

    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState("");

    const [nama, setNama] = useState("");

    const [firstName, setFirstName] = useState("");
    const [surName, setSurName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState("");
    const [uniqueCode, setUniqueCode] = useState("");
    const [dateBirthday, setDateBirthday] = useState("");
    const [instansi, setInstansi] = useState("");

    const [imageFingerprint, setImageFingerprint] = useState("");

    //modal
    const [modalAdd, setModalAdd] = useState(false);
    const [modalUpdate, setModalUpdate] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [modalKirimCode, setModalKirimCode] = useState(false);
    const [modalFingerprint, setModalFingerprint] = useState(false);
    const [modalUpload, setModalUpload] = useState(false);
    const [modalImage, setModalImage] = useState(false);
    //end modal

    const [filesMacro, setFilesMacro] = useState([]);
    const [previewL1, setPreviewL1] = useState("");
    const [doneL1, setDoneL1] = useState(false);
    const [previewL2, setPreviewL2] = useState("");
    const [doneL2, setDoneL2] = useState(false);
    const [previewL3, setPreviewL3] = useState("");
    const [doneL3, setDoneL3] = useState(false);
    const [previewL4, setPreviewL4] = useState("");
    const [doneL4, setDoneL4] = useState(false);
    const [previewL5, setPreviewL5] = useState("");
    const [doneL5, setDoneL5] = useState(false);
    const [previewR1, setPreviewR1] = useState("");
    const [doneR1, setDoneR1] = useState(false);
    const [previewR2, setPreviewR2] = useState("");
    const [doneR2, setDoneR2] = useState(false);
    const [previewR3, setPreviewR3] = useState("");
    const [doneR3, setDoneR3] = useState(false);
    const [previewR4, setPreviewR4] = useState("");
    const [doneR4, setDoneR4] = useState(false);
    const [previewR5, setPreviewR5] = useState("");
    const [doneR5, setDoneR5] = useState(false);

    const [indexFingerprint, setIndexFingerprint] = useState("");

    const [l1, setL1] = useState("")
    const [l2, setL2] = useState("")
    const [l3, setL3] = useState("")
    const [l4, setL4] = useState("")
    const [l5, setL5] = useState("")
    const [r1, setR1] = useState("")
    const [r2, setR2] = useState("")
    const [r3, setR3] = useState("")
    const [r4, setR4] = useState("")
    const [r5, setR5] = useState("")

    const [sorting, setSorting] = useState(false)
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [orderDate, setOrderDate] = useState('asc')
    const [modalNoData, setModalNoData] = useState(false)
    const [row, setRow] = useState(10)


    const [arrowNavTab, setarrowNavTab] = useState("1");
    const arrowNavToggle = (tab) => {
        if (arrowNavTab !== tab) {
            setarrowNavTab(tab);
        }
    };

    const handleModalImage = (img) => {
        setModalImage(!modalImage);
        setImageFingerprint(img);
        // console.log(img)
    }

    const generate = () => {
        random_kode(7);
    };
    
    function random_kode(string_length) {
        var random_string = "";
        var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < string_length; i++) {
            random_string += characters.charAt(
            Math.floor(Math.random() * characters.length)
          );
        }
        setUniqueCode(random_string);
    }

    const changeKode = (event) => {
        const value = event.target.value;
    }

    const animationOptions = {
        loop: true,
        autoplay: true,
        animationData: animateAlert,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const handleUploadFileMacro = () => {
        setModalImage(false);
        setModalUpload(false);

        if(indexFingerprint === "L1") {
            const srcl1 = URL.createObjectURL(previewL1)
            setPreviewL1(srcl1)

            // console.log(indexFingerprint)
            setL1(filesMacro)
            setDoneL1(true)
        }

        if(indexFingerprint === "L2") {
            const srcl2 = URL.createObjectURL(previewL2)
            setPreviewL2(srcl2)
    
            // console.log(indexFingerprint)
            setL2(filesMacro)
            setDoneL2(true)
        } 

        if(indexFingerprint === "L3") {
            const srcl3 = URL.createObjectURL(previewL3)
            setPreviewL3(srcl3)
    
            // console.log(indexFingerprint)
            setL3(filesMacro)
            setDoneL3(true)
        } 

        if(indexFingerprint === "L4") {
            const srcl4 = URL.createObjectURL(previewL4)
            setPreviewL4(srcl4)
    
            // console.log(indexFingerprint)
            setL4(filesMacro)
            setDoneL4(true)
        } 

        if(indexFingerprint === "L5") {
            const srcl5 = URL.createObjectURL(previewL5)
            setPreviewL5(srcl5)
    
            // console.log(indexFingerprint)
            setL5(filesMacro)
            setDoneL5(true)
        } 

        if(indexFingerprint === "R1") {
            const srcr1 = URL.createObjectURL(previewR1)
            setPreviewR1(srcr1)

            // console.log(indexFingerprint)
            setR1(filesMacro)
            setDoneR1(true)
        } 

        if(indexFingerprint === "R2") {
            const srcr2 = URL.createObjectURL(previewR2)
            setPreviewR2(srcr2)
    
            // console.log(indexFingerprint)
            setR2(filesMacro)
            setDoneR2(true)
        } 

        if(indexFingerprint === "R3") {
            const srcr3 = URL.createObjectURL(previewR3)
            setPreviewR3(srcr3)
    
            // console.log(indexFingerprint)
            setR3(filesMacro)
            setDoneR3(true)
        } 

        if(indexFingerprint === "R4") {
            const srcr4 = URL.createObjectURL(previewR4)
            setPreviewR4(srcr4)
    
            // console.log(indexFingerprint)
            setR4(filesMacro)
            setDoneR4(true)
        } 

        if(indexFingerprint === "R5") {
            const srcr5 = URL.createObjectURL(previewR5)
            setPreviewR1(srcr5)
    
            // console.log(indexFingerprint)
            setR5(filesMacro)
            setDoneR5(true)
        }
    }

    const handleUploadFileScanner = () => {
        const canvas = canvasRef.current;
        
        const context = canvas.getContext('2d');
        const image = new Image()

        setModalImage(false);
        setModalUpload(false);

        if(indexFingerprint === "L1") {
            const srcl1 = URL.createObjectURL(previewL1)
            image.src = srcl1
            image.onload = () => {
                context.translate(image.width, 0)
                context.scale(-1,1);
                context.drawImage(image, 10, 10, image.width, image.height);
                canvas.toBlob((blob) => {
                    // console.log(blob)
                    setL1(new File([blob], "mirrorL1", {type: 'image/jpg'}))
                })

                setPreviewL1(canvas.toDataURL());
                
            }
            setDoneL1(true)
        }

        if(indexFingerprint === "L2") {
            const srcl2 = URL.createObjectURL(previewL2)
            image.src = srcl2
            image.onload = () => {
                context.translate(image.width, 0)
                context.scale(-1,1);
                context.drawImage(image, 10, 10, image.width, image.height);
                canvas.toBlob((blob) => {
                    // console.log(blob)
                    setL2(new File([blob], "mirrorL2", {type: 'image/jpg'}))
                })

                setPreviewL2(canvas.toDataURL());
                
            }
            setDoneL2(true)
        } 

        if(indexFingerprint === "L3") {
            const srcl3 = URL.createObjectURL(previewL3)
            image.src = srcl3
            image.onload = () => {
                context.translate(image.width, 0)
                context.scale(-1,1);
                context.drawImage(image, 10, 10, image.width, image.height);
                canvas.toBlob((blob) => {
                    // console.log(blob)
                    setL3(new File([blob], "mirrorL3", {type: 'image/jpg'}))
                })

                setPreviewL3(canvas.toDataURL());
                
            }
            setDoneL3(true)
        } 

        if(indexFingerprint === "L4") {
            const srcl4 = URL.createObjectURL(previewL4)
            image.src = srcl4
            image.onload = () => {
                context.translate(image.width, 0)
                context.scale(-1,1);
                context.drawImage(image, 10, 10, image.width, image.height);
                canvas.toBlob((blob) => {
                    // console.log(blob)
                    setL4(new File([blob], "mirrorL4", {type: 'image/jpg'}))
                })

                setPreviewL4(canvas.toDataURL());
                
            }
            setDoneL4(true)
        } 

        if(indexFingerprint === "L5") {
            const srcl5 = URL.createObjectURL(previewL5)
            image.src = srcl5
            image.onload = () => {
                context.translate(image.width, 0)
                context.scale(-1,1);
                context.drawImage(image, 10, 10, image.width, image.height);
                canvas.toBlob((blob) => {
                    // console.log(blob)
                    setL5(new File([blob], "mirrorL5", {type: 'image/jpg'}))
                })

                setPreviewL5(canvas.toDataURL());
                
            }
            setDoneL5(true)
        } 

        if(indexFingerprint === "R1") {
            const srcr1 = URL.createObjectURL(previewR1)
            image.src = srcr1
            image.onload = () => {
                context.translate(image.width, 0)
                context.scale(-1,1);
                context.drawImage(image, 10, 10, image.width, image.height);
                canvas.toBlob((blob) => {
                    // console.log(blob)
                    setR1(new File([blob], "mirrorR1", {type: 'image/jpg'}))
                })

                setPreviewR1(canvas.toDataURL());
                
            }
            setDoneR1(true)
        } 

        if(indexFingerprint === "R2") {
            const srcr2 = URL.createObjectURL(previewR2)
            image.src = srcr2
            image.onload = () => {
                context.translate(image.width, 0)
                context.scale(-1,1);
                context.drawImage(image, 10, 10, image.width, image.height);
                canvas.toBlob((blob) => {
                    // console.log(blob)
                    setR2(new File([blob], "mirrorR2", {type: 'image/jpg'}))
                })

                setPreviewR2(canvas.toDataURL());
                
            }
            setDoneR2(true)
        } 

        if(indexFingerprint === "R3") {
            const srcr3 = URL.createObjectURL(previewR3)
            image.src = srcr3
            image.onload = () => {
                context.translate(image.width, 0)
                context.scale(-1,1);
                context.drawImage(image, 10, 10, image.width, image.height);
                canvas.toBlob((blob) => {
                    // console.log(blob)
                    setR3(new File([blob], "mirrorR3", {type: 'image/jpg'}))
                })

                setPreviewR3(canvas.toDataURL());
                
            }
            setDoneR3(true)
        } 

        if(indexFingerprint === "R4") {
            const srcr4 = URL.createObjectURL(previewR4)
            image.src = srcr4
            image.onload = () => {
                context.translate(image.width, 0)
                context.scale(-1,1);
                context.drawImage(image, 10, 10, image.width, image.height);
                canvas.toBlob((blob) => {
                    // console.log(blob)
                    setR4(new File([blob], "mirrorR4", {type: 'image/jpg'}))
                })

                setPreviewR4(canvas.toDataURL());
                
            }
            setDoneR4(true)
        } 

        if(indexFingerprint === "R5") {
            const srcr5 = URL.createObjectURL(previewR5)
            image.src = srcr5
            image.onload = () => {
                context.translate(image.width, 0)
                context.scale(-1,1);
                context.drawImage(image, 10, 10, image.width, image.height);
                canvas.toBlob((blob) => {
                    // console.log(blob)
                    setR5(new File([blob], "mirrorR5", {type: 'image/jpg'}))
                })

                setPreviewR5(canvas.toDataURL());
                
            }
            setDoneR5(true)
        }
    }

    useEffect(() => {
        getAllInstansi()
        // getAllClient()
        
    },[])

    useEffect(() => {
        getAllClientNew()
    },[page, limit, orderDate])

    const getAllInstansi = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-agency`,
            headers: { 
              'Authorization': `Bearer ${code}`
            }
        };
        
        axios(config)
        .then((response) => {
            // console.log(response.results)
            setGetInstansi(response.results);

        })
        .catch((error) => {
            // console.log(error);
            if(error.response.data.statusCode === 403) {
                setModalAccessDenied(true)
            }
        });
          
    }

    // const getAllClient = () => {

    //     let config = {
    //         method: 'get',
    //         maxBodyLength: Infinity,
    //         url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-privilege-user?privilegeId=51`,
    //         headers: { 
    //           'Authorization': `Bearer ${code}`
    //         }
    //     };
          
    //       axios.request(config)
    //       .then((response) => {
    //         // console.log(response)
    //         if(response.statusCode !== 403) {
    //             const data = response.results
    //             if (sorting){
    //                 const sortedData = [...data].sort((a, b) => b.user_id - a.user_id);
    //                 setUsers(sortedData);
    //             } else {
    //                 const sortedData = [...data].sort((a, b) => a.user_id - b.user_id);
    //                 setUsers(sortedData);
    //             }
    //             // console.log(sortedData)
                
    //         } else {
    //             setModalAccessDenied(true)
    //         }
    //       })
    //       .catch((error) => {
    //         // console.log(error);
    //         if(error.response.data.statusCode === 403) {
    //             setModalAccessDenied(true)
    //         }
    //       });

    // }

    // const getAllClientSort = (sorting) => {

    //     let config = {
    //         method: 'get',
    //         maxBodyLength: Infinity,
    //         url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-privilege-user?privilegeId=51`,
    //         headers: { 
    //           'Authorization': `Bearer ${code}`
    //         }
    //     };
          
    //       axios.request(config)
    //       .then((response) => {
    //         // console.log(response)
    //         if(response.statusCode !== 403) {
    //             const data = response.results
    //             if (sorting){
    //                 const sortedData = [...data].sort((a, b) => b.user_id - a.user_id);
    //                 setUsers(sortedData);
    //             } else {
    //                 const sortedData = [...data].sort((a, b) => a.user_id - b.user_id);
    //                 setUsers(sortedData);
    //             }
    //             // console.log(sortedData)
                
    //         } else {
    //             setModalAccessDenied(true)
    //         }
    //       })
    //       .catch((error) => {
    //         // console.log(error);
    //         if(error.response.data.statusCode === 403) {
    //             setModalAccessDenied(true)
    //         }
    //       });

    // }

    const getAllClientNew = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-user-client-only?page=${page}&limit=${limit}&orderDate=${orderDate}`,
            headers: { 
              'Authorization': `Bearer ${code}`
            }
          };
          
          axios.request(config)
          .then((response) => {
            // console.log("hai",  response.results);
            setUsers(response.results)
            setRow(response.results.length)
          })
          .catch((error) => {
            console.log(error.response);
            if(error.response.data.statusCode === 404) {
                setModalNoData(true);
                setPage(1)
            }
          });
    }

    const getClient = (userId) => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-user?userId=${userId}`,
            headers: { 
                'Authorization': `Bearer ${code}`
            }
        };
          
        axios(config)
          .then((response) => {
            // console.log(response.results);
            setFirstName(response.results.first_name);
            setSurName(response.results.last_name);
            setPhone(response.results.phone);
            setEmail(response.results.email);
            setGender(response.results.sex);
            
            // const formatDate = new Date(response.results[0].date_birth)
            // const year = formatDate.getFullYear();
            // const month = formatDate.getMonth()+1 > 9 ? formatDate.getMonth()+1 : "0"+(formatDate.getMonth()+1);
            // const day = formatDate.getDate() > 9 ? formatDate.getDate() : "0"+formatDate.getDate();
            // setDateBirthday(year+"-"+month+"-"+day);
            
          })
          .catch((error) => {
            // console.log(error);
            if(error.response.data.statusCode === 403) {
                setModalAccessDenied(true)
            }
          });
    }

    const getFingerprint = (user_id) => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-by-id?userId=${user_id}`,
            headers: { 
              'Authorization': `Bearer ${code}`,
            },
          };
          
          axios.request(config)
          .then((response) => {
            // console.log(response);

            if(response.status !== 401) {

                getImage(response.results[0].l1, "l1")
                getImage(response.results[0].l2, "l2")
                getImage(response.results[0].l3, "l3")
                getImage(response.results[0].l4, "l4")
                getImage(response.results[0].l5, "l5")
                getImage(response.results[0].r1, "r1")
                getImage(response.results[0].r2, "r2")
                getImage(response.results[0].r3, "r3")
                getImage(response.results[0].r4, "r4")
                getImage(response.results[0].r5, "r5")
                setStep(1)

            }

            // history.push('/users-group/list-of-images-fingerprint-taker')
          })
          .catch((error) => {            
            // console.log(error);
            // console.log(error.response);
            if(error.response.data.statusCode === 403) {
                setModalAccessDenied(true)
            }else{
                setModalFingerprint(true)
            }
          });
    }

    const getImage = (imageName, index) => {

        if(index === "l1"){
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
                headers: { 
                  'Authorization': `Bearer ${code}`
                }
            };
              
            axios.request(config)
                .then((response) => {
                    // console.log(response)
                    setL1(response.results.image)
                })
                .catch((error) => {
                    // console.log(error);
                });
        }
        if(index === "l2"){
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
                headers: { 
                  'Authorization': `Bearer ${code}`
                }
            };
              
            axios.request(config)
                .then((response) => {
                    // console.log(response)
                    setL2(response.results.image)
                })
                .catch((error) => {
                    // console.log(error);
                });
        }
        if(index === "l3"){
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
                headers: { 
                  'Authorization': `Bearer ${code}`
                }
            };
              
            axios.request(config)
                .then((response) => {
                    // console.log(response)
                    setL3(response.results.image)
                })
                .catch((error) => {
                    // console.log(error);
                });
        }
        if(index === "l4"){
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
                headers: { 
                  'Authorization': `Bearer ${code}`
                }
            };
              
            axios.request(config)
                .then((response) => {
                    // console.log(response)
                    setL4(response.results.image)
                })
                .catch((error) => {
                    // console.log(error);
                });
        }
        if(index === "l5"){
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
                headers: { 
                  'Authorization': `Bearer ${code}`
                }
            };
              
            axios.request(config)
                .then((response) => {
                    // console.log(response)
                    setL5(response.results.image)
                })
                .catch((error) => {
                    // console.log(error);
                });
        }
        if(index === "r1"){
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
                headers: { 
                  'Authorization': `Bearer ${code}`
                }
            };
              
            axios.request(config)
                .then((response) => {
                    // console.log(response)
                    setR1(response.results.image)
                })
                .catch((error) => {
                    // console.log(error);
                });
        }
        if(index === "r2"){
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
                headers: { 
                  'Authorization': `Bearer ${code}`
                }
            };
              
            axios.request(config)
                .then((response) => {
                    // console.log(response)
                    setR2(response.results.image)
                })
                .catch((error) => {
                    // console.log(error);
                });
        }
        if(index === "r3"){
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
                headers: { 
                  'Authorization': `Bearer ${code}`
                }
            };
              
            axios.request(config)
                .then((response) => {
                    // console.log(response)
                    setR3(response.results.image)
                })
                .catch((error) => {
                    // console.log(error);
                });
        }
        if(index === "r4"){
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
                headers: { 
                  'Authorization': `Bearer ${code}`
                }
            };
              
            axios.request(config)
                .then((response) => {
                    // console.log(response)
                    setR4(response.results.image)
                })
                .catch((error) => {
                    // console.log(error);
                });
        }
        if(index === "r5"){
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
                headers: { 
                  'Authorization': `Bearer ${code}`
                }
            };
              
            axios.request(config)
                .then((response) => {
                    // console.log(response)
                    setR5(response.results.image)
                })
                .catch((error) => {
                    // console.log(error);
                });
        }


    }

    const [waiting, setWaiting] = useState(false);

    const addClient = () => {
        setWaiting(true)

        const formatDate = new Date(dateBirthday)
        const year = formatDate.getFullYear();
        const month = formatDate.getMonth()+1 > 9 ? formatDate.getMonth()+1 : "0"+(formatDate.getMonth()+1);
        const day = formatDate.getDate() > 9 ? formatDate.getDate() : "0"+formatDate.getDate();
        const tanggalLahir = year+"-"+month+"-"+day

        if(instansi === "" || uniqueCode === "" || firstName === "" || surName === "" || gender === "") {
            setAlert(false);
            setWaiting(false);

            setTimeout(() => {
                setFade('fade')
            }, 200)
    
            setTimeout(() => {
                setFade('fadeout');
            }, 4500)
    
            setTimeout(() => {
                setAlert(true);
            }, 5000);
        } else {

            let data = new FormData();
            data.append('firstName', firstName);
            data.append('lastName', surName);
            data.append('phone', phone);
            if(email !== "") {
                data.append('email', email);
            }
            if(tanggalLahir === "NaN-0NaN-0NaN") {
                console.log("")
            } else{
                data.append('dateBirth', tanggalLahir)
            }
            
            data.append('sex', gender);
            data.append('privilegeId', '51');
      
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/make-custom-user`,
                headers: { 
                  'Authorization': `Bearer ${code}`, 
                },
                data : data
            };
      
            axios(config)
                .then((response) => {
                // console.log(response);
    
                let config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    // url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-user`, 
                    url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-privilege-user?privilegeId=51`,
                    headers: { 
                        'Authorization': `Bearer ${code}`, 
                    },
                };
            
                axios(config)
                    .then((response) => {
                        // console.log(response)
                        // console.log(response.results)
                        const coba = response.results;
                        const index = response.results.length - 1;
                        const id = coba[index].user_id;
    
                        // console.log(id, uniqueCode);
    
                        let data = new FormData();
                        data.append('userId', id);
                        data.append('uniqueCode', uniqueCode);

                        let config = {
                            method: 'post',
                            maxBodyLength: Infinity,
                            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/make-unique-code`,
                            headers: { 
                                'Authorization': `Bearer ${code}`, 
                            },
                            data : data
                        };
    
                        axios(config)
                        .then((response) => {
                                // console.log(response);
    
                                let data = new FormData();
                                data.append('userId', id);
                                data.append('agencyId', instansi);
    
                                let config = {
                                    method: 'post',
                                    maxBodyLength: Infinity,
                                    url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/make-user-agency`,
                                    headers: { 
                                        'Authorization': `Bearer ${code}`, 
                                    },
                                    data : data
                                };
    
                                axios(config)
                                .then((response) => {
                                    // console.log(response);
    
                                    if(response.statusCode !== 403){
                                        getAllClientNew();
                                        setModalAdd(false);
                                        setWaiting(false)
    
                                        setModalAlertSuccess(true)
                                        setTimeout(() => {
                                            setModalAlertSuccess(false)
                                        }, 4000)
                                    } else {
                                        setModalAccessDenied(true);
                                    }
    
                                })
                                .catch((error) => {
                                    // console.log(error);
                                    if(error.response.data.statusCode === 403) {
                                        setModalAccessDenied(true)
                                    }
    
                                    setAlert(false);
                                    setWaiting(false);
    
                                    setTimeout(() => {
                                        setFade('fade')
                                    }, 200)
                            
                                    setTimeout(() => {
                                        setFade('fadeout');
                                    }, 4500)
                            
                                    setTimeout(() => {
                                        setAlert(true);
                                    }, 5000);
                                });

                        })
                        .catch((error) => {
                            // console.log(error);
                            if(error.response.data.statusCode === 403) {
                                setModalAccessDenied(true)
                            }
    
                            setAlert(false);
                            setWaiting(false);
    
                            setTimeout(() => {
                                setFade('fade')
                            }, 200)
                    
                            setTimeout(() => {
                                setFade('fadeout');
                            }, 4500)
                    
                            setTimeout(() => {
                                setAlert(true);
                            }, 5000);
                        });
                    })
                    .catch((error) => {
                        // console.log(error)
                        if(error.response.data.statusCode === 403) {
                            setModalAccessDenied(true)
                        }
    
                        setAlert(false);
                        setWaiting(false);
    
                        setTimeout(() => {
                            setFade('fade')
                        }, 200)
                
                        setTimeout(() => {
                            setFade('fadeout');
                        }, 4500)
                
                        setTimeout(() => {
                            setAlert(true);
                        }, 5000);
                    })
            })
            .catch((error) => {
                // console.log(error);
                if(error.response.data.statusCode === 403) {
                    setModalAccessDenied(true)
                }
    
                setAlert(false);
                setWaiting(false);
    
                setTimeout(() => {
                    setFade('fade')
                }, 200)
        
                setTimeout(() => {
                    setFade('fadeout');
                }, 4500)
        
                setTimeout(() => {
                    setAlert(true);
                }, 5000);
            });

        }

    }

    const updateFingerprint = () => {
        let data = new FormData();
        data.append('userId', userId);
        if(doneR1 === true){
        data.append('r1', r1);
        }
        if(doneR2 === true){
        data.append('r2', r2);
        }
        if(doneR3 === true){
        data.append('r3', r3);
        }
        if(doneR4 === true){
        data.append('r4', r4);
        }
        if(doneR5 === true){
        data.append('r5', r5);
        }
        if(doneL1 === true){
        data.append('l1', l1);
        }
        if(doneL2 === true){
        data.append('l2', l2);
        }
        if(doneL3 === true){
        data.append('l3', l3);
        }
        if(doneL4 === true){
        data.append('l4', l4);
        }
        if(doneL5 === true){
        data.append('l5', l5);
        }

        let config = {
        method: 'patch',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/update-fingerprint`,
        headers: { 
            'Authorization': `Bearer ${code}`, 
        },
        data : data
        };

        axios.request(config)
        .then((response) => {
            // console.log(response);
            setModalAlertSuccess(true)
            setTimeout(() => {
                setModalAlertSuccess(false)
            }, 4000)
        })
        .catch((error) => {
        // console.log(error);
        if(error.response.data.statusCode === 403) {
            setModalAccessDenied(true)
        }
        });
    }

    const updateUniqueCode = () => {
        let data = new FormData();
        data.append('userId', userId);
        data.append('uniqueCode', uniqueCode);

        let config = {
        method: 'patch',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/update-unique-code`,
        headers: { 
            'Authorization': `Bearer ${code}`, 
        },
        data : data
        };

        axios.request(config)
        .then((response) => {
            // console.log(response);
            setModalKirimCode(false)
            getAllClientNew()

            setModalAlertSuccess(true)
            setTimeout(() => {
                setModalAlertSuccess(false)
            }, 4000)
        })
        .catch((error) => {
        // console.log(error);
        if(error.response.data.statusCode === 403) {
            setModalAccessDenied(true)
        }
        });
    }

    const updateClient = () => {
        const formatDate = new Date(dateBirthday)
        const year = formatDate.getFullYear();
        const month = formatDate.getMonth()+1 > 9 ? formatDate.getMonth()+1 : "0"+(formatDate.getMonth()+1);
        const day = formatDate.getDate() > 9 ? formatDate.getDate() : "0"+formatDate.getDate();

        let data = new FormData();
        data.append('userId', userId);
        data.append('firstName', firstName);
        data.append('lastName', surName);
        if(email !== null) {
            data.append('email', email);
        }
        data.append('phone', phone);
        data.append('sex', gender);
        if(dateBirthday !== undefined){
            data.append('dateBirth', year+"-"+month+"-"+day);
          }

        let config = {
        method: 'patch',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/update-user`,
        headers: { 
            'Authorization': `Bearer ${code}`, 
        },
        data : data
        };

        axios(config)
        .then((response) => {
        // console.log(response);
        setModalUpdate(false);
        getAllClientNew()

        setModalAlertSuccess(true)
        setTimeout(() => {
            setModalAlertSuccess(false)
        }, 4000)
        })
        .catch((error) => {
        // console.log(error);
        if(error.response.data.statusCode === 403) {
            setModalAccessDenied(true)
        }

        setAlert(false);

        setTimeout(() => {
            setFade('fade')
        }, 200)

        setTimeout(() => {
            setFade('fadeout');
        }, 4500)

        setTimeout(() => {
            setAlert(true);
        }, 5000);
        });

    }

    const logOut = () => {
        sessionStorage.removeItem('user');
        window.location.href = '/login'
    }

    return ( 
        <React.Fragment>
            <div className="page-content">

            <Row>
                {step === 0 && (
                <>
                <Col lg={12}>
                    <Card>
                        <CardHeader>
                            <h4 className="card-title mb-0">Fingerprint Taker Table</h4>
                        </CardHeader>

                        <CardBody>
                            <div id="customerList">
                                <Row className="g-4 mb-3">
                                    <Col className="col-sm-auto">
                                        
                                        <div>
                                            <button className="btn buttonVersFutureLinkGreen"
                                                onClick={() => {
                                                setModalAdd(true);
                                                setFirstName("");
                                                setSurName("");
                                                setEmail("");
                                                setPhone("");
                                                setGender("");
                                                setUniqueCode("");
                                                setWaiting(false);
                                                }}
                                            ><i className="ri-add-line align-bottom me-1"></i> Add</button>
                                            {/* <Button className="btn btn-soft-danger" onClick={()=> setModalDelete(true)}><i className="ri-delete-bin-2-line"></i></Button> */}
                                        </div>
                                    </Col>
                                    <Col className="col-sm">
                                        <div className="d-flex justify-content-sm-end">
                                            <div style={{marginRight: '10px'}}>
                                                <select className='form-control' value={limit} onChange={(event) => {setLimit(event.target.value); setPage(1)}}>
                                                    <option value={10} selected="selected">10</option>
                                                    <option value={20}>20</option>
                                                    <option value={50}>50</option>
                                                    <option value={100}>100</option>
                                                    <option value={200}>200</option>
                                                </select>
                                            </div>
                                            <div style={{marginRight: '10px'}}>
                                                {sorting === true ? (
                                                    <>
                                                    <button className='btn buttonVersFutureLinkBorderGrey' onClick={() => {setOrderDate('asc'); setSorting(!sorting)}}>
                                                        <i className='ri-sort-asc'></i>  
                                                    </button>
                                                    </>
                                                    ) : (
                                                    <>
                                                    <button className='btn buttonVersFutureLinkBorderGrey' onClick={() => {setOrderDate('desc'); setSorting(!sorting)}}>
                                                        <i className='ri-sort-desc'></i>  
                                                    </button>
                                                    </>
                                                )}
                                            </div>
                                            <div className="search-box" style={{marginRight: '10px'}}>
                                                <input type="text" className="form-control search" placeholder="Search..." onChange={(e) => setKata(e.target.value)}/>
                                                <i className="ri-search-line search-icon"></i>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <div className="pagination-wrap hstack gap-2">
                                                    {page !== 1 && (
                                                        <>
                                                        <Link className="page-item pagination-prev" onClick={() => {setPage(page-1)}}>
                                                            Previous
                                                        </Link>
                                                        </>
                                                    )}
                                                    {row === parseInt(limit) && (
                                                        <>
                                                        <Link className="page-item pagination-next" onClick={() => {setPage(page+1)}}>
                                                            Next
                                                        </Link>
                                                        </>
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <div className="table-responsive table-card mt-3 mb-1">
                                    <table className="table align-middle table-nowrap" id="customerTable">
                                        <thead className="table-light">
                                            <tr>
                                                <th scope="col" style={{ width: "50px" }}>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                    </div>
                                                </th>
                                                <th className="sort" data-sort="no">No</th>
                                                <th className="sort" data-sort="nama">Nama</th>
                                                <th className="sort" data-sort="email">Email</th>
                                                <th className="sort" data-sort="status">Package</th>
                                                <th className="sort" data-sort="instansi">Instansi</th>
                                                <th className="sort" data-sort="gender">Gender</th>
                                                <th className="sort" data-sort="date-birthday">Date Birthday</th>
                                                <th className="sort" data-sort="unique-code">Unique Code</th>
                                                <th className="sort" data-sort="action">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="list form-check-all">
                                            {users
                                            .filter((item) => 
                                            // item.agency_name.toLowerCase().includes(kata.toLowerCase()) ||
                                            item.first_name.toLowerCase().includes(kata.toLowerCase()) ||
                                            item.last_name.toLowerCase().includes(kata.toLowerCase()))
                                            .map((item, index) => (
                                            <>
                                            <tr key={index}>
                                                <th scope="row">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="checkAll" value="option1" />
                                                    </div>
                                                </th>
                                                <td className="no">{index+1}</td>
                                                <td className="nama">{item.first_name} {item.last_name}</td>
                                                <td className="email">{item.email}</td>
                                                <td className="package"><span className="badge badge-soft-success text-uppercase">{item.package_name}</span></td>
                                                <td className="instansi">{item.agency_name}</td>
                                                <td className="gender">{item.sex}</td>
                                                <td className="date-birthday">{new Date(item.date_birth).getFullYear() + "-" + (new Date(item.date_birth).getMonth()+1 > 9 ? new Date(item.date_birth).getMonth()+1 : "0"+(new Date(item.date_birth).getMonth()+1)) + "-" + (new Date(item.date_birth).getDate() > 9 ? new Date(item.date_birth).getDate() : "0"+new Date(item.date_birth).getDate()) == "1970-01-01" ? (<><span style={{color: 'red'}}>Not Available</span></>) : new Date(item.date_birth).getFullYear() + "-" + (new Date(item.date_birth).getMonth()+1 > 9 ? new Date(item.date_birth).getMonth()+1 : "0"+(new Date(item.date_birth).getMonth()+1)) + "-" + (new Date(item.date_birth).getDate() > 9 ? new Date(item.date_birth).getDate() : "0"+new Date(item.date_birth).getDate()) }</td>
                                                <td className="uniqueCode">{item.unique_code}</td>
                                                <td>
                                                    <div className="d-flex gap-2">
                                                        <div>
                                                            <button className="btn buttonVersFutureLinkTeal"  onClick={() => {getFingerprint(item.user_id); setUserId(item.user_id); setNama(`${item.first_name} ${item.last_name}`)}}>View Images</button>
                                                        </div>
                                                        <div>
                                                            <button className="btn buttonVersFutureLink" onClick={() => {setModalKirimCode(true); setUniqueCode(item.unique_code === null ? "" : item.unique_code); setUserId(item.user_id)}}>Refresh Unique Code</button>
                                                        </div>
                                                        <div>
                                                            <button className="btn buttonVersFutureLinkGreen" onClick={() => {
                                                                setModalUpdate(true); 
                                                                getClient(item.user_id); 
                                                                setUserId(item.user_id);
                                                                setFirstName("");
                                                                setSurName("");
                                                                setPhone("");
                                                                setEmail("");
                                                                setGender("");
                                                                setDateBirthday("");
                                                                }}>Edit</button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            </>

                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="d-flex justify-content-end">
                                    <div className="pagination-wrap hstack gap-2">
                                        {page !== 1 && (
                                            <>
                                            <Link className="page-item pagination-prev" onClick={() => {setPage(page-1)}}>
                                                Previous
                                            </Link>
                                            </>
                                        )}

                                        <Link className="page-item pagination-next" onClick={() => {setPage(page+1)}}>
                                            Next
                                        </Link>

                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                </>
                )}
                {step === 1 && (
                <>
                    <button className='btn buttonVersFutureLinkBorderGrey' style={{width : '70px', marginLeft: '20px'}} 
                        onClick={() => {
                            setStep(0);
                            setR1("");
                            setR2("");
                            setR3("");
                            setR4("");
                            setR5("");
                            setL1("");
                            setL2("");
                            setL3("");
                            setL4("");
                            setL5("");
                            setNama("");
                            setDoneL1(false);
                            setDoneL2(false);
                            setDoneL3(false);
                            setDoneL4(false);
                            setDoneL5(false);
                            setDoneR1(false);
                            setDoneR2(false);
                            setDoneR3(false);
                            setDoneR4(false);
                            setDoneR5(false);
                            setIndexFingerprint("");
                            setFilesMacro([]);
                            setPreviewL1("");
                            setPreviewL2("");
                            setPreviewL3("");
                            setPreviewL4("");
                            setPreviewL5("");
                            setPreviewR1("");
                            setPreviewR2("");
                            setPreviewR3("");
                            setPreviewR4("");
                            setPreviewR5("");
                            setKata("");
                        }}
                    > Back </button>

                    <p style={{margin: '20px 0px', fontSize: '24px', fontWeight: '500', textAlign: 'center'}}>{nama}</p>

                    <Row>
                    <Col xxl={4} xl={4} sm={6} className="element-item project designing development" >
                        <Card className="gallery-box" onClick={() => {handleModalImage(doneL1 === false ? l1 : previewL1); setIndexFingerprint("L1")}} style={{cursor: "pointer"}}>
                            <div className='text-center'>
                                <h3>
                                    L1
                                </h3>
                            </div>

                            <div className="gallery-container" style={{display: 'flex'}}>
                                <img className="gallery-img img-fluid mx-auto" style={{maxWidth: '300px', maxHeight: '220px'}} src={doneL1 === false ? l1 : previewL1} alt="" />
                                <div className="gallery-overlay">
                                    <h5 className="overlay-caption">Jari Jempol Kiri</h5>
                                </div>
                            </div>

                            <div className="box-content">
                                <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
                                    <img src={KiJempol} alt="" style={{width: '100px', border: '1px solid white', borderRadius: '10px'}}/>
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col xxl={4} xl={4} sm={6} className="element-item project designing development" >
                        <Card className="gallery-box" onClick={() => {handleModalImage(doneL2 === false ? l2 : previewL2); setIndexFingerprint("L2")}} style={{cursor: "pointer"}}>
                            <div className='text-center'>
                                <h3>
                                    L2
                                </h3>
                            </div>

                            <div className="gallery-container" style={{display: 'flex'}}>
                                <img className="gallery-img img-fluid mx-auto" style={{maxWidth: '300px', maxHeight: '220px'}} src={doneL2 === false ? l2 : previewL2} alt="" />
                                <div className="gallery-overlay">
                                    <h5 className="overlay-caption">Jari Telunjuk Kiri</h5>
                                </div>
                            </div>

                            <div className="box-content">
                                <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
                                    <img src={KiTelunjuk} alt="" style={{width: '100px', border: '1px solid white', borderRadius: '10px'}}/>
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col xxl={4} xl={4} sm={6} className="element-item project designing development" >
                        <Card className="gallery-box" onClick={() => {handleModalImage(doneL3 === false ? l3 : previewL3); setIndexFingerprint("L3")}} style={{cursor: "pointer"}}>
                            <div className='text-center'>
                                <h3>
                                    L3
                                </h3>
                            </div>

                            <div className="gallery-container" style={{display: 'flex'}}>
                                <img className="gallery-img img-fluid mx-auto" style={{maxWidth: '300px', maxHeight: '220px'}} src={doneL3 === false ? l3 : previewL3} alt="" />
                                <div className="gallery-overlay">
                                    <h5 className="overlay-caption">Jari Tengah Kiri</h5>
                                </div>
                            </div>

                            <div className="box-content">
                                <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
                                    <img src={KiTengah} alt="" style={{width: '100px', border: '1px solid white', borderRadius: '10px'}}/>
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col xxl={4} xl={4} sm={6} className="element-item project designing development" >
                        <Card className="gallery-box" onClick={() => {handleModalImage(doneL4 === false ? l4 : previewL4); setIndexFingerprint("L4")}} style={{cursor: "pointer"}}>
                            <div className='text-center'>
                                <h3>
                                    L4
                                </h3>
                            </div>

                            <div className="gallery-container"style={{display: 'flex'}}>
                                <img className="gallery-img img-fluid mx-auto" style={{maxWidth: '300px', maxHeight: '220px'}} src={doneL4 === false ? l4 : previewL4} alt="" />
                                <div className="gallery-overlay">
                                    <h5 className="overlay-caption">Jari Manis Kiri</h5>
                                </div>
                            </div>

                            <div className="box-content">
                                <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
                                    <img src={KiManis} alt="" style={{width: '100px', border: '1px solid white', borderRadius: '10px'}}/>
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col xxl={4} xl={4} sm={6} className="element-item project designing development" >
                        <Card className="gallery-box" onClick={() => {handleModalImage(doneL5 === false ? l5 : previewL5); setIndexFingerprint("L5")}} style={{cursor: "pointer"}}>
                            <div className='text-center'>
                                <h3>
                                    L5
                                </h3>
                            </div>

                            <div className="gallery-container" style={{display: 'flex'}}>
                                <img className="gallery-img img-fluid mx-auto" style={{maxWidth: '300px', maxHeight: '220px'}} src={doneL5 === false ? l5 : previewL5} alt="" />
                                <div className="gallery-overlay">
                                    <h5 className="overlay-caption">Jari Kelingking Kiri</h5>
                                </div>
                            </div>

                            <div className="box-content">
                                <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
                                    <img src={KiKelingking} alt="" style={{width: '100px', border: '1px solid white', borderRadius: '10px'}}/>
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <hr/>

                    <Col xxl={4} xl={4} sm={6} className="element-item project designing development" >
                        <Card className="gallery-box" onClick={() => {handleModalImage(doneR1 === false ? r1 : previewR1); setIndexFingerprint("R1")}} style={{cursor: "pointer"}}>
                            <div className='text-center'>
                                <h3>
                                    R1
                                </h3>
                            </div>

                            <div className="gallery-container" style={{display: 'flex'}}>
                                <img className="gallery-img img-fluid mx-auto" style={{maxWidth: '300px', maxHeight: '220px'}} src={doneR1 === false ? r1 : previewR1} alt="" />
                                <div className="gallery-overlay">
                                    <h5 className="overlay-caption">Jari Jempol Kanan</h5>
                                </div>
                            </div>

                            <div className="box-content">
                                <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
                                    <img src={KaJempol} alt="" style={{width: '100px', border: '1px solid white', borderRadius: '10px'}}/>
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col xxl={4} xl={4} sm={6} className="element-item project designing development" >
                        <Card className="gallery-box" onClick={() => {handleModalImage(doneR2 === false ? r2 : previewR2); setIndexFingerprint("R2")}} style={{cursor: "pointer"}}>
                            <div className='text-center'>
                                <h3>
                                    R2
                                </h3>
                            </div>

                            <div className="gallery-container" style={{display: 'flex'}}>
                                <img className="gallery-img img-fluid mx-auto" style={{maxWidth: '300px', maxHeight: '220px'}} src={doneR2 === false ? r2 : previewR2} alt="" />
                                <div className="gallery-overlay">
                                    <h5 className="overlay-caption">Jari Telunjuk Kanan</h5>
                                </div>
                            </div>

                            <div className="box-content">
                                <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
                                    <img src={KaTelunjuk} alt="" style={{width: '100px', border: '1px solid white', borderRadius: '10px'}}/>
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col xxl={4} xl={4} sm={6} className="element-item project designing development" >
                        <Card className="gallery-box" onClick={() => {handleModalImage(doneR3 === false ? r3 : previewR3); setIndexFingerprint("R3")}} style={{cursor: "pointer"}}>
                            <div className='text-center'>
                                <h3>
                                    R3
                                </h3>
                            </div>

                            <div className="gallery-container" style={{display: 'flex'}}>
                                <img className="gallery-img img-fluid mx-auto" style={{maxWidth: '300px', maxHeight: '220px'}} src={doneR3 === false ? r3 : previewR3} alt="" />
                                <div className="gallery-overlay">
                                    <h5 className="overlay-caption">Jari Tengah Kanan</h5>
                                </div>
                            </div>

                            <div className="box-content">
                                <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
                                    <img src={KaTengah} alt="" style={{width: '100px', border: '1px solid white', borderRadius: '10px'}}/>
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col xxl={4} xl={4} sm={6} className="element-item project designing development" >
                        <Card className="gallery-box" onClick={() => {handleModalImage(doneR4 === false ? r4 : previewR4); setIndexFingerprint("R4")}} style={{cursor: "pointer"}}>
                            <div className='text-center'>
                                <h3>
                                    R4
                                </h3>
                            </div>

                            <div className="gallery-container" style={{display: 'flex'}}>
                                <img className="gallery-img img-fluid mx-auto" style={{maxWidth: '300px', maxHeight: '220px'}} src={doneR4 === false ? r4 : previewR4} alt="" />
                                <div className="gallery-overlay">
                                    <h5 className="overlay-caption">Jari Manis Kanan</h5>
                                </div>
                            </div>

                            <div className="box-content">
                                <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
                                    <img src={KaManis} alt="" style={{width: '100px', border: '1px solid white', borderRadius: '10px'}}/>
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col xxl={4} xl={4} sm={6} className="element-item project designing development" >
                        <Card className="gallery-box" onClick={() => {handleModalImage(doneR5 === false ? r5 : previewR5); setIndexFingerprint("R5")}} style={{cursor: "pointer"}}>
                            <div className='text-center'>
                                <h3>
                                    R5
                                </h3>
                            </div>

                            <div className="gallery-container" style={{display: 'flex'}}>
                                <img className="gallery-img img-fluid mx-auto" style={{maxWidth: '300px', maxHeight: '220px'}} src={doneR5 === false ? r5 : previewR5} alt="" />
                                <div className="gallery-overlay">
                                    <h5 className="overlay-caption">Jari Kelingking Kanan</h5>
                                </div>
                            </div>

                            <div className="box-content">
                                <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
                                    <img src={KaKelingking} alt="" style={{width: '100px', border: '1px solid white', borderRadius: '10px'}}/>
                                </div>
                            </div>
                        </Card>
                    </Col>

                    </Row>

                <div className="text-center my-2">    
                    <Button color="dark" className="rounded-pill" onClick={() => {updateFingerprint()}}> Submit </Button>
                </div>
                </>
                )}
            </Row>

            <Modal isOpen={modalAccessDenied} toggle={() => {}} centered>
                <ModalBody className='modalCenter'> 
                    <img src={accessDenied} style={{width: '30%'}}/>
                    <p style={{fontSize: '24px', fontWeight: '700', marginTop: '20px'}}>Access Denied</p>
                    <p style={{margin: '0px', color: 'grey'}}>You currently does not have access to this page. <br/> Please Login Again</p>
                </ModalBody>
                <ModalFooter style={{display: 'flex', justifyContent: 'center', gap: '80px'}}>
                    <button className='btn buttonVersFutureLink' style={{fontSize: '14px', fontWeight: '500'}} onClick={() => {logOut()}}>Login Again</button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalNoData} toggle={() => {setModalNoData(!modalNoData)}} centered>
                <ModalBody className='modalCenter'> 
                    <img src={accessDenied} style={{width: '30%'}}/>
                    <p style={{fontSize: '24px', fontWeight: '700', marginTop: '20px'}}>Nothing Data</p>
                </ModalBody>
            </Modal>

            <Modal isOpen={modalAdd} toggle={() => { setModalAdd(!modalAdd) }} centered >
                <div className="modal-header bg-light p-3">
                    <h5 className="modal-title" id="exampleModalLabel"> Add User </h5>
                    <Button type="button" onClick={() => { setModalAdd(!modalAdd) }} className="btn-close" aria-label="Close" >
                    </Button>
                </div>
                    <ModalBody>
                        <Alert className={'alertRed ' + fade} hidden={alert}>Check Again</Alert>
                        <div className="mb-3">
                            <label htmlFor="gender-field" className="form-label">Instansi <span style={{color: 'red'}}>*</span></label>
                            <select className="form-control" data-trigger name="Gender-field" id="Gender-field" value={instansi} onChange={(event) => {setInstansi(event.target.value)}}>
                                <option value="" selected="selected" hidden="hidden">
                                    Choose here
                                </option>
                                {getInstansi.map((item, index) => (
                                    <>
                                        <option key={index} value={item.agency_id}>{item.name}</option>
                                    </>
                                ))}
                            </select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="firstname-field" className="form-label">First Name <span style={{color: 'red'}}>*</span></label>
                            <input value={firstName} onChange={(event) =>  {setFirstName(event.target.value)}} type="text" id="firstname-field" className="form-control" placeholder="Enter Name"   />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="lastname-field" className="form-label">Surname <span style={{color: 'red'}}>*</span></label>
                            <input value={surName} onChange={(event) =>  {setSurName(event.target.value)}} type="text" id="lastname-field" className="form-control" placeholder="Enter Name"   />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="email-field" className="form-label">Email </label>
                            <input value={email} onChange={(event) =>  {setEmail(event.target.value)}} type="email" id="email-field" className="form-control" placeholder="Enter Email"   />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="hp-field" className="form-label">Phone</label>
                            <input value={phone} onChange={(event) =>  {setPhone(event.target.value)}} type="number" id="hp-field" className="form-control" placeholder="Enter Number Phone"   />
                        </div>

                        <div className="mb-3" style={{display: 'flex', gap: '20px'}}>
                            <div style={{width: "-webkit-fill-available"}}>
                                <label htmlFor="kode" className="form-label">
                                Unique Code <span style={{color: 'red'}}>*</span>
                                </label>
                                <input type="text" value={uniqueCode} onChange={changeKode} className="form-control" id="kode" disabled />
                            </div>

                            <Button onClick={generate} style={{ backgroundColor: "#ffffff", borderColor: "#ced4da", marginTop: '21px' }}>
                                <img src={Refresh} />
                            </Button>
                        </div>

                        <div className='mb-3' style={{display: 'flex', justifyContent: 'center'}}>
                            <div style={{ height: "auto", margin: "0 auto", maxWidth: '128px', width: "100%" }}>
                                <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value={uniqueCode}
                                viewBox={`0 0 256 256`}
                                />
                            </div>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="gender-field" className="form-label">Gender <span style={{color: 'red'}}>*</span></label>
                            <select className="form-control" data-trigger name="Gender-field" id="Gender-field" value={gender} onChange={(event) => {setGender(event.target.value)}}>
                                <option value="" selected="selected" hidden="hidden">Choose Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="date-field" className="form-label">Date Birthday </label>
                            <Flatpickr
                                className="form-control"
                                options={{
                                    dateFormat: "Y-m-d",
                                }}
                                placeholder="Select Date"
                                onChange={(event) =>  {setDateBirthday(event[0])}}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button type="button" className="btn buttonVersFutureLinkBorderGrey" onClick={() => {setModalAdd(false)}}>Close</button>
                            <button className="btn buttonVersFutureLinkGreen" onClick={() => {addClient()}} disabled={waiting}>Add User</button>
                        </div>
                    </ModalFooter>
            </Modal>

            <Modal isOpen={modalUpdate} toggle={() => { setModalUpdate(!modalUpdate) }} centered >
                <div className="modal-header bg-light p-3">
                    <h5 className="modal-title" id="exampleModalLabel"> Update User </h5>
                    <Button type="button" onClick={() => { setModalUpdate(!modalUpdate) }} className="btn-close" aria-label="Close" >
                    </Button>
                </div>

                    <ModalBody>
                        <Alert className={'alertRed ' + fade} hidden={alert}>Check Again</Alert>

                        <div className="mb-3">
                            <label htmlFor="firstname-field" className="form-label">First Name</label>
                            <input value={firstName} onChange={(event) =>  {setFirstName(event.target.value)}} type="text" id="firstname-field" className="form-control" placeholder="Enter Name"   />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="lastname-field" className="form-label">Surname</label>
                            <input value={surName} onChange={(event) =>  {setSurName(event.target.value)}} type="text" id="lastname-field" className="form-control" placeholder="Enter Name"   />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="email-field" className="form-label">Email</label>
                            <input value={email} onChange={(event) =>  {setEmail(event.target.value)}} type="email" id="email-field" className="form-control" placeholder="Enter Email"   />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="hp-field" className="form-label">Nomor HP</label>
                            <input value={phone} onChange={(event) =>  {setPhone(event.target.value)}} type="number" id="hp-field" className="form-control" placeholder="Enter Number Phone"   />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="gender-field" className="form-label">Gender</label>
                            <select className="form-control" data-trigger name="Gender-field" id="Gender-field" value={gender} onChange={(event) => {setGender(event.target.value)}} >
                                <option value="">Choose Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="date-field" className="form-label">Date Birthday</label>
                            <Flatpickr
                            className="form-control"
                            options={{
                                dateFormat: "Y-m-d",
                            }}
                            value={dateBirthday}
                            onChange={(event) => setDateBirthday(event[0])}
                            placeholder="Select Date"
                            />
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button className="btn buttonVersFutureLinkBorderGrey" onClick={() => setModalUpdate(false)}>Close</button>
                            <button className="btn buttonVersFutureLinkGreen" onClick={() => {updateClient()}}>Update User</button>
                        </div>
                    </ModalFooter>
            </Modal>

            <Modal isOpen={modalKirimCode} toggle={() => {setModalKirimCode(!modalKirimCode)}} centered >
                <ModalHeader>
                    <h5 className="modal-title" id="exampleModalToggleLabel">
                        Re-send Unique Code
                    </h5>
                </ModalHeader>
                <ModalBody className="text-center p-5">

                    {uniqueCode === "" ? (
                        <>
                        <p>You don't need to refresh the Unique Code.</p>
                        <button className="btn buttonVersFutureLinkGreen" onClick={() => {setModalKirimCode(false)}}>
                            Okey
                        </button>
                        </>
                    ) : (
                        <>

                        <div className="mb-3" style={{display: 'flex', gap: '20px'}}>
                            <div style={{width: "-webkit-fill-available"}}>
                                <label htmlFor="kode" className="form-label">
                                    Unique Code
                                </label>
                                <input type="text" value={uniqueCode} onChange={changeKode} className="form-control" id="kode" disabled style={{padding: '1.5rem 1rem'}} />
                            </div>

                            <Button onClick={generate} style={{ backgroundColor: "#ffffff", borderColor: "#ced4da", marginTop: '21px' }}>
                                <img src={Refresh} />
                            </Button>
                        </div>

                        <div className='mb-3' style={{display: 'flex', justifyContent: 'center'}}>
                            <div style={{ height: "auto", margin: "0 auto", maxWidth: '128px', width: "100%" }}>
                                <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={uniqueCode}
                                    viewBox={`0 0 256 256`}
                                />
                            </div>
                        </div>

                        <div className="mt-4 pt-4">
                            <h4>Refresh Unique Code</h4>
                            <p className="text-muted"> Are you sure Reset Unique Code ?</p>
                            <div className='d-flex justify-content-center gap-4 mt-2'>
                                <button className="btn buttonVersFutureLinkBorderGrey" onClick={() => {setModalKirimCode(false) }}>
                                    Close
                                </button>
                                <button className="btn buttonVersFutureLinkGreen" onClick={() => {updateUniqueCode()}}>
                                    Submit
                                </button>
                            </div>
                        </div>
                        
                        </>
                    )}



                </ModalBody>
            </Modal>

            <Modal isOpen={modalFingerprint} toggle={() => {setModalFingerprint(!modalFingerprint)}} centered >
                <div style={{marginTop: '30px', marginBottom: '30px'}}>
                    <Lottie options={animationOptions} height={250} width={250} isClickToPauseDisabled={true} />
                </div>
                <div style={{marginBottom: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <p style={{textAlign: 'center', fontSize: '18px', fontWeight: '500', color: '#374151'}}>Please Capture Fingerprint on Mobile Apps <br/>Future Link</p>
                </div>
            </Modal>

            <Modal className='modal-xl' isOpen={modalImage} toggle={() => {handleModalImage()}} centered >
                <ModalHeader toggle={() => {handleModalImage();}}>
                </ModalHeader>
                <ModalBody className="text-center">
                    <img className="overlay" src={imageFingerprint} style={{width: '100%'}} />

                    <div className="text-center my-2">    
                        <Button color="dark" className="rounded-pill" onClick={() => setModalUpload(true)}> Upload Image </Button>
                    </div>
                </ModalBody>
            </Modal>

            <Modal className="modal-xl" isOpen={modalUpload} toggle={() => {setModalUpload(!modalUpload)}} centered>
                <ModalHeader>
                    <h5 className="modal-title" id="exampleModalToggleLabel">
                        Upload File Image
                    </h5>
                </ModalHeader>
                <ModalBody className="text-center p-5">
                    <Row>
                        <Card>
                            <CardBody>
                                <Nav pills className="nav nav-pills arrow-navtabs nav-success bg-light mb-3 justify-content-lg-evenly">
                                    <NavItem>
                                        <NavLink style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: arrowNavTab === "1",
                                            })}
                                            onClick={() => {
                                                arrowNavToggle("1");
                                            }}
                                        >
                                        Upload via Macro
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: arrowNavTab === "2",
                                            })}
                                            onClick={() => {
                                                arrowNavToggle("2");
                                            }}
                                        >
                                            Upload via Scanner
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={arrowNavTab} className="text-muted">
                                    <TabPane tabId="1" id="arrow-overview">
                                        <Card>
                                            <CardHeader>
                                                <h4 className="card-title mb-0">MACRO</h4>
                                            </CardHeader>

                                            <CardBody>
                                                {indexFingerprint === "L1" && (
                                                <>
                                                    <FilePond
                                                    onupdatefiles={(event) => {setPreviewL1(event[0].file); setFilesMacro(event[0].file) }}
                                                    allowMultiple={false}
                                                    maxFiles={1}
                                                    name="files"
                                                    className="filepond filepond-input-multiple"
                                                    />
                                                </>
                                                )}
                                                {indexFingerprint === "L2" && (
                                                <>
                                                    <FilePond
                                                    
                                                    onupdatefiles={(event) => {setPreviewL2(event[0].file); setFilesMacro(event[0].file) }}
                                                    allowMultiple={false}
                                                    maxFiles={1}
                                                    name="files"
                                                    className="filepond filepond-input-multiple"
                                                    />
                                                </>
                                                )}
                                                {indexFingerprint === "L3" && (
                                                <>
                                                    <FilePond
                                                    
                                                    onupdatefiles={(event) => {setPreviewL3(event[0].file); setFilesMacro(event[0].file) }}
                                                    allowMultiple={false}
                                                    maxFiles={1}
                                                    name="files"
                                                    className="filepond filepond-input-multiple"
                                                    />
                                                </>
                                                )}
                                                {indexFingerprint === "L4" && (
                                                <>
                                                    <FilePond
                                                    
                                                    onupdatefiles={(event) => {setPreviewL4(event[0].file); setFilesMacro(event[0].file) }}
                                                    allowMultiple={false}
                                                    maxFiles={1}
                                                    name="files"
                                                    className="filepond filepond-input-multiple"
                                                    />
                                                </>
                                                )}
                                                {indexFingerprint === "L5" && (
                                                <>
                                                    <FilePond
                                                    
                                                    onupdatefiles={(event) => {setPreviewL5(event[0].file); setFilesMacro(event[0].file) }}
                                                    allowMultiple={false}
                                                    maxFiles={1}
                                                    name="files"
                                                    className="filepond filepond-input-multiple"
                                                    />
                                                </>
                                                )}
                                                {indexFingerprint === "R1" && (
                                                <>
                                                    <FilePond
                                                    
                                                    onupdatefiles={(event) => {setPreviewR1(event[0].file); setFilesMacro(event[0].file) }}
                                                    allowMultiple={false}
                                                    maxFiles={1}
                                                    name="files"
                                                    className="filepond filepond-input-multiple"
                                                    />
                                                </>
                                                )}
                                                {indexFingerprint === "R2" && (
                                                <>
                                                    <FilePond
                                                    
                                                    onupdatefiles={(event) => {setPreviewR2(event[0].file); setFilesMacro(event[0].file) }}
                                                    allowMultiple={false}
                                                    maxFiles={1}
                                                    name="files"
                                                    className="filepond filepond-input-multiple"
                                                    />
                                                </>
                                                )}
                                                {indexFingerprint === "R3" && (
                                                <>
                                                    <FilePond
                                                    
                                                    onupdatefiles={(event) => {setPreviewR3(event[0].file); setFilesMacro(event[0].file) }}
                                                    allowMultiple={false}
                                                    maxFiles={1}
                                                    name="files"
                                                    className="filepond filepond-input-multiple"
                                                    />
                                                </>
                                                )}
                                                {indexFingerprint === "R4" && (
                                                <>
                                                    <FilePond
                                                    
                                                    onupdatefiles={(event) => {setPreviewR4(event[0].file); setFilesMacro(event[0].file) }}
                                                    allowMultiple={false}
                                                    maxFiles={1}
                                                    name="files"
                                                    className="filepond filepond-input-multiple"
                                                    />
                                                </>
                                                )}
                                                {indexFingerprint === "R5" && (
                                                <>
                                                    <FilePond
                                                    
                                                    onupdatefiles={(event) => {setPreviewR5(event[0].file); setFilesMacro(event[0].file) }}
                                                    allowMultiple={false}
                                                    maxFiles={1}
                                                    name="files"
                                                    className="filepond filepond-input-multiple"
                                                    />
                                                </>
                                                )}
                                                

                                            </CardBody>
                                            <CardFooter>
                                                <Button color="buttonVersFutureLinkGreen" onClick={() => {handleUploadFileMacro()}}> Save Image </Button>
                                            </CardFooter>
                                        </Card>
                                    </TabPane>
                                    
                                    <TabPane tabId="2" id="arrow-profile">
                                        <Card>
                                            <CardHeader>
                                                <h4 className="card-title mb-0">SCANNER FP</h4>
                                            </CardHeader>

                                            <CardBody>
                                            {indexFingerprint === "L1" && (
                                                <>
                                                    <FilePond
                                                    
                                                    onupdatefiles={(event) => {setPreviewL1(event[0].file)}}
                                                    allowMultiple={false}
                                                    maxFiles={1}
                                                    name="files"
                                                    className="filepond filepond-input-multiple"
                                                    />
                                                </>
                                                )}
                                                {indexFingerprint === "L2" && (
                                                <>
                                                    <FilePond
                                                    
                                                    onupdatefiles={(event) => {setPreviewL2(event[0].file)}}
                                                    allowMultiple={false}
                                                    maxFiles={1}
                                                    name="files"
                                                    className="filepond filepond-input-multiple"
                                                    />
                                                </>
                                                )}
                                                {indexFingerprint === "L3" && (
                                                <>
                                                    <FilePond
                                                    
                                                    onupdatefiles={(event) => {setPreviewL3(event[0].file)}}
                                                    allowMultiple={false}
                                                    maxFiles={1}
                                                    name="files"
                                                    className="filepond filepond-input-multiple"
                                                    />
                                                </>
                                                )}
                                                {indexFingerprint === "L4" && (
                                                <>
                                                    <FilePond
                                                    
                                                    onupdatefiles={(event) => {setPreviewL4(event[0].file) }}
                                                    allowMultiple={false}
                                                    maxFiles={1}
                                                    name="files"
                                                    className="filepond filepond-input-multiple"
                                                    />
                                                </>
                                                )}
                                                {indexFingerprint === "L5" && (
                                                <>
                                                    <FilePond
                                                    
                                                    onupdatefiles={(event) => {setPreviewL5(event[0].file)}}
                                                    allowMultiple={false}
                                                    maxFiles={1}
                                                    name="files"
                                                    className="filepond filepond-input-multiple"
                                                    />
                                                </>
                                                )}
                                                {indexFingerprint === "R1" && (
                                                <>
                                                    <FilePond
                                                    
                                                    onupdatefiles={(event) => {setPreviewR1(event[0].file)}}
                                                    allowMultiple={false}
                                                    maxFiles={1}
                                                    name="files"
                                                    className="filepond filepond-input-multiple"
                                                    />
                                                </>
                                                )}
                                                {indexFingerprint === "R2" && (
                                                <>
                                                    <FilePond
                                                    
                                                    onupdatefiles={(event) => {setPreviewR2(event[0].file)}}
                                                    allowMultiple={false}
                                                    maxFiles={1}
                                                    name="files"
                                                    className="filepond filepond-input-multiple"
                                                    />
                                                </>
                                                )}
                                                {indexFingerprint === "R3" && (
                                                <>
                                                    <FilePond
                                                    
                                                    onupdatefiles={(event) => {setPreviewR3(event[0].file)}}
                                                    allowMultiple={false}
                                                    maxFiles={1}
                                                    name="files"
                                                    className="filepond filepond-input-multiple"
                                                    />
                                                </>
                                                )}
                                                {indexFingerprint === "R4" && (
                                                <>
                                                    <FilePond
                                                    
                                                    onupdatefiles={(event) => {setPreviewR4(event[0].file)}}
                                                    allowMultiple={false}
                                                    maxFiles={1}
                                                    name="files"
                                                    className="filepond filepond-input-multiple"
                                                    />
                                                </>
                                                )}
                                                {indexFingerprint === "R5" && (
                                                <>
                                                    <FilePond
                                                    
                                                    onupdatefiles={(event) => {setPreviewR5(event[0].file)}}
                                                    allowMultiple={false}
                                                    maxFiles={1}
                                                    name="files"
                                                    className="filepond filepond-input-multiple"
                                                    />
                                                </>
                                                )}
                                                <canvas ref={canvasRef} width={500} height={550}/>
                                            </CardBody>
                                            <CardFooter>
                                                <Button color="primary" className="rounded-pill" onClick={() => {handleUploadFileScanner()}}> Save Image </Button>
                                            </CardFooter>
                                        </Card>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Row>
                </ModalBody>
            </Modal>

            <Modal isOpen={modalAlertSuccess} toggle={() => {setModalAlertSuccess(!modalAlertSuccess)}} centered>
                <ModalBody className='modalCenter' style={{margin: '0px'}}>
                    <Lottie options={animationSuccess} height={350} width={350} isClickToPauseDisabled={true} />
                </ModalBody>
            </Modal>

            <Modal isOpen={modalAlertDelete} toggle={() => {setModalAlertDelete(!modalAlertDelete)}} centered>
                <ModalBody className='modalCenter' style={{margin: '0px'}}>
                    <Lottie options={animationDelete} height={350} width={350} isClickToPauseDisabled={true} />
                </ModalBody>
            </Modal>

            </div>
        </React.Fragment>
    );
};

export default TableUserDefaultFpTaker;
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import whorl from "../../../../../assets/images/FutureLink/whorl.png";
import loop from "../../../../../assets/images/FutureLink/loop.png";
import arch from "../../../../../assets/images/FutureLink/arch.png";
import hand from "../../../../../assets/images/FutureLink/hand.png";
import Badge from "react-bootstrap/Badge";

const Summary = (haisayang) => {
  const data = haisayang.history.location.state.data;
  // console.log(haisayang.history.location.state.data)

  const packages = haisayang.history.location.state.package_id;
  // console.log(haisayang.history.location.state.package_id)

  const token = haisayang.history.location.state.params;

  const [titleTFRC, setTitleTFRC] = useState("");
  const [colorTFRC, setColorTFRC] = useState("");
  const [textTFRC, setTextTFRC] = useState("");
  const [dominant, setDominant] = useState("");
  const [dominantImage, setDominantImage] = useState();
  const [dominantText, setDominantText] = useState([]);

  useEffect(() => {
    let dominant = getDominant();
    setDominant(dominant);
    if (dominant == "Whorl") {
      setDominantImage(whorl);
      setDominantText([
        "Emosi: tidak stabil, meledak-ledak, sensitif",
        "Karakter: dominan, suka memimpin, suka menjadi pusat perhatian",
        "Kebiasaan: tidak bisa diam, mudah bosan.",
        "Kelebihan: bisa menjadi pemimpin, berpendirian teguh, sosok yang kuat, sangat bertanggung jawab, mandiri, gesit ketika bertindak, serta dapat menganalisa situasi dengan cermat.",
        "Kekurangan: pemikir, terobsesi pada kekuasaan, sukar menyesuaikan diri, memiliki egosentris yang tinggi, kaku, dan memiliki kontrol emosi yang buruk.",
      ]);
    } else if (dominant == "Loop") {
      setDominantImage(loop);
      setDominantText([
        "Emosi: ramah, optimis, terbuka",
        "Karakter: simpatinya tinggi, tulus, optimis, kurang inisiatif",
        "Kebiasaan: rajin dan bertanggung jawab, pekerja keras.",
        "Kelebihan: memiliki empati tinggi, senang membantu orang lain, tulus, rajin dan bertanggung jawab, terbuka, ramah, murah senyum, optimis, dan pekerja keras.",
        "Kekurangan: kurang memiliki inisiatif, tidak menyukai aturan yang keras, tidak bisa berdiam diri, dan bergantung pada orang lain.",
      ]);
    } else {
      setDominantImage(arch);
      setDominantText([
        "Emosi: sabar, sulit mengekspresikan perasaan",
        "Karakter: sederhana, praktis, sulit beradaptasi",
        "Kebiasaan: impulsive, selalu bersemangat.",
        "Kelebihan: praktis, berkepala dingin, bersahaja, selalu bersemangat, memiliki kepercayaan diri yang tinggi, tegas, dan tidak mudah menyerah.",
        "Kekurangan: berkepala dingin, sukar mengutarakan perasaan, impulsive, kurang mampu menerima pendapat orang lain, dan sulit beradaptasi.",
      ]);
    }

    if (data.tfrc >= 238) {
      setTitleTFRC("Sangat Tinggi");
      setColorTFRC("#007F5F");
      setTextTFRC(
        "Memiliki kemampuan untuk mendobrak batasan dari keterbatasan diri sendiri."
      );
    } else if (data.tfrc >= 205) {
      setTitleTFRC("Tinggi");
      setColorTFRC("#2B9348");
      setTextTFRC(
        "Memiliki kemampuan tersembunyi yang berpotensi menjadi suatu kekuatan besar."
      );
    } else if (data.tfrc >= 172) {
      setTitleTFRC("Diatas Rata-Rata");
      setColorTFRC("#55A630");
      setTextTFRC(
        "Memiliki kemampuan untuk memimpin orang lain untuk mencapai harapannya."
      );
    } else if (data.tfrc >= 137) {
      setTitleTFRC("Rata-Rata");
      setColorTFRC("#80B918");
      setTextTFRC("Memiliki kemampuan untuk memotivasi orang lain.");
    } else if (data.tfrc >= 102) {
      setTitleTFRC("Dibawah Rata-Rata");
      setColorTFRC("#AACC00");
      setTextTFRC(
        "Memiliki wawasan mengenai diri sendiri dan memiliki ekspektasi yang realistis."
      );
    } else if (data.tfrc >= 70) {
      setTitleTFRC("Cukup Rendah");
      setColorTFRC("#BFD200");
      setTextTFRC("Membutuhkan bimbingan untuk kinerja yang optimal.");
    } else {
      setTitleTFRC("Rendah");
      setColorTFRC("#D4D700");
      setTextTFRC(
        "Membutuhkan bantuan dan penanganan khusus untuk kinerja yang lebih baik."
      );
    }
  }, []);

  const getDominant = () => {
    var cognitive = parseInt(data.cognitive);
    var affective = parseInt(data.affective);
    var reverse = parseInt(data.reverse);
    var reflective = parseInt(data.reflective);
    if (
      (cognitive > reflective && cognitive > affective) ||
      cognitive == reflective
    ) {
      return "Whorl";
    } else if (
      (reflective > cognitive && reflective > affective) ||
      reflective == affective
    ) {
      return "Loop";
    } else {
      return "Arch";
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Link
          style={{
            width: "max-content",
            display: "flex",
            alginContent: "start",
          }}
          className="btn buttonVersFutureLinkBorderGrey"
          to={`/report-mobile/${token}`}
        >
          Back
        </Link>
        <div className="polasidikjariList" style={{ alignItems: "center" }}>
          <h4 style={{ display: "flex", justifyContent: "center" }}>
            Kemampuan Umum
          </h4>
          <p>
            Having a dominant loop fingerprint pattern indicates a person with a
            friendly character, excelling in both intrapersonal and
            interpersonal skills. With a left-brain dominance, your thought
            process leans towards action, despite a low IQ and average EQ
            scores. Your auditory learning style and introspective intelligence
            make the arts field a suitable fit, although fine motor skills
            present a challenge.
          </p>
          <div className="grid-container-3">
            <img src={dominantImage} width={100} style={{ margin: "auto" }} />
            <div>
              <h4>{dominant}</h4>
              <ul
                style={{
                  fontSize: "16px",
                }}
              >
                {dominantText.map((row) => {
                  return <><li>{row}</li></>;
                })}
              </ul>
            </div>
            <img
              className="hand-pattern"
              src={hand}
              style={{ margin: "auto" }}
            />
          </div>
          <div className="card-tfrc" style={{ borderColor: colorTFRC }}>
            <div
              style={{
                backgroundColor: colorTFRC,
                borderRadius: 5,
                padding: 5,
              }}
            >
              <p style={{ color: "white", margin: "auto" }}>{titleTFRC}</p>
            </div>
            <p>Total Finger Ridge Count (TFRC)</p>
            <h1 style={{ color: colorTFRC }}>{data.tfrc}</h1>
            <p style={{ textAlign: "center" }}>{textTFRC}</p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "20px",
          }}
        >
          <Link
            className="bottomButton buttonVersFutureLink"
            to={{
              pathname: "/report-mobiles/summary/2",
              state: { data: data, package_id: packages, params: token },
            }}
          >
            Selanjutnya {">"}
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Summary;

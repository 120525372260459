import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import Lottie from 'react-lottie';
import accessDenied from '../../../../../assets/images/FutureLink/accessDenied.json';

import kecerdasan from '../../../../../assets/images/FutureLink/kecerdasan.png';
import kecerdasan1 from '../../../../../assets/images/FutureLink/kecerdasan1.png';
import kecerdasan2 from '../../../../../assets/images/FutureLink/kecerdasan2.png';
import kecerdasan3 from '../../../../../assets/images/FutureLink/kecerdasan3.png';
import kecerdasan4 from '../../../../../assets/images/FutureLink/kecerdasan4.png';

const Kecerdasan= (haisayang) => {
    const data = haisayang.history.location.state.data
    // console.log(haisayang.history.location.state.data)

    const packages = haisayang.history.location.state.package_id
    // console.log(haisayang.history.location.state.package_id)

    const token = haisayang.history.location.state.params

    return(
        <React.Fragment>
            <div className="page-content">
                {packages === 22 || packages === 33 ? (
                    <>
                <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                    <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 40px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Kecerdasan</p>
                </div>

                <div className='kecerdasan'>
                    <Link style={{marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to={`/report-mobile/${token}`}>Back</Link>

                    <div className='kecerdasanList'>
                        <div className='kecerdasanMain'>
                            <p style={{fontSize: '18px', fontWeight: '700'}}>Berbicara Mengenai Kecerdasan</p>
                            <p style={{fontSize: '16px', lineHeight: '28px', textAlign: 'justify', marginBottom: '30px'}}>Kecerdasan merupakan kemampuan seseorang untuk bisa memecahkan permasalahan, mampu beradaptasi dengan baik, dan mampu mengubah arah tindakan, serta mengkritik diri sendiri. Dalam perkembangannya kecerdasan terbagi menjadi beberapa bagian. 
                            Seorang psikolog asal Jerman bernama Goleman (1995)  membagi potensi pikiran atau kecerdasan manusia menjadi pikiran rasional (IQ) dan emosional (EQ).
                            Goeltz (2007) menambahkan teori kecerdasan manusia dalam beradaptasi (AQ) dan kecerdasan manusia dalam berkreasi (CQ).</p>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <img src={kecerdasan} className='imgMain'/>
                            </div>
                            
                        </div>
                        <div className='kecerdasanMobile'>
                            <div className='title'>
                                <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 50px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Intelligence Quotient (IQ)</p>
                            </div>
                            <p style={{fontSize: '16px', lineHeight: '28px', textAlign: 'justify'}}><span style={{fontWeight: '900'}}>Intelligence Quotient</span> atau IQ biasa disebut sebagai kecerdasan intelektual. IQ digunakan untuk menguraikan sifat pikiran manusia, mengukur kemampuan untuk menangkap, menyimpan, dan mengingat kembali mengenai pengetahuan faktual. Metode pengukuran kecerdasan ini ada sejak akhir abad ke-19, namun menurut psikolog terkenal IQ hanya menyumbang sekitar 20% faktor yang menentukan kesuksesan hidup.
                            <br/><br/>
                            Ciri-ciri orang yang nilai IQ-nya tinggi cenderung perfeksionis, memiliki keingintahuan yang tinggi, memiliki keterampilan pemecahan masalah, memiliki memori luar biasa, kemampuan logis dan analitisnya sangat baik.</p>
                            <div className='kecerdasanIQ'>
                                <div className='value'>
                                    <p className='p1'>IQ Anda</p>
                                    <p className='p2'>{Math.trunc(data.iq)}</p>
                                </div>
                                <img src={kecerdasan1} style={{width: '100%'}}/>
                            </div>
                            
                        </div>
                        <div className='kecerdasanMobile'>
                            <div className='title'>
                                <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 50px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Emotional Quotient (EQ)</p>
                            </div>
                            <p style={{fontSize: '16px', lineHeight: '28px', textAlign: 'justify'}}><span style={{fontWeight: '900'}}>Emotional Quotient</span> atau EQ biasa disebut sebagai kecerdasan emosi yang erat kaitannya dengan karakter seseorang. EQ digunakan untuk memahami perasaan orang lain, mengontrol emosi, kerja sama, komitmen, hingga disiplin, serta mengukur kemampuan untuk memahami dan mengelola orang dan tantangan secara matang. John D Mayer dan Peter Salovey menciptakan kerangka kerja untuk kecerdasan emosional pada tahun 1990-an.
                            <br/><br/>
                            Ciri-ciri orang yang nilai EQ-nya tinggi cenderung mengungkapkan perasaan mereka dengan jelas & langsung, mampu membaca komunikasi non-verbal, menyeimbangkan perasaan mereka dengan akal, logika, dan kenyataan, mandiri dan percaya pada diri sendiri, termotivasi secara intrinsik, dan tangguh secara emosional.</p>
                            <div className='kecerdasanEQ'>
                                
                                <div className='value'>
                                    <p className='p1'>EQ Anda</p>
                                    <p className='p2'>{Math.trunc(data.eq)}</p>
                                </div>

                                <img src={kecerdasan2} style={{width: '80%'}}/>
                            </div>
                            
                        </div>
                        <div className='kecerdasanMobile'>
                            <div className='title'>
                                <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 50px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Adversity Quotient (AQ)</p>
                            </div>
                            <p style={{fontSize: '16px', lineHeight: '28px', textAlign: 'justify'}}><span style={{fontWeight: '900'}}>Adversity Quotient</span> atau AQ mengukur kemampuan untuk beradaptasi dengan baik terhadap tekanan, kesulitan, trauma, atau tragedi. Kecerdasan ini memerlukan kestabilan dan pertahanan kesehatan fisik dan fungsi psikologis, bahkan dalam menghadapi kekacauan.
                            <br/><br/>
                            Ciri-ciri orang yang nilai AQ-nya tinggi cenderung tampil maksimal dalam menghadapi kesulitan - tantangan besar dan kecil yang menghadang setiap hari, memanfaatkan kemampuan alami untuk belajar dan berubah, serta meningkatkan vitalitas, dapat ditingkatkan secara dramatis, diperbaiki dan diperkuat secara permanen.</p>
                            <div className='kecerdasanAQ'>
                                
                                <div className='value'>
                                    <p className='p1'>AQ Anda</p>
                                    <p className='p2'>{Math.trunc(data.aq)}</p>
                                </div>

                                <img src={kecerdasan3} style={{width: '80%'}}/>
                            </div>
                            
                        </div>
                        <div className='kecerdasanMobile'>
                            <div className='title'>
                                <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 50px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Creative Quotient (CQ)</p>
                            </div>
                            <p style={{fontSize: '16px', lineHeight: '28px', textAlign: 'justify'}}><span style={{fontWeight: '900'}}>Creative Quotient</span> atau CQ mengukur kemampuan kreativitas dan mempertimbangkan ide serta kelancaran untuk menentukan tingkat kreativitas yang dimiliki seseorang. Kecerdasan ini dapat memunculkan ide, pemecahan masalah melalui cara yang berbeda dari yang sudah lazim, serta menguraikan sesuatu secara terperinci. 
                            <br/><br/>
                            Ciri-ciri orang yang memiliki CQ yang tinggi cenderung peka terhadap keindahan & tertarik pada nilai-nilai estetika, sering menampilkan keceriaan intelektual, suka berfantasi & berimajinasi, pemikir yang fasih (mampu menghasilkan kemungkinan, atau konsekuensi), pemikir yang fleksibel (mampu menggunakan banyak alternatif dan pendekatan berbeda untuk pemecahan masalah), pemikir orisinal (mencari kombinasi baru, tidak biasa, atau tidak konvensional).</p>
                            <div className='kecerdasanCQ'>
                                
                                <div className='value'>
                                    <p className='p1'>CQ Anda</p>
                                    <p className='p2'>{Math.trunc(data.cq)}</p>
                                </div>

                                <img src={kecerdasan4} style={{width: '80%'}}/>
                            </div>
                        </div>
                    </div>
                </div>
                

                <div style={{display: 'flex', justifyContent:'space-between', paddingTop: '32px'}}>
                    {packages === 22 && (
                        <>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report-mobiles/gaya-belajar", state: { data: data, package_id: packages, params: token }}} onClick={() => {document.body.scrollIntoView({behavior: "smooth"})}}>{"<"} Gaya Belajar</Link>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report-mobiles/domain-kecerdasan", state: { data: data, package_id: packages, params: token }}} onClick={() => {document.body.scrollIntoView({behavior: "smooth"})}}>Domain Kecerdasan {">"}</Link>
                        </>
                    )}
                    {packages === 33 && (
                        <>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report-mobiles/gaya-belajar", state: { data: data, package_id: packages, params: token }}} onClick={() => {document.body.scrollIntoView({behavior: "smooth"})}}>{"<"} Gaya Belajar</Link>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report-mobiles/domain-kecerdasan", state: { data: data, package_id: packages, params: token }}} onClick={() => {document.body.scrollIntoView({behavior: "smooth"})}}>Domain Kecerdasan {">"}</Link>
                        </>
                    )}
                </div>
                    </>
                ) : (
                    <>
                        <Link style={{marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to={`/report-mobile/${token}`}>Back</Link>
                        <Lottie options={{loop: true,autoplay: true,animationData: accessDenied,rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} isClickToPauseDisabled={true} />
                        <p className='upgradePlease'> Maaf, Anda belum melakukan Upgrade yang lebih tinggi</p>
                    </>
                )}

            </div>
        </React.Fragment>
    )

}

export default Kecerdasan;
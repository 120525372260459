import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Progress} from 'reactstrap';

import Lottie from 'react-lottie';
import accessDenied from '../../../../../assets/images/FutureLink/accessDenied.json';

import howard from '../../../../../assets/images/FutureLink/howard.png';
import intrapersonal from '../../../../../assets/images/FutureLink/intrapersonal.png';
import interpersonal from '../../../../../assets/images/FutureLink/interpersonal.png';
import musical from '../../../../../assets/images/FutureLink/musical.png';
import naturalist from '../../../../../assets/images/FutureLink/naturalist.png';
import logical from '../../../../../assets/images/FutureLink/logical.png';
import visual1 from '../../../../../assets/images/FutureLink/visual1.png';
import existential from '../../../../../assets/images/FutureLink/existential.png';
import kinesthetic1 from '../../../../../assets/images/FutureLink/kinesthetic1.png';
import linguistik from '../../../../../assets/images/FutureLink/linguistik.png';

import single from '../../../../../assets/images/FutureLink/single.png';
import dual from '../../../../../assets/images/FutureLink/dual.png';
import triple from '../../../../../assets/images/FutureLink/triple.png';

const KecerdasanMajemuk= (haisayang) => {
    const data = haisayang.history.location.state.data
    // console.log(haisayang.history.location.state.data)

    const packages = haisayang.history.location.state.package_id
    // console.log(haisayang.history.location.state.package_id)

    const token = haisayang.history.location.state.params

    return(
        <React.Fragment>
            <div className="page-content">
                {packages === 33 ? (
                    <>
                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                        <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 40px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Kecerdasan Majemuk</p>
                    </div>

                    <div className='kecerdasanmajemuk'>
                        <Link style={{marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to={`/report-mobile/${token}`}>Back</Link>

                        <div className='kecerdasanmajemukWeb'>

                            <div style={{margin: '30px 80px'}}>
                                <div style={{display: 'flex', alignItems: 'center', gap: '30px'}}>
                                    <img src={howard} style={{width: '140px'}} alt=''/>
                                    <p style={{fontSize: '24px', fontWeight: '700', margin: '0px'}}>Dr. Howard Gardner<br/>Multiple Intelligences Inventor</p>
                                </div>

                                <div style={{margin: '25px 0px'}}>
                                    <p style={{margin: '0px', fontSize: '14px', textAlign: 'justify'}}>Howard Gardner atau Antony Wilker adalah tokoh pendidikan dan psikologi terkenal yang mencetuskan teori tentang 9 kecerdasan majemuk atau 9 multiple intelligences yang dipublikasikan dalam bukunya yang berjudul Frames of Mind. Dia mengusulkan bahwa semua individu memiliki sembilan kecerdasan independen yang memungkinkan orang untuk memecahkan masalah dengan berbagai tingkat keterampilan.</p>
                                </div>

                                <div style={{display: 'flex', flexWrap: 'wrap', gap: '30px', margin: '50px 0px'}}>
                                    <div style={{display: 'flex', alignItems: 'center', gap: '40px', width: '250px'}}>
                                        <img src={intrapersonal} style={{width: '48px'}} alt=''/>
                                        <p style={{margin: '0px', color: '#4895EF', fontSize: '18px', fontWeight: '700'}}>Intrapersonal</p>
                                    </div>

                                    <div style={{display: 'flex', alignItems: 'center', gap: '40px', width: '250px'}}>
                                        <img src={interpersonal} style={{width: '48px'}} alt=''/>
                                        <p style={{margin: '0px', color: '#4895EF', fontSize: '18px', fontWeight: '700'}}>Interpersonal</p>
                                    </div>

                                    <div style={{display: 'flex', alignItems: 'center', gap: '40px', width: '250px'}}>
                                        <img src={musical} style={{width: '48px'}} alt=''/>
                                        <p style={{margin: '0px', color: '#4895EF', fontSize: '18px', fontWeight: '700'}}>Musical</p>
                                    </div>

                                    <div style={{display: 'flex', alignItems: 'center', gap: '40px', width: '250px'}}>
                                        <img src={naturalist} style={{width: '48px'}} alt=''/>
                                        <p style={{margin: '0px', color: '#4895EF', fontSize: '18px', fontWeight: '700'}}>Naturalist</p>
                                    </div>

                                    <div style={{display: 'flex', alignItems: 'center', gap: '40px', width: '250px'}}>
                                        <img src={logical} style={{width: '48px'}} alt=''/>
                                        <p style={{margin: '0px', color: '#4895EF', fontSize: '18px', fontWeight: '700'}}>Logical</p>
                                    </div>

                                    <div style={{display: 'flex', alignItems: 'center', gap: '40px', width: '250px'}}>
                                        <img src={visual1} style={{width: '48px'}} alt=''/>
                                        <p style={{margin: '0px', color: '#4895EF', fontSize: '18px', fontWeight: '700'}}>Visual</p>
                                    </div>

                                    <div style={{display: 'flex', alignItems: 'center', gap: '40px', width: '250px'}}>
                                        <img src={existential} style={{width: '48px'}} alt=''/>
                                        <p style={{margin: '0px', color: '#4895EF', fontSize: '18px', fontWeight: '700'}}>Existensial</p>
                                    </div>

                                    <div style={{display: 'flex', alignItems: 'center', gap: '40px', width: '250px'}}>
                                        <img src={kinesthetic1} style={{width: '48px'}} alt=''/>
                                        <p style={{margin: '0px', color: '#4895EF', fontSize: '18px', fontWeight: '700'}}>Kinesthetic</p>
                                    </div>

                                    <div style={{display: 'flex', alignItems: 'center', gap: '40px', width: '250px'}}>
                                        <img src={linguistik} style={{width: '48px'}} alt=''/>
                                        <p style={{margin: '0px', color: '#4895EF', fontSize: '18px', fontWeight: '700'}}>Linguistik</p>
                                    </div>
                                </div>

                                <div className='expert'>
                                    
                                    <div className='itemKanan'>
                                        <p className='number'>01</p>
                                        <div className='text'>
                                            <p className='keterangan'>Intrapersonal</p>
                                            <p className='isi'>Kemampuan untuk memahami diri sendiri.</p>
                                        </div>
                                        <div className='rounds'></div>
                                        <div className='imgValue'>
                                            <img src={intrapersonal} alt='' />
                                            <p>{data["8_intra"]}%</p>
                                        </div>
                                    </div>
                                    <div className='itemKiri'>
                                        <p className='number'>02</p>
                                        <div className='text'>
                                            <p className='keterangan'>Interpersonal</p>
                                            <p className='isi'>Kemampuan untuk memahami orang lain.</p>
                                        </div>
                                        <div className='rounds'></div>
                                        <div className='imgValue'>
                                            <img src={interpersonal} alt=''/>
                                            <p>{data["8_inter"]}%</p>
                                        </div>
                                    </div>
                                    <div className='itemKanan'>
                                        <p className='number'>03</p>
                                        <div className='text'>
                                            <p className='keterangan'>Logical</p>
                                            <p className='isi'>Kemampuan untuk bekerja dengan angka dan pemahaman logis.</p>
                                        </div>
                                        <div className='rounds'></div>
                                        <div className='imgValue'>
                                            <img src={logical} alt=''/>
                                            <p>{data["8_logical"]}%</p>
                                        </div>
                                    </div>
                                    <div className='itemKiri'>
                                        <p className='number'>04</p>
                                        <div className='text'>
                                            <p className='keterangan'>Visual</p>
                                            <p className='isi'>Kemampuan untuk memahami sesuatu dengan melihat atau membayangkan.</p>
                                        </div>
                                        <div className='rounds'></div>
                                        <div className='imgValue'>
                                            <img src={visual1} alt=''/>
                                            <p>{data["8_visual"]}%</p>
                                        </div>
                                    </div>
                                    <div className='itemKanan'>
                                        <p className='number'>05</p>
                                        <div className='text'>
                                            <p className='keterangan'>Kinesthetic</p>
                                            <p className='isi'>Kemampuan untuk beraktivitas dengan kekuatan tubuh dan tangan.</p>
                                        </div>
                                        <div className='rounds'></div>
                                        <div className='imgValue'>
                                            <img src={kinesthetic1} alt=''/>
                                            <p>{data["8_bodily_kinesthetic"]}%</p>
                                        </div>
                                    </div>
                                    <div className='itemKiri'>
                                        <p className='number'>06</p>
                                        <div className='text'>
                                            <p className='keterangan'>Linguistik</p>
                                            <p className='isi'>Kemampuan berekspresi secara verbal dan non-verbal dengan lancar.</p>
                                        </div>
                                        <div className='rounds'></div>
                                        <div className='imgValue'>
                                            <img src={linguistik} alt=''/>
                                            <p>{data["8_linguistic"]}%</p>
                                        </div>
                                    </div>
                                    <div className='itemKanan'>
                                        <p className='number'>07</p>
                                        <div className='text'>
                                            <p className='keterangan'>Musical</p>
                                            <p className='isi'>Kemampuan untuk memahami suara, serta kompatibilitas dengan emosi dan ekspresi.</p>
                                        </div>
                                        <div className='rounds'></div>
                                        <div className='imgValue'>
                                            <img src={musical} alt=''/>
                                            <p>{data["8_musical"]}%</p>
                                        </div>
                                    </div>
                                    <div className='itemKiri'>
                                        <p className='number'>08</p>
                                        <div className='text'>
                                            <p className='keterangan'>Naturalist</p>
                                            <p className='isi'>Kemampuan untuk terhubung dengan lingkungan naturalistik.</p>
                                        </div>
                                        <div className='rounds'></div>
                                        <div className='imgValue'>
                                            <img src={naturalist} alt=''/>
                                            <p>{data["8_naturalist"]}%</p>
                                        </div>
                                    </div>
                                    <div className='itemKanan'>
                                        <p className='number'>09</p>
                                        <div className='text'>
                                            <p className='keterangan'>Existential</p>
                                            <p className='isi'>Kemampuan untuk memahami keberadaan manusia.</p>
                                        </div>
                                        <div className='rounds'></div>
                                        <div className='imgValue'>
                                            <img src={existential} alt=''/>
                                            <p>{data.existential}%</p>
                                        </div>
                                    </div>

                                </div>
                            
                                <div  style={{marginBottom: '75px'}}>
                                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                        <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 40px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Intrapersonal</p>
                                    </div>

                                    <div style={{display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '30px'}}>
                                        <img src={intrapersonal} style={{width: '80px'}} alt=''/>
                                        <img src={dual} style={{width: '250px'}} alt=''/>
                                        <div style={{display: 'flex', flexDirection: 'column', gap: '80px'}}>
                                            <div>
                                            <p style={{margin: '0px', fontSize: '24px', fontWeight: '700', color: '#05C38A'}}>{data.planning}%</p>
                                            <p style={{margin : '0px', fontSize: '14px'}}>Planning, Intuitive, Judgement, & Execution</p>
                                            </div>

                                            <div>
                                            <p style={{margin: '0px', fontSize: '24px', fontWeight: '700', color: '#05C38A'}}>{data.emotion}%</p>
                                            <p style={{margin : '0px', fontSize: '14px'}}>Self Motivational & Emotional Behavior</p>
                                            </div>

                                        </div>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Deskripsi:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Kecerdasan ini mendistribusikan kapasitas introspektif dan self-efektif. Mereka biasanya sangat sadar diri dan mampu memahami emosi, tujuan, dan motivasi mereka sendiri. Mereka sering memiliki ketertarikan pada pengajaran berbasis pemikiran seperti filsafat. Mereka belajar paling baik ketika diizinkan untuk berkonsentrasi pada subjek itu sendiri. Seringkali ada perfeksionisme tingkat tinggi yang terkait dengan kecerdasan ini.
                                        <br/><br/>
                                        Kecerdasan ini menimbulkan kenyamanan dengan diri sendiri. Mereka menyukai privasi dan bekerja secara mandiri. Mereka mudah memotivasi diri dan menunjukkan kepercayaan pada kemampuan diri sendiri karena mengetahui kekuatan dan kelemahan diri sendiri. Mereka terbiasa mengikuti insting, intuitif dan menghabiskan waktu untuk berpikir serta berefleksi. Mudah mengungkapkan rasa keadilan, perfeksionis dan sadar akan rentang emosi diri sendiri serta jarang meminta bantuan untuk masalah pribadi.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Cara Belajar:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Cara belajar yang digunakan biasanya dengan menggunakan korelasi dalam membuat perbandingan, memberikan kegiatan yang menawarkan pilihan, menetapkan tujuan untuk diri sendiri di dalam kelas, menulis jurnal harian, mengungkapkan perasaan terhadap suatu topik, memberikan kesempatan untuk melakukan refleksi terhadap pembelajaran, meneliti peristiwa terkini dalam hal keadilan sosial, memanfaatkan inventaris minat, kuesioner, wawancara, dan pendekatan lain untuk mengukur pertumbuhan diri.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Pengembangan Diri:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Cara meningkatkan kecerdasan intrapersonal adalah dengan bekerja secara mandiri dan memiliki ruang khusus, membuat jurnal, fokus pada perasaan, menetapkan target pencapaian, serta mempertimbangkan hal-hal (atau orang) apa yang menyebabkan stres dan temukan strategi untuk meminimalkan efeknya.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Keterampilan:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Keterampilan yang dihasilkan dari kecerdasan ini adalah mendiagnosis, mengobati, dan merehabilitasi disfungsi fisik dan mental, memberikan konseling dan bimbingan karir, memberikan perhatian penuh pada apa yang dikatakan orang lain, meluangkan waktu untuk memahami poin yang dibuat dan mengajukan pertanyaan yang sesuai, menyelesaikan konflik, menyelesaikan perselisihan dan bernegosiasi dengan orang lain.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Karir:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Orang yang cerdas diri paling cocok untuk pekerjaan dimana mereka dapat bekerja sendiri dan atau mengeksplorasi perasaan, nilai, dan keyakinan batin mereka. Mereka cenderung termotivasi secara intrinsik di tempat kerja daripada termotivasi oleh penghargaan eksternal. Mereka cenderung berkemauan keras dan memiliki pendapat yang matang tentang bagaimana sesuatu harus dilakukan.
                                        <br/><br/>
                                        Contoh pekerjaan yang mengandalkan kecerdasan ini adalah Terapis, Psikolog, Peneliti Potensi Manusia, Filsuf, Tokoh Agama, Pekerja Sosial, Pemandu Meditasi, Konselor, Pemimpin Organisasi, Penasihat & Pelatih, Peneliti Pola Kognitif, dan Profesional Kesehatan Mental.
                                        </p>
                                    </div>
                                </div>

                                <div style={{marginBottom: '75px'}}>
                                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                        <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 40px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Interpersonal</p>
                                    </div>

                                    <div style={{display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '30px'}}>
                                        <img src={interpersonal} style={{width: '80px'}} alt=''/>
                                        <img src={dual} style={{width: '250px'}} alt='' />
                                        <div style={{display: 'flex', flexDirection: 'column', gap: '80px'}}>
                                            <div>
                                            <p style={{margin: '0px', fontSize: '24px', fontWeight: '700', color: '#05C38A'}}>{data.social}%</p>
                                            <p style={{margin : '0px', fontSize: '14px'}}> Social Behavior & Language Comprehension</p>
                                            </div>

                                            <div>
                                            <p style={{margin: '0px', fontSize: '24px', fontWeight: '700', color: '#05C38A'}}>{data.motivation}%</p>
                                            <p style={{margin : '0px', fontSize: '14px'}}> Motivational & Emotional Behavior to Others</p>
                                            </div>

                                        </div>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Deskripsi:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Kecerdasan ini berkaitan dengan interaksi dengan orang lain. Orang-orang dalam kategori ini biasanya ekstrovert dan dicirikan oleh kepekaan mereka terhadap suasana hati, perasaan, temperamen dan motivasi orang lain serta kemampuan mereka untuk bekerja sama agar dapat bekerja sebagai bagian dari kelompok. Orang yang memiliki kecerdasan ini merupakan orang yang sangat maju dan sangat selaras dengan orang lain. Mereka telah mengembangkan beragam keterampilan sosial yang mereka andalkan dalam komunikasi orang-ke-orang.
                                        <br/><br/>
                                        Kecerdasan ini membuat setiap orang dapat menikmati lingkaran pertemanan, membentuk dan memelihara hubungan sosial, memikirkan dan memahami orang lain, memiliki empati dan terlibat dalam interaksi dan pengaruh secara efektif dengan satu atau lebih orang dalam situasi akrab, santai atau kerja. Pandai menyelesaikan konflik dalam kelompok dan cenderung menjadi pemimpin alami, senang mengajar orang lain dan berbagi. Mudah mengenali dan menggunakan berbagai cara untuk berhubungan dengan orang lain, menikmati kerja kolaboratif, dan suka bertemu orang baru.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Pengembangan Diri:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Cara meningkatkan kecerdasan Interpersonal adalah dengan menganalisis peran dan interaksi orang lain, melatih “people watching", bergabung dengan klub dan berbagi minat dengan orang lain, melatih keterampilan mendengarkan, dan tersenyum saat berbicara dengan orang lain.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Keterampilan:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Keterampilan yang dihasilkan dari kecerdasan Keterampilan yang dihasilkan dari kecerdasan ini adalah membujuk orang lain untuk mengubah pikiran atau perilaku mereka, menyadari reaksi orang lain dan memahami mengapa mereka bereaksi seperti itu, berbicara dengan orang lain untuk menyampaikan informasi secara efektif, serta memberikan bantuan pribadi, perhatian medis, dukungan emosional atau perawatan lain kepada orang lain.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Karir:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Orang yang kecerdasan interpersonalnya tinggi dapat berkomunikasi secara efektif dan mudah berempati dengan orang lain dan mungkin pemimpin atau pengikut, mereka biasanya belajar paling baik dengan bekerja dengan orang lain dan seringkali mereka menikmati diskusi dan debat.
                                        <br/><br/>
                                        Contoh pekerjaan yang mengandalkan kecerdasan ini adalah Pendidik, Pelatih, Mentor, Pembawa Acara Talk Show, Staf Penjualan, Penyelenggara Acara, Manajer Layanan Pelanggan, Kepala Sekolah, Manajer, Perawat, Pekerja Sosial, Politisi, Antropolog, Sosiolog, Pemimpin Organisasi (Presiden dan CEO), Konselor, dan Profesional Sumber Daya Manusia.
                                        </p>
                                    </div>
                                </div>

                                <div style={{marginBottom: '75px'}}>
                                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                        <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 40px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Logical</p>
                                    </div>

                                    <div style={{display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '30px'}}>
                                        <img src={logical} style={{width: '80px'}} alt=''/>
                                        <img src={triple} style={{width: '250px'}} alt=''/>
                                        <div style={{display: 'flex', flexDirection: 'column', gap: '15px'}}>
                                            <div>
                                            <p style={{margin: '0px', fontSize: '24px', fontWeight: '700', color: '#05C38A'}}>{data.numerical}%</p>
                                            <p style={{margin : '0px', fontSize: '14px'}}>Numeric Skills</p>
                                            </div>

                                            <div>
                                            <p style={{margin: '0px', fontSize: '24px', fontWeight: '700', color: '#05C38A'}}>{data.reasoning}%</p>
                                            <p style={{margin : '0px', fontSize: '14px'}}> Reasoning & Problem Solving</p>
                                            </div>

                                            <div>
                                            <p style={{margin: '0px', fontSize: '24px', fontWeight: '700', color: '#05C38A'}}>{data.geometry}%</p>
                                            <p style={{margin : '0px', fontSize: '14px'}}> Complex Visual / Spatial Geometry Problem</p>
                                            </div>

                                        </div>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Deskripsi:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Kecerdasan ini memiliki kapasitas untuk bekerja dengan angka dan bernalar dengan baik. Ini termasuk membedakan pola logis atau hubungan dan mengkategorikan, mengklasifikasikan, dan menghitung informasi dan data secara efisien. Meskipun sering diasumsikan bahwa mereka yang memiliki kecerdasan ini secara alami unggul dalam matematika, pemrograman komputer, dan aktivitas logis atau numerik lainnya, definisi yang lebih akurat tidak terlalu menekankan pada kemampuan matematika tradisional dan lebih banyak kemampuan penalaran, pengenalan pola abstrak, pemikiran dan penyelidikan ilmiah, dan kemampuan untuk melakukan perhitungan yang rumit.
                                        <br/><br/>
                                        Kecerdasan ini biasanya senang mencari keteraturan, menganalisis ide-ide abstrak, bernalar secara ilmiah, dan mengidentifikasi hubungan. Selain itu suka menyelesaikan teka-teki, mahir menghitung angka, memecahkan masalah secara naluriah, dapat dengan mudah melakukan operasi matematika dengan kecepatan tinggi dan nyaman ketika sesuatu diukur, dikategorikan, dianalisis atau dikuantifikasi. Mungkin tidak mau berkompromi karena memiikirkan sebab dan akibat serta memahami hubungan di antara tindakan, objek, atau gagasan.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Cara Belajar:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Cara belajar yang digunakan biasanya dengan menciptakan tatanan intrinsik dan ekstrinsik di lingkungan belajar, menyajikan kriteria di awal kegiatan untuk memberikan struktur, menawarkan tugas pemecahan masalah terbuka, melakukan kegiatan berpikir konvergen dalam pengajaran. Mempromosikan eksperimen yang menguji hipotesis, menggunakan argumen logis dalam bahasa, melakukan debat dan mengerjakan teka-teki, serta menetapkan tujuan jangka pendek yang dapat dicapai.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Pengembangan Diri:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Cara meningkatkan kecerdasan Logikal adalah dengan memecahkan teka-teki logis, bermain catur, memecahkan masalah matematika, menemukan hal-hal baru dan tidak biasa untuk dihitung, diperkirakan, dan diukur, serta menulis ulang setiap catatan dalam bentuk kerangka angka romawi.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Keterampilan:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Keterampilan yang dihasilkan dari kecerdasan ini adalah pengetahuan tentang aritmatika, aljabar, geometri, kalkulus, statistik, dan aplikasi matematika lainnya. Pengetahuan tentang prinsip dan praktik ekonomi dan akuntansi. Kemampuan untuk memilih metode matematika yang tepat untuk memecahkan masalah serta penggunaan logika dan penalaran untuk mengidentifikasi kekuatan dan kelemahan. Penerapan penalaran deduktif pada masalah tertentu untuk menghasilkan jawaban yang masuk akal.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Karir:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Orang yang memiliki kecerdasan logika paling cocok untuk pekerjaan yang melibatkan matematika dan logika. Banyak pekerjaan yang diminati saat ini membutuhkan beberapa kemampuan untuk menggunakan angka, logika, atau penalaran ilmiah sebagai cara untuk menghadapi tantangan.
                                        <br/><br/>
                                        Contoh pekerjaan yang mengandalkan kecerdasan ini adalah Ilmuwan, Ahli Matematika, Petugas Pajak, Akuntan, Ahli Statistik, Ilmuwan, Hakim, Aktuaris, Insinyur Perangkat Lunak, Insinyur, Dokter, Ekonom.
                                        </p>
                                    </div>
                                </div>

                                <div style={{marginBottom: '75px'}}>
                                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                        <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 40px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Visual</p>
                                    </div>

                                    <div style={{display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '30px'}}>
                                        <img src={visual1} style={{width: '80px'}} alt=''/>
                                        <img src={dual} style={{width: '250px'}} alt=''/>
                                        <div style={{display: 'flex', flexDirection: 'column', gap: '80px'}}>
                                            <div>
                                            <p style={{margin: '0px', fontSize: '24px', fontWeight: '700', color: '#05C38A'}}>{data["3d"]}%</p>
                                            <p style={{margin : '0px', fontSize: '14px'}}>3D Space Processing</p>
                                            </div>

                                            <div>
                                            <p style={{margin: '0px', fontSize: '24px', fontWeight: '700', color: '#05C38A'}}>{data.spiritual}%</p>
                                            <p style={{margin : '0px', fontSize: '14px'}}> Visual Processing & Spiritual Relations</p>
                                            </div>

                                        </div>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Deskripsi:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Kecerdasan ini berkaitan dengan penglihatan dan penilaian spasial. Ini juga melibatkan kepekaan terhadap bentuk, garis, warna, bentuk, dan ruang, serta hubungan yang ada di antara mereka. Orang dengan kecerdasan visual-spasial yang kuat biasanya sangat baik dalam memvisualisasikan dan memanipulasi objek secara mental. Mereka memiliki memori visual yang kuat dan cenderung artistik. Mereka seringkali adalah pemikir visual yang "melihat" solusi untuk masalah. Mereka berpikir dalam gambar dan sangat sadar akan objek, bentuk, warna, pola, dan tekstur.
                                        <br/><br/>
                                        Kecerdasan ini biasanya peka terhadap warna, dapat membaca bagan dan peta dengan mudah, suka menggunakan kamera, selalu memperhatikan bagaimana sebuah ruangan diatur, mengingat sesuatu dengan melihatnya, dan bisa melihat bila gambar tidak menggantung lurus. Memiliki indra pengarahan yang baik, sering mencoret-coret atau menggambar, bisa dengan senang hati menghabiskan waktu melihat pemandangan yang indah, suka menikmati memecahkan jigsaw atau teka-teki visual, menikmati membongkar barang-barang dan kemudian menyatukannya Kembali, dan dapat memanipulasi model tiga dimensi dalam pikiran mereka.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Cara Belajar:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Cara belajar yang digunakan biasanya dengan bergerak di sekitar lingkungan belajar, membuat lingkungan yang merangsang secara visual, membuat sketsa rencana sebelum mulai bekerja, brainstorming ide, melakukan pemetaan semantic, membuat diagram konsep abstrak, mengerjakan tugas kinerja penilaian visual, serta memanfaatkan teknologi visual seperti Kid Pix dan PowerPoint.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Pengembangan Diri:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Cara meningkatkan kecerdasan visual adalah dengan mengunjungi galeri seni, menonton film, belajar menggambar atau melukis, dan membuat doodle. Belajar dengan Peta Pikiran, serta melatih kata-kata dan mengilustrasikan sebuah cerita.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Keterampilan:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Keterampilan yang dihasilkan dari kecerdasan visual adalah pengetahuan tentang teknik desain, alat, dan prinsip yang terlibat dalam produksi rencana, cetak biru, gambar, dan model. Kemampuan menganalisis kebutuhan dan persyaratan produk untuk membuat desain. Kemampuan untuk membayangkan bagaimana sesuatu akan terlihat setelah ditata ulang. Kemampuan untuk mengembangkan cara-cara kreatif untuk memecahkan masalah. Kemampuan untuk mencocokkan atau mendeteksi perbedaan antara warna, bentuk, dan kecerahan.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Karir:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Orang yang memiliki kecerdasan gambar paling cocok untuk pekerjaan yang melibatkan seni atau sains. Mereka yang tertarik dengan seni cenderung mengembangkan keterampilan dalam melukis, memahat, menggambar, dan mendesain. Mereka mampu membayangkan dan memvisualisasikan suatu objek dan kemudian membuat objek seni dari gambaran mental ini di kepala mereka. Mereka yang tertarik dengan sains dapat menggunakan keterampilan visualisasi mereka di bidang teknik, arsitektur, dan penyusunan.
                                        <br/><br/>
                                        Contoh pekerjaan yang mengandalkan kecerdasan ini adalah Pemandu wisata, Desainer Interior, Perencanaan Kota, Pilot, Kapten, Arsitek, Fotografer, Pelukis, Artis dan Animator Multimedia, Ahli Bedah, Desainer Beranda, Kartunis, serta Ilustrator.
                                        </p>
                                    </div>
                                </div>

                                <div style={{marginBottom: '75px'}}>
                                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                        <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 40px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Kinesthetic</p>
                                    </div>

                                    <div style={{display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '30px'}}>
                                        <img src={kinesthetic1} style={{width: '80px'}} alt=''/>
                                        <img src={dual} style={{width: '250px'}} alt=''/>
                                        <div style={{display: 'flex', flexDirection: 'column', gap: '80px'}}>
                                            <div>
                                            <p style={{margin: '0px', fontSize: '24px', fontWeight: '700', color: '#05C38A'}}>{data.fine_motor_kin}%</p>
                                            <p style={{margin : '0px', fontSize: '14px'}}> Fine Motor Skills</p>
                                            </div>

                                            <div>
                                            <p style={{margin: '0px', fontSize: '24px', fontWeight: '700', color: '#05C38A'}}>{data.gross_motor_kin}%</p>
                                            <p style={{margin : '0px', fontSize: '14px'}}> Gross Motor Skills</p>
                                            </div>

                                        </div>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Deskripsi:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Kecerdasan ini berhubungan dengan gerak dan perbuatan. Dalam kategori ini, orang umumnya mahir dalam aktivitas fisik seperti olahraga atau menari dan sering lebih menyukai aktivitas yang memanfaatkan gerakan. Kecerdasan tubuh sering diekspresikan dalam keterampilan fisik tertentu seperti koordinasi, keseimbangan, ketangkasan, kekuatan, kelenturan, dan kecepatan. Orang yang memiliki kecerdasan kinestetik-jasmani yang tinggi memiliki memori otot yang kuat yaitu mengingat sesuatu melalui tubuh daripada melalui kata-kata (memori verbal) atau gambar (memori visual).
                                        <br/><br/>
                                        Kecerdasan ini biasanya lebih menggunakan gerakan tubuh dan tangan saat berbicara dengan orang lain tentang kegiatan dan pembelajaran, dapat tetap fokus pada tugas langsung untuk jangka waktu yang lama, dapat melakukan tugas tanpa petunjuk tertulis, dan menikmati permainan kelompok dan tugas belajar aktif. Lebih suka belajar dengan pena di tangan untuk menulis sesuatu, menikmati aktivitas seperti berenang, berlari, mengendarai sepeda, atau berselancar serta melakukan koordinasi untuk gerakan seluruh tubuh dan penggunaan tangan untuk memanipulasi objek.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Cara Belajar:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Cara belajar yang digunakan biasanya dengan menyediakan pusat pembelajaran langsung, memasukkan drama kreatif ke dalam instruksi, menyertakan game interaktif dalam meninjau dan meremediasi konten, menawarkan pengalaman dalam gerakan mengikuti irama dan musik, serta melakukan eksperimen sains secara langsung. Membiarkan peluang untuk membangun dan membongkar, membangun representasi fisik dari konsep, serta menjaga seseorang bergerak secara fisik sepanjang pembelajaran.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Pengembangan Diri:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Cara meningkatkan kecerdasan ini adalah dengan terlibat dalam olahraga dan aktivitas fisik, menggunakan tangan yang tidak dominan (mungkin selama satu jam sehari), memperhatikan hubungan antara bahasa tubuh dan perasaan, mempelajari cara aktif menggunakan bahasa tubuh untuk mempengaruhi orang lain, dan melakukan hobi praktis.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Keterampilan:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Keterampilan yang dihasilkan oleh kecerdasan kinestetik adalah kemampuan mengerahkan kekuatan otot maksimal untuk mengangkat, mendorong, menarik, atau membawa benda. Kemampuan mengerahkan kekuatan otot secara berulang-ulang dan terus menerus sepanjang waktu. Melakukan perbaikan, penyesuaian, dan pengujian mesin, perangkat, komponen bergerak, dan peralatan. Memeriksa peralatan, struktur atau bahan untuk mengidentifikasi penyebab kesalahan atau masalah atau cacat lainnya. Kemampuan untuk menggerakkan tangan dengan cepat untuk menggenggam, memanipulasi, atau merakit objek.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Karir:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Orang yang memiliki kecerdasan tubuh paling cocok untuk pekerjaan yang melibatkan penggunaan tangan, jari, kaki, dan lengan mereka untuk memecahkan masalah atau memperbaiki atau menghasilkan sesuatu.
                                        <br/><br/>
                                        Contoh pekerjaan yang mengandalkan kecerdasan kinestetik adalah Aktor, Instruktur Yoga, Penari, Atlet, Pematung, Staf Perawat, Koki, Mekanik/Teknisi, Pengemudi, Olahragawan, Prajurit, Pemadam Kebakaran, Artis Pertunjukan, Ahli Ergonomi, Ahli Osteopat, Pengrajin, AhliAkupunktur, dan Petualang.
                                        </p>
                                    </div>
                                </div>

                                <div style={{marginBottom: '75px'}}>
                                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                        <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 40px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Linguistik</p>
                                    </div>

                                    <div style={{display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '30px'}}>
                                        <img src={linguistik} style={{width: '80px'}} alt=''/>
                                        <img src={triple} style={{width: '250px'}} alt=''/>
                                        <div style={{display: 'flex', flexDirection: 'column', gap: '15px'}}>
                                            <div>
                                            <p style={{margin: '0px', fontSize: '24px', fontWeight: '700', color: '#05C38A'}}>{data.communication}%</p>
                                            <p style={{margin : '0px', fontSize: '14px'}}> Verbal Comumunication</p>
                                            </div>

                                            <div>
                                            <p style={{margin: '0px', fontSize: '24px', fontWeight: '700', color: '#05C38A'}}>{data.writing}%</p>
                                            <p style={{margin : '0px', fontSize: '14px'}}> Visual Aspect of Writting</p>
                                            </div>

                                            <div>
                                            <p style={{margin: '0px', fontSize: '24px', fontWeight: '700', color: '#05C38A'}}>{data.memory}%</p>
                                            <p style={{margin : '0px', fontSize: '14px'}}> Verbal Memory & Language Comprehension</p>
                                            </div>

                                        </div>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Deskripsi:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Kecerdasan ini berkaitan dengan kata-kata yang diucapkan atau ditulis. Ini termasuk kemampuan untuk memanipulasi struktur atau sintaksis bahasa. Dengan kata lain, orang yang kecerdasan kata-katanya sangat berkembang cenderung menjadi penulis dan pembicara publik yang hebat. Mereka sering menjadi komunikator yang efektif dan menghargai kata-kata tertulis. Orang dengan kecerdasan verbal-linguistik biasanya pandai membaca, menulis, bercerita dan menghafal kata-kata dan tanggal.
                                        <br/><br/>
                                        Kecerdasan ini biasanya menghargai detail tata bahasa dan makna, mengetahui dengan baik ketika seseorang menggunakan bahasa yang tata bahasanya salah, dapat mengeja dengan mudah, memiliki ingatan yang baik untuk kata-kata, orang, tempat, nama, dan tanggal. Menikmati permainan kata, memahami lelucon, dan membuat komentar cerdas, suka menggunakan bahasa deskriptif, pendongeng yang baik, dapat mendemonstrasikan pemahaman dengan mudah melalui diskusi dan esai. Suka mempelajari kata-kata baru dan menggunakannya saat berbicara atau menulis, menggunakan bahasa secara efektif untuk negosiasi dan persuasi, serta menggunakan kata-kata dengan baik dalam menulis laporan, surat, dan cerita.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Cara Belajar:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Cara belajar yang digunakan adalah dengan menjelajahi kosa kata baru, mempelajari istilah dan ungkapan dari bahasa ibu, mencari kesempatan untuk berbicara di depan umum, memasukkan drama ke dalam pembelajaran, membuat dan menyimpan jurnal harian. Mencari peluang untuk menulis kreatif, ekspositori dan naratif, melakukan pembinaan bercerita secara lisan, serta memanfaatkan literatur anak dan dewasa muda yang berkualitas.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Pengembangan Diri:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Cara meningkatkan kecerdasan ini adalah dengan menceritakan kisah, lelucon, dan anekdot. Memainkan permainan memori, bereksperimen dengan buku permainan kata (anagram, akrostik, dan lain-lain), senang mempelajari dan menggunakan kata baru setiap hari. Mendengarkan deskripsi dengan hati-hati, pergantian frase dan oxymoron yang menghibur (kontradiksi verbal). Mendengarkan radio, bicara lebih sering - dan "bergabung" dengan mengungkapkan sudut pandang sendiri.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Keterampilan:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Keterampilan yang dihasilkan dari kercerdasan Linguistik adalah memahami urutan dan makna kata, mengingat dan menghafal informasi Menjelaskan, mengajar dan belajar. Membujuk orang lain melalui tulisan dan/atau berbicara. Membuat tulisan kreatif dan ekspresif, serta kemampuan untuk mengkomunikasikan informasi dan ide dengan jelas melalui berbicara atau menulis.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Karir:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Orang yang memiliki kecerdasan kata paling cocok untuk pekerjaan yang melibatkan berbicara atau menulis. Mereka ahli dalam memberikan instruksi kepada orang lain, menulis dan mengedit teks, serta mendiskusikan dan memperdebatkan informasi dan ide di tempat kerja. Mereka juga sering terampil mengajar dan membujuk orang lain untuk melakukan sesuatu.
                                        <br/><br/>
                                        Contoh pekerjaan yang mengandalkan kecerdasan ini adalah Penulis, Pengacara, Jurnalis, Pembicara, Pelatih, Copywriter, Guru, Penyair, Editor, Penerjemah, Konsultan Media, Presenter TV & Radio, Artis Pengisi Suara, dan Novelis.
                                        </p>
                                    </div>
                                </div>

                                <div style={{marginBottom: '75px'}}>
                                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                        <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 40px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Musical</p>
                                    </div>

                                    <div style={{display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '30px'}}>
                                        <img src={musical} style={{width: '80px'}} alt=''/>
                                        <img src={triple} style={{width: '250px'}} alt=''/>
                                        <div style={{display: 'flex', flexDirection: 'column', gap: '15px'}}>
                                            <div>
                                            <p style={{margin: '0px', fontSize: '24px', fontWeight: '700', color: '#05C38A'}}>{data.voice}%</p>
                                            <p style={{margin : '0px', fontSize: '14px'}}> Voice Tone & Pitch Modulations</p>
                                            </div>

                                            <div>
                                            <p style={{margin: '0px', fontSize: '24px', fontWeight: '700', color: '#05C38A'}}>{data.perception}%</p>
                                            <p style={{margin : '0px', fontSize: '14px'}}> Perception of Auditory Stumuli</p>
                                            </div>

                                            <div>
                                            <p style={{margin: '0px', fontSize: '24px', fontWeight: '700', color: '#05C38A'}}>{data.recognition}%</p>
                                            <p style={{margin : '0px', fontSize: '14px'}}> Process & Recognition</p>
                                            </div>

                                        </div>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Deskripsi:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Kecerdasan ini berkaitan dengan irama, musik, dan pendengaran. Mereka yang memiliki kecerdasan ritmik musik tingkat tinggi menunjukkan kepekaan yang lebih besar terhadap suara, ritme, nada, dan musik. Mereka biasanya memiliki nada yang bagus dan bahkan mungkin memiliki nada absolut dan mampu menyanyi, memainkan alat musik, dan menggubah musik. Selain itu, mereka akan sering menggunakan lagu atau ritme untuk mempelajari dan mengingat informasi dan mungkin bekerja paling baik dengan memainkan musik.
                                        <br/><br/>
                                        Kecerdasan ini biasanya senang mendengarkan musik dan bergerak dengan irama, suka menyanyi atau bersenandung, mudah mengingat melodi lagu setelah mendengarkan sekali saja, dapat menanggapi irama dalam bahasa, terbiasa mengambil istilah dan frasa dalam bahasa asing dengan mudah. Menggunakan pola untuk mengingat keterampilan, ide, dan konsep. Dapat mengenali, membuat dan mereproduksi musik dengan menggunakan instrumen atau suara, serta dapat mendengarkan secara aktif dan hubungan yang kuat antara musik dan emosi.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Cara Belajar:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Cara belajar yang digunakan biasanya dengan mendengarkan suara di lingkungan belajar, bergerak dengan irama, mengidentifikasi skema sajak, mendengarkan simfoni, menguraikan kode, dan belajar membaca musik. Menggunakan musik untuk membantu menciptakan "suasana hati" yang tepat untuk apa yang dilakukan.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Pengembangan Diri:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Cara meningkatkan kecerdasan ini adalah dengan mencoba memainkan berbagai jenis musik saat bekerja, bereksperimen untuk melihat musik "'yang paling cocok", menggunakan musik untuk membantu menciptakan "suasana hati" yang tepat untuk apa yang dilakukan, mendengarkan berbagai gaya musik, termasuk musik Oriental dan Afrika, serta mencoba menutup mata dan mendengarkan baik-baik berbagai suara di sekitar kemudian pilih arah dan sumber suara tersebut.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Keterampilan:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Keterampilan yang dihasilkan dari kecerdasan musikal adalah dapat menerapkan teori musik untuk membuat struktur musik, menggunakan komputer untuk menyusun, mengatur, dan mengaransemen musik, menjadi panduan musisi selama latihan, pertunjukan, dan sesi rekaman. Fokus pada satu sumber suara di hadapan suara lain yang mengganggu atau membedakan antara suara yang bervariasi dalam nada dan kenyaringan.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Karir:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Orang yang memiliki kecerdasan kata paling cocok untuk pekerjaan yang mengandalkan isyarat pendengaran dan getaran, termasuk nada, ketukan, ritme, suara, dan musik. Orang pintar musik seringkali sangat kreatif dan memiliki kemampuan yang berkaitan dengan musik dan pekerjaan musik.
                                        <br/><br/>
                                        Contoh pekerjaan yang mengandalkan kecerdasan ini adalah Konduktor Orkestra Simfoni, Musisi, Penyanyi, Komposer, DJ, Perencana Musik, Guru music, Guru vokal, dan Pemain Alat Musik.
                                        </p>
                                    </div>
                                </div>

                                <div style={{marginBottom: '75px'}}>
                                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                        <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 40px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Naaturalist</p>
                                    </div>

                                    <div style={{display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '30px'}}>
                                        <img src={naturalist} style={{width: '80px'}} alt=''/>
                                        <img src={dual} style={{width: '250px'}} alt=''/>
                                        <div style={{display: 'flex', flexDirection: 'column', gap: '80px'}}>
                                            <div>
                                            <p style={{margin: '0px', fontSize: '24px', fontWeight: '700', color: '#05C38A'}}>{data.observation}%</p>
                                            <p style={{margin : '0px', fontSize: '14px'}}> Observation Skill</p>
                                            </div>

                                            <div>
                                            <p style={{margin: '0px', fontSize: '24px', fontWeight: '700', color: '#05C38A'}}>{data.senses}%</p>
                                            <p style={{margin : '0px', fontSize: '14px'}}> Senses</p>
                                            </div>

                                        </div>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Deskripsi:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Kecerdasan ini melibatkan pemahaman alam tumbuhan dan hewan, memperhatikan karakteristik dan mengkategorikannya. Ini umumnya melibatkan pengamatan yang tajam terhadap lingkungan dan sekitarnya serta kemampuan untuk mengklasifikasikan berbagai hal. Mereka senang menjelajah dan bekerja di luar ruangan.
                                        <br/><br/>
                                        Kecerdasan ini biasanya terorganisir secara alami, Suka belajar dan menunjukkan empati dengan alam. Memahami perbedaan makna yang halus, suka mengumpulkan, memilah, dan mengatur materi. Memahami perilaku hewan, kebutuhan, dan karakteristik. Kemampuan untuk bekerja dengan tanaman, yaitu berkebun, bertani dan hortikultura. Memiliki pengetahuan tentang energi kehidupan alami, kekuatan, termasuk cuaca dan fisika. Menghargai tempat-tempat indah dan merasa hidup saat bersentuhan dengan alam sehingga suka berkemah, mendaki, berjalan, dan mendaki. Lebih suka berada di luar ruangan daripada di dalam ruangan.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Cara Belajar:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Cara belajar yang digunakan adalah dengan menggunakan organisator grafis, menyediakan tugas penyortiran dan pengelompokan atribut, memetakan hierarki, membangun portofolio karya, membuat koneksi ke dunia alami, dan membuat strategi pemodelan untuk menemukan kategori dan hierarki umum.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Pengembangan Diri:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Cara meningkatkan kecerdasan Naturalis adalah dengan mengunjungi pedesaan dan meluangkan waktu untuk menghargai warna, suara, bau, tekstur, dan lain-lain. Mengunjungi pusat taman dan lakukan hal yang sama. Mengunjungi kebun binatang, cari persamaan dan perbedaan antar hewan serta berkebun.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Keterampilan:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Keterampilan yang dihasilkan dari kecerdasan Naturalis adalah pengetahuan tentang tumbuhan dan hewan serta interaksinya satu sama lain dengan lingkungan. Pengetahuan tentang prinsip dan metode untuk mendeskripsikan fitur massa darat, laut, atau udara. Kemampuan untuk mendeteksi penyakit atau kondisi tidak sehat yang membutuhkan perawatan pada tumbuhan dan hewan.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Karir:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Orang yang cerdas alam paling cocok untuk pekerjaan yang melibatkan belajar, mengasuh, dan menghubungkan informasi tentang alam sekitar mereka. Mereka memiliki kepekaan yang luar biasa terhadap alam. Mereka juga pandai menanam tanaman atau merawat, melatih dan berinteraksi dengan hewan. Mereka dapat memahami dan menjelaskan perubahan dan pengaruh lingkungan.
                                        <br/><br/>
                                        Contoh pekerjaan yang mengandalkan kecerdasan ini adalah Dokter Hewan, Cendekiawan Hewan, Arkeolog, Cendekiawan Tumbuhan, Koki, Perancang Halaman, Peneliti Cuaca, Produser Dokumenter, Peneliti Lingkungan, dan Aktivis Perlindungan Hewan.
                                        </p>
                                    </div>
                                </div>

                                <div style={{marginBottom: '75px'}}>
                                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                                        <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 40px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Existential</p>
                                    </div>

                                    <div style={{display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '30px'}}>
                                        <img src={existential} style={{width: '80px'}} alt=''/>
                                        <img src={single} style={{width: '250px'}} alt=''/>
                                        <div style={{display: 'flex'}}>
                                            <div>
                                            <p style={{margin: '0px', fontSize: '24px', fontWeight: '700', color: '#05C38A'}}>{data.existential}%</p>
                                            <p style={{margin : '0px', fontSize: '14px'}}> Existential</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Deskripsi:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Kecerdasan ini memiliki kemampuan untuk kepekaan atau memiliki kapasitas untuk membuat konsep atau menangani pertanyaan yang lebih dalam atau lebih besar tentang keberadaan manusia, seperti makna hidup, mengapa kita dilahirkan, mengapa kita mati, apa itu kesadaran atau bagaimana kita bisa sampai di sini.
                                        <br/><br/>
                                        Kecerdasan ini biasanya memiliki kesadaran refleksi dan transformasi diri, baik sebagai individu maupun sebagai spesies. Dapat menemukan peran individu di alam semesta, menemukan nilai dan tujuan dalam hidup mereka, menginspirasi orang untuk mengajukan pertanyaan mendalam yang berkontribusi pada kebahagiaan dan kesejahteraan mental mereka.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Cara Belajar:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Cara belajar yang digunakan biasanya dengan membawa konten dan materi pembelajaran yang menekankan pada semua renungan eksistensial yang telah digeluti oleh banyak pemikir cemerlang sepanjang sejarah: Siapakah kita? Dari mana kita berasal? Kemana kita akan pergi dari sini? Selain itu juga dapat melakukan pembelajaran dengan diskusi dan filosofis.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Pengembangan Diri:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Cara meningkatkan kecerdasan Eksistensial adalah dengan melakukan tanya-jawab atau diskusi berulang kali,  membaca buku yang mengeksplorasi topik-topik terkait kemudian membahasnya. Berkontemplasi atau meditasi untuk mengakses pengetahuan mendalam tentang berbagai hal, melakukan perawatan tubuh melalui nutrisi yang sehat dan olahraga sebagai wadah sementara bagi jiwa manusia, memeriksa masalah yang sudah ada dan mengeksplorasi kemungkinan dan kebenaran baru, latihan rohani, dan memupuk ikatan solidaritas yang ada di antara semua.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Keterampilan:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Kemampuan yang dihasilkan oleh kecerdasan Eksistensial adalah kemampuan untuk melihat melampaui akal untuk menjelaskan fenomena, minat yang kuat pada masyarakat dan orang-orang di sekitarnya, kemampuan untuk secara kohesif meringkas apa yang telah didiskusikan, kemampuan untuk melihat bagaimana sesuatu berhubungan dengan gambaran besar, dan daya tanggap terhadap sifat-sifat manusia yang tidak berwujud, baik itu menanggapi seni, kebajikan filosofis, atau ajaran agama.
                                        </p>
                                    </div>

                                    <div>
                                        <p style={{fontSize: '14px', fontWeight: '700', margin: '0px'}}>Karir:</p>
                                        <p style={{fontSize: '14px', textAlign: 'justify'}}>
                                        Orang yang cerdas kebermaknaan paling cocok untuk melakukan pekerjaan yang berhubungan dengan menghargai dan menganggap keberadaan sesuatu, memiliki minat dan kemampuan dalam berpikir secara besar dan filosofis.
                                        <br/><br/>
                                        Contoh pekerjaan yang mengandalkan kecerdasan ini adalah Filantropi, Filsuf, Teolog, Ilmuwan, Pekerja Sosial, Motivator, Pekerja Sumber Daya Manusia, dan Psikiater.
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='kecerdasanmajemukMobile'>
                            <div className='kecerdasanmajemukMobileList'>
                                <div className='kecerdasanmajemukMobileItem'>
                                    <p className='judul'>Intrapersonal</p>
                                    <Progress className='progress-xl animated-progess progress-label width300'
                                        value={data["8_intra"]} 
                                        color="red"
                                    >                      
                                        <div className='label' style={{right: '-70px'}}>{data["8_intra"]} %</div>
                                    </Progress>
                                    <p className='keterangan'>Kemampuan berekspresi secara verbal dan non-verbal dengan lancar.</p>
                                </div>
                                <div className='kecerdasanmajemukMobileItem'>
                                    <p className='judul'>Interpersonal</p>
                                    <Progress className='progress-xl animated-progess progress-label width300'
                                        value={data["8_intra"] } 
                                        color="orange"
                                    >                      
                                        <div className='label' style={{right: '-70px'}}>{data["8_inter"]}%</div>
                                    </Progress>
                                    <p className='keterangan'>Kemampuan untuk memahami orang lain.</p>
                                </div>
                                <div className='kecerdasanmajemukMobileItem'>
                                    <p className='judul'>Logical</p>
                                    <Progress className='progress-xl animated-progess progress-label width300'
                                        value={data["8_logical"] } 
                                        color="yellow"
                                    >                      
                                        <div className='label' style={{right: '-70px'}}>{data["8_logical"]}%</div>
                                    </Progress>
                                    <p className='keterangan'>Kemampuan untuk bekerja dengan angka dan pemahaman logis.</p>
                                </div>
                                <div className='kecerdasanmajemukMobileItem'>
                                    <p className='judul'>Visual</p>
                                    <Progress className='progress-xl animated-progess progress-label width300'
                                        value={data["8_visual"] } 
                                        color="green"
                                    >                      
                                        <div className='label' style={{right: '-70px'}}>{data["8_visual"]}%</div>
                                    </Progress>
                                    <p className='keterangan'>Kemampuan untuk memahami sesuatu dengan melihat atau membayangkan.</p>
                                </div>
                                <div className='kecerdasanmajemukMobileItem'>
                                    <p className='judul'>Kinesthetic</p>
                                    <Progress className='progress-xl animated-progess progress-label width300'
                                        value={data["8_bodily_kinesthetic"] } 
                                        color="teal"
                                    >                      
                                        <div className='label' style={{right: '-70px'}}>{data["8_bodily_kinesthetic"]}%</div>
                                    </Progress>
                                    <p className='keterangan'>Kemampuan untuk beraktivitas dengan kekuatan tubuh dan tangan.</p>
                                </div>
                                <div className='kecerdasanmajemukMobileItem'>
                                    <p className='judul'>Linguistik</p>
                                    <Progress className='progress-xl animated-progess progress-label width300'
                                        value={data["8_linguistic"] } 
                                        color="sky"
                                    >                      
                                        <div className='label' style={{right: '-70px'}}>{data["8_linguistic"]}%</div>
                                    </Progress>
                                    <p className='keterangan'>Kemampuan berekspresi secara verbal dan non-verbal dengan lancar.</p>
                                </div>
                                <div className='kecerdasanmajemukMobileItem'>
                                    <p className='judul'>Musical</p>
                                    <Progress className='progress-xl animated-progess progress-label width300'
                                        value={data["8_musical"] } 
                                        color="indigo"
                                    >                      
                                        <div className='label' style={{right: '-70px'}}>{data["8_musical"]}%</div>
                                    </Progress>
                                    <p className='keterangan'>Kemampuan untuk memahami suara serta kompatibiltas dengan emosi dan ekspresi</p>
                                </div>
                                <div className='kecerdasanmajemukMobileItem'>
                                    <p className='judul'>Naturalist</p>
                                    <Progress className='progress-xl animated-progess progress-label width300'
                                        value={data["8_naturalist"] } 
                                        color="purple"
                                    >                      
                                        <div className='label' style={{right: '-70px'}}>{data["8_naturalist"]}%</div>
                                    </Progress>
                                    <p className='keterangan'>Kemampuan untuk memahami suara, serta kompatibilitas dengan emosi dan ekspresi</p>
                                </div>
                                <div className='kecerdasanmajemukMobileItem'>
                                    <p className='judul'>Existential</p>
                                    <Progress className='progress-xl animated-progess progress-label width300'
                                        value={data.existential } 
                                        color="fuchsia"
                                    >                      
                                        <div className='label' style={{right: '-70px'}}>{data.existential}%</div>
                                    </Progress>
                                    <p className='keterangan'>Kemampuan untuk memahami suara, serta kompatibilitas dengan emosi dan ekspresi</p>
                                </div>

                            </div>

                        </div>


                    </div>

                    <div style={{display: 'flex', justifyContent:'space-between', paddingTop: '32px'}}>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report-mobiles/penjurusan", state: { data: data, package_id: packages, params: token }}} onClick={() => {document.body.scrollIntoView({behavior: "smooth"})}}>{"<"} Pemilihan Jurusan</Link>
                        <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report-mobiles/ekstrakulikuler", state: { data: data, package_id: packages, params: token }}} onClick={() => {document.body.scrollIntoView({behavior: "smooth"})}}>Ekstrakulikuler {">"}</Link>
                    </div>
                    </>
                ) : (
                    <>
                        <Link style={{marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to={`/report-mobile/${token}`}>Back</Link>
                        <Lottie options={{loop: true,autoplay: true,animationData: accessDenied,rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} isClickToPauseDisabled={true} />
                        <p className='upgradePlease'> Maaf, Anda belum melakukan Upgrade yang lebih tinggi</p>
                    </>
                )}


            </div>
        </React.Fragment>
    )

}

export default KecerdasanMajemuk;
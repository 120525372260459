import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { useInterval } from "usehooks-ts";
import axios from "axios";

import Lottie from "react-lottie";
import accessDenied from "../../../../assets/images/FutureLink/accessDenied.json";

import { decodeToken } from "react-jwt";

import no1 from "../../../../assets/images/FutureLink/no1.png";
import no2 from "../../../../assets/images/FutureLink/no2.png";
import no3 from "../../../../assets/images/FutureLink/no3.png";
import no4 from "../../../../assets/images/FutureLink/no4.png";
import no5 from "../../../../assets/images/FutureLink/no5.png";
import no6 from "../../../../assets/images/FutureLink/no6.png";
import no7 from "../../../../assets/images/FutureLink/no7.png";
import no8 from "../../../../assets/images/FutureLink/no8.png";
import no9 from "../../../../assets/images/FutureLink/no9.png";
import no10 from "../../../../assets/images/FutureLink/no10.png";
import no11 from "../../../../assets/images/FutureLink/no11.png";
import no12 from "../../../../assets/images/FutureLink/no12.png";
import no13 from "../../../../assets/images/FutureLink/no13.png";
import no14 from "../../../../assets/images/FutureLink/no14.png";
import no15 from "../../../../assets/images/FutureLink/no15.png";
import no16 from "../../../../assets/images/FutureLink/no16.png";
import no17 from "../../../../assets/images/FutureLink/no17.png";
import no23 from "../../../../assets/images/FutureLink/no23.png";

const ReportFPClient = (e) => {
  console.log("report FP Client", e)
  if(e.state === undefined){
    window.location.href = "/"
  }
  const data = e.state.data
  const packages = e.state.package_id
  const img_finger = e.state.img_finger
  const shape_finger = e.state.shape_finger
  const pdf = e.state.pdf

  const listKemampuanUmum = packages.toString().includes("33") ? [
    {
      gambar: no1,
      ket: 'Hasil Pola Sidik Jari',
      path: "/client/report/hasilsidikjari/polasidikjari",
    },
    {
      gambar: no2,
      ket: 'Distribusi Neuron',
      path: "/client/report/hasilsidikjari/distribusineuron",
    },
    {
      gambar: no3,
      ket: 'Total Finger Ridge Count',
      path: "/client/report/hasilsidikjari/tfrc",
    },
    {
      gambar: no8,
      ket: 'Lobus Otak',
      path: "/client/report/hasilsidikjari/lobusotak"
    },
    {
      gambar: no10,
      ket: 'Indra Dasar',
      path: "/client/report/hasilsidikjari/indradasar",
    },

  ] : packages.toString().includes("22")? [
    {
      gambar: no1,
      ket: 'Hasil Pola Sidik Jari',
      path: "/client/report/hasilsidikjari/polasidikjari",
    },
    {
      gambar: no2,
      ket: 'Distribusi Neuron',
      path: "/client/report/hasilsidikjari/distribusineuron",
    },
  ] : [
    {
      gambar: no1,
      ket: 'Hasil Pola Sidik Jari',
      path: "/client/report/hasilsidikjari/polasidikjari",
    },
    {
      gambar: no2,
      ket: 'Distribusi Neuron',
      path: "/client/report/hasilsidikjari/distribusineuron",
    },
  ]
  const listKemampuanPenunjangAkademik = packages.toString().includes("33") ? [
    {
      gambar: no4,
      ket: 'Dominasi Otak',
      path: "/client/report/hasilsidikjari/dominasiotak",
    },
    {
      gambar: no5,
      ket: 'Gaya Belajar',
      path: "/client/report/hasilsidikjari/gayabelajar",
    },
    {
      gambar: no9,
      ket: 'Kecerdasan',
      path: "/client/report/hasilsidikjari/kecerdasan",
    },
    {
      gambar: no14,
      ket: 'Domain Kecerdasan',
      path: "/client/report/hasilsidikjari/domainkecerdasan",
    },
    {
      gambar: no7,
      ket: 'Pemilihan Jurusan',
      path: "/client/report/hasilsidikjari/pemilihanjurusan",
    },
  ] : packages.toString().includes("22") ? [
    {
      gambar: no4,
      ket: 'Dominasi Otak',
      path: "/client/report/hasilsidikjari/dominasiotak",
    },
    {
      gambar: no5,
      ket: 'Gaya Belajar',
      path: "/client/report/hasilsidikjari/gayabelajar",
    },
    {
      gambar: no9,
      ket: 'Kecerdasan',
      path: "/client/report/hasilsidikjari/kecerdasan",
    },
    {
      gambar: no14,
      ket: 'Domain Kecerdasan',
      path: "/client/report/hasilsidikjari/domainkecerdasan",
    },
    {
      gambar: no7,
      ket: 'Pemilihan Jurusan',
      path: "/client/report/hasilsidikjari/pemilihanjurusan",
    },
  ] : [
    {
      gambar: no4,
      ket: 'Dominasi Otak',
      path: "/client/report/hasilsidikjari/dominasiotak",
    },
    {
      gambar: no5,
      ket: 'Gaya Belajar',
      path: "/client/report/hasilsidikjari/gayabelajar",
    },
  ]
  const listKemampuanPenunjangMinatBakat = packages.toString().includes("33") ? [
    {
      gambar: no13,
      ket: 'Kecerdasan Majemuk',
      path: "/client/report/hasilsidikjari/kecerdasanmajemuk",
    },
    {
      gambar: no6,
      ket: 'Ekstrakulikuler',
      path: "/client/report/hasilsidikjari/ekstrakulikuler",
    },
    {
      gambar: no12,
      ket: 'Minat Studi (RIASEC)',
      path: "/client/report/hasilsidikjari/minatstudi",
    },
  ] : packages.toString().includes("22") ? [
    {
      gambar: no6,
      ket: 'Ekstrakulikuler',
      path: "/client/report/hasilsidikjari/ekstrakulikuler",
    },
  ] : [
    {
      gambar: no6,
      ket: 'Ekstrakulikuler',
      path: "/client/report/hasilsidikjari/ekstrakulikuler",
    },
  ]
  const listKemampuanPenunjangKepribadian = [
    {
      gambar: no15,
      ket: 'DISC',
      path: "/client/report/hasilsidikjari/disc",
    },
    {
      gambar: no16,
      ket: 'DOPE',
      path: "/client/report/hasilsidikjari/dope",
    },
    {
      gambar: no17,
      ket: 'Perbandingan Konsep Pemikiran',
      path: "/client/report/hasilsidikjari/konseppemikiran",
    },
    {
      gambar: no11,
      ket: 'Gaya Berpikir',
      path: "/client/report/hasilsidikjari/gayaberpikir",
    },
  ]

  return (
    <Row className="corev2 repCen">

      <div className="reportList">

        <div className="reportClassification">
          <div className="judul">
            <p>Kemampuan Umum</p>
            <div className="merah"></div>
          </div>

          <div className="list">
            {listKemampuanUmum.map((item, index) => (
            <Link key={index} className="reportItem" to={{ pathname: item.path, state: { data: data, package_id: packages, img_finger: img_finger,shape_finger: shape_finger } }} style={{ backgroundColor: "white", border: "grey", minHeight: '120px', justifyContent: 'space-evenly' }}>
              <img src={item.gambar} style={{ width: "60px" }} />
              <p style={{ textAlign: "center", margin: '0px' }}>{item.ket}</p>
            </Link>
            ))}
          </div>
        </div>

        <div className="reportClassification">
          <div className="judul">
            <p>Kemampuan Penunjang Akademik</p>
            <div className="hijau"></div>
          </div>

          <div className="list">
            {listKemampuanPenunjangAkademik.map((item, index) => (
            <Link key={index} className="reportItem" to={{ pathname: item.path, state: { data: data, package_id: packages, img_finger: img_finger,shape_finger: shape_finger } }} style={{ backgroundColor: "white", border: "grey", minHeight: '120px', justifyContent: 'space-evenly' }}>
              <img src={item.gambar} style={{ width: "60px" }} />
              <p style={{ textAlign: "center", margin: '0px' }}>{item.ket}</p>
            </Link>
            ))}
          </div>
        </div>
        <div className="reportClassification">
          <div className="judul">
            <p>Kemampuan Penunjang Minat & Bakat</p>
            <div className="ungu"></div>
          </div>

          <div className="list">
            {listKemampuanPenunjangMinatBakat.map((item, index) => (
            <Link key={index} className="reportItem" to={{ pathname: item.path, state: { data: data, package_id: packages, img_finger: img_finger,shape_finger: shape_finger } }} style={{ backgroundColor: "white", border: "grey", minHeight: '120px', justifyContent: 'space-evenly' }}>
              <img src={item.gambar} style={{ width: "60px" }} />
              <p style={{ textAlign: "center", margin: '0px' }}>{item.ket}</p>
            </Link>
            ))}
          </div>
        </div>

        {packages === 33 && (
        <div className="reportClassification">
          <div className="judul">
            <p>Kemampuan Penunjang Kepribadian</p>
            <div className="ungu"></div>
          </div>

          <div className="list">
            {listKemampuanPenunjangKepribadian.map((item, index) => (
            <Link key={index} className="reportItem" to={{ pathname: item.path, state: { data: data, package_id: packages, img_finger: img_finger,shape_finger: shape_finger } }} style={{ backgroundColor: "white", border: "grey", minHeight: '120px', justifyContent: 'space-evenly' }}>
              <img src={item.gambar} style={{ width: "60px" }} />
              <p style={{ textAlign: "center", margin: '0px' }}>{item.ket}</p>
            </Link>
            ))}
          </div>
        </div>
        )}

      </div>
      <div className="reportListMobile">
        <a href={pdf} className="buttonVersFutureLink btnRadius" style={{textAlign: 'center'}}>Download Report PDF</a>
        
        {/* {listReport.map((item, index) => (
          <Link key={index} className="reportItemMo" to={{ pathname: item.path, state: { data: data, package_id: packages, img_finger: img_finger,shape_finger: shape_finger } }}>
            <img src={item.gambar} style={{ width: "60px" }} />
            <p style={{margin: '0px' }}>{item.ket}</p>
          </Link>
        ))} */}

        <div className="reportClassification">
          <div className="judul">
            <p>Kemampuan Umum</p>
            <div className="merah"></div>
          </div>

          <div className="list">
            {listKemampuanUmum.map((item, index) => (
            <Link key={index} className="reportItemMo" to={{ pathname: item.path, state: { data: data, package_id: packages, img_finger: img_finger,shape_finger: shape_finger } }}>
              <img src={item.gambar} style={{ width: "60px" }} />
              <p style={{margin: '0px' }}>{item.ket}</p>
            </Link>
            ))}
          </div>
        </div>

        <div className="reportClassification">
          <div className="judul">
            <p>Kemampuan Penunjang Akademik</p>
            <div className="hijau"></div>
          </div>

          <div className="list">
            {listKemampuanPenunjangAkademik.map((item, index) => (
            <Link key={index} className="reportItemMo" to={{ pathname: item.path, state: { data: data, package_id: packages, img_finger: img_finger,shape_finger: shape_finger } }}>
              <img src={item.gambar} style={{ width: "60px" }} />
              <p style={{margin: '0px' }}>{item.ket}</p>
            </Link>
            ))}
          </div>
        </div>
        <div className="reportClassification">
          <div className="judul">
            <p>Kemampuan Penunjang Minat & Bakat</p>
            <div className="ungu"></div>
          </div>

          <div className="list">
            {listKemampuanPenunjangMinatBakat.map((item, index) => (
            <Link key={index} className="reportItemMo" to={{ pathname: item.path, state: { data: data, package_id: packages, img_finger: img_finger,shape_finger: shape_finger } }}>
              <img src={item.gambar} style={{ width: "60px" }} />
              <p style={{margin: '0px' }}>{item.ket}</p>
            </Link>
            ))}
          </div>
        </div>

        {packages === 33 && (
        <div className="reportClassification">
          <div className="judul">
            <p>Kemampuan Penunjang Kepribadian</p>
            <div className="ungu"></div>
          </div>

          <div className="list">
            {listKemampuanPenunjangKepribadian.map((item, index) => (
            <Link key={index} className="reportItemMo" to={{ pathname: item.path, state: { data: data, package_id: packages, img_finger: img_finger,shape_finger: shape_finger } }}>
              <img src={item.gambar} style={{ width: "60px" }} />
              <p style={{margin: '0px' }}>{item.ket}</p>
            </Link>
            ))}
          </div>
        </div>
        )}

      </div>
    </Row>
  );
};

//};

export default ReportFPClient;

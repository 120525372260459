import React, { useState, useEffect } from 'react';
import {Row, Col, Modal, ModalBody, ModalFooter} from 'reactstrap';
import CountUp from "react-countup";

import axios from 'axios';

import accessDenied from '../../../assets/images/FutureLink/accessDenied.png'

const Dashboard = () => {
    const [modalAccessDenied, setModalAccessDenied] = useState(false);
    const logOut = () => {
        sessionStorage.removeItem('user');
        window.location.href = '/login'
    }

    const code = sessionStorage.getItem('user');
    const [usersGroup, setUsersGroup] = useState([]);

    useEffect(() => {
        getAllPrivilege();
    }, [])

    const getAllPrivilege = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-privilege`,
            headers: {
                'Authorization': `Bearer ${code}`
            }
        };
  
        axios.request(config)
        .then((response) => {
            // console.log(response)
            if(response.statusCode === 401) {
                setModalAccessDenied(true)
            }else{
                setUsersGroup(response.results)
            }
        })
        .catch((error) => {
            // console.log(error.response)
            if(error.response.data.statusCode === 403) {
                setModalAccessDenied(true)
            }
        })
    }



    return ( 
        <React.Fragment>
            <div className="page-content">
            <Row className='row-users-group groupDashboard'style={{padding: '0px 25px'}}>
                {usersGroup.map((item, index) => (
                    <>
                    <Col key={index} xs={3} className="col-users-group" style={{width: '250px', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '30px 40px'}}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <p style={{ fontSize: "18px", fontWeight: "500", color: "#374151" }}>
                                {item.name}
                            </p>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                            <h1 style={{ margin: "0", color: "#2A4365", fontSize: "40px" }}>
                                <CountUp start={0} end={item.count} duration={2}/>
                            </h1>
                        </div>
                    </Col>
                    </>
                ))}

            </Row>

            <Modal isOpen={modalAccessDenied} toggle={() => {}} centered>
                <ModalBody className='modalCenter'> 
                    <img src={accessDenied} style={{width: '30%'}}/>
                    <p style={{fontSize: '24px', fontWeight: '700', marginTop: '20px'}}>Access Denied</p>
                    <p style={{margin: '0px', color: 'grey'}}>You currently does not have access to this page. <br/> Please Login Again</p>
                </ModalBody>
                <ModalFooter style={{display: 'flex', justifyContent: 'center', gap: '80px'}}>
                    <button className='btn buttonVersFutureLink' style={{fontSize: '14px', fontWeight: '500'}} onClick={() => {logOut()}}>Login Again</button>
                </ModalFooter>
            </Modal>
            </div>


        </React.Fragment>
    );
};

export default Dashboard;
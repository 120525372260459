import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody, ModalFooter, Row, Label, Input, Alert } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import axios from 'axios'

import Lottie from 'react-lottie';
import animateSuccess from '../../../assets/images/FutureLink/success.json'

import deleteData from '../../../assets/images/FutureLink/deleteData.png';
import accessDenied from '../../../assets/images/FutureLink/accessDenied.png';

const ModulAnnotator = () => {
    const [modalAccessDenied, setModalAccessDenied] = useState(false);
    const logOut = () => {
      sessionStorage.removeItem('user');
      window.location.href = '/login'
    }

    const [show, setShow] = useState(false);

    const code = sessionStorage.getItem('user');

    const [kata, setKata] = useState('')

    const [fade, setFade] = useState('fadeout');
    const [alert, setAlert] = useState(true);
    const [kontenError, setKontenError] = useState("Check Again");

    const [modalAdd, setModalAdd] = useState(false);
    const [modalUpdate, setModalUpdate] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [modalAlertSuccess, setModalAlertSuccess] = useState(false);    

    const [annotator, setAnnotator] = useState([]);
    
    const [annotatorId, setAnnotatorId] = useState("");

    const [firstName, setFirstName] = useState("");
    const [surName, setSurName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confPassword, setConfPassword] = useState("");
    const [dateBirthday, setDateBirthday] = useState("");
    const [gender, setGender] = useState("");

    useEffect(() => {
        getAllAnnotator();
    },[])

    const getAllAnnotator = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-user`,
            headers: { 
                'Authorization': `Bearer ${code}`
            }
        };
          
        axios.request(config)
            .then((response) => {
            // console.log(response.results.filter((item) => item.privilege_id === 59));
            setAnnotator(response.results.filter((item) => item.privilege_id === 59))
            })
            .catch((error) => {
            // console.log(error);
            if(error.response.data.statusCode === 403) {
                setModalAccessDenied(true)
            }
            });
    }

    const addAnnotator = () => {

        if (email === "" || firstName === "" ||surName === "" || password === "" || gender === "") {
            setAlert(false);
            setKontenError("Field cannot be empty")
  
            setTimeout(() => {
                setFade('fade')
            }, 200)
    
            setTimeout(() => {
                setFade('fadeout');
            }, 4500)
    
            setTimeout(() => {
                setAlert(true);
                setKontenError("Check Again")
            }, 5000);
          } else if (email.includes(".") === false || email.includes("@") === false ) {
            setAlert(false);
            setKontenError("Email not found")
  
            setTimeout(() => {
                setFade('fade')
            }, 200)
    
            setTimeout(() => {
                setFade('fadeout');
            }, 4500)
    
            setTimeout(() => {
                setAlert(true);
                setKontenError("Check Again")
            }, 5000);
    
          } else {

            if(password !== confPassword) {
                setAlert(false);
                setKontenError("Password not match")
        
                setTimeout(() => {
                    setFade('fade')
                }, 200)
        
                setTimeout(() => {
                    setFade('fadeout');
                }, 4500)
        
                setTimeout(() => {
                    setAlert(true);
                    setKontenError("Check Again");
                }, 5000);
            } else {
        
              let data = new FormData();
              data.append('firstName', firstName);
              data.append('lastName', surName);
              data.append('phone', phone);
              data.append('email', email);
              data.append('password', password);
              if(dateBirthday !== ""){
                data.append('dateBirth', dateBirthday);
              }
              data.append('sex', gender);
              data.append('privilegeId', "59");
        
              let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/make-custom-user`,
                headers: { 
                  'Authorization': `Bearer ${code}`, 
                },
                data : data
              };
        
              axios(config)
              .then((response) => {
                // console.log(response);
                setModalAdd(false);
                getAllAnnotator();
                setModalAlertSuccess(true)
                setTimeout(() => {
                  setModalAlertSuccess(false)
                }, 4000)
                // setModalAdd(false)
                // getAllUserPrivilege(idPrivilege)
                // setModalAlertSuccess(true)
                // setTimeout(() => {
                //   setModalAlertSuccess(false)
                // }, 4000)
              })
              .catch((error) => {
                // console.log(error);
                if(error.response.data.statusCode === 403) {
                    setModalAccessDenied(true)
                } else if (error.response.data.statusCode === 422) {
                    setAlert(false);
                    setKontenError("Im Sorry, Format Wrong")
          
                    setTimeout(() => {
                        setFade('fade')
                    }, 200)
            
                    setTimeout(() => {
                        setFade('fadeout');
                    }, 4500)
            
                    setTimeout(() => {
                        setAlert(true);
                        setKontenError("Check Again")
                    }, 5000);
                } else {
                    if(error.response.data.statusCode === 500) {
                      setAlert(false);
                      setKontenError("Email is already registered")
            
                      setTimeout(() => {
                          setFade('fade')
                      }, 200)
              
                      setTimeout(() => {
                          setFade('fadeout');
                      }, 4500)
              
                      setTimeout(() => {
                          setAlert(true);
                          setKontenError("Check Again")
                      }, 5000);
                    } else {
                      setAlert(false);
          
                      setTimeout(() => {
                          setFade('fade')
                      }, 200)
              
                      setTimeout(() => {
                          setFade('fadeout');
                      }, 4500)
              
                      setTimeout(() => {
                          setAlert(true);
                          setKontenError("Check Again")
                      }, 5000);
                    }
                }

              });
    
            }
    
          }

    }

    const updateAnnotator = () => {

        if (email === "" || firstName === "" ||surName === ""|| gender === "" ) {
            setAlert(false);
            setKontenError("Field cannot be empty")
  
            setTimeout(() => {
                setFade('fade')
            }, 200)
    
            setTimeout(() => {
                setFade('fadeout');
            }, 4500)
    
            setTimeout(() => {
                setAlert(true);
                setKontenError("Check Again")
            }, 5000);
        } else if (email.includes(".") === false || email.includes("@") === false) {
            setAlert(false);
            setKontenError("Email not found")
  
            setTimeout(() => {
                setFade('fade')
            }, 200)
    
            setTimeout(() => {
                setFade('fadeout');
            }, 4500)
    
            setTimeout(() => {
                setAlert(true);
                setKontenError("Check Again")
            }, 5000);
    
        } else {
            let data = new FormData();
            data.append('userId', annotatorId);
            data.append('firstName', firstName);
            data.append('lastName', surName);
            data.append('phone', phone);
            data.append('email', email);
            if(dateBirthday !== "") {
                data.append('dateBirth', dateBirthday);
            }
            data.append('sex', gender);

        
            let config = {
                method: 'patch',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/update-user`,
                headers: { 
                  'Authorization': `Bearer ${code}`, 
                },
                data : data
            };
        
            axios(config)
                .then((response) => {
                // console.log(response);
                setModalUpdate(false);
                getAllAnnotator();

                setModalAlertSuccess(true)
                setTimeout(() => {
                  setModalAlertSuccess(false)
                }, 4000)
              })
              .catch((error) => {
                // console.log(error);

                if(error.response.data.statusCode === 403) {
                    setModalAccessDenied(true)
                } else if (error.response.data.statusCode === 422) {
                    setAlert(false);
                    setKontenError("Im Sorry, Format Wrong")
          
                    setTimeout(() => {
                        setFade('fade')
                    }, 200)
            
                    setTimeout(() => {
                        setFade('fadeout');
                    }, 4500)
            
                    setTimeout(() => {
                        setAlert(true);
                        setKontenError("Check Again")
                    }, 5000);
                } else {
                    if(error.response.data.statusCode === 500) {
                      setAlert(false);
                      setKontenError("Email is already registered")
            
                      setTimeout(() => {
                          setFade('fade')
                      }, 200)
              
                      setTimeout(() => {
                          setFade('fadeout');
                      }, 4500)
              
                      setTimeout(() => {
                          setAlert(true);
                          setKontenError("Check Again")
                      }, 5000);
                    } else {
                      setAlert(false);
          
                      setTimeout(() => {
                          setFade('fade')
                      }, 200)
              
                      setTimeout(() => {
                          setFade('fadeout');
                      }, 4500)
              
                      setTimeout(() => {
                          setAlert(true);
                          setKontenError("Check Again")
                      }, 5000);
                    }
                }

              });
          }

    }

    const deleteAnnotator = () => {
        let config = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/delete-user?userId=${annotatorId}`,
            headers: { 
                'Authorization': `Bearer ${code}`
            }
        };
        
        axios.request(config)
        .then((response) => {
          // console.log(response);
            setModalDelete(false)
            getAllAnnotator()  
        })
        .catch((error) => {
            // console.log(error);

            if(error.response.data.statusCode === 403) {
                setModalAccessDenied(true)
            }
        });
    }


    return ( 
        <React.Fragment>
            <div className="page-content">
            <Container fluid>
            <Row>
                <Col lg={12}>

                    <Card>
                        <CardHeader>
                            <h4 className="card-title mb-0">Annotator Table</h4>
                        </CardHeader>

                        <CardBody>
                            <div id="customerList">
                                <Row className="g-4 mb-3">
                                    <Col className="col-sm-auto">
                                        <div>
                                            <button className="btn buttonVersFutureLinkGreen" id="create-btn" onClick={() => {
                                                setModalAdd(true);
                                                setFirstName("");
                                                setSurName("");
                                                setEmail("");
                                                setPhone("");
                                                setGender("");
                                                setDateBirthday("");
                                                setAnnotatorId("");
                                                }}>
                                                <i className="ri-add-line align-bottom me-1"></i> Add
                                            </button>
                                            {/* <Button className="btn btn-soft-danger"><i className="ri-delete-bin-2-line"></i></Button> */}
                                        </div>
                                    </Col>
                                    <Col className="col-sm">
                                        <div className="d-flex justify-content-sm-end">
                                            <div className="search-box ms-2">
                                                <input type="text" className="form-control search" placeholder="Search..." onChange={(e) => setKata(e.target.value)}/>
                                                <i className="ri-search-line search-icon"></i>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <div className="table-responsive table-card mt-3 mb-1">
                                    <table className="table align-middle table-nowrap" id="customerTable">
                                        <thead className="table-light">
                                            <tr>
                                                {/* <th scope="col" style={{ width: "50px" }}>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                    </div>
                                                </th> */}
                                                <th className="sort" data-sort="no">No</th>
                                                <th className="sort" data-sort="nama">Name</th>
                                                <th className="sort" data-sort="email">Email</th>
                                                <th className="sort" data-sort="status">Phone</th>
                                                <th className="sort" data-sort="action">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="list form-check-all">
                                            {annotator.filter((item) => 
                                            item.first_name.toLowerCase().includes(kata.toLocaleLowerCase()) ||
                                            item.last_name.toLowerCase().includes(kata.toLowerCase())
                                            ).map((item, index) => (
                                            <>
                                            <tr key={index}>
                                                <td className="no">{index+1}</td>
                                                <td className="nama">{item.first_name} {item.last_name}</td>
                                                <td className="email">{item.email}</td>
                                                <td className="status">{item.phone === null || item.phone === "" || item.phone === "null"  ? "" : item.phone}</td>
                                                <td>
                                                    <div className="d-flex gap-2">
                                                        <div className="edit">
                                                            <button className="btn buttonVersFutureLinkGreen" 
                                                            onClick={() => {
                                                                setModalUpdate(true);
                                                                setAnnotatorId(item.user_id);
                                                                setFirstName(item.first_name);
                                                                setSurName(item.last_name);
                                                                setEmail(item.email);
                                                                setPhone(item.phone === null || item.phone === "" || item.phone === "null" ? "" : item.phone);
                                                                setGender(item.sex);
                                                                setDateBirthday(item.date_birth === null ? "" : item.date_birth.slice(0, 10));
                                                            }}>Edit</button>
                                                        </div>
                                                        <div className="remove">
                                                            <button className="btn buttonVersFutureLinkRed"  onClick={() => {
                                                                setModalDelete(true); 
                                                                setAnnotatorId(item.user_id);
                                                            }}>Remove</button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            </>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="noresult" style={{ display: "none" }}>
                                        <div className="text-center">
                                            <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                                colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                            </lord-icon>
                                            <h5 className="mt-2">Sorry! No Result Found</h5>
                                            <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                                orders for you search.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-end">
                                    <div className="pagination-wrap hstack gap-2">
                                        <Link className="page-item pagination-prev disabled" to="#">
                                            Previous
                                        </Link>
                                        <ul className="pagination listjs-pagination mb-0"></ul>
                                        <Link className="page-item pagination-next" to="#">
                                            Next
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {/* Add Modal */}
            <Modal isOpen={modalAdd} toggle={() => {setModalAdd(!modalAdd)}} centered>
                <ModalHeader toggle={() => {setModalAdd(false)}}>
                <h4>Add Annotator</h4>
                </ModalHeader>
            
                <ModalBody>
                    <Alert className={'alertRed ' + fade} hidden={alert}>{kontenError}</Alert>
                    <div className="mb-3">
                        <Label htmlFor="name" className="form-label">First Name <span style={{color: 'red'}}>*</span></Label>
                        <Input value={firstName} onChange={(event) => setFirstName(event.target.value)} type="text" className="form-control" id="first_name" placeholder="Enter your name" required/>
                    </div>

                    <div className="mb-3">
                        <Label htmlFor="name" className="form-label">Surname <span style={{color: 'red'}}>*</span></Label>
                        <Input value={surName} onChange={(event) => setSurName(event.target.value)} type="text" className="form-control" id="last_name" placeholder="Enter your name" required/>
                    </div>

                    <div className="mb-3">
                        <Label htmlFor="name" className="form-label">Phone</Label>
                        <Input value={phone} onChange={(event) => setPhone(event.target.value)} type="number" className="form-control" placeholder="Enter your number" required/>
                    </div>

                    <div className="mb-3">
                        <Label htmlFor="email" className="form-label">Email <span style={{color: 'red'}}>*</span></Label>
                        <Input value={email} onChange={(event) => setEmail(event.target.value)} type="text" className="form-control" id="email" placeholder="Enter your email" required/>
                    </div>

                    <div className="mb-3">
                        <Label htmlFor="password" className="form-label">Password <span style={{color: 'red'}}>*</span></Label>
                        <div className="position-relative auth-pass-inputgroup">
                          <Input value={password} onChange={(event) => setPassword(event.target.value)} type={show === false ? "password" : "text"} className="form-control pe-5" placeholder="Enter password" required/>
                          <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" onClick={() => {setShow(!show);}}>
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                        <p style={{color: 'red', fontSize: '12px'}}>Password must contain uppercase, lowercase, numbers, symbols, and be more than 8 characters.</p>
                    </div>

                    <div className="mb-3">
                        <Label htmlFor="confPassword" className="form-label">Confirm Password <span style={{color: 'red'}}>*</span></Label>
                        <div className="position-relative auth-pass-inputgroup">
                          <Input value={confPassword} onChange={(event) => setConfPassword(event.target.value)} type={show === false ? "password" : "text"} className="form-control pe-5" placeholder="Enter password" required/>
                          <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" onClick={() => {setShow(!show);}}>
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="gender-field" className="form-label">Gender <span style={{color: 'red'}}>*</span></label>
                        <select value={gender} onChange={(event) => setGender(event.target.value)} className="form-control" data-trigger name="Gender-field" id="Gender-field" >
                            <option value="">Choose Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="date-field" className="form-label">Date Birthday</label>
                        <Flatpickr
                        className="form-control"
                        options={{
                            dateFormat: "Y-m-d"
                        }}
                        onChange={(a,b,c) => setDateBirthday(b)}
                        placeholder="Select Date"
                    />
                    </div>
                </ModalBody>
                <ModalFooter>
                <button className='btn buttonVersFutureLinkGreen' onClick={() => {addAnnotator()}}>Create Annotator</button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalUpdate} toggle={() => {setModalUpdate(!modalUpdate)}} centered>
                <ModalHeader toggle={() => {setModalUpdate(false)}}>
                    <h4>Edit Annotator</h4>
                </ModalHeader>
            
                <ModalBody>
                    <Alert className={'alertRed ' + fade} hidden={alert}>{kontenError}</Alert>
                    <div className="mb-3">
                        <Label htmlFor="name" className="form-label">First Name <span style={{color: 'red'}}>*</span></Label>
                        <Input value={firstName} onChange={(event) => setFirstName(event.target.value)} type="text" className="form-control" id="first_name" placeholder="Enter your name" required/>
                    </div>

                    <div className="mb-3">
                        <Label htmlFor="name" className="form-label">Surname <span style={{color: 'red'}}>*</span></Label>
                        <Input value={surName} onChange={(event) => setSurName(event.target.value)} type="text" className="form-control" id="last_name" placeholder="Enter your name" required/>
                    </div>

                    <div className="mb-3">
                        <Label htmlFor="name" className="form-label">Phone</Label>
                        <Input value={phone} onChange={(event) => setPhone(event.target.value)} type="number" className="form-control" placeholder="Enter your number" required/>
                    </div>

                    <div className="mb-3">
                        <Label htmlFor="email" className="form-label">Email <span style={{color: 'red'}}>*</span></Label>
                        <Input value={email} onChange={(event) => setEmail(event.target.value)} type="text" className="form-control" id="email" placeholder="Enter your email" required/>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="gender-field" className="form-label">Gender <span style={{color: 'red'}}>*</span></label>
                        <select value={gender} onChange={(event) => setGender(event.target.value)} className="form-control" data-trigger name="Gender-field" id="Gender-field" >
                            <option value="">Choose Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="date-field" className="form-label">Date Birthday</label>
                        <Flatpickr
                        className="form-control"
                        options={{
                            dateFormat: "Y-m-d"
                        }}
                        value={dateBirthday}
                        onChange={(a,b,c) => setDateBirthday(b)}
                        placeholder="Select Date"
                    />
                    </div>
                </ModalBody>
            <ModalFooter>
            <button className='btn buttonVersFutureLinkGreen' onClick={() => {updateAnnotator()}}>Update Annotator</button>
            </ModalFooter>
            </Modal>

            <Modal isOpen={modalAlertSuccess} toggle={() => {setModalAlertSuccess(!modalAlertSuccess)}} centered>
                <ModalBody className='modalCenter' style={{margin: '0px'}}>
                    <Lottie options={{loop: true, autoplay: true,animationData: animateSuccess,rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} height={350} width={350} isClickToPauseDisabled={true} />
                </ModalBody>
            </Modal>

            <Modal isOpen={modalDelete} toggle={() => {setModalDelete(!modalDelete)}} centered>
                <ModalBody className='modalCenter'> 
                    <img src={deleteData}/>
                    <p style={{fontSize: '24px', fontWeight: '700', marginTop: '20px'}}>You are about to delete a user </p>
                    <p style={{margin: '0px', color: 'grey'}}>This we will delete your User from FutureLink <br/> Are you sure ?</p>
                </ModalBody>
                <ModalFooter style={{display: 'flex', justifyContent: 'center', gap: '80px'}}>
                    <button className='btn buttonVersFutureLinkBorderGrey ' onClick={() => {setModalDelete(false)}}>Close</button>
                    <button className='btn buttonVersFutureLinkRed' onClick={() => {deleteAnnotator()}}>Delete</button>
                </ModalFooter>
            </Modal>

            {/* <Modal isOpen={modalDelete} toggle={() => {setModalDelete(!modalDelete)}} centered>
            <ModalBody style={{display: 'flex', justifyContent: 'center', margin: '35px 0px'}}> 
            <img src={deleteData}/>
            </ModalBody>
            <ModalFooter style={{display: 'flex', justifyContent: 'center', gap: '80px'}}>
            <button className='btn buttonVersFutureLinkBorderGrey ' onClick={() => {setModalDelete(false)}}>Close</button>
            <button className='btn buttonVersFutureLinkRed' onClick={() => {setModalDelete(false)}}>Delete</button>
            </ModalFooter>
            </Modal> */}

            </Container>
            </div>
        </React.Fragment>
    );
};

export default ModulAnnotator;
import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Input,
  Label,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";

import axios from "axios";
import FormData from "form-data";

import accessDenied from "../../../assets/images/FutureLink/accessDenied.png";

import KiJempol from "../../../assets/images/FutureLink/KiJempol.png";
import KiTelunjuk from "../../../assets/images/FutureLink/KiTelunjuk.png";
import KiTengah from "../../../assets/images/FutureLink/KiTengah.png";
import KiManis from "../../../assets/images/FutureLink/KiManis.png";
import KiKelingking from "../../../assets/images/FutureLink/KiKelingking.png";
import KaJempol from "../../../assets/images/FutureLink/KaJempol.png";
import KaTelunjuk from "../../../assets/images/FutureLink/KaTelunjuk.png";
import KaTengah from "../../../assets/images/FutureLink/KaTengah.png";
import KaManis from "../../../assets/images/FutureLink/KaManis.png";
import KaKelingking from "../../../assets/images/FutureLink/KaKelingking.png";

import Lottie from "react-lottie";
import loading from "../../../assets/images/FutureLink/loading.json";

const TableUserAccRetakeImage = () => {
  // document.title="Widgets | Velzon - React Admin & Dashboard Template";
  const code = sessionStorage.getItem("user");
  const [modalAccessDenied, setModalAccessDenied] = useState(false);
  const fingerType = [
    {
      name: "Tidak diketahui",
      value: "nothing",
    },
    {
      name: "Whorl Centric",
      value: "W_centric",
    },
    {
      name: "Whorl Spiral",
      value: "W_spiral",
    },
    {
      name: "Whorl Elongated Centric",
      value: "W_elongated_centric",
    },
    {
      name: "Whorl Elongated Spiral",
      value: "W_elongated_spiral",
    },
    {
      name: "Whorl Imploding",
      value: "W_imploding",
    },
    {
      name: "Whorl Composite",
      value: "W_composite",
    },
    {
      name: "Target Centric Ulnar Peacock Eye",
      value: "W_peacock_centric_ulnar",
    },
    {
      name: "Spiral Ulnar Peacock Eye",
      value: "W_peacock_spiral_ulnar",
    },
    {
      name: "Target Centric Radial Peacock Eye",
      value: "W_peacock_centric_radial",
    },
    {
      name: "Spiral Radial Peacock Eye",
      value: "W_peacock_spiral_radial",
    },
    {
      name: "Loop Double",
      value: "L_double",
    },
    {
      name: "Loop Ulnar",
      value: "L_ulnar",
    },
    {
      name: "Loop Radial",
      value: "L_radial",
    },
    {
      name: "Loop Falling Ulnar",
      value: "L_fallen_ulnar",
    },
    {
      name: "Loop Falling Radial",
      value: "L_fallen_radial",
    },
    {
      name: "Arch Simple",
      value: "A_simple",
    },
    {
      name: "Arch Tented",
      value: "A_tented",
    },
    {
      name: "Arch Tented with Ulnar Loop",
      value: "A_w_L_ulnar",
    },
    {
      name: "Arch Tented with Radial Loop",
      value: "A_w_L_radial",
    },
    {
      name: "Accidental Whorl",
      value: "accidental",
    },
  ];
  const [step, setStep] = useState(0);
  const [kata, setKata] = useState("");

  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState("");
  const [imageFingerprint, setImageFingerprint] = useState("");
  const [noted, setNoted] = useState("");
  const [ind, setInd] = useState("");

  const [l1, setL1] = useState("");
  const [l2, setL2] = useState("");
  const [l3, setL3] = useState("");
  const [l4, setL4] = useState("");
  const [l5, setL5] = useState("");
  const [l1Catatan, setL1Catatan] = useState("");
  const [l2Catatan, setL2Catatan] = useState("");
  const [l3Catatan, setL3Catatan] = useState("");
  const [l4Catatan, setL4Catatan] = useState("");
  const [l5Catatan, setL5Catatan] = useState("");
  const [l1Rcs, setL1Rcs] = useState("");
  const [l2Rcs, setL2Rcs] = useState("");
  const [l3Rcs, setL3Rcs] = useState("");
  const [l4Rcs, setL4Rcs] = useState("");
  const [l5Rcs, setL5Rcs] = useState("");
  const [l1Shape, setL1Shape] = useState("");
  const [l2Shape, setL2Shape] = useState("");
  const [l3Shape, setL3Shape] = useState("");
  const [l4Shape, setL4Shape] = useState("");
  const [l5Shape, setL5Shape] = useState("");
  const [l1Status, setL1Status] = useState(false);
  const [l2Status, setL2Status] = useState(false);
  const [l3Status, setL3Status] = useState(false);
  const [l4Status, setL4Status] = useState(false);
  const [l5Status, setL5Status] = useState(false);

  const [r1, setR1] = useState("");
  const [r2, setR2] = useState("");
  const [r3, setR3] = useState("");
  const [r4, setR4] = useState("");
  const [r5, setR5] = useState("");
  const [r1Catatan, setR1Catatan] = useState("");
  const [r2Catatan, setR2Catatan] = useState("");
  const [r3Catatan, setR3Catatan] = useState("");
  const [r4Catatan, setR4Catatan] = useState("");
  const [r5Catatan, setR5Catatan] = useState("");
  const [r1Rcs, setR1Rcs] = useState("");
  const [r2Rcs, setR2Rcs] = useState("");
  const [r3Rcs, setR3Rcs] = useState("");
  const [r4Rcs, setR4Rcs] = useState("");
  const [r5Rcs, setR5Rcs] = useState("");
  const [r1Shape, setR1Shape] = useState("");
  const [r2Shape, setR2Shape] = useState("");
  const [r3Shape, setR3Shape] = useState("");
  const [r4Shape, setR4Shape] = useState("");
  const [r5Shape, setR5Shape] = useState("");
  const [r1Status, setR1Status] = useState(false);
  const [r2Status, setR2Status] = useState(false);
  const [r3Status, setR3Status] = useState(false);
  const [r4Status, setR4Status] = useState(false);
  const [r5Status, setR5Status] = useState(false);
  const [checkLog, setCheckLog] = useState("");

  const [namaLengkap, setNamaLengkap] = useState("");
  const [nama, setNama] = useState("");
  const [namaB, setNamaB] = useState("");
  const [umur, setUmur] = useState("");
  const [gender, setGender] = useState("");
  const [hp, setHp] = useState("");
  const [email, setEmail] = useState("");
  const [instansi, setInstansi] = useState("");

  const [packages, setPackages] = useState([]);
  const [packageId, setPackageId] = useState("");
  const [packageName, setPackageName] = useState("");

  const [uniqueCode, setUniqueCode] = useState("");

  // const [ehe, setEhe] = useState("");

  const [a, setA] = useState("");
  const [b, setB] = useState("");
  const [c, setC] = useState("");
  const [d, setD] = useState("");
  const [e, setE] = useState("");
  const [f, setF] = useState("");
  const [g, setG] = useState("");
  const [h, setH] = useState("");
  const [i, setI] = useState("");
  const [j, setJ] = useState("");

  //modal
  const [modalImage, setModalImage] = useState(false);
  const [modalCatatan, setModalCatatan] = useState(false);
  //end modal

  // Flownya gini... Patternnya buat array dulu... terus di filter sesuai nama pattern dan hitung jumlahnya ada berapa dan dikurangin dengan 10... terus di check apakah sesuai dengan pattern khusus ? jika kena nama pattern khusus dan belum diisi Rcsnya jadi 0, selain itu diparseInt (karena string)...  terus totalRCS / pengurang
  const array = [l1Shape,l2Shape,l3Shape,l4Shape,l5Shape,r1Shape,r2Shape,r3Shape,r4Shape,r5Shape];
  const jumlahArchSimple = array.filter((item) => item === "A_simple").length;
  const jumlahArchTented = array.filter((item) => item === "A_tented").length;
  const jumlahAccidental = array.filter((item) => item === "accidental").length;

  const q1 = l1Shape === "A_simple" || l1Shape === "A_tented" || l1Shape === "accidental" || l1Shape === "" || l1Shape === null || l1Rcs === "" || l1Rcs === null ? 0 : parseInt(l1Rcs);
  const q2 = l2Shape === "A_simple" || l2Shape === "A_tented" || l2Shape === "accidental" || l2Shape === "" || l2Shape === null || l2Rcs === "" || l2Rcs === null ? 0 : parseInt(l2Rcs);
  const q3 = l3Shape === "A_simple" || l3Shape === "A_tented" || l3Shape === "accidental" || l3Shape === "" || l3Shape === null || l3Rcs === "" || l3Rcs === null ? 0 : parseInt(l3Rcs);
  const q4 = l4Shape === "A_simple" || l4Shape === "A_tented" || l4Shape === "accidental" || l4Shape === "" || l4Shape === null || l4Rcs === "" || l4Rcs === null ? 0 : parseInt(l4Rcs);
  const q5 = l5Shape === "A_simple" || l5Shape === "A_tented" || l5Shape === "accidental" || l5Shape === "" || l5Shape === null || l5Rcs === "" || l5Rcs === null ? 0 : parseInt(l5Rcs);
  const w1 = r1Shape === "A_simple" || r1Shape === "A_tented" || r1Shape === "accidental" || r1Shape === "" || r1Shape === null || r1Rcs === "" || r1Rcs === null ? 0 : parseInt(r1Rcs);
  const w2 = r2Shape === "A_simple" || r2Shape === "A_tented" || r2Shape === "accidental" || r2Shape === "" || r2Shape === null || r2Rcs === "" || r2Rcs === null ? 0 : parseInt(r2Rcs);
  const w3 = r3Shape === "A_simple" || r3Shape === "A_tented" || r3Shape === "accidental" || r3Shape === "" || r3Shape === null || r3Rcs === "" || r3Rcs === null ? 0 : parseInt(r3Rcs);
  const w4 = r4Shape === "A_simple" || r4Shape === "A_tented" || r4Shape === "accidental" || r4Shape === "" || r4Shape === null || r4Rcs === "" || r4Rcs === null ? 0 : parseInt(r4Rcs);
  const w5 = r5Shape === "A_simple" || r5Shape === "A_tented" || r5Shape === "accidental" || r5Shape === "" || r5Shape === null || r5Rcs === "" || r5Rcs === null ? 0 : parseInt(r5Rcs);
  const pengurang = 10 - jumlahAccidental - jumlahArchSimple - jumlahArchTented;
  const jumlah = (q1 + q2 + q3 + q4 + q5 + w1 + w2 + w3 + w4 + w5) / pengurang;
  // console.log(array)
  // console.log(l2Rcs)
  // console.log(jumlahArchSimple)
  // console.log(jumlahArchTented)
  // console.log(jumlahAccidental)
  // console.log(q1+q2+q3+q4+q5+w1+w2+w3+w4+w5)
  // console.log(pengurang)
  // console.log(jumlah)

  const animationLoading = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleModalImage = (img) => {
    setModalImage(!modalImage);
    setImageFingerprint(img);
  };

  const handleModalCatatan = (catatan, index) => {
    setModalCatatan(!modalCatatan);
    setNoted(catatan);
    setInd(index);
  };

  const [sorting, setSorting] = useState(false)
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [nothing, setNothing] = useState(false);
  const [orderDate, setOrderDate] = useState('asc');
  const [modalNoData, setModalNoData] = useState(false);
  const [row, setRow] = useState(10)

  useEffect(() => {
    getAllFingerprint();
  }, [page, limit, orderDate]);

  const getAllFingerprint = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-fingerprint?page=${page}&limit=${limit}&orderDate=${orderDate}`,
      headers: {
        Authorization: `Bearer ${code}`,
      },
    };

    axios(config)
    .then((response) => {
        setUsers(response.results);
        setRow(response.results.length)
    })
    .catch((error) => {
        // console.log(error);
        if(error.response.data.statusCode === 403) {
            setModalAccessDenied(true)
        }
        if(error.response.data.statusCode === 404) {
            setModalNoData(true);
            setPage(1)
        }
    });
  };

  const getFingerprintByID = (user_id) => {
    let data = new FormData();

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-by-id?userId=${user_id}`,
      headers: {
        Authorization: `Bearer ${code}`,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        // console.log(response)
        getImage(response.results[0].l1, "l1");
        getImage(response.results[0].l2, "l2");
        getImage(response.results[0].l3, "l3");
        getImage(response.results[0].l4, "l4");
        getImage(response.results[0].l5, "l5");
        getImage(response.results[0].r1, "r1");
        getImage(response.results[0].r2, "r2");
        getImage(response.results[0].r3, "r3");
        getImage(response.results[0].r4, "r4");
        getImage(response.results[0].r5, "r5");

        setL1Catatan(response.results[0].l1_catatan);
        setL2Catatan(response.results[0].l2_catatan);
        setL3Catatan(response.results[0].l3_catatan);
        setL4Catatan(response.results[0].l4_catatan);
        setL5Catatan(response.results[0].l5_catatan);
        setR1Catatan(response.results[0].r1_catatan);
        setR2Catatan(response.results[0].r2_catatan);
        setR3Catatan(response.results[0].r3_catatan);
        setR4Catatan(response.results[0].r4_catatan);
        setR5Catatan(response.results[0].r5_catatan);

        setL1Rcs(response.results[0].l1_rcs);
        setL2Rcs(response.results[0].l2_rcs);
        setL3Rcs(response.results[0].l3_rcs);
        setL4Rcs(response.results[0].l4_rcs);
        setL5Rcs(response.results[0].l5_rcs);
        setR1Rcs(response.results[0].r1_rcs);
        setR2Rcs(response.results[0].r2_rcs);
        setR3Rcs(response.results[0].r3_rcs);
        setR4Rcs(response.results[0].r4_rcs);
        setR5Rcs(response.results[0].r5_rcs);

        setL1Shape(response.results[0].l1_shape);
        setL2Shape(response.results[0].l2_shape);
        setL3Shape(response.results[0].l3_shape);
        setL4Shape(response.results[0].l4_shape);
        setL5Shape(response.results[0].l5_shape);
        setR1Shape(response.results[0].r1_shape);
        setR2Shape(response.results[0].r2_shape);
        setR3Shape(response.results[0].r3_shape);
        setR4Shape(response.results[0].r4_shape);
        setR5Shape(response.results[0].r5_shape);

        setL1Status(response.results[0].l1_status === "valid" ? true : false);
        setL2Status(response.results[0].l2_status === "valid" ? true : false);
        setL3Status(response.results[0].l3_status === "valid" ? true : false);
        setL4Status(response.results[0].l4_status === "valid" ? true : false);
        setL5Status(response.results[0].l5_status === "valid" ? true : false);
        setR1Status(response.results[0].r1_status === "valid" ? true : false);
        setR2Status(response.results[0].r2_status === "valid" ? true : false);
        setR3Status(response.results[0].r3_status === "valid" ? true : false);
        setR4Status(response.results[0].r4_status === "valid" ? true : false);
        setR5Status(response.results[0].r5_status === "valid" ? true : false);

        setCheckLog(response.results[0].checked_log);
      })
      .catch((error) => {
        // console.log(error);
        if (error.response.data.statusCode === 403) {
          setModalAccessDenied(true);
        }
      });
  };

  const getUserByID = (user_id) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-user?userId=${user_id}`,
      headers: {
        Authorization: `Bearer ${code}`,
      },
    };

    axios(config)
      .then((response) => {
        // console.log(response);
        const hariIni = new Date();
        const hariKemaren = new Date(response.results.date_birth);
        const selisih = hariIni - hariKemaren;
        const age = selisih / (1000 * 60 * 60 * 24 * 365);
        const usia = Math.floor(age);

        setNama(response.results.first_name);
        setNamaB(response.results.last_name);
        setUmur(usia);
        setGender(response.results.sex);
        setHp(response.results.phone);
        setEmail(response.results.email);
        setInstansi(response.results.agency);
        setUniqueCode(response.results.unique_code);

        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-by-id?userId=${user_id}`,
          headers: {
            Authorization: `Bearer ${code}`,
          },
        };

        axios(config)
          .then((response) => {
            // console.log(response);

            // setEhe(`${response.results[0].r1, response.results[0].r2, response.results[0].r3, response.results[0].r4, response.results[0].r5, response.results[0].l1, response.results[0].l2, response.results[0].l3, response.results[0].l4, response.results[0].l5}`)

            setA(response.results[0].l1);
            setB(response.results[0].l2);
            setC(response.results[0].l3);
            setD(response.results[0].l4);
            setE(response.results[0].l5);
            setF(response.results[0].r1);
            setG(response.results[0].r2);
            setH(response.results[0].r3);
            setI(response.results[0].r4);
            setJ(response.results[0].r5);

            getImage(response.results[0].l1, "l1");
            getImage(response.results[0].l2, "l2");
            getImage(response.results[0].l3, "l3");
            getImage(response.results[0].l4, "l4");
            getImage(response.results[0].l5, "l5");
            getImage(response.results[0].r1, "r1");
            getImage(response.results[0].r2, "r2");
            getImage(response.results[0].r3, "r3");
            getImage(response.results[0].r4, "r4");
            getImage(response.results[0].r5, "r5");

            setL1Rcs(response.results[0].l1_rcs);
            setL2Rcs(response.results[0].l2_rcs);
            setL3Rcs(response.results[0].l3_rcs);
            setL4Rcs(response.results[0].l4_rcs);
            setL5Rcs(response.results[0].l5_rcs);
            setR1Rcs(response.results[0].r1_rcs);
            setR2Rcs(response.results[0].r2_rcs);
            setR3Rcs(response.results[0].r3_rcs);
            setR4Rcs(response.results[0].r4_rcs);
            setR5Rcs(response.results[0].r5_rcs);

            setL1Shape(response.results[0].l1_shape);
            setL2Shape(response.results[0].l2_shape);
            setL3Shape(response.results[0].l3_shape);
            setL4Shape(response.results[0].l4_shape);
            setL5Shape(response.results[0].l5_shape);
            setR1Shape(response.results[0].r1_shape);
            setR2Shape(response.results[0].r2_shape);
            setR3Shape(response.results[0].r3_shape);
            setR4Shape(response.results[0].r4_shape);
            setR5Shape(response.results[0].r5_shape);
            setStep(2);
          })
          .catch((error) => {
            // console.log(error);

            if (error.response.data.statusCode === 403) {
              setModalAccessDenied(true);
            }
          });
      })
      .catch((error) => {
        // console.log(error);

        if (error.response.data.statusCode === 403) {
          setModalAccessDenied(true);
        }
      });
  };

  const getImage = (imageName, index) => {
    if (index === "l1") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setL1(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "l2") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setL2(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "l3") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setL3(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "l4") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setL4(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "l5") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setL5(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "r1") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setR1(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "r2") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setR2(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "r3") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setR3(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "r4") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setR4(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    if (index === "r5") {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-image?imageName=${imageName}`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response)
          setR5(response.results.image);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  const getAllPackages = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      // url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-packages?status=1`,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-packages`,
      headers: {
        Authorization: `Bearer ${code}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response);
        if (response.statusCode !== 403) {
          // console.log(response);
          setPackages(response.results);
        } else {
          setModalAccessDenied(true);
        }
      })
      .catch((error) => {
        // console.log(error);
        if (error.response.data.statusCode === 403) {
          setModalAccessDenied(true);
        }
      });
  };

  const updateFingerprint = () => {
    let data = new FormData();
    data.append("userId", userId);

    data.append("l1Status", l1Status === true ? "valid" : "not valid");
    data.append(
      "l1Rcs",
      l1Shape === "A_simple" ||
        l1Shape === "A_tented" ||
        l1Shape === "accidental"
        ? jumlah.toFixed(1)
        : l1Rcs
    );
    data.append("l1Shape", l1Shape);
    data.append("l1Catatan", l1Catatan);

    data.append("l2Status", l2Status === true ? "valid" : "not valid");
    data.append(
      "l2Rcs",
      l2Shape === "A_simple" ||
        l2Shape === "A_tented" ||
        l2Shape === "accidental"
        ? jumlah.toFixed(1)
        : l2Rcs
    );
    data.append("l2Shape", l2Shape);
    data.append("l2Catatan", l2Catatan);

    data.append("l3Status", l3Status === true ? "valid" : "not valid");
    data.append(
      "l3Rcs",
      l3Shape === "A_simple" ||
        l3Shape === "A_tented" ||
        l3Shape === "accidental"
        ? jumlah.toFixed(1)
        : l3Rcs
    );
    data.append("l3Shape", l3Shape);
    data.append("l3Catatan", l3Catatan);

    data.append("l4Status", l4Status === true ? "valid" : "not valid");
    data.append(
      "l4Rcs",
      l4Shape === "A_simple" ||
        l4Shape === "A_tented" ||
        l4Shape === "accidental"
        ? jumlah.toFixed(1)
        : l4Rcs
    );
    data.append("l4Shape", l4Shape);
    data.append("l4Catatan", l4Catatan);

    data.append("l5Status", l5Status === true ? "valid" : "not valid");
    data.append(
      "l5Rcs",
      l5Shape === "A_simple" ||
        l5Shape === "A_tented" ||
        l5Shape === "accidental"
        ? jumlah.toFixed(1)
        : l5Rcs
    );
    data.append("l5Shape", l5Shape);
    data.append("l5Catatan", l5Catatan);

    data.append("r1Status", r1Status === true ? "valid" : "not valid");
    data.append(
      "r1Rcs",
      r1Shape === "A_simple" ||
        r1Shape === "A_tented" ||
        r1Shape === "accidental"
        ? jumlah.toFixed(1)
        : r1Rcs
    );
    data.append("r1Shape", r1Shape);
    data.append("r1Catatan", r1Catatan);

    data.append("r2Status", r2Status === true ? "valid" : "not valid");
    data.append(
      "r2Rcs",
      r2Shape === "A_simple" ||
        r2Shape === "A_tented" ||
        r2Shape === "accidental"
        ? jumlah.toFixed(1)
        : r2Rcs
    );
    data.append("r2Shape", r2Shape);
    data.append("r2Catatan", r2Catatan);

    data.append("r3Status", r3Status === true ? "valid" : "not valid");
    data.append(
      "r3Rcs",
      r3Shape === "A_simple" ||
        r3Shape === "A_tented" ||
        r3Shape === "accidental"
        ? jumlah.toFixed(1)
        : r3Rcs
    );
    data.append("r3Shape", r3Shape);
    data.append("r3Catatan", r3Catatan);

    data.append("r4Status", r4Status === true ? "valid" : "not valid");
    data.append(
      "r4Rcs",
      r4Shape === "A_simple" ||
        r4Shape === "A_tented" ||
        r4Shape === "accidental"
        ? jumlah.toFixed(1)
        : r4Rcs
    );
    data.append("r4Shape", r4Shape);
    data.append("r4Catatan", r4Catatan);

    data.append("r5Status", r5Status === true ? "valid" : "not valid");
    data.append(
      "r5Rcs",
      r5Shape === "A_simple" ||
        r5Shape === "A_tented" ||
        r5Shape === "accidental"
        ? jumlah.toFixed(1)
        : r5Rcs
    );
    data.append("r5Shape", r5Shape);
    data.append("r5Catatan", r5Catatan);

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/update-fingerprint/from-spv-annotator`,
      headers: {
        Authorization: `Bearer ${code}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        // console.log(error);
        if (error.response.data.statusCode === 403) {
          setModalAccessDenied(true);
        }
      });
  };

  const [modalLoading, setModalLoading] = useState(false);

  const [downloada, setDownloada] = useState(0);
  const [uploadb, setUploadb] = useState(0);
  const [downloadb, setDownloadb] = useState(0);

  const addGenerateReport = async () => {
    const textWithSpace = `${nama} ${namaB}`; // Gabungin dulu namanya

    if (packageId === "") {
      // Check Package ID, apakah dia sudah terisi apa belum
      console.log("Diisi dulu kawan");
    } else {
      try {
        setModalLoading(true); // Munculin Modal Loading

        const imagel1 = await fetch(l1); // l1 itu isinya link Minio, jadinya aku convert dulu ke Blob
        const blobl1 = await imagel1.blob();

        const imagel2 = await fetch(l2);
        const blobl2 = await imagel2.blob();

        const imagel3 = await fetch(l3);
        const blobl3 = await imagel3.blob();

        const imagel4 = await fetch(l4);
        const blobl4 = await imagel4.blob();

        const imagel5 = await fetch(l5);
        const blobl5 = await imagel5.blob();

        const imager1 = await fetch(r1);
        const blobr1 = await imager1.blob();

        const imager2 = await fetch(r2);
        const blobr2 = await imager2.blob();

        const imager3 = await fetch(r3);
        const blobr3 = await imager3.blob();

        const imager4 = await fetch(r4);
        const blobr4 = await imager4.blob();

        const imager5 = await fetch(r5);
        const blobr5 = await imager5.blob();

        let data = new FormData(); // Mulai API Pertama deh Outputnya Report Data yang banyak itu loh
        data.append(
          "shapes",
          JSON.stringify([
            l1Shape,
            l2Shape,
            l3Shape,
            l4Shape,
            l5Shape,
            r1Shape,
            r2Shape,
            r3Shape,
            r4Shape,
            r5Shape,
          ])
        );
        data.append(
          "rcs",
          JSON.stringify([
            l1Rcs,
            l2Rcs,
            l3Rcs,
            l4Rcs,
            l5Rcs,
            r1Rcs,
            r2Rcs,
            r3Rcs,
            r4Rcs,
            r5Rcs,
          ])
        );

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_BASE_ENDPOINT_AI}/report_generation`,
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            // console.log(response); // {report_data: {...}}

            const json = {
              name: nama + " " + namaB,
              age: umur,
              gender: gender,
              contact: hp,
              email: email === null ? " " : email,
              agency: instansi,
              package_type: packageName,
            };
            const reportData = JSON.stringify(response.report_data);

            let data = new FormData(); // Mulai API Kedua Outputnya File PPTX
            data.append("package_type", packageName);
            data.append("data", JSON.stringify(json));
            data.append("report_data", reportData);
            data.append("unique_code", uniqueCode);
            data.append(
              "shapes",
              JSON.stringify([
                l1Shape,
                l2Shape,
                l3Shape,
                l4Shape,
                l5Shape,
                r1Shape,
                r2Shape,
                r3Shape,
                r4Shape,
                r5Shape,
              ])
            );
            data.append("image", blobr1);
            data.append("image", blobr2);
            data.append("image", blobr3);
            data.append("image", blobr4);
            data.append("image", blobr5);
            data.append("image", blobl1);
            data.append("image", blobl2);
            data.append("image", blobl3);
            data.append("image", blobl4);
            data.append("image", blobl5);

            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: `${process.env.REACT_APP_BASE_ENDPOINT_AI}/report_generation_pdf`,
              data: data,
              responseType: "blob",
              onDownloadProgress: (event) => {
                // console.log("Download", event)
                const down = Math.round((event.loaded / event.total) * 100);
                // console.log(down)
                setDownloada(down);
              },
            };

            axios
              .request(config)
              .then((response) => {
                // console.log(response); // isinya Aneh

                try {
                  // Buat File dulu dan Nama File sesuai nama pengguna (jika ada Spasi, otomatis convert jadi _) filenya juga Type dibuat PPTX dulu
                  const files = new File(
                    [response],
                    `${textWithSpace.replace(/ /g, "_")}.pptx`,
                    {
                      type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                    }
                  );
                  // console.log(files);

                  let data = new FormData(); // Mulai API Ketiga Outputnya jadiin PDF
                  data.append("userId", userId);
                  data.append("file", files);

                  let config = {
                    method: "post",
                    maxBodyLength: Infinity,
                    url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/export-pptx-to-pdf`,
                    headers: {
                      Authorization: `Bearer ${code}`,
                    },
                    data: data,
                    onUploadProgress: (event) => {
                      // console.log("Download", event)
                      const up = Math.round((event.loaded / event.total) * 100);
                      // console.log(up)
                      setUploadb(up);
                    },
                    onDownloadProgress: (event) => {
                      // console.log("Download", event)
                      const down = Math.round(
                        (event.loaded / event.total) * 100
                      );
                      // console.log(down)
                      setDownloadb(down);
                    },
                  };

                  axios
                    .request(config)
                    .then((response) => {
                      // console.log(response); // {namePdf: ... , linkMinio: ....}
                      const pptxToPdf = response;

                      let data = new FormData(); // Mulai API keempat Output bisa mengubah/update di tabel Report (awalnya NULL jadi ada isinya)
                      data.append("reportData", reportData);
                      data.append("userId", userId);
                      data.append("filename", pptxToPdf.namePdf);

                      let config = {
                        method: "patch",
                        maxBodyLength: Infinity,
                        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/upload-report-manual`,
                        headers: {
                          Authorization: `Bearer ${code}`,
                        },
                        data: data,
                      };

                      axios
                        .request(config)
                        .then((response) => {
                          // console.log(response);

                          const membuatTombolDownload =
                            document.createElement("a"); // Ini buat Download File PDFnya OTOMATIS ketika sudah jadi semua
                          membuatTombolDownload.href = pptxToPdf.linkMinio;
                          membuatTombolDownload.setAttribute(
                            "download",
                            `${textWithSpace.replace(/ /g, "_")}.pdf`
                          );
                          document.body.appendChild(membuatTombolDownload);
                          membuatTombolDownload.click();
                          document.body.removeChild(membuatTombolDownload);

                          setModalLoading(false); // Modal Loading di mattin deh :)
                        })
                        .catch((error) => {
                          // console.log(error);
                          setModalLoading(false);
                          if (error.response.data.statusCode === 403) {
                            setModalAccessDenied(true);
                          }
                        });
                    })
                    .catch((error) => {
                      // console.log(error);
                      setModalLoading(false);
                      if (error.response.data.statusCode === 403) {
                        setModalAccessDenied(true);
                      }
                    });
                } catch (error) {
                  // console.log("error", error);
                  setModalLoading(false);
                }
              })
              .catch((error) => {
                // console.log(error);
                setModalLoading(false);
              });
          })
          .catch((error) => {
            // console.log(error);
            setModalLoading(false);
          });
      } catch {
        // console.log("wek");
        setModalLoading(false);
      }
    }
  };

  const newAddGenerateReport = async () => {
    if (
      r1Rcs === null ||
      r2Rcs === null ||
      r3Rcs === null ||
      r4Rcs === null ||
      r5Rcs === null ||
      l1Rcs === null ||
      l2Rcs === null ||
      l3Rcs === null ||
      l4Rcs === null ||
      l5Rcs === null ||
      r1Rcs == 0 ||
      r2Rcs == 0 ||
      r3Rcs == 0 ||
      r4Rcs == 0 ||
      r5Rcs == 0 ||
      l1Rcs == 0 ||
      l2Rcs == 0 ||
      l3Rcs == 0 ||
      l4Rcs == 0 ||
      l5Rcs == 0
    ) {
      alert("Please input RCS");
    } else {
      if (
        fingerType.some((item) => item.value === r1Shape) &&
        fingerType.some((item) => item.value === r2Shape) &&
        fingerType.some((item) => item.value === r3Shape) &&
        fingerType.some((item) => item.value === r4Shape) &&
        fingerType.some((item) => item.value === r5Shape) &&
        fingerType.some((item) => item.value === l1Shape) &&
        fingerType.some((item) => item.value === l2Shape) &&
        fingerType.some((item) => item.value === l3Shape) &&
        fingerType.some((item) => item.value === l4Shape) &&
        fingerType.some((item) => item.value === l5Shape)
      ) {
        setModalLoading(true); // Munculin Modal Loading

        let data = new FormData();
        data.append("userId", userId);
        data.append("firstName", nama);
        data.append("lastName", namaB);
        data.append("age", umur);
        data.append("gender", gender);
        if (hp !== null) {
          data.append("contact", hp);
        }
        data.append("email", email === null ? " " : email);
        if (instansi !== null) {
          data.append("agency", instansi);
        }
        data.append("packageType", packageName);
        data.append("packageTypeId", packageId);
        if (uniqueCode !== null) {
          data.append("uniqueCode", uniqueCode);
        }
        data.append(
          "fingerprint",
          `${f},${g},${h},${i},${j},${a},${b},${c},${d},${e}`
        );
        data.append("r1Shape", r1Shape);
        data.append("r2Shape", r2Shape);
        data.append("r3Shape", r3Shape);
        data.append("r4Shape", r4Shape);
        data.append("r5Shape", r5Shape);
        data.append("l1Shape", l1Shape);
        data.append("l2Shape", l2Shape);
        data.append("l3Shape", l3Shape);
        data.append("l4Shape", l4Shape);
        data.append("l5Shape", l5Shape);
        data.append("r1Rcs", r1Rcs);
        data.append("r2Rcs", r2Rcs);
        data.append("r3Rcs", r3Rcs);
        data.append("r4Rcs", r4Rcs);
        data.append("r5Rcs", r5Rcs);
        data.append("l1Rcs", l1Rcs);
        data.append("l2Rcs", l2Rcs);
        data.append("l3Rcs", l3Rcs);
        data.append("l4Rcs", l4Rcs);
        data.append("l5Rcs", l5Rcs);

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/generate-pdf-next`,
          headers: {
            Authorization: `Bearer ${code}`,
          },
          onUploadProgress: (event) => {
            // console.log("Download", event)
            const down = Math.round((event.loaded / event.total) * 100);
            // console.log(down)
            setDownloada(down);
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            // console.log(response);

            if (response.statusCode === 200) {
              setModalLoading(false);
              setDownloada(0);
              const membuatTombolDownload = document.createElement("a"); // Ini buat Download File PDFnya OTOMATIS ketika sudah jadi semua
              membuatTombolDownload.href = response.results.linkMinio;
              membuatTombolDownload.setAttribute(
                "download",
                `${response.results.namePdf.replace(/ /g, "_")}.pdf`
              );
              document.body.appendChild(membuatTombolDownload);
              membuatTombolDownload.click();
              document.body.removeChild(membuatTombolDownload);
            }
          })
          .catch((error) => {
            // console.log(error);
            // console.log(error.response);
            setModalLoading(false); // Munculin Modal Loading
          });
      } else {
        alert("Shape not formated, please check again");
      }
    }
  };

  const logOut = () => {
    sessionStorage.removeItem("user");
    window.location.href = "/login";
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <Col lg={12}>
            {step === 0 && (
              <>
                <Card>
                  <CardHeader>
                    <h4 className="card-title mb-0">SPV Annotation Table</h4>
                  </CardHeader>

                  <CardBody>
                    <div id="customerList">
                      <Row className="g-4 mb-3">
                        <Col style={{padding: '0px'}} className='col-sm' >    
                          <div className="d-flex justify-content-sm-end">
                            <div style={{marginRight: '10px'}}>
                              <select className="form-control"  value={limit} onChange={(event) => {setLimit(event.target.value); setPage(1)}}>
                                <option value={10} selected="selected">10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={200}>200</option>
                              </select>
                            </div>
                            <div style={{marginRight: '10px'}}>
                              {sorting === true ? (
                                <>
                                  <button className='btn buttonVersFutureLinkBorderGrey' onClick={() => {setOrderDate('asc'); setSorting(!sorting)}}>
                                    <i className='ri-sort-asc'></i>  
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button className='btn buttonVersFutureLinkBorderGrey' onClick={() => {setOrderDate('desc'); setSorting(!sorting)}}>
                                    <i className='ri-sort-desc'></i>  
                                  </button>
                                </>
                              )}
                            </div>
                            <div className="search-box">
                              <input type="text" className="form-control search" placeholder="Search..." onChange={(e) => setKata(e.target.value)}/>
                              <i className="ri-search-line search-icon"></i>
                            </div>
                            <div className="d-flex justify-content-end">
                              <div className="pagination-wrap hstack gap-2">
                                {page !== 1 && (
                                  <>
                                    <Link className="page-item pagination-prev" onClick={() => {setPage(page-1)}}>
                                      Previous
                                    </Link>
                                  </>
                                )}
                                {row === parseInt(limit) && (
                                  <>
                                    <Link className="page-item pagination-next" onClick={() => {setPage(page+1)}}>
                                      Next
                                    </Link>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>


                      <div className="table-responsive table-card mt-3 mb-1">
                        <table
                          className="table align-middle table-nowrap"
                          id="customerTable"
                        >
                          <thead className="table-light">
                            <tr>
                              <th scope="col" style={{ width: "50px" }}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="checkAll"
                                    value="option"
                                  />
                                </div>
                              </th>
                              <th className="sort" data-sort="no">
                                No
                              </th>
                              <th className="sort" data-sort="nama">
                                Name
                              </th>
                              <th className="sort" data-sort="email">
                                Email
                              </th>
                              <th className="sort" data-sort="status">
                                Package
                              </th>
                              <th className="sort" data-sort="images">
                                Images
                              </th>
                              <th className="sort" data-sort="status_anotasi">
                                Status Anotasi
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {users
                              .filter(
                                (item) =>
                                  // item.name.toLowerCase().includes(kata.toLowerCase()) ||
                                  item.first_name
                                    .toLowerCase()
                                    .includes(kata.toLowerCase()) ||
                                  item.last_name
                                    .toLowerCase()
                                    .includes(kata.toLowerCase())
                              )
                              .map((item, index) => (
                                <>
                                  <tr key={index}>
                                    <th scope="row">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          name="checkAll"
                                          value="option1"
                                        />
                                      </div>
                                    </th>
                                    <td className="no">{index + 1}</td>
                                    <td className="nama">
                                      {item.first_name} {item.last_name}
                                    </td>
                                    <td className="email">{item.email}</td>
                                    <td className="status">
                                      {item.package_name}
                                    </td>
                                    <td className="status">
                                      <div className="edit">
                                        <button
                                          className="btn btn-sm btn-success edit-item-btn"
                                          onClick={() => {
                                            setStep(1);
                                            getFingerprintByID(item.user_id);
                                            setUserId(item.user_id);
                                            setNamaLengkap(
                                              `${item.first_name} ${item.last_name}`
                                            );
                                          }}
                                        >
                                          View Images
                                        </button>
                                      </div>
                                    </td>
                                    {item.r1_status == null &&
                                    item.r2_status == null &&
                                    item.r3_status == null &&
                                    item.r4_status == null &&
                                    item.r5_status == null &&
                                    item.l1_status == null &&
                                    item.l2_status == null &&
                                    item.l3_status == null &&
                                    item.l4_status == null &&
                                    item.l5_status == null ? (
                                      <>
                                        <td className="status">
                                          <span
                                            style={{
                                              padding: "7px 15px",
                                              fontSize: "12px",
                                              fontWeight: "600",
                                              borderRadius: "30px",
                                              color: "white",
                                              backgroundColor: "#3B82F6",
                                            }}
                                          >
                                            Waiting
                                          </span>
                                        </td>
                                      </>
                                    ) : item.r1_status == null ||
                                      item.r2_status == null ||
                                      item.r3_status == null ||
                                      item.r4_status == null ||
                                      item.r5_status == null ||
                                      item.l1_status == null ||
                                      item.l2_status == null ||
                                      item.l3_status == null ||
                                      item.l4_status == null ||
                                      item.l5_status == null ||
                                      item.r1_status == "not valid" ||
                                      item.r2_status == "not valid" ||
                                      item.r3_status == "not valid" ||
                                      item.r4_status == "not valid" ||
                                      item.r5_status == "not valid" ||
                                      item.l1_status == "not valid" ||
                                      item.l2_status == "not valid" ||
                                      item.l3_status == "not valid" ||
                                      item.l4_status == "not valid" ||
                                      item.l5_status == "not valid" ? (
                                      <>
                                        <td className="status">
                                          <span
                                            style={{
                                              padding: "7px 19px",
                                              fontSize: "12px",
                                              fontWeight: "600",
                                              borderRadius: "30px",
                                              color: "white",
                                              backgroundColor: "#F43F5E",
                                            }}
                                          >
                                            Reject
                                          </span>
                                        </td>
                                      </>
                                    ) : (
                                      <>
                                        <td className="status">
                                          <span
                                            style={{
                                              padding: "7px 17px",
                                              fontSize: "12px",
                                              fontWeight: "600",
                                              borderRadius: "30px",
                                              color: "white",
                                              backgroundColor: "#10B981",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              getUserByID(item.user_id);
                                              setInstansi(item.name);
                                              getAllPackages();
                                              setUserId(item.user_id);
                                              setPackageId(item.package_id);
                                              setPackageName(item.package_name);
                                            }}
                                          >
                                            Accept
                                          </span>
                                        </td>
                                      </>
                                    )}
                                  </tr>
                                </>
                              ))}
                          </tbody>
                        </table>
                        {nothing === true && (
                          <>
                            <div className="noresult">
                              <div className="text-center">
                                <h5 className="mt-2">Sorry! No Result Found</h5>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="d-flex justify-content-end">
                        <div className="pagination-wrap hstack gap-2">
                          {page !== 1 && (
                             <>
                              <Link className="page-item pagination-prev" onClick={() => {setPage(page-1)}}>
                                Previous
                              </Link>
                            </>
                          )}
                          {row === parseInt(limit) && (
                            <>
                              <Link className="page-item pagination-next" onClick={() => {setPage(page+1)}}>
                                Next
                              </Link>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </>
            )}

            {step === 1 && (
              <>
                <button
                  className="btn buttonVersFutureLinkBorderGrey"
                  style={{ width: "70px", marginLeft: "20px" }}
                  onClick={() => {
                    setStep(0);
                    setR1("");
                    setR2("");
                    setR3("");
                    setR4("");
                    setR5("");
                    setL1("");
                    setL2("");
                    setL3("");
                    setL4("");
                    setL5("");
                    setR1Status("");
                    setR2Status("");
                    setR3Status("");
                    setR4Status("");
                    setR5Status("");
                    setL1Status("");
                    setL2Status("");
                    setL3Status("");
                    setL4Status("");
                    setL5Status("");
                    setR1Catatan("");
                    setR2Catatan("");
                    setR3Catatan("");
                    setR4Catatan("");
                    setR5Catatan("");
                    setL1Catatan("");
                    setL2Catatan("");
                    setL3Catatan("");
                    setL4Catatan("");
                    setL5Catatan("");
                    setR1Shape("");
                    setR2Shape("");
                    setR3Shape("");
                    setR4Shape("");
                    setR5Shape("");
                    setL1Shape("");
                    setL2Shape("");
                    setL3Shape("");
                    setL4Shape("");
                    setL5Shape("");
                    setR1Rcs("");
                    setR2Rcs("");
                    setR3Rcs("");
                    setR4Rcs("");
                    setR5Rcs("");
                    setL1Rcs("");
                    setL2Rcs("");
                    setL3Rcs("");
                    setL4Rcs("");
                    setL5Rcs("");
                    setNamaLengkap("");
                    setKata("");
                  }}
                >
                  {" "}
                  Back{" "}
                </button>

                <p
                  style={{
                    margin: "20px 0px",
                    fontSize: "24px",
                    fontWeight: "500",
                    textAlign: "center",
                  }}
                >
                  {namaLengkap}
                </p>

                <Row>
                  <Col
                    xxl={3}
                    xl={4}
                    sm={6}
                    className="element-item project designing development"
                  >
                    <Card className="gallery-box">
                      <div className="text-center">
                        <h3>L1</h3>
                      </div>

                      <div
                        className="gallery-container"
                        onClick={() => {
                          handleModalImage(l1);
                        }}
                        style={{ cursor: "pointer", display: "flex" }}
                      >
                        <img
                          className="gallery-img img-fluid mx-auto"
                          style={{ maxWidth: "300px", maxHeight: "220px" }}
                          src={l1}
                          alt=""
                        />
                        <div className="gallery-overlay">
                          <h5 className="overlay-caption">Thumb Left</h5>
                        </div>
                      </div>

                      <div className="box-content">
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={KiJempol}
                            alt=""
                            style={{
                              width: "100px",
                              border: "1px solid white",
                              borderRadius: "10px",
                            }}
                          />
                        </div>

                        <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                          <div className="flex-shrink-0">
                            <div className="d-flex gap-3">
                              <button
                                type="button"
                                className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0"
                              >
                                Add Note :
                              </button>
                              <button
                                type="button"
                                className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleModalCatatan(l1Catatan, "l1")
                                }
                              >
                                <i className="ri-question-answer-fill text-muted align-bottom me-1"></i>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                          <div>
                            <select
                              className="form-control"
                              value={l1Shape}
                              onChange={(event) => {
                                setL1Shape(event.target.value);
                              }}
                            >
                              <option
                                value="0"
                                selected="selected"
                                hidden="hidden"
                              >
                                Choose here
                              </option>
                              {fingerType.map((type, index) => (
                                <option key={index} value={type.value}>
                                  {type.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Input Redge Count"
                              value={
                                l1Shape === "A_simple" ||
                                l1Shape === "A_tented" ||
                                l1Shape === "accidental"
                                  ? jumlah.toFixed(1)
                                  : l1Rcs
                              }
                              onChange={(event) => {
                                setL1Rcs(event.target.value);
                              }}
                              disabled={
                                l1Shape === "A_simple" ||
                                l1Shape === "A_tented" ||
                                l1Shape === "accidental" ||
                                l1Shape === "" ||
                                l1Shape === null
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>

                        <div className="d-flex justify-content-center mt-3 gap-2">
                          <div className="text-center">
                            <button
                              className={
                                l1Status === "valid" || l1Status === true
                                  ? "btn buttonVersFutureLinkGreen"
                                  : "btn buttonVersFutureLinkRed"
                              }
                              onClick={() => {
                                setL1Status(!l1Status);
                              }}
                            >
                              {/* {l1Status === 'valid' || l1Status === true ? "Accept" : "Reject"}  */}
                              Accept
                            </button>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>

                  <Col
                    xxl={3}
                    xl={4}
                    sm={6}
                    className="element-item project designing development"
                  >
                    <Card className="gallery-box">
                      <div className="text-center">
                        <h3>L2</h3>
                      </div>

                      <div
                        className="gallery-container"
                        onClick={() => {
                          handleModalImage(l2);
                        }}
                        style={{ cursor: "pointer", display: "flex" }}
                      >
                        <img
                          className="gallery-img img-fluid mx-auto"
                          style={{ maxWidth: "300px", maxHeight: "220px" }}
                          src={l2}
                          alt=""
                        />
                        <div className="gallery-overlay">
                          <h5 className="overlay-caption">Pointer Left</h5>
                        </div>
                      </div>

                      <div className="box-content">
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={KiTelunjuk}
                            alt=""
                            style={{
                              width: "100px",
                              border: "1px solid white",
                              borderRadius: "10px",
                            }}
                          />
                        </div>

                        <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                          <div className="flex-shrink-0">
                            <div className="d-flex gap-3">
                              <button
                                type="button"
                                className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0"
                              >
                                Add Note :
                              </button>
                              <button
                                type="button"
                                className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleModalCatatan(l2Catatan, "l2")
                                }
                              >
                                <i className="ri-question-answer-fill text-muted align-bottom me-1"></i>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                          <div>
                            <select
                              className="form-control"
                              data-trigger
                              value={l2Shape}
                              onChange={(event) => {
                                setL2Shape(event.target.value);
                              }}
                            >
                              <option
                                value="0"
                                selected="selected"
                                hidden="hidden"
                              >
                                Choose here
                              </option>
                              {fingerType.map((type, index) => (
                                <option key={index} value={type.value}>
                                  {type.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Input Redge Count"
                              value={
                                l2Shape === "A_simple" ||
                                l2Shape === "A_tented" ||
                                l2Shape === "accidental"
                                  ? jumlah.toFixed(1)
                                  : l2Rcs
                              }
                              onChange={(event) => {
                                setL2Rcs(event.target.value);
                              }}
                              disabled={
                                l2Shape === "A_simple" ||
                                l2Shape === "A_tented" ||
                                l2Shape === "accidental" ||
                                l2Shape === "" ||
                                l2Shape === null
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>

                        <div className="d-flex justify-content-center mt-3 gap-2">
                          <div className="text-center">
                            <button
                              className={
                                l2Status === "valid" || l2Status === true
                                  ? "btn buttonVersFutureLinkGreen"
                                  : "btn buttonVersFutureLinkRed"
                              }
                              onClick={() => {
                                setL2Status(!l2Status);
                              }}
                            >
                              {/* {l2Status === 'valid' || l2Status === true ? "Accept" : "Reject"}  */}
                              Accept
                            </button>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>

                  <Col
                    xxl={3}
                    xl={4}
                    sm={6}
                    className="element-item project designing development"
                  >
                    <Card className="gallery-box">
                      <div className="text-center">
                        <h3>L3</h3>
                      </div>

                      <div
                        className="gallery-container"
                        onClick={() => {
                          handleModalImage(l3);
                        }}
                        style={{ cursor: "pointer", display: "flex" }}
                      >
                        <img
                          className="gallery-img img-fluid mx-auto"
                          style={{ maxWidth: "300px", maxHeight: "220px" }}
                          src={l3}
                          alt=""
                        />
                        <div className="gallery-overlay">
                          <h5 className="overlay-caption">Middle Left</h5>
                        </div>
                      </div>

                      <div className="box-content">
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={KiTengah}
                            alt=""
                            style={{
                              width: "100px",
                              border: "1px solid white",
                              borderRadius: "10px",
                            }}
                          />
                        </div>

                        <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                          <div className="flex-shrink-0">
                            <div className="d-flex gap-3">
                              <button
                                type="button"
                                className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0"
                              >
                                Add Note :
                              </button>
                              <button
                                type="button"
                                className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleModalCatatan(l3Catatan, "l3")
                                }
                              >
                                <i className="ri-question-answer-fill text-muted align-bottom me-1"></i>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                          <div>
                            <select
                              className="form-control"
                              data-trigger
                              value={l3Shape}
                              onChange={(event) => {
                                setL3Shape(event.target.value);
                              }}
                            >
                              <option
                                value="0"
                                selected="selected"
                                hidden="hidden"
                              >
                                Choose here
                              </option>
                              {fingerType.map((type, index) => (
                                <option key={index} value={type.value}>
                                  {type.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Input Redge Count"
                              value={
                                l3Shape === "A_simple" ||
                                l3Shape === "A_tented" ||
                                l3Shape === "accidental"
                                  ? jumlah.toFixed(1)
                                  : l3Rcs
                              }
                              onChange={(event) => {
                                setL3Rcs(event.target.value);
                              }}
                              disabled={
                                l3Shape === "A_simple" ||
                                l3Shape === "A_tented" ||
                                l3Shape === "accidental" ||
                                l3Shape === "" ||
                                l3Shape === null
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>

                        <div className="d-flex justify-content-center mt-3 gap-2">
                          <div className="text-center">
                            <button
                              className={
                                l3Status === "valid" || l3Status === true
                                  ? "btn buttonVersFutureLinkGreen"
                                  : "btn buttonVersFutureLinkRed"
                              }
                              onClick={() => {
                                setL3Status(!l3Status);
                              }}
                            >
                              {/* {l3Status === 'valid' || l3Status === true ? "Accept" : "Reject"}  */}
                              Accept
                            </button>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>

                  <Col
                    xxl={3}
                    xl={4}
                    sm={6}
                    className="element-item project designing development"
                  >
                    <Card className="gallery-box">
                      <div className="text-center">
                        <h3>L4</h3>
                      </div>

                      <div
                        className="gallery-container"
                        onClick={() => {
                          handleModalImage(l4);
                        }}
                        style={{ cursor: "pointer", display: "flex" }}
                      >
                        <img
                          className="gallery-img img-fluid mx-auto"
                          style={{ maxWidth: "300px", maxHeight: "220px" }}
                          src={l4}
                          alt=""
                        />
                        <div className="gallery-overlay">
                          <h5 className="overlay-caption">Ring Left</h5>
                        </div>
                      </div>

                      <div className="box-content">
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={KiManis}
                            alt=""
                            style={{
                              width: "100px",
                              border: "1px solid white",
                              borderRadius: "10px",
                            }}
                          />
                        </div>

                        <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                          <div className="flex-shrink-0">
                            <div className="d-flex gap-3">
                              <button
                                type="button"
                                className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0"
                              >
                                Add Note :
                              </button>
                              <button
                                type="button"
                                className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleModalCatatan(l4Catatan, "l4")
                                }
                              >
                                <i className="ri-question-answer-fill text-muted align-bottom me-1"></i>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                          <div>
                            <select
                              className="form-control"
                              data-trigger
                              value={l4Shape}
                              onChange={(event) => {
                                setL4Shape(event.target.value);
                              }}
                            >
                              <option
                                value="0"
                                selected="selected"
                                hidden="hidden"
                              >
                                Choose here
                              </option>
                              {fingerType.map((type, index) => (
                                <option key={index} value={type.value}>
                                  {type.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Input Redge Count"
                              value={
                                l4Shape === "A_simple" ||
                                l4Shape === "A_tented" ||
                                l4Shape === "accidental"
                                  ? jumlah.toFixed(1)
                                  : l4Rcs
                              }
                              onChange={(event) => {
                                setL4Rcs(event.target.value);
                              }}
                              disabled={
                                l4Shape === "A_simple" ||
                                l4Shape === "A_tented" ||
                                l4Shape === "accidental" ||
                                l4Shape === "" ||
                                l4Shape === null
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>

                        <div className="d-flex justify-content-center mt-3 gap-2">
                          <div className="text-center">
                            <button
                              className={
                                l4Status === "valid" || l4Status === true
                                  ? "btn buttonVersFutureLinkGreen"
                                  : "btn buttonVersFutureLinkRed"
                              }
                              onClick={() => {
                                setL4Status(!l4Status);
                              }}
                            >
                              {/* {l4Status === 'valid' || l4Status === true ? "Accept" : "Reject"}  */}
                              Accept
                            </button>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>

                  <Col
                    xxl={3}
                    xl={4}
                    sm={6}
                    className="element-item project designing development"
                  >
                    <Card className="gallery-box">
                      <div className="text-center">
                        <h3>L5</h3>
                      </div>

                      <div
                        className="gallery-container"
                        onClick={() => {
                          handleModalImage(l5);
                        }}
                        style={{ cursor: "pointer", display: "flex" }}
                      >
                        <img
                          className="gallery-img img-fluid mx-auto"
                          style={{ maxWidth: "300px", maxHeight: "220px" }}
                          src={l5}
                          alt=""
                        />
                        <div className="gallery-overlay">
                          <h5 className="overlay-caption">Pinky Left</h5>
                        </div>
                      </div>

                      <div className="box-content">
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={KiKelingking}
                            alt=""
                            style={{
                              width: "100px",
                              border: "1px solid white",
                              borderRadius: "10px",
                            }}
                          />
                        </div>

                        <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                          <div className="flex-shrink-0">
                            <div className="d-flex gap-3">
                              <button
                                type="button"
                                className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0"
                              >
                                Add Note :
                              </button>
                              <button
                                type="button"
                                className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleModalCatatan(l5Catatan, "l5")
                                }
                              >
                                <i className="ri-question-answer-fill text-muted align-bottom me-1"></i>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                          <div>
                            <select
                              className="form-control"
                              data-trigger
                              value={l5Shape}
                              onChange={(event) => {
                                setL5Shape(event.target.value);
                              }}
                            >
                              <option
                                value="0"
                                selected="selected"
                                hidden="hidden"
                              >
                                Choose here
                              </option>
                              {fingerType.map((type, index) => (
                                <option key={index} value={type.value}>
                                  {type.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Input Redge Count"
                              value={
                                l5Shape === "A_simple" ||
                                l5Shape === "A_tented" ||
                                l5Shape === "accidental"
                                  ? jumlah.toFixed(1)
                                  : l5Rcs
                              }
                              onChange={(event) => {
                                setL5Rcs(event.target.value);
                              }}
                              disabled={
                                l5Shape === "A_simple" ||
                                l5Shape === "A_tented" ||
                                l5Shape === "accidental" ||
                                l5Shape === "" ||
                                l5Shape === null
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>

                        <div className="d-flex justify-content-center mt-3 gap-2">
                          <div className="text-center">
                            <button
                              className={
                                l5Status === "valid" || l5Status === true
                                  ? "btn buttonVersFutureLinkGreen"
                                  : "btn buttonVersFutureLinkRed"
                              }
                              onClick={() => {
                                setL5Status(!l5Status);
                              }}
                            >
                              {/* {l5Status === 'valid' || l5Status === true ? "Accept" : "Reject"}  */}
                              Accept
                            </button>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>

                  <hr />

                  <Col
                    xxl={3}
                    xl={4}
                    sm={6}
                    className="element-item project designing development"
                  >
                    <Card className="gallery-box">
                      <div className="text-center">
                        <h3>R1</h3>
                      </div>
                      <div
                        className="gallery-container"
                        onClick={() => {
                          handleModalImage(r1);
                        }}
                        style={{ cursor: "pointer", display: "flex" }}
                      >
                        <img
                          className="gallery-img img-fluid mx-auto"
                          style={{ maxWidth: "300px", maxHeight: "220px" }}
                          src={r1}
                          alt=""
                        />
                        <div className="gallery-overlay">
                          <h5 className="overlay-caption">Thumb Right</h5>
                        </div>
                      </div>
                      <div className="box-content">
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={KaJempol}
                            alt=""
                            style={{
                              width: "100px",
                              border: "1px solid white",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                        <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                          <div className="flex-shrink-0">
                            <div className="d-flex gap-3">
                              <button
                                type="button"
                                className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0"
                              >
                                Add Note :
                              </button>
                              <button
                                type="button"
                                className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleModalCatatan(r1Catatan, "r1")
                                }
                              >
                                <i className="ri-question-answer-fill text-muted align-bottom me-1"></i>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                          <div>
                            <select
                              className="form-control"
                              data-trigger
                              value={r1Shape}
                              onChange={(event) => {
                                setR1Shape(event.target.value);
                              }}
                            >
                              <option
                                value="0"
                                selected="selected"
                                hidden="hidden"
                              >
                                Choose here
                              </option>
                              {fingerType.map((type, index) => (
                                <option key={index} value={type.value}>
                                  {type.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Input Redge Count"
                              value={
                                r1Shape === "A_simple" ||
                                r1Shape === "A_tented" ||
                                r1Shape === "accidental"
                                  ? jumlah.toFixed(1)
                                  : r1Rcs
                              }
                              onChange={(event) => {
                                setR1Rcs(event.target.value);
                              }}
                              disabled={
                                r1Shape === "A_simple" ||
                                r1Shape === "A_tented" ||
                                r1Shape === "accidental" ||
                                r1Shape === "" ||
                                r1Shape === null
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>

                        <div className="d-flex justify-content-center mt-3 gap-2">
                          <div className="text-center">
                            <button
                              className={
                                r1Status === "valid" || r1Status === true
                                  ? "btn buttonVersFutureLinkGreen"
                                  : "btn buttonVersFutureLinkRed"
                              }
                              onClick={() => {
                                setR1Status(!r1Status);
                              }}
                            >
                              {/* {r1Status === 'valid' || r1Status === true ? "Accept" : "Reject"}  */}
                              Accept
                            </button>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>

                  <Col
                    xxl={3}
                    xl={4}
                    sm={6}
                    className="element-item project designing development"
                  >
                    <Card className="gallery-box">
                      <div className="text-center">
                        <h3>R2</h3>
                      </div>
                      <div
                        className="gallery-container"
                        onClick={() => {
                          handleModalImage(r2);
                        }}
                        style={{ cursor: "pointer", display: "flex" }}
                      >
                        <img
                          className="gallery-img img-fluid mx-auto"
                          style={{ maxWidth: "300px", maxHeight: "220px" }}
                          src={r2}
                          alt=""
                        />
                        <div className="gallery-overlay">
                          <h5 className="overlay-caption">Pointer Right</h5>
                        </div>
                      </div>
                      <div className="box-content">
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={KaTelunjuk}
                            alt=""
                            style={{
                              width: "100px",
                              border: "1px solid white",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                        <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                          <div className="flex-shrink-0">
                            <div className="d-flex gap-3">
                              <button
                                type="button"
                                className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0"
                              >
                                Add Note :
                              </button>
                              <button
                                type="button"
                                className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleModalCatatan(r2Catatan, "r2")
                                }
                              >
                                <i className="ri-question-answer-fill text-muted align-bottom me-1"></i>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                          <div>
                            <select
                              className="form-control"
                              data-trigger
                              value={r2Shape}
                              onChange={(event) => {
                                setR2Shape(event.target.value);
                              }}
                            >
                              <option
                                value="0"
                                selected="selected"
                                hidden="hidden"
                              >
                                Choose here
                              </option>
                              {fingerType.map((type, index) => (
                                <option key={index} value={type.value}>
                                  {type.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Input Redge Count"
                              value={
                                r2Shape === "A_simple" ||
                                r2Shape === "A_tented" ||
                                r2Shape === "accidental"
                                  ? jumlah.toFixed(1)
                                  : r2Rcs
                              }
                              onChange={(event) => {
                                setR2Rcs(event.target.value);
                              }}
                              disabled={
                                r2Shape === "A_simple" ||
                                r2Shape === "A_tented" ||
                                r2Shape === "accidental" ||
                                r2Shape === "" ||
                                r2Shape === null
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>

                        <div className="d-flex justify-content-center mt-3 gap-2">
                          <div className="text-center">
                            <button
                              className={
                                r2Status === "valid" || r2Status === true
                                  ? "btn buttonVersFutureLinkGreen"
                                  : "btn buttonVersFutureLinkRed"
                              }
                              onClick={() => {
                                setR2Status(!r2Status);
                              }}
                            >
                              {/* {r2Status === 'valid' || r2Status === true ? "Accept" : "Reject"}  */}
                              Accept
                            </button>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>

                  <Col
                    xxl={3}
                    xl={4}
                    sm={6}
                    className="element-item project designing development"
                  >
                    <Card className="gallery-box">
                      <div className="text-center">
                        <h3>R3</h3>
                      </div>
                      <div
                        className="gallery-container"
                        onClick={() => {
                          handleModalImage(r3);
                        }}
                        style={{ cursor: "pointer", display: "flex" }}
                      >
                        <img
                          className="gallery-img img-fluid mx-auto"
                          style={{ maxWidth: "300px", maxHeight: "220px" }}
                          src={r3}
                          alt=""
                        />
                        <div className="gallery-overlay">
                          <h5 className="overlay-caption">Middle Right</h5>
                        </div>
                      </div>
                      <div className="box-content">
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={KaTengah}
                            alt=""
                            style={{
                              width: "100px",
                              border: "1px solid white",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                        <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                          <div className="flex-shrink-0">
                            <div className="d-flex gap-3">
                              <button
                                type="button"
                                className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0"
                              >
                                Add Note :
                              </button>
                              <button
                                type="button"
                                className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleModalCatatan(r3Catatan, "r3")
                                }
                              >
                                <i className="ri-question-answer-fill text-muted align-bottom me-1"></i>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                          <div>
                            <select
                              className="form-control"
                              data-trigger
                              value={r3Shape}
                              onChange={(event) => {
                                setR3Shape(event.target.value);
                              }}
                            >
                              <option
                                value="0"
                                selected="selected"
                                hidden="hidden"
                              >
                                Choose here
                              </option>
                              {fingerType.map((type, index) => (
                                <option key={index} value={type.value}>
                                  {type.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Input Redge Count"
                              value={
                                r3Shape === "A_simple" ||
                                r3Shape === "A_tented" ||
                                r3Shape === "accidental"
                                  ? jumlah.toFixed(1)
                                  : r3Rcs
                              }
                              onChange={(event) => {
                                setR3Rcs(event.target.value);
                              }}
                              disabled={
                                r3Shape === "A_simple" ||
                                r3Shape === "A_tented" ||
                                r3Shape === "accidental" ||
                                r3Shape === "" ||
                                r3Shape === null
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>

                        <div className="d-flex justify-content-center mt-3 gap-2">
                          <div className="text-center">
                            <button
                              className={
                                r3Status === "valid" || r3Status === true
                                  ? "btn buttonVersFutureLinkGreen"
                                  : "btn buttonVersFutureLinkRed"
                              }
                              onClick={() => {
                                setR3Status(!r3Status);
                              }}
                            >
                              {/* {r3Status === 'valid' || r3Status === true? "Accept" : "Reject"}  */}
                              Accept
                            </button>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>

                  <Col
                    xxl={3}
                    xl={4}
                    sm={6}
                    className="element-item project designing development"
                  >
                    <Card className="gallery-box">
                      <div className="text-center">
                        <h3>R4</h3>
                      </div>
                      <div
                        className="gallery-container"
                        onClick={() => {
                          handleModalImage(r4);
                        }}
                        style={{ cursor: "pointer", display: "flex" }}
                      >
                        <img
                          className="gallery-img img-fluid mx-auto"
                          style={{ maxWidth: "300px", maxHeight: "220px" }}
                          src={r4}
                          alt=""
                        />
                        <div className="gallery-overlay">
                          <h5 className="overlay-caption">Ring Right</h5>
                        </div>
                      </div>
                      <div className="box-content">
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={KaManis}
                            alt=""
                            style={{
                              width: "100px",
                              border: "1px solid white",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                        <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                          <div className="flex-shrink-0">
                            <div className="d-flex gap-3">
                              <button
                                type="button"
                                className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0"
                              >
                                Add Note :
                              </button>
                              <button
                                type="button"
                                className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleModalCatatan(r4Catatan, "r4")
                                }
                              >
                                <i className="ri-question-answer-fill text-muted align-bottom me-1"></i>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                          <div>
                            <select
                              className="form-control"
                              data-trigger
                              value={r4Shape}
                              onChange={(event) => {
                                setR4Shape(event.target.value);
                              }}
                            >
                              <option
                                value="0"
                                selected="selected"
                                hidden="hidden"
                              >
                                Choose here
                              </option>
                              {fingerType.map((type, index) => (
                                <option key={index} value={type.value}>
                                  {type.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Input Redge Count"
                              value={
                                r4Shape === "A_simple" ||
                                r4Shape === "A_tented" ||
                                r4Shape === "accidental"
                                  ? jumlah.toFixed(1)
                                  : r4Rcs
                              }
                              onChange={(event) => {
                                setR4Rcs(event.target.value);
                              }}
                              disabled={
                                r4Shape === "A_simple" ||
                                r4Shape === "A_tented" ||
                                r4Shape === "accidental" ||
                                r4Shape === "" ||
                                r4Shape === null
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>

                        <div className="d-flex justify-content-center mt-3 gap-2">
                          <div className="text-center">
                            <button
                              className={
                                r4Status === "valid" || r4Status === true
                                  ? "btn buttonVersFutureLinkGreen"
                                  : "btn buttonVersFutureLinkRed"
                              }
                              onClick={() => {
                                setR4Status(!r4Status);
                              }}
                            >
                              {/* {r4Status === 'valid' || r4Status === true? "Accept" : "Reject"}  */}
                              Accept
                            </button>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>

                  <Col
                    xxl={3}
                    xl={4}
                    sm={6}
                    className="element-item project designing development"
                  >
                    <Card className="gallery-box">
                      <div className="text-center">
                        <h3>R5</h3>
                      </div>
                      <div
                        className="gallery-container"
                        onClick={() => {
                          handleModalImage(r5);
                        }}
                        style={{ cursor: "pointer", display: "flex" }}
                      >
                        <img
                          className="gallery-img img-fluid mx-auto"
                          style={{ maxWidth: "300px", maxHeight: "220px" }}
                          src={r5}
                          alt=""
                        />
                        <div className="gallery-overlay">
                          <h5 className="overlay-caption">Pinky Right</h5>
                        </div>
                      </div>
                      <div className="box-content">
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={KaKelingking}
                            alt=""
                            style={{
                              width: "100px",
                              border: "1px solid white",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                        <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                          <div className="flex-shrink-0">
                            <div className="d-flex gap-3">
                              <button
                                type="button"
                                className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0"
                              >
                                Add Note :
                              </button>
                              <button
                                type="button"
                                className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleModalCatatan(r5Catatan, "r5")
                                }
                              >
                                <i className="ri-question-answer-fill text-muted align-bottom me-1"></i>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">
                          <div>
                            <select
                              className="form-control"
                              data-trigger
                              value={r5Shape}
                              onChange={(event) => {
                                setR5Shape(event.target.value);
                              }}
                            >
                              <option
                                value="0"
                                selected="selected"
                                hidden="hidden"
                              >
                                Choose here
                              </option>
                              {fingerType.map((type, index) => (
                                <option key={index} value={type.value}>
                                  {type.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Input Redge Count"
                              value={
                                r5Shape === "A_simple" ||
                                r5Shape === "A_tented" ||
                                r5Shape === "accidental"
                                  ? jumlah.toFixed(1)
                                  : r5Rcs
                              }
                              onChange={(event) => {
                                setR5Rcs(event.target.value);
                              }}
                              disabled={
                                r5Shape === "A_simple" ||
                                r5Shape === "A_tented" ||
                                r5Shape === "accidental" ||
                                r5Shape === "" ||
                                r5Shape === null
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>

                        <div className="d-flex justify-content-center mt-3 gap-2">
                          <div className="text-center">
                            <button
                              className={
                                r5Status === "valid" || r5Status === true
                                  ? "btn buttonVersFutureLinkGreen"
                                  : "btn buttonVersFutureLinkRed"
                              }
                              onClick={() => {
                                setR5Status(!r5Status);
                              }}
                            >
                              {/* {r5Status === 'valid' || r5Status === true ? "Accept" : "Reject"}  */}
                              Accept
                            </button>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>

                <div className="text-center my-2">
                  <Button
                    color="dark"
                    className="rounded-pill"
                    onClick={() => {
                      updateFingerprint();
                    }}
                  >
                    {" "}
                    Submit{" "}
                  </Button>
                </div>
              </>
            )}

            {step === 2 && (
              <>
                <button
                  className="btn buttonVersFutureLinkBorderGrey"
                  style={{ width: "70px", marginBottom: "30px" }}
                  onClick={() => {
                    setStep(0);
                    setNama("");
                    setNamaB("");
                    setUmur("");
                    setGender("");
                    setHp("");
                    setEmail("");
                    setL1Rcs("");
                    setL2Rcs("");
                    setL3Rcs("");
                    setL4Rcs("");
                    setL5Rcs("");
                    setR1Rcs("");
                    setR2Rcs("");
                    setR3Rcs("");
                    setR4Rcs("");
                    setR5Rcs("");
                    setL1Shape("");
                    setL2Shape("");
                    setL3Shape("");
                    setL4Shape("");
                    setL5Shape("");
                    setR1Shape("");
                    setR2Shape("");
                    setR3Shape("");
                    setR4Shape("");
                    setR5Shape("");
                    setL1("");
                    setL2("");
                    setL3("");
                    setL4("");
                    setL5("");
                    setR1("");
                    setR2("");
                    setR3("");
                    setR4("");
                    setR5("");
                    setUserId("");
                    setInstansi("");
                    setPackageName("");
                    setPackageId("");
                  }}
                >
                  {" "}
                  Back{" "}
                </button>
                <div className="mb-3">
                  <Label htmlFor="name" className="form-label">
                    Name
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="name"
                    value={nama + " " + namaB}
                  />
                </div>

                <div className="mb-3">
                  <Label htmlFor="umur" className="form-label">
                    Age
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="umur"
                    value={umur}
                  />
                </div>

                <div className="mb-3">
                  <Label htmlFor="gender" className="form-label">
                    Gender
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="gender"
                    value={gender}
                  />
                </div>

                <div className="mb-3">
                  <Label htmlFor="hp" className="form-label">
                    No HP
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="hp"
                    value={hp}
                    onChange={(event) => {
                      setHp(event.target.value);
                    }}
                  />
                </div>

                <div className="mb-3">
                  <Label htmlFor="email" className="form-label">
                    Email
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                </div>

                <div className="mb-3">
                  <Label htmlFor="instansi" className="form-label">
                    Agency
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="instansi"
                    value={instansi}
                  />
                </div>

                <Row>
                  <Col xs={12} sm={12} md={6}>
                    <div className="mb-3">
                      <Label htmlFor="name" className="form-label">
                        Left Thumb (L1)
                      </Label>
                      <div className="input-group">
                        <span
                          className="input-group-text"
                          style={{ padding: "0.5rem 22px" }}
                        >
                          Pattern
                        </span>
                        <Input
                          type="text"
                          className="form-control"
                          value={l1Shape}
                        />
                      </div>
                      <div className="input-group">
                        <span className="input-group-text">Ridge Count</span>
                        <Input
                          type="text"
                          className="form-control"
                          value={l1Rcs}
                        />
                      </div>
                      <img
                        alt=""
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }}
                        src={l1}
                      />
                    </div>
                    <div className="mb-3">
                      <Label htmlFor="name" className="form-label">
                        Left Point (L2)
                      </Label>
                      <div className="input-group">
                        <span
                          className="input-group-text"
                          style={{ padding: "0.5rem 22px" }}
                        >
                          Pattern
                        </span>
                        <Input
                          type="text"
                          className="form-control"
                          value={l2Shape}
                        />
                      </div>
                      <div className="input-group">
                        <span className="input-group-text">Ridge Count</span>
                        <Input
                          type="text"
                          className="form-control"
                          value={l2Rcs}
                        />
                      </div>
                      <img
                        alt=""
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }}
                        src={l2}
                      />
                    </div>
                    <div className="mb-3">
                      <Label htmlFor="name" className="form-label">
                        Left Middle (L3)
                      </Label>
                      <div className="input-group">
                        <span
                          className="input-group-text"
                          style={{ padding: "0.5rem 22px" }}
                        >
                          Pattern
                        </span>
                        <Input
                          type="text"
                          className="form-control"
                          value={l3Shape}
                        />
                      </div>
                      <div className="input-group">
                        <span className="input-group-text">Ridge Count</span>
                        <Input
                          type="text"
                          className="form-control"
                          value={l3Rcs}
                        />
                      </div>
                      <img
                        alt=""
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }}
                        src={l3}
                      />
                    </div>
                    <div className="mb-3">
                      <Label htmlFor="name" className="form-label">
                        Left Ring (L4)
                      </Label>
                      <div className="input-group">
                        <span
                          className="input-group-text"
                          style={{ padding: "0.5rem 22px" }}
                        >
                          Pattern
                        </span>
                        <Input
                          type="text"
                          className="form-control"
                          value={l4Shape}
                        />
                      </div>
                      <div className="input-group">
                        <span className="input-group-text">Ridge Count</span>
                        <Input
                          type="text"
                          className="form-control"
                          value={l4Rcs}
                        />
                      </div>
                      <img
                        alt=""
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }}
                        src={l4}
                      />
                    </div>
                    <div className="mb-3">
                      <Label htmlFor="name" className="form-label">
                        Left Pinky (L5)
                      </Label>
                      <div className="input-group">
                        <span
                          className="input-group-text"
                          style={{ padding: "0.5rem 22px" }}
                        >
                          Pattern
                        </span>
                        <Input
                          type="text"
                          className="form-control"
                          value={l5Shape}
                        />
                      </div>
                      <div className="input-group">
                        <span className="input-group-text">Ridge Count</span>
                        <Input
                          type="text"
                          className="form-control"
                          value={l5Rcs}
                        />
                      </div>
                      <img
                        alt=""
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }}
                        src={l5}
                      />
                    </div>
                  </Col>

                  <Col xs={12} sm={12} md={6}>
                    <div className="mb-3">
                      <Label htmlFor="name" className="form-label">
                        Right Thumb (R1)
                      </Label>
                      <div className="input-group">
                        <span
                          className="input-group-text"
                          style={{ padding: "0.5rem 22px" }}
                        >
                          Pattern
                        </span>
                        <Input
                          type="text"
                          className="form-control"
                          value={r1Shape}
                        />
                      </div>
                      <div className="input-group">
                        <span className="input-group-text">Ridge Count</span>
                        <Input
                          type="text"
                          className="form-control"
                          value={r1Rcs}
                        />
                      </div>
                      <img
                        alt=""
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }}
                        src={r1}
                      />
                    </div>
                    <div className="mb-3">
                      <Label htmlFor="name" className="form-label">
                        Right Point (R2)
                      </Label>
                      <div className="input-group">
                        <span
                          className="input-group-text"
                          style={{ padding: "0.5rem 22px" }}
                        >
                          Pattern
                        </span>
                        <Input
                          type="text"
                          className="form-control"
                          value={r2Shape}
                        />
                      </div>
                      <div className="input-group">
                        <span className="input-group-text">Ridge Count</span>
                        <Input
                          type="text"
                          className="form-control"
                          value={r2Rcs}
                        />
                      </div>
                      <img
                        alt=""
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }}
                        src={r2}
                      />
                    </div>
                    <div className="mb-3">
                      <Label htmlFor="name" className="form-label">
                        Right Middle (R3)
                      </Label>
                      <div className="input-group">
                        <span
                          className="input-group-text"
                          style={{ padding: "0.5rem 22px" }}
                        >
                          Pattern
                        </span>
                        <Input
                          type="text"
                          className="form-control"
                          value={r3Shape}
                        />
                      </div>
                      <div className="input-group">
                        <span className="input-group-text">Ridge Count</span>
                        <Input
                          type="text"
                          className="form-control"
                          value={r3Rcs}
                        />
                      </div>
                      <img
                        alt=""
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }}
                        src={r3}
                      />
                    </div>
                    <div className="mb-3">
                      <Label htmlFor="name" className="form-label">
                        Right Ring (R4)
                      </Label>
                      <div className="input-group">
                        <span
                          className="input-group-text"
                          style={{ padding: "0.5rem 22px" }}
                        >
                          Pattern
                        </span>
                        <Input
                          type="text"
                          className="form-control"
                          value={r4Shape}
                        />
                      </div>
                      <div className="input-group">
                        <span className="input-group-text">Ridge Count</span>
                        <Input
                          type="text"
                          className="form-control"
                          value={r4Rcs}
                        />
                      </div>
                      <img
                        alt=""
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }}
                        src={r4}
                      />
                    </div>
                    <div className="mb-3">
                      <Label htmlFor="name" className="form-label">
                        Right Pinky (R5)
                      </Label>
                      <div className="input-group">
                        <span
                          className="input-group-text"
                          style={{ padding: "0.5rem 22px" }}
                        >
                          Pattern
                        </span>
                        <Input
                          type="text"
                          className="form-control"
                          value={r5Shape}
                        />
                      </div>
                      <div className="input-group">
                        <span className="input-group-text">Ridge Count</span>
                        <Input
                          type="text"
                          className="form-control"
                          value={r5Rcs}
                        />
                      </div>
                      <img
                        alt=""
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }}
                        src={r5}
                      />
                    </div>
                  </Col>

                  <div className="mb-3">
                    <label htmlFor="gender-field" className="form-label">
                      Package
                    </label>
                    <select
                      value={packageId}
                      onChange={(event) => {
                        setPackageId(event.target.value);
                      }}
                      className="form-control"
                      id="Gender-field"
                      disabled={true}
                    >
                      <option value="0" selected="selected" hidden="hidden">
                        Choose package
                      </option>
                      {packages.map((item) => (
                        <>
                          <option value={item.package_id}>{item.name}</option>
                        </>
                      ))}
                    </select>
                  </div>
                </Row>

                <button
                  className="btn buttonVersFutureLinkGreen"
                  onClick={() => {
                    newAddGenerateReport();
                  }}
                >
                  {" "}
                  Generate Now
                </button>
              </>
            )}
          </Col>
        </Row>
      </div>

      <Modal isOpen={modalAccessDenied} toggle={() => {}} centered>
        <ModalBody className="modalCenter">
          <img src={accessDenied} style={{ width: "30%" }} />
          <p style={{ fontSize: "24px", fontWeight: "700", marginTop: "20px" }}>
            Access Denied
          </p>
          <p style={{ margin: "0px", color: "grey" }}>
            You currently does not have access to this page. <br /> Please Login
            Again
          </p>
        </ModalBody>
        <ModalFooter
          style={{ display: "flex", justifyContent: "center", gap: "80px" }}
        >
          <button
            className="btn buttonVersFutureLink"
            style={{ fontSize: "14px", fontWeight: "500" }}
            onClick={() => {
              logOut();
            }}
          >
            Login Again
          </button>
        </ModalFooter>
      </Modal>

      <Modal
        className="modal-xl"
        isOpen={modalImage}
        toggle={() => {
          handleModalImage();
        }}
        centered
      >
        <ModalHeader
          toggle={() => {
            handleModalImage();
          }}
        ></ModalHeader>
        <ModalBody className="text-center">
          <img
            className="overlay"
            src={imageFingerprint}
            style={{ width: "100%" }}
          />
        </ModalBody>
      </Modal>

      <Modal
        className="modal-md"
        isOpen={modalCatatan}
        toggle={() => {
          setModalCatatan(!modalCatatan);
        }}
        centered
      >
        <ModalHeader
          toggle={() => {
            setModalCatatan(!modalCatatan);
          }}
        >
          Note from SPV
        </ModalHeader>
        <ModalBody className="text-center">
          <div className="mb-1">
            {ind === "l1" && (
              <>
                <textarea
                  className="form-control"
                  rows="4"
                  value={l1Catatan}
                  onChange={(event) => {
                    setL1Catatan(event.target.value);
                  }}
                ></textarea>
              </>
            )}
            {ind === "l2" && (
              <>
                <textarea
                  className="form-control"
                  rows="4"
                  value={l2Catatan}
                  onChange={(event) => {
                    setL2Catatan(event.target.value);
                  }}
                ></textarea>
              </>
            )}
            {ind === "l3" && (
              <>
                <textarea
                  className="form-control"
                  rows="4"
                  value={l3Catatan}
                  onChange={(event) => {
                    setL3Catatan(event.target.value);
                  }}
                ></textarea>
              </>
            )}
            {ind === "l4" && (
              <>
                <textarea
                  className="form-control"
                  rows="4"
                  value={l4Catatan}
                  onChange={(event) => {
                    setL4Catatan(event.target.value);
                  }}
                ></textarea>
              </>
            )}
            {ind === "l5" && (
              <>
                <textarea
                  className="form-control"
                  rows="4"
                  value={l5Catatan}
                  onChange={(event) => {
                    setL5Catatan(event.target.value);
                  }}
                ></textarea>
              </>
            )}
            {ind === "r1" && (
              <>
                <textarea
                  className="form-control"
                  rows="4"
                  value={r1Catatan}
                  onChange={(event) => {
                    setR1Catatan(event.target.value);
                  }}
                ></textarea>
              </>
            )}
            {ind === "r2" && (
              <>
                <textarea
                  className="form-control"
                  rows="4"
                  value={r2Catatan}
                  onChange={(event) => {
                    setR2Catatan(event.target.value);
                  }}
                ></textarea>
              </>
            )}
            {ind === "r3" && (
              <>
                <textarea
                  className="form-control"
                  rows="4"
                  value={r3Catatan}
                  onChange={(event) => {
                    setR3Catatan(event.target.value);
                  }}
                ></textarea>
              </>
            )}
            {ind === "r4" && (
              <>
                <textarea
                  className="form-control"
                  rows="4"
                  value={r4Catatan}
                  onChange={(event) => {
                    setR4Catatan(event.target.value);
                  }}
                ></textarea>
              </>
            )}
            {ind === "r5" && (
              <>
                <textarea
                  className="form-control"
                  rows="4"
                  value={r5Catatan}
                  onChange={(event) => {
                    setR5Catatan(event.target.value);
                  }}
                ></textarea>
              </>
            )}
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalLoading}
        toggle={() => {
          setModalLoading(!modalLoading);
        }}
        centered
      >
        <ModalBody className="modalCenter" style={{ margin: "0px" }}>
          <Lottie
            options={animationLoading}
            height={350}
            width={350}
            isClickToPauseDisabled={true}
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ margin: "0px" }}>Please Wait ... </p>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default TableUserAccRetakeImage;

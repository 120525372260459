import React, {useState} from "react";
import {Row, Col, Progress} from 'reactstrap';
import {Link} from 'react-router-dom';

import eagle from '../../../../../assets/images/FutureLink/eagle.png';
import dove from '../../../../../assets/images/FutureLink/dove.png';
import owl from '../../../../../assets/images/FutureLink/owl.png';
import peacock from '../../../../../assets/images/FutureLink/peacock.png';



const Dope = (e) => {
    const state = e.state.data
    const data = e.state.data
    const package_id = e.state.package_id
    const img_finger = e.state.img_finger
    const shape_finger = e.state.shape_finger
    const disc = state.disc

    return(
        <Row className="corev2 qx" style={{height: '85vh'}}>
            <div className="appearOnMobile tiwtiw" style={{marginBottom: '24px'}}>
                <Link to={{pathname: '/client/reportFP', state: { data: data, package_id: package_id, img_finger:img_finger, shape_finger:shape_finger },}} className="bBek">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M10.295 19.7155C10.3886 19.8077 10.4994 19.8806 10.6211 19.93C10.7429 19.9793 10.8731 20.0042 11.0045 20.0033C11.1358 20.0023 11.2657 19.9754 11.3867 19.9243C11.5076 19.8731 11.6173 19.7986 11.7095 19.705C11.8017 19.6115 11.8746 19.5007 11.9239 19.379C11.9733 19.2572 11.9982 19.127 11.9972 18.9956C11.9963 18.8643 11.9694 18.7344 11.9183 18.6134C11.8671 18.4925 11.7926 18.3827 11.699 18.2906L6.32903 13.0006H19.999C20.2642 13.0006 20.5186 12.8952 20.7061 12.7077C20.8937 12.5201 20.999 12.2658 20.999 12.0006C20.999 11.7353 20.8937 11.481 20.7061 11.2934C20.5186 11.1059 20.2642 11.0006 19.999 11.0006H6.33603L11.7 5.71355C11.884 5.52633 11.987 5.2743 11.9867 5.01183C11.9865 4.74936 11.8831 4.49751 11.6988 4.31061C11.5145 4.12371 11.2642 4.01675 11.0017 4.01281C10.7393 4.00887 10.4858 4.10827 10.296 4.28955L3.37203 11.1116C3.25411 11.2278 3.16048 11.3664 3.09657 11.5192C3.03267 11.672 2.99976 11.8359 2.99976 12.0016C2.99976 12.1672 3.03267 12.3311 3.09657 12.4839C3.16048 12.6367 3.25411 12.7753 3.37203 12.8916L10.296 19.7146L10.295 19.7155Z" fill="#374151"/>
                </svg>
                </Link>
                <p className="antitank" style={{textAlign: 'center'}}>DOPE</p>
            </div>
        {disc === 'domination' && (
            <>
            <div className='dopeItem'>
                <div style={{display: 'flex', alignItems: 'center', gap: '30px'}}>
                    <img src={eagle} style={{width: '100px'}} />
                    <p style={{margin: '0px', fontSize: '24px', fontWeight: '900', color: '#1F599C'}}>EAGLE</p>
                </div>
                <p style={{fontSize: '16px', textAlign: 'justify', width: '90%'}}>
                Orang yang kepribadiannya <span style={{fontWeight: '700'}}>elang</span> cenderung dominan, berorientasi pada pekerjaan/tugas, egosentris, mandiri, berorientasi pada hasil, berani, bersedia menerima tantangan, agresif, sadar akan citra diri, senang mengontrol, memiliki daya tahan dan semangat kegigihan, setia kepada teman, tidak mudah dipengaruhi tetapi setia kepada orang yang dikagumi.
                Orang yang kepribadiannya elang dapat dimotivasi dengan target yang harus dicapai sebelum tenggat waktu, peraturan yang membuat sibuk dan tertantang, kewenangan yang diberikan untuk bernegosiasi dan mengambil keputusan, persaingan nyata atau persaingan yang berasal dari imajinasi.
                Kekuatan dari orang ini adalah mudah termotivasi, mandiri, senang berinisiatif, tak kenal takut, agresif, dan menarik. Sedangkan kelemahannya adalah keras kepala, paranoid, mudah tersinggung, egois, tidak sabar, tidak simpatik, senang memaksa, dan kontroversial.
                </p>

            </div>
            </>
        )}
        {disc === 'influential' && (
            <>
            <div className='dopeItem'>
                <div style={{display: 'flex', alignItems: 'center', gap: '30px'}}>
                    <img src={peacock} style={{width: '100px'}} />
                    <p style={{margin: '0px', fontSize: '24px', fontWeight: '900', color: '#1F599C'}}>PEACOCK</p>
                </div>
                <p style={{fontSize: '16px', textAlign: 'justify', width: '90%'}}>
                Orang yang kepribadiannya <span style={{fontWeight: '700'}}>merak</span> biasanya pembicara yang antusias, senang menjadi pusat perhatian, memiliki antusiasme yang tinggi, optimis, cepat bergerak, energik, senang berinovasi, kreatif, mudah beradaptasi, spontan, memiliki selera humor yang baik, jujur dan setia, emosional & sensitif, serta nikmati tantangan.
                Orang yang kepribadiannya merak dapat dimotivasi oleh ide-ide baru, ragam karya, pemikiran kreatif dan inovatif, kebebasan, sensasi dan tantangan yang dihadapi, spontanitas dan fleksibilitas untuk perubahan cepat.
                Kekuatan yang dimiliki orang ini adalah jiwa antusias, sosial, karismatik, berpikiran terbuka, emosional, banyak bicara, energik, kompetitif, san suka petualangan. Sedangkan kelemahannya adalah emosional, tidak teratur, tidak bertanggung jawab, tidak realistis, tidak mahir dengan detail atau kontrol waktu.
                </p>

            </div>
            </>
        )}
        {disc === 'steady' && (
            <>
            <div className='dopeItem'>
                <div style={{display: 'flex', alignItems: 'center', gap: '30px'}}>
                    <img src={dove} style={{width: '100px'}} />
                    <p style={{margin: '0px', fontSize: '24px', fontWeight: '900', color: '#1F599C'}}>DOVE</p>
                </div>
                <p style={{fontSize: '16px', textAlign: 'justify', width: '90%'}}>
                    Orang yang kepribadiannya <span style={{fontWeight: '700'}}>merpati</span> berorientasi pada orang, setia, ramah, pekerja keras, suportif, sensitif, bergerak lambat, santai, pendengar yang baik dan simpatik, cinta damai, suka membantu, ramah, peduli, mengasuh, pengertian dan sabar, dermawan, senang memberi, berpusat pada proses, baik hati, sederhana, dan anggota tim yang hebat.
                    Orang yang kepribadiannya merpati dimotivasi oleh berbagi hal yang mereka yakini benar, proses demokrasi, rasa kesatuan dalam tim, bekerja sama, kesempatan untuk membantu dengan tulus, dan instruksi komprehensif.
                    Kekuatan orang ini adalah menghindari pengambilan risiko, dapat dipercaya, santai, senang memberi, jujur, sabar, introvert, serta dapat diandalkan. Sedangkan kelemahannya adalah terlalu pengikut, mudah tertipu, tergantung pada orang lain, dan dapat diprediksi.
                </p>

            </div>
            </>
        )}
        {disc === 'compliant' && (
            <>
                <div className='dopeItem'>
                    <div style={{display: 'flex', alignItems: 'center', gap: '30px'}}>
                        <img src={owl} style={{width: '100px'}} />
                        <p style={{margin: '0px', fontSize: '24px', fontWeight: '900', color: '#1F599C'}}>OWL</p>
                    </div>
                    <p style={{fontSize: '16px', textAlign: 'justify', width: '90%'}}>
                    Orang yang kepribadiannya <span style={{fontWeight: '700'}}>burung hantu</span> biasanya orang yang logis, berpikiran matematis, metodis dan analitis serta terkadang terlihat perfeksionis. Terbiasa fokus pada kualitas, selalu menyelesaikan tugas yang dijalankan, membutuhkan aturan, mampu menerapkan keterampilan diri sendiri, memiliki kemampuan multitasking, dapat dengan mudah beradaptasi, tidak akan langsung menjawab ketika ditanya (terbiasa untuk menunggu, menganalisis, dan kemudian menjawab).
                    Orang yang berkepribadian burung hantu dimotivasi oleh ketersediaan waktu yang cukup untuk menyelesaikan tugas, privasi, kesempatan untuk merencanakan masa depan secara detail, dikelilingi oleh orang-orang dengan karakteristik positif, orang yang membutuhkan dan menghargai, membantu orang lain, serta mencari solusi konflik.
                    Kekuatan orang ini adalah berorientasi pada detail, penuh perhatian dan rasa ingin tahu, teliti, tenang, introvert, berhati-hati, serta konservatif. Serta kelemahannya adalah sulit percaya, egois, bimbang, pendendam, dan picik.
                    </p>

                </div>
            </>
        )}


        </Row>
    )
}

export default Dope
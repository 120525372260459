import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";

const Summary2 = (haisayang) => {
  const data = haisayang.history.location.state.data;
  // console.log(haisayang.history.location.state.data)

  const packages = haisayang.history.location.state.package_id;
  // console.log(haisayang.history.location.state.package_id)

  const token = haisayang.history.location.state.params;

  useEffect(() => {});

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="polasidikjariList">
          <Link
            style={{ width: "max-content" }}
            className="btn buttonVersFutureLinkBorderGrey"
            to={`/report-mobile/${token}`}
          >
            Back
          </Link>
          <h4 style={{ display: "flex", justifyContent: "center" }}>
            Kemampuan Penunjang Akademik
          </h4>
          <div className="grid-container">
            <div className="grid-item">
              <div className="grid-container">
                <div className="grid-item">
                  <h5>Gaya Belajar</h5>
                  <ReactApexChart
                    series={[
                      {
                        data: [
                          {
                            x: "Auditory",
                            y: data.auditory_learning,
                            fillColor: "#4C7AB0",
                          },
                          {
                            x: "Visual",
                            y: data.visual_learning,
                            fillColor: "#1F599C",
                            strokeColor: "#C23829",
                          },
                          {
                            x: "Kinesthetic",
                            y: data.kinesthetic_learning,
                            fillColor: "#19477D",
                          },
                        ],
                      },
                    ]}
                    options={{
                      chart: {
                        id: "basic-bar",
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      yaxis: {
                        labels: {
                          formatter: function (val) {
                            return val + "%";
                          },
                        },
                      },
                    }}
                    type="bar"
                    width={400}
                  />
                </div>
                <div className="grid-item">
                  <h5>Dominasi Otak</h5>
                  <ReactApexChart
                    series={[
                      parseFloat(data.right_brain),
                      parseFloat(data.left_brain),
                    ]}
                    options={{
                      chart: {
                        id: "donut",
                      },
                      labels: ["Otak Kanan", "Otak Kiri"],
                      colors: ["#19477D", "#799BC4"],
                      dataLabels: {
                        enabled: true,
                      },
                      legend: {
                        show: true,
                        // position: "bottom",
                      },
                    }}
                    type="donut"
                    width={400}
                  />
                </div>
                {/* <div className="grid-container"> */}
                <div className="grid-item">
                  <div className="card-gradient">
                    <h6 style={{ color: "white" }}>kecerdasan</h6>
                    <p style={{ color: "white", fontSize: "11px" }}>
                      Kemampuan seseorang untuk menghadapi sesuatu yang dapat
                      diukur.
                    </p>
                  </div>
                </div>

                {/* </div> */}
                <div className="grid-item">
                  <div className="grid-container">
                    <div className="grid-item center-item">
                      <div className="q-title">
                        <h5>IQ Anda</h5>
                      </div>
                      <h1 style={{ color: "green" }}>{data.iq}</h1>
                    </div>
                    <div className="grid-item center-item">
                      <div className="q-title">
                        <h5>EQ Anda</h5>
                      </div>
                      <h1 style={{ color: "red" }}>{data.eq}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-item">
              <div className="q-title center-item">
                <h6>Academic and Career Advice:</h6>
                <p style={{ fontSize: "11px" }}>
                  Pursue academic paths in the arts, such as visual arts, music,
                  or literature, where your auditory learning style and
                  introspective intelligence can thrive. Embrace courses that
                  allow for creativity and self-expression. While fine motor
                  skills may pose difficulties, focus on art forms that
                  accommodate your strengths, such as conceptual or digital art.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "20px",
          }}
        >
          <Link
            className="bottomButton buttonVersFutureLink"
            to={{
              pathname: "/report-mobiles/summary",
              state: { data: data, package_id: packages, params: token },
            }}
            style={{ marginRight: 5 }}
          >
            {"<"} Sebelumnya
          </Link>
          <Link
            className="bottomButton buttonVersFutureLink"
            to={{
              pathname: "/report-mobiles/summary/3",
              state: { data: data, package_id: packages, params: token },
            }}
          >
            Selanjutnya {">"}
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Summary2;

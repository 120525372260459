import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import Lottie from 'react-lottie';
import accessDenied from '../../../../assets/images/FutureLink/accessDenied.json';

import penglihatan from '../../../../assets/images/FutureLink/penglihatan.png';
import pendengar from '../../../../assets/images/FutureLink/pendengar.png';
import peraba from '../../../../assets/images/FutureLink/peraba.png';
import pengecap from '../../../../assets/images/FutureLink/pengecap.png';
import pencium from '../../../../assets/images/FutureLink/pencium.png';

const IndraDasar= (haisayang) => {
    if(haisayang.history.location.state === undefined){
        window.location.href = "/report"
    }

    const data = haisayang.history.location.state.data
    // console.log(haisayang.history.location.state.data)

    const packages = haisayang.history.location.state.package_id
    // console.log(haisayang.history.location.state.package_id)

    return(
        <React.Fragment>
            <div className="page-content">

                {packages === 33 ? (
                    <>
                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                        <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 40px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Indra Dasar</p>
                    </div>

                    <div className='indradasar'>
                        <Link style={{marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to='/report'>Back</Link>

                        <p className='indradasarIntro'>Manusia memiliki lima indra dasar yang biasa disebut dengan <span style={{fontWeight: '900'}}>panca indra</span> yaitu indra penglihatan, pendengar, peraba, pengecap, dan pencium. Organ sensorik terhubung ke otak melalui saraf. Saraf mengirimkan informasi melalui impuls elektrokimia ke otak. Sistem saraf sensorik mengumpulkan dan mengirimkan data sensorik dari lingkungan secara terus-menerus. Informasi tentang warna, bentuk, dan nuansa objek di sekitar ini membantu otak menentukan siapa mereka.</p>

                        <div className='indradasarList'>
                            <div className='indradasarItem'>
                                <img src={penglihatan} className='indradasarImg'/>
                                <div className='item'>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'max-content'}}>
                                        <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Penglihatan</p>
                                        <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{data.sight}%</p>
                                    </div>

                                    <p style={{margin: '0px', fontSize: '14px', textAlign: 'justify'}}><span style={{fontWeight: '900'}}>Indra penglihatan atau mata</span> merupakan alat yang mengirim rangsangan yang berasal dari apa yang kita lihat menuju ke otak. Mata berfungsi untuk memusatkan perhatian pada suatu objek, menyesuaikan cahaya yang masuk dan dapat digunakan sebagai media komunikasi.</p>
                                </div>
                            </div>

                            <div className='indradasarItem'>
                                <img src={pendengar} className='indradasarImg'/>
                                <div className='item'>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'max-content'}}>
                                        <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Pendengar</p>
                                        <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{data.hear}%</p>
                                    </div>

                                    <p style={{margin: '0px', fontSize: '14px', textAlign: 'justify'}}><span style={{fontWeight: '900'}}>Indra pendengar atau telinga</span> merupakan alat yang memiliki kemampuan untuk mengetahui berbagai macam getaran bunyi. Telinga berfungsi untuk mendengarkan bunyi dan menjaga keseimbangan.</p>
                                </div>
                            </div>

                            <div className='indradasarItem'>
                                <img src={peraba} className='indradasarImg'/>
                                <div className='item'>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'max-content'}}>
                                        <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Peraba</p>
                                        <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{data.touch}%</p>
                                    </div>
                                    <p style={{margin: '0px', fontSize: '14px', textAlign: 'justify'}}><span style={{fontWeight: '900'}}>Indra peraba atau kulit</span> merupakan alat untuk merasakan berbagai macam hal melalui sentuhan. Kulit berfungsi untuk jalan keluar keringat, tempat penyimpanan lemak, mengatur suhu tubuh, dan melindungi bagian tubuh di dalamnya.</p>
                                </div>
                            </div>

                            <div className='indradasarItem'>
                                <img src={pengecap} className='indradasarImg'/>
                                <div className='item'>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'max-content'}}>
                                        <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Pengecap</p>
                                        <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{data.taste}%</p>
                                    </div>

                                    <p style={{margin: '0px', fontSize: '14px', textAlign: 'justify'}}><span style={{fontWeight: '900'}}>Indra pengecap atau lidah</span> merupakan alat yang digunakan untuk merasakan segala sesuatu yang masuk melalui mulut. Lidah berfungsi sebagai perasa, pembentuk huruf ketika berbicara, dan membantu proses pengunyahan dan menelan makanan.</p>
                                </div>
                            </div>

                            <div className='indradasarItem'>
                                <img src={pencium} className='indradasarImg'/>
                                <div className='item'>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: 'max-content'}}>
                                        <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Pencium</p>
                                        <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{data.smell}%</p>
                                    </div>

                                    <p style={{margin: '0px', fontSize: '14px', textAlign: 'justify'}}><span style={{fontWeight: '900'}}>Indra pencium atau hidung</span> merupakan alat yang dapat menangkap berbagai macam aroma. Hidung berfungsi untuk mencium bau, menyaring udara, dan sebagai alat pernafasan.</p>
                                </div>
                            </div>
                        </div>

                        <div style={{display: 'flex', justifyContent:'space-between', padding: '20px'}}>
                            <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report/kecerdasan", state: { data: data, package_id: packages }}}>{"<"} Kecerdasan</Link>
                            <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report/gaya-berpikir", state: { data: data, package_id: packages }}}>Gaya Berpikir {">"}</Link>
                        </div>
                    </div>
                    </>
                ) : (
                    <>
                        <Link style={{marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to='/report'>Back</Link>
                        <Lottie options={{loop: true,autoplay: true,animationData: accessDenied,rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} isClickToPauseDisabled={true} />
                        <p className='upgradePlease'> Maaf, Anda belum melakukan Upgrade yang lebih tinggi</p>
                    </>
                )}

            

            </div>
        </React.Fragment>
    )

}

export default IndraDasar;
import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody, ModalFooter, Row, Label, Input, Alert, UncontrolledCarousel } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import axios from 'axios';
import FormData from 'form-data';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import DOMPurify from "dompurify";

import deleteData from '../../../assets/images/FutureLink/deleteData.png'
import accessDenied from '../../../assets/images/FutureLink/accessDenied.png'

import Lottie from 'react-lottie';
import animateSuccess from '../../../assets/images/FutureLink/success.json'

// Import React FilePond
import { FilePond,  registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const ModulArticle = () => {
    // document.title="Widgets | Velzon - React Admin & Dashboard Template";
    const [modalAccessDenied, setModalAccessDenied] = useState(false);
    const logOut = () => {
      sessionStorage.removeItem('user');
      window.location.href = '/login'
    }

    const code = sessionStorage.getItem('user');
    const [step, setStep] = useState(0);
    const [arcStep, setArcStep] = useState(0);

    const [spotlight, setSpotlight] = useState([]);
    const [idSpotlight, setIdSpotlight] = useState(0);
    const [files, setFiles] = useState([]);

    const [modalAdd, setModalAdd] = useState(false);
    const [modalUpdate, setModalUpdate] = useState(false);

    const [listArticle, setListArticle] = useState([]);
    const [articleId, setArticleId] = useState("");

    const [listVideo, setListVideo] = useState([]);
    const [videoId, setVideoId] = useState("");

    const [judul, setJudul] = useState('');
    const [pengarang, setPengarang] = useState('');
    const [fileImage, setFileImage] = useState("");
    const [quill, setQuill] = useState('');
    const [checkUrl, setCheckUrl] = useState("");

    const [url, setUrl] = useState("")

    const [upVideo, setUpVideo] = useState(true);

    const [modalDeleteArticle, setModalDeleteArticle] = useState(false);
    const [modalDeleteVideo, setModalDeleteVideo] = useState(false);

    const generate = () => {
        random_kode(11);
    };
    
    function random_kode(string_length) {
        var random_string = "";
        var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < string_length; i++) {
            random_string += characters.charAt(
            Math.floor(Math.random() * characters.length)
          );
        }
        setArticleId(random_string);
    }

    useEffect(() => {
        getAllSpotlight()
    },[])
    
    const getAllSpotlight = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-spotlight`,
            headers: { 
              'Authorization': `Bearer ${code}`
            }
        };
          
        axios.request(config)
            .then((response) => {
            // console.log(response.results);
            setSpotlight(response.results.map((item, index) => {return {id: item.id, src: item.linkMinio, key:index}}))
            })
            .catch((error) => {
            // console.log(error);
            if(error.response.data.statusCode === 403) {
                setModalAccessDenied(true)
            }
            });
    }

    const addSpotlight = () => {
        let data = new FormData();
        files.map((item) => {
            data.append('image', item.file);
        })
        

        let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/create-spotlight`,
        headers: { 
            'Authorization': `Bearer ${code}`, 
        },
        data : data
        };

        axios.request(config)
        .then((response) => {
        // console.log(response);
        setModalAdd(false);
        getAllSpotlight()
        })
        .catch((error) => {
        // console.log(error);
        if(error.response.data.statusCode === 403) {
            setModalAccessDenied(true)
        }
        });
    }

    const updateSpotlight = () => {
        
        let data = new FormData();
        data.append('spotlightId', idSpotlight);
        data.append('image', files);
        
        let config = {
          method: 'patch',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/update-spotlight`,
          headers: { 
            'Authorization': `Bearer ${code}`
          },
          data : data
        };
        
        axios.request(config)
        .then((response) => {
        //   console.log(response);
          getAllSpotlight()
          setModalUpdate(false);
        })
        .catch((error) => {
        //   console.log(error);
          if(error.response.data.statusCode === 403) {
            setModalAccessDenied(true)
        }
        });
    }

    const deleteSpotlight = () => {
        let config = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/delete-spotlight?spotlightId=${idSpotlight}`,
            headers: { 
              'Authorization': `Bearer ${code}`
            }
        };
          
        axios.request(config)
            .then((response) => {
            // console.log(response);
            setModalUpdate(false);
            getAllSpotlight()
            })
            .catch((error) => {
            // console.log(error);
            if(error.response.data.statusCode === 403) {
                setModalAccessDenied(true)
            }
            });
    }

    const toolbarOptions = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block', 'link'],
      
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'align': [] }],
      
        ['clean']                                         // remove formatting button
    ]}

    const getAllArticle = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-article`,
            headers: { 
              'Authorization': `Bearer ${code}`
            }
          };
          
          axios.request(config)
          .then((response) => {
            setListArticle(response.results)
            // console.log(response.results);
          })
          .catch((error) => {
            // console.log(error);
            if(error.response.data.statusCode === 403) {
                setModalAccessDenied(true)
            }
          });
    }

    const addArticle = () => {

        let data = new FormData();
        data.append('articleId', articleId)
        data.append('headerImage', fileImage);
        data.append('articleTitle', judul);
        data.append('articleContent', quill);
        data.append('author', pengarang);
        data.append('url', `${process.env.REACT_APP_BASE_ENDPOINT_FRONTEND}/article/${articleId}`)


        let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/create-article`,
        headers: { 
            'Authorization': `Bearer ${code}`, 
        },
        data : data
        };

        axios.request(config)
        .then((response) => {
        // console.log(response);
        window.location.reload()
        })
        .catch((error) => {
        // console.log(error);
        if(error.response.data.statusCode === 403) {
            setModalAccessDenied(true)
        }
        });
    }

    const updateArticle = () => {
        let data = new FormData();
        data.append('articleId', articleId);
        if(fileImage !== "") {
            data.append('headerImage', fileImage);
        }
        data.append('articleTitle', judul);
        data.append('articleContent', quill);
        data.append('author', pengarang);
        data.append('url', `${process.env.REACT_APP_BASE_ENDPOINT_FRONTEND}/article/${articleId}`)

        let config = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/update-article`,
            headers: { 
                'Authorization': `Bearer ${code}`, 
            },
            data : data
        };

        axios.request(config)
        .then((response) => {
            // console.log(response);
            window.location.reload();
            })
            .catch((error) => {
            // console.log(error);
            if(error.response.data.statusCode === 403) {
                setModalAccessDenied(true)
            }
        });
    }

    const deleteArticle = () => {
        let config = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/delete-article?articleId=${articleId}`,
            headers: { 
              'Authorization': `Bearer ${code}`
            }
          };
          
          axios.request(config)
          .then((response) => {
            // console.log(response);
            setModalDeleteArticle(false);
            window.location.reload();
          })
          .catch((error) => {
            // console.log(error);
            if(error.response.data.statusCode === 403) {
                setModalAccessDenied(true)
            }
          });
    }

    const getAllVideo = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-videos`,
            headers: { 
              'Authorization': `Bearer ${code}`
            }
        };
          
        axios.request(config)
            .then((response) => {
            // console.log(response.results);
            setListVideo(response.results);
            })
            .catch((error) => {
            // console.log(error);
            if(error.response.data.statusCode === 403) {
                setModalAccessDenied(true)
            }
            });
    }

    const addVideo = () => {
        let data = new FormData();
        data.append('title', judul);
        data.append('url', url);
        data.append('clickbait', fileImage);

        let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/upload-video`,
        headers: { 
            'Authorization': `Bearer ${code}`, 
        },
        data : data
        };

        axios.request(config)
        .then((response) => {
        // console.log(response);
        })
        .catch((error) => {
        // console.log(error);
        if(error.response.data.statusCode === 403) {
            setModalAccessDenied(true)
        }
        });
    }

    const deleteVideo = () => {
          
        let config = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/delete-video?id=${videoId}`,
            headers: { 
                'Authorization': `Bearer ${code}`
            },
        };
          
        axios.request(config)
            .then((response) => {
            // console.log(response);
            window.location.reload()
            })
            .catch((error) => {
            // console.log(error);
            if(error.response.data.statusCode === 403) {
                setModalAccessDenied(true)
            }
            });
    }

    const updateVideo = () => {
        let data = new FormData();
        data.append('title', judul);
        data.append('url', url);
        if(fileImage !== "") {
            data.append('clickbait', fileImage);
        }
        data.append('id', videoId);

        let config = {
        method: 'patch',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/update-video`,
        headers: { 
            'Authorization': `Bearer ${code}`, 
        },
        data : data
        };

        axios.request(config)
        .then((response) => {
            window.location.reload()
        // console.log(response);
        })
        .catch((error) => {
        // console.log(error);
        });
    }
 
    return ( 
        <React.Fragment>
            <Container fluid>
                <div className="page-content">
                    <div className='artikelHeader'>
                        <div className='artikelItem'>
                            <button className='buttonVersFutureLinkTeal buttonHeader' onClick={() => {setStep(0)}}>Spotlight</button>
                        </div>
                        <div className='artikelItem'>
                            <button className='buttonVersFutureLinkGreen buttonHeader' onClick={() => {setStep(1); getAllArticle(); setArcStep(0);}}>Article</button>
                        </div>
                        <div className='artikelItem'>
                            <button className='buttonVersFutureLink buttonHeader' onClick={() => {setStep(2); getAllVideo(); setArcStep(0)}}>Video</button>
                        </div>
                    </div>
                    {step === 0 && (
                        <>
                            <div>
                                <button className='btn buttonVersFutureLink' style={{marginBottom: '20px'}} onClick={() => {setModalAdd(true); setFiles([])}}> + Add Spotlight</button>

                                <h2>Preview Spotlight</h2>
                                <div className='spotlightPreview'>
                                    {spotlight.map((item, index) => (
                                        <>
                                        <div className="spotlightItem" key={index} >

                                            <img src={item.src} onClick={() => {setModalUpdate(true); setIdSpotlight(item.id); setFiles([])}} style={{width: 'max-content', height: 'max-content'}} />

                                        </div>
                                        </>
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                    {step === 1 && (
                        <>
                            {arcStep === 0 && (
                                <>
                                <button className='btn buttonVersFutureLink' style={{marginBottom: '20px'}} onClick={() => {setArcStep(1); generate()}}> + Add Article</button>
                                <div className='listArticle'>
                                {listArticle.map((item, index) => (
                                    <>
                                        <div className='cardArticle' key={index} onClick={()=>{setArcStep(1); setJudul(item.article_title); setPengarang(item.author); setQuill(item.article_content); setCheckUrl(item.url); setArticleId(item.article_id)}}>
                                            <img className='articleImage' src={item.linkMinio}/>
                                            <div className='articleKeterangan'>
                                                <p className='articleJudul'>{item.article_title}</p>
                                                <p className='articleTanggal'>{new Date(item.created_at).toLocaleDateString('id-ID', {day: 'numeric', month: 'long', year: 'numeric'})}</p>
                                            </div>
                                            
                                        </div>
                                    </>
                                ))}
                                </div>
                                </>
                            )}
                            {arcStep === 1 && (
                                <>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <button className='btn buttonVersFutureLinkBorderGrey' onClick={() => {setArcStep(0); setJudul(""); setFileImage([]); setPengarang(""); setQuill(""); getAllArticle(); setCheckUrl(""); setArticleId("")}}> Back </button>
                                        {checkUrl !== "" && (
                                            <>
                                                <button className='btn buttonVersFutureLinkRed' onClick={() => {setModalDeleteArticle(true)}}> Delete Article </button>
                                            </>
                                        )}
                                    </div>


                                    <div className="mb-3 mt-3">
                                        <label htmlFor="judul" className="form-label">Title</label>
                                        <input type="text" className="form-control" placeholder="Judul" value={judul} onChange={(event) => {setJudul(event.target.value)}} />
                                    </div>

                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <input type='file' onChange={(event) => {setFileImage(event.target.files[0])}} />
                                        <button className='btn buttonVersFutureLinkRed' onClick={() => {setFileImage([])}}>Cancel</button>
                                    </div>
                                    

                                    <div className="mb-3 mt-3">
                                        <label htmlFor="pengarang" className="form-label">Author</label>
                                        <input type="text" className="form-control" placeholder="pengarang" value={pengarang} onChange={(event) => {setPengarang(event.target.value)}} />
                                    </div>

                                    <ReactQuill theme="snow" value={quill} onChange={(event) => {setQuill(event)}} modules={toolbarOptions}/>

                                    <div style={{display: 'flex', gap: '25px'}}>
                                        {checkUrl === "" && (
                                            <>
                                                <button className='btn buttonVersFutureLinkGreen' style={{margin: '20px 0px'}} onClick={() => {addArticle()}}>Create Article</button>
                                            </>
                                        )}

                                        {checkUrl !== "" && (
                                            <>
                                            <button className='btn buttonVersFutureLinkGreen' style={{margin: '20px 0px'}} onClick={() => {updateArticle()}}>Save Article</button>
                                            <button className='btn buttonVersFutureLinkTeal' style={{margin: '20px 0px'}} onClick={() => { window.open(`${checkUrl}`, "_blank")}}>View Article</button>
                                            </>
                                        )}

                                    </div>

                                    {fileImage.length === 0 ? (
                                        <>

                                        </>
                                    ):(
                                        <>
                                        <img src={URL.createObjectURL(fileImage)} style={{width: '100%', height: '300px', objectFit: 'cover'}} />
                                        </>
                                    )}

                                    <p style={{fontSize: '28px', fontWeight: '600'}}>{judul}</p>
                                    <p style={{fontSize: '14px', fontWeight: '600'}}>Author : <em>{pengarang}</em></p>
                                    <p style={{fontSize: '14px'}} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(quill)}}></p>
                                </>
                            )}
                        </>
                    )}
                    {step === 2 && (
                        <>
                            {arcStep === 0 && (
                                <>
                                <button className='btn buttonVersFutureLink' style={{marginBottom: '20px'}} onClick={() => {setArcStep(1); setJudul(""); setUrl(""); setFileImage([]); setUpVideo(true)}}> + Add Video</button>
                                <div className='listArticle'>
                                {listVideo.map((item, index) => (
                                    <>
                                        <div className='cardArticle' key={index} onClick={()=>{setArcStep(1); setJudul(item.title); setUrl(item.url); setVideoId(item.id); setUpVideo(false)}}>
                                            <img className='articleImage' src={item.clickbait}/>
                                            <div className='articleKeterangan'>
                                                <p className='articleJudul'>{item.title}</p>
                                                <p className='articleTanggal'>{new Date(item.created_at).toLocaleDateString('id-ID', {day: 'numeric', month: 'long', year: 'numeric'})}</p>
                                            </div>
                                        </div>
                                    </>
                                ))}
                                </div>
                                </>
                            )}
                            {arcStep === 1 && (
                                <>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <button className='btn buttonVersFutureLinkBorderGrey' onClick={() => {setArcStep(0); setJudul(""); setFileImage([]); setUrl("")}}> Back </button>
                                        {videoId !== "" && (
                                            <>
                                            <button className='btn buttonVersFutureLinkRed' onClick={() => {setModalDeleteVideo(true)}}> Delete Video </button>
                                            </>
                                        )}
                                    </div>

                                    <div className="mb-3 mt-3">
                                        <label htmlFor="judul" className="form-label">Judul</label>
                                        <input type="text" className="form-control" placeholder="Judul" value={judul} onChange={(event) => {setJudul(event.target.value)}} />
                                    </div>

                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <input type='file' onChange={(event) => {setFileImage(event.target.files[0])}} />
                                        <button className='btn buttonVersFutureLinkRed' onClick={() => {setFileImage([])}}>Cancel</button>
                                    </div>

                                    <div className="mb-3 mt-3">
                                        <label htmlFor="url" className="form-label">Url</label>
                                        <input type="text" className="form-control" placeholder="https://...." value={url} onChange={(event) => {setUrl(event.target.value)}} />
                                    </div>


                                    {upVideo === true ? (
                                        <>

                                    <div style={{display: 'flex', gap: '25px'}}>
                                        <button className='btn buttonVersFutureLinkGreen' style={{margin: '20px 0px'}} onClick={() => {addVideo()}}>Create Video</button>
                                    </div>

                                        </>
                                    ) : (
                                        <>

                                    <div style={{display: 'flex', gap: '25px'}}>
                                        <button className='btn buttonVersFutureLinkGreen' style={{margin: '20px 0px'}} onClick={() => {updateVideo()}}>Update Video</button>
                                    </div>

                                        </>
                                    )}

                                </>
                            )}
                        
                        </>
                    )}

                    
                </div>

                <Modal isOpen={modalAccessDenied} toggle={() => {}} centered>
                    <ModalBody className='modalCenter'> 
                        <img src={accessDenied} style={{width: '30%'}}/>
                        <p style={{fontSize: '24px', fontWeight: '700', marginTop: '20px'}}>Access Denied</p>
                        <p style={{margin: '0px', color: 'grey'}}>You currently does not have access to this page. <br/> Please Login Again</p>
                    </ModalBody>
                    <ModalFooter style={{display: 'flex', justifyContent: 'center', gap: '80px'}}>
                        <button className='btn buttonVersFutureLink' style={{fontSize: '14px', fontWeight: '500'}} onClick={() => {logOut()}}>Login Again</button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={modalAdd} toggle={() => { setModalAdd(!modalAdd)}} centered >
                    <ModalHeader toggle={() => {setModalAdd(!modalAdd)}}>
                        <h4>Add Spotlight</h4>
                    </ModalHeader>

                    <ModalBody>
                        <FilePond
                            onupdatefiles={setFiles}
                            allowMultiple={true}
                            name="files"
                            className="filepond filepond-input-multiple"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button className='btn buttonVersFutureLinkBorderGrey ' onClick={() => {setModalAdd(false)}}>Close</button>
                            <button className='btn buttonVersFutureLink' onClick={() => {addSpotlight()}}>Update</button>
                        </div>
                    </ModalFooter>

                </Modal>

                <Modal isOpen={modalUpdate} toggle={() => { setModalUpdate(!modalUpdate); setIdSpotlight(0) }} centered >
                    <ModalHeader toggle={() => {setModalUpdate(!modalUpdate); setIdSpotlight(0)}}>
                        <h4>Change Spotlight</h4>
                    </ModalHeader>

                    <ModalBody>
                        {/* <Alert className={'alertRed ' + fade} hidden={alert}>Check Again</Alert> */}

                        <FilePond
                            onupdatefiles={(event) => {setFiles(event[0].file)}}
                            allowMultiple={false}
                            name="files"
                            className="filepond filepond-input-multiple"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button className='btn buttonVersFutureLinkRed ' onClick={() => {deleteSpotlight()}}>Delete</button>
                            <button className='btn buttonVersFutureLink' onClick={() => {updateSpotlight()}}>Update</button>
                        </div>
                    </ModalFooter>

                </Modal>

                <Modal isOpen={modalDeleteArticle} toggle={() => {setModalDeleteArticle(!modalDeleteArticle)}} centered>
                    <ModalBody className='modalCenter'> 
                        <img src={deleteData}/>
                        <p style={{fontSize: '24px', fontWeight: '700', marginTop: '20px'}}>You are about to delete Article</p>
                        <p style={{margin: '0px', color: 'grey'}}>This we will delete your Article from FutureLink <br/> Are you sure ?</p>
                    </ModalBody>
                    <ModalFooter style={{display: 'flex', justifyContent: 'center', gap: '80px'}}>
                        <button className='btn buttonVersFutureLinkBorderGrey ' onClick={() => {setModalDeleteArticle(false)}}>Close</button>
                        <button className='btn buttonVersFutureLinkRed' onClick={() => {deleteArticle()}}>Delete</button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={modalDeleteVideo} toggle={() => {setModalDeleteVideo(!modalDeleteVideo)}} centered>
                    <ModalBody className='modalCenter'>
                        <img src={deleteData}/>
                        <p style={{fontSize: '24px', fontWeight: '700', marginTop: '20px'}}>You are about to delete Video </p>
                        <p style={{margin: '0px', color: 'grey'}}>This we will delete your Video from FutureLink <br/> Are you sure ?</p>
                    </ModalBody>
                    <ModalFooter style={{display: 'flex', justifyContent: 'center', gap: '80px'}}>
                        <button className='btn buttonVersFutureLinkBorderGrey ' onClick={() => {setModalDeleteVideo(false)}}>Close</button>
                        <button className='btn buttonVersFutureLinkRed' onClick={() => {deleteVideo()}}>Delete</button>
                    </ModalFooter>
                </Modal>
            </Container>
        </React.Fragment>
    );
};

export default ModulArticle;
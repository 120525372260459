import React, { useState, useEffect } from "react";
import { io } from "socket.io-client";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Alert,
} from "reactstrap";

import axios from "axios";
import FormData from "form-data";

import deleteData from "../../../assets/images/FutureLink/deleteData.png";
import accessDenied from "../../../assets/images/FutureLink/accessDenied.png";

import Lottie from "react-lottie";
import animateSuccess from "../../../assets/images/FutureLink/success.json";
import animateDelete from "../../../assets/images/FutureLink/delete.json";

const socket = io(process.env.REACT_APP_BASE_ENDPOINT_BACKEND);
socket.on("connect", () => {
  // console.log(socket.id);
});
const ModulInstansi = () => {
  // document.title="Widgets | Velzon - React Admin & Dashboard Template";
  const [modalAccessDenied, setModalAccessDenied] = useState(false);
  const [kata, setKata] = useState("");
  const logOut = () => {
    sessionStorage.removeItem("user");
    window.location.href = "/login";
  };

  const code = sessionStorage.getItem("user");
  const [kontenError, setKontenError] = useState("Check Again");

  const [step, setStep] = useState(0);
  const [jumlah, setJumlah] = useState(0);
  const [seeAll, setSeeAll] = useState(false);
  const [packages, setPackages] = useState([]);

  const [users, setUsers] = useState([]);
  const [fade, setFade] = useState("fadeout");
  const [alert, setAlert] = useState(true);
  const [modalAlertSuccess, setModalAlertSuccess] = useState(false);
  const [modalAlertDelete, setModalAlertDelete] = useState(false);

  const [instansi, setInstansi] = useState([]);
  const [idInstansi, setIdInstansi] = useState("");
  const [userId, setUserId] = useState("");

  const [upPackages, setUpPackages] = useState(1);
  const [forMultiple, setForMultiple] = useState([]);

  //modal
  const [modalAdd, setModalAdd] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalUpgrade, setModalUpgrade] = useState(false);
  const [modalDownload, setModalDownload] = useState(false);
  const [modalDeleteClient, setModalDeleteClient] = useState(false);
  //end modal

  const [name, setName] = useState("");
  const [sector, setSector] = useState("");

  useEffect(() => {
    getAllInstansi();
    getAllPackages();
  }, []);

  const getAllInstansi = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-agency`,
      headers: {
        Authorization: `Bearer ${code}`,
      },
    };

    axios(config)
      .then((response) => {
        // console.log(response.results);
        setInstansi(response.results);
      })
      .catch((error) => {
        // console.log(error);
        if (error.response.data.statusCode === 403) {
          setModalAccessDenied(true);
        }
      });
  };

  const getAllPackages = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      // url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-packages?status=1`,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-packages`,
      headers: {
        Authorization: `Bearer ${code}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response);
        setPackages(response.results);
      })
      .catch((error) => {
        // console.log(error);
        if (error.response.data.statusCode === 403) {
          setModalAccessDenied(true);
        }
      });
  };

  const getAllClientWithFilter = (agency_id) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-privilege-user?privilegeId=51`,
      headers: {
        Authorization: `Bearer ${code}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        const data = response.results;
        const sortedData = [...data].sort((a, b) => a.user_id - b.user_id);
        const filter = sortedData.filter((item) => item.agency_id == agency_id);
        const reMap = filter.map((item) => {
          return {
            select: false,
            agency_id: item.agency_id,
            agency_name: item.agency_name,
            date_birth: item.date_birth,
            email: item.email,
            first_name: item.first_name,
            last_name: item.last_name,
            package_id: item.package_id,
            package_name: item.package_name,
            sex: item.sex,
            unique_code: item.unique_code,
            user_id: item.user_id,
          };
        });
        // console.log(reMap);
        setUsers(reMap);
      })
      .catch((error) => {
        // console.log(error);
        if (error.response.data.statusCode === 403) {
          setModalAccessDenied(true);
        }
      });
  };

  const getInstansi = (agency_id) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-agency?agencyId=${agency_id}`,
      headers: {
        Authorization: `Bearer ${code}`,
      },
    };

    axios(config)
      .then((response) => {
        // console.log(response.results[0]);
        setName(response.results[0].name);
        setSector(response.results[0].sector);
      })
      .catch((error) => {
        // console.log(error);
        if (error.response.data.statusCode === 403) {
          setModalAccessDenied(true);
        }
      });
  };

  const deleteInstansi = () => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/delete-agency?agencyId=${idInstansi}`,
      headers: {
        Authorization: `Bearer ${code}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response);
        getAllInstansi();
        setModalDelete(false);

        setModalAlertDelete(true);
        setTimeout(() => {
          setModalAlertDelete(false);
        }, 4000);
      })
      .catch((error) => {
        // console.log(error);
        if (error.response.data.statusCode === 403) {
          setModalAccessDenied(true);
        }
      });
  };

  const deleteClient = () => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/delete-user?userId=${userId}`,
      headers: {
        Authorization: `Bearer ${code}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response);
        setModalDeleteClient(false);
        window.location.reload();
      })
      .catch((error) => {
        // console.log(error);
        if (error.response.data.statusCode === 403) {
          setModalAccessDenied(true);
        }
      });
  };

  const addInstansi = () => {
    if (name === "" || sector === "") {
      setAlert(false);
      setKontenError("Field cannot be empty");

      setTimeout(() => {
        setFade("fade");
      }, 200);

      setTimeout(() => {
        setFade("fadeout");
      }, 4500);

      setTimeout(() => {
        setAlert(true);
      }, 5000);
    } else {
      let data = new FormData();
      data.append("name", name);
      data.append("sector", sector);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/make-agency`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          // console.log(response);
          getAllInstansi();
          setModalAdd(false);

          setModalAlertSuccess(true);
          setTimeout(() => {
            setModalAlertSuccess(false);
          }, 4000);
        })
        .catch((error) => {
          // console.log(error);

          if (error.response.data.statusCode === 403) {
            setModalAccessDenied(true);
          }

          setAlert(false);

          setTimeout(() => {
            setFade("fade");
          }, 200);

          setTimeout(() => {
            setFade("fadeout");
          }, 4500);

          setTimeout(() => {
            setAlert(true);
          }, 5000);
        });
    }
  };

  const updateInstansi = () => {
    if (name === "" || sector === "") {
      setAlert(false);
      setKontenError("Field cannot be empty");

      setTimeout(() => {
        setFade("fade");
      }, 200);

      setTimeout(() => {
        setFade("fadeout");
      }, 4500);

      setTimeout(() => {
        setAlert(true);
        setKontenError("Check Again");
      }, 5000);
    } else {
      let data = new FormData();
      data.append("agencyId", idInstansi);
      data.append("name", name);
      data.append("sector", sector);

      let config = {
        method: "patch",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/update-agency`,
        headers: {
          Authorization: `Bearer ${code}`,
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          // console.log(response);
          getAllInstansi();
          setModalUpdate(false);

          setModalAlertSuccess(true);
          setTimeout(() => {
            setModalAlertSuccess(false);
          }, 4000);
        })
        .catch((error) => {
          // console.log(error);
          setAlert(false);

          if (error.response.data.statusCode === 403) {
            setModalAccessDenied(true);
          }

          setTimeout(() => {
            setFade("fade");
          }, 200);

          setTimeout(() => {
            setFade("fadeout");
          }, 4500);

          setTimeout(() => {
            setAlert(true);
          }, 5000);
        });
    }
  };

  // console.log(forMultiple);

  const getFingerprintByID = (user_id) => {
    let data = new FormData();

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-fingerprint-by-id?userId=${user_id}`,
      headers: {
        Authorization: `Bearer ${code}`,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const upgradePackages = () => {
    let data = JSON.stringify({
      userIds: forMultiple,
      packageId: parseInt(upPackages),
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/upgrade-multiple-report`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${code}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response);

        setModalUpgrade(false);
        setModalAlertSuccess(true);
        setTimeout(() => {
          setModalAlertSuccess(false);
        }, 4000);
      })
      .catch((error) => {
        // console.log(error);
        if (error.response.data.statusCode === 403) {
          setModalAccessDenied(true);
        }
        if (error.response.data.statusCode === 400) {
          setAlert(false);
          setKontenError("Im Sorry, Something Wrong :(");

          setTimeout(() => {
            setFade("fade");
          }, 200);

          setTimeout(() => {
            setFade("fadeout");
          }, 4500);

          setTimeout(() => {
            setAlert(true);
            setKontenError("Check Again");
          }, 5000);
        }
      });

    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Authorization", `Bearer ${code}`);

    // var raw = JSON.stringify({
    // "userIds": forMultiple,
    // "packageId": parseInt(upPackages)
    // });

    // var requestOptions = {
    // method: 'POST',
    // headers: myHeaders,
    // body: raw,
    // redirect: 'follow'
    // };

    // fetch("https://v1-backend.futurelink.id/v1/upgrade-multiple-report", requestOptions)
    // .then(response => response.text())
    // .then(result => console.log(result))
    // .catch(error => console.log('error', error));
  };

  const downloadMultiple = () => {
    let data = JSON.stringify({ userIds: forMultiple });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/download-multiple-report/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${code}`,
      },
      responseType: "blob",
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        // console.log(error);
        if (error.response.data.statusCode === 403) {
          setModalAccessDenied(true);
        }
      });
  };

  const loginDalem = (uniqueCode) => {
    let data = new FormData();
    data.append("uniqueCode", uniqueCode);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/login-unique-code`,
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response);
        if (response.statusCode === 200) {
          window.open(
            `${process.env.REACT_APP_BASE_ENDPOINT_FRONTEND}/report-available/${response.results.accessToken}`,
            "_blank"
          );
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <React.Fragment>
      <Container fluid>
        <div className="page-content">
          <Row>
            {step === 0 && (
              <>
                <Col lg={12}>
                  <Card>
                    <CardHeader>
                      <h4 className="card-title mb-0">Agency Table</h4>
                    </CardHeader>

                    <CardBody>
                      <div id="customerList">
                        <Row className="g-4 mb-3">
                          <Col className="col-sm-auto">
                            <div>
                              <button
                                className="btn buttonVersFutureLinkGreen"
                                onClick={() => {
                                  setModalAdd(true);
                                  setName("");
                                  setSector("");
                                }}
                              >
                                <i className="ri-add-line align-bottom me-1"></i>{" "}
                                Add
                              </button>
                            </div>
                          </Col>
                        </Row>

                        <div className="table-responsive table-card mt-3 mb-1">
                          <table
                            className="table align-middle table-nowrap"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                <th className="sort" data-sort="no">
                                  No
                                </th>
                                <th className="sort" data-sort="nama">
                                  Name
                                </th>
                                <th className="sort" data-sort="email">
                                  Sector
                                </th>
                                <th className="sort" data-sort="action">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                                <tr>
                                  <td className="no">0</td>
                                  <td className="nama">All User</td>
                                  <td className="email">All User</td>
                                  <td>
                                    <div className="d-flex gap-2">
                                      <div className="edit">
                                        <button
                                          className="btn buttonVersFutureLinkTeal"
                                          onClick={() => {
                                            getAllClientWithFilter(
                                              null
                                            );
                                            setStep(1);
                                            setForMultiple([]);
                                          }}
                                        >
                                          View Client
                                        </button>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              {instansi.map((item, index) => (
                                <tr key={index}>
                                  <td className="no">{index + 1}</td>
                                  <td className="nama">{item.name}</td>
                                  <td className="email">{item.sector}</td>
                                  <td>
                                    <div className="d-flex gap-2">
                                      <div className="edit">
                                        <button
                                          className="btn buttonVersFutureLinkTeal"
                                          onClick={() => {
                                            getAllClientWithFilter(
                                              item.agency_id
                                            );
                                            setStep(1);
                                            setForMultiple([]);
                                          }}
                                        >
                                          View Client
                                        </button>
                                      </div>
                                      <div className="edit">
                                        <button
                                          className="btn buttonVersFutureLinkGreen"
                                          onClick={() => {
                                            setModalUpdate(true);
                                            getInstansi(item.agency_id);
                                            setIdInstansi(item.agency_id);
                                            setName("");
                                            setSector("");
                                          }}
                                        >
                                          Edit
                                        </button>
                                      </div>
                                      <div className="remove">
                                        <button
                                          className="btn buttonVersFutureLinkRed"
                                          onClick={() => {
                                            setModalDelete(true);
                                            setIdInstansi(item.agency_id);
                                          }}
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </>
            )}
            {step === 1 && (
              <>
                <button
                  className="btn buttonVersFutureLinkBorderGrey"
                  style={{ width: "70px", marginLeft: "20px" }}
                  onClick={() => {
                    setStep(0);
                    setUsers([]);
                    setForMultiple([]);
                    setKata("");
                  }}
                >
                  {" "}
                  Back{" "}
                </button>

                <Col lg={12}>
                  <Card>
                    <CardHeader>
                      <h4 className="card-title mb-0">
                        List Client with Agency
                      </h4>
                    </CardHeader>

                    <CardBody>
                      <div id="customerList">
                        <Row className="g-4 mb-3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <div style={{ display: "flex", gap: "20px" }}>
                              <button
                                className="btn buttonVersFutureLink"
                                onClick={() => {
                                  setJumlah(
                                    users.filter((item) => item.select === true)
                                      .length
                                  );
                                  setModalUpgrade(true);
                                  setForMultiple(
                                    users
                                      .filter((item) => item.select === true)
                                      .map((item) => item.user_id)
                                  );
                                }}
                              >
                                Upgrade Packages
                              </button>
                              <button
                                className="btn buttonVersFutureLinkGreen"
                                onClick={() => {
                                  setJumlah(
                                    users.filter((item) => item.select === true)
                                      .length
                                  );
                                  setModalDownload(true);
                                  setForMultiple(
                                    users
                                      .filter((item) => item.select === true)
                                      .map((item) => item.user_id)
                                  );
                                }}
                              >
                                Download
                              </button>
                              <div className="search-box ms-2">
                                <input
                                  type="text"
                                  className="form-control search"
                                  placeholder="Search..."
                                  onChange={(e) => setKata(e.target.value)}
                                />
                                <i className="ri-search-line search-icon"></i>
                              </div>
                            </div>
                          </div>
                        </Row>

                        <div className="table-responsive table-card mt-3 mb-1">
                          <table
                            className="table align-middle table-nowrap"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                <th scope="col" style={{ width: "50px" }}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      onChange={(event) => {
                                        setUsers(
                                          users.map((hehe) => {
                                            {
                                              hehe.select =
                                                event.target.checked;
                                            }
                                            return hehe;
                                          })
                                        );
                                      }}
                                    />
                                  </div>
                                </th>
                                <th className="sort" data-sort="no">
                                  No
                                </th>
                                <th className="sort" data-sort="nama">
                                  Name
                                </th>
                                <th className="sort" data-sort="email">
                                  Email
                                </th>
                                <th className="sort" data-sort="status">
                                  Package
                                </th>
                                <th className="sort" data-sort="instansi">
                                  Agency
                                </th>
                                <th className="sort" data-sort="gender">
                                  Gender
                                </th>
                                <th className="sort" data-sort="date-birthday">
                                  Date Birthday
                                </th>
                                <th className="sort" data-sort="unique-code">
                                  Unique Code
                                </th>
                                <th className="sort" data-sort="action">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {users
                                .filter(
                                  (item) =>
                                    item.first_name
                                      .toLowerCase()
                                      .includes(kata.toLocaleLowerCase()) ||
                                    item.last_name
                                      .toLowerCase()
                                      .includes(kata.toLowerCase())
                                )
                                .map((item, index) => (
                                  <tr key={index}>
                                    <td scope="row">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          onChange={(event) => {
                                            setUsers(
                                              users.map((hehe) => {
                                                if (
                                                  hehe.user_id === item.user_id
                                                ) {
                                                  hehe.select =
                                                    event.target.checked;
                                                }
                                                return hehe;
                                              })
                                            );
                                          }}
                                          checked={item.select}
                                        />
                                      </div>
                                    </td>
                                    <td className="no">{index + 1}</td>
                                    <td className="nama">
                                      {item.first_name} {item.last_name}
                                    </td>
                                    <td className="email">{item.email}</td>
                                    <td className="package">
                                      <span className="badge badge-soft-success text-uppercase">
                                        {item.package_name}
                                      </span>
                                    </td>
                                    <td className="instansi">
                                      {item.agency_name}
                                    </td>
                                    <td className="gender">{item.sex}</td>
                                    <td className="date-birthday">
                                      {new Date(item.date_birth).getFullYear() +
                                        "-" +
                                        (new Date(item.date_birth).getMonth() +
                                          1 >
                                        9
                                          ? new Date(
                                              item.date_birth
                                            ).getMonth() + 1
                                          : "0" +
                                            (new Date(
                                              item.date_birth
                                            ).getMonth() +
                                              1)) +
                                        "-" +
                                        (new Date(item.date_birth).getDate() > 9
                                          ? new Date(item.date_birth).getDate()
                                          : "0" +
                                            new Date(
                                              item.date_birth
                                            ).getDate())}
                                    </td>
                                    <td className="uniqueCode">
                                      {item.unique_code}
                                    </td>
                                    <td>
                                      <div className="d-flex gap-2">
                                        <div>
                                          <button
                                            className="btn buttonVersFutureLinkTeal"
                                            onClick={() => {
                                              loginDalem(item.unique_code);
                                            }}
                                          >
                                            View Report
                                          </button>
                                        </div>
                                        <div>
                                          <button
                                            className="btn buttonVersFutureLinkRed"
                                            onClick={() => {
                                              setModalDeleteClient(true);
                                              setUserId(item.user_id);
                                            }}
                                          >
                                            Delete
                                          </button>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </>
            )}
          </Row>
        </div>

        <Modal isOpen={modalAccessDenied} toggle={() => {}} centered>
          <ModalBody className="modalCenter">
            <img src={accessDenied} style={{ width: "30%" }} />
            <p
              style={{ fontSize: "24px", fontWeight: "700", marginTop: "20px" }}
            >
              Access Denied
            </p>
            <p style={{ margin: "0px", color: "grey" }}>
              You currently does not have access to this page. <br /> Please
              Login Again
            </p>
          </ModalBody>
          <ModalFooter
            style={{ display: "flex", justifyContent: "center", gap: "80px" }}
          >
            <button
              className="btn buttonVersFutureLink"
              style={{ fontSize: "14px", fontWeight: "500" }}
              onClick={() => {
                logOut();
              }}
            >
              Login Again
            </button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={modalAdd}
          toggle={() => {
            setModalAdd(!modalAdd);
          }}
          centered
        >
          <ModalHeader
            toggle={() => {
              setModalAdd(!modalAdd);
            }}
          >
            <h4>Add Agency</h4>
          </ModalHeader>
          <ModalBody>
            <Alert className={"alertRed " + fade} hidden={alert}>
              {kontenError}
            </Alert>
            <div className="mb-3">
              <label htmlFor="agencyname-field" className="form-label">
                Name
              </label>
              <input
                value={name}
                onChange={(event) => setName(event.target.value)}
                type="text"
                id="agencyname-field"
                className="form-control"
                placeholder="Enter agency name"
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="price-field" className="form-label">
                Sector
              </label>
              <input
                value={sector}
                onChange={(event) => setSector(event.target.value)}
                type="text"
                id="price-field"
                className="form-control"
                placeholder="Government... "
                required
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                className="btn buttonVersFutureLinkBorderGrey "
                onClick={() => {
                  setModalAdd(false);
                }}
              >
                Close
              </button>
              <button
                className="btn buttonVersFutureLink"
                onClick={() => {
                  addInstansi();
                }}
              >
                Create
              </button>
            </div>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={modalUpdate}
          toggle={() => {
            setModalUpdate(!modalUpdate);
          }}
          centered
        >
          <ModalHeader
            toggle={() => {
              setModalUpdate(!modalUpdate);
            }}
          >
            <h4>Update Agency</h4>
          </ModalHeader>

          <ModalBody>
            <Alert className={"alertRed " + fade} hidden={alert}>
              {kontenError}
            </Alert>
            <div className="mb-3">
              <label htmlFor="agencyname-field" className="form-label">
                Name
              </label>
              <input
                value={name}
                onChange={(event) => setName(event.target.value)}
                type="text"
                id="agencyname-field"
                className="form-control"
                placeholder="Enter agency name"
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="price-field" className="form-label">
                Sector
              </label>
              <input
                value={sector}
                onChange={(event) => setSector(event.target.value)}
                type="text"
                id="price-field"
                className="form-control"
                placeholder="Government... "
                required
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                className="btn buttonVersFutureLinkBorderGrey "
                onClick={() => {
                  setModalAdd(false);
                }}
              >
                Close
              </button>
              <button
                className="btn buttonVersFutureLink"
                onClick={() => {
                  updateInstansi();
                }}
              >
                Update
              </button>
            </div>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={modalDelete}
          toggle={() => {
            setModalDelete(!modalDelete);
          }}
          centered
        >
          <ModalBody className="modalCenter">
            <img src={deleteData} />
            <p
              style={{ fontSize: "24px", fontWeight: "700", marginTop: "20px" }}
            >
              You are about to delete a Agency{" "}
            </p>
            <p style={{ margin: "0px", color: "grey" }}>
              This we will delete your Agency from FutureLink <br /> Are you
              sure ?
            </p>
          </ModalBody>
          <ModalFooter
            style={{ display: "flex", justifyContent: "center", gap: "80px" }}
          >
            <button
              className="btn buttonVersFutureLinkBorderGrey "
              onClick={() => {
                setModalDelete(false);
              }}
            >
              Close
            </button>
            <button
              className="btn buttonVersFutureLinkRed"
              onClick={() => {
                deleteInstansi();
              }}
            >
              Delete
            </button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={modalDeleteClient}
          toggle={() => {
            setModalDeleteClient(!modalDeleteClient);
          }}
          centered
        >
          <ModalBody className="modalCenter">
            <img src={deleteData} />
            <p
              style={{ fontSize: "24px", fontWeight: "700", marginTop: "20px" }}
            >
              You are about to delete a Client
            </p>
            <p style={{ margin: "0px", color: "grey" }}>
              This we will delete your Client from FutureLink <br /> Are you
              sure ?
            </p>
          </ModalBody>
          <ModalFooter
            style={{ display: "flex", justifyContent: "center", gap: "80px" }}
          >
            <button
              className="btn buttonVersFutureLinkBorderGrey "
              onClick={() => {
                setModalDeleteClient(false);
              }}
            >
              Close
            </button>
            <button
              className="btn buttonVersFutureLinkRed"
              onClick={() => {
                deleteClient();
              }}
            >
              Delete
            </button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={modalUpgrade}
          toggle={() => {
            setModalUpgrade(!modalUpgrade);
          }}
          centered
        >
          <ModalHeader
            toggle={() => {
              setModalUpgrade(!modalUpgrade);
            }}
          >
            <h4>Upgrade Package</h4>
          </ModalHeader>
          <ModalBody>
            <Alert className={"alertRed " + fade} hidden={alert}>
              {kontenError}
            </Alert>
            <div
              style={{
                cursor: "pointer",
                fontSize: "24px",
                fontWeight: "500",
                textAlign: "center",
                marginBottom: "10px",
              }}
              onClick={() => {
                setSeeAll(!seeAll);
              }}
            >
              {jumlah} Upgrade
            </div>
            {seeAll === true && (
              <>
                <div
                  style={{
                    height: "150px",
                    overflowX: "hidden",
                    overflowY: "scroll",
                  }}
                >
                  {users
                    .filter((item) => item.select === true)
                    .map((hehe, index) => (
                      <>
                        {/* {console.log(hehe)} */}
                        <div style={{ fontSize: "14px", paddingBottom: "6px" }}>
                          {index + 1}. {hehe.first_name} {hehe.last_name} {"("}
                          {hehe.email === null
                            ? " Belum terdapat Email "
                            : hehe.mail}
                          {")"}
                        </div>
                      </>
                    ))}
                </div>
              </>
            )}
            <div className="mb-3">
              <label htmlFor="gender-field" className="form-label">
                Package
              </label>
              <select
                className="form-control"
                id="Gender-field"
                onChange={(event) => {
                  // console.log(event.target.value);
                  setUpPackages(event.target.value);
                }}
              >
                <option value="0" selected="selected" hidden="hidden">
                  Choose package
                </option>
                {packages.map((item) => (
                  <>
                    <option value={item.package_id}>{item.name}</option>
                  </>
                ))}
              </select>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                className="btn buttonVersFutureLinkBorderGrey "
                onClick={() => {
                  setModalUpgrade(false);
                }}
              >
                Close
              </button>
              <button
                className="btn buttonVersFutureLink"
                onClick={() => {
                  upgradePackages();
                }}
              >
                Upgrade Now
              </button>
            </div>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={modalDownload}
          toggle={() => {
            setModalDownload(!modalDownload);
          }}
          centered
        >
          <ModalHeader
            toggle={() => {
              setModalDownload(!modalDownload);
            }}
          >
            <h4>Download Report</h4>
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                cursor: "pointer",
                fontSize: "24px",
                fontWeight: "500",
                textAlign: "center",
                marginBottom: "10px",
              }}
              onClick={() => {
                setSeeAll(!seeAll);
              }}
            >
              {jumlah}Download
            </div>
            {seeAll === true && (
              <>
                <div
                  style={{
                    height: "150px",
                    overflowX: "hidden",
                    overflowY: "scroll",
                  }}
                >
                  {users
                    .filter((item) => item.select === true)
                    .map((hehe, index) => (
                      <>
                        <div style={{ fontSize: "14px", paddingBottom: "6px" }}>
                          {index + 1}. {hehe.first_name} {hehe.last_name} {"("}
                          {hehe.email}
                          {")"}
                        </div>
                      </>
                    ))}
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                className="btn buttonVersFutureLinkBorderGrey "
                onClick={() => {
                  setModalDownload(false);
                }}
              >
                Close
              </button>
              <button
                className="btn buttonVersFutureLinkGreen"
                onClick={() => {
                  downloadMultiple();
                }}
              >
                Download Report
              </button>
            </div>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={modalAlertSuccess}
          toggle={() => {
            setModalAlertSuccess(!modalAlertSuccess);
          }}
          centered
        >
          <ModalBody className="modalCenter" style={{ margin: "0px" }}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animateSuccess,
                rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
              }}
              height={350}
              width={350}
              isClickToPauseDisabled={true}
            />
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modalAlertDelete}
          toggle={() => {
            setModalAlertDelete(!modalAlertDelete);
          }}
          centered
        >
          <ModalBody className="modalCenter" style={{ margin: "0px" }}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animateDelete,
                rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
              }}
              height={350}
              width={350}
              isClickToPauseDisabled={true}
            />
          </ModalBody>
        </Modal>
      </Container>
    </React.Fragment>
  );
};

export default ModulInstansi;

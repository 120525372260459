import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import Lottie from 'react-lottie';
import accessDenied from '../../../../../assets/images/FutureLink/accessDenied.json';

import selfCognitive from '../../../../../assets/images/FutureLink/selfCognitive.png';
import affective from '../../../../../assets/images/FutureLink/affective.png';
import reflective from '../../../../../assets/images/FutureLink/reflective.png';
import reverse from '../../../../../assets/images/FutureLink/reverse.png';

const GayaBerpikir= (haisayang) => {
    const data = haisayang.history.location.state.data
    // console.log(haisayang.history.location.state.data)

    const packages = haisayang.history.location.state.package_id
    // console.log(haisayang.history.location.state.package_id)

    const token = haisayang.history.location.state.params

    return(
        <React.Fragment>
            <div className="page-content">
                {packages === 33 ? (
                    <>
                <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                    <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 40px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Gaya Berpikir</p>
                </div>

                <div className='gayaberpikir'>
                    <Link style={{marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to={`/report-mobile/${token}`}>Back</Link>

                    <p className='gayaberpikirIntro'>Terdapat empat jenis gaya seseorang ketika berpikir. Keempat gaya berpikir tersebut dapat diprediksi dengan menganalisis pola sidik jari pada kesepuluh jari yang dimiliki setiap orang. Pola sidik jari “Whorl” menggambarkan gaya berpikir self-cognitive learners, pola sidik jari “Loop” menggambarkan gaya berpikir affective learners, pola sidik jari “Arch” menggambarkan gaya berpikir reflective learners, sedangkan pola sidik jari “Reverse Loop” menggambarkan gaya berpikir reverse thinking.</p>

                    <div className='gayaberpikirList'>

                        <div className='gayaberpikirItemKiri'>
                            <img src={selfCognitive} className='selfCognitive'/>
                            <div className='itemBox'>
                                <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Self-Cognitive leaners</p>
                                <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{data.cognitive}%</p>
                            </div>
                        </div>

                        <div className='gayaberpikirItemKanan'>
                            <div className='khusus'>
                                <div className='itemBox'>
                                    <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Affective leaners</p>
                                    <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{data.affective}%</p>
                                </div>
                                <img src={affective} className='affective'/>
                            </div>
                        </div>

                        <div className='gayaberpikirItemKiri'>
                            <img src={reflective} className='reflective'/>
                            <div className='itemBox'>
                                <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Reflective leaners</p>
                                <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{data.reflective}%</p>
                            </div>
                        </div>

                        <div className='gayaberpikirItemKanan'>
                            <div className='itemBox'>
                                <p style={{margin: '0px', padding: '5px 25px', border: '2px solid #4895EF', borderRadius: '15px', color: '#1F599C', fontSize: '24px', fontWeight: '900', width: 'max-content'}}>Reverse leaners</p>
                                <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A'}}>{data.reverse}%</p>
                            </div>
                            <img src={reverse} className='reverse'/>
                        </div>

                    </div>

                    <p className='gayaberpikirMobile'>Terdapat empat jenis gaya seseorang ketika berpikir. Keempat gaya berpikir tersebut dapat diprediksi dengan menganalisis pola sidik jari pada kesepuluh jari yang dimiliki setiap orang. Pola sidik jari “Whorl” menggambarkan gaya berpikir self-cognitive learners, pola sidik jari “Loop” menggambarkan gaya berpikir affective learners, pola sidik jari “Arch” menggambarkan gaya berpikir reflective learners, sedangkan pola sidik jari “Reverse Loop” menggambarkan gaya berpikir reverse thinking.</p>
                </div>

                <div style={{display: 'flex', justifyContent:'space-between', paddingTop: '32px'}}>
                    <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report-mobiles/konsep-pemikiran", state: { data: data, package_id: packages, params: token }}} onClick={() => {document.body.scrollIntoView({behavior: "smooth"})}}>{"<"} Konsep Pemikiran</Link>
                </div>
                    </>
                ) : (
                    <>
                        <Link style={{marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to={`/report-mobile/${token}`}>Back</Link>
                        <Lottie options={{loop: true,autoplay: true,animationData: accessDenied,rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} isClickToPauseDisabled={true} />
                        <p className='upgradePlease'> Maaf, Anda belum melakukan Upgrade yang lebih tinggi</p>
                    </>
                )}


                {/* <div style={{display: 'flex', flexDirection: 'column', gap: '50px'}}>
                    <div>
                        <div style={{display: 'flex', alignItems: 'center', gap: '30px', marginBottom: '20px'}}>
                            <div style={{width: '48px', height: '48px', backgroundColor: 'black', borderRadius:'5px'}}></div>
                            <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>Self-cognitive Learners</p>
                        </div>
                        <p style={{margin: '0px', textAlign: 'justify', fontSize: '14px'}}>Mandiri, berorientasi pada tujuan, fokus pada target, terbiasa memulai segalanya sendiri, cenderung keras kepala, memiliki kepercayaan diri yang tinggi, kurang fleksibel, berani, memiliki tekad yang kuat, agresif, bertanggung jawab, egois, dan suka memerintah.<br/><br/>
                        Gaya berpikir ini dapat ditingkatkan dengan memberikan keterbatasan informasi agar mau mencari lebih banyak lagi, menempatkan di tempat dengan lingkungan yang kompetitif agar termotivasi, serta membiarkan diri menerima kekalahan ataupun jawaban yang salah agar dapat berkembang.</p>
                    </div>
                    <div>
                        <div style={{display: 'flex', alignItems: 'center', gap: '30px', marginBottom: '20px'}}>
                            <div style={{width: '48px', height: '48px', backgroundColor: 'black', borderRadius:'5px'}}></div>
                            <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>Affective Learners</p>
                        </div>
                        <p style={{margin: '0px', textAlign: 'justify', fontSize: '14px'}}>Kemampuan belajarnya tinggi, fleksibel, berorientasi pada orang, membutuhkan motivasi dan dorongan dalam belajar, kooperatif, kurang percaya diri, peduli akan hubungan antar individu, sabar, suportif, dan cenderung emosional.
                        <br/><br/>
                        Gaya berpikir ini dapat ditingkatkan dengan memberikan materi pembelajaran berbasis contoh, menggunakan pengingat untuk belajar, memilih panutan yang bercita-cita tinggi sebagai inspirasi, membuat lingkungan yang disiplin secara ketat, dan lebih banyak melakukan demonstrasi.</p>
                    </div>
                    <div>
                        <div style={{display: 'flex', alignItems: 'center', gap: '30px', marginBottom: '20px'}}>
                            <div style={{width: '48px', height: '48px', backgroundColor: 'black', borderRadius:'5px'}}></div>
                            <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>Reflective Learners</p>
                        </div>
                        <p style={{margin: '0px', textAlign: 'justify', fontSize: '14px'}}>Memiliki potensi yang tidak terbatas, sangat fleksibel, konservatif, ramah namun cenderung banyak bicara, manajemen waktu dan kedisiplinannya kurang baik, membutuhkan dukungan dan panduan untuk melakukan yang terbaik, dan kurang suka mengambil risiko.
                        <br/><br/>
                        Gaya berpikir ini dapat ditingkatkan dengan menggunakan metode pembelajaran yang disederhanakan, merencanakan kegiatan sehari-hari, membiasakan penegasan positif terhadap diri sendiri, serta menggunakan hadiah dan hukuman untuk mendorong peningkatan dan mencapai tujuan.</p>
                    </div>
                    <div>
                        <div style={{display: 'flex', alignItems: 'center', gap: '30px', marginBottom: '20px'}}>
                            <div style={{width: '48px', height: '48px', backgroundColor: 'black', borderRadius:'5px'}}></div>
                            <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>Reverse Thinking</p>
                        </div>
                        <p style={{margin: '0px', textAlign: 'justify', fontSize: '14px'}}>Memiliki kreatifitas yang tinggi, pemikirannya sering kali “out of the box”, senang melakukan sesuatu yang baru dan berbeda, tidak suka berada di keramaian, tidak mengikuti tren dunia, memiliki pemikiran yang ilmiah, mudah penasaran, dan tidak suka aturan.
                        <br/><br/>
                        Gaya berpikir ini dapat ditingkatkan dengan menggunakan psikologi terbalik ketika belajar, memotivasi diri dengan tantangan, membiasakan diri untuk melakukan perencanaan dan manajemen diri, serta menggunakan sistem hadiah dan hukuman atas pencapaian diri sendiri.</p>
                    </div>
                </div> */}

            </div>
        </React.Fragment>
    )

}

export default GayaBerpikir;
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useInterval } from "usehooks-ts";

import HalfO from "../../../../assets/images/FutureLink/paro.png";
import Spm from "../../../../assets/images/FutureLink/openingSpm.png";
import Logo from "../../../../assets/images/FutureLink/complementaryLogo.png";

import A1 from "../../../../assets/images/FutureLink/SPM/A1-Q.svg";
import A1Q1 from "../../../../assets/images/FutureLink/SPM/A1-1.svg";
import A1Q2 from "../../../../assets/images/FutureLink/SPM/A1-2.svg";
import A1Q3 from "../../../../assets/images/FutureLink/SPM/A1-3.svg";
import A1Q4 from "../../../../assets/images/FutureLink/SPM/A1-4.svg";
import A1Q5 from "../../../../assets/images/FutureLink/SPM/A1-5.svg";
import A1Q6 from "../../../../assets/images/FutureLink/SPM/A1-6.svg";
import A2 from "../../../../assets/images/FutureLink/SPM/A2-Q.svg";
import A2Q1 from "../../../../assets/images/FutureLink/SPM/A2-1.svg";
import A2Q2 from "../../../../assets/images/FutureLink/SPM/A2-2.svg";
import A2Q3 from "../../../../assets/images/FutureLink/SPM/A2-3.svg";
import A2Q4 from "../../../../assets/images/FutureLink/SPM/A2-4.svg";
import A2Q5 from "../../../../assets/images/FutureLink/SPM/A2-5.svg";
import A2Q6 from "../../../../assets/images/FutureLink/SPM/A2-6.svg";
import A3 from "../../../../assets/images/FutureLink/SPM/A3-Q.svg";
import A3Q1 from "../../../../assets/images/FutureLink/SPM/A3-1.svg";
import A3Q2 from "../../../../assets/images/FutureLink/SPM/A3-2.svg";
import A3Q3 from "../../../../assets/images/FutureLink/SPM/A3-3.svg";
import A3Q4 from "../../../../assets/images/FutureLink/SPM/A3-4.svg";
import A3Q5 from "../../../../assets/images/FutureLink/SPM/A3-5.svg";
import A3Q6 from "../../../../assets/images/FutureLink/SPM/A3-6.svg";
import A4 from "../../../../assets/images/FutureLink/SPM/A4-Q.svg";
import A4Q1 from "../../../../assets/images/FutureLink/SPM/A4-1.svg";
import A4Q2 from "../../../../assets/images/FutureLink/SPM/A4-2.svg";
import A4Q3 from "../../../../assets/images/FutureLink/SPM/A4-3.svg";
import A4Q4 from "../../../../assets/images/FutureLink/SPM/A4-4.svg";
import A4Q5 from "../../../../assets/images/FutureLink/SPM/A4-5.svg";
import A4Q6 from "../../../../assets/images/FutureLink/SPM/A4-6.svg";
import A5 from "../../../../assets/images/FutureLink/SPM/A5-Q.svg";
import A5Q1 from "../../../../assets/images/FutureLink/SPM/A5-1.svg";
import A5Q2 from "../../../../assets/images/FutureLink/SPM/A5-2.svg";
import A5Q3 from "../../../../assets/images/FutureLink/SPM/A5-3.svg";
import A5Q4 from "../../../../assets/images/FutureLink/SPM/A5-4.svg";
import A5Q5 from "../../../../assets/images/FutureLink/SPM/A5-5.svg";
import A5Q6 from "../../../../assets/images/FutureLink/SPM/A5-6.svg";
import A6 from "../../../../assets/images/FutureLink/SPM/A6-Q.svg";
import A6Q1 from "../../../../assets/images/FutureLink/SPM/A6-1.svg";
import A6Q2 from "../../../../assets/images/FutureLink/SPM/A6-2.svg";
import A6Q3 from "../../../../assets/images/FutureLink/SPM/A6-3.svg";
import A6Q4 from "../../../../assets/images/FutureLink/SPM/A6-4.svg";
import A6Q5 from "../../../../assets/images/FutureLink/SPM/A6-5.svg";
import A6Q6 from "../../../../assets/images/FutureLink/SPM/A6-6.svg";
import A7 from "../../../../assets/images/FutureLink/SPM/A7-Q.svg";
import A7Q1 from "../../../../assets/images/FutureLink/SPM/A7-1.svg";
import A7Q2 from "../../../../assets/images/FutureLink/SPM/A7-2.svg";
import A7Q3 from "../../../../assets/images/FutureLink/SPM/A7-3.svg";
import A7Q4 from "../../../../assets/images/FutureLink/SPM/A7-4.svg";
import A7Q5 from "../../../../assets/images/FutureLink/SPM/A7-5.svg";
import A7Q6 from "../../../../assets/images/FutureLink/SPM/A7-6.svg";
import A8 from "../../../../assets/images/FutureLink/SPM/A8-Q.svg";
import A8Q1 from "../../../../assets/images/FutureLink/SPM/A8-1.svg";
import A8Q2 from "../../../../assets/images/FutureLink/SPM/A8-2.svg";
import A8Q3 from "../../../../assets/images/FutureLink/SPM/A8-3.svg";
import A8Q4 from "../../../../assets/images/FutureLink/SPM/A8-4.svg";
import A8Q5 from "../../../../assets/images/FutureLink/SPM/A8-5.svg";
import A8Q6 from "../../../../assets/images/FutureLink/SPM/A8-6.svg";
import A9 from "../../../../assets/images/FutureLink/SPM/A9-Q.svg";
import A9Q1 from "../../../../assets/images/FutureLink/SPM/A9-1.svg";
import A9Q2 from "../../../../assets/images/FutureLink/SPM/A9-2.svg";
import A9Q3 from "../../../../assets/images/FutureLink/SPM/A9-3.svg";
import A9Q4 from "../../../../assets/images/FutureLink/SPM/A9-4.svg";
import A9Q5 from "../../../../assets/images/FutureLink/SPM/A9-5.svg";
import A9Q6 from "../../../../assets/images/FutureLink/SPM/A9-6.svg";
import A10 from "../../../../assets/images/FutureLink/SPM/A10-Q.svg";
import A10Q1 from "../../../../assets/images/FutureLink/SPM/A10-1.svg";
import A10Q2 from "../../../../assets/images/FutureLink/SPM/A10-2.svg";
import A10Q3 from "../../../../assets/images/FutureLink/SPM/A10-3.svg";
import A10Q4 from "../../../../assets/images/FutureLink/SPM/A10-4.svg";
import A10Q5 from "../../../../assets/images/FutureLink/SPM/A10-5.svg";
import A10Q6 from "../../../../assets/images/FutureLink/SPM/A10-6.svg";
import A11 from "../../../../assets/images/FutureLink/SPM/A11-Q.svg";
import A11Q1 from "../../../../assets/images/FutureLink/SPM/A11-1.svg";
import A11Q2 from "../../../../assets/images/FutureLink/SPM/A11-2.svg";
import A11Q3 from "../../../../assets/images/FutureLink/SPM/A11-3.svg";
import A11Q4 from "../../../../assets/images/FutureLink/SPM/A11-4.svg";
import A11Q5 from "../../../../assets/images/FutureLink/SPM/A11-5.svg";
import A11Q6 from "../../../../assets/images/FutureLink/SPM/A11-6.svg";
import A12 from "../../../../assets/images/FutureLink/SPM/A12-Q.svg";
import A12Q1 from "../../../../assets/images/FutureLink/SPM/A12-1.svg";
import A12Q2 from "../../../../assets/images/FutureLink/SPM/A12-2.svg";
import A12Q3 from "../../../../assets/images/FutureLink/SPM/A12-3.svg";
import A12Q4 from "../../../../assets/images/FutureLink/SPM/A12-4.svg";
import A12Q5 from "../../../../assets/images/FutureLink/SPM/A12-5.svg";
import A12Q6 from "../../../../assets/images/FutureLink/SPM/A12-6.svg";

import B1 from "../../../../assets/images/FutureLink/SPM/B1-Q.svg";
import B1Q1 from "../../../../assets/images/FutureLink/SPM/B1-1.svg";
import B1Q2 from "../../../../assets/images/FutureLink/SPM/B1-2.svg";
import B1Q3 from "../../../../assets/images/FutureLink/SPM/B1-3.svg";
import B1Q4 from "../../../../assets/images/FutureLink/SPM/B1-4.svg";
import B1Q5 from "../../../../assets/images/FutureLink/SPM/B1-5.svg";
import B1Q6 from "../../../../assets/images/FutureLink/SPM/B1-6.svg";
import B2 from "../../../../assets/images/FutureLink/SPM/B2-Q.svg";
import B2Q1 from "../../../../assets/images/FutureLink/SPM/B2-1.svg";
import B2Q2 from "../../../../assets/images/FutureLink/SPM/B2-2.svg";
import B2Q3 from "../../../../assets/images/FutureLink/SPM/B2-3.svg";
import B2Q4 from "../../../../assets/images/FutureLink/SPM/B2-4.svg";
import B2Q5 from "../../../../assets/images/FutureLink/SPM/B2-5.svg";
import B2Q6 from "../../../../assets/images/FutureLink/SPM/B2-6.svg";
import B3 from "../../../../assets/images/FutureLink/SPM/B3-Q.svg";
import B3Q1 from "../../../../assets/images/FutureLink/SPM/B3-1.svg";
import B3Q2 from "../../../../assets/images/FutureLink/SPM/B3-2.svg";
import B3Q3 from "../../../../assets/images/FutureLink/SPM/B3-3.svg";
import B3Q4 from "../../../../assets/images/FutureLink/SPM/B3-4.svg";
import B3Q5 from "../../../../assets/images/FutureLink/SPM/B3-5.svg";
import B3Q6 from "../../../../assets/images/FutureLink/SPM/B3-6.svg";
import B4 from "../../../../assets/images/FutureLink/SPM/B4-Q.svg";
import B4Q1 from "../../../../assets/images/FutureLink/SPM/B4-1.svg";
import B4Q2 from "../../../../assets/images/FutureLink/SPM/B4-2.svg";
import B4Q3 from "../../../../assets/images/FutureLink/SPM/B4-3.svg";
import B4Q4 from "../../../../assets/images/FutureLink/SPM/B4-4.svg";
import B4Q5 from "../../../../assets/images/FutureLink/SPM/B4-5.svg";
import B4Q6 from "../../../../assets/images/FutureLink/SPM/B4-6.svg";
import B5 from "../../../../assets/images/FutureLink/SPM/B5-Q.svg";
import B5Q1 from "../../../../assets/images/FutureLink/SPM/B5-1.svg";
import B5Q2 from "../../../../assets/images/FutureLink/SPM/B5-2.svg";
import B5Q3 from "../../../../assets/images/FutureLink/SPM/B5-3.svg";
import B5Q4 from "../../../../assets/images/FutureLink/SPM/B5-4.svg";
import B5Q5 from "../../../../assets/images/FutureLink/SPM/B5-5.svg";
import B5Q6 from "../../../../assets/images/FutureLink/SPM/B5-6.svg";
import B6 from "../../../../assets/images/FutureLink/SPM/B6-Q.svg";
import B6Q1 from "../../../../assets/images/FutureLink/SPM/B6-1.svg";
import B6Q2 from "../../../../assets/images/FutureLink/SPM/B6-2.svg";
import B6Q3 from "../../../../assets/images/FutureLink/SPM/B6-3.svg";
import B6Q4 from "../../../../assets/images/FutureLink/SPM/B6-4.svg";
import B6Q5 from "../../../../assets/images/FutureLink/SPM/B6-5.svg";
import B6Q6 from "../../../../assets/images/FutureLink/SPM/B6-6.svg";
import B7 from "../../../../assets/images/FutureLink/SPM/B7-Q.svg";
import B7Q1 from "../../../../assets/images/FutureLink/SPM/B7-1.svg";
import B7Q2 from "../../../../assets/images/FutureLink/SPM/B7-2.svg";
import B7Q3 from "../../../../assets/images/FutureLink/SPM/B7-3.svg";
import B7Q4 from "../../../../assets/images/FutureLink/SPM/B7-4.svg";
import B7Q5 from "../../../../assets/images/FutureLink/SPM/B7-5.svg";
import B7Q6 from "../../../../assets/images/FutureLink/SPM/B7-6.svg";
import B8 from "../../../../assets/images/FutureLink/SPM/B8-Q.svg";
import B8Q1 from "../../../../assets/images/FutureLink/SPM/B8-1.svg";
import B8Q2 from "../../../../assets/images/FutureLink/SPM/B8-2.svg";
import B8Q3 from "../../../../assets/images/FutureLink/SPM/B8-3.svg";
import B8Q4 from "../../../../assets/images/FutureLink/SPM/B8-4.svg";
import B8Q5 from "../../../../assets/images/FutureLink/SPM/B8-5.svg";
import B8Q6 from "../../../../assets/images/FutureLink/SPM/B8-6.svg";
import B9 from "../../../../assets/images/FutureLink/SPM/B9-Q.svg";
import B9Q1 from "../../../../assets/images/FutureLink/SPM/B9-1.svg";
import B9Q2 from "../../../../assets/images/FutureLink/SPM/B9-2.svg";
import B9Q3 from "../../../../assets/images/FutureLink/SPM/B9-3.svg";
import B9Q4 from "../../../../assets/images/FutureLink/SPM/B9-4.svg";
import B9Q5 from "../../../../assets/images/FutureLink/SPM/B9-5.svg";
import B9Q6 from "../../../../assets/images/FutureLink/SPM/B9-6.svg";
import B10 from "../../../../assets/images/FutureLink/SPM/B10-Q.svg";
import B10Q1 from "../../../../assets/images/FutureLink/SPM/B10-1.svg";
import B10Q2 from "../../../../assets/images/FutureLink/SPM/B10-2.svg";
import B10Q3 from "../../../../assets/images/FutureLink/SPM/B10-3.svg";
import B10Q4 from "../../../../assets/images/FutureLink/SPM/B10-4.svg";
import B10Q5 from "../../../../assets/images/FutureLink/SPM/B10-5.svg";
import B10Q6 from "../../../../assets/images/FutureLink/SPM/B10-6.svg";
import B11 from "../../../../assets/images/FutureLink/SPM/B11-Q.svg";
import B11Q1 from "../../../../assets/images/FutureLink/SPM/B11-1.svg";
import B11Q2 from "../../../../assets/images/FutureLink/SPM/B11-2.svg";
import B11Q3 from "../../../../assets/images/FutureLink/SPM/B11-3.svg";
import B11Q4 from "../../../../assets/images/FutureLink/SPM/B11-4.svg";
import B11Q5 from "../../../../assets/images/FutureLink/SPM/B11-5.svg";
import B11Q6 from "../../../../assets/images/FutureLink/SPM/B11-6.svg";
import B12 from "../../../../assets/images/FutureLink/SPM/B12-Q.svg";
import B12Q1 from "../../../../assets/images/FutureLink/SPM/B12-1.svg";
import B12Q2 from "../../../../assets/images/FutureLink/SPM/B12-2.svg";
import B12Q3 from "../../../../assets/images/FutureLink/SPM/B12-3.svg";
import B12Q4 from "../../../../assets/images/FutureLink/SPM/B12-4.svg";
import B12Q5 from "../../../../assets/images/FutureLink/SPM/B12-5.svg";
import B12Q6 from "../../../../assets/images/FutureLink/SPM/B12-6.svg";

import end from "../../../../assets/images/FutureLink/selesaiTest.png";

const Soal = () => {
  const [halaman, setHalaman] = useState("home");
  const [nomor, setNomor] = useState(1);
  const [sembunyi, setSembunyi] = useState("");
  const [ja1, setJA1] = useState(0);
  const [ja2, setJA2] = useState(0);
  const [ja3, setJA3] = useState(0);
  const [ja4, setJA4] = useState(0);
  const [ja5, setJA5] = useState(0);
  const [ja6, setJA6] = useState(0);
  const [ja7, setJA7] = useState(0);
  const [ja8, setJA8] = useState(0);
  const [ja9, setJA9] = useState(0);
  const [ja10, setJA10] = useState(0);
  const [ja11, setJA11] = useState(0);
  const [ja12, setJA12] = useState(0);
  const [ca11, setCa11] = useState(false);
  const [ca12, setCa12] = useState(false);
  const [ca13, setCa13] = useState(false);
  const [ca14, setCa14] = useState(false);
  const [ca15, setCa15] = useState(false);
  const [ca16, setCa16] = useState(false);
  const [ca21, setCa21] = useState(false);
  const [ca22, setCa22] = useState(false);
  const [ca23, setCa23] = useState(false);
  const [ca24, setCa24] = useState(false);
  const [ca25, setCa25] = useState(false);
  const [ca26, setCa26] = useState(false);
  const [ca31, setCa31] = useState(false);
  const [ca32, setCa32] = useState(false);
  const [ca33, setCa33] = useState(false);
  const [ca34, setCa34] = useState(false);
  const [ca35, setCa35] = useState(false);
  const [ca36, setCa36] = useState(false);
  const [ca41, setCa41] = useState(false);
  const [ca42, setCa42] = useState(false);
  const [ca43, setCa43] = useState(false);
  const [ca44, setCa44] = useState(false);
  const [ca45, setCa45] = useState(false);
  const [ca46, setCa46] = useState(false);
  const [ca51, setCa51] = useState(false);
  const [ca52, setCa52] = useState(false);
  const [ca53, setCa53] = useState(false);
  const [ca54, setCa54] = useState(false);
  const [ca55, setCa55] = useState(false);
  const [ca56, setCa56] = useState(false);
  const [ca61, setCa61] = useState(false);
  const [ca62, setCa62] = useState(false);
  const [ca63, setCa63] = useState(false);
  const [ca64, setCa64] = useState(false);
  const [ca65, setCa65] = useState(false);
  const [ca66, setCa66] = useState(false);
  const [ca71, setCa71] = useState(false);
  const [ca72, setCa72] = useState(false);
  const [ca73, setCa73] = useState(false);
  const [ca74, setCa74] = useState(false);
  const [ca75, setCa75] = useState(false);
  const [ca76, setCa76] = useState(false);
  const [ca81, setCa81] = useState(false);
  const [ca82, setCa82] = useState(false);
  const [ca83, setCa83] = useState(false);
  const [ca84, setCa84] = useState(false);
  const [ca85, setCa85] = useState(false);
  const [ca86, setCa86] = useState(false);
  const [ca91, setCa91] = useState(false);
  const [ca92, setCa92] = useState(false);
  const [ca93, setCa93] = useState(false);
  const [ca94, setCa94] = useState(false);
  const [ca95, setCa95] = useState(false);
  const [ca96, setCa96] = useState(false);
  const [ca101, setCa101] = useState(false);
  const [ca102, setCa102] = useState(false);
  const [ca103, setCa103] = useState(false);
  const [ca104, setCa104] = useState(false);
  const [ca105, setCa105] = useState(false);
  const [ca106, setCa106] = useState(false);
  const [ca111, setCa111] = useState(false);
  const [ca112, setCa112] = useState(false);
  const [ca113, setCa113] = useState(false);
  const [ca114, setCa114] = useState(false);
  const [ca115, setCa115] = useState(false);
  const [ca116, setCa116] = useState(false);
  const [ca121, setCa121] = useState(false);
  const [ca122, setCa122] = useState(false);
  const [ca123, setCa123] = useState(false);
  const [ca124, setCa124] = useState(false);
  const [ca125, setCa125] = useState(false);
  const [ca126, setCa126] = useState(false);

  const [jb1, setJB1] = useState(0);
  const [jb2, setJB2] = useState(0);
  const [jb3, setJB3] = useState(0);
  const [jb4, setJB4] = useState(0);
  const [jb5, setJB5] = useState(0);
  const [jb6, setJB6] = useState(0);
  const [jb7, setJB7] = useState(0);
  const [jb8, setJB8] = useState(0);
  const [jb9, setJB9] = useState(0);
  const [jb10, setJB10] = useState(0);
  const [jb11, setJB11] = useState(0);
  const [jb12, setJB12] = useState(0);
  const [cb11, setCb11] = useState(false);
  const [cb12, setCb12] = useState(false);
  const [cb13, setCb13] = useState(false);
  const [cb14, setCb14] = useState(false);
  const [cb15, setCb15] = useState(false);
  const [cb16, setCb16] = useState(false);
  const [cb21, setCb21] = useState(false);
  const [cb22, setCb22] = useState(false);
  const [cb23, setCb23] = useState(false);
  const [cb24, setCb24] = useState(false);
  const [cb25, setCb25] = useState(false);
  const [cb26, setCb26] = useState(false);
  const [cb31, setCb31] = useState(false);
  const [cb32, setCb32] = useState(false);
  const [cb33, setCb33] = useState(false);
  const [cb34, setCb34] = useState(false);
  const [cb35, setCb35] = useState(false);
  const [cb36, setCb36] = useState(false);
  const [cb41, setCb41] = useState(false);
  const [cb42, setCb42] = useState(false);
  const [cb43, setCb43] = useState(false);
  const [cb44, setCb44] = useState(false);
  const [cb45, setCb45] = useState(false);
  const [cb46, setCb46] = useState(false);
  const [cb51, setCb51] = useState(false);
  const [cb52, setCb52] = useState(false);
  const [cb53, setCb53] = useState(false);
  const [cb54, setCb54] = useState(false);
  const [cb55, setCb55] = useState(false);
  const [cb56, setCb56] = useState(false);
  const [cb61, setCb61] = useState(false);
  const [cb62, setCb62] = useState(false);
  const [cb63, setCb63] = useState(false);
  const [cb64, setCb64] = useState(false);
  const [cb65, setCb65] = useState(false);
  const [cb66, setCb66] = useState(false);
  const [cb71, setCb71] = useState(false);
  const [cb72, setCb72] = useState(false);
  const [cb73, setCb73] = useState(false);
  const [cb74, setCb74] = useState(false);
  const [cb75, setCb75] = useState(false);
  const [cb76, setCb76] = useState(false);
  const [cb81, setCb81] = useState(false);
  const [cb82, setCb82] = useState(false);
  const [cb83, setCb83] = useState(false);
  const [cb84, setCb84] = useState(false);
  const [cb85, setCb85] = useState(false);
  const [cb86, setCb86] = useState(false);
  const [cb91, setCb91] = useState(false);
  const [cb92, setCb92] = useState(false);
  const [cb93, setCb93] = useState(false);
  const [cb94, setCb94] = useState(false);
  const [cb95, setCb95] = useState(false);
  const [cb96, setCb96] = useState(false);
  const [cb101, setCb101] = useState(false);
  const [cb102, setCb102] = useState(false);
  const [cb103, setCb103] = useState(false);
  const [cb104, setCb104] = useState(false);
  const [cb105, setCb105] = useState(false);
  const [cb106, setCb106] = useState(false);
  const [cb111, setCb111] = useState(false);
  const [cb112, setCb112] = useState(false);
  const [cb113, setCb113] = useState(false);
  const [cb114, setCb114] = useState(false);
  const [cb115, setCb115] = useState(false);
  const [cb116, setCb116] = useState(false);
  const [cb121, setCb121] = useState(false);
  const [cb122, setCb122] = useState(false);
  const [cb123, setCb123] = useState(false);
  const [cb124, setCb124] = useState(false);
  const [cb125, setCb125] = useState(false);
  const [cb126, setCb126] = useState(false);

  const [typeNow, setTypeNow] = useState("A");

  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  const [count, setCount] = useState(1800);
  const [benar, setBenar] = useState(false);
  const [berjalan, setBerjalan] = useState("jam");

  const hitMe = () => {
    console.log([
      ja1,
      ja2,
      ja3,
      ja4,
      ja5,
      ja6,
      ja7,
      ja8,
      ja9,
      ja10,
      ja11,
      ja12,
      jb1,
      jb2,
      jb3,
      jb4,
      jb5,
      jb6,
      jb7,
      jb8,
      jb9,
      jb10,
      jb11,
      jb12,
    ]);
  };

  useInterval(
    () => {
      setCount(count - 1);
      setMin(Math.floor((count / 60) % 60));
      setSec(Math.floor(count % 60));

      if (count < 61) {
        setBerjalan("jam redTime");
      }

      if (count == -1) {
        setBenar(false);
        setNomor(1);
        setHalaman("selesai");
        hitMe();
        setCount(1800);
      }
    },
    benar ? 1000 : null
  );

  useEffect(() => {
    if (nomor == 1) {
      setSembunyi("trans");
    } else {
      setSembunyi("");
    }
  }, [nomor]);

  // const getAllTestInfo = (cs, id = 0) => {
  //   let requestValidation = true;
  //   if (cs == modulTestState.section) {
  //     if (!currentTest.hasSection) requestValidation = false;
  //   }

  //   let urlDetail =
  //     cs == modulTestState.test
  //       ? "/v1/get-all-test"
  //       : cs == modulTestState.question
  //       ? "/v1/get-test-question-and-answer"
  //       : cs == modulTestState.answer
  //       ? "/v1/get-test-answer"
  //       : cs == modulTestState.section
  //       ? "/v1/get-all-test-section"
  //       : cs == modulTestState.subsection
  //       ? "/v1/get-all-test-subsection"
  //       : "";

  //   let urlPayload =
  //     cs == modulTestState.test
  //       ? { orderBy: "DESC" }
  //       : cs == modulTestState.answer
  //       ? { qId: id }
  //       : cs == modulTestState.subsection
  //       ? { testSectionId: id }
  //       : {
  //           testId: id,
  //         };

  //   let config = {
  //     method: "get",
  //     maxBodyLength: Infinity,
  //     url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}${urlDetail}`,
  //     headers: {
  //       Authorization: `Bearer ${code}`,
  //     },
  //     params: urlPayload,
  //   };

  //   requestValidation
  //     ? axios
  //         .request(config)
  //         .then((response) => {
  //           setCurrentState(cs);
  //           cs == modulTestState.test
  //             ? setTests(
  //                 response.results.map((item, index) => {
  //                   return {
  //                     testId: item.test_id,
  //                     name: item.name,
  //                     duration: item.duration,
  //                     hasSection: Boolean(parseInt(item.has_section)),
  //                     status: item.status,
  //                     price: item.price,
  //                   };
  //                 })
  //               )
  //             : cs == modulTestState.question
  //             ? setQuestions(
  //                 response.results.map((item, index) => {
  //                   return {
  //                     qId: item.q_id,
  //                     aId: item.a_id,
  //                     questionText: item.question_text,
  //                     questionImage: item.question_image,
  //                     questionType: item.type,
  //                     arrangement: item.arrangement,
  //                   };
  //                 })
  //               )
  //             : cs == modulTestState.answer
  //             ? setAnswers(
  //                 response.results.map((item, index) => {
  //                   return {
  //                     qId: item.q_id,
  //                     aId: item.a_id,
  //                     answerText: item.answer_text,
  //                     answerImage: item.answer_image,
  //                     point: item.point,
  //                   };
  //                 })
  //               )
  //             : cs == modulTestState.section
  //             ? setSections(
  //                 response.results.map((item, index) => {
  //                   return {
  //                     testSectionId: item.test_section_id,
  //                     name: item.name,
  //                     duration: item.duration,
  //                     arrangement: item.arrangement,
  //                   };
  //                 })
  //               )
  //             : cs == modulTestState.subsection
  //             ? setSubsections(
  //                 response.results.map((item, index) => {
  //                   return {
  //                     testSubsectionId: item.test_subsection_id,
  //                     name: item.name,
  //                     duration: item.duration,
  //                     arrangement: item.arrangement,
  //                   };
  //                 })
  //               )
  //             : null;
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //           setAlert(true);
  //           // if (error.response.data.statusCode === 403) {
  //           //   setModalAccessDenied(true);
  //           // }
  //         })
  //     : setAlert(true);
  // };

  // const RenderQuestionAndAnswer = (
  //   questionText = "",
  //   questionImage = "",
  //   answerList
  // ) => {
  //   return (
  //     <>
  //       <img src={A1} className="fotoSoal" />

  //       <div className="listJawaban">
  //         <div className={ca11 ? "jawaban click" : "jawaban"}>
  //           <input
  //             type="radio"
  //             id="j11"
  //             name="1"
  //             value={1}
  //             onChange={(event) => {
  //               setJA1(event.target.value);
  //               setCa11(event.target.checked);
  //               setCa12(false);
  //               setCa13(false);
  //               setCa14(false);
  //               setCa15(false);
  //               setCa16(false);
  //             }}
  //           />
  //           <label htmlFor="j11">
  //             <img src={A1Q1} />
  //           </label>
  //         </div>
  //         <div className={ca12 ? "jawaban click" : "jawaban"}>
  //           <input
  //             type="radio"
  //             id="j12"
  //             name="1"
  //             value={2}
  //             onChange={(event) => {
  //               setJA1(event.target.value);
  //               setCa12(event.target.checked);
  //               setCa11(false);
  //               setCa13(false);
  //               setCa14(false);
  //               setCa15(false);
  //               setCa16(false);
  //             }}
  //           />
  //           <label htmlFor="j12">
  //             <img src={A1Q2} />
  //           </label>
  //         </div>
  //         <div className={ca13 ? "jawaban click" : "jawaban"}>
  //           <input
  //             type="radio"
  //             id="j13"
  //             name="1"
  //             value={3}
  //             onChange={(event) => {
  //               setJA1(event.target.value);
  //               setCa13(event.target.checked);
  //               setCa12(false);
  //               setCa11(false);
  //               setCa14(false);
  //               setCa15(false);
  //               setCa16(false);
  //             }}
  //           />
  //           <label htmlFor="j13">
  //             <img src={A1Q3} />
  //           </label>
  //         </div>
  //         <div className={ca14 ? "jawaban click" : "jawaban"}>
  //           <input
  //             type="radio"
  //             id="j14"
  //             name="1"
  //             value={4}
  //             onChange={(event) => {
  //               setJA1(event.target.value);
  //               setCa14(event.target.checked);
  //               setCa12(false);
  //               setCa13(false);
  //               setCa11(false);
  //               setCa15(false);
  //               setCa16(false);
  //             }}
  //           />
  //           <label htmlFor="j14">
  //             <img src={A1Q4} />
  //           </label>
  //         </div>
  //         <div className={ca15 ? "jawaban click" : "jawaban"}>
  //           <input
  //             type="radio"
  //             id="j15"
  //             name="1"
  //             value={5}
  //             onChange={(event) => {
  //               setJA1(event.target.value);
  //               setCa15(event.target.checked);
  //               setCa12(false);
  //               setCa13(false);
  //               setCa14(false);
  //               setCa11(false);
  //               setCa16(false);
  //             }}
  //           />
  //           <label htmlFor="j15">
  //             <img src={A1Q5} />
  //           </label>
  //         </div>
  //         <div className={ca16 ? "jawaban click" : "jawaban"}>
  //           <input
  //             type="radio"
  //             id="j16"
  //             name="1"
  //             value={6}
  //             onChange={(event) => {
  //               setJA1(event.target.value);
  //               setCa16(event.target.checked);
  //               setCa12(false);
  //               setCa13(false);
  //               setCa14(false);
  //               setCa15(false);
  //               setCa11(false);
  //             }}
  //           />
  //           <label htmlFor="j16">
  //             <img src={A1Q6} />
  //           </label>
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  return (
    <React.Fragment>
      <div className="tC">
        <div className="tCLeft">
          <div className="tCLeftTop">
            <i className="ri-arrow-left-line fz24"></i>
            <div className="tCLeftTopDesc">
              <p className="tCLeftTopKeterangan">
                Type {typeNow}: <br />
                Basic Spatial
              </p>
              <div className="tCLeftTopList">
                <div className="tCLeftTopLine"></div>
                <div className="tCLeftTopType">
                  {typeNow === "A" ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="10"
                          fill="#FCFCFC"
                          stroke="#10B981"
                          strokeWidth={1.6}
                        />
                        <path
                          d="M12 1.99902C17.524 1.99902 22.002 6.47702 22.002 12.001C22.002 17.524 17.524 22.002 12 22.002C6.47605 22.002 1.99805 17.524 1.99805 12.001C1.99805 6.47702 6.47605 1.99902 12 1.99902ZM12 3.49902C10.8759 3.48683 9.76041 3.69772 8.71827 4.1195C7.67612 4.54127 6.72798 5.16554 5.92871 5.95619C5.12944 6.74684 4.49492 7.68816 4.06187 8.72566C3.62882 9.76317 3.40584 10.8763 3.40584 12.0005C3.40584 13.1248 3.62882 14.2379 4.06187 15.2754C4.49492 16.3129 5.12944 17.2542 5.92871 18.0449C6.72798 18.8355 7.67612 19.4598 8.71827 19.8816C9.76041 20.3033 10.8759 20.5142 12 20.502C14.2321 20.4678 16.3611 19.5571 17.9274 17.9666C19.4937 16.376 20.3717 14.2333 20.3717 12.001C20.3717 9.76874 19.4937 7.626 17.9274 6.03548C16.3611 4.44496 14.2321 3.53326 12 3.49902ZM11.997 5.99902C13.5878 5.99902 15.1134 6.63095 16.2383 7.7558C17.3631 8.88064 17.995 10.4063 17.995 11.997C17.995 13.5878 17.3631 15.1134 16.2383 16.2382C15.1134 17.3631 13.5878 17.995 11.997 17.995C10.4063 17.995 8.88066 17.3631 7.75582 16.2382C6.63098 15.1134 5.99905 13.5878 5.99905 11.997C5.99905 10.4063 6.63098 8.88064 7.75582 7.7558C8.88066 6.63095 10.4063 5.99902 11.997 5.99902Z"
                          fill="#10B981"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="9.3"
                          fill="#FCFCFC"
                          stroke="#10B981"
                          strokeWidth={2.5}
                        />
                      </svg>
                    </>
                  )}
                  <p>Type A</p>
                </div>
                <div className="tCLeftTopType">
                  {typeNow === "B" ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="10"
                          fill="#FCFCFC"
                          stroke="#10B981"
                          strokeWidth={1.6}
                        />
                        <path
                          d="M12 1.99902C17.524 1.99902 22.002 6.47702 22.002 12.001C22.002 17.524 17.524 22.002 12 22.002C6.47605 22.002 1.99805 17.524 1.99805 12.001C1.99805 6.47702 6.47605 1.99902 12 1.99902ZM12 3.49902C10.8759 3.48683 9.76041 3.69772 8.71827 4.1195C7.67612 4.54127 6.72798 5.16554 5.92871 5.95619C5.12944 6.74684 4.49492 7.68816 4.06187 8.72566C3.62882 9.76317 3.40584 10.8763 3.40584 12.0005C3.40584 13.1248 3.62882 14.2379 4.06187 15.2754C4.49492 16.3129 5.12944 17.2542 5.92871 18.0449C6.72798 18.8355 7.67612 19.4598 8.71827 19.8816C9.76041 20.3033 10.8759 20.5142 12 20.502C14.2321 20.4678 16.3611 19.5571 17.9274 17.9666C19.4937 16.376 20.3717 14.2333 20.3717 12.001C20.3717 9.76874 19.4937 7.626 17.9274 6.03548C16.3611 4.44496 14.2321 3.53326 12 3.49902ZM11.997 5.99902C13.5878 5.99902 15.1134 6.63095 16.2383 7.7558C17.3631 8.88064 17.995 10.4063 17.995 11.997C17.995 13.5878 17.3631 15.1134 16.2383 16.2382C15.1134 17.3631 13.5878 17.995 11.997 17.995C10.4063 17.995 8.88066 17.3631 7.75582 16.2382C6.63098 15.1134 5.99905 13.5878 5.99905 11.997C5.99905 10.4063 6.63098 8.88064 7.75582 7.7558C8.88066 6.63095 10.4063 5.99902 11.997 5.99902Z"
                          fill="#10B981"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="9.3"
                          fill="#FCFCFC"
                          stroke="#10B981"
                          strokeWidth={2.5}
                        />
                      </svg>
                    </>
                  )}
                  <p>Type B</p>
                </div>
                <div className="tCLeftTopType">
                  {typeNow === "C" ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="10"
                          fill="#FCFCFC"
                          stroke="#10B981"
                          strokeWidth={1.6}
                        />
                        <path
                          d="M12 1.99902C17.524 1.99902 22.002 6.47702 22.002 12.001C22.002 17.524 17.524 22.002 12 22.002C6.47605 22.002 1.99805 17.524 1.99805 12.001C1.99805 6.47702 6.47605 1.99902 12 1.99902ZM12 3.49902C10.8759 3.48683 9.76041 3.69772 8.71827 4.1195C7.67612 4.54127 6.72798 5.16554 5.92871 5.95619C5.12944 6.74684 4.49492 7.68816 4.06187 8.72566C3.62882 9.76317 3.40584 10.8763 3.40584 12.0005C3.40584 13.1248 3.62882 14.2379 4.06187 15.2754C4.49492 16.3129 5.12944 17.2542 5.92871 18.0449C6.72798 18.8355 7.67612 19.4598 8.71827 19.8816C9.76041 20.3033 10.8759 20.5142 12 20.502C14.2321 20.4678 16.3611 19.5571 17.9274 17.9666C19.4937 16.376 20.3717 14.2333 20.3717 12.001C20.3717 9.76874 19.4937 7.626 17.9274 6.03548C16.3611 4.44496 14.2321 3.53326 12 3.49902ZM11.997 5.99902C13.5878 5.99902 15.1134 6.63095 16.2383 7.7558C17.3631 8.88064 17.995 10.4063 17.995 11.997C17.995 13.5878 17.3631 15.1134 16.2383 16.2382C15.1134 17.3631 13.5878 17.995 11.997 17.995C10.4063 17.995 8.88066 17.3631 7.75582 16.2382C6.63098 15.1134 5.99905 13.5878 5.99905 11.997C5.99905 10.4063 6.63098 8.88064 7.75582 7.7558C8.88066 6.63095 10.4063 5.99902 11.997 5.99902Z"
                          fill="#10B981"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="9.3"
                          fill="#FCFCFC"
                          stroke="#10B981"
                          strokeWidth={2.5}
                        />
                      </svg>
                    </>
                  )}
                  <p>Type C</p>
                </div>
                <div className="tCLeftTopType">
                  {typeNow === "D" ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="10"
                          fill="#FCFCFC"
                          stroke="#10B981"
                          strokeWidth={1.6}
                        />
                        <path
                          d="M12 1.99902C17.524 1.99902 22.002 6.47702 22.002 12.001C22.002 17.524 17.524 22.002 12 22.002C6.47605 22.002 1.99805 17.524 1.99805 12.001C1.99805 6.47702 6.47605 1.99902 12 1.99902ZM12 3.49902C10.8759 3.48683 9.76041 3.69772 8.71827 4.1195C7.67612 4.54127 6.72798 5.16554 5.92871 5.95619C5.12944 6.74684 4.49492 7.68816 4.06187 8.72566C3.62882 9.76317 3.40584 10.8763 3.40584 12.0005C3.40584 13.1248 3.62882 14.2379 4.06187 15.2754C4.49492 16.3129 5.12944 17.2542 5.92871 18.0449C6.72798 18.8355 7.67612 19.4598 8.71827 19.8816C9.76041 20.3033 10.8759 20.5142 12 20.502C14.2321 20.4678 16.3611 19.5571 17.9274 17.9666C19.4937 16.376 20.3717 14.2333 20.3717 12.001C20.3717 9.76874 19.4937 7.626 17.9274 6.03548C16.3611 4.44496 14.2321 3.53326 12 3.49902ZM11.997 5.99902C13.5878 5.99902 15.1134 6.63095 16.2383 7.7558C17.3631 8.88064 17.995 10.4063 17.995 11.997C17.995 13.5878 17.3631 15.1134 16.2383 16.2382C15.1134 17.3631 13.5878 17.995 11.997 17.995C10.4063 17.995 8.88066 17.3631 7.75582 16.2382C6.63098 15.1134 5.99905 13.5878 5.99905 11.997C5.99905 10.4063 6.63098 8.88064 7.75582 7.7558C8.88066 6.63095 10.4063 5.99902 11.997 5.99902Z"
                          fill="#10B981"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="9.3"
                          fill="#FCFCFC"
                          stroke="#10B981"
                          strokeWidth={2.5}
                        />
                      </svg>
                    </>
                  )}
                  <p>Type D</p>
                </div>
                <div className="tCLeftTopType">
                  {typeNow === "E" ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="10"
                          fill="#FCFCFC"
                          stroke="#10B981"
                          strokeWidth={1.6}
                        />
                        <path
                          d="M12 1.99902C17.524 1.99902 22.002 6.47702 22.002 12.001C22.002 17.524 17.524 22.002 12 22.002C6.47605 22.002 1.99805 17.524 1.99805 12.001C1.99805 6.47702 6.47605 1.99902 12 1.99902ZM12 3.49902C10.8759 3.48683 9.76041 3.69772 8.71827 4.1195C7.67612 4.54127 6.72798 5.16554 5.92871 5.95619C5.12944 6.74684 4.49492 7.68816 4.06187 8.72566C3.62882 9.76317 3.40584 10.8763 3.40584 12.0005C3.40584 13.1248 3.62882 14.2379 4.06187 15.2754C4.49492 16.3129 5.12944 17.2542 5.92871 18.0449C6.72798 18.8355 7.67612 19.4598 8.71827 19.8816C9.76041 20.3033 10.8759 20.5142 12 20.502C14.2321 20.4678 16.3611 19.5571 17.9274 17.9666C19.4937 16.376 20.3717 14.2333 20.3717 12.001C20.3717 9.76874 19.4937 7.626 17.9274 6.03548C16.3611 4.44496 14.2321 3.53326 12 3.49902ZM11.997 5.99902C13.5878 5.99902 15.1134 6.63095 16.2383 7.7558C17.3631 8.88064 17.995 10.4063 17.995 11.997C17.995 13.5878 17.3631 15.1134 16.2383 16.2382C15.1134 17.3631 13.5878 17.995 11.997 17.995C10.4063 17.995 8.88066 17.3631 7.75582 16.2382C6.63098 15.1134 5.99905 13.5878 5.99905 11.997C5.99905 10.4063 6.63098 8.88064 7.75582 7.7558C8.88066 6.63095 10.4063 5.99902 11.997 5.99902Z"
                          fill="#10B981"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="9.3"
                          fill="#FCFCFC"
                          stroke="#10B981"
                          strokeWidth={2.5}
                        />
                      </svg>
                    </>
                  )}
                  <p>Type E</p>
                </div>
              </div>
            </div>
          </div>
          <div className="tCLeftBottom">
            <img src={HalfO} />
            <div className="bg">
              <div>
                <p>Apa kegunaan Test ini?</p>
                <ul>
                  <li>Pengukuran Kecerdasan Non-Verbal</li>
                  <li>Pengukuran Kecerdasan Umum</li>
                  <li>Identifikasi Bakat dan Potensi</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="tCRight">
          <div className="tCTopbar">
            <div className="web">
              <img src={Logo} />
              <p className="title">Standart Progressive Matrices Test</p>
            </div>
            <div className="mob">
              <p className="title">SPM Test</p>
            </div>
          </div>
          <div className="tCMain">
            {halaman === "home" && (
              <>
                <img src={Spm} className="open" />
                <div className="info">
                  <div className="ket">
                    <p className="title">⚠️ Petunjuk Pengerjaan Soal</p>
                    <p>
                      Tes ini akan menguji kemampuan Anda untuk mengenali pola
                      dan hubungan dalam matriks. Cobalah untuk mengidentifikasi
                      pola umum atau aturan dalam matriks sebelum Anda mencoba
                      menjawab soal.
                    </p>
                  </div>
                </div>
                <p className="waktu">Waktu Pengerjaan: 30 Menit </p>

                <Link to="#" className="buttonTest" onClick={() => { setHalaman("mulai"); setBenar(true); }} >
                  Mulai Test
                </Link>
              </>
            )}
            {halaman === "mulai" && (
              <>
                <div className="soal">
                  <div className="mobileTop">
                    <p className={typeNow === "A" ? "clickable" : "a"}>
                      <span className={typeNow === "A" ? "whiter" : "b"}>
                        A
                      </span>
                    </p>
                    <p className={typeNow === "B" ? "clickable" : "a"}>
                      <span className={typeNow === "B" ? "whiter" : "b"}>
                        B
                      </span>
                    </p>
                    <p className={typeNow === "C" ? "clickable" : "a"}>
                      <span className={typeNow === "C" ? "whiter" : "b"}>
                        C
                      </span>
                    </p>
                    <p className={typeNow === "D" ? "clickable" : "a"}>
                      <span className={typeNow === "D" ? "whiter" : "b"}>
                        D
                      </span>
                    </p>
                    <p className={typeNow === "E" ? "clickable" : "a"}>
                      <span className={typeNow === "E" ? "whiter" : "b"}>
                        E
                      </span>
                    </p>
                  </div>

                  <div>
                    <div className="top">
                      <p> {nomor} / 60 </p>
                      <p className={berjalan}>
                        {" "}
                        <i
                          className="bx bx-time"
                          style={{ marginLeft: "4px" }}
                        ></i>{" "}
                        {"  "} {min > 9 ? min : "0" + min}:
                        {sec > 9 ? sec : "0" + sec}{" "}
                      </p>
                    </div>
                    {/* <div className="grupNumber">
                      <span className="number">1</span>
                      <span className="number">2</span>
                      <span className="number">3</span>
                      <span className="number">4</span>
                      <span className="number">5</span>
                      <span className="number">6</span>
                      <span className="number">7</span>
                      <span className="number">8</span>
                      <span className="number">9</span>
                      <span className="number">10</span>
                      <span className="number">11</span>
                      <span className="number">12</span>
                    </div> */} 
                  </div>

                  {nomor % 12 === 1 && (
                    <>
                      {typeNow === "A" && (
                        <>
                          <img src={A1} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={ca11 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j11"
                                name="1"
                                value={1}
                                onChange={(event) => {
                                  setJA1(event.target.value);
                                  setCa11(event.target.checked);
                                  setCa12(false);
                                  setCa13(false);
                                  setCa14(false);
                                  setCa15(false);
                                  setCa16(false);
                                }}
                              />
                              <label htmlFor="j11">
                                <img src={A1Q1} />
                              </label>
                            </div>
                            <div className={ca12 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j12"
                                name="1"
                                value={2}
                                onChange={(event) => {
                                  setJA1(event.target.value);
                                  setCa12(event.target.checked);
                                  setCa11(false);
                                  setCa13(false);
                                  setCa14(false);
                                  setCa15(false);
                                  setCa16(false);
                                }}
                              />
                              <label htmlFor="j12">
                                <img src={A1Q2} />
                              </label>
                            </div>
                            <div className={ca13 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j13"
                                name="1"
                                value={3}
                                onChange={(event) => {
                                  setJA1(event.target.value);
                                  setCa13(event.target.checked);
                                  setCa12(false);
                                  setCa11(false);
                                  setCa14(false);
                                  setCa15(false);
                                  setCa16(false);
                                }}
                              />
                              <label htmlFor="j13">
                                <img src={A1Q3} />
                              </label>
                            </div>
                            <div className={ca14 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j14"
                                name="1"
                                value={4}
                                onChange={(event) => {
                                  setJA1(event.target.value);
                                  setCa14(event.target.checked);
                                  setCa12(false);
                                  setCa13(false);
                                  setCa11(false);
                                  setCa15(false);
                                  setCa16(false);
                                }}
                              />
                              <label htmlFor="j14">
                                <img src={A1Q4} />
                              </label>
                            </div>
                            <div className={ca15 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j15"
                                name="1"
                                value={5}
                                onChange={(event) => {
                                  setJA1(event.target.value);
                                  setCa15(event.target.checked);
                                  setCa12(false);
                                  setCa13(false);
                                  setCa14(false);
                                  setCa11(false);
                                  setCa16(false);
                                }}
                              />
                              <label htmlFor="j15">
                                <img src={A1Q5} />
                              </label>
                            </div>
                            <div className={ca16 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j16"
                                name="1"
                                value={6}
                                onChange={(event) => {
                                  setJA1(event.target.value);
                                  setCa16(event.target.checked);
                                  setCa12(false);
                                  setCa13(false);
                                  setCa14(false);
                                  setCa15(false);
                                  setCa11(false);
                                }}
                              />
                              <label htmlFor="j16">
                                <img src={A1Q6} />
                              </label>
                            </div>

                            {typeNow === "C" || typeNow === "D" || typeNow === "E" && (
                              <>
                              <div className={ca15 ? "jawaban click" : "jawaban"}>
                                <input
                                  type="radio"
                                  id="j15"
                                  name="1"
                                  value={5}
                                  onChange={(event) => {
                                    setJA1(event.target.value);
                                    setCa15(event.target.checked);
                                    setCa12(false);
                                    setCa13(false);
                                    setCa14(false);
                                    setCa11(false);
                                    setCa16(false);
                                  }}
                                />
                                <label htmlFor="j15">
                                  <img src={A1Q5} />
                                </label>
                              </div>
                              <div className={ca16 ? "jawaban click" : "jawaban"}>
                                <input
                                  type="radio"
                                  id="j16"
                                  name="1"
                                  value={6}
                                  onChange={(event) => {
                                    setJA1(event.target.value);
                                    setCa16(event.target.checked);
                                    setCa12(false);
                                    setCa13(false);
                                    setCa14(false);
                                    setCa15(false);
                                    setCa11(false);
                                  }}
                                />
                                <label htmlFor="j16">
                                  <img src={A1Q6} />
                                </label>
                              </div>
                              </>
                            )}

                          </div>
                        </>
                      )}

                      {typeNow === "B" && (
                        <>
                          <img src={B1} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={cb11 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j11"
                                name="1"
                                value={1}
                                onChange={(event) => {
                                  setJB1(event.target.value);
                                  setCb11(event.target.checked);
                                  setCb12(false);
                                  setCb13(false);
                                  setCb14(false);
                                  setCb15(false);
                                  setCb16(false);
                                }}
                              />
                              <label htmlFor="j11">
                                <img src={B1Q1} />
                              </label>
                            </div>
                            <div className={cb12 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j12"
                                name="1"
                                value={2}
                                onChange={(event) => {
                                  setJB1(event.target.value);
                                  setCb12(event.target.checked);
                                  setCb11(false);
                                  setCb13(false);
                                  setCb14(false);
                                  setCb15(false);
                                  setCb16(false);
                                }}
                              />
                              <label htmlFor="j12">
                                <img src={B1Q2} />
                              </label>
                            </div>
                            <div className={cb13 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j13"
                                name="1"
                                value={3}
                                onChange={(event) => {
                                  setJB1(event.target.value);
                                  setCb13(event.target.checked);
                                  setCb12(false);
                                  setCb11(false);
                                  setCb14(false);
                                  setCb15(false);
                                  setCb16(false);
                                }}
                              />
                              <label htmlFor="j13">
                                <img src={B1Q3} />
                              </label>
                            </div>
                            <div className={cb14 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j14"
                                name="1"
                                value={4}
                                onChange={(event) => {
                                  setJB1(event.target.value);
                                  setCb14(event.target.checked);
                                  setCb12(false);
                                  setCb13(false);
                                  setCb11(false);
                                  setCb15(false);
                                  setCb16(false);
                                }}
                              />
                              <label htmlFor="j14">
                                <img src={B1Q4} />
                              </label>
                            </div>
                            <div className={cb15 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j15"
                                name="1"
                                value={5}
                                onChange={(event) => {
                                  setJB1(event.target.value);
                                  setCb15(event.target.checked);
                                  setCb12(false);
                                  setCb13(false);
                                  setCb14(false);
                                  setCb11(false);
                                  setCb16(false);
                                }}
                              />
                              <label htmlFor="j15">
                                <img src={B1Q5} />
                              </label>
                            </div>
                            <div className={cb16 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j16"
                                name="1"
                                value={6}
                                onChange={(event) => {
                                  setJB1(event.target.value);
                                  setCb16(event.target.checked);
                                  setCb12(false);
                                  setCb13(false);
                                  setCb14(false);
                                  setCb15(false);
                                  setCb11(false);
                                }}
                              />
                              <label htmlFor="j16">
                                <img src={B1Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {nomor % 12 === 2 && (
                    <>
                      {typeNow === "A" && (
                        <>
                          <img src={A2} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={ca21 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j21"
                                name="2"
                                value={1}
                                onChange={(event) => {
                                  setJA2(event.target.value);
                                  setCa21(event.target.checked);
                                  setCa22(false);
                                  setCa23(false);
                                  setCa24(false);
                                  setCa25(false);
                                  setCa26(false);
                                }}
                              />
                              <label htmlFor="j21">
                                <img src={A2Q1} />
                              </label>
                            </div>
                            <div className={ca22 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j22"
                                name="2"
                                value={2}
                                onChange={(event) => {
                                  setJA2(event.target.value);
                                  setCa22(event.target.checked);
                                  setCa21(false);
                                  setCa23(false);
                                  setCa24(false);
                                  setCa25(false);
                                  setCa26(false);
                                }}
                              />
                              <label htmlFor="j22">
                                <img src={A2Q2} />
                              </label>
                            </div>
                            <div className={ca23 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j23"
                                name="2"
                                value={3}
                                onChange={(event) => {
                                  setJA2(event.target.value);
                                  setCa23(event.target.checked);
                                  setCa22(false);
                                  setCa21(false);
                                  setCa24(false);
                                  setCa25(false);
                                  setCa26(false);
                                }}
                              />
                              <label htmlFor="j23">
                                <img src={A2Q3} />
                              </label>
                            </div>
                            <div className={ca24 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j24"
                                name="2"
                                value={4}
                                onChange={(event) => {
                                  setJA2(event.target.value);
                                  setCa24(event.target.checked);
                                  setCa22(false);
                                  setCa23(false);
                                  setCa21(false);
                                  setCa25(false);
                                  setCa26(false);
                                }}
                              />
                              <label htmlFor="j24">
                                <img src={A2Q4} />
                              </label>
                            </div>
                            <div className={ca25 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j25"
                                name="2"
                                value={5}
                                onChange={(event) => {
                                  setJA2(event.target.value);
                                  setCa25(event.target.checked);
                                  setCa22(false);
                                  setCa23(false);
                                  setCa24(false);
                                  setCa21(false);
                                  setCa26(false);
                                }}
                              />
                              <label htmlFor="j25">
                                <img src={A2Q5} />
                              </label>
                            </div>
                            <div className={ca26 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j26"
                                name="2"
                                value={6}
                                onChange={(event) => {
                                  setJA2(event.target.value);
                                  setCa26(event.target.checked);
                                  setCa22(false);
                                  setCa23(false);
                                  setCa24(false);
                                  setCa25(false);
                                  setCa21(false);
                                }}
                              />
                              <label htmlFor="j26">
                                <img src={A2Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "B" && (
                        <>
                          <img src={B2} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={cb21 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j21"
                                name="2"
                                value={1}
                                onChange={(event) => {
                                  setJB2(event.target.value);
                                  setCb21(event.target.checked);
                                  setCb22(false);
                                  setCb23(false);
                                  setCb24(false);
                                  setCb25(false);
                                  setCb26(false);
                                }}
                              />
                              <label htmlFor="j21">
                                <img src={B2Q1} />
                              </label>
                            </div>
                            <div className={cb22 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j22"
                                name="2"
                                value={2}
                                onChange={(event) => {
                                  setJB2(event.target.value);
                                  setCb22(event.target.checked);
                                  setCb21(false);
                                  setCb23(false);
                                  setCb24(false);
                                  setCb25(false);
                                  setCb26(false);
                                }}
                              />
                              <label htmlFor="j22">
                                <img src={B2Q2} />
                              </label>
                            </div>
                            <div className={cb23 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j23"
                                name="2"
                                value={3}
                                onChange={(event) => {
                                  setJB2(event.target.value);
                                  setCb23(event.target.checked);
                                  setCb22(false);
                                  setCb21(false);
                                  setCb24(false);
                                  setCb25(false);
                                  setCb26(false);
                                }}
                              />
                              <label htmlFor="j23">
                                <img src={B2Q3} />
                              </label>
                            </div>
                            <div className={cb24 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j24"
                                name="2"
                                value={4}
                                onChange={(event) => {
                                  setJB2(event.target.value);
                                  setCb24(event.target.checked);
                                  setCb22(false);
                                  setCb23(false);
                                  setCb21(false);
                                  setCb25(false);
                                  setCb26(false);
                                }}
                              />
                              <label htmlFor="j24">
                                <img src={B2Q4} />
                              </label>
                            </div>
                            <div className={cb25 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j25"
                                name="2"
                                value={5}
                                onChange={(event) => {
                                  setJB2(event.target.value);
                                  setCb25(event.target.checked);
                                  setCb22(false);
                                  setCb23(false);
                                  setCb24(false);
                                  setCb21(false);
                                  setCb26(false);
                                }}
                              />
                              <label htmlFor="j25">
                                <img src={B2Q5} />
                              </label>
                            </div>
                            <div className={cb26 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j26"
                                name="2"
                                value={6}
                                onChange={(event) => {
                                  setJB2(event.target.value);
                                  setCb26(event.target.checked);
                                  setCb22(false);
                                  setCb23(false);
                                  setCb24(false);
                                  setCb25(false);
                                  setCb21(false);
                                }}
                              />
                              <label htmlFor="j26">
                                <img src={B2Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {nomor % 12 === 3 && (
                    <>
                      {typeNow === "A" && (
                        <>
                          <img src={A3} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={ca31 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j31"
                                name="3"
                                value={1}
                                onChange={(event) => {
                                  setJA3(event.target.value);
                                  setCa31(event.target.checked);
                                  setCa32(false);
                                  setCa33(false);
                                  setCa34(false);
                                  setCa35(false);
                                  setCa36(false);
                                }}
                              />
                              <label htmlFor="j31">
                                <img src={A3Q1} />
                              </label>
                            </div>
                            <div className={ca32 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j32"
                                name="3"
                                value={2}
                                onChange={(event) => {
                                  setJA3(event.target.value);
                                  setCa32(event.target.checked);
                                  setCa31(false);
                                  setCa33(false);
                                  setCa34(false);
                                  setCa35(false);
                                  setCa36(false);
                                }}
                              />
                              <label htmlFor="j32">
                                <img src={A3Q2} />
                              </label>
                            </div>
                            <div className={ca33 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j33"
                                name="3"
                                value={3}
                                onChange={(event) => {
                                  setJA3(event.target.value);
                                  setCa33(event.target.checked);
                                  setCa32(false);
                                  setCa31(false);
                                  setCa34(false);
                                  setCa35(false);
                                  setCa36(false);
                                }}
                              />
                              <label htmlFor="j33">
                                <img src={A3Q3} />
                              </label>
                            </div>
                            <div className={ca34 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j34"
                                name="3"
                                value={4}
                                onChange={(event) => {
                                  setJA3(event.target.value);
                                  setCa34(event.target.checked);
                                  setCa32(false);
                                  setCa33(false);
                                  setCa31(false);
                                  setCa35(false);
                                  setCa36(false);
                                }}
                              />
                              <label htmlFor="j34">
                                <img src={A3Q4} />
                              </label>
                            </div>
                            <div className={ca35 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j35"
                                name="3"
                                value={5}
                                onChange={(event) => {
                                  setJA3(event.target.value);
                                  setCa35(event.target.checked);
                                  setCa32(false);
                                  setCa33(false);
                                  setCa34(false);
                                  setCa31(false);
                                  setCa36(false);
                                }}
                              />
                              <label htmlFor="j35">
                                <img src={A3Q5} />
                              </label>
                            </div>
                            <div className={ca36 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j36"
                                name="3"
                                value={6}
                                onChange={(event) => {
                                  setJA3(event.target.value);
                                  setCa36(event.target.checked);
                                  setCa32(false);
                                  setCa33(false);
                                  setCa34(false);
                                  setCa35(false);
                                  setCa31(false);
                                }}
                              />
                              <label htmlFor="j36">
                                <img src={A3Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "B" && (
                        <>
                          <img src={B3} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={cb31 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j31"
                                name="3"
                                value={1}
                                onChange={(event) => {
                                  setJB3(event.target.value);
                                  setCb31(event.target.checked);
                                  setCb32(false);
                                  setCb33(false);
                                  setCb34(false);
                                  setCb35(false);
                                  setCb36(false);
                                }}
                              />
                              <label htmlFor="j31">
                                <img src={B3Q1} />
                              </label>
                            </div>
                            <div className={cb32 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j32"
                                name="3"
                                value={2}
                                onChange={(event) => {
                                  setJB3(event.target.value);
                                  setCb32(event.target.checked);
                                  setCb31(false);
                                  setCb33(false);
                                  setCb34(false);
                                  setCb35(false);
                                  setCb36(false);
                                }}
                              />
                              <label htmlFor="j32">
                                <img src={B3Q2} />
                              </label>
                            </div>
                            <div className={cb33 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j33"
                                name="3"
                                value={3}
                                onChange={(event) => {
                                  setJB3(event.target.value);
                                  setCb33(event.target.checked);
                                  setCb32(false);
                                  setCb31(false);
                                  setCb34(false);
                                  setCb35(false);
                                  setCb36(false);
                                }}
                              />
                              <label htmlFor="j33">
                                <img src={B3Q3} />
                              </label>
                            </div>
                            <div className={cb34 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j34"
                                name="3"
                                value={4}
                                onChange={(event) => {
                                  setJB3(event.target.value);
                                  setCb34(event.target.checked);
                                  setCb32(false);
                                  setCb33(false);
                                  setCb31(false);
                                  setCb35(false);
                                  setCb36(false);
                                }}
                              />
                              <label htmlFor="j34">
                                <img src={B3Q4} />
                              </label>
                            </div>
                            <div className={cb35 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j35"
                                name="3"
                                value={5}
                                onChange={(event) => {
                                  setJB3(event.target.value);
                                  setCb35(event.target.checked);
                                  setCb32(false);
                                  setCb33(false);
                                  setCb34(false);
                                  setCb31(false);
                                  setCb36(false);
                                }}
                              />
                              <label htmlFor="j35">
                                <img src={B3Q5} />
                              </label>
                            </div>
                            <div className={cb36 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j36"
                                name="3"
                                value={6}
                                onChange={(event) => {
                                  setJB3(event.target.value);
                                  setCb36(event.target.checked);
                                  setCb32(false);
                                  setCb33(false);
                                  setCb34(false);
                                  setCb35(false);
                                  setCb31(false);
                                }}
                              />
                              <label htmlFor="j36">
                                <img src={B3Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {nomor % 12 === 4 && (
                    <>
                      {typeNow === "A" && (
                        <>
                          <img src={A4} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={ca41 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j41"
                                name="4"
                                value={1}
                                onChange={(event) => {
                                  setJA4(event.target.value);
                                  setCa41(event.target.checked);
                                  setCa42(false);
                                  setCa43(false);
                                  setCa44(false);
                                  setCa45(false);
                                  setCa46(false);
                                }}
                              />
                              <label htmlFor="j41">
                                <img src={A4Q1} />
                              </label>
                            </div>
                            <div className={ca42 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j42"
                                name="4"
                                value={2}
                                onChange={(event) => {
                                  setJA4(event.target.value);
                                  setCa42(event.target.checked);
                                  setCa41(false);
                                  setCa43(false);
                                  setCa44(false);
                                  setCa45(false);
                                  setCa46(false);
                                }}
                              />
                              <label htmlFor="j42">
                                <img src={A4Q2} />
                              </label>
                            </div>
                            <div className={ca43 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j43"
                                name="4"
                                value={3}
                                onChange={(event) => {
                                  setJA4(event.target.value);
                                  setCa43(event.target.checked);
                                  setCa42(false);
                                  setCa41(false);
                                  setCa44(false);
                                  setCa45(false);
                                  setCa46(false);
                                }}
                              />
                              <label htmlFor="j43">
                                <img src={A4Q3} />
                              </label>
                            </div>
                            <div className={ca44 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j44"
                                name="4"
                                value={4}
                                onChange={(event) => {
                                  setJA4(event.target.value);
                                  setCa44(event.target.checked);
                                  setCa42(false);
                                  setCa43(false);
                                  setCa41(false);
                                  setCa45(false);
                                  setCa46(false);
                                }}
                              />
                              <label htmlFor="j44">
                                <img src={A4Q4} />
                              </label>
                            </div>
                            <div className={ca45 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j45"
                                name="4"
                                value={5}
                                onChange={(event) => {
                                  setJA4(event.target.value);
                                  setCa45(event.target.checked);
                                  setCa42(false);
                                  setCa43(false);
                                  setCa44(false);
                                  setCa41(false);
                                  setCa46(false);
                                }}
                              />
                              <label htmlFor="j45">
                                <img src={A4Q5} />
                              </label>
                            </div>
                            <div className={ca46 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j46"
                                name="4"
                                value={6}
                                onChange={(event) => {
                                  setJA4(event.target.value);
                                  setCa46(event.target.checked);
                                  setCa42(false);
                                  setCa43(false);
                                  setCa44(false);
                                  setCa45(false);
                                  setCa41(false);
                                }}
                              />
                              <label htmlFor="j46">
                                <img src={A4Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "B" && (
                        <>
                          <img src={B4} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={cb41 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j41"
                                name="4"
                                value={1}
                                onChange={(event) => {
                                  setJB4(event.target.value);
                                  setCb41(event.target.checked);
                                  setCb42(false);
                                  setCb43(false);
                                  setCb44(false);
                                  setCb45(false);
                                  setCb46(false);
                                }}
                              />
                              <label htmlFor="j41">
                                <img src={B4Q1} />
                              </label>
                            </div>
                            <div className={cb42 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j42"
                                name="4"
                                value={2}
                                onChange={(event) => {
                                  setJB4(event.target.value);
                                  setCb42(event.target.checked);
                                  setCb41(false);
                                  setCb43(false);
                                  setCb44(false);
                                  setCb45(false);
                                  setCb46(false);
                                }}
                              />
                              <label htmlFor="j42">
                                <img src={B4Q2} />
                              </label>
                            </div>
                            <div className={cb43 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j43"
                                name="4"
                                value={3}
                                onChange={(event) => {
                                  setJB4(event.target.value);
                                  setCb43(event.target.checked);
                                  setCb42(false);
                                  setCb41(false);
                                  setCb44(false);
                                  setCb45(false);
                                  setCb46(false);
                                }}
                              />
                              <label htmlFor="j43">
                                <img src={B4Q3} />
                              </label>
                            </div>
                            <div className={cb44 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j44"
                                name="4"
                                value={4}
                                onChange={(event) => {
                                  setJB4(event.target.value);
                                  setCb44(event.target.checked);
                                  setCb42(false);
                                  setCb43(false);
                                  setCb41(false);
                                  setCb45(false);
                                  setCb46(false);
                                }}
                              />
                              <label htmlFor="j44">
                                <img src={B4Q4} />
                              </label>
                            </div>
                            <div className={cb45 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j45"
                                name="4"
                                value={5}
                                onChange={(event) => {
                                  setJB4(event.target.value);
                                  setCb45(event.target.checked);
                                  setCb42(false);
                                  setCb43(false);
                                  setCb44(false);
                                  setCb41(false);
                                  setCb46(false);
                                }}
                              />
                              <label htmlFor="j45">
                                <img src={B4Q5} />
                              </label>
                            </div>
                            <div className={cb46 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j46"
                                name="4"
                                value={6}
                                onChange={(event) => {
                                  setJB4(event.target.value);
                                  setCb46(event.target.checked);
                                  setCb42(false);
                                  setCb43(false);
                                  setCb44(false);
                                  setCb45(false);
                                  setCb41(false);
                                }}
                              />
                              <label htmlFor="j46">
                                <img src={B4Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {nomor % 12 === 5 && (
                    <>
                      {typeNow === "A" && (
                        <>
                          <img src={A5} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={ca51 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j51"
                                name="5"
                                value={1}
                                onChange={(event) => {
                                  setJA5(event.target.value);
                                  setCa51(event.target.checked);
                                  setCa52(false);
                                  setCa53(false);
                                  setCa54(false);
                                  setCa55(false);
                                  setCa56(false);
                                }}
                              />
                              <label htmlFor="j51">
                                <img src={A5Q1} />
                              </label>
                            </div>
                            <div className={ca52 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j52"
                                name="5"
                                value={2}
                                onChange={(event) => {
                                  setJA5(event.target.value);
                                  setCa52(event.target.checked);
                                  setCa51(false);
                                  setCa53(false);
                                  setCa54(false);
                                  setCa55(false);
                                  setCa56(false);
                                }}
                              />
                              <label htmlFor="j52">
                                <img src={A5Q2} />
                              </label>
                            </div>
                            <div className={ca53 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j53"
                                name="5"
                                value={3}
                                onChange={(event) => {
                                  setJA5(event.target.value);
                                  setCa53(event.target.checked);
                                  setCa52(false);
                                  setCa51(false);
                                  setCa54(false);
                                  setCa55(false);
                                  setCa56(false);
                                }}
                              />
                              <label htmlFor="j53">
                                <img src={A5Q3} />
                              </label>
                            </div>
                            <div className={ca54 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j54"
                                name="5"
                                value={4}
                                onChange={(event) => {
                                  setJA5(event.target.value);
                                  setCa54(event.target.checked);
                                  setCa52(false);
                                  setCa53(false);
                                  setCa51(false);
                                  setCa55(false);
                                  setCa56(false);
                                }}
                              />
                              <label htmlFor="j54">
                                <img src={A5Q4} />
                              </label>
                            </div>
                            <div className={ca55 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j55"
                                name="5"
                                value={5}
                                onChange={(event) => {
                                  setJA5(event.target.value);
                                  setCa55(event.target.checked);
                                  setCa52(false);
                                  setCa53(false);
                                  setCa54(false);
                                  setCa51(false);
                                  setCa56(false);
                                }}
                              />
                              <label htmlFor="j55">
                                <img src={A5Q5} />
                              </label>
                            </div>
                            <div className={ca56 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j56"
                                name="5"
                                value={6}
                                onChange={(event) => {
                                  setJA5(event.target.value);
                                  setCa56(event.target.checked);
                                  setCa52(false);
                                  setCa53(false);
                                  setCa54(false);
                                  setCa55(false);
                                  setCa51(false);
                                }}
                              />
                              <label htmlFor="j56">
                                <img src={A5Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "B" && (
                        <>
                          <img src={B5} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={cb51 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j51"
                                name="5"
                                value={1}
                                onChange={(event) => {
                                  setJB5(event.target.value);
                                  setCb51(event.target.checked);
                                  setCb52(false);
                                  setCb53(false);
                                  setCb54(false);
                                  setCb55(false);
                                  setCb56(false);
                                }}
                              />
                              <label htmlFor="j51">
                                <img src={B5Q1} />
                              </label>
                            </div>
                            <div className={cb52 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j52"
                                name="5"
                                value={2}
                                onChange={(event) => {
                                  setJB5(event.target.value);
                                  setCb52(event.target.checked);
                                  setCb51(false);
                                  setCb53(false);
                                  setCb54(false);
                                  setCb55(false);
                                  setCb56(false);
                                }}
                              />
                              <label htmlFor="j52">
                                <img src={B5Q2} />
                              </label>
                            </div>
                            <div className={cb53 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j53"
                                name="5"
                                value={3}
                                onChange={(event) => {
                                  setJB5(event.target.value);
                                  setCb53(event.target.checked);
                                  setCb52(false);
                                  setCb51(false);
                                  setCb54(false);
                                  setCb55(false);
                                  setCb56(false);
                                }}
                              />
                              <label htmlFor="j53">
                                <img src={B5Q3} />
                              </label>
                            </div>
                            <div className={cb54 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j54"
                                name="5"
                                value={4}
                                onChange={(event) => {
                                  setJB5(event.target.value);
                                  setCb54(event.target.checked);
                                  setCb52(false);
                                  setCb53(false);
                                  setCb51(false);
                                  setCb55(false);
                                  setCb56(false);
                                }}
                              />
                              <label htmlFor="j54">
                                <img src={B5Q4} />
                              </label>
                            </div>
                            <div className={cb55 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j55"
                                name="5"
                                value={5}
                                onChange={(event) => {
                                  setJB5(event.target.value);
                                  setCb55(event.target.checked);
                                  setCb52(false);
                                  setCb53(false);
                                  setCb54(false);
                                  setCb51(false);
                                  setCb56(false);
                                }}
                              />
                              <label htmlFor="j55">
                                <img src={B5Q5} />
                              </label>
                            </div>
                            <div className={cb56 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j56"
                                name="5"
                                value={6}
                                onChange={(event) => {
                                  setJB5(event.target.value);
                                  setCb56(event.target.checked);
                                  setCb52(false);
                                  setCb53(false);
                                  setCb54(false);
                                  setCb55(false);
                                  setCb51(false);
                                }}
                              />
                              <label htmlFor="j56">
                                <img src={B5Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {nomor % 12 === 6 && (
                    <>
                      {typeNow === "A" && (
                        <>
                          <img src={A6} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={ca61 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j61"
                                name="6"
                                value={1}
                                onChange={(event) => {
                                  setJA6(event.target.value);
                                  setCa61(event.target.checked);
                                  setCa62(false);
                                  setCa63(false);
                                  setCa64(false);
                                  setCa65(false);
                                  setCa66(false);
                                }}
                              />
                              <label htmlFor="j61">
                                <img src={A6Q1} />
                              </label>
                            </div>
                            <div className={ca62 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j62"
                                name="6"
                                value={2}
                                onChange={(event) => {
                                  setJA6(event.target.value);
                                  setCa62(event.target.checked);
                                  setCa61(false);
                                  setCa63(false);
                                  setCa64(false);
                                  setCa65(false);
                                  setCa66(false);
                                }}
                              />
                              <label htmlFor="j62">
                                <img src={A6Q2} />
                              </label>
                            </div>
                            <div className={ca63 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j63"
                                name="6"
                                value={3}
                                onChange={(event) => {
                                  setJA6(event.target.value);
                                  setCa63(event.target.checked);
                                  setCa62(false);
                                  setCa61(false);
                                  setCa64(false);
                                  setCa65(false);
                                  setCa66(false);
                                }}
                              />
                              <label htmlFor="j63">
                                <img src={A6Q3} />
                              </label>
                            </div>
                            <div className={ca64 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j64"
                                name="6"
                                value={4}
                                onChange={(event) => {
                                  setJA6(event.target.value);
                                  setCa64(event.target.checked);
                                  setCa62(false);
                                  setCa63(false);
                                  setCa61(false);
                                  setCa65(false);
                                  setCa66(false);
                                }}
                              />
                              <label htmlFor="j64">
                                <img src={A6Q4} />
                              </label>
                            </div>
                            <div className={ca65 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j65"
                                name="6"
                                value={5}
                                onChange={(event) => {
                                  setJA6(event.target.value);
                                  setCa65(event.target.checked);
                                  setCa62(false);
                                  setCa63(false);
                                  setCa64(false);
                                  setCa61(false);
                                  setCa66(false);
                                }}
                              />
                              <label htmlFor="j65">
                                <img src={A6Q5} />
                              </label>
                            </div>
                            <div className={ca66 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j66"
                                name="6"
                                value={6}
                                onChange={(event) => {
                                  setJA6(event.target.value);
                                  setCa66(event.target.checked);
                                  setCa62(false);
                                  setCa63(false);
                                  setCa64(false);
                                  setCa65(false);
                                  setCa61(false);
                                }}
                              />
                              <label htmlFor="j66">
                                <img src={A6Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "B" && (
                        <>
                          <img src={B6} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={cb61 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j61"
                                name="6"
                                value={1}
                                onChange={(event) => {
                                  setJB6(event.target.value);
                                  setCb61(event.target.checked);
                                  setCb62(false);
                                  setCb63(false);
                                  setCb64(false);
                                  setCb65(false);
                                  setCb66(false);
                                }}
                              />
                              <label htmlFor="j61">
                                <img src={B6Q1} />
                              </label>
                            </div>
                            <div className={cb62 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j62"
                                name="6"
                                value={2}
                                onChange={(event) => {
                                  setJB6(event.target.value);
                                  setCb62(event.target.checked);
                                  setCb61(false);
                                  setCb63(false);
                                  setCb64(false);
                                  setCb65(false);
                                  setCb66(false);
                                }}
                              />
                              <label htmlFor="j62">
                                <img src={B6Q2} />
                              </label>
                            </div>
                            <div className={cb63 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j63"
                                name="6"
                                value={3}
                                onChange={(event) => {
                                  setJB6(event.target.value);
                                  setCb63(event.target.checked);
                                  setCb62(false);
                                  setCb61(false);
                                  setCb64(false);
                                  setCb65(false);
                                  setCb66(false);
                                }}
                              />
                              <label htmlFor="j63">
                                <img src={B6Q3} />
                              </label>
                            </div>
                            <div className={cb64 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j64"
                                name="6"
                                value={4}
                                onChange={(event) => {
                                  setJB6(event.target.value);
                                  setCb64(event.target.checked);
                                  setCb62(false);
                                  setCb63(false);
                                  setCb61(false);
                                  setCb65(false);
                                  setCb66(false);
                                }}
                              />
                              <label htmlFor="j64">
                                <img src={B6Q4} />
                              </label>
                            </div>
                            <div className={cb65 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j65"
                                name="6"
                                value={5}
                                onChange={(event) => {
                                  setJB6(event.target.value);
                                  setCb65(event.target.checked);
                                  setCb62(false);
                                  setCb63(false);
                                  setCb64(false);
                                  setCb61(false);
                                  setCb66(false);
                                }}
                              />
                              <label htmlFor="j65">
                                <img src={B6Q5} />
                              </label>
                            </div>
                            <div className={cb66 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j66"
                                name="6"
                                value={6}
                                onChange={(event) => {
                                  setJB6(event.target.value);
                                  setCb66(event.target.checked);
                                  setCb62(false);
                                  setCb63(false);
                                  setCb64(false);
                                  setCb65(false);
                                  setCb61(false);
                                }}
                              />
                              <label htmlFor="j66">
                                <img src={B6Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {nomor % 12 === 7 && (
                    <>
                      {typeNow === "A" && (
                        <>
                          <img src={A7} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={ca71 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j71"
                                name="7"
                                value={1}
                                onChange={(event) => {
                                  setJA7(event.target.value);
                                  setCa71(event.target.checked);
                                  setCa72(false);
                                  setCa73(false);
                                  setCa74(false);
                                  setCa75(false);
                                  setCa76(false);
                                }}
                              />
                              <label htmlFor="j71">
                                <img src={A7Q1} />
                              </label>
                            </div>
                            <div className={ca72 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j72"
                                name="7"
                                value={2}
                                onChange={(event) => {
                                  setJA7(event.target.value);
                                  setCa72(event.target.checked);
                                  setCa71(false);
                                  setCa73(false);
                                  setCa74(false);
                                  setCa75(false);
                                  setCa76(false);
                                }}
                              />
                              <label htmlFor="j72">
                                <img src={A7Q2} />
                              </label>
                            </div>
                            <div className={ca73 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j73"
                                name="7"
                                value={3}
                                onChange={(event) => {
                                  setJA7(event.target.value);
                                  setCa73(event.target.checked);
                                  setCa72(false);
                                  setCa71(false);
                                  setCa74(false);
                                  setCa75(false);
                                  setCa76(false);
                                }}
                              />
                              <label htmlFor="j73">
                                <img src={A7Q3} />
                              </label>
                            </div>
                            <div className={ca74 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j74"
                                name="7"
                                value={4}
                                onChange={(event) => {
                                  setJA7(event.target.value);
                                  setCa74(event.target.checked);
                                  setCa72(false);
                                  setCa73(false);
                                  setCa71(false);
                                  setCa75(false);
                                  setCa76(false);
                                }}
                              />
                              <label htmlFor="j74">
                                <img src={A7Q4} />
                              </label>
                            </div>
                            <div className={ca75 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j75"
                                name="7"
                                value={5}
                                onChange={(event) => {
                                  setJA7(event.target.value);
                                  setCa75(event.target.checked);
                                  setCa72(false);
                                  setCa73(false);
                                  setCa74(false);
                                  setCa71(false);
                                  setCa76(false);
                                }}
                              />
                              <label htmlFor="j75">
                                <img src={A7Q5} />
                              </label>
                            </div>
                            <div className={ca76 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j76"
                                name="7"
                                value={6}
                                onChange={(event) => {
                                  setJA7(event.target.value);
                                  setCa76(event.target.checked);
                                  setCa72(false);
                                  setCa73(false);
                                  setCa74(false);
                                  setCa75(false);
                                  setCa71(false);
                                }}
                              />
                              <label htmlFor="j76">
                                <img src={A7Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "B" && (
                        <>
                          <img src={B7} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={cb71 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j71"
                                name="7"
                                value={1}
                                onChange={(event) => {
                                  setJB7(event.target.value);
                                  setCb71(event.target.checked);
                                  setCb72(false);
                                  setCb73(false);
                                  setCb74(false);
                                  setCb75(false);
                                  setCb76(false);
                                }}
                              />
                              <label htmlFor="j71">
                                <img src={B7Q1} />
                              </label>
                            </div>
                            <div className={cb72 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j72"
                                name="7"
                                value={2}
                                onChange={(event) => {
                                  setJB7(event.target.value);
                                  setCb72(event.target.checked);
                                  setCb71(false);
                                  setCb73(false);
                                  setCb74(false);
                                  setCb75(false);
                                  setCb76(false);
                                }}
                              />
                              <label htmlFor="j72">
                                <img src={B7Q2} />
                              </label>
                            </div>
                            <div className={cb73 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j73"
                                name="7"
                                value={3}
                                onChange={(event) => {
                                  setJB7(event.target.value);
                                  setCb73(event.target.checked);
                                  setCb72(false);
                                  setCb71(false);
                                  setCb74(false);
                                  setCb75(false);
                                  setCb76(false);
                                }}
                              />
                              <label htmlFor="j73">
                                <img src={B7Q3} />
                              </label>
                            </div>
                            <div className={cb74 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j74"
                                name="7"
                                value={4}
                                onChange={(event) => {
                                  setJB7(event.target.value);
                                  setCb74(event.target.checked);
                                  setCb72(false);
                                  setCb73(false);
                                  setCb71(false);
                                  setCb75(false);
                                  setCb76(false);
                                }}
                              />
                              <label htmlFor="j74">
                                <img src={B7Q4} />
                              </label>
                            </div>
                            <div className={cb75 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j75"
                                name="7"
                                value={5}
                                onChange={(event) => {
                                  setJB7(event.target.value);
                                  setCb75(event.target.checked);
                                  setCb72(false);
                                  setCb73(false);
                                  setCb74(false);
                                  setCb71(false);
                                  setCb76(false);
                                }}
                              />
                              <label htmlFor="j75">
                                <img src={B7Q5} />
                              </label>
                            </div>
                            <div className={cb76 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j76"
                                name="7"
                                value={6}
                                onChange={(event) => {
                                  setJB7(event.target.value);
                                  setCb76(event.target.checked);
                                  setCb72(false);
                                  setCb73(false);
                                  setCb74(false);
                                  setCb75(false);
                                  setCb71(false);
                                }}
                              />
                              <label htmlFor="j76">
                                <img src={B7Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {nomor % 12 === 8 && (
                    <>
                      {typeNow === "A" && (
                        <>
                          <img src={A8} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={ca81 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j81"
                                name="8"
                                value={1}
                                onChange={(event) => {
                                  setJA8(event.target.value);
                                  setCa81(event.target.checked);
                                  setCa82(false);
                                  setCa83(false);
                                  setCa84(false);
                                  setCa85(false);
                                  setCa86(false);
                                }}
                              />
                              <label htmlFor="j81">
                                <img src={A8Q1} />
                              </label>
                            </div>
                            <div className={ca82 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j82"
                                name="8"
                                value={2}
                                onChange={(event) => {
                                  setJA8(event.target.value);
                                  setCa82(event.target.checked);
                                  setCa81(false);
                                  setCa83(false);
                                  setCa84(false);
                                  setCa85(false);
                                  setCa86(false);
                                }}
                              />
                              <label htmlFor="j82">
                                <img src={A8Q2} />
                              </label>
                            </div>
                            <div className={ca83 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j83"
                                name="8"
                                value={3}
                                onChange={(event) => {
                                  setJA8(event.target.value);
                                  setCa83(event.target.checked);
                                  setCa82(false);
                                  setCa81(false);
                                  setCa84(false);
                                  setCa85(false);
                                  setCa86(false);
                                }}
                              />
                              <label htmlFor="j83">
                                <img src={A8Q3} />
                              </label>
                            </div>
                            <div className={ca84 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j84"
                                name="8"
                                value={4}
                                onChange={(event) => {
                                  setJA8(event.target.value);
                                  setCa84(event.target.checked);
                                  setCa82(false);
                                  setCa83(false);
                                  setCa81(false);
                                  setCa85(false);
                                  setCa86(false);
                                }}
                              />
                              <label htmlFor="j84">
                                <img src={A8Q4} />
                              </label>
                            </div>
                            <div className={ca85 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j85"
                                name="8"
                                value={5}
                                onChange={(event) => {
                                  setJA8(event.target.value);
                                  setCa85(event.target.checked);
                                  setCa82(false);
                                  setCa83(false);
                                  setCa84(false);
                                  setCa81(false);
                                  setCa86(false);
                                }}
                              />
                              <label htmlFor="j85">
                                <img src={A8Q5} />
                              </label>
                            </div>
                            <div className={ca86 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j86"
                                name="8"
                                value={6}
                                onChange={(event) => {
                                  setJA8(event.target.value);
                                  setCa86(event.target.checked);
                                  setCa82(false);
                                  setCa83(false);
                                  setCa84(false);
                                  setCa85(false);
                                  setCa81(false);
                                }}
                              />
                              <label htmlFor="j86">
                                <img src={A8Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "B" && (
                        <>
                          <img src={B8} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={cb81 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j81"
                                name="8"
                                value={1}
                                onChange={(event) => {
                                  setJB8(event.target.value);
                                  setCb81(event.target.checked);
                                  setCb82(false);
                                  setCb83(false);
                                  setCb84(false);
                                  setCb85(false);
                                  setCb86(false);
                                }}
                              />
                              <label htmlFor="j81">
                                <img src={B8Q1} />
                              </label>
                            </div>
                            <div className={cb82 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j82"
                                name="8"
                                value={2}
                                onChange={(event) => {
                                  setJB8(event.target.value);
                                  setCb82(event.target.checked);
                                  setCb81(false);
                                  setCb83(false);
                                  setCb84(false);
                                  setCb85(false);
                                  setCb86(false);
                                }}
                              />
                              <label htmlFor="j82">
                                <img src={B8Q2} />
                              </label>
                            </div>
                            <div className={cb83 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j83"
                                name="8"
                                value={3}
                                onChange={(event) => {
                                  setJB8(event.target.value);
                                  setCb83(event.target.checked);
                                  setCb82(false);
                                  setCb81(false);
                                  setCb84(false);
                                  setCb85(false);
                                  setCb86(false);
                                }}
                              />
                              <label htmlFor="j83">
                                <img src={B8Q3} />
                              </label>
                            </div>
                            <div className={cb84 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j84"
                                name="8"
                                value={4}
                                onChange={(event) => {
                                  setJB8(event.target.value);
                                  setCb84(event.target.checked);
                                  setCb82(false);
                                  setCb83(false);
                                  setCb81(false);
                                  setCb85(false);
                                  setCb86(false);
                                }}
                              />
                              <label htmlFor="j84">
                                <img src={B8Q4} />
                              </label>
                            </div>
                            <div className={cb85 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j85"
                                name="8"
                                value={5}
                                onChange={(event) => {
                                  setJB8(event.target.value);
                                  setCb85(event.target.checked);
                                  setCb82(false);
                                  setCb83(false);
                                  setCb84(false);
                                  setCb81(false);
                                  setCb86(false);
                                }}
                              />
                              <label htmlFor="j85">
                                <img src={B8Q5} />
                              </label>
                            </div>
                            <div className={cb86 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j86"
                                name="8"
                                value={6}
                                onChange={(event) => {
                                  setJB8(event.target.value);
                                  setCb86(event.target.checked);
                                  setCb82(false);
                                  setCb83(false);
                                  setCb84(false);
                                  setCb85(false);
                                  setCb81(false);
                                }}
                              />
                              <label htmlFor="j86">
                                <img src={B8Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {nomor % 12 === 9 && (
                    <>
                      {typeNow === "A" && (
                        <>
                          <img src={A9} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={ca91 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j91"
                                name="9"
                                value={1}
                                onChange={(event) => {
                                  setJA9(event.target.value);
                                  setCa91(event.target.checked);
                                  setCa92(false);
                                  setCa93(false);
                                  setCa94(false);
                                  setCa95(false);
                                  setCa96(false);
                                }}
                              />
                              <label htmlFor="j91">
                                <img src={A9Q1} />
                              </label>
                            </div>
                            <div className={ca92 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j92"
                                name="9"
                                value={2}
                                onChange={(event) => {
                                  setJA9(event.target.value);
                                  setCa92(event.target.checked);
                                  setCa91(false);
                                  setCa93(false);
                                  setCa94(false);
                                  setCa95(false);
                                  setCa96(false);
                                }}
                              />
                              <label htmlFor="j92">
                                <img src={A9Q2} />
                              </label>
                            </div>
                            <div className={ca93 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j93"
                                name="9"
                                value={3}
                                onChange={(event) => {
                                  setJA9(event.target.value);
                                  setCa93(event.target.checked);
                                  setCa92(false);
                                  setCa91(false);
                                  setCa94(false);
                                  setCa95(false);
                                  setCa96(false);
                                }}
                              />
                              <label htmlFor="j93">
                                <img src={A9Q3} />
                              </label>
                            </div>
                            <div className={ca94 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j94"
                                name="9"
                                value={4}
                                onChange={(event) => {
                                  setJA9(event.target.value);
                                  setCa94(event.target.checked);
                                  setCa92(false);
                                  setCa93(false);
                                  setCa91(false);
                                  setCa95(false);
                                  setCa96(false);
                                }}
                              />
                              <label htmlFor="j94">
                                <img src={A9Q4} />
                              </label>
                            </div>
                            <div className={ca95 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j95"
                                name="9"
                                value={5}
                                onChange={(event) => {
                                  setJA9(event.target.value);
                                  setCa95(event.target.checked);
                                  setCa92(false);
                                  setCa93(false);
                                  setCa94(false);
                                  setCa91(false);
                                  setCa96(false);
                                }}
                              />
                              <label htmlFor="j95">
                                <img src={A9Q5} />
                              </label>
                            </div>
                            <div className={ca96 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j96"
                                name="9"
                                value={6}
                                onChange={(event) => {
                                  setJA9(event.target.value);
                                  setCa96(event.target.checked);
                                  setCa92(false);
                                  setCa93(false);
                                  setCa94(false);
                                  setCa95(false);
                                  setCa91(false);
                                }}
                              />
                              <label htmlFor="j96">
                                <img src={A9Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      {typeNow === "B" && (
                        <>
                          <img src={B9} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={cb91 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j91"
                                name="9"
                                value={1}
                                onChange={(event) => {
                                  setJB9(event.target.value);
                                  setCb91(event.target.checked);
                                  setCb92(false);
                                  setCb93(false);
                                  setCb94(false);
                                  setCb95(false);
                                  setCb96(false);
                                }}
                              />
                              <label htmlFor="j91">
                                <img src={B9Q1} />
                              </label>
                            </div>
                            <div className={cb92 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j92"
                                name="9"
                                value={2}
                                onChange={(event) => {
                                  setJB9(event.target.value);
                                  setCb92(event.target.checked);
                                  setCb91(false);
                                  setCb93(false);
                                  setCb94(false);
                                  setCb95(false);
                                  setCb96(false);
                                }}
                              />
                              <label htmlFor="j92">
                                <img src={B9Q2} />
                              </label>
                            </div>
                            <div className={cb93 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j93"
                                name="9"
                                value={3}
                                onChange={(event) => {
                                  setJB9(event.target.value);
                                  setCb93(event.target.checked);
                                  setCb92(false);
                                  setCb91(false);
                                  setCb94(false);
                                  setCb95(false);
                                  setCb96(false);
                                }}
                              />
                              <label htmlFor="j93">
                                <img src={B9Q3} />
                              </label>
                            </div>
                            <div className={cb94 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j94"
                                name="9"
                                value={4}
                                onChange={(event) => {
                                  setJB9(event.target.value);
                                  setCb94(event.target.checked);
                                  setCb92(false);
                                  setCb93(false);
                                  setCb91(false);
                                  setCb95(false);
                                  setCb96(false);
                                }}
                              />
                              <label htmlFor="j94">
                                <img src={B9Q4} />
                              </label>
                            </div>
                            <div className={cb95 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j95"
                                name="9"
                                value={5}
                                onChange={(event) => {
                                  setJB9(event.target.value);
                                  setCb95(event.target.checked);
                                  setCb92(false);
                                  setCb93(false);
                                  setCb94(false);
                                  setCb91(false);
                                  setCb96(false);
                                }}
                              />
                              <label htmlFor="j95">
                                <img src={B9Q5} />
                              </label>
                            </div>
                            <div className={cb96 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j96"
                                name="9"
                                value={6}
                                onChange={(event) => {
                                  setJB9(event.target.value);
                                  setCb96(event.target.checked);
                                  setCb92(false);
                                  setCb93(false);
                                  setCb94(false);
                                  setCb95(false);
                                  setCb91(false);
                                }}
                              />
                              <label htmlFor="j96">
                                <img src={B9Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {nomor % 12 === 10 && (
                    <>
                      {typeNow === "A" && (
                        <>
                          <img src={A10} className="fotoSoal" />

                          <div className="listJawaban">
                            <div
                              className={ca101 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j101"
                                name="10"
                                value={1}
                                onChange={(event) => {
                                  setJA10(event.target.value);
                                  setCa101(event.target.checked);
                                  setCa102(false);
                                  setCa103(false);
                                  setCa104(false);
                                  setCa105(false);
                                  setCa106(false);
                                }}
                              />
                              <label htmlFor="j101">
                                <img src={A10Q1} />
                              </label>
                            </div>
                            <div
                              className={ca102 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j102"
                                name="10"
                                value={2}
                                onChange={(event) => {
                                  setJA10(event.target.value);
                                  setCa102(event.target.checked);
                                  setCa101(false);
                                  setCa103(false);
                                  setCa104(false);
                                  setCa105(false);
                                  setCa106(false);
                                }}
                              />
                              <label htmlFor="j102">
                                <img src={A10Q2} />
                              </label>
                            </div>
                            <div
                              className={ca103 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j103"
                                name="10"
                                value={3}
                                onChange={(event) => {
                                  setJA10(event.target.value);
                                  setCa103(event.target.checked);
                                  setCa102(false);
                                  setCa101(false);
                                  setCa104(false);
                                  setCa105(false);
                                  setCa106(false);
                                }}
                              />
                              <label htmlFor="j103">
                                <img src={A10Q3} />
                              </label>
                            </div>
                            <div
                              className={ca104 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j104"
                                name="10"
                                value={4}
                                onChange={(event) => {
                                  setJA10(event.target.value);
                                  setCa104(event.target.checked);
                                  setCa102(false);
                                  setCa103(false);
                                  setCa101(false);
                                  setCa105(false);
                                  setCa106(false);
                                }}
                              />
                              <label htmlFor="j104">
                                <img src={A10Q4} />
                              </label>
                            </div>
                            <div
                              className={ca105 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j105"
                                name="10"
                                value={5}
                                onChange={(event) => {
                                  setJA10(event.target.value);
                                  setCa105(event.target.checked);
                                  setCa102(false);
                                  setCa103(false);
                                  setCa104(false);
                                  setCa101(false);
                                  setCa106(false);
                                }}
                              />
                              <label htmlFor="j105">
                                <img src={A10Q5} />
                              </label>
                            </div>
                            <div
                              className={ca106 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j106"
                                name="10"
                                value={6}
                                onChange={(event) => {
                                  setJA10(event.target.value);
                                  setCa106(event.target.checked);
                                  setCa102(false);
                                  setCa103(false);
                                  setCa104(false);
                                  setCa105(false);
                                  setCa101(false);
                                }}
                              />
                              <label htmlFor="j106">
                                <img src={A10Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      {typeNow === "B" && (
                        <>
                          <img src={B10} className="fotoSoal" />

                          <div className="listJawaban">
                            <div
                              className={cb101 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j101"
                                name="10"
                                value={1}
                                onChange={(event) => {
                                  setJB10(event.target.value);
                                  setCb101(event.target.checked);
                                  setCb102(false);
                                  setCb103(false);
                                  setCb104(false);
                                  setCb105(false);
                                  setCb106(false);
                                }}
                              />
                              <label htmlFor="j101">
                                <img src={B10Q1} />
                              </label>
                            </div>
                            <div
                              className={cb102 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j102"
                                name="10"
                                value={2}
                                onChange={(event) => {
                                  setJB10(event.target.value);
                                  setCb102(event.target.checked);
                                  setCb101(false);
                                  setCb103(false);
                                  setCb104(false);
                                  setCb105(false);
                                  setCb106(false);
                                }}
                              />
                              <label htmlFor="j102">
                                <img src={B10Q2} />
                              </label>
                            </div>
                            <div
                              className={cb103 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j103"
                                name="10"
                                value={3}
                                onChange={(event) => {
                                  setJB10(event.target.value);
                                  setCb103(event.target.checked);
                                  setCb102(false);
                                  setCb101(false);
                                  setCb104(false);
                                  setCb105(false);
                                  setCb106(false);
                                }}
                              />
                              <label htmlFor="j103">
                                <img src={B10Q3} />
                              </label>
                            </div>
                            <div
                              className={cb104 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j104"
                                name="10"
                                value={4}
                                onChange={(event) => {
                                  setJB10(event.target.value);
                                  setCb104(event.target.checked);
                                  setCb102(false);
                                  setCb103(false);
                                  setCb101(false);
                                  setCb105(false);
                                  setCb106(false);
                                }}
                              />
                              <label htmlFor="j104">
                                <img src={B10Q4} />
                              </label>
                            </div>
                            <div
                              className={cb105 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j105"
                                name="10"
                                value={5}
                                onChange={(event) => {
                                  setJB10(event.target.value);
                                  setCb105(event.target.checked);
                                  setCb102(false);
                                  setCb103(false);
                                  setCb104(false);
                                  setCb101(false);
                                  setCb106(false);
                                }}
                              />
                              <label htmlFor="j105">
                                <img src={B10Q5} />
                              </label>
                            </div>
                            <div
                              className={cb106 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j106"
                                name="10"
                                value={6}
                                onChange={(event) => {
                                  setJB10(event.target.value);
                                  setCb106(event.target.checked);
                                  setCb102(false);
                                  setCb103(false);
                                  setCb104(false);
                                  setCb105(false);
                                  setCb101(false);
                                }}
                              />
                              <label htmlFor="j106">
                                <img src={B10Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {nomor % 12 === 11 && (
                    <>
                      {typeNow === "A" && (
                        <>
                          <img src={A11} className="fotoSoal" />

                          <div className="listJawaban">
                            <div
                              className={ca111 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j111"
                                name="11"
                                value={1}
                                onChange={(event) => {
                                  setJA11(event.target.value);
                                  setCa111(event.target.checked);
                                  setCa112(false);
                                  setCa113(false);
                                  setCa114(false);
                                  setCa115(false);
                                  setCa116(false);
                                }}
                              />
                              <label htmlFor="j111">
                                <img src={A11Q1} />
                              </label>
                            </div>
                            <div
                              className={ca112 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j112"
                                name="11"
                                value={2}
                                onChange={(event) => {
                                  setJA11(event.target.value);
                                  setCa112(event.target.checked);
                                  setCa111(false);
                                  setCa113(false);
                                  setCa114(false);
                                  setCa115(false);
                                  setCa116(false);
                                }}
                              />
                              <label htmlFor="j112">
                                <img src={A11Q2} />
                              </label>
                            </div>
                            <div
                              className={ca113 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j113"
                                name="11"
                                value={3}
                                onChange={(event) => {
                                  setJA11(event.target.value);
                                  setCa113(event.target.checked);
                                  setCa112(false);
                                  setCa111(false);
                                  setCa114(false);
                                  setCa115(false);
                                  setCa116(false);
                                }}
                              />
                              <label htmlFor="j113">
                                <img src={A11Q3} />
                              </label>
                            </div>
                            <div
                              className={ca114 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j114"
                                name="11"
                                value={4}
                                onChange={(event) => {
                                  setJA11(event.target.value);
                                  setCa114(event.target.checked);
                                  setCa112(false);
                                  setCa113(false);
                                  setCa111(false);
                                  setCa115(false);
                                  setCa116(false);
                                }}
                              />
                              <label htmlFor="j114">
                                <img src={A11Q4} />
                              </label>
                            </div>
                            <div
                              className={ca115 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j115"
                                name="11"
                                value={5}
                                onChange={(event) => {
                                  setJA11(event.target.value);
                                  setCa115(event.target.checked);
                                  setCa112(false);
                                  setCa113(false);
                                  setCa114(false);
                                  setCa111(false);
                                  setCa116(false);
                                }}
                              />
                              <label htmlFor="j115">
                                <img src={A11Q5} />
                              </label>
                            </div>
                            <div
                              className={ca116 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j116"
                                name="11"
                                value={6}
                                onChange={(event) => {
                                  setJA11(event.target.value);
                                  setCa116(event.target.checked);
                                  setCa112(false);
                                  setCa113(false);
                                  setCa114(false);
                                  setCa115(false);
                                  setCa111(false);
                                }}
                              />
                              <label htmlFor="j116">
                                <img src={A11Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      {typeNow === "B" && (
                        <>
                          <img src={B11} className="fotoSoal" />

                          <div className="listJawaban">
                            <div
                              className={cb111 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j111"
                                name="11"
                                value={1}
                                onChange={(event) => {
                                  setJB11(event.target.value);
                                  setCb111(event.target.checked);
                                  setCb112(false);
                                  setCb113(false);
                                  setCb114(false);
                                  setCb115(false);
                                  setCb116(false);
                                }}
                              />
                              <label htmlFor="j111">
                                <img src={B11Q1} />
                              </label>
                            </div>
                            <div
                              className={cb112 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j112"
                                name="11"
                                value={2}
                                onChange={(event) => {
                                  setJB11(event.target.value);
                                  setCb112(event.target.checked);
                                  setCb111(false);
                                  setCb113(false);
                                  setCb114(false);
                                  setCb115(false);
                                  setCb116(false);
                                }}
                              />
                              <label htmlFor="j112">
                                <img src={B11Q2} />
                              </label>
                            </div>
                            <div
                              className={cb113 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j113"
                                name="11"
                                value={3}
                                onChange={(event) => {
                                  setJB11(event.target.value);
                                  setCb113(event.target.checked);
                                  setCb112(false);
                                  setCb111(false);
                                  setCb114(false);
                                  setCb115(false);
                                  setCb116(false);
                                }}
                              />
                              <label htmlFor="j113">
                                <img src={B11Q3} />
                              </label>
                            </div>
                            <div
                              className={cb114 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j114"
                                name="11"
                                value={4}
                                onChange={(event) => {
                                  setJB11(event.target.value);
                                  setCb114(event.target.checked);
                                  setCb112(false);
                                  setCb113(false);
                                  setCb111(false);
                                  setCb115(false);
                                  setCb116(false);
                                }}
                              />
                              <label htmlFor="j114">
                                <img src={B11Q4} />
                              </label>
                            </div>
                            <div
                              className={cb115 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j115"
                                name="11"
                                value={5}
                                onChange={(event) => {
                                  setJB11(event.target.value);
                                  setCb115(event.target.checked);
                                  setCb112(false);
                                  setCb113(false);
                                  setCb114(false);
                                  setCb111(false);
                                  setCb116(false);
                                }}
                              />
                              <label htmlFor="j115">
                                <img src={B11Q5} />
                              </label>
                            </div>
                            <div
                              className={cb116 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j116"
                                name="11"
                                value={6}
                                onChange={(event) => {
                                  setJB11(event.target.value);
                                  setCb116(event.target.checked);
                                  setCb112(false);
                                  setCb113(false);
                                  setCb114(false);
                                  setCb115(false);
                                  setCb111(false);
                                }}
                              />
                              <label htmlFor="j116">
                                <img src={B11Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {nomor % 12 === 0 && (
                    <>
                      {typeNow === "A" && (
                        <>
                          <img src={A12} className="fotoSoal" />

                          <div className="listJawaban">
                            <div
                              className={ca121 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j121"
                                name="12"
                                value={1}
                                onChange={(event) => {
                                  setJA12(event.target.value);
                                  setCa121(event.target.checked);
                                  setCa122(false);
                                  setCa123(false);
                                  setCa124(false);
                                  setCa125(false);
                                  setCa126(false);
                                }}
                              />
                              <label htmlFor="j121">
                                <img src={A12Q1} />
                              </label>
                            </div>
                            <div
                              className={ca122 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j122"
                                name="12"
                                value={2}
                                onChange={(event) => {
                                  setJA12(event.target.value);
                                  setCa122(event.target.checked);
                                  setCa121(false);
                                  setCa123(false);
                                  setCa124(false);
                                  setCa125(false);
                                  setCa126(false);
                                }}
                              />
                              <label htmlFor="j122">
                                <img src={A12Q2} />
                              </label>
                            </div>
                            <div
                              className={ca123 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j123"
                                name="12"
                                value={3}
                                onChange={(event) => {
                                  setJA12(event.target.value);
                                  setCa123(event.target.checked);
                                  setCa122(false);
                                  setCa121(false);
                                  setCa124(false);
                                  setCa125(false);
                                  setCa126(false);
                                }}
                              />
                              <label htmlFor="j123">
                                <img src={A12Q3} />
                              </label>
                            </div>
                            <div
                              className={ca124 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j124"
                                name="12"
                                value={4}
                                onChange={(event) => {
                                  setJA12(event.target.value);
                                  setCa124(event.target.checked);
                                  setCa122(false);
                                  setCa123(false);
                                  setCa121(false);
                                  setCa125(false);
                                  setCa126(false);
                                }}
                              />
                              <label htmlFor="j124">
                                <img src={A12Q4} />
                              </label>
                            </div>
                            <div
                              className={ca125 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j125"
                                name="12"
                                value={5}
                                onChange={(event) => {
                                  setJA12(event.target.value);
                                  setCa125(event.target.checked);
                                  setCa122(false);
                                  setCa123(false);
                                  setCa124(false);
                                  setCa121(false);
                                  setCa126(false);
                                }}
                              />
                              <label htmlFor="j125">
                                <img src={A12Q5} />
                              </label>
                            </div>
                            <div
                              className={ca126 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j126"
                                name="12"
                                value={6}
                                onChange={(event) => {
                                  setJA12(event.target.value);
                                  setCa126(event.target.checked);
                                  setCa122(false);
                                  setCa123(false);
                                  setCa124(false);
                                  setCa125(false);
                                  setCa121(false);
                                }}
                              />
                              <label htmlFor="j126">
                                <img src={A12Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      {typeNow === "B" && (
                        <>
                          <img src={B12} className="fotoSoal" />

                          <div className="listJawaban">
                            <div
                              className={cb121 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j121"
                                name="12"
                                value={1}
                                onChange={(event) => {
                                  setJB12(event.target.value);
                                  setCb121(event.target.checked);
                                  setCb122(false);
                                  setCb123(false);
                                  setCb124(false);
                                  setCb125(false);
                                  setCb126(false);
                                }}
                              />
                              <label htmlFor="j121">
                                <img src={B12Q1} />
                              </label>
                            </div>
                            <div
                              className={cb122 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j122"
                                name="12"
                                value={2}
                                onChange={(event) => {
                                  setJB12(event.target.value);
                                  setCb122(event.target.checked);
                                  setCb121(false);
                                  setCb123(false);
                                  setCb124(false);
                                  setCb125(false);
                                  setCb126(false);
                                }}
                              />
                              <label htmlFor="j122">
                                <img src={B12Q2} />
                              </label>
                            </div>
                            <div
                              className={cb123 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j123"
                                name="12"
                                value={3}
                                onChange={(event) => {
                                  setJB12(event.target.value);
                                  setCb123(event.target.checked);
                                  setCb122(false);
                                  setCb121(false);
                                  setCb124(false);
                                  setCb125(false);
                                  setCb126(false);
                                }}
                              />
                              <label htmlFor="j123">
                                <img src={B12Q3} />
                              </label>
                            </div>
                            <div
                              className={cb124 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j124"
                                name="12"
                                value={4}
                                onChange={(event) => {
                                  setJB12(event.target.value);
                                  setCb124(event.target.checked);
                                  setCb122(false);
                                  setCb123(false);
                                  setCb121(false);
                                  setCb125(false);
                                  setCb126(false);
                                }}
                              />
                              <label htmlFor="j124">
                                <img src={B12Q4} />
                              </label>
                            </div>
                            <div
                              className={cb125 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j125"
                                name="12"
                                value={5}
                                onChange={(event) => {
                                  setJB12(event.target.value);
                                  setCb125(event.target.checked);
                                  setCb122(false);
                                  setCb123(false);
                                  setCb124(false);
                                  setCb121(false);
                                  setCb126(false);
                                }}
                              />
                              <label htmlFor="j125">
                                <img src={B12Q5} />
                              </label>
                            </div>
                            <div
                              className={cb126 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j126"
                                name="12"
                                value={6}
                                onChange={(event) => {
                                  setJB12(event.target.value);
                                  setCb126(event.target.checked);
                                  setCb122(false);
                                  setCb123(false);
                                  setCb124(false);
                                  setCb125(false);
                                  setCb121(false);
                                }}
                              />
                              <label htmlFor="j126">
                                <img src={B12Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  <div className="bottom">
                    <div className="grup">
                      {nomor % 12 == 1 ? (
                        <>
                          {typeNow === "A" && (
                            <>
                              <Link
                                to="#"
                                className={"buttonTest bola " + sembunyi}
                                disabled={nomor == 1 ? true : false}
                                onClick={() => {
                                  setNomor(nomor - 1);
                                  setTypeNow("A");
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M10.295 19.7155C10.3886 19.8077 10.4994 19.8806 10.6211 19.93C10.7429 19.9793 10.8731 20.0042 11.0045 20.0033C11.1358 20.0023 11.2657 19.9754 11.3867 19.9243C11.5076 19.8731 11.6173 19.7986 11.7095 19.705C11.8017 19.6115 11.8746 19.5007 11.9239 19.379C11.9733 19.2572 11.9982 19.127 11.9972 18.9956C11.9963 18.8643 11.9694 18.7344 11.9183 18.6134C11.8671 18.4925 11.7926 18.3827 11.699 18.2906L6.32903 13.0006H19.999C20.2642 13.0006 20.5186 12.8952 20.7061 12.7077C20.8937 12.5201 20.999 12.2658 20.999 12.0006C20.999 11.7353 20.8937 11.481 20.7061 11.2934C20.5186 11.1059 20.2642 11.0006 19.999 11.0006H6.33603L11.7 5.71355C11.884 5.52633 11.987 5.2743 11.9867 5.01183C11.9865 4.74936 11.8831 4.49751 11.6988 4.31061C11.5145 4.12371 11.2642 4.01675 11.0017 4.01281C10.7393 4.00887 10.4858 4.10827 10.296 4.28955L3.37203 11.1116C3.25411 11.2278 3.16048 11.3664 3.09657 11.5192C3.03267 11.672 2.99976 11.8359 2.99976 12.0016C2.99976 12.1672 3.03267 12.3311 3.09657 12.4839C3.16048 12.6367 3.25411 12.7753 3.37203 12.8916L10.296 19.7146L10.295 19.7155Z"
                                    fill="#FCFCFC"
                                  />
                                </svg>{" "}
                                <span className="disapearText">Sebelumnya</span>
                              </Link>
                            </>
                          )}
                          {typeNow === "B" && (
                            <>
                              <Link
                                to="#"
                                className={"buttonTest bola " + sembunyi}
                                disabled={nomor == 1 ? true : false}
                                onClick={() => {
                                  setNomor(nomor - 1);
                                  setTypeNow("A");
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M10.295 19.7155C10.3886 19.8077 10.4994 19.8806 10.6211 19.93C10.7429 19.9793 10.8731 20.0042 11.0045 20.0033C11.1358 20.0023 11.2657 19.9754 11.3867 19.9243C11.5076 19.8731 11.6173 19.7986 11.7095 19.705C11.8017 19.6115 11.8746 19.5007 11.9239 19.379C11.9733 19.2572 11.9982 19.127 11.9972 18.9956C11.9963 18.8643 11.9694 18.7344 11.9183 18.6134C11.8671 18.4925 11.7926 18.3827 11.699 18.2906L6.32903 13.0006H19.999C20.2642 13.0006 20.5186 12.8952 20.7061 12.7077C20.8937 12.5201 20.999 12.2658 20.999 12.0006C20.999 11.7353 20.8937 11.481 20.7061 11.2934C20.5186 11.1059 20.2642 11.0006 19.999 11.0006H6.33603L11.7 5.71355C11.884 5.52633 11.987 5.2743 11.9867 5.01183C11.9865 4.74936 11.8831 4.49751 11.6988 4.31061C11.5145 4.12371 11.2642 4.01675 11.0017 4.01281C10.7393 4.00887 10.4858 4.10827 10.296 4.28955L3.37203 11.1116C3.25411 11.2278 3.16048 11.3664 3.09657 11.5192C3.03267 11.672 2.99976 11.8359 2.99976 12.0016C2.99976 12.1672 3.03267 12.3311 3.09657 12.4839C3.16048 12.6367 3.25411 12.7753 3.37203 12.8916L10.296 19.7146L10.295 19.7155Z"
                                    fill="#FCFCFC"
                                  />
                                </svg>{" "}
                                <span className="disapearText">Sebelumnya</span>
                              </Link>
                            </>
                          )}
                          {typeNow === "C" && (
                            <>
                              <Link
                                to="#"
                                className={"buttonTest bola " + sembunyi}
                                disabled={nomor == 1 ? true : false}
                                onClick={() => {
                                  setNomor(nomor - 1);
                                  setTypeNow("B");
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M10.295 19.7155C10.3886 19.8077 10.4994 19.8806 10.6211 19.93C10.7429 19.9793 10.8731 20.0042 11.0045 20.0033C11.1358 20.0023 11.2657 19.9754 11.3867 19.9243C11.5076 19.8731 11.6173 19.7986 11.7095 19.705C11.8017 19.6115 11.8746 19.5007 11.9239 19.379C11.9733 19.2572 11.9982 19.127 11.9972 18.9956C11.9963 18.8643 11.9694 18.7344 11.9183 18.6134C11.8671 18.4925 11.7926 18.3827 11.699 18.2906L6.32903 13.0006H19.999C20.2642 13.0006 20.5186 12.8952 20.7061 12.7077C20.8937 12.5201 20.999 12.2658 20.999 12.0006C20.999 11.7353 20.8937 11.481 20.7061 11.2934C20.5186 11.1059 20.2642 11.0006 19.999 11.0006H6.33603L11.7 5.71355C11.884 5.52633 11.987 5.2743 11.9867 5.01183C11.9865 4.74936 11.8831 4.49751 11.6988 4.31061C11.5145 4.12371 11.2642 4.01675 11.0017 4.01281C10.7393 4.00887 10.4858 4.10827 10.296 4.28955L3.37203 11.1116C3.25411 11.2278 3.16048 11.3664 3.09657 11.5192C3.03267 11.672 2.99976 11.8359 2.99976 12.0016C2.99976 12.1672 3.03267 12.3311 3.09657 12.4839C3.16048 12.6367 3.25411 12.7753 3.37203 12.8916L10.296 19.7146L10.295 19.7155Z"
                                    fill="#FCFCFC"
                                  />
                                </svg>{" "}
                                <span className="disapearText">Sebelumnya</span>
                              </Link>
                            </>
                          )}
                          {typeNow === "D" && (
                            <>
                              <Link
                                to="#"
                                className={"buttonTest bola " + sembunyi}
                                disabled={nomor == 1 ? true : false}
                                onClick={() => {
                                  setNomor(nomor - 1);
                                  setTypeNow("C");
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M10.295 19.7155C10.3886 19.8077 10.4994 19.8806 10.6211 19.93C10.7429 19.9793 10.8731 20.0042 11.0045 20.0033C11.1358 20.0023 11.2657 19.9754 11.3867 19.9243C11.5076 19.8731 11.6173 19.7986 11.7095 19.705C11.8017 19.6115 11.8746 19.5007 11.9239 19.379C11.9733 19.2572 11.9982 19.127 11.9972 18.9956C11.9963 18.8643 11.9694 18.7344 11.9183 18.6134C11.8671 18.4925 11.7926 18.3827 11.699 18.2906L6.32903 13.0006H19.999C20.2642 13.0006 20.5186 12.8952 20.7061 12.7077C20.8937 12.5201 20.999 12.2658 20.999 12.0006C20.999 11.7353 20.8937 11.481 20.7061 11.2934C20.5186 11.1059 20.2642 11.0006 19.999 11.0006H6.33603L11.7 5.71355C11.884 5.52633 11.987 5.2743 11.9867 5.01183C11.9865 4.74936 11.8831 4.49751 11.6988 4.31061C11.5145 4.12371 11.2642 4.01675 11.0017 4.01281C10.7393 4.00887 10.4858 4.10827 10.296 4.28955L3.37203 11.1116C3.25411 11.2278 3.16048 11.3664 3.09657 11.5192C3.03267 11.672 2.99976 11.8359 2.99976 12.0016C2.99976 12.1672 3.03267 12.3311 3.09657 12.4839C3.16048 12.6367 3.25411 12.7753 3.37203 12.8916L10.296 19.7146L10.295 19.7155Z"
                                    fill="#FCFCFC"
                                  />
                                </svg>{" "}
                                <span className="disapearText">Sebelumnya</span>
                              </Link>
                            </>
                          )}
                          {typeNow === "E" && (
                            <>
                              <Link
                                to="#"
                                className={"buttonTest bola " + sembunyi}
                                disabled={nomor == 1 ? true : false}
                                onClick={() => {
                                  setNomor(nomor - 1);
                                  setTypeNow("D");
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M10.295 19.7155C10.3886 19.8077 10.4994 19.8806 10.6211 19.93C10.7429 19.9793 10.8731 20.0042 11.0045 20.0033C11.1358 20.0023 11.2657 19.9754 11.3867 19.9243C11.5076 19.8731 11.6173 19.7986 11.7095 19.705C11.8017 19.6115 11.8746 19.5007 11.9239 19.379C11.9733 19.2572 11.9982 19.127 11.9972 18.9956C11.9963 18.8643 11.9694 18.7344 11.9183 18.6134C11.8671 18.4925 11.7926 18.3827 11.699 18.2906L6.32903 13.0006H19.999C20.2642 13.0006 20.5186 12.8952 20.7061 12.7077C20.8937 12.5201 20.999 12.2658 20.999 12.0006C20.999 11.7353 20.8937 11.481 20.7061 11.2934C20.5186 11.1059 20.2642 11.0006 19.999 11.0006H6.33603L11.7 5.71355C11.884 5.52633 11.987 5.2743 11.9867 5.01183C11.9865 4.74936 11.8831 4.49751 11.6988 4.31061C11.5145 4.12371 11.2642 4.01675 11.0017 4.01281C10.7393 4.00887 10.4858 4.10827 10.296 4.28955L3.37203 11.1116C3.25411 11.2278 3.16048 11.3664 3.09657 11.5192C3.03267 11.672 2.99976 11.8359 2.99976 12.0016C2.99976 12.1672 3.03267 12.3311 3.09657 12.4839C3.16048 12.6367 3.25411 12.7753 3.37203 12.8916L10.296 19.7146L10.295 19.7155Z"
                                    fill="#FCFCFC"
                                  />
                                </svg>{" "}
                                <span className="disapearText">Sebelumnya</span>
                              </Link>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Link
                            to="#"
                            className={"buttonTest bola " + sembunyi}
                            disabled={nomor == 1 ? true : false}
                            onClick={() => {
                              setNomor(nomor - 1);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M10.295 19.7155C10.3886 19.8077 10.4994 19.8806 10.6211 19.93C10.7429 19.9793 10.8731 20.0042 11.0045 20.0033C11.1358 20.0023 11.2657 19.9754 11.3867 19.9243C11.5076 19.8731 11.6173 19.7986 11.7095 19.705C11.8017 19.6115 11.8746 19.5007 11.9239 19.379C11.9733 19.2572 11.9982 19.127 11.9972 18.9956C11.9963 18.8643 11.9694 18.7344 11.9183 18.6134C11.8671 18.4925 11.7926 18.3827 11.699 18.2906L6.32903 13.0006H19.999C20.2642 13.0006 20.5186 12.8952 20.7061 12.7077C20.8937 12.5201 20.999 12.2658 20.999 12.0006C20.999 11.7353 20.8937 11.481 20.7061 11.2934C20.5186 11.1059 20.2642 11.0006 19.999 11.0006H6.33603L11.7 5.71355C11.884 5.52633 11.987 5.2743 11.9867 5.01183C11.9865 4.74936 11.8831 4.49751 11.6988 4.31061C11.5145 4.12371 11.2642 4.01675 11.0017 4.01281C10.7393 4.00887 10.4858 4.10827 10.296 4.28955L3.37203 11.1116C3.25411 11.2278 3.16048 11.3664 3.09657 11.5192C3.03267 11.672 2.99976 11.8359 2.99976 12.0016C2.99976 12.1672 3.03267 12.3311 3.09657 12.4839C3.16048 12.6367 3.25411 12.7753 3.37203 12.8916L10.296 19.7146L10.295 19.7155Z"
                                fill="#FCFCFC"
                              />
                            </svg>{" "}
                            <span className="disapearText">Sebelumnya</span>
                          </Link>
                        </>
                      )}
                      {nomor % 12 !== 0 ? (
                        <>
                          <Link
                            to="#"
                            className="buttonTest bola"
                            onClick={() => {
                              setNomor(nomor + 1);
                            }}
                          >
                            <span className="disapearText">Selanjutnya</span>{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M13.704 4.28428C13.6116 4.18739 13.5006 4.11006 13.3777 4.05687C13.2548 4.00368 13.1224 3.97571 12.9885 3.97464C12.8546 3.97357 12.7218 3.9994 12.5981 4.05062C12.4743 4.10183 12.3622 4.17738 12.2682 4.27277C12.1742 4.36817 12.1003 4.48148 12.0509 4.60597C12.0016 4.73046 11.9777 4.86359 11.9808 4.99748C11.9838 5.13137 12.0138 5.26327 12.0688 5.38537C12.1238 5.50748 12.2027 5.61728 12.301 5.70828L17.67 11.0003H4C3.73478 11.0003 3.48043 11.1056 3.29289 11.2932C3.10536 11.4807 3 11.7351 3 12.0003C3 12.2655 3.10536 12.5199 3.29289 12.7074C3.48043 12.8949 3.73478 13.0003 4 13.0003H17.665L12.3 18.2853C12.1206 18.4733 12.0214 18.7238 12.0235 18.9836C12.0256 19.2435 12.1288 19.4923 12.3112 19.6774C12.4935 19.8626 12.7408 19.9694 13.0006 19.9753C13.2604 19.9813 13.5123 19.8859 13.703 19.7093L20.628 12.8873C20.7459 12.771 20.8396 12.6324 20.9035 12.4796C20.9674 12.3269 21.0003 12.1629 21.0003 11.9973C21.0003 11.8317 20.9674 11.6677 20.9035 11.5149C20.8396 11.3621 20.7459 11.2236 20.628 11.1073L13.703 4.28428H13.704Z"
                                fill="#FCFCFC"
                              />
                            </svg>
                          </Link>
                        </>
                      ) : (
                        <>
                          {/* <Link className='buttonTest bola' onClick={() => {setNomor(1); setHalaman("selesai"); hitMe()}}>
                                                            <span className='disapearText'>Selanjutnya</span> 
                                                            {" "}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M13.704 4.28428C13.6116 4.18739 13.5006 4.11006 13.3777 4.05687C13.2548 4.00368 13.1224 3.97571 12.9885 3.97464C12.8546 3.97357 12.7218 3.9994 12.5981 4.05062C12.4743 4.10183 12.3622 4.17738 12.2682 4.27277C12.1742 4.36817 12.1003 4.48148 12.0509 4.60597C12.0016 4.73046 11.9777 4.86359 11.9808 4.99748C11.9838 5.13137 12.0138 5.26327 12.0688 5.38537C12.1238 5.50748 12.2027 5.61728 12.301 5.70828L17.67 11.0003H4C3.73478 11.0003 3.48043 11.1056 3.29289 11.2932C3.10536 11.4807 3 11.7351 3 12.0003C3 12.2655 3.10536 12.5199 3.29289 12.7074C3.48043 12.8949 3.73478 13.0003 4 13.0003H17.665L12.3 18.2853C12.1206 18.4733 12.0214 18.7238 12.0235 18.9836C12.0256 19.2435 12.1288 19.4923 12.3112 19.6774C12.4935 19.8626 12.7408 19.9694 13.0006 19.9753C13.2604 19.9813 13.5123 19.8859 13.703 19.7093L20.628 12.8873C20.7459 12.771 20.8396 12.6324 20.9035 12.4796C20.9674 12.3269 21.0003 12.1629 21.0003 11.9973C21.0003 11.8317 20.9674 11.6677 20.9035 11.5149C20.8396 11.3621 20.7459 11.2236 20.628 11.1073L13.703 4.28428H13.704Z" fill="#FCFCFC"/>
                                                            </svg>
                                                        </Link> */}
                          {typeNow === "A" && (
                            <>
                              <Link
                                to="#"
                                className="buttonTest bola"
                                onClick={() => {
                                  setNomor(nomor + 1);
                                  setTypeNow("B");
                                }}
                              >
                                <span className="disapearText">
                                  Selanjutnya
                                </span>{" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M13.704 4.28428C13.6116 4.18739 13.5006 4.11006 13.3777 4.05687C13.2548 4.00368 13.1224 3.97571 12.9885 3.97464C12.8546 3.97357 12.7218 3.9994 12.5981 4.05062C12.4743 4.10183 12.3622 4.17738 12.2682 4.27277C12.1742 4.36817 12.1003 4.48148 12.0509 4.60597C12.0016 4.73046 11.9777 4.86359 11.9808 4.99748C11.9838 5.13137 12.0138 5.26327 12.0688 5.38537C12.1238 5.50748 12.2027 5.61728 12.301 5.70828L17.67 11.0003H4C3.73478 11.0003 3.48043 11.1056 3.29289 11.2932C3.10536 11.4807 3 11.7351 3 12.0003C3 12.2655 3.10536 12.5199 3.29289 12.7074C3.48043 12.8949 3.73478 13.0003 4 13.0003H17.665L12.3 18.2853C12.1206 18.4733 12.0214 18.7238 12.0235 18.9836C12.0256 19.2435 12.1288 19.4923 12.3112 19.6774C12.4935 19.8626 12.7408 19.9694 13.0006 19.9753C13.2604 19.9813 13.5123 19.8859 13.703 19.7093L20.628 12.8873C20.7459 12.771 20.8396 12.6324 20.9035 12.4796C20.9674 12.3269 21.0003 12.1629 21.0003 11.9973C21.0003 11.8317 20.9674 11.6677 20.9035 11.5149C20.8396 11.3621 20.7459 11.2236 20.628 11.1073L13.703 4.28428H13.704Z"
                                    fill="#FCFCFC"
                                  />
                                </svg>
                              </Link>
                            </>
                          )}
                          {typeNow === "B" && (
                            <>
                              <Link
                                to="#"
                                className="buttonTest bola"
                                onClick={() => {
                                  setNomor(nomor + 1);
                                  setTypeNow("C");
                                }}
                              >
                                <span className="disapearText">
                                  Selanjutnya
                                </span>{" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M13.704 4.28428C13.6116 4.18739 13.5006 4.11006 13.3777 4.05687C13.2548 4.00368 13.1224 3.97571 12.9885 3.97464C12.8546 3.97357 12.7218 3.9994 12.5981 4.05062C12.4743 4.10183 12.3622 4.17738 12.2682 4.27277C12.1742 4.36817 12.1003 4.48148 12.0509 4.60597C12.0016 4.73046 11.9777 4.86359 11.9808 4.99748C11.9838 5.13137 12.0138 5.26327 12.0688 5.38537C12.1238 5.50748 12.2027 5.61728 12.301 5.70828L17.67 11.0003H4C3.73478 11.0003 3.48043 11.1056 3.29289 11.2932C3.10536 11.4807 3 11.7351 3 12.0003C3 12.2655 3.10536 12.5199 3.29289 12.7074C3.48043 12.8949 3.73478 13.0003 4 13.0003H17.665L12.3 18.2853C12.1206 18.4733 12.0214 18.7238 12.0235 18.9836C12.0256 19.2435 12.1288 19.4923 12.3112 19.6774C12.4935 19.8626 12.7408 19.9694 13.0006 19.9753C13.2604 19.9813 13.5123 19.8859 13.703 19.7093L20.628 12.8873C20.7459 12.771 20.8396 12.6324 20.9035 12.4796C20.9674 12.3269 21.0003 12.1629 21.0003 11.9973C21.0003 11.8317 20.9674 11.6677 20.9035 11.5149C20.8396 11.3621 20.7459 11.2236 20.628 11.1073L13.703 4.28428H13.704Z"
                                    fill="#FCFCFC"
                                  />
                                </svg>
                              </Link>
                            </>
                          )}
                          {typeNow === "C" && (
                            <>
                              <Link
                                to="#"
                                className="buttonTest bola"
                                onClick={() => {
                                  setNomor(nomor + 1);
                                  setTypeNow("D");
                                }}
                              >
                                <span className="disapearText">
                                  Selanjutnya
                                </span>{" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M13.704 4.28428C13.6116 4.18739 13.5006 4.11006 13.3777 4.05687C13.2548 4.00368 13.1224 3.97571 12.9885 3.97464C12.8546 3.97357 12.7218 3.9994 12.5981 4.05062C12.4743 4.10183 12.3622 4.17738 12.2682 4.27277C12.1742 4.36817 12.1003 4.48148 12.0509 4.60597C12.0016 4.73046 11.9777 4.86359 11.9808 4.99748C11.9838 5.13137 12.0138 5.26327 12.0688 5.38537C12.1238 5.50748 12.2027 5.61728 12.301 5.70828L17.67 11.0003H4C3.73478 11.0003 3.48043 11.1056 3.29289 11.2932C3.10536 11.4807 3 11.7351 3 12.0003C3 12.2655 3.10536 12.5199 3.29289 12.7074C3.48043 12.8949 3.73478 13.0003 4 13.0003H17.665L12.3 18.2853C12.1206 18.4733 12.0214 18.7238 12.0235 18.9836C12.0256 19.2435 12.1288 19.4923 12.3112 19.6774C12.4935 19.8626 12.7408 19.9694 13.0006 19.9753C13.2604 19.9813 13.5123 19.8859 13.703 19.7093L20.628 12.8873C20.7459 12.771 20.8396 12.6324 20.9035 12.4796C20.9674 12.3269 21.0003 12.1629 21.0003 11.9973C21.0003 11.8317 20.9674 11.6677 20.9035 11.5149C20.8396 11.3621 20.7459 11.2236 20.628 11.1073L13.703 4.28428H13.704Z"
                                    fill="#FCFCFC"
                                  />
                                </svg>
                              </Link>
                            </>
                          )}
                          {typeNow === "D" && (
                            <>
                              <Link
                                to="#"
                                className="buttonTest bola"
                                onClick={() => {
                                  setNomor(nomor + 1);
                                  setTypeNow("E");
                                }}
                              >
                                <span className="disapearText">
                                  Selanjutnya
                                </span>{" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M13.704 4.28428C13.6116 4.18739 13.5006 4.11006 13.3777 4.05687C13.2548 4.00368 13.1224 3.97571 12.9885 3.97464C12.8546 3.97357 12.7218 3.9994 12.5981 4.05062C12.4743 4.10183 12.3622 4.17738 12.2682 4.27277C12.1742 4.36817 12.1003 4.48148 12.0509 4.60597C12.0016 4.73046 11.9777 4.86359 11.9808 4.99748C11.9838 5.13137 12.0138 5.26327 12.0688 5.38537C12.1238 5.50748 12.2027 5.61728 12.301 5.70828L17.67 11.0003H4C3.73478 11.0003 3.48043 11.1056 3.29289 11.2932C3.10536 11.4807 3 11.7351 3 12.0003C3 12.2655 3.10536 12.5199 3.29289 12.7074C3.48043 12.8949 3.73478 13.0003 4 13.0003H17.665L12.3 18.2853C12.1206 18.4733 12.0214 18.7238 12.0235 18.9836C12.0256 19.2435 12.1288 19.4923 12.3112 19.6774C12.4935 19.8626 12.7408 19.9694 13.0006 19.9753C13.2604 19.9813 13.5123 19.8859 13.703 19.7093L20.628 12.8873C20.7459 12.771 20.8396 12.6324 20.9035 12.4796C20.9674 12.3269 21.0003 12.1629 21.0003 11.9973C21.0003 11.8317 20.9674 11.6677 20.9035 11.5149C20.8396 11.3621 20.7459 11.2236 20.628 11.1073L13.703 4.28428H13.704Z"
                                    fill="#FCFCFC"
                                  />
                                </svg>
                              </Link>
                            </>
                          )}
                          {typeNow === "E" && (
                            <>
                              <Link
                                to="#"
                                className="buttonTest bola"
                                onClick={() => {
                                  setNomor(1);
                                  setHalaman("selesai");
                                  hitMe();
                                }}
                              >
                                <span className="disapearText">
                                  Selanjutnya
                                </span>{" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M13.704 4.28428C13.6116 4.18739 13.5006 4.11006 13.3777 4.05687C13.2548 4.00368 13.1224 3.97571 12.9885 3.97464C12.8546 3.97357 12.7218 3.9994 12.5981 4.05062C12.4743 4.10183 12.3622 4.17738 12.2682 4.27277C12.1742 4.36817 12.1003 4.48148 12.0509 4.60597C12.0016 4.73046 11.9777 4.86359 11.9808 4.99748C11.9838 5.13137 12.0138 5.26327 12.0688 5.38537C12.1238 5.50748 12.2027 5.61728 12.301 5.70828L17.67 11.0003H4C3.73478 11.0003 3.48043 11.1056 3.29289 11.2932C3.10536 11.4807 3 11.7351 3 12.0003C3 12.2655 3.10536 12.5199 3.29289 12.7074C3.48043 12.8949 3.73478 13.0003 4 13.0003H17.665L12.3 18.2853C12.1206 18.4733 12.0214 18.7238 12.0235 18.9836C12.0256 19.2435 12.1288 19.4923 12.3112 19.6774C12.4935 19.8626 12.7408 19.9694 13.0006 19.9753C13.2604 19.9813 13.5123 19.8859 13.703 19.7093L20.628 12.8873C20.7459 12.771 20.8396 12.6324 20.9035 12.4796C20.9674 12.3269 21.0003 12.1629 21.0003 11.9973C21.0003 11.8317 20.9674 11.6677 20.9035 11.5149C20.8396 11.3621 20.7459 11.2236 20.628 11.1073L13.703 4.28428H13.704Z"
                                    fill="#FCFCFC"
                                  />
                                </svg>
                              </Link>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            {halaman === "selesai" && (
              <>
                <div className="testBerakhir">
                  <p>
                    Semua soal udah selesai, <br />
                    yuk cek hasilnya ke halaman report!
                  </p>
                  <img src={end} />
                  <Link
                    className="buttonTest end"
                    to={{ pathname: "/complementary" }}
                  >
                    Kembali
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Soal;

import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import ReactApexChart from "react-apexcharts";

import Lottie from 'react-lottie';
import accessDenied from '../../../../assets/images/FutureLink/accessDenied.json';

import saint from '../../../../assets/images/FutureLink/saint.png';
import bisnis from '../../../../assets/images/FutureLink/bisnis.png';
import seni from '../../../../assets/images/FutureLink/seni.png';

const Penjurusan = (haisayang) => {
    if(haisayang.history.location.state === undefined){
        window.location.href = "/report"
    }

    const data = haisayang.history.location.state.data
    // console.log(haisayang.history.location.state.data)

    const packages = haisayang.history.location.state.package_id
    // console.log(haisayang.history.location.state.package_id)

    const science = Math.abs(data.science);
    const commerce = Math.abs(data.commerce);
    const art = Math.abs(data.art);

    return(
        <React.Fragment>
            <div className="page-content">
                {packages === 22 || packages === 33 ? (
                    <>
                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                        <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 40px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>Pemilihan Jurusan</p>
                    </div>

                    <div className='penjurusan'>

                        <Link style={{marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to='/report'>Back</Link>
                        
                        <div className='penjurusanWeb'>
                            <p style={{margin: '0px', fontSize: '14px', textAlign: 'justify'}}><span style={{fontWeight: '700'}}>Pemilihan jurusan</span> ialah suatu pengambilan keputusan yang merupakan proses dimana seseorang menjatuhkan pilihannya dari beberapa alternatif pilihan yang ada. Dengan menentukan pilihan jurusan yang tepat, seseorang khususnya anak usia sekolah diharapkan mampu menyiapkan diri dengan optimal terhadap pilihan yang telah diambil. Pemilihan jurusan dapat ditentukan berdasarkan sembilan <span style={{fontWeight: '700'}}>kecerdasan majemuk</span> atau multiple intelligence yang dimiliki oleh setiap orang.</p>

                            <div style={{margin: '80px 0px'}}>
                                <ReactApexChart
                                    series={[art, science, commerce]}
                                    options={{        
                                        chart: {
                                            height: 320,
                                            type: 'donut',
                                        },
                                        labels: ['Bidang Seni', 'Bidang Sains', 'Bidang Bisnis'],
                                        dataLabels: {
                                            enabled: false
                                        },
                                        legend: {
                                            position: 'bottom'
                                        },
                                        // colors: chartDonutupdatingColors
                                        colors: ['#4361EE', '#05C38A', '#EF6806'],
                                    }}
                                    type="donut"
                                    height={320}
                                />
                            </div>
                        </div>

                        <div className='penjurusanList'>

                            <div >

                                <div className='penjurusanWebMobile' >
                                    <div className='penjurusanBulat'>
                                        <p style={{margin: '0px'}}>Bidang Sains</p>
                                    </div>
                                    <div>
                                        <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A', textAlign: 'center'}}>{data.science}%</p>
                                        <p className='penjurusanBorder'>Kecocokan Anda</p>
                                    </div>
                                </div>

                                <div className='penjurusanKeterangan'>

                                    <p style={{fontSize: '14px', textAlign: 'justify'}}><span style={{fontWeight: '800'}}>Sains</span> berasal dari bahasa latin yaitu “scientia” yang artinya pengetahuan. Sains dapat diartikan sebagai pengetahuan sistematis yang diperoleh dari suatu observasi, penelitian, dan uji coba yang mengarah pada penentuan sifat dasar atau prinsip sesuatu yang sedang diselidiki, dipelajari, dan lain sebagainya. Sains juga dapat diartikan suatu cara untuk memperoleh pengetahuan baru yang berupa produk ilmiah dan sikap ilmiah melalui suatu kegiatan yang disebut proses ilmiah.</p>

                                    <p style={{fontSize: '14px', fontWeight: '800'}}>Contoh Jurusan</p>

                                    <ul style={{fontSize: '14px'}}>
                                        <li><span style={{fontWeight: '800'}}>Pelayan medis:</span> kedokteran, keperawatan, analis kesehatan, analis gizi, dan apoteker.</li>
                                        <li><span style={{fontWeight: '800'}}>Ilmuwan:</span> teknik kimia, fisika murni, mikrobiologi, geofisika, biokimia dan biofisik.</li>
                                        <li><span style={{fontWeight: '800'}}>Lainnya: </span> Arsitek, programmer komputer, teknik listrik, pendidikan sains, dan lain sebagainya.</li>
                                    </ul>

                                    <img src={saint} style={{width: '100%'}}/>

                                </div>

                            </div>

                            <div>

                                <div className='penjurusanWebMobile'>
                                    <div className='penjurusanBulat'>
                                        <p style={{margin: '0px'}}>Bidang Bisnis</p>
                                    </div>
                                    <div>
                                        <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A', textAlign: 'center'}}>{data.commerce}%</p>
                                        <p className='penjurusanBorder'>Kecocokan Anda</p>
                                    </div>
                                </div>

                                <div className='penjurusanKeterangan'>

                                    <p style={{fontSize: '14px', textAlign: 'justify'}}><span style={{fontWeight: '800'}}>Bisnis</span> diambil dari bahasa Inggris “business” yang artinya adalah kesibukan. Secara umum, ilmu bisnis adalah bidang ilmu yang mempelajari bagaimana melakukan pemasaran produk, pembukuan dan pengelolaan keuangan serta hal-hal terkait keberlanjutan dari suatu usaha sehingga dapat menghasilkan keuntungan. Selain itu, bisnis juga membantu memahami kebutuhan pelanggan dan mengkomersialisasikannya.</p>

                                    <p style={{fontSize: '14px', fontWeight: '800'}}>Contoh Jurusan</p>

                                    <ul style={{fontSize: '14px'}}>
                                        <li><span style={{fontWeight:'800'}}>Keuangan:</span> akuntansi, manajemen, perbankan, perpajakan, serta ilmu ekonomi.</li>
                                        <li><span style={{fontWeight: '800'}}>Sumber daya manusia: </span> manajemen, sosiologi, pemasaran, serta administrasi bisnis. </li>
                                        <li><span style={{fontWeight: '800'}}>Lainnya: </span>Hukum, kewirausahaan, aktuaria, serta sistem informasi bisnis.</li>
                                    </ul>

                                    <img src={bisnis} style={{width: '100%'}}/>

                                </div>

                            </div>

                            <div>

                                <div className='penjurusanWebMobile'>
                                    <div className='penjurusanBulat'>
                                        <p style={{margin: '0px'}}>Bidang Seni</p>
                                    </div>
                                    <div>
                                        <p style={{margin: '0px', fontSize: '32px', fontWeight: '900', color: '#05C38A', textAlign: 'center'}}>{data.art}%</p>
                                        <p className='penjurusanBorder'>Kecocokan Anda</p>
                                    </div>
                                </div>

                                <div className='penjurusanKeterangan'>

                                    <p style={{fontSize: '14px', textAlign: 'justify'}}><span style={{fontWeight: '800'}}>Seni</span> dari bahasa Sansekerta “sani” yang artinya persembahan, pelayanan dan pemberian yang tulus. Ilmu seni mempelajari kegiatan manusia dalam menciptakan karya visual, audio, atau pertunjukan yang mengungkapkan imajinasi, gagasan, atau keprihatinan teknik pembuatnya, untuk dihargai keindahannya atau kekuatan emosinya.   Pembahasan seni dari masing-masing bidang ilmu dikorelasikan berdasarkan pada pemikiran-pemikiran yang bersifat rasionalitas, sistematis, dan tetap berpedoman pada realitas.</p>

                                    <p style={{fontSize: '14px', fontWeight: '800'}}>Contoh Jurusan</p>

                                    <ul style={{fontSize: '14px'}}>
                                        <li><span style={{fontWeight: '800'}}>Musik: </span> karawitan, etnomusikologi, penyaji musik, serta seni musik.Pelayan medis: kedokteran, keperawatan, analis kesehatan, analis gizi, dan apoteker.</li>
                                        <li><span style={{fontWeight: '800'}}>Kriya: </span> desain komunikasi visual, seni lukis, seni pahat, perancang busana, dan tata rias.</li>
                                        <li><span style={{fontWeight: '800'}}>Lainnya: </span> Seni tari, pewayangan, desain interior, pertelevisian dan perfilman.</li>
                                    </ul>

                                    <img src={seni} style={{width: '100%'}}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{display: 'flex', justifyContent:'space-between', padding: '20px'}}>
                        {packages === 22 && (
                            <>
                            <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report/ekstrakulikuler", state: { data: data, package_id: packages }}}>{"<"} Ekstrakulikuler</Link>
                            <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report/kecerdasan", state: { data: data, package_id: packages }}}>Kecerdasan {">"}</Link>
                            </>
                        )}
                        {packages === 33 && (
                            <>
                            <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report/ekstrakulikuler", state: { data: data, package_id: packages }}}>{"<"} Ekstrakulikuler</Link>
                            <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report/lobus-otak", state: { data: data, package_id: packages }}}>Lobus Otak {">"}</Link>
                            </>
                        )}
                    </div>
                    </>
                ) : (
                    <>
                        <Link style={{marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to='/report'>Back</Link>
                        <Lottie options={{loop: true,autoplay: true,animationData: accessDenied,rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} isClickToPauseDisabled={true} />
                        <p className='upgradePlease'> Maaf, Anda belum melakukan Upgrade yang lebih tinggi</p>
                    </>
                )}

            </div>
        </React.Fragment>
    )

}

export default Penjurusan;
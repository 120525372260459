import React, { useState, useEffect } from "react";
import {Row, Col, Modal} from "reactstrap";
import {Link} from 'react-router-dom';
import axios from 'axios';
import Lottie from 'react-lottie';

import ls from '../../../../assets/images/FutureLink/purEd.png';
import lz from '../../../../assets/images/FutureLink/purPr.png';
import animateAlert from '../../../../assets/images/FutureLink/alert.json'
import accessLoading from '../../../../assets/images/FutureLink/loading.json';

const PurchaseClient = () => {
    const [emailVerify, setEmailVerify] = useState(0)
    const [modalAlertVerify, setModalAlertVerify] = useState(false);
    const [loadingPackage, setLoadingPackage] = useState(true);
    const [listPackages, setListPackages] = useState([]);
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const code = sessionStorage.getItem('user')
    useEffect(() => {
        getUser()
        getAllPackagesV2()
    },[])

    const getUser = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-user-token`,
            headers: { 
                'Authorization': `Bearer ${code}`
            }
        };
          
        axios.request(config)
            .then((response) => {
                // console.log(response);
                // setCheckPackage(response.results.package_id)
                setEmail(response.results.email)
                setPhone(response.results.phone)
                setEmailVerify(response.results.email_verified)
            })
            .catch((error) => {
                // console.log(error);
            });
    }

    const getAllPackagesV2 = () => {
        setLoadingPackage(true)

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}/v1/get-all-packages?status=1`,
            headers: { 
                'Authorization': `Bearer ${code}`
            }
        };
          
        axios.request(config)
            .then((response) => {
                setListPackages(response.results)

                setTimeout(() => {
                    setLoadingPackage(false)
                }, 2000)
            })
            .catch((error) => {
                setTimeout(() => {
                    setLoadingPackage(false)
                }, 2000)
            });

    }

    return(<>
        <Row className="corev2 repCen">
        {loadingPackage === true ? (
            <>
                <Lottie width={'50%'} options={{loop: true, autoplay: true,animationData: accessLoading,rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} isClickToPauseDisabled={true} />
            </>
            ) : (
            <>
            {listPackages.map((item,index) => (
                <>
                <Col lg={3}>
                    <div className="rlHeader">
                        <p className="antitank">{item.name}</p>
                    </div>
                    <div className="rlContent">
                        <p className="hargaPur">Rp. {item.price},00</p>
                        <p className="ketPur">{item.description}</p>
                        <img src={item.image} style={{width: '100%', marginBottom: '20%', height: '205px', objectFit: 'cover'}}/>
                        {emailVerify === 0 ? (
                            <Link className="buttonTest width77" onClick={() => {setModalAlertVerify(true)}}>Beli Sekarang!</Link>
                        ) : (
                            <Link className="buttonTest width77" to={{pathname: '/client/purchase/process', state:{pack: item.name, pack_id: item.package_id, harga: item.price, is_counselor: item.counselor == 0 ? false : true, email: email, phone: phone}}}>Beli Sekarang!</Link>
                        )}

                    </div>
                </Col>
                </>
            ))}
            </>
        )}
            {/* <Col lg={5}>
                <div className="rlHeader">
                    <p className="antitank">Paket Edukasional</p>
                </div>
                <div className="rlContent">
                    <p className="hargaPur">Rp. 129.000,00</p>
                    <p className="ketPur">Cocok buat kamu yang lagi fokus dalam memilih karir pendidikan selanjutnya!</p>
                    <img src={ls} style={{width: '100%', marginBottom: '20%'}}/>
                    {emailVerify === 0 ? (
                        <Link className="buttonTest width77" onClick={() => {setModalAlertVerify(true)}}>Beli Sekarang!</Link>
                    ) : (
                        <Link className="buttonTest width77" to={{pathname: '/client/purchase/process', state:{pack: 'Educational', pack_id: 22, harga: 129000, is_counselor: false, email: email, phone: phone}}}>Beli Sekarang!</Link>
                    )}

                </div>
            </Col>
            <Col lg={5}>
                <div className="rlHeader">
                    <p className="antitank">Paket Profesional</p>
                </div>
                <div className="rlContent">
                    <p className="hargaPur">Rp. 199.000,00</p>
                    <p className="ketPur">Cocok buat kamu yang lagi fokus dalam pengembangan diri dan karir kerjamu 😎</p>
                    <img src={lz} style={{width: '100%'}}/>
                    {emailVerify === 0 ? (
                        <Link className="buttonTest width77" onClick={() => {setModalAlertVerify(true)}}>Beli Sekarang!</Link>
                    ) : (
                    <Link className="buttonTest width77" to={{pathname:'/client/purchase/process', state:{pack: 'Professional', pack_id: 33, harga: 199000, is_counselor: true, email: email, phone: phone}}}>Beli Sekarang!</Link>
                    )}
                </div>
            </Col> */}
        </Row>

        <Modal isOpen={modalAlertVerify} toggle={() => {setModalAlertVerify(!modalAlertVerify)}} centered >
            <div style={{marginTop: '30px', marginBottom: '30px'}}>
                <Lottie options={{loop: true,autoplay: true,animationData: animateAlert,rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} height={250} width={250} isClickToPauseDisabled={true} />
            </div>
            <div style={{marginBottom: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <p style={{textAlign: 'center', fontSize: '18px', fontWeight: '500', color: '#374151'}}>Anda Belum Verify Email <br/>Silahkan Pergi ke Halaman Profile</p>
            </div>
        </Modal>
        </>
    )
}

export default PurchaseClient
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { Progress } from "reactstrap";
import eagle from "../../../../../assets/images/FutureLink/eagle_dope.png";
import dove from "../../../../../assets/images/FutureLink/dove_dope.png";
import owl from "../../../../../assets/images/FutureLink/owl_dope.png";
import peacock from "../../../../../assets/images/FutureLink/peacock_dope.png";

const Summary4 = (haisayang) => {
  const data = haisayang.history.location.state.data;
  // console.log(haisayang.history.location.state.data)

  const packages = haisayang.history.location.state.package_id;
  // console.log(haisayang.history.location.state.package_id)

  const token = haisayang.history.location.state.params;
  const [dope, setDope] = useState("");
  const [dopeImg, setDopeImg] = useState();
  const [dopeDesc, setDopeDesc] = useState("");

  useEffect(() => {
    if (data.disc == "domination") {
      setDope("Eagle");
      setDopeImg(eagle);
      setDopeDesc(
        "Menilai sesuatu berdasarkan hasil, pemberani, tidak mudah dipengaruhi, sadar akan citra diri, keras kepala dan kurang simpatik"
      );
    } else if (data.disc == "influential") {
      setDope("Peacock");
      setDopeImg(peacock);
      setDopeDesc(
        "Memiliki antusiasme yang tinggi, energik dan bergerak cepat, menikmati tantangan, tidak teratur dan tidak suka hal detail"
      );
    } else if (data.disc == "steady") {
      setDope("Dove");
      setDopeImg(dove);
      setDopeDesc(
        "Ramah dan suportif, suka membantu dan memberi, santai dan cenderung lambat bergerak, suka tergantung pada orang lain"
      );
    } else {
      setDope("Owl");
      setDopeImg(owl);
      setDopeDesc(
        "Mudah beradaptasi, terbiasa untuk menganalisis sesuatu, menyukai hal-hal yang detail, sulit percaya orang lain, cukup egois"
      );
    }
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="polasidikjariList">
          <Link
            style={{ width: "max-content" }}
            className="btn buttonVersFutureLinkBorderGrey"
            to={`/report-mobile/${token}`}
          >
            Back
          </Link>
          <h4 style={{ display: "flex", justifyContent: "center" }}>
            Kemampuan Penunjang Kepribadian
          </h4>
          <div className="grid-container">
            <div className="grid-item">
              <div className="grid-container">
                <div className="grid-item end-item">
                  <h6>DOPE</h6>
                  <h1>{dope}</h1>
                  <p style={{ textAlign: "right", fontSize: 14 }}>{dopeDesc}</p>
                </div>
                <div className="grid-item">
                  <img src={dopeImg} />
                </div>
              </div>
              <h5>Konsep Pemikiran</h5>
              <p style={{ fontSize: 14 }}>
                Perbandingan sikap dominan yang dilakukan untuk menyelesaikan
                suatu permasalahan.
              </p>
              <div style={{ marginBottom: "80px" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "25%",
                    justifyContent: "center",
                    marginTop: "25px",
                  }}
                >
                  <div>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        textAlign: "right",
                      }}
                    >
                      Executor
                    </p>
                  </div>

                  <div>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        textAlign: "left",
                      }}
                    >
                      Thinker
                    </p>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p
                    style={{
                      margin: "0px",
                      flex: "1",
                      textAlign: "end",
                      paddingRight: "10px",
                      fontSize: "14px",
                    }}
                  >
                    {data.executor}%
                  </p>
                  <div style={{ flex: "4" }}>
                    <Progress multi style={{ height: "30px" }}>
                      <Progress bar value={data.executor} color="success" />
                      <Progress bar value={data.thinker} color="warning" />
                    </Progress>
                  </div>
                  <p
                    style={{
                      margin: "0px",
                      flex: "1",
                      textAlign: "start",
                      paddingLeft: "10px",
                      fontSize: "14px",
                    }}
                  >
                    {data.thinker}%
                  </p>
                </div>
              </div>
            </div>
            <div className="grid-item">
              <div className="grid-item">
                <div className="q-title center-item">
                  <h6>Self-Development Suggestions:</h6>
                  <p style={{ fontSize: "11px" }}>
                    Work on improving fine motor skills through activities
                    related to your artistic interests, practicing regularly to
                    enhance dexterity. Engage in mindfulness exercises to
                    strengthen introspective intelligence and self-awareness.
                    Join art-related clubs or workshops to develop your skills
                    further and connect with like-minded individuals. Celebrate
                    your unique strengths within the arts, and remember that
                    personal growth often comes from both challenges and
                    successes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "20px",
          }}
        >
          <Link
            className="bottomButton buttonVersFutureLink"
            to={{
              pathname: "/report-mobiles/summary/3",
              state: { data: data, package_id: packages, params: token },
            }}
          >
            {"<"} Sebelumnya
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Summary4;

import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Container, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Label, Input, Modal, ModalHeader, ModalBody, CardImgOverlay } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { Link } from 'react-router-dom';
import Masonry from "react-masonry-component";


// import Images
import SmallImage1 from '../../../assets/images/small/img-1.jpg';
import SmallImage2 from '../../../assets/images/small/img-2.jpg';
import SmallImage3 from '../../../assets/images/small/img-3.jpg';
import SmallImage4 from '../../../assets/images/small/img-4.jpg';
import SmallImage5 from '../../../assets/images/small/img-5.jpg';
import SmallImage6 from '../../../assets/images/small/img-6.jpg';
import SmallImage7 from '../../../assets/images/small/img-7.jpg';
import SmallImage8 from '../../../assets/images/small/img-8.jpg';
import SmallImage9 from '../../../assets/images/small/img-9.jpg';
import SmallImage10 from '../../../assets/images/small/img-10.jpg';
import smallImage11 from '../../../assets/images/small/img-11.jpg';
import smallImage12 from '../../../assets/images/small/img-12.jpg';

import KiJempol from '../../../assets/images/FutureLink/KiJempol.png';
import KiTelunjuk from '../../../assets/images/FutureLink/KiTelunjuk.png';
import KiTengah from '../../../assets/images/FutureLink/KiTengah.png';
import KiManis from '../../../assets/images/FutureLink/KiManis.png';
import KiKelingking from '../../../assets/images/FutureLink/KiKelingking.png';
import KaJempol from '../../../assets/images/FutureLink/KaJempol.png';
import KaTelunjuk from '../../../assets/images/FutureLink/KaTelunjuk.png';
import KaTengah from '../../../assets/images/FutureLink/KaTengah.png';
import KaManis from '../../../assets/images/FutureLink/KaManis.png';
import KaKelingking from '../../../assets/images/FutureLink/KaKelingking.png';

import RThumb from '../../../assets/images/FutureLink/RThumb.png'
import RPoint from '../../../assets/images/FutureLink/RPoint.png'
import RMiddle from '../../../assets/images/FutureLink/RMiddle.png'
import RRing from '../../../assets/images/FutureLink/RRing.png'
import RPinky from '../../../assets/images/FutureLink/RPinky.png'
import LThumb from '../../../assets/images/FutureLink/LThumb.png'
import LPoint from '../../../assets/images/FutureLink/LPoint.png'
import LMiddle from '../../../assets/images/FutureLink/LMiddle.png'
import LRing from '../../../assets/images/FutureLink/LRing.png'
import LPinky from '../../../assets/images/FutureLink/LPinky.png'

const ListOfImages = () => {
  // document.title="Gallery | Velzon - React Admin & Dashboard Template";
  const [pattern, setPattern] = useState("");
  const fingerType = [
    {
      name: "Tidak diketahui",
      value: "nothing",
    },
    {
      name: "Whorl Centric",
      value: "W_centric",
    },
    {
      name: "Whorl Spiral",
      value: "W_spiral",
    },
    {
      name: "Whorl Elongated Centric",
      value: "W_elongated_centric",
    },
    {
      name: "Whorl Elongated Spiral",
      value: "W_elongated_spiral",
    },
    {
      name: "Whorl Imploding",
      value: "W_imploding",
    },
    {
      name: "Whorl Composite",
      value: "W_composite",
    },
    {
      name: "Target Centric Ulnar Peacock Eye",
      value: "W_peacock_centric_ulnar",
    },
    {
      name: "Spiral Ulnar Peacock Eye",
      value: "W_peacock_spiral_ulnar",
    },
    {
      name: "Target Centric Radial Peacock Eye",
      value: "W_peacock_centric_radial",
    },
    {
      name: "Spiral Radial Peacock Eye",
      value: "W_peacock_spiral_radial",
    },
    {
      name: "Loop Double", //
      value: "L_double",
    },
    {
      name: "Loop Ulnar",
      value: "L_ulnar",
    },
    {
      name: "Loop Radial",
      value: "L_radial",
    },
    {
      name: "Loop Falling Ulnar",
      value: "L_fallen_ulnar",
    },
    {
      name: "Loop Falling Radial",
      value: "L_fallen_radial",
    },
    {
      name: "Arch Simple",
      value: "A_simple",
    },
    {
      name: "Arch Tented",
      value: "A_tented",
    },
    {
      name: "Arch Tented with Ulnar Loop",
      value: "A_w_L_ulnar",
    },
    {
      name: "Arch Tented with Radial Loop",
      value: "A_w_L_radial",
    },
    {
      name: "Accidental Whorl",
      value: "accidental",
    },
  ];

  const [displayCategory, setCategory] = useState("Right");

  //modal
  const [modal_togFirst, setmodal_togFirst] = useState(false);
  const [modal_image, setmodal_image] = useState(false);
  const [modal_image_id, setmodal_image_id] = useState("");
  const [modal_tambahkan_catatan, setmodal_tambahkan_catatan] = useState(false);
  //end modal

  const gallery = [
    {
        id:1,
        img:RThumb,
        title:"Right Thumb",
        auther:"Ron Mackie",
        // likes:"2.2K",
        // comments:"1.3K",
        category: "Right",
        imgIcon: KaJempol
    },
    {
        id:2,
        img:RPoint,
        title:"Right Index",
        auther:"Nancy Martino",
        // likes:"2.2K",
        // comments:"1.3K",
        category: "Right",
        imgIcon: KaTelunjuk
    },
    {
        id:3,
        img:RMiddle,
        title:"Right Middle",
        auther:"Elwood Arter",
        // likes:"2.2K",
        // comments:"1.3K",
        category: "Right",
        imgIcon: KaTengah
    },
    {
        id:4,
        img:RRing,
        title:"Right Ring",
        auther:"Jason McQuaid",
        // likes:"2.2K",
        // comments:"1.3K",
        category: "Right",
        imgIcon: KaManis
    },
    {
        id:5,
        img:RPinky,
        title:"Right Little",
        auther:"Henry Baird",
        // likes:"2.2K",
        // comments:"1.3K",
        category: "Right",
        imgIcon: KaKelingking
    },
    {
        id:6,
        img:LPinky,
        title:"Left Little",
        auther:"Erica Kernan",
        // likes:"2.2K",
        // comments:"1.3K",
        category: "Left",
        imgIcon: KiKelingking
    },
    {
        id:7,
        img:LRing,
        title:"Left Ring",
        auther:"James Ballard",
        // likes:"2.2K",
        // comments:"1.3K",
        category: "Left",
        imgIcon: KiManis
    },
    {
        id:8,
        img:LMiddle,
        title:"Left Middle",
        auther:"Ruby Griffin",
        // likes:"2.2K",
        // comments:"1.3K",
        category: "Left",
        imgIcon: KiTengah
    },
    {
        id:9,
        img:LPoint,
        title:"Left Index",
        auther:"Ron Mackie",
        // likes:"2.2K",
        // comments:"1.3K",
        category: "Left",
        imgIcon: KiTelunjuk
    },
    {
      id:10,
      img:LThumb,
      title:"Left Thumb",
      auther:"Ron Mackie",
      // likes:"2.2K",
      // comments:"1.3K",
      category: "Left",
      imgIcon: KiJempol
    }
  ]

  const tog_togFirst = () => {
    setmodal_togFirst(!modal_togFirst);
  }

  const tog_modalImage = (img) => {
    setmodal_image(!modal_image);
    setmodal_image_id(img);
  }

  const tog_modal_tambahkan_catatan = () => {
    setmodal_tambahkan_catatan(!modal_tambahkan_catatan);
  }


  return (
    <React.Fragment>
      <div className="page-content">        
        <Container fluid>
          <BreadCrumb title="List Of Image From Teja" pageTitle="Pages" /> 
          <Row>
            <Col lg={12}>
            <Link to="/users-group/table-user-default" style={{marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey'> 
              Back
            </Link>
              <div className="">
                <CardBody>
                  <Row>
                    <Col lg={12}>
                      <div className="text-center">
                        <ul className="list-inline categories-filter animation-nav" id="filter">
                          <li className="list-inline-item">
                            <Link to="#" onClick={() => setCategory("Right")} className={displayCategory === "Right"? "categories active": "categories"} data-filter=".Right">RIGHT</Link></li>
                          <li className="list-inline-item">
                            <Link to="#" onClick={() => setCategory("Left")} className={displayCategory === "Left"? "categories active": "categories"} data-filter=".Left">LEFT</Link></li>
                        </ul>
                      </div>

                      <Masonry className="row gallery-wrapper">
                      {(gallery.filter(({ category }) => displayCategory === category)).map(({ img, title, auther,likes,comments, imgIcon },key) => (
                        <Col xxl={3} xl={4} sm={6} className="element-item project designing development" key={key} >
                          <Card className="gallery-box">
                            <div className="gallery-container" onClick={() => tog_modalImage(img)} style={{cursor: "pointer", display: 'flex'}}>
                                <img className="gallery-img img-fluid mx-auto" src={img} alt="" />
                                <div className="gallery-overlay">
                                  <h5 className="overlay-caption">{title}</h5>
                                </div>
                            </div>

                            <div className="box-content">

                              <div style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
                                <img src={imgIcon} alt="" style={{width: '100px', border: '1px solid white', borderRadius: '10px'}}/>
                              </div>

                                <div className="d-flex align-items-center mt-1">
                                  <div className="flex-grow-1 text-muted">Checked by <Link to="#" className="text-body text-truncate">{auther}</Link></div>
                                  <div className="flex-shrink-0">
                                    <div className="d-flex gap-3">
                                      <button type="button" className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0">
                                        status: <span className="badge badge-soft-success text-uppercase">Diterima</span>
                                      </button>
                                      <button type="button" className="btn btn-sm fs-13 btn-link text-body text-decoration-none px-0">
                                        <i className="ri-question-answer-fill text-muted align-bottom me-1" onClick={()=> tog_modal_tambahkan_catatan()}></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              

                              <div className="d-flex align-items-center mt-3 mb-1 justify-content-between">

                                  <div>
                                  {/* <UncontrolledDropdown>
                                      <DropdownToggle tag="button" className="btn btn-secondary" >
                                          Choose Pattern <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu className="dropdown-menu-dark">
                                        <DropdownItem  >Tidak Diketahui</DropdownItem>
                                          
                                          <DropdownItem>Whorl Spiral</DropdownItem>
                                          <DropdownItem>Whorl Elongated Target</DropdownItem>
                                          <DropdownItem>Whorl Elongated Spiral</DropdownItem>

                                      </DropdownMenu>
                                  </UncontrolledDropdown> */}
                                  <select className="form-control" data-trigger  >
                                    <option value="0" selected="selected" hidden="hidden">
                                      Choose here
                                    </option>
                                      {fingerType.map((type, index) => (
                                        <option key={index} value={type.value}>{type.name}</option>
                                      ))}
                                  </select>
                                  </div>
                            
                                  <div>
                                      <Input type="text" className="form-control" id="basiInput" placeholder='Input Redge Count'/>
                                  </div>
                              </div>

                              <div className="d-flex justify-content-center mt-3">
                                  <div style={{marginRight : "10px"}}>
                                  <Label className="form-check-label" for="SwitchCheck1">Unlock</Label>
                                  </div>
                                  <div className="form-check form-switch">
                                      <Input className="form-check-input" type="checkbox" role="switch" id="SwitchCheck1" defaultChecked/>
                                  </div>
                                  <div style={{marginLeft : "10px"}}>
                                  <Label className="form-check-label" for="SwitchCheck1">Lock</Label>
                                  </div>
                              </div>

                            </div>
                          </Card>
                        </Col>
                      ))}
                        
                      </Masonry>

                      <div className="text-center my-2">    
                        <Button color="dark" className="rounded-pill" onClick={() => tog_togFirst()}> Submit </Button>
                      </div>

                    </Col>
                  </Row>
                </CardBody>
              </div>
            </Col>
          </Row>

          {/* modal image*/}
          <Modal
          className='modal-xl'
          isOpen={modal_image}
          toggle={() => {
              tog_modalImage();
          }}
          id="firstmodal"
          centered
          >
          <ModalHeader toggle={() => {
              tog_modalImage();
          }}>

          </ModalHeader>
          <ModalBody className="text-center">
              <img className="overlay" src={modal_image_id} />
          </ModalBody>
          </Modal>
          {/* end modal image*/}

          {/* modal submit*/}
          <Modal
          isOpen={modal_togFirst}
          toggle={() => {
              tog_togFirst();
          }}
          id="firstmodal"
          centered
      >
          <ModalHeader>
              <h5 className="modal-title" id="exampleModalToggleLabel">
                  Perhatian !!!
              </h5>
              {/* <Button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                      setmodal_togFirst(false);
                  }}
                  aria-label="Close"
              >
              </Button> */}
          </ModalHeader>
          <ModalBody className="text-center p-5">
              <lord-icon
                  src="https://cdn.lordicon.com/tdrtiskw.json"
                  trigger="loop"
                  colors="primary:#f7b84b,secondary:#5ea3cb"
                  style={{ width: "130px", height: "130px" }}>
              </lord-icon>
              <div className="mt-4 pt-4">
                  <h4>Uh oh, something went wrong!</h4>
                  <p className="text-muted"> The transfer was not successfully received by us. the email of the recipient wasn't correct.</p>
                  <div className='d-flex justify-content-center gap-4 mt-2'>
                    <Button className="btn btn-danger" onClick={() => {tog_togFirst(false); }}>
                        Close
                    </Button>
                    <Button className="btn btn-primary">
                        Submit
                    </Button>
                  </div>
              </div>
          </ModalBody>
          </Modal>
          {/* end modal submit */}

          {/* modal tambahkan catatan*/}
          <Modal
          className='modal-md'
          isOpen={modal_tambahkan_catatan}
          toggle={() => {
              tog_modal_tambahkan_catatan();
          }}
          id="firstmodal"
          centered
          >
          <ModalHeader toggle={() => {
              tog_modal_tambahkan_catatan();
          }}>
            Catatan dari admin
          </ModalHeader>
          <ModalBody className="text-center">
              <div className="mb-1">
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="4"
                  placeholder="Lebih teliti lagi, masih salah dalam memahami pattern"
                  disabled={true}
                ></textarea>
              </div>
          </ModalBody>
          </Modal>
          {/* end modal tambahkan catatan*/}

        </Container>
      </div>
    </React.Fragment>
  )
}

export default ListOfImages
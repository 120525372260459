import React, {useState} from "react";
import {Row, Col, Progress} from 'reactstrap'
import {Link} from 'react-router-dom'
import ReactApexChart from "react-apexcharts";

const Dn = (e) => {
    const state = e.state.data
    const data = e.state.data
    const package_id = e.state.package_id
    const img_finger = e.state.img_finger
    const shape_finger = e.state.shape_finger

    const right = [
        {
            title: 'Ibu Jari Kanan (R1)',
            text: "Intrapersonal",
            img: img_finger[0],
            value: state.intrapersonal,
            ket: 'Keterampilan intrapersonal merupakan kemampuan untuk memahami kekuatan serta kelemahan diri sendiri yang meliputi keterampilan untuk berpikir secara rasional serta mengatur koordinasi, pengendalian, dan pencapaian diri.'
        },
        {
            title: 'Jari Telunjuk Kanan (R2)',
            text: "Logical",
            img: img_finger[2],
            value: state.logical,
            ket: 'Keterampilan logika merupakan kemampuan untuk menarik kesimpulan berdasarkan logika dan dapat dibuktikan dengan ilmu yang sudah diketahui yang meliputi kemampuan logika dan penalaran, serta analisis dan proses komputasi.'
        },
        {
            title: 'Jari Tengah Kanan (R3)',
            text: "Motorik Halus",
            img: img_finger[4],
            value: state.fine_motors,
            ket: 'Keterampilan motorik halus merupakan kemampuan yang melibatkan otot-otot kecil yang lebih menuntut koordinasi mata dan tangan yang meliputi keterampilan jari, mengidentifikasi tindakan, serta kontrol tangan.'
        },
        {
            title: 'Jari Manis Kanan (R4)',
            text: "Linguistik",
            img: img_finger[6],
            value: state.lingustic,
            ket: 'Keterampilan linguistik merupakan kemampuan untuk menyusun pikiran dan mampu mengungkapkan melalui kata-kata yang meliputi kemampuan dan pemahaman bahasa, pembentukan dan memori kata, serta komunikasi verbal.'
        },
        {
            title: 'Jari Kelingking Kanan (R5)',
            text: "Naturalistik",
            img: img_finger[8],
            value: state.naturalist,
            ket: 'Naturalistik merupakan kemampuan untuk mengenali, melihat perbedaan, menggolongkan, dan mengkategorikan apa yang dilihat atau dijumpai di alam dan lingkungan sekitarnya.'
        },
        {
            title: 'Ibu Jari Kiri (L1)',
            text: "Interpersonal",
            img: img_finger[1],
            value: state.interpersonal,
            ket: 'Keterampilan interpersonal merupakan kemampuan untuk berinteraksi secara efektif dengan orang lain yang meliputi keterampilan dalam memimpin, belajar dalam kelompok, dan bekerja secara tim.'
        },
        {
            title: 'Jari Telunjuk Kiri (L2)',
            text: "Spasial",
            img: img_finger[3],
            value: state.imagination,
            ket: 'Keterampilan spasial merupakan kemampuan untuk menciptakan gambaran sesuatu berdasarkan kenyataan yang  meliputi imajinasi, pembentukan ide, visualisasi, serta berpikir kreatif.'
        },
        {
            title: 'Jari Tengah Kiri (L3)',
            text: "Motorik Kasar",
            img: img_finger[5],
            value: state.gross_motors,
            ket: 'Keterampilan motorik kasar merupakan kemampuan yang melibatkan gerakan otot besar seperti otot lengan, kaki, atau bahkan otot di seluruh tubuhnya yang meliputi keterampilan dalam melakukan koordinasi gerakan serta bahasa tubuh.'
        },
        {
            title: 'Jari Manis Kiri (L4)',
            text: "Musikal",
            img: img_finger[7],
            value: state.musical,
            ket: 'Keterampilan musikal merupakan kemampuan untuk mengelola musik serta emosi dari diri sendiri dan orang-orang di sekitar yang  meliputi kepekaan terhadap suara, ritme, nada, emosi dan perasaan, serta komunikasi non-verbal.'
        },
        {
            title: 'Jari Kelingking Kiri (L5)',
            text: "Visual",
            img: img_finger[9],
            value: state.visual_spatial,
            ket: 'Keterampilan visual merupakan kemampuan seseorang untuk memahami, menyimpan, mengingat, dan menciptakan gambaran yang meliputi apresiasi visual, interpretasi estetika visual, seni visualisasi, serta introspeksi.'
        },
    ]

    const think = Math.abs(state.think);
    const auditory = Math.abs(state.auditory);
    const action = Math.abs(state.action);
    const tactile = Math.abs(state.tactile);
    const visual = Math.abs(state.visual);

    return(
        <Row className="corev2">
            <div className="appearOnMobile tiwtiw" style={{marginBottom: '24px'}}>
            <Link to={{pathname: '/client/reportFP', state: { data: data, package_id: package_id, img_finger:img_finger, shape_finger:shape_finger },}} className="bBek">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M10.295 19.7155C10.3886 19.8077 10.4994 19.8806 10.6211 19.93C10.7429 19.9793 10.8731 20.0042 11.0045 20.0033C11.1358 20.0023 11.2657 19.9754 11.3867 19.9243C11.5076 19.8731 11.6173 19.7986 11.7095 19.705C11.8017 19.6115 11.8746 19.5007 11.9239 19.379C11.9733 19.2572 11.9982 19.127 11.9972 18.9956C11.9963 18.8643 11.9694 18.7344 11.9183 18.6134C11.8671 18.4925 11.7926 18.3827 11.699 18.2906L6.32903 13.0006H19.999C20.2642 13.0006 20.5186 12.8952 20.7061 12.7077C20.8937 12.5201 20.999 12.2658 20.999 12.0006C20.999 11.7353 20.8937 11.481 20.7061 11.2934C20.5186 11.1059 20.2642 11.0006 19.999 11.0006H6.33603L11.7 5.71355C11.884 5.52633 11.987 5.2743 11.9867 5.01183C11.9865 4.74936 11.8831 4.49751 11.6988 4.31061C11.5145 4.12371 11.2642 4.01675 11.0017 4.01281C10.7393 4.00887 10.4858 4.10827 10.296 4.28955L3.37203 11.1116C3.25411 11.2278 3.16048 11.3664 3.09657 11.5192C3.03267 11.672 2.99976 11.8359 2.99976 12.0016C2.99976 12.1672 3.03267 12.3311 3.09657 12.4839C3.16048 12.6367 3.25411 12.7753 3.37203 12.8916L10.296 19.7146L10.295 19.7155Z" fill="#374151"/>
                </svg>
                </Link>
                <p className="antitank" style={{textAlign: 'center'}}>Distribusi Neuron</p>
            </div>
            <Row className="disT">

                <div className='distribusineuronList'>
                    <div className='collection'>

                    {right.map((item, index) => (
                    <div key={index} className='item'>
                        <div className='value'>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <p className='dbTit'>{item.title}</p>
                                <p style={{margin: '0px', fontSize: '18px', color: '#1F599C'}}>{item.text}</p>
                            </div>
                            <div style={{display: 'flex', alignItems:'center', gap: '12px'}}>
                                <div style={{border: '1px solid #05c38a',padding: '10px',borderRadius: '10px'}}>
                                    <img src={item.img} className="polasImg" />
                                </div>
                                <p style={{fontSize: '20px', fontWeight: '900', color: '#05C38A', margin: '0px'}}>{item.value}%</p>
                            </div>

                        </div>

                        <p className='dbKet'>{item.ket}</p>
                    </div>
                    ))}
                    </div>

                </div>

            </Row>
            <Row className="disB">
                <div>
                    <ReactApexChart
                        series={[ think, auditory, action, tactile, visual]}
                        options={{        
                            chart: {
                                height: 320,
                                type: 'donut',
                            },
                            labels: ['Think', 'Auditory', 'Action', 'Tactile', 'Visual'],
                            dataLabels: {
                                enabled: false
                            },
                            legend: {
                                position: 'right'
                            },
                            colors: ['#9C89B8', '#F0A6C0', '#EFC3E6', '#F0E6E0', '#B8BED0'],
                        }}
                        type="donut"
                        height={320}
                    />
                </div>
                <div>
                <div className='dbL'>
                        <div>
                            <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                <div style={{width: '24px', height: '24px', backgroundColor: '#EFC3E6', borderRadius: '50px'}}></div>
                                <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>Action <span style={{marginLeft: '30px'}}>{action}%</span></p>
                            </div>

                            <div style={{marginTop: '10px'}}>
                                <p style={{margin: '0px', fontSize: '16px', textAlign: 'justify'}}>Proses bertindak ditunjukkan oleh <span style={{fontWeight: '900'}}>ibu jari</span> yang menggambarkan kemampuan untuk merencanakan dan melaksanakan tujuan. Selain itu juga menggambarkan cara menghadapi diri sendiri dan orang lain.</p>
                            </div>
                        </div>
                        <div>
                            <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                <div style={{width: '24px', height: '24px', backgroundColor: '#9C89B8', borderRadius: '50px'}}></div>
                                <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>Think <span style={{marginLeft: '30px'}}>{think}%</span></p>
                            </div>

                            <div style={{marginTop: '10px'}}>
                                <p style={{margin: '0px', fontSize: '16px', textAlign: 'justify'}}>Proses berpikir ditunjukkan oleh <span style={{fontWeight: '900'}}>jari telunjuk</span> yang menggambarkan kemampuan untuk menerapkan pemikiran logis dan imajiner. Selain itu juga menunjukkan cara berpikir setiap orang.</p>
                            </div>
                        </div>
                        <div>
                            <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                <div style={{width: '24px', height: '24px', backgroundColor: '#F0E6E0', borderRadius: '50px'}}></div>
                                <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>Tactile  <span style={{marginLeft: '30px'}}>{tactile}%</span></p>
                            </div>

                            <div style={{marginTop: '10px'}}>
                                <p style={{margin: '0px', fontSize: '16px', textAlign: 'justify'}}>Proses taktil ditunjukkan oleh <span style={{fontWeight: '900'}}>jari tengah</span> yang menggambarkan kemampuan dalam mengkoordinasikan gerak tubuh, gerakan tangan, dan koordinasi mata-tangan diri sendiri.</p>
                            </div>
                        </div>
                        <div>
                            <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                <div style={{width: '24px', height: '24px', backgroundColor: '#F0A6C0', borderRadius: '50px'}}></div>
                                <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>Auditory <span style={{marginLeft: '30px'}}>{auditory}%</span></p>
                            </div>

                            <div style={{marginTop: '10px'}}>
                                <p style={{margin: '0px', fontSize: '16px', textAlign: 'justify'}}>Proses auditori ditunjukkan oleh <span style={{fontWeight: '900'}}>jari manis</span> yang menggambarkan kemampuan untuk memproses informasi dengan mendengarkan dan berbicara. </p>
                            </div>
                        </div>
                        <div>
                            <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                                <div style={{width: '24px', height: '24px', backgroundColor: '#B8BED0', borderRadius: '50px'}}></div>
                                <p style={{margin: '0px', fontSize: '18px', fontWeight: '700'}}>Visual <span style={{marginLeft: '30px'}}>{visual}%</span></p>
                            </div>

                            <div style={{marginTop: '10px'}}>
                                <p style={{margin: '0px', fontSize: '16px', textAlign: 'justify'}}>Proses visual ditunjukkan oleh <span style={{fontWeight: '900'}}>jari kelingking</span> yang menggambarkan kemampuan untuk memvisualisasikan, membayangkan dan memahami berbagai hal. Selain itu juga berkaitan dengan pemrosesan informasi melalui mata setiap orang.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        </Row>
    )
}

export default Dn
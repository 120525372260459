import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import Lottie from 'react-lottie';
import accessDenied from '../../../../assets/images/FutureLink/accessDenied.json';

import red from '../../../../assets/images/FutureLink/red.png';
import yellow from '../../../../assets/images/FutureLink/yellow.png';
import green from '../../../../assets/images/FutureLink/green.png';
import blue from '../../../../assets/images/FutureLink/blue.png';

const DISC= (haisayang) => {
    if(haisayang.history.location.state === undefined){
        window.location.href = "/report"
    }

    const data = haisayang.history.location.state.data
    // console.log(haisayang.history.location.state.data)

    const packages = haisayang.history.location.state.package_id
    // console.log(haisayang.history.location.state.package_id)


    return(
        <React.Fragment>
            <div className="page-content">
                {packages === 33 ? (
                    <>
                <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                    <p style={{margin: '0px', backgroundColor: '#1F599C', padding: '8px 40px', fontSize: '18px', fontWeight: '900', color: 'white', borderRadius: '10px', width: 'max-content'}}>DISC</p>
                </div>

                <div className='disc'>
                    <Link style={{marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to='/report'>Back</Link>

                    <div className='discWeb'>

                        <div className='discGambar'>
                            <img src={red} style={{width: '240px'}} />
                            <img src={yellow} style={{width: '150px'}} />
                            <img src={green} style={{width: '225px'}} />
                            <img src={blue} style={{width: '145px'}} />
                        </div>

                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <p style={{fontSize: '14px', fontWeight: '700', marginBottom: '30px'}}>Tipe Kepribadian Anda menurut DISC adalah:</p>
                            <div style={{width: '300px', height: '300px', border: '2px solid #1F599C', borderRadius: '1000px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                {data.disc === 'domination' && (
                                    <>
                                    <p className='red' style={{fontSize: '32px', margin: '0px', fontWeight: '600'}}>Dominant</p>
                                    </>
                                )}
                                {data.disc === 'influential' && (
                                    <>
                                    <p className='yellow' style={{fontSize: '32px', margin: '0px', fontWeight: '600'}}>Influence</p>
                                    </>
                                )}
                                {data.disc=== 'steady' && (
                                    <>
                                    <p className='green' style={{fontSize: '32px', margin: '0px', fontWeight: '600'}}>Steadiness</p>
                                    </>
                                )}
                                {data.disc === 'compliant' && (
                                    <>
                                    <p className='blue' style={{fontSize: '32px', margin: '0px', fontWeight: '600'}}>Cautious</p>
                                    </>
                                )}

                            </div>
                        </div>

                        <div style={{marginTop: '50px'}}>
                            <p style={{fontSize: '14px', textAlign: 'justify'}}><span style={{fontWeight: '700'}}>DISC</span> pertama kali diperkenalkan dalam buku tahun 1928 yang berjudul Emotions of Normal People oleh psikolog Willian Marston. Model Marston membagi perilaku manusia menjadi empat tipe kepribadian DISC: (D) Dominant, (I) Influence, (S) Steadiness, dan (C) Cautious. DISC adalah alat penilaian pribadi yang digunakan untuk meningkatkan produktivitas kerja, kerja tim, kepemimpinan, dan komunikasi. DISC mengukur kepribadian dan gaya perilaku. DISC tidak mengukur kecerdasan, bakat, kesehatan mental atau nilai-nilai tertentu.</p>
                        </div>

                        <div>
                            <div style={{margin: '50px'}}>
                                <p style={{fontSize: '36px', fontWeight: '700', color: '#A50E0A'}}>Dominant</p>
                                <div style={{overflow: 'hidden'}}>
                                    <img src={red} style={{width: '220px', float: 'left', marginRight: '40px'}} />
                                    <p style={{fontSize: '14px', textAlign: 'justify', margin: '0px'}}>
                                    Orang yang berkepribadian <span style={{fontWeight: '700'}}>dominan</span>  cenderung mengutarakan segala hal secara langsung, biasanya memiliki pengaruh yang kuat untuk menentukan sesuatu. Tidak hanya itu, orang yang dominan memiliki kekuatan ego yang tinggi namun dapat menjadi pemecah masalah yang ulung, berani mengambil risiko, dan pemula yang mandiri.
                                    <br/><br/>
                                    Orang yang dominan adalah seseorang yang dapat mengambil kesimpulan dari segala hal, sangat menghargai segala yang selesai dengan tepat waktu, dan orang-orang yang sangat berinovatif. Namun orang yang dominan memiliki sikap yang terlalu keras kepala, terlalu melebihi otoritas yang dimiliki, sangat tidak menyukai rutinitas, dan terlalu menyusahkan diri dengan melakukan banyak hal secara sekaligus.
                                    <br/><br/>
                                    Ketakutan terbesar orang yang dominan adalah sangat takut untuk dimanfaatkan. Sedangkan hal yang dapat memotivasi orang yang dominan adalah tantangan baru, menghadapi sesuatu yang terdiri dari kekuasaan dan wewenang untuk mengambil risiko, serta membuat keputusan.
                                    </p>
                                </div>
                            </div>

                            <div style={{margin: '50px'}}>
                                <p style={{fontSize: '36px', fontWeight: '700', color: '#F9A239'}}>Influence</p>
                                <div style={{overflow: 'hidden'}}>
                                    <img src={yellow} style={{width: '170px', float: 'left', marginRight: '40px'}} />
                                    <p style={{fontSize: '14px', textAlign: 'justify', margin: '0px'}}>
                                    Orang yang berkepribadian <span style={{fontWeight: '700'}}>influence</span> biasa dikenal sebagai seorang yang berpengaruh. Karakteristiknya antusias, memiliki kepercayaan diri yang tinggi, selalu optimis, banyak bicara, impulsive, emosional dan persuasif (mudah mempengaruhi orang lain). Orang yang berpengaruh selalu memberi semangat yang besar kepada orang-orang di sekitarnya, memotivasi orang lain untuk mencapai tujuan, memiliki humor yang positif dan baik, hebat dalam bernegosiasi terutama saat terjadi konflik, serta berperan aktif dalam menciptakan perdamaian.
                                    <br/><br/>
                                    Namun orang yang berpengaruh ini biasanya lebih mementingkan popularitas daripada hasil yang nyata, tidak suka memperhatikan segala sesuatu secara detail, ekspresif dan cenderung hanya ingin mendengarkan sesuatu yang membuat nyaman dan bahagia.
                                    <br/><br/>
                                    Ketakutan terbesar dari orang yang berpengaruh adalah penolakan. Orang ini sangat takut dengan sebuah penolakan karena menganggap semua orang akan menerima dan menyukainya.
                                    </p>
                                </div>
                            </div>

                            <div style={{margin: '50px'}}>
                                <p style={{fontSize: '36px', fontWeight: '700', color: '#2F940F'}}>Steadiness</p>
                                <div style={{overflow: 'hidden'}}>
                                    <img src={green} style={{width: '200px', float: 'left', marginRight: '40px'}} />
                                    <p style={{fontSize: '14px', textAlign: 'justify', margin: '0px'}}>
                                    Orang yang kepribadiannya <span style={{fontWeight: '700'}}>steadiness</span> adalah seorang pendengar yang baik, anggota tim yang unggul, seorang yang posesif dan menenangkan. Orang ini juga mudah untuk ditebak dan sangat ramah serta selalu berusaha memahami orang lain, dapat diandalkan, sangat setia, patuh terhadap pihak yang memiliki otoritas, penyabar, seorang yang memiliki empati tinggi dan sangat handal dalam mendamaikan konflik.
                                    <br/><br/>
                                    Namun orang berkepribadian steadiness biasanya suka menolak perubahan, sehingga membutuhkan waktu yang cukup lama untuk menyesuaikan diri dengan lingkungan baru. Selain itu biasanya juga merupakan seorang yang pendendam, peka terhadap kritik dan kesulitan untuk menetapkan prioritas.
                                    <br/><br/>
                                    Ketakutan dari kepribadian ini adalah takut kehilangan rasa aman. Sedangkan hal yang dapat memotivasinya adalah pengakuan atas kesetiaan, berada di lingkungan yang tidak memiliki perubahan secara mendadak, baik dalam prosedur atau gaya hidup serta hal-hal yang mudah untuk dikerjakan.
                                    </p>
                                </div>
                            </div>

                            <div style={{margin: '50px'}}>
                                <p style={{fontSize: '36px', fontWeight: '700', color: '#0584E2'}}>Cautious</p>
                                <div style={{overflow: 'hidden'}}>
                                    <img src={blue} style={{width: '170px', float: 'left', marginRight: '40px'}} />
                                    <p style={{fontSize: '14px', textAlign: 'justify', margin: '0px'}}>
                                    Orang yang berkepribadian <span style={{fontWeight: '700'}}>cautious</span> biasanya sangat akurat dan seorang yang analitis, sangat cermat dan teliti serta segalanya harus berdasarkan fakta dan tepat pada sasaran. Hal tersebut membuat orang ini memiliki standar yang tinggi dan sangat sistematis.
                                    <br/><br/>
                                    Orang yang berkepribadian cautious memiliki ketelitian yang sangat mendalam pada semua kegiatan, suka mendefinisikan situasi, mengumpulkan data, mengkritik dan menguji kebenaran dari informasi-informasi yang didapatkan.
                                    <br/><br/>
                                    Namun orang yang berkepribadian ini memerlukan batasan yang jelas dari setiap tindakan yang diperbuat, terlalu terikat oleh prosedur dan metode yang ada, sering mandek dalam hal-hal yang rinci, tidak suka mengungkapkan perasaan secara verbal, tidak suka berdebat. Sedangkan hal yang paling ditakutinya adalah kritik.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='discMobile'>

                        <div className='discMobileDeskripsi'>
                            <p style={{fontSize: '14px', textAlign: 'justify'}}><span style={{fontWeight: '700'}}>DISC</span> pertama kali diperkenalkan dalam buku tahun 1928 yang berjudul Emotions of Normal People oleh psikolog Willian Marston. Model Marston membagi perilaku manusia menjadi empat tipe kepribadian DISC: (D) Dominant, (I) Influence, (S) Steadiness, dan (C) Cautious. DISC adalah alat penilaian pribadi yang digunakan untuk meningkatkan produktivitas kerja, kerja tim, kepemimpinan, dan komunikasi. DISC mengukur kepribadian dan gaya perilaku. DISC tidak mengukur kecerdasan, bakat, kesehatan mental atau nilai-nilai tertentu.</p>
                        </div>

                        <div className='discMobileType'>
                            {data.disc === 'domination' && (
                                <>
                                <img src={red} className='discRed'/>
                                <div className='discMobileValue'>
                                    <p className='p1'>Kepribadianmu adalah</p>
                                    <p className='p2 red'>Dominant</p>
                                </div>
                                <div className='discMobileKeterangan'>
                                    <p>Orang yang berkepribadian <span style={{fontWeight: '700'}}>dominan</span> cenderung mengutarakan segala hal secara langsung, biasanya memiliki pengaruh yang kuat untuk menentukan sesuatu. Tidak hanya itu, orang yang dominan memiliki kekuatan ego yang tinggi namun dapat menjadi pemecah masalah yang ulung, berani mengambil risiko, dan pemula yang mandiri.</p>
                                </div>
                                </>
                            )}
                            {data.disc === 'influential' && (
                                <>
                                <img src={yellow} className='discYellow'/>
                                <div className='discMobileValue'>
                                    <p className='p1'>Kepribadianmu adalah</p>
                                    <p className='p2 yellow'>Influence</p>
                                </div>
                                <div className='discMobileKeterangan'>
                                    <p>Orang yang berkepribadian <span style={{fontWeight: '700'}}>influence</span> biasa dikenal sebagai seorang yang berpengaruh. Karakteristiknya antusias, memiliki kepercayaan diri yang tinggi, selalu optimis, banyak bicara, impulsive, emosional dan persuasif (mudah mempengaruhi orang lain). Orang yang berpengaruh selalu memberi semangat yang besar kepada orang-orang di sekitarnya, memotivasi orang lain untuk mencapai tujuan, memiliki humor yang positif dan baik, hebat dalam bernegosiasi terutama saat terjadi konflik, serta berperan aktif dalam menciptakan perdamaian.</p>
                                </div>
                                </>
                            )}
                            {data.disc === 'steady' && (
                                <>
                                <img src={green} className='discRed'/>
                                <div className='discMobileValue'>
                                    <p className='p1'>Kepribadianmu adalah</p>
                                    <p className='p2 green'>Steadiness</p>
                                </div>
                                <div className='discMobileKeterangan'>
                                    <p>Orang yang berkepribadian <span style={{fontWeight: '700'}}>steadiness</span> adalah seorang pendengar yang baik, anggota tim yang unggul, seorang yang posesif dan menenangkan. Orang ini juga mudah untuk ditebak dan sangat ramah serta selalu berusaha memahami orang lain, dapat diandalkan, sangat setia, patuh terhadap pihak yang memiliki otoritas, penyabar, seorang yang memiliki empati tinggi dan sangat handal dalam mendamaikan konflik.</p>
                                </div>
                                </>
                            )}
                            {data.disc === 'compliant' && (
                                <>
                                <img src={blue} className='discYellow'/>
                                <div className='discMobileValue'>
                                    <p className='p1'>Kepribadianmu adalah</p>
                                    <p className='p2 blue'>Cautious</p>
                                </div>
                                <div className='discMobileKeterangan'>
                                    <p>Orang yang berkepribadian <span style={{fontWeight: '700'}}>cautious</span> biasanya sangat akurat dan seorang yang analitis, sangat cermat dan teliti serta segalanya harus berdasarkan fakta dan tepat pada sasaran. Hal tersebut membuat orang ini memiliki standar yang tinggi dan sangat sistematis. Orang yang berkepribadian cautious memiliki ketelitian yang sangat mendalam pada semua kegiatan, suka mendefinisikan situasi, mengumpulkan data, mengkritik dan menguji kebenaran dari informasi-informasi yang didapatkan.</p>
                                </div>
                                </>
                            )}
                        </div>

                    </div>


                </div>

                <div className='prevNext'>
                    <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report/domain-kecerdasan", state: { data: data, package_id: packages }}}>{"<"} Dominan Kecerdasan</Link>
                    <Link className='bottomButton buttonVersFutureLink' to={{pathname: "/report/dope", state: { data: data, package_id: packages }}}>DOPE {">"}</Link>
                </div>
                    </>
                ) : (
                    <>
                        <Link style={{marginBottom: '30px'}} className='btn buttonVersFutureLinkBorderGrey' to='/report'>Back</Link>
                        <Lottie options={{loop: true,autoplay: true,animationData: accessDenied,rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} isClickToPauseDisabled={true} />
                        <p className='upgradePlease'> Maaf, Anda belum melakukan Upgrade yang lebih tinggi</p>
                    </>
                )}
            </div>
        </React.Fragment>
    )

}

export default DISC;
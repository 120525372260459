import React, {useEffect, useState} from 'react';
import Lottie from 'react-lottie';

import paymentFailed from '../../../../assets/images/FutureLink/payment-failed.json'

const Failed = () => {

    return ( 
        <React.Fragment>
            <div className="page-content">
            <Lottie options={{ loop: true, autoplay: true, animationData: paymentFailed, rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}}} height={350} width={350} isClickToPauseDisabled={true} />
            </div>
        </React.Fragment>
    );
};

export default Failed;
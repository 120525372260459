import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useInterval } from "usehooks-ts";

import HalfO from "../../../../assets/images/FutureLink/paro.png";
import Spm from "../../../../assets/images/FutureLink/openingSpm.png";
import Logo from "../../../../assets/images/FutureLink/complementaryLogo.png";

import A1 from "../../../../assets/images/FutureLink/SPM/A1-Q.svg";
import A1Q1 from "../../../../assets/images/FutureLink/SPM/A1-1.svg";
import A1Q2 from "../../../../assets/images/FutureLink/SPM/A1-2.svg";
import A1Q3 from "../../../../assets/images/FutureLink/SPM/A1-3.svg";
import A1Q4 from "../../../../assets/images/FutureLink/SPM/A1-4.svg";
import A1Q5 from "../../../../assets/images/FutureLink/SPM/A1-5.svg";
import A1Q6 from "../../../../assets/images/FutureLink/SPM/A1-6.svg";
import A2 from "../../../../assets/images/FutureLink/SPM/A2-Q.svg";
import A2Q1 from "../../../../assets/images/FutureLink/SPM/A2-1.svg";
import A2Q2 from "../../../../assets/images/FutureLink/SPM/A2-2.svg";
import A2Q3 from "../../../../assets/images/FutureLink/SPM/A2-3.svg";
import A2Q4 from "../../../../assets/images/FutureLink/SPM/A2-4.svg";
import A2Q5 from "../../../../assets/images/FutureLink/SPM/A2-5.svg";
import A2Q6 from "../../../../assets/images/FutureLink/SPM/A2-6.svg";
import A3 from "../../../../assets/images/FutureLink/SPM/A3-Q.svg";
import A3Q1 from "../../../../assets/images/FutureLink/SPM/A3-1.svg";
import A3Q2 from "../../../../assets/images/FutureLink/SPM/A3-2.svg";
import A3Q3 from "../../../../assets/images/FutureLink/SPM/A3-3.svg";
import A3Q4 from "../../../../assets/images/FutureLink/SPM/A3-4.svg";
import A3Q5 from "../../../../assets/images/FutureLink/SPM/A3-5.svg";
import A3Q6 from "../../../../assets/images/FutureLink/SPM/A3-6.svg";
import A4 from "../../../../assets/images/FutureLink/SPM/A4-Q.svg";
import A4Q1 from "../../../../assets/images/FutureLink/SPM/A4-1.svg";
import A4Q2 from "../../../../assets/images/FutureLink/SPM/A4-2.svg";
import A4Q3 from "../../../../assets/images/FutureLink/SPM/A4-3.svg";
import A4Q4 from "../../../../assets/images/FutureLink/SPM/A4-4.svg";
import A4Q5 from "../../../../assets/images/FutureLink/SPM/A4-5.svg";
import A4Q6 from "../../../../assets/images/FutureLink/SPM/A4-6.svg";
import A5 from "../../../../assets/images/FutureLink/SPM/A5-Q.svg";
import A5Q1 from "../../../../assets/images/FutureLink/SPM/A5-1.svg";
import A5Q2 from "../../../../assets/images/FutureLink/SPM/A5-2.svg";
import A5Q3 from "../../../../assets/images/FutureLink/SPM/A5-3.svg";
import A5Q4 from "../../../../assets/images/FutureLink/SPM/A5-4.svg";
import A5Q5 from "../../../../assets/images/FutureLink/SPM/A5-5.svg";
import A5Q6 from "../../../../assets/images/FutureLink/SPM/A5-6.svg";
import A6 from "../../../../assets/images/FutureLink/SPM/A6-Q.svg";
import A6Q1 from "../../../../assets/images/FutureLink/SPM/A6-1.svg";
import A6Q2 from "../../../../assets/images/FutureLink/SPM/A6-2.svg";
import A6Q3 from "../../../../assets/images/FutureLink/SPM/A6-3.svg";
import A6Q4 from "../../../../assets/images/FutureLink/SPM/A6-4.svg";
import A6Q5 from "../../../../assets/images/FutureLink/SPM/A6-5.svg";
import A6Q6 from "../../../../assets/images/FutureLink/SPM/A6-6.svg";
import A7 from "../../../../assets/images/FutureLink/SPM/A7-Q.svg";
import A7Q1 from "../../../../assets/images/FutureLink/SPM/A7-1.svg";
import A7Q2 from "../../../../assets/images/FutureLink/SPM/A7-2.svg";
import A7Q3 from "../../../../assets/images/FutureLink/SPM/A7-3.svg";
import A7Q4 from "../../../../assets/images/FutureLink/SPM/A7-4.svg";
import A7Q5 from "../../../../assets/images/FutureLink/SPM/A7-5.svg";
import A7Q6 from "../../../../assets/images/FutureLink/SPM/A7-6.svg";
import A8 from "../../../../assets/images/FutureLink/SPM/A8-Q.svg";
import A8Q1 from "../../../../assets/images/FutureLink/SPM/A8-1.svg";
import A8Q2 from "../../../../assets/images/FutureLink/SPM/A8-2.svg";
import A8Q3 from "../../../../assets/images/FutureLink/SPM/A8-3.svg";
import A8Q4 from "../../../../assets/images/FutureLink/SPM/A8-4.svg";
import A8Q5 from "../../../../assets/images/FutureLink/SPM/A8-5.svg";
import A8Q6 from "../../../../assets/images/FutureLink/SPM/A8-6.svg";
import A9 from "../../../../assets/images/FutureLink/SPM/A9-Q.svg";
import A9Q1 from "../../../../assets/images/FutureLink/SPM/A9-1.svg";
import A9Q2 from "../../../../assets/images/FutureLink/SPM/A9-2.svg";
import A9Q3 from "../../../../assets/images/FutureLink/SPM/A9-3.svg";
import A9Q4 from "../../../../assets/images/FutureLink/SPM/A9-4.svg";
import A9Q5 from "../../../../assets/images/FutureLink/SPM/A9-5.svg";
import A9Q6 from "../../../../assets/images/FutureLink/SPM/A9-6.svg";
import A10 from "../../../../assets/images/FutureLink/SPM/A10-Q.svg";
import A10Q1 from "../../../../assets/images/FutureLink/SPM/A10-1.svg";
import A10Q2 from "../../../../assets/images/FutureLink/SPM/A10-2.svg";
import A10Q3 from "../../../../assets/images/FutureLink/SPM/A10-3.svg";
import A10Q4 from "../../../../assets/images/FutureLink/SPM/A10-4.svg";
import A10Q5 from "../../../../assets/images/FutureLink/SPM/A10-5.svg";
import A10Q6 from "../../../../assets/images/FutureLink/SPM/A10-6.svg";
import A11 from "../../../../assets/images/FutureLink/SPM/A11-Q.svg";
import A11Q1 from "../../../../assets/images/FutureLink/SPM/A11-1.svg";
import A11Q2 from "../../../../assets/images/FutureLink/SPM/A11-2.svg";
import A11Q3 from "../../../../assets/images/FutureLink/SPM/A11-3.svg";
import A11Q4 from "../../../../assets/images/FutureLink/SPM/A11-4.svg";
import A11Q5 from "../../../../assets/images/FutureLink/SPM/A11-5.svg";
import A11Q6 from "../../../../assets/images/FutureLink/SPM/A11-6.svg";
import A12 from "../../../../assets/images/FutureLink/SPM/A12-Q.svg";
import A12Q1 from "../../../../assets/images/FutureLink/SPM/A12-1.svg";
import A12Q2 from "../../../../assets/images/FutureLink/SPM/A12-2.svg";
import A12Q3 from "../../../../assets/images/FutureLink/SPM/A12-3.svg";
import A12Q4 from "../../../../assets/images/FutureLink/SPM/A12-4.svg";
import A12Q5 from "../../../../assets/images/FutureLink/SPM/A12-5.svg";
import A12Q6 from "../../../../assets/images/FutureLink/SPM/A12-6.svg";

import B1 from "../../../../assets/images/FutureLink/SPM/B1-Q.svg";
import B1Q1 from "../../../../assets/images/FutureLink/SPM/B1-1.svg";
import B1Q2 from "../../../../assets/images/FutureLink/SPM/B1-2.svg";
import B1Q3 from "../../../../assets/images/FutureLink/SPM/B1-3.svg";
import B1Q4 from "../../../../assets/images/FutureLink/SPM/B1-4.svg";
import B1Q5 from "../../../../assets/images/FutureLink/SPM/B1-5.svg";
import B1Q6 from "../../../../assets/images/FutureLink/SPM/B1-6.svg";
import B2 from "../../../../assets/images/FutureLink/SPM/B2-Q.svg";
import B2Q1 from "../../../../assets/images/FutureLink/SPM/B2-1.svg";
import B2Q2 from "../../../../assets/images/FutureLink/SPM/B2-2.svg";
import B2Q3 from "../../../../assets/images/FutureLink/SPM/B2-3.svg";
import B2Q4 from "../../../../assets/images/FutureLink/SPM/B2-4.svg";
import B2Q5 from "../../../../assets/images/FutureLink/SPM/B2-5.svg";
import B2Q6 from "../../../../assets/images/FutureLink/SPM/B2-6.svg";
import B3 from "../../../../assets/images/FutureLink/SPM/B3-Q.svg";
import B3Q1 from "../../../../assets/images/FutureLink/SPM/B3-1.svg";
import B3Q2 from "../../../../assets/images/FutureLink/SPM/B3-2.svg";
import B3Q3 from "../../../../assets/images/FutureLink/SPM/B3-3.svg";
import B3Q4 from "../../../../assets/images/FutureLink/SPM/B3-4.svg";
import B3Q5 from "../../../../assets/images/FutureLink/SPM/B3-5.svg";
import B3Q6 from "../../../../assets/images/FutureLink/SPM/B3-6.svg";
import B4 from "../../../../assets/images/FutureLink/SPM/B4-Q.svg";
import B4Q1 from "../../../../assets/images/FutureLink/SPM/B4-1.svg";
import B4Q2 from "../../../../assets/images/FutureLink/SPM/B4-2.svg";
import B4Q3 from "../../../../assets/images/FutureLink/SPM/B4-3.svg";
import B4Q4 from "../../../../assets/images/FutureLink/SPM/B4-4.svg";
import B4Q5 from "../../../../assets/images/FutureLink/SPM/B4-5.svg";
import B4Q6 from "../../../../assets/images/FutureLink/SPM/B4-6.svg";
import B5 from "../../../../assets/images/FutureLink/SPM/B5-Q.svg";
import B5Q1 from "../../../../assets/images/FutureLink/SPM/B5-1.svg";
import B5Q2 from "../../../../assets/images/FutureLink/SPM/B5-2.svg";
import B5Q3 from "../../../../assets/images/FutureLink/SPM/B5-3.svg";
import B5Q4 from "../../../../assets/images/FutureLink/SPM/B5-4.svg";
import B5Q5 from "../../../../assets/images/FutureLink/SPM/B5-5.svg";
import B5Q6 from "../../../../assets/images/FutureLink/SPM/B5-6.svg";
import B6 from "../../../../assets/images/FutureLink/SPM/B6-Q.svg";
import B6Q1 from "../../../../assets/images/FutureLink/SPM/B6-1.svg";
import B6Q2 from "../../../../assets/images/FutureLink/SPM/B6-2.svg";
import B6Q3 from "../../../../assets/images/FutureLink/SPM/B6-3.svg";
import B6Q4 from "../../../../assets/images/FutureLink/SPM/B6-4.svg";
import B6Q5 from "../../../../assets/images/FutureLink/SPM/B6-5.svg";
import B6Q6 from "../../../../assets/images/FutureLink/SPM/B6-6.svg";
import B7 from "../../../../assets/images/FutureLink/SPM/B7-Q.svg";
import B7Q1 from "../../../../assets/images/FutureLink/SPM/B7-1.svg";
import B7Q2 from "../../../../assets/images/FutureLink/SPM/B7-2.svg";
import B7Q3 from "../../../../assets/images/FutureLink/SPM/B7-3.svg";
import B7Q4 from "../../../../assets/images/FutureLink/SPM/B7-4.svg";
import B7Q5 from "../../../../assets/images/FutureLink/SPM/B7-5.svg";
import B7Q6 from "../../../../assets/images/FutureLink/SPM/B7-6.svg";
import B8 from "../../../../assets/images/FutureLink/SPM/B8-Q.svg";
import B8Q1 from "../../../../assets/images/FutureLink/SPM/B8-1.svg";
import B8Q2 from "../../../../assets/images/FutureLink/SPM/B8-2.svg";
import B8Q3 from "../../../../assets/images/FutureLink/SPM/B8-3.svg";
import B8Q4 from "../../../../assets/images/FutureLink/SPM/B8-4.svg";
import B8Q5 from "../../../../assets/images/FutureLink/SPM/B8-5.svg";
import B8Q6 from "../../../../assets/images/FutureLink/SPM/B8-6.svg";
import B9 from "../../../../assets/images/FutureLink/SPM/B9-Q.svg";
import B9Q1 from "../../../../assets/images/FutureLink/SPM/B9-1.svg";
import B9Q2 from "../../../../assets/images/FutureLink/SPM/B9-2.svg";
import B9Q3 from "../../../../assets/images/FutureLink/SPM/B9-3.svg";
import B9Q4 from "../../../../assets/images/FutureLink/SPM/B9-4.svg";
import B9Q5 from "../../../../assets/images/FutureLink/SPM/B9-5.svg";
import B9Q6 from "../../../../assets/images/FutureLink/SPM/B9-6.svg";
import B10 from "../../../../assets/images/FutureLink/SPM/B10-Q.svg";
import B10Q1 from "../../../../assets/images/FutureLink/SPM/B10-1.svg";
import B10Q2 from "../../../../assets/images/FutureLink/SPM/B10-2.svg";
import B10Q3 from "../../../../assets/images/FutureLink/SPM/B10-3.svg";
import B10Q4 from "../../../../assets/images/FutureLink/SPM/B10-4.svg";
import B10Q5 from "../../../../assets/images/FutureLink/SPM/B10-5.svg";
import B10Q6 from "../../../../assets/images/FutureLink/SPM/B10-6.svg";
import B11 from "../../../../assets/images/FutureLink/SPM/B11-Q.svg";
import B11Q1 from "../../../../assets/images/FutureLink/SPM/B11-1.svg";
import B11Q2 from "../../../../assets/images/FutureLink/SPM/B11-2.svg";
import B11Q3 from "../../../../assets/images/FutureLink/SPM/B11-3.svg";
import B11Q4 from "../../../../assets/images/FutureLink/SPM/B11-4.svg";
import B11Q5 from "../../../../assets/images/FutureLink/SPM/B11-5.svg";
import B11Q6 from "../../../../assets/images/FutureLink/SPM/B11-6.svg";
import B12 from "../../../../assets/images/FutureLink/SPM/B12-Q.svg";
import B12Q1 from "../../../../assets/images/FutureLink/SPM/B12-1.svg";
import B12Q2 from "../../../../assets/images/FutureLink/SPM/B12-2.svg";
import B12Q3 from "../../../../assets/images/FutureLink/SPM/B12-3.svg";
import B12Q4 from "../../../../assets/images/FutureLink/SPM/B12-4.svg";
import B12Q5 from "../../../../assets/images/FutureLink/SPM/B12-5.svg";
import B12Q6 from "../../../../assets/images/FutureLink/SPM/B12-6.svg";

import C1 from "../../../../assets/images/FutureLink/SPM/C1-Q.svg";
import C1Q1 from "../../../../assets/images/FutureLink/SPM/C1-1.svg";
import C1Q2 from "../../../../assets/images/FutureLink/SPM/C1-2.svg";
import C1Q3 from "../../../../assets/images/FutureLink/SPM/C1-3.svg";
import C1Q4 from "../../../../assets/images/FutureLink/SPM/C1-4.svg";
import C1Q5 from "../../../../assets/images/FutureLink/SPM/C1-5.svg";
import C1Q6 from "../../../../assets/images/FutureLink/SPM/C1-6.svg";
import C1Q7 from "../../../../assets/images/FutureLink/SPM/C1-7.svg";
import C1Q8 from "../../../../assets/images/FutureLink/SPM/C1-8.svg";
import C2 from "../../../../assets/images/FutureLink/SPM/C2-Q.svg";
import C2Q1 from "../../../../assets/images/FutureLink/SPM/C2-1.svg";
import C2Q2 from "../../../../assets/images/FutureLink/SPM/C2-2.svg";
import C2Q3 from "../../../../assets/images/FutureLink/SPM/C2-3.svg";
import C2Q4 from "../../../../assets/images/FutureLink/SPM/C2-4.svg";
import C2Q5 from "../../../../assets/images/FutureLink/SPM/C2-5.svg";
import C2Q6 from "../../../../assets/images/FutureLink/SPM/C2-6.svg";
import C2Q7 from "../../../../assets/images/FutureLink/SPM/C2-7.svg";
import C2Q8 from "../../../../assets/images/FutureLink/SPM/C2-8.svg";
import C3 from "../../../../assets/images/FutureLink/SPM/C3-Q.svg";
import C3Q1 from "../../../../assets/images/FutureLink/SPM/C3-1.svg";
import C3Q2 from "../../../../assets/images/FutureLink/SPM/C3-2.svg";
import C3Q3 from "../../../../assets/images/FutureLink/SPM/C3-3.svg";
import C3Q4 from "../../../../assets/images/FutureLink/SPM/C3-4.svg";
import C3Q5 from "../../../../assets/images/FutureLink/SPM/C3-5.svg";
import C3Q6 from "../../../../assets/images/FutureLink/SPM/C3-6.svg";
import C3Q7 from "../../../../assets/images/FutureLink/SPM/C3-7.svg";
import C3Q8 from "../../../../assets/images/FutureLink/SPM/C3-8.svg";
import C4 from "../../../../assets/images/FutureLink/SPM/C4-Q.svg";
import C4Q1 from "../../../../assets/images/FutureLink/SPM/C4-1.svg";
import C4Q2 from "../../../../assets/images/FutureLink/SPM/C4-2.svg";
import C4Q3 from "../../../../assets/images/FutureLink/SPM/C4-3.svg";
import C4Q4 from "../../../../assets/images/FutureLink/SPM/C4-4.svg";
import C4Q5 from "../../../../assets/images/FutureLink/SPM/C4-5.svg";
import C4Q6 from "../../../../assets/images/FutureLink/SPM/C4-6.svg";
import C4Q7 from "../../../../assets/images/FutureLink/SPM/C4-7.svg";
import C4Q8 from "../../../../assets/images/FutureLink/SPM/C4-8.svg";
import C5 from "../../../../assets/images/FutureLink/SPM/C5-Q.svg";
import C5Q1 from "../../../../assets/images/FutureLink/SPM/C5-1.svg";
import C5Q2 from "../../../../assets/images/FutureLink/SPM/C5-2.svg";
import C5Q3 from "../../../../assets/images/FutureLink/SPM/C5-3.svg";
import C5Q4 from "../../../../assets/images/FutureLink/SPM/C5-4.svg";
import C5Q5 from "../../../../assets/images/FutureLink/SPM/C5-5.svg";
import C5Q6 from "../../../../assets/images/FutureLink/SPM/C5-6.svg";
import C5Q7 from "../../../../assets/images/FutureLink/SPM/C5-7.svg";
import C5Q8 from "../../../../assets/images/FutureLink/SPM/C5-8.svg";
import C6 from "../../../../assets/images/FutureLink/SPM/C6-Q.svg";
import C6Q1 from "../../../../assets/images/FutureLink/SPM/C6-1.svg";
import C6Q2 from "../../../../assets/images/FutureLink/SPM/C6-2.svg";
import C6Q3 from "../../../../assets/images/FutureLink/SPM/C6-3.svg";
import C6Q4 from "../../../../assets/images/FutureLink/SPM/C6-4.svg";
import C6Q5 from "../../../../assets/images/FutureLink/SPM/C6-5.svg";
import C6Q6 from "../../../../assets/images/FutureLink/SPM/C6-6.svg";
import C6Q7 from "../../../../assets/images/FutureLink/SPM/C6-7.svg";
import C6Q8 from "../../../../assets/images/FutureLink/SPM/C6-8.svg";
import C7 from "../../../../assets/images/FutureLink/SPM/C7-Q.svg";
import C7Q1 from "../../../../assets/images/FutureLink/SPM/C7-1.svg";
import C7Q2 from "../../../../assets/images/FutureLink/SPM/C7-2.svg";
import C7Q3 from "../../../../assets/images/FutureLink/SPM/C7-3.svg";
import C7Q4 from "../../../../assets/images/FutureLink/SPM/C7-4.svg";
import C7Q5 from "../../../../assets/images/FutureLink/SPM/C7-5.svg";
import C7Q6 from "../../../../assets/images/FutureLink/SPM/C7-6.svg";
import C7Q7 from "../../../../assets/images/FutureLink/SPM/C7-7.svg";
import C7Q8 from "../../../../assets/images/FutureLink/SPM/C7-8.svg";
import C8 from "../../../../assets/images/FutureLink/SPM/C8-Q.svg";
import C8Q1 from "../../../../assets/images/FutureLink/SPM/C8-1.svg";
import C8Q2 from "../../../../assets/images/FutureLink/SPM/C8-2.svg";
import C8Q3 from "../../../../assets/images/FutureLink/SPM/C8-3.svg";
import C8Q4 from "../../../../assets/images/FutureLink/SPM/C8-4.svg";
import C8Q5 from "../../../../assets/images/FutureLink/SPM/C8-5.svg";
import C8Q6 from "../../../../assets/images/FutureLink/SPM/C8-6.svg";
import C8Q7 from "../../../../assets/images/FutureLink/SPM/C8-7.svg";
import C8Q8 from "../../../../assets/images/FutureLink/SPM/C8-8.svg";
import C9 from "../../../../assets/images/FutureLink/SPM/C9-Q.svg";
import C9Q1 from "../../../../assets/images/FutureLink/SPM/C9-1.svg";
import C9Q2 from "../../../../assets/images/FutureLink/SPM/C9-2.svg";
import C9Q3 from "../../../../assets/images/FutureLink/SPM/C9-3.svg";
import C9Q4 from "../../../../assets/images/FutureLink/SPM/C9-4.svg";
import C9Q5 from "../../../../assets/images/FutureLink/SPM/C9-5.svg";
import C9Q6 from "../../../../assets/images/FutureLink/SPM/C9-6.svg";
import C9Q7 from "../../../../assets/images/FutureLink/SPM/C9-7.svg";
import C9Q8 from "../../../../assets/images/FutureLink/SPM/C9-8.svg";
import C10 from "../../../../assets/images/FutureLink/SPM/C10-Q.svg";
import C10Q1 from "../../../../assets/images/FutureLink/SPM/C10-1.svg";
import C10Q2 from "../../../../assets/images/FutureLink/SPM/C10-2.svg";
import C10Q3 from "../../../../assets/images/FutureLink/SPM/C10-3.svg";
import C10Q4 from "../../../../assets/images/FutureLink/SPM/C10-4.svg";
import C10Q5 from "../../../../assets/images/FutureLink/SPM/C10-5.svg";
import C10Q6 from "../../../../assets/images/FutureLink/SPM/C10-6.svg";
import C10Q7 from "../../../../assets/images/FutureLink/SPM/C10-7.svg";
import C10Q8 from "../../../../assets/images/FutureLink/SPM/C10-8.svg";
import C11 from "../../../../assets/images/FutureLink/SPM/C11-Q.svg";
import C11Q1 from "../../../../assets/images/FutureLink/SPM/C11-1.svg";
import C11Q2 from "../../../../assets/images/FutureLink/SPM/C11-2.svg";
import C11Q3 from "../../../../assets/images/FutureLink/SPM/C11-3.svg";
import C11Q4 from "../../../../assets/images/FutureLink/SPM/C11-4.svg";
import C11Q5 from "../../../../assets/images/FutureLink/SPM/C11-5.svg";
import C11Q6 from "../../../../assets/images/FutureLink/SPM/C11-6.svg";
import C11Q7 from "../../../../assets/images/FutureLink/SPM/C11-7.svg";
import C11Q8 from "../../../../assets/images/FutureLink/SPM/C11-8.svg";
import C12 from "../../../../assets/images/FutureLink/SPM/C12-Q.svg";
import C12Q1 from "../../../../assets/images/FutureLink/SPM/C12-1.svg";
import C12Q2 from "../../../../assets/images/FutureLink/SPM/C12-2.svg";
import C12Q3 from "../../../../assets/images/FutureLink/SPM/C12-3.svg";
import C12Q4 from "../../../../assets/images/FutureLink/SPM/C12-4.svg";
import C12Q5 from "../../../../assets/images/FutureLink/SPM/C12-5.svg";
import C12Q6 from "../../../../assets/images/FutureLink/SPM/C12-6.svg";
import C12Q7 from "../../../../assets/images/FutureLink/SPM/C12-7.svg";
import C12Q8 from "../../../../assets/images/FutureLink/SPM/C12-8.svg";

import D1 from "../../../../assets/images/FutureLink/SPM/D1-Q.svg";
import D1Q1 from "../../../../assets/images/FutureLink/SPM/D1-1.svg";
import D1Q2 from "../../../../assets/images/FutureLink/SPM/D1-2.svg";
import D1Q3 from "../../../../assets/images/FutureLink/SPM/D1-3.svg";
import D1Q4 from "../../../../assets/images/FutureLink/SPM/D1-4.svg";
import D1Q5 from "../../../../assets/images/FutureLink/SPM/D1-5.svg";
import D1Q6 from "../../../../assets/images/FutureLink/SPM/D1-6.svg";
import D1Q7 from "../../../../assets/images/FutureLink/SPM/D1-7.svg";
import D1Q8 from "../../../../assets/images/FutureLink/SPM/D1-8.svg";
import D2 from "../../../../assets/images/FutureLink/SPM/D2-Q.svg";
import D2Q1 from "../../../../assets/images/FutureLink/SPM/D2-1.svg";
import D2Q2 from "../../../../assets/images/FutureLink/SPM/D2-2.svg";
import D2Q3 from "../../../../assets/images/FutureLink/SPM/D2-3.svg";
import D2Q4 from "../../../../assets/images/FutureLink/SPM/D2-4.svg";
import D2Q5 from "../../../../assets/images/FutureLink/SPM/D2-5.svg";
import D2Q6 from "../../../../assets/images/FutureLink/SPM/D2-6.svg";
import D2Q7 from "../../../../assets/images/FutureLink/SPM/D2-7.svg";
import D2Q8 from "../../../../assets/images/FutureLink/SPM/D2-8.svg";
import D3 from "../../../../assets/images/FutureLink/SPM/D3-Q.svg";
import D3Q1 from "../../../../assets/images/FutureLink/SPM/D3-1.svg";
import D3Q2 from "../../../../assets/images/FutureLink/SPM/D3-2.svg";
import D3Q3 from "../../../../assets/images/FutureLink/SPM/D3-3.svg";
import D3Q4 from "../../../../assets/images/FutureLink/SPM/D3-4.svg";
import D3Q5 from "../../../../assets/images/FutureLink/SPM/D3-5.svg";
import D3Q6 from "../../../../assets/images/FutureLink/SPM/D3-6.svg";
import D3Q7 from "../../../../assets/images/FutureLink/SPM/D3-7.svg";
import D3Q8 from "../../../../assets/images/FutureLink/SPM/D3-8.svg";
import D4 from "../../../../assets/images/FutureLink/SPM/D4-Q.svg";
import D4Q1 from "../../../../assets/images/FutureLink/SPM/D4-1.svg";
import D4Q2 from "../../../../assets/images/FutureLink/SPM/D4-2.svg";
import D4Q3 from "../../../../assets/images/FutureLink/SPM/D4-3.svg";
import D4Q4 from "../../../../assets/images/FutureLink/SPM/D4-4.svg";
import D4Q5 from "../../../../assets/images/FutureLink/SPM/D4-5.svg";
import D4Q6 from "../../../../assets/images/FutureLink/SPM/D4-6.svg";
import D4Q7 from "../../../../assets/images/FutureLink/SPM/D4-7.svg";
import D4Q8 from "../../../../assets/images/FutureLink/SPM/D4-8.svg";
import D5 from "../../../../assets/images/FutureLink/SPM/D5-Q.svg";
import D5Q1 from "../../../../assets/images/FutureLink/SPM/D5-1.svg";
import D5Q2 from "../../../../assets/images/FutureLink/SPM/D5-2.svg";
import D5Q3 from "../../../../assets/images/FutureLink/SPM/D5-3.svg";
import D5Q4 from "../../../../assets/images/FutureLink/SPM/D5-4.svg";
import D5Q5 from "../../../../assets/images/FutureLink/SPM/D5-5.svg";
import D5Q6 from "../../../../assets/images/FutureLink/SPM/D5-6.svg";
import D5Q7 from "../../../../assets/images/FutureLink/SPM/D5-7.svg";
import D5Q8 from "../../../../assets/images/FutureLink/SPM/D5-8.svg";
import D6 from "../../../../assets/images/FutureLink/SPM/D6-Q.svg";
import D6Q1 from "../../../../assets/images/FutureLink/SPM/D6-1.svg";
import D6Q2 from "../../../../assets/images/FutureLink/SPM/D6-2.svg";
import D6Q3 from "../../../../assets/images/FutureLink/SPM/D6-3.svg";
import D6Q4 from "../../../../assets/images/FutureLink/SPM/D6-4.svg";
import D6Q5 from "../../../../assets/images/FutureLink/SPM/D6-5.svg";
import D6Q6 from "../../../../assets/images/FutureLink/SPM/D6-6.svg";
import D6Q7 from "../../../../assets/images/FutureLink/SPM/D6-7.svg";
import D6Q8 from "../../../../assets/images/FutureLink/SPM/D6-8.svg";
import D7 from "../../../../assets/images/FutureLink/SPM/D7-Q.svg";
import D7Q1 from "../../../../assets/images/FutureLink/SPM/D7-1.svg";
import D7Q2 from "../../../../assets/images/FutureLink/SPM/D7-2.svg";
import D7Q3 from "../../../../assets/images/FutureLink/SPM/D7-3.svg";
import D7Q4 from "../../../../assets/images/FutureLink/SPM/D7-4.svg";
import D7Q5 from "../../../../assets/images/FutureLink/SPM/D7-5.svg";
import D7Q6 from "../../../../assets/images/FutureLink/SPM/D7-6.svg";
import D7Q7 from "../../../../assets/images/FutureLink/SPM/D7-7.svg";
import D7Q8 from "../../../../assets/images/FutureLink/SPM/D7-8.svg";
import D8 from "../../../../assets/images/FutureLink/SPM/D8-Q.svg";
import D8Q1 from "../../../../assets/images/FutureLink/SPM/D8-1.svg";
import D8Q2 from "../../../../assets/images/FutureLink/SPM/D8-2.svg";
import D8Q3 from "../../../../assets/images/FutureLink/SPM/D8-3.svg";
import D8Q4 from "../../../../assets/images/FutureLink/SPM/D8-4.svg";
import D8Q5 from "../../../../assets/images/FutureLink/SPM/D8-5.svg";
import D8Q6 from "../../../../assets/images/FutureLink/SPM/D8-6.svg";
import D8Q7 from "../../../../assets/images/FutureLink/SPM/D8-7.svg";
import D8Q8 from "../../../../assets/images/FutureLink/SPM/D8-8.svg";
import D9 from "../../../../assets/images/FutureLink/SPM/D9-Q.svg";
import D9Q1 from "../../../../assets/images/FutureLink/SPM/D9-1.svg";
import D9Q2 from "../../../../assets/images/FutureLink/SPM/D9-2.svg";
import D9Q3 from "../../../../assets/images/FutureLink/SPM/D9-3.svg";
import D9Q4 from "../../../../assets/images/FutureLink/SPM/D9-4.svg";
import D9Q5 from "../../../../assets/images/FutureLink/SPM/D9-5.svg";
import D9Q6 from "../../../../assets/images/FutureLink/SPM/D9-6.svg";
import D9Q7 from "../../../../assets/images/FutureLink/SPM/D9-7.svg";
import D9Q8 from "../../../../assets/images/FutureLink/SPM/D9-8.svg";
import D10 from "../../../../assets/images/FutureLink/SPM/D10-Q.svg";
import D10Q1 from "../../../../assets/images/FutureLink/SPM/D10-1.svg";
import D10Q2 from "../../../../assets/images/FutureLink/SPM/D10-2.svg";
import D10Q3 from "../../../../assets/images/FutureLink/SPM/D10-3.svg";
import D10Q4 from "../../../../assets/images/FutureLink/SPM/D10-4.svg";
import D10Q5 from "../../../../assets/images/FutureLink/SPM/D10-5.svg";
import D10Q6 from "../../../../assets/images/FutureLink/SPM/D10-6.svg";
import D10Q7 from "../../../../assets/images/FutureLink/SPM/D10-7.svg";
import D10Q8 from "../../../../assets/images/FutureLink/SPM/D10-8.svg";
import D11 from "../../../../assets/images/FutureLink/SPM/D11-Q.svg";
import D11Q1 from "../../../../assets/images/FutureLink/SPM/D11-1.svg";
import D11Q2 from "../../../../assets/images/FutureLink/SPM/D11-2.svg";
import D11Q3 from "../../../../assets/images/FutureLink/SPM/D11-3.svg";
import D11Q4 from "../../../../assets/images/FutureLink/SPM/D11-4.svg";
import D11Q5 from "../../../../assets/images/FutureLink/SPM/D11-5.svg";
import D11Q6 from "../../../../assets/images/FutureLink/SPM/D11-6.svg";
import D11Q7 from "../../../../assets/images/FutureLink/SPM/D11-7.svg";
import D11Q8 from "../../../../assets/images/FutureLink/SPM/D11-8.svg";
import D12 from "../../../../assets/images/FutureLink/SPM/D12-Q.svg";
import D12Q1 from "../../../../assets/images/FutureLink/SPM/D12-1.svg";
import D12Q2 from "../../../../assets/images/FutureLink/SPM/D12-2.svg";
import D12Q3 from "../../../../assets/images/FutureLink/SPM/D12-3.svg";
import D12Q4 from "../../../../assets/images/FutureLink/SPM/D12-4.svg";
import D12Q5 from "../../../../assets/images/FutureLink/SPM/D12-5.svg";
import D12Q6 from "../../../../assets/images/FutureLink/SPM/D12-6.svg";
import D12Q7 from "../../../../assets/images/FutureLink/SPM/D12-7.svg";
import D12Q8 from "../../../../assets/images/FutureLink/SPM/D12-8.svg";

import E1 from "../../../../assets/images/FutureLink/SPM/E1-Q.svg";
import E1Q1 from "../../../../assets/images/FutureLink/SPM/E1-1.svg";
import E1Q2 from "../../../../assets/images/FutureLink/SPM/E1-2.svg";
import E1Q3 from "../../../../assets/images/FutureLink/SPM/E1-3.svg";
import E1Q4 from "../../../../assets/images/FutureLink/SPM/E1-4.svg";
import E1Q5 from "../../../../assets/images/FutureLink/SPM/E1-5.svg";
import E1Q6 from "../../../../assets/images/FutureLink/SPM/E1-6.svg";
import E1Q7 from "../../../../assets/images/FutureLink/SPM/E1-7.svg";
import E1Q8 from "../../../../assets/images/FutureLink/SPM/E1-8.svg";
import E2 from "../../../../assets/images/FutureLink/SPM/E2-Q.svg";
import E2Q1 from "../../../../assets/images/FutureLink/SPM/E2-1.svg";
import E2Q2 from "../../../../assets/images/FutureLink/SPM/E2-2.svg";
import E2Q3 from "../../../../assets/images/FutureLink/SPM/E2-3.svg";
import E2Q4 from "../../../../assets/images/FutureLink/SPM/E2-4.svg";
import E2Q5 from "../../../../assets/images/FutureLink/SPM/E2-5.svg";
import E2Q6 from "../../../../assets/images/FutureLink/SPM/E2-6.svg";
import E2Q7 from "../../../../assets/images/FutureLink/SPM/E2-7.svg";
import E2Q8 from "../../../../assets/images/FutureLink/SPM/E2-8.svg";
import E3 from "../../../../assets/images/FutureLink/SPM/E3-Q.svg";
import E3Q1 from "../../../../assets/images/FutureLink/SPM/E3-1.svg";
import E3Q2 from "../../../../assets/images/FutureLink/SPM/E3-2.svg";
import E3Q3 from "../../../../assets/images/FutureLink/SPM/E3-3.svg";
import E3Q4 from "../../../../assets/images/FutureLink/SPM/E3-4.svg";
import E3Q5 from "../../../../assets/images/FutureLink/SPM/E3-5.svg";
import E3Q6 from "../../../../assets/images/FutureLink/SPM/E3-6.svg";
import E3Q7 from "../../../../assets/images/FutureLink/SPM/E3-7.svg";
import E3Q8 from "../../../../assets/images/FutureLink/SPM/E3-8.svg";
import E4 from "../../../../assets/images/FutureLink/SPM/E4-Q.svg";
import E4Q1 from "../../../../assets/images/FutureLink/SPM/E4-1.svg";
import E4Q2 from "../../../../assets/images/FutureLink/SPM/E4-2.svg";
import E4Q3 from "../../../../assets/images/FutureLink/SPM/E4-3.svg";
import E4Q4 from "../../../../assets/images/FutureLink/SPM/E4-4.svg";
import E4Q5 from "../../../../assets/images/FutureLink/SPM/E4-5.svg";
import E4Q6 from "../../../../assets/images/FutureLink/SPM/E4-6.svg";
import E4Q7 from "../../../../assets/images/FutureLink/SPM/E4-7.svg";
import E4Q8 from "../../../../assets/images/FutureLink/SPM/E4-8.svg";
import E5 from "../../../../assets/images/FutureLink/SPM/E5-Q.svg";
import E5Q1 from "../../../../assets/images/FutureLink/SPM/E5-1.svg";
import E5Q2 from "../../../../assets/images/FutureLink/SPM/E5-2.svg";
import E5Q3 from "../../../../assets/images/FutureLink/SPM/E5-3.svg";
import E5Q4 from "../../../../assets/images/FutureLink/SPM/E5-4.svg";
import E5Q5 from "../../../../assets/images/FutureLink/SPM/E5-5.svg";
import E5Q6 from "../../../../assets/images/FutureLink/SPM/E5-6.svg";
import E5Q7 from "../../../../assets/images/FutureLink/SPM/E5-7.svg";
import E5Q8 from "../../../../assets/images/FutureLink/SPM/E5-8.svg";
import E6 from "../../../../assets/images/FutureLink/SPM/E6-Q.svg";
import E6Q1 from "../../../../assets/images/FutureLink/SPM/E6-1.svg";
import E6Q2 from "../../../../assets/images/FutureLink/SPM/E6-2.svg";
import E6Q3 from "../../../../assets/images/FutureLink/SPM/E6-3.svg";
import E6Q4 from "../../../../assets/images/FutureLink/SPM/E6-4.svg";
import E6Q5 from "../../../../assets/images/FutureLink/SPM/E6-5.svg";
import E6Q6 from "../../../../assets/images/FutureLink/SPM/E6-6.svg";
import E6Q7 from "../../../../assets/images/FutureLink/SPM/E6-7.svg";
import E6Q8 from "../../../../assets/images/FutureLink/SPM/E6-8.svg";
import E7 from "../../../../assets/images/FutureLink/SPM/E7-Q.svg";
import E7Q1 from "../../../../assets/images/FutureLink/SPM/E7-1.svg";
import E7Q2 from "../../../../assets/images/FutureLink/SPM/E7-2.svg";
import E7Q3 from "../../../../assets/images/FutureLink/SPM/E7-3.svg";
import E7Q4 from "../../../../assets/images/FutureLink/SPM/E7-4.svg";
import E7Q5 from "../../../../assets/images/FutureLink/SPM/E7-5.svg";
import E7Q6 from "../../../../assets/images/FutureLink/SPM/E7-6.svg";
import E7Q7 from "../../../../assets/images/FutureLink/SPM/E7-7.svg";
import E7Q8 from "../../../../assets/images/FutureLink/SPM/E7-8.svg";
import E8 from "../../../../assets/images/FutureLink/SPM/E8-Q.svg";
import E8Q1 from "../../../../assets/images/FutureLink/SPM/E8-1.svg";
import E8Q2 from "../../../../assets/images/FutureLink/SPM/E8-2.svg";
import E8Q3 from "../../../../assets/images/FutureLink/SPM/E8-3.svg";
import E8Q4 from "../../../../assets/images/FutureLink/SPM/E8-4.svg";
import E8Q5 from "../../../../assets/images/FutureLink/SPM/E8-5.svg";
import E8Q6 from "../../../../assets/images/FutureLink/SPM/E8-6.svg";
import E8Q7 from "../../../../assets/images/FutureLink/SPM/E8-7.svg";
import E8Q8 from "../../../../assets/images/FutureLink/SPM/E8-8.svg";
import E9 from "../../../../assets/images/FutureLink/SPM/E9-Q.svg";
import E9Q1 from "../../../../assets/images/FutureLink/SPM/E9-1.svg";
import E9Q2 from "../../../../assets/images/FutureLink/SPM/E9-2.svg";
import E9Q3 from "../../../../assets/images/FutureLink/SPM/E9-3.svg";
import E9Q4 from "../../../../assets/images/FutureLink/SPM/E9-4.svg";
import E9Q5 from "../../../../assets/images/FutureLink/SPM/E9-5.svg";
import E9Q6 from "../../../../assets/images/FutureLink/SPM/E9-6.svg";
import E9Q7 from "../../../../assets/images/FutureLink/SPM/E9-7.svg";
import E9Q8 from "../../../../assets/images/FutureLink/SPM/E9-8.svg";
import E10 from "../../../../assets/images/FutureLink/SPM/E10-Q.svg";
import E10Q1 from "../../../../assets/images/FutureLink/SPM/E10-1.svg";
import E10Q2 from "../../../../assets/images/FutureLink/SPM/E10-2.svg";
import E10Q3 from "../../../../assets/images/FutureLink/SPM/E10-3.svg";
import E10Q4 from "../../../../assets/images/FutureLink/SPM/E10-4.svg";
import E10Q5 from "../../../../assets/images/FutureLink/SPM/E10-5.svg";
import E10Q6 from "../../../../assets/images/FutureLink/SPM/E10-6.svg";
import E10Q7 from "../../../../assets/images/FutureLink/SPM/E10-7.svg";
import E10Q8 from "../../../../assets/images/FutureLink/SPM/E10-8.svg";
import E11 from "../../../../assets/images/FutureLink/SPM/E11-Q.svg";
import E11Q1 from "../../../../assets/images/FutureLink/SPM/E11-1.svg";
import E11Q2 from "../../../../assets/images/FutureLink/SPM/E11-2.svg";
import E11Q3 from "../../../../assets/images/FutureLink/SPM/E11-3.svg";
import E11Q4 from "../../../../assets/images/FutureLink/SPM/E11-4.svg";
import E11Q5 from "../../../../assets/images/FutureLink/SPM/E11-5.svg";
import E11Q6 from "../../../../assets/images/FutureLink/SPM/E11-6.svg";
import E11Q7 from "../../../../assets/images/FutureLink/SPM/E11-7.svg";
import E11Q8 from "../../../../assets/images/FutureLink/SPM/E11-8.svg";
import E12 from "../../../../assets/images/FutureLink/SPM/E12-Q.svg";
import E12Q1 from "../../../../assets/images/FutureLink/SPM/E12-1.svg";
import E12Q2 from "../../../../assets/images/FutureLink/SPM/E12-2.svg";
import E12Q3 from "../../../../assets/images/FutureLink/SPM/E12-3.svg";
import E12Q4 from "../../../../assets/images/FutureLink/SPM/E12-4.svg";
import E12Q5 from "../../../../assets/images/FutureLink/SPM/E12-5.svg";
import E12Q6 from "../../../../assets/images/FutureLink/SPM/E12-6.svg";
import E12Q7 from "../../../../assets/images/FutureLink/SPM/E12-7.svg";
import E12Q8 from "../../../../assets/images/FutureLink/SPM/E12-8.svg";

import end from "../../../../assets/images/FutureLink/selesaiTest.png";

const SoalTrialNugi = () => {
  const [halaman, setHalaman] = useState("home");
  const [nomor, setNomor] = useState(1);
  const [sembunyi, setSembunyi] = useState("");
  const [ja1, setJA1] = useState(0);
  const [ja2, setJA2] = useState(0);
  const [ja3, setJA3] = useState(0);
  const [ja4, setJA4] = useState(0);
  const [ja5, setJA5] = useState(0);
  const [ja6, setJA6] = useState(0);
  const [ja7, setJA7] = useState(0);
  const [ja8, setJA8] = useState(0);
  const [ja9, setJA9] = useState(0);
  const [ja10, setJA10] = useState(0);
  const [ja11, setJA11] = useState(0);
  const [ja12, setJA12] = useState(0);
  const [ca11, setCa11] = useState(false);
  const [ca12, setCa12] = useState(false);
  const [ca13, setCa13] = useState(false);
  const [ca14, setCa14] = useState(false);
  const [ca15, setCa15] = useState(false);
  const [ca16, setCa16] = useState(false);
  const [ca21, setCa21] = useState(false);
  const [ca22, setCa22] = useState(false);
  const [ca23, setCa23] = useState(false);
  const [ca24, setCa24] = useState(false);
  const [ca25, setCa25] = useState(false);
  const [ca26, setCa26] = useState(false);
  const [ca31, setCa31] = useState(false);
  const [ca32, setCa32] = useState(false);
  const [ca33, setCa33] = useState(false);
  const [ca34, setCa34] = useState(false);
  const [ca35, setCa35] = useState(false);
  const [ca36, setCa36] = useState(false);
  const [ca41, setCa41] = useState(false);
  const [ca42, setCa42] = useState(false);
  const [ca43, setCa43] = useState(false);
  const [ca44, setCa44] = useState(false);
  const [ca45, setCa45] = useState(false);
  const [ca46, setCa46] = useState(false);
  const [ca51, setCa51] = useState(false);
  const [ca52, setCa52] = useState(false);
  const [ca53, setCa53] = useState(false);
  const [ca54, setCa54] = useState(false);
  const [ca55, setCa55] = useState(false);
  const [ca56, setCa56] = useState(false);
  const [ca61, setCa61] = useState(false);
  const [ca62, setCa62] = useState(false);
  const [ca63, setCa63] = useState(false);
  const [ca64, setCa64] = useState(false);
  const [ca65, setCa65] = useState(false);
  const [ca66, setCa66] = useState(false);
  const [ca71, setCa71] = useState(false);
  const [ca72, setCa72] = useState(false);
  const [ca73, setCa73] = useState(false);
  const [ca74, setCa74] = useState(false);
  const [ca75, setCa75] = useState(false);
  const [ca76, setCa76] = useState(false);
  const [ca81, setCa81] = useState(false);
  const [ca82, setCa82] = useState(false);
  const [ca83, setCa83] = useState(false);
  const [ca84, setCa84] = useState(false);
  const [ca85, setCa85] = useState(false);
  const [ca86, setCa86] = useState(false);
  const [ca91, setCa91] = useState(false);
  const [ca92, setCa92] = useState(false);
  const [ca93, setCa93] = useState(false);
  const [ca94, setCa94] = useState(false);
  const [ca95, setCa95] = useState(false);
  const [ca96, setCa96] = useState(false);
  const [ca101, setCa101] = useState(false);
  const [ca102, setCa102] = useState(false);
  const [ca103, setCa103] = useState(false);
  const [ca104, setCa104] = useState(false);
  const [ca105, setCa105] = useState(false);
  const [ca106, setCa106] = useState(false);
  const [ca111, setCa111] = useState(false);
  const [ca112, setCa112] = useState(false);
  const [ca113, setCa113] = useState(false);
  const [ca114, setCa114] = useState(false);
  const [ca115, setCa115] = useState(false);
  const [ca116, setCa116] = useState(false);
  const [ca121, setCa121] = useState(false);
  const [ca122, setCa122] = useState(false);
  const [ca123, setCa123] = useState(false);
  const [ca124, setCa124] = useState(false);
  const [ca125, setCa125] = useState(false);
  const [ca126, setCa126] = useState(false);

  const [jb1, setJB1] = useState(0);
  const [jb2, setJB2] = useState(0);
  const [jb3, setJB3] = useState(0);
  const [jb4, setJB4] = useState(0);
  const [jb5, setJB5] = useState(0);
  const [jb6, setJB6] = useState(0);
  const [jb7, setJB7] = useState(0);
  const [jb8, setJB8] = useState(0);
  const [jb9, setJB9] = useState(0);
  const [jb10, setJB10] = useState(0);
  const [jb11, setJB11] = useState(0);
  const [jb12, setJB12] = useState(0);
  const [cb11, setCb11] = useState(false);
  const [cb12, setCb12] = useState(false);
  const [cb13, setCb13] = useState(false);
  const [cb14, setCb14] = useState(false);
  const [cb15, setCb15] = useState(false);
  const [cb16, setCb16] = useState(false);
  const [cb21, setCb21] = useState(false);
  const [cb22, setCb22] = useState(false);
  const [cb23, setCb23] = useState(false);
  const [cb24, setCb24] = useState(false);
  const [cb25, setCb25] = useState(false);
  const [cb26, setCb26] = useState(false);
  const [cb31, setCb31] = useState(false);
  const [cb32, setCb32] = useState(false);
  const [cb33, setCb33] = useState(false);
  const [cb34, setCb34] = useState(false);
  const [cb35, setCb35] = useState(false);
  const [cb36, setCb36] = useState(false);
  const [cb41, setCb41] = useState(false);
  const [cb42, setCb42] = useState(false);
  const [cb43, setCb43] = useState(false);
  const [cb44, setCb44] = useState(false);
  const [cb45, setCb45] = useState(false);
  const [cb46, setCb46] = useState(false);
  const [cb51, setCb51] = useState(false);
  const [cb52, setCb52] = useState(false);
  const [cb53, setCb53] = useState(false);
  const [cb54, setCb54] = useState(false);
  const [cb55, setCb55] = useState(false);
  const [cb56, setCb56] = useState(false);
  const [cb61, setCb61] = useState(false);
  const [cb62, setCb62] = useState(false);
  const [cb63, setCb63] = useState(false);
  const [cb64, setCb64] = useState(false);
  const [cb65, setCb65] = useState(false);
  const [cb66, setCb66] = useState(false);
  const [cb71, setCb71] = useState(false);
  const [cb72, setCb72] = useState(false);
  const [cb73, setCb73] = useState(false);
  const [cb74, setCb74] = useState(false);
  const [cb75, setCb75] = useState(false);
  const [cb76, setCb76] = useState(false);
  const [cb81, setCb81] = useState(false);
  const [cb82, setCb82] = useState(false);
  const [cb83, setCb83] = useState(false);
  const [cb84, setCb84] = useState(false);
  const [cb85, setCb85] = useState(false);
  const [cb86, setCb86] = useState(false);
  const [cb91, setCb91] = useState(false);
  const [cb92, setCb92] = useState(false);
  const [cb93, setCb93] = useState(false);
  const [cb94, setCb94] = useState(false);
  const [cb95, setCb95] = useState(false);
  const [cb96, setCb96] = useState(false);
  const [cb101, setCb101] = useState(false);
  const [cb102, setCb102] = useState(false);
  const [cb103, setCb103] = useState(false);
  const [cb104, setCb104] = useState(false);
  const [cb105, setCb105] = useState(false);
  const [cb106, setCb106] = useState(false);
  const [cb111, setCb111] = useState(false);
  const [cb112, setCb112] = useState(false);
  const [cb113, setCb113] = useState(false);
  const [cb114, setCb114] = useState(false);
  const [cb115, setCb115] = useState(false);
  const [cb116, setCb116] = useState(false);
  const [cb121, setCb121] = useState(false);
  const [cb122, setCb122] = useState(false);
  const [cb123, setCb123] = useState(false);
  const [cb124, setCb124] = useState(false);
  const [cb125, setCb125] = useState(false);
  const [cb126, setCb126] = useState(false);

  const [jc1, setJC1] = useState(0);
  const [jc2, setJC2] = useState(0);
  const [jc3, setJC3] = useState(0);
  const [jc4, setJC4] = useState(0);
  const [jc5, setJC5] = useState(0);
  const [jc6, setJC6] = useState(0);
  const [jc7, setJC7] = useState(0);
  const [jc8, setJC8] = useState(0);
  const [jc9, setJC9] = useState(0);
  const [jc10, setJC10] = useState(0);
  const [jc11, setJC11] = useState(0);
  const [jc12, setJC12] = useState(0);
  const [cc11, setCc11] = useState(false);
  const [cc12, setCc12] = useState(false);
  const [cc13, setCc13] = useState(false);
  const [cc14, setCc14] = useState(false);
  const [cc15, setCc15] = useState(false);
  const [cc16, setCc16] = useState(false);
  const [cc17, setCc17] = useState(false);
  const [cc18, setCc18] = useState(false);
  const [cc21, setCc21] = useState(false);
  const [cc22, setCc22] = useState(false);
  const [cc23, setCc23] = useState(false);
  const [cc24, setCc24] = useState(false);
  const [cc25, setCc25] = useState(false);
  const [cc26, setCc26] = useState(false);
  const [cc27, setCc27] = useState(false);
  const [cc28, setCc28] = useState(false);
  const [cc31, setCc31] = useState(false);
  const [cc32, setCc32] = useState(false);
  const [cc33, setCc33] = useState(false);
  const [cc34, setCc34] = useState(false);
  const [cc35, setCc35] = useState(false);
  const [cc36, setCc36] = useState(false);
  const [cc37, setCc37] = useState(false);
  const [cc38, setCc38] = useState(false);
  const [cc41, setCc41] = useState(false);
  const [cc42, setCc42] = useState(false);
  const [cc43, setCc43] = useState(false);
  const [cc44, setCc44] = useState(false);
  const [cc45, setCc45] = useState(false);
  const [cc46, setCc46] = useState(false);
  const [cc47, setCc47] = useState(false);
  const [cc48, setCc48] = useState(false);
  const [cc51, setCc51] = useState(false);
  const [cc52, setCc52] = useState(false);
  const [cc53, setCc53] = useState(false);
  const [cc54, setCc54] = useState(false);
  const [cc55, setCc55] = useState(false);
  const [cc56, setCc56] = useState(false);
  const [cc57, setCc57] = useState(false);
  const [cc58, setCc58] = useState(false);
  const [cc61, setCc61] = useState(false);
  const [cc62, setCc62] = useState(false);
  const [cc63, setCc63] = useState(false);
  const [cc64, setCc64] = useState(false);
  const [cc65, setCc65] = useState(false);
  const [cc66, setCc66] = useState(false);
  const [cc67, setCc67] = useState(false);
  const [cc68, setCc68] = useState(false);
  const [cc71, setCc71] = useState(false);
  const [cc72, setCc72] = useState(false);
  const [cc73, setCc73] = useState(false);
  const [cc74, setCc74] = useState(false);
  const [cc75, setCc75] = useState(false);
  const [cc76, setCc76] = useState(false);
  const [cc77, setCc77] = useState(false);
  const [cc78, setCc78] = useState(false);
  const [cc81, setCc81] = useState(false);
  const [cc82, setCc82] = useState(false);
  const [cc83, setCc83] = useState(false);
  const [cc84, setCc84] = useState(false);
  const [cc85, setCc85] = useState(false);
  const [cc86, setCc86] = useState(false);
  const [cc87, setCc87] = useState(false);
  const [cc88, setCc88] = useState(false);
  const [cc91, setCc91] = useState(false);
  const [cc92, setCc92] = useState(false);
  const [cc93, setCc93] = useState(false);
  const [cc94, setCc94] = useState(false);
  const [cc95, setCc95] = useState(false);
  const [cc96, setCc96] = useState(false);
  const [cc97, setCc97] = useState(false);
  const [cc98, setCc98] = useState(false);
  const [cc101, setCc101] = useState(false);
  const [cc102, setCc102] = useState(false);
  const [cc103, setCc103] = useState(false);
  const [cc104, setCc104] = useState(false);
  const [cc105, setCc105] = useState(false);
  const [cc106, setCc106] = useState(false);
  const [cc107, setCc107] = useState(false);
  const [cc108, setCc108] = useState(false);
  const [cc111, setCc111] = useState(false);
  const [cc112, setCc112] = useState(false);
  const [cc113, setCc113] = useState(false);
  const [cc114, setCc114] = useState(false);
  const [cc115, setCc115] = useState(false);
  const [cc116, setCc116] = useState(false);
  const [cc117, setCc117] = useState(false);
  const [cc118, setCc118] = useState(false);
  const [cc121, setCc121] = useState(false);
  const [cc122, setCc122] = useState(false);
  const [cc123, setCc123] = useState(false);
  const [cc124, setCc124] = useState(false);
  const [cc125, setCc125] = useState(false);
  const [cc126, setCc126] = useState(false);
  const [cc127, setCc127] = useState(false);
  const [cc128, setCc128] = useState(false);

  const [jd1, setJD1] = useState(0);
  const [jd2, setJD2] = useState(0);
  const [jd3, setJD3] = useState(0);
  const [jd4, setJD4] = useState(0);
  const [jd5, setJD5] = useState(0);
  const [jd6, setJD6] = useState(0);
  const [jd7, setJD7] = useState(0);
  const [jd8, setJD8] = useState(0);
  const [jd9, setJD9] = useState(0);
  const [jd10, setJD10] = useState(0);
  const [jd11, setJD11] = useState(0);
  const [jd12, setJD12] = useState(0);
  const [cd11, setCd11] = useState(false);
  const [cd12, setCd12] = useState(false);
  const [cd13, setCd13] = useState(false);
  const [cd14, setCd14] = useState(false);
  const [cd15, setCd15] = useState(false);
  const [cd16, setCd16] = useState(false);
  const [cd17, setCd17] = useState(false);
  const [cd18, setCd18] = useState(false);
  const [cd21, setCd21] = useState(false);
  const [cd22, setCd22] = useState(false);
  const [cd23, setCd23] = useState(false);
  const [cd24, setCd24] = useState(false);
  const [cd25, setCd25] = useState(false);
  const [cd26, setCd26] = useState(false);
  const [cd27, setCd27] = useState(false);
  const [cd28, setCd28] = useState(false);
  const [cd31, setCd31] = useState(false);
  const [cd32, setCd32] = useState(false);
  const [cd33, setCd33] = useState(false);
  const [cd34, setCd34] = useState(false);
  const [cd35, setCd35] = useState(false);
  const [cd36, setCd36] = useState(false);
  const [cd37, setCd37] = useState(false);
  const [cd38, setCd38] = useState(false);
  const [cd41, setCd41] = useState(false);
  const [cd42, setCd42] = useState(false);
  const [cd43, setCd43] = useState(false);
  const [cd44, setCd44] = useState(false);
  const [cd45, setCd45] = useState(false);
  const [cd46, setCd46] = useState(false);
  const [cd47, setCd47] = useState(false);
  const [cd48, setCd48] = useState(false);
  const [cd51, setCd51] = useState(false);
  const [cd52, setCd52] = useState(false);
  const [cd53, setCd53] = useState(false);
  const [cd54, setCd54] = useState(false);
  const [cd55, setCd55] = useState(false);
  const [cd56, setCd56] = useState(false);
  const [cd57, setCd57] = useState(false);
  const [cd58, setCd58] = useState(false);
  const [cd61, setCd61] = useState(false);
  const [cd62, setCd62] = useState(false);
  const [cd63, setCd63] = useState(false);
  const [cd64, setCd64] = useState(false);
  const [cd65, setCd65] = useState(false);
  const [cd66, setCd66] = useState(false);
  const [cd67, setCd67] = useState(false);
  const [cd68, setCd68] = useState(false);
  const [cd71, setCd71] = useState(false);
  const [cd72, setCd72] = useState(false);
  const [cd73, setCd73] = useState(false);
  const [cd74, setCd74] = useState(false);
  const [cd75, setCd75] = useState(false);
  const [cd76, setCd76] = useState(false);
  const [cd77, setCd77] = useState(false);
  const [cd78, setCd78] = useState(false);
  const [cd81, setCd81] = useState(false);
  const [cd82, setCd82] = useState(false);
  const [cd83, setCd83] = useState(false);
  const [cd84, setCd84] = useState(false);
  const [cd85, setCd85] = useState(false);
  const [cd86, setCd86] = useState(false);
  const [cd87, setCd87] = useState(false);
  const [cd88, setCd88] = useState(false);
  const [cd91, setCd91] = useState(false);
  const [cd92, setCd92] = useState(false);
  const [cd93, setCd93] = useState(false);
  const [cd94, setCd94] = useState(false);
  const [cd95, setCd95] = useState(false);
  const [cd96, setCd96] = useState(false);
  const [cd97, setCd97] = useState(false);
  const [cd98, setCd98] = useState(false);
  const [cd101, setCd101] = useState(false);
  const [cd102, setCd102] = useState(false);
  const [cd103, setCd103] = useState(false);
  const [cd104, setCd104] = useState(false);
  const [cd105, setCd105] = useState(false);
  const [cd106, setCd106] = useState(false);
  const [cd107, setCd107] = useState(false);
  const [cd108, setCd108] = useState(false);
  const [cd111, setCd111] = useState(false);
  const [cd112, setCd112] = useState(false);
  const [cd113, setCd113] = useState(false);
  const [cd114, setCd114] = useState(false);
  const [cd115, setCd115] = useState(false);
  const [cd116, setCd116] = useState(false);
  const [cd117, setCd117] = useState(false);
  const [cd118, setCd118] = useState(false);
  const [cd121, setCd121] = useState(false);
  const [cd122, setCd122] = useState(false);
  const [cd123, setCd123] = useState(false);
  const [cd124, setCd124] = useState(false);
  const [cd125, setCd125] = useState(false);
  const [cd126, setCd126] = useState(false);
  const [cd127, setCd127] = useState(false);
  const [cd128, setCd128] = useState(false);

  const [je1, setJE1] = useState(0);
  const [je2, setJE2] = useState(0);
  const [je3, setJE3] = useState(0);
  const [je4, setJE4] = useState(0);
  const [je5, setJE5] = useState(0);
  const [je6, setJE6] = useState(0);
  const [je7, setJE7] = useState(0);
  const [je8, setJE8] = useState(0);
  const [je9, setJE9] = useState(0);
  const [je10, setJE10] = useState(0);
  const [je11, setJE11] = useState(0);
  const [je12, setJE12] = useState(0);
  const [ce11, setCe11] = useState(false);
  const [ce12, setCe12] = useState(false);
  const [ce13, setCe13] = useState(false);
  const [ce14, setCe14] = useState(false);
  const [ce15, setCe15] = useState(false);
  const [ce16, setCe16] = useState(false);
  const [ce17, setCe17] = useState(false);
  const [ce18, setCe18] = useState(false);
  const [ce21, setCe21] = useState(false);
  const [ce22, setCe22] = useState(false);
  const [ce23, setCe23] = useState(false);
  const [ce24, setCe24] = useState(false);
  const [ce25, setCe25] = useState(false);
  const [ce26, setCe26] = useState(false);
  const [ce27, setCe27] = useState(false);
  const [ce28, setCe28] = useState(false);
  const [ce31, setCe31] = useState(false);
  const [ce32, setCe32] = useState(false);
  const [ce33, setCe33] = useState(false);
  const [ce34, setCe34] = useState(false);
  const [ce35, setCe35] = useState(false);
  const [ce36, setCe36] = useState(false);
  const [ce37, setCe37] = useState(false);
  const [ce38, setCe38] = useState(false);
  const [ce41, setCe41] = useState(false);
  const [ce42, setCe42] = useState(false);
  const [ce43, setCe43] = useState(false);
  const [ce44, setCe44] = useState(false);
  const [ce45, setCe45] = useState(false);
  const [ce46, setCe46] = useState(false);
  const [ce47, setCe47] = useState(false);
  const [ce48, setCe48] = useState(false);
  const [ce51, setCe51] = useState(false);
  const [ce52, setCe52] = useState(false);
  const [ce53, setCe53] = useState(false);
  const [ce54, setCe54] = useState(false);
  const [ce55, setCe55] = useState(false);
  const [ce56, setCe56] = useState(false);
  const [ce57, setCe57] = useState(false);
  const [ce58, setCe58] = useState(false);
  const [ce61, setCe61] = useState(false);
  const [ce62, setCe62] = useState(false);
  const [ce63, setCe63] = useState(false);
  const [ce64, setCe64] = useState(false);
  const [ce65, setCe65] = useState(false);
  const [ce66, setCe66] = useState(false);
  const [ce67, setCe67] = useState(false);
  const [ce68, setCe68] = useState(false);
  const [ce71, setCe71] = useState(false);
  const [ce72, setCe72] = useState(false);
  const [ce73, setCe73] = useState(false);
  const [ce74, setCe74] = useState(false);
  const [ce75, setCe75] = useState(false);
  const [ce76, setCe76] = useState(false);
  const [ce77, setCe77] = useState(false);
  const [ce78, setCe78] = useState(false);
  const [ce81, setCe81] = useState(false);
  const [ce82, setCe82] = useState(false);
  const [ce83, setCe83] = useState(false);
  const [ce84, setCe84] = useState(false);
  const [ce85, setCe85] = useState(false);
  const [ce86, setCe86] = useState(false);
  const [ce87, setCe87] = useState(false);
  const [ce88, setCe88] = useState(false);
  const [ce91, setCe91] = useState(false);
  const [ce92, setCe92] = useState(false);
  const [ce93, setCe93] = useState(false);
  const [ce94, setCe94] = useState(false);
  const [ce95, setCe95] = useState(false);
  const [ce96, setCe96] = useState(false);
  const [ce97, setCe97] = useState(false);
  const [ce98, setCe98] = useState(false);
  const [ce101, setCe101] = useState(false);
  const [ce102, setCe102] = useState(false);
  const [ce103, setCe103] = useState(false);
  const [ce104, setCe104] = useState(false);
  const [ce105, setCe105] = useState(false);
  const [ce106, setCe106] = useState(false);
  const [ce107, setCe107] = useState(false);
  const [ce108, setCe108] = useState(false);
  const [ce111, setCe111] = useState(false);
  const [ce112, setCe112] = useState(false);
  const [ce113, setCe113] = useState(false);
  const [ce114, setCe114] = useState(false);
  const [ce115, setCe115] = useState(false);
  const [ce116, setCe116] = useState(false);
  const [ce117, setCe117] = useState(false);
  const [ce118, setCe118] = useState(false);
  const [ce121, setCe121] = useState(false);
  const [ce122, setCe122] = useState(false);
  const [ce123, setCe123] = useState(false);
  const [ce124, setCe124] = useState(false);
  const [ce125, setCe125] = useState(false);
  const [ce126, setCe126] = useState(false);
  const [ce127, setCe127] = useState(false);
  const [ce128, setCe128] = useState(false);

  const [typeNow, setTypeNow] = useState("A");

  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  const [count, setCount] = useState(1799);
  const [benar, setBenar] = useState(false);
  const [berjalan, setBerjalan] = useState("jam");

  const hitMe = () => {
    console.log([
      ja1,
      ja2,
      ja3,
      ja4,
      ja5,
      ja6,
      ja7,
      ja8,
      ja9,
      ja10,
      ja11,
      ja12,
      jb1,
      jb2,
      jb3,
      jb4,
      jb5,
      jb6,
      jb7,
      jb8,
      jb9,
      jb10,
      jb11,
      jb12,
      jc1,
      jc2,
      jc3,
      jc4,
      jc5,
      jc6,
      jc7,
      jc8,
      jc9,
      jc10,
      jc11,
      jc12,
      jd1,
      jd2,
      jd3,
      jd4,
      jd5,
      jd6,
      jd7,
      jd8,
      jd9,
      jd10,
      jd11,
      jd12,
      je1,
      je2,
      je3,
      je4,
      je5,
      je6,
      je7,
      je8,
      je9,
      je10,
      je11,
      je12,
    ]);
  };

  useInterval(
    () => {
      setCount(count - 1);
      setMin(Math.floor((count / 60) % 60));
      setSec(Math.floor(count % 60));

      if (count < 61) {
        setBerjalan("jam redTime");
      }

      if (count == -1) {
        setBenar(false);
        setNomor(1);
        setHalaman("selesai");
        hitMe();
        setCount(1799);
      }
    },
    benar ? 1000 : null
  );

  useEffect(() => {
    if (nomor == 1) {
      setSembunyi("trans");
    } else {
      setSembunyi("");
    }
    console.log("type", typeNow);
  }, [nomor]);

  // const getAllTestInfo = (cs, id = 0) => {
  //   let requestValidation = true;
  //   if (cs == modulTestState.section) {
  //     if (!currentTest.hasSection) requestValidation = false;
  //   }

  //   let urlDetail =
  //     cs == modulTestState.test
  //       ? "/v1/get-all-test"
  //       : cs == modulTestState.question
  //       ? "/v1/get-test-question-and-answer"
  //       : cs == modulTestState.answer
  //       ? "/v1/get-test-answer"
  //       : cs == modulTestState.section
  //       ? "/v1/get-all-test-section"
  //       : cs == modulTestState.subsection
  //       ? "/v1/get-all-test-subsection"
  //       : "";

  //   let urlPayload =
  //     cs == modulTestState.test
  //       ? { orderBy: "DESC" }
  //       : cs == modulTestState.answer
  //       ? { qId: id }
  //       : cs == modulTestState.subsection
  //       ? { testSectionId: id }
  //       : {
  //           testId: id,
  //         };

  //   let config = {
  //     method: "get",
  //     maxBodyLength: Infinity,
  //     url: `${process.env.REACT_APP_BASE_ENDPOINT_BACKEND}${urlDetail}`,
  //     headers: {
  //       Authorization: `Bearer ${code}`,
  //     },
  //     params: urlPayload,
  //   };

  //   requestValidation
  //     ? axios
  //         .request(config)
  //         .then((response) => {
  //           setCurrentState(cs);
  //           cs == modulTestState.test
  //             ? setTests(
  //                 response.results.map((item, index) => {
  //                   return {
  //                     testId: item.test_id,
  //                     name: item.name,
  //                     duration: item.duration,
  //                     hasSection: Boolean(parseInt(item.has_section)),
  //                     status: item.status,
  //                     price: item.price,
  //                   };
  //                 })
  //               )
  //             : cs == modulTestState.question
  //             ? setQuestions(
  //                 response.results.map((item, index) => {
  //                   return {
  //                     qId: item.q_id,
  //                     aId: item.a_id,
  //                     questionText: item.question_text,
  //                     questionImage: item.question_image,
  //                     questionType: item.type,
  //                     arrangement: item.arrangement,
  //                   };
  //                 })
  //               )
  //             : cs == modulTestState.answer
  //             ? setAnswers(
  //                 response.results.map((item, index) => {
  //                   return {
  //                     qId: item.q_id,
  //                     aId: item.a_id,
  //                     answerText: item.answer_text,
  //                     answerImage: item.answer_image,
  //                     point: item.point,
  //                   };
  //                 })
  //               )
  //             : cs == modulTestState.section
  //             ? setSections(
  //                 response.results.map((item, index) => {
  //                   return {
  //                     testSectionId: item.test_section_id,
  //                     name: item.name,
  //                     duration: item.duration,
  //                     arrangement: item.arrangement,
  //                   };
  //                 })
  //               )
  //             : cs == modulTestState.subsection
  //             ? setSubsections(
  //                 response.results.map((item, index) => {
  //                   return {
  //                     testSubsectionId: item.test_subsection_id,
  //                     name: item.name,
  //                     duration: item.duration,
  //                     arrangement: item.arrangement,
  //                   };
  //                 })
  //               )
  //             : null;
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //           setAlert(true);
  //           // if (error.response.data.statusCode === 403) {
  //           //   setModalAccessDenied(true);
  //           // }
  //         })
  //     : setAlert(true);
  // };

  const RenderQuestionAndAnswer = (
    questionText = "",
    questionImage = "",
    answerList
  ) => {
    return (
      <>
        <img src={A1} className="fotoSoal" />

        <div className="listJawaban">
          <div className={ca11 ? "jawaban click" : "jawaban"}>
            <input
              type="radio"
              id="j11"
              name="1"
              value={1}
              onChange={(event) => {
                setJA1(event.target.value);
                setCa11(event.target.checked);
                setCa12(false);
                setCa13(false);
                setCa14(false);
                setCa15(false);
                setCa16(false);
              }}
            />
            <label htmlFor="j11">
              <img src={A1Q1} />
            </label>
          </div>
          <div className={ca12 ? "jawaban click" : "jawaban"}>
            <input
              type="radio"
              id="j12"
              name="1"
              value={2}
              onChange={(event) => {
                setJA1(event.target.value);
                setCa12(event.target.checked);
                setCa11(false);
                setCa13(false);
                setCa14(false);
                setCa15(false);
                setCa16(false);
              }}
            />
            <label htmlFor="j12">
              <img src={A1Q2} />
            </label>
          </div>
          <div className={ca13 ? "jawaban click" : "jawaban"}>
            <input
              type="radio"
              id="j13"
              name="1"
              value={3}
              onChange={(event) => {
                setJA1(event.target.value);
                setCa13(event.target.checked);
                setCa12(false);
                setCa11(false);
                setCa14(false);
                setCa15(false);
                setCa16(false);
              }}
            />
            <label htmlFor="j13">
              <img src={A1Q3} />
            </label>
          </div>
          <div className={ca14 ? "jawaban click" : "jawaban"}>
            <input
              type="radio"
              id="j14"
              name="1"
              value={4}
              onChange={(event) => {
                setJA1(event.target.value);
                setCa14(event.target.checked);
                setCa12(false);
                setCa13(false);
                setCa11(false);
                setCa15(false);
                setCa16(false);
              }}
            />
            <label htmlFor="j14">
              <img src={A1Q4} />
            </label>
          </div>
          <div className={ca15 ? "jawaban click" : "jawaban"}>
            <input
              type="radio"
              id="j15"
              name="1"
              value={5}
              onChange={(event) => {
                setJA1(event.target.value);
                setCa15(event.target.checked);
                setCa12(false);
                setCa13(false);
                setCa14(false);
                setCa11(false);
                setCa16(false);
              }}
            />
            <label htmlFor="j15">
              <img src={A1Q5} />
            </label>
          </div>
          <div className={ca16 ? "jawaban click" : "jawaban"}>
            <input
              type="radio"
              id="j16"
              name="1"
              value={6}
              onChange={(event) => {
                setJA1(event.target.value);
                setCa16(event.target.checked);
                setCa12(false);
                setCa13(false);
                setCa14(false);
                setCa15(false);
                setCa11(false);
              }}
            />
            <label htmlFor="j16">
              <img src={A1Q6} />
            </label>
          </div>
        </div>
      </>
    );
  };

  function checkNumber(number) {
    if (number == nomor % 12) {
      return "number";
    } else {
      return "inumber";
    }
  }

  return (
    <React.Fragment>
      <div className="tC">
        <div className="tCLeft">
          <div className="tCLeftTop">
            <i className="ri-arrow-left-line fz24"></i>
            <div className="tCLeftTopDesc">
              <p className="tCLeftTopKeterangan">
                Type {typeNow}: <br />
                Basic Spatial
              </p>
              <div className="tCLeftTopList">
                <div className="tCLeftTopLine"></div>
                <div className="tCLeftTopType">
                  {typeNow === "A" ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="10"
                          fill="#FCFCFC"
                          stroke="#10B981"
                          strokeWidth={1.6}
                        />
                        <path
                          d="M12 1.99902C17.524 1.99902 22.002 6.47702 22.002 12.001C22.002 17.524 17.524 22.002 12 22.002C6.47605 22.002 1.99805 17.524 1.99805 12.001C1.99805 6.47702 6.47605 1.99902 12 1.99902ZM12 3.49902C10.8759 3.48683 9.76041 3.69772 8.71827 4.1195C7.67612 4.54127 6.72798 5.16554 5.92871 5.95619C5.12944 6.74684 4.49492 7.68816 4.06187 8.72566C3.62882 9.76317 3.40584 10.8763 3.40584 12.0005C3.40584 13.1248 3.62882 14.2379 4.06187 15.2754C4.49492 16.3129 5.12944 17.2542 5.92871 18.0449C6.72798 18.8355 7.67612 19.4598 8.71827 19.8816C9.76041 20.3033 10.8759 20.5142 12 20.502C14.2321 20.4678 16.3611 19.5571 17.9274 17.9666C19.4937 16.376 20.3717 14.2333 20.3717 12.001C20.3717 9.76874 19.4937 7.626 17.9274 6.03548C16.3611 4.44496 14.2321 3.53326 12 3.49902ZM11.997 5.99902C13.5878 5.99902 15.1134 6.63095 16.2383 7.7558C17.3631 8.88064 17.995 10.4063 17.995 11.997C17.995 13.5878 17.3631 15.1134 16.2383 16.2382C15.1134 17.3631 13.5878 17.995 11.997 17.995C10.4063 17.995 8.88066 17.3631 7.75582 16.2382C6.63098 15.1134 5.99905 13.5878 5.99905 11.997C5.99905 10.4063 6.63098 8.88064 7.75582 7.7558C8.88066 6.63095 10.4063 5.99902 11.997 5.99902Z"
                          fill="#10B981"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="9.3"
                          fill="#FCFCFC"
                          stroke="#10B981"
                          strokeWidth={2.5}
                        />
                      </svg>
                    </>
                  )}
                  <p>Type A</p>
                </div>
                <div className="tCLeftTopType">
                  {typeNow === "B" ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="10"
                          fill="#FCFCFC"
                          stroke="#10B981"
                          strokeWidth={1.6}
                        />
                        <path
                          d="M12 1.99902C17.524 1.99902 22.002 6.47702 22.002 12.001C22.002 17.524 17.524 22.002 12 22.002C6.47605 22.002 1.99805 17.524 1.99805 12.001C1.99805 6.47702 6.47605 1.99902 12 1.99902ZM12 3.49902C10.8759 3.48683 9.76041 3.69772 8.71827 4.1195C7.67612 4.54127 6.72798 5.16554 5.92871 5.95619C5.12944 6.74684 4.49492 7.68816 4.06187 8.72566C3.62882 9.76317 3.40584 10.8763 3.40584 12.0005C3.40584 13.1248 3.62882 14.2379 4.06187 15.2754C4.49492 16.3129 5.12944 17.2542 5.92871 18.0449C6.72798 18.8355 7.67612 19.4598 8.71827 19.8816C9.76041 20.3033 10.8759 20.5142 12 20.502C14.2321 20.4678 16.3611 19.5571 17.9274 17.9666C19.4937 16.376 20.3717 14.2333 20.3717 12.001C20.3717 9.76874 19.4937 7.626 17.9274 6.03548C16.3611 4.44496 14.2321 3.53326 12 3.49902ZM11.997 5.99902C13.5878 5.99902 15.1134 6.63095 16.2383 7.7558C17.3631 8.88064 17.995 10.4063 17.995 11.997C17.995 13.5878 17.3631 15.1134 16.2383 16.2382C15.1134 17.3631 13.5878 17.995 11.997 17.995C10.4063 17.995 8.88066 17.3631 7.75582 16.2382C6.63098 15.1134 5.99905 13.5878 5.99905 11.997C5.99905 10.4063 6.63098 8.88064 7.75582 7.7558C8.88066 6.63095 10.4063 5.99902 11.997 5.99902Z"
                          fill="#10B981"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="9.3"
                          fill="#FCFCFC"
                          stroke="#10B981"
                          strokeWidth={2.5}
                        />
                      </svg>
                    </>
                  )}
                  <p>Type B</p>
                </div>
                <div className="tCLeftTopType">
                  {typeNow === "C" ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="10"
                          fill="#FCFCFC"
                          stroke="#10B981"
                          strokeWidth={1.6}
                        />
                        <path
                          d="M12 1.99902C17.524 1.99902 22.002 6.47702 22.002 12.001C22.002 17.524 17.524 22.002 12 22.002C6.47605 22.002 1.99805 17.524 1.99805 12.001C1.99805 6.47702 6.47605 1.99902 12 1.99902ZM12 3.49902C10.8759 3.48683 9.76041 3.69772 8.71827 4.1195C7.67612 4.54127 6.72798 5.16554 5.92871 5.95619C5.12944 6.74684 4.49492 7.68816 4.06187 8.72566C3.62882 9.76317 3.40584 10.8763 3.40584 12.0005C3.40584 13.1248 3.62882 14.2379 4.06187 15.2754C4.49492 16.3129 5.12944 17.2542 5.92871 18.0449C6.72798 18.8355 7.67612 19.4598 8.71827 19.8816C9.76041 20.3033 10.8759 20.5142 12 20.502C14.2321 20.4678 16.3611 19.5571 17.9274 17.9666C19.4937 16.376 20.3717 14.2333 20.3717 12.001C20.3717 9.76874 19.4937 7.626 17.9274 6.03548C16.3611 4.44496 14.2321 3.53326 12 3.49902ZM11.997 5.99902C13.5878 5.99902 15.1134 6.63095 16.2383 7.7558C17.3631 8.88064 17.995 10.4063 17.995 11.997C17.995 13.5878 17.3631 15.1134 16.2383 16.2382C15.1134 17.3631 13.5878 17.995 11.997 17.995C10.4063 17.995 8.88066 17.3631 7.75582 16.2382C6.63098 15.1134 5.99905 13.5878 5.99905 11.997C5.99905 10.4063 6.63098 8.88064 7.75582 7.7558C8.88066 6.63095 10.4063 5.99902 11.997 5.99902Z"
                          fill="#10B981"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="9.3"
                          fill="#FCFCFC"
                          stroke="#10B981"
                          strokeWidth={2.5}
                        />
                      </svg>
                    </>
                  )}
                  <p>Type C</p>
                </div>
                <div className="tCLeftTopType">
                  {typeNow === "D" ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="10"
                          fill="#FCFCFC"
                          stroke="#10B981"
                          strokeWidth={1.6}
                        />
                        <path
                          d="M12 1.99902C17.524 1.99902 22.002 6.47702 22.002 12.001C22.002 17.524 17.524 22.002 12 22.002C6.47605 22.002 1.99805 17.524 1.99805 12.001C1.99805 6.47702 6.47605 1.99902 12 1.99902ZM12 3.49902C10.8759 3.48683 9.76041 3.69772 8.71827 4.1195C7.67612 4.54127 6.72798 5.16554 5.92871 5.95619C5.12944 6.74684 4.49492 7.68816 4.06187 8.72566C3.62882 9.76317 3.40584 10.8763 3.40584 12.0005C3.40584 13.1248 3.62882 14.2379 4.06187 15.2754C4.49492 16.3129 5.12944 17.2542 5.92871 18.0449C6.72798 18.8355 7.67612 19.4598 8.71827 19.8816C9.76041 20.3033 10.8759 20.5142 12 20.502C14.2321 20.4678 16.3611 19.5571 17.9274 17.9666C19.4937 16.376 20.3717 14.2333 20.3717 12.001C20.3717 9.76874 19.4937 7.626 17.9274 6.03548C16.3611 4.44496 14.2321 3.53326 12 3.49902ZM11.997 5.99902C13.5878 5.99902 15.1134 6.63095 16.2383 7.7558C17.3631 8.88064 17.995 10.4063 17.995 11.997C17.995 13.5878 17.3631 15.1134 16.2383 16.2382C15.1134 17.3631 13.5878 17.995 11.997 17.995C10.4063 17.995 8.88066 17.3631 7.75582 16.2382C6.63098 15.1134 5.99905 13.5878 5.99905 11.997C5.99905 10.4063 6.63098 8.88064 7.75582 7.7558C8.88066 6.63095 10.4063 5.99902 11.997 5.99902Z"
                          fill="#10B981"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="9.3"
                          fill="#FCFCFC"
                          stroke="#10B981"
                          strokeWidth={2.5}
                        />
                      </svg>
                    </>
                  )}
                  <p>Type D</p>
                </div>
                <div className="tCLeftTopType">
                  {typeNow === "E" ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="10"
                          fill="#FCFCFC"
                          stroke="#10B981"
                          strokeWidth={1.6}
                        />
                        <path
                          d="M12 1.99902C17.524 1.99902 22.002 6.47702 22.002 12.001C22.002 17.524 17.524 22.002 12 22.002C6.47605 22.002 1.99805 17.524 1.99805 12.001C1.99805 6.47702 6.47605 1.99902 12 1.99902ZM12 3.49902C10.8759 3.48683 9.76041 3.69772 8.71827 4.1195C7.67612 4.54127 6.72798 5.16554 5.92871 5.95619C5.12944 6.74684 4.49492 7.68816 4.06187 8.72566C3.62882 9.76317 3.40584 10.8763 3.40584 12.0005C3.40584 13.1248 3.62882 14.2379 4.06187 15.2754C4.49492 16.3129 5.12944 17.2542 5.92871 18.0449C6.72798 18.8355 7.67612 19.4598 8.71827 19.8816C9.76041 20.3033 10.8759 20.5142 12 20.502C14.2321 20.4678 16.3611 19.5571 17.9274 17.9666C19.4937 16.376 20.3717 14.2333 20.3717 12.001C20.3717 9.76874 19.4937 7.626 17.9274 6.03548C16.3611 4.44496 14.2321 3.53326 12 3.49902ZM11.997 5.99902C13.5878 5.99902 15.1134 6.63095 16.2383 7.7558C17.3631 8.88064 17.995 10.4063 17.995 11.997C17.995 13.5878 17.3631 15.1134 16.2383 16.2382C15.1134 17.3631 13.5878 17.995 11.997 17.995C10.4063 17.995 8.88066 17.3631 7.75582 16.2382C6.63098 15.1134 5.99905 13.5878 5.99905 11.997C5.99905 10.4063 6.63098 8.88064 7.75582 7.7558C8.88066 6.63095 10.4063 5.99902 11.997 5.99902Z"
                          fill="#10B981"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="9.3"
                          fill="#FCFCFC"
                          stroke="#10B981"
                          strokeWidth={2.5}
                        />
                      </svg>
                    </>
                  )}
                  <p>Type E</p>
                </div>
              </div>
            </div>
          </div>
          <div className="tCLeftBottom">
            <img src={HalfO} />
            <div className="bg">
              <div>
                <p>Apa kegunaan Test ini?</p>
                <ul>
                  <li>Pengukuran Kecerdasan Non-Verbal</li>
                  <li>Pengukuran Kecerdasan Umum</li>
                  <li>Identifikasi Bakat dan Potensi</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="tCRight">
          <div className="tCTopbar">
            <div className="web">
              <img src={Logo} />
              <p className="title">Standart Progressive Matrices Test</p>
            </div>
            <div className="mob">
              <p className="title">SPM Test</p>
            </div>
          </div>
          <div className="tCMain">
            {halaman === "home" && (
              <>
                <img src={Spm} className="open" />
                <div className="info">
                  <div className="ket">
                    <p className="title">⚠️ Petunjuk Pengerjaan Soal</p>
                    <p>
                      Tes ini akan menguji kemampuan Anda untuk mengenali pola
                      dan hubungan dalam matriks. Cobalah untuk mengidentifikasi
                      pola umum atau aturan dalam matriks sebelum Anda mencoba
                      menjawab soal.
                    </p>
                  </div>
                </div>
                <p className="waktu">Waktu Pengerjaan: 30 Menit </p>

                <Link
                  to="#"
                  className="buttonTest"
                  onClick={() => {
                    setHalaman("mulai");
                    setBenar(true);
                  }}
                >
                  Mulai Test
                </Link>
              </>
            )}
            {halaman === "mulai" && (
              <>
                <div className="soal">
                  <div className="mobileTop">
                    <p className={typeNow === "A" ? "clickable" : "a"}>
                      <span className={typeNow === "A" ? "whiter" : "b"}>
                        A
                      </span>
                    </p>
                    <p className={typeNow === "B" ? "clickable" : "a"}>
                      <span className={typeNow === "B" ? "whiter" : "b"}>
                        B
                      </span>
                    </p>
                    <p className={typeNow === "C" ? "clickable" : "a"}>
                      <span className={typeNow === "C" ? "whiter" : "b"}>
                        C
                      </span>
                    </p>
                    <p className={typeNow === "D" ? "clickable" : "a"}>
                      <span className={typeNow === "D" ? "whiter" : "b"}>
                        D
                      </span>
                    </p>
                    <p className={typeNow === "E" ? "clickable" : "a"}>
                      <span className={typeNow === "E" ? "whiter" : "b"}>
                        E
                      </span>
                    </p>
                  </div>

                  <div>
                    <div className="top">
                      <p> {nomor == 12 ? nomor : nomor % 12} / 12 </p>
                      <p className={berjalan}>
                        {" "}
                        <i
                          className="bx bx-time"
                          style={{ marginLeft: "4px" }}
                        ></i>{" "}
                        {"  "} {min > 9 ? min : "0" + min}:
                        {sec > 9 ? sec : "0" + sec}{" "}
                      </p>
                    </div>
                    <div className="grupNumber">
                      <span className={checkNumber(1)}>1</span>
                      <span className={checkNumber(2)}>2</span>
                      <span className={checkNumber(3)}>3</span>
                      <span className={checkNumber(4)}>4</span>
                      <span className={checkNumber(5)}>5</span>
                      <span className={checkNumber(6)}>6</span>
                      <span className={checkNumber(7)}>7</span>
                      <span className={checkNumber(8)}>8</span>
                      <span className={checkNumber(9)}>9</span>
                      <span className={checkNumber(10)}>10</span>
                      <span className={checkNumber(11)}>11</span>
                      <span className={checkNumber(0)}>12</span>
                    </div>
                  </div>

                  {nomor % 12 === 1 && (
                    <>
                      {typeNow === "A" && (
                        <>
                          <img src={A1} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={ca11 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j11"
                                name="1"
                                value={1}
                                onChange={(event) => {
                                  setJA1(event.target.value);
                                  setCa11(event.target.checked);
                                  setCa12(false);
                                  setCa13(false);
                                  setCa14(false);
                                  setCa15(false);
                                  setCa16(false);
                                }}
                              />
                              <label htmlFor="j11">
                                <img src={A1Q1} />
                              </label>
                            </div>
                            <div className={ca12 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j12"
                                name="1"
                                value={2}
                                onChange={(event) => {
                                  setJA1(event.target.value);
                                  setCa12(event.target.checked);
                                  setCa11(false);
                                  setCa13(false);
                                  setCa14(false);
                                  setCa15(false);
                                  setCa16(false);
                                }}
                              />
                              <label htmlFor="j12">
                                <img src={A1Q2} />
                              </label>
                            </div>
                            <div className={ca13 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j13"
                                name="1"
                                value={3}
                                onChange={(event) => {
                                  setJA1(event.target.value);
                                  setCa13(event.target.checked);
                                  setCa12(false);
                                  setCa11(false);
                                  setCa14(false);
                                  setCa15(false);
                                  setCa16(false);
                                }}
                              />
                              <label htmlFor="j13">
                                <img src={A1Q3} />
                              </label>
                            </div>
                            <div className={ca14 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j14"
                                name="1"
                                value={4}
                                onChange={(event) => {
                                  setJA1(event.target.value);
                                  setCa14(event.target.checked);
                                  setCa12(false);
                                  setCa13(false);
                                  setCa11(false);
                                  setCa15(false);
                                  setCa16(false);
                                }}
                              />
                              <label htmlFor="j14">
                                <img src={A1Q4} />
                              </label>
                            </div>
                            <div className={ca15 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j15"
                                name="1"
                                value={5}
                                onChange={(event) => {
                                  setJA1(event.target.value);
                                  setCa15(event.target.checked);
                                  setCa12(false);
                                  setCa13(false);
                                  setCa14(false);
                                  setCa11(false);
                                  setCa16(false);
                                }}
                              />
                              <label htmlFor="j15">
                                <img src={A1Q5} />
                              </label>
                            </div>
                            <div className={ca16 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j16"
                                name="1"
                                value={6}
                                onChange={(event) => {
                                  setJA1(event.target.value);
                                  setCa16(event.target.checked);
                                  setCa12(false);
                                  setCa13(false);
                                  setCa14(false);
                                  setCa15(false);
                                  setCa11(false);
                                }}
                              />
                              <label htmlFor="j16">
                                <img src={A1Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "B" && (
                        <>
                          <img src={B1} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={cb11 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j11"
                                name="1"
                                value={1}
                                onChange={(event) => {
                                  setJB1(event.target.value);
                                  setCb11(event.target.checked);
                                  setCb12(false);
                                  setCb13(false);
                                  setCb14(false);
                                  setCb15(false);
                                  setCb16(false);
                                }}
                              />
                              <label htmlFor="j11">
                                <img src={B1Q1} />
                              </label>
                            </div>
                            <div className={cb12 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j12"
                                name="1"
                                value={2}
                                onChange={(event) => {
                                  setJB1(event.target.value);
                                  setCb12(event.target.checked);
                                  setCb11(false);
                                  setCb13(false);
                                  setCb14(false);
                                  setCb15(false);
                                  setCb16(false);
                                }}
                              />
                              <label htmlFor="j12">
                                <img src={B1Q2} />
                              </label>
                            </div>
                            <div className={cb13 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j13"
                                name="1"
                                value={3}
                                onChange={(event) => {
                                  setJB1(event.target.value);
                                  setCb13(event.target.checked);
                                  setCb12(false);
                                  setCb11(false);
                                  setCb14(false);
                                  setCb15(false);
                                  setCb16(false);
                                }}
                              />
                              <label htmlFor="j13">
                                <img src={B1Q3} />
                              </label>
                            </div>
                            <div className={cb14 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j14"
                                name="1"
                                value={4}
                                onChange={(event) => {
                                  setJB1(event.target.value);
                                  setCb14(event.target.checked);
                                  setCb12(false);
                                  setCb13(false);
                                  setCb11(false);
                                  setCb15(false);
                                  setCb16(false);
                                }}
                              />
                              <label htmlFor="j14">
                                <img src={B1Q4} />
                              </label>
                            </div>
                            <div className={cb15 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j15"
                                name="1"
                                value={5}
                                onChange={(event) => {
                                  setJB1(event.target.value);
                                  setCb15(event.target.checked);
                                  setCb12(false);
                                  setCb13(false);
                                  setCb14(false);
                                  setCb11(false);
                                  setCb16(false);
                                }}
                              />
                              <label htmlFor="j15">
                                <img src={B1Q5} />
                              </label>
                            </div>
                            <div className={cb16 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j16"
                                name="1"
                                value={6}
                                onChange={(event) => {
                                  setJB1(event.target.value);
                                  setCb16(event.target.checked);
                                  setCb12(false);
                                  setCb13(false);
                                  setCb14(false);
                                  setCb15(false);
                                  setCb11(false);
                                }}
                              />
                              <label htmlFor="j16">
                                <img src={B1Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "C" && (
                        <>
                          <img src={C1} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div className={cc11 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j11"
                                name="1"
                                value={1}
                                onChange={(event) => {
                                  setJC1(event.target.value);
                                  setCc11(event.target.checked);
                                  setCc12(false);
                                  setCc13(false);
                                  setCc14(false);
                                  setCc15(false);
                                  setCc16(false);
                                  setCc17(false);
                                  setCc18(false);
                                }}
                              />
                              <label htmlFor="j11">
                                <img src={C1Q1} />
                              </label>
                            </div>
                            <div className={cc12 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j12"
                                name="1"
                                value={2}
                                onChange={(event) => {
                                  setJC1(event.target.value);
                                  setCc12(event.target.checked);
                                  setCc11(false);
                                  setCc13(false);
                                  setCc14(false);
                                  setCc15(false);
                                  setCc16(false);
                                  setCc17(false);
                                  setCc18(false);
                                }}
                              />
                              <label htmlFor="j12">
                                <img src={C1Q2} />
                              </label>
                            </div>
                            <div className={cc13 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j13"
                                name="1"
                                value={3}
                                onChange={(event) => {
                                  setJC1(event.target.value);
                                  setCc13(event.target.checked);
                                  setCc11(false);
                                  setCc12(false);
                                  setCc14(false);
                                  setCc15(false);
                                  setCc16(false);
                                  setCc17(false);
                                  setCc18(false);
                                }}
                              />
                              <label htmlFor="j13">
                                <img src={C1Q3} />
                              </label>
                            </div>
                            <div className={cc14 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j14"
                                name="1"
                                value={4}
                                onChange={(event) => {
                                  setJC1(event.target.value);
                                  setCc14(event.target.checked);
                                  setCc11(false);
                                  setCc12(false);
                                  setCc13(false);
                                  setCc15(false);
                                  setCc16(false);
                                  setCc17(false);
                                  setCc18(false);
                                }}
                              />
                              <label htmlFor="j14">
                                <img src={C1Q4} />
                              </label>
                            </div>
                            <div className={cc15 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j15"
                                name="1"
                                value={5}
                                onChange={(event) => {
                                  setJC1(event.target.value);
                                  setCc15(event.target.checked);
                                  setCc11(false);
                                  setCc12(false);
                                  setCc13(false);
                                  setCc14(false);
                                  setCc16(false);
                                  setCc17(false);
                                  setCc18(false);
                                }}
                              />
                              <label htmlFor="j15">
                                <img src={C1Q5} />
                              </label>
                            </div>
                            <div className={cc16 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j16"
                                name="1"
                                value={6}
                                onChange={(event) => {
                                  setJC1(event.target.value);
                                  setCc16(event.target.checked);
                                  setCc11(false);
                                  setCc12(false);
                                  setCc13(false);
                                  setCc14(false);
                                  setCc15(false);
                                  setCc17(false);
                                  setCc18(false);
                                }}
                              />
                              <label htmlFor="j16">
                                <img src={C1Q6} />
                              </label>
                            </div>
                            <div className={cc17 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j17"
                                name="1"
                                value={7}
                                onChange={(event) => {
                                  setJC1(event.target.value);
                                  setCc17(event.target.checked);
                                  setCc11(false);
                                  setCc12(false);
                                  setCc13(false);
                                  setCc14(false);
                                  setCc15(false);
                                  setCc16(false);
                                  setCc18(false);
                                }}
                              />
                              <label htmlFor="j17">
                                <img src={C1Q7} />
                              </label>
                            </div>
                            <div className={cc18 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j18"
                                name="1"
                                value={8}
                                onChange={(event) => {
                                  setJC1(event.target.value);
                                  setCc18(event.target.checked);
                                  setCc11(false);
                                  setCc12(false);
                                  setCc13(false);
                                  setCc14(false);
                                  setCc15(false);
                                  setCc16(false);
                                  setCc17(false);
                                }}
                              />
                              <label htmlFor="j18">
                                <img src={C1Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "D" && (
                        <>
                          <img src={D1} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div className={cd11 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j11"
                                name="1"
                                value={1}
                                onChange={(event) => {
                                  setJD1(event.target.value);
                                  setCd11(event.target.checked);
                                  setCd12(false);
                                  setCd13(false);
                                  setCd14(false);
                                  setCd15(false);
                                  setCd16(false);
                                  setCd17(false);
                                  setCd18(false);
                                }}
                              />
                              <label htmlFor="j11">
                                <img src={D1Q1} />
                              </label>
                            </div>
                            <div className={cd12 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j12"
                                name="1"
                                value={2}
                                onChange={(event) => {
                                  setJD1(event.target.value);
                                  setCd12(event.target.checked);
                                  setCd11(false);
                                  setCd13(false);
                                  setCd14(false);
                                  setCd15(false);
                                  setCd16(false);
                                  setCd17(false);
                                  setCd18(false);
                                }}
                              />
                              <label htmlFor="j12">
                                <img src={D1Q2} />
                              </label>
                            </div>
                            <div className={cd13 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j13"
                                name="1"
                                value={3}
                                onChange={(event) => {
                                  setJD1(event.target.value);
                                  setCd13(event.target.checked);
                                  setCd11(false);
                                  setCd12(false);
                                  setCd14(false);
                                  setCd15(false);
                                  setCd16(false);
                                  setCd17(false);
                                  setCd18(false);
                                }}
                              />
                              <label htmlFor="j13">
                                <img src={D1Q3} />
                              </label>
                            </div>
                            <div className={cd14 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j14"
                                name="1"
                                value={4}
                                onChange={(event) => {
                                  setJD1(event.target.value);
                                  setCd14(event.target.checked);
                                  setCd11(false);
                                  setCd12(false);
                                  setCd13(false);
                                  setCd15(false);
                                  setCd16(false);
                                  setCd17(false);
                                  setCd18(false);
                                }}
                              />
                              <label htmlFor="j14">
                                <img src={D1Q4} />
                              </label>
                            </div>
                            <div className={cd15 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j15"
                                name="1"
                                value={5}
                                onChange={(event) => {
                                  setJD1(event.target.value);
                                  setCd15(event.target.checked);
                                  setCd11(false);
                                  setCd12(false);
                                  setCd13(false);
                                  setCd14(false);
                                  setCd16(false);
                                  setCd17(false);
                                  setCd18(false);
                                }}
                              />
                              <label htmlFor="j15">
                                <img src={D1Q5} />
                              </label>
                            </div>
                            <div className={cd16 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j16"
                                name="1"
                                value={6}
                                onChange={(event) => {
                                  setJD1(event.target.value);
                                  setCd16(event.target.checked);
                                  setCd11(false);
                                  setCd12(false);
                                  setCd13(false);
                                  setCd14(false);
                                  setCd15(false);
                                  setCd17(false);
                                  setCd18(false);
                                }}
                              />
                              <label htmlFor="j16">
                                <img src={D1Q6} />
                              </label>
                            </div>
                            <div className={cd17 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j17"
                                name="1"
                                value={7}
                                onChange={(event) => {
                                  setJD1(event.target.value);
                                  setCd17(event.target.checked);
                                  setCd11(false);
                                  setCd12(false);
                                  setCd13(false);
                                  setCd14(false);
                                  setCd15(false);
                                  setCd16(false);
                                  setCd18(false);
                                }}
                              />
                              <label htmlFor="j17">
                                <img src={D1Q7} />
                              </label>
                            </div>
                            <div className={cd18 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j18"
                                name="1"
                                value={8}
                                onChange={(event) => {
                                  setJD1(event.target.value);
                                  setCd18(event.target.checked);
                                  setCd11(false);
                                  setCd12(false);
                                  setCd13(false);
                                  setCd14(false);
                                  setCd15(false);
                                  setCd16(false);
                                  setCd17(false);
                                }}
                              />
                              <label htmlFor="j18">
                                <img src={D1Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "E" && (
                        <>
                          <img src={E1} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div className={ce11 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j11"
                                name="1"
                                value={1}
                                onChange={(event) => {
                                  setJE1(event.target.value);
                                  setCe11(event.target.checked);
                                  setCe12(false);
                                  setCe13(false);
                                  setCe14(false);
                                  setCe15(false);
                                  setCe16(false);
                                  setCe17(false);
                                  setCe18(false);
                                }}
                              />
                              <label htmlFor="j11">
                                <img src={E1Q1} />
                              </label>
                            </div>
                            <div className={ce12 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j12"
                                name="1"
                                value={2}
                                onChange={(event) => {
                                  setJE1(event.target.value);
                                  setCe12(event.target.checked);
                                  setCe11(false);
                                  setCe13(false);
                                  setCe14(false);
                                  setCe15(false);
                                  setCe16(false);
                                  setCe17(false);
                                  setCe18(false);
                                }}
                              />
                              <label htmlFor="j12">
                                <img src={E1Q2} />
                              </label>
                            </div>
                            <div className={ce13 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j13"
                                name="1"
                                value={3}
                                onChange={(event) => {
                                  setJE1(event.target.value);
                                  setCe13(event.target.checked);
                                  setCe11(false);
                                  setCe12(false);
                                  setCe14(false);
                                  setCe15(false);
                                  setCe16(false);
                                  setCe17(false);
                                  setCe18(false);
                                }}
                              />
                              <label htmlFor="j13">
                                <img src={E1Q3} />
                              </label>
                            </div>
                            <div className={ce14 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j14"
                                name="1"
                                value={4}
                                onChange={(event) => {
                                  setJE1(event.target.value);
                                  setCe14(event.target.checked);
                                  setCe11(false);
                                  setCe12(false);
                                  setCe13(false);
                                  setCe15(false);
                                  setCe16(false);
                                  setCe17(false);
                                  setCe18(false);
                                }}
                              />
                              <label htmlFor="j14">
                                <img src={E1Q4} />
                              </label>
                            </div>
                            <div className={ce15 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j15"
                                name="1"
                                value={5}
                                onChange={(event) => {
                                  setJE1(event.target.value);
                                  setCe15(event.target.checked);
                                  setCe11(false);
                                  setCe12(false);
                                  setCe13(false);
                                  setCe14(false);
                                  setCe16(false);
                                  setCe17(false);
                                  setCe18(false);
                                }}
                              />
                              <label htmlFor="j15">
                                <img src={E1Q5} />
                              </label>
                            </div>
                            <div className={ce16 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j16"
                                name="1"
                                value={6}
                                onChange={(event) => {
                                  setJE1(event.target.value);
                                  setCe16(event.target.checked);
                                  setCe11(false);
                                  setCe12(false);
                                  setCe13(false);
                                  setCe14(false);
                                  setCe15(false);
                                  setCe17(false);
                                  setCe18(false);
                                }}
                              />
                              <label htmlFor="j16">
                                <img src={E1Q6} />
                              </label>
                            </div>
                            <div className={ce17 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j17"
                                name="1"
                                value={7}
                                onChange={(event) => {
                                  setJE1(event.target.value);
                                  setCe17(event.target.checked);
                                  setCe11(false);
                                  setCe12(false);
                                  setCe13(false);
                                  setCe14(false);
                                  setCe15(false);
                                  setCe16(false);
                                  setCe18(false);
                                }}
                              />
                              <label htmlFor="j17">
                                <img src={E1Q7} />
                              </label>
                            </div>
                            <div className={ce18 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j18"
                                name="1"
                                value={8}
                                onChange={(event) => {
                                  setJE1(event.target.value);
                                  setCe18(event.target.checked);
                                  setCe11(false);
                                  setCe12(false);
                                  setCe13(false);
                                  setCe14(false);
                                  setCe15(false);
                                  setCe16(false);
                                  setCe17(false);
                                }}
                              />
                              <label htmlFor="j18">
                                <img src={E1Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {nomor % 12 === 2 && (
                    <>
                      {typeNow === "A" && (
                        <>
                          <img src={A2} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={ca21 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j21"
                                name="2"
                                value={1}
                                onChange={(event) => {
                                  setJA2(event.target.value);
                                  setCa21(event.target.checked);
                                  setCa22(false);
                                  setCa23(false);
                                  setCa24(false);
                                  setCa25(false);
                                  setCa26(false);
                                }}
                              />
                              <label htmlFor="j21">
                                <img src={A2Q1} />
                              </label>
                            </div>
                            <div className={ca22 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j22"
                                name="2"
                                value={2}
                                onChange={(event) => {
                                  setJA2(event.target.value);
                                  setCa22(event.target.checked);
                                  setCa21(false);
                                  setCa23(false);
                                  setCa24(false);
                                  setCa25(false);
                                  setCa26(false);
                                }}
                              />
                              <label htmlFor="j22">
                                <img src={A2Q2} />
                              </label>
                            </div>
                            <div className={ca23 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j23"
                                name="2"
                                value={3}
                                onChange={(event) => {
                                  setJA2(event.target.value);
                                  setCa23(event.target.checked);
                                  setCa22(false);
                                  setCa21(false);
                                  setCa24(false);
                                  setCa25(false);
                                  setCa26(false);
                                }}
                              />
                              <label htmlFor="j23">
                                <img src={A2Q3} />
                              </label>
                            </div>
                            <div className={ca24 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j24"
                                name="2"
                                value={4}
                                onChange={(event) => {
                                  setJA2(event.target.value);
                                  setCa24(event.target.checked);
                                  setCa22(false);
                                  setCa23(false);
                                  setCa21(false);
                                  setCa25(false);
                                  setCa26(false);
                                }}
                              />
                              <label htmlFor="j24">
                                <img src={A2Q4} />
                              </label>
                            </div>
                            <div className={ca25 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j25"
                                name="2"
                                value={5}
                                onChange={(event) => {
                                  setJA2(event.target.value);
                                  setCa25(event.target.checked);
                                  setCa22(false);
                                  setCa23(false);
                                  setCa24(false);
                                  setCa21(false);
                                  setCa26(false);
                                }}
                              />
                              <label htmlFor="j25">
                                <img src={A2Q5} />
                              </label>
                            </div>
                            <div className={ca26 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j26"
                                name="2"
                                value={6}
                                onChange={(event) => {
                                  setJA2(event.target.value);
                                  setCa26(event.target.checked);
                                  setCa22(false);
                                  setCa23(false);
                                  setCa24(false);
                                  setCa25(false);
                                  setCa21(false);
                                }}
                              />
                              <label htmlFor="j26">
                                <img src={A2Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "B" && (
                        <>
                          <img src={B2} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={cb21 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j21"
                                name="2"
                                value={1}
                                onChange={(event) => {
                                  setJB2(event.target.value);
                                  setCb21(event.target.checked);
                                  setCb22(false);
                                  setCb23(false);
                                  setCb24(false);
                                  setCb25(false);
                                  setCb26(false);
                                }}
                              />
                              <label htmlFor="j21">
                                <img src={B2Q1} />
                              </label>
                            </div>
                            <div className={cb22 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j22"
                                name="2"
                                value={2}
                                onChange={(event) => {
                                  setJB2(event.target.value);
                                  setCb22(event.target.checked);
                                  setCb21(false);
                                  setCb23(false);
                                  setCb24(false);
                                  setCb25(false);
                                  setCb26(false);
                                }}
                              />
                              <label htmlFor="j22">
                                <img src={B2Q2} />
                              </label>
                            </div>
                            <div className={cb23 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j23"
                                name="2"
                                value={3}
                                onChange={(event) => {
                                  setJB2(event.target.value);
                                  setCb23(event.target.checked);
                                  setCb22(false);
                                  setCb21(false);
                                  setCb24(false);
                                  setCb25(false);
                                  setCb26(false);
                                }}
                              />
                              <label htmlFor="j23">
                                <img src={B2Q3} />
                              </label>
                            </div>
                            <div className={cb24 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j24"
                                name="2"
                                value={4}
                                onChange={(event) => {
                                  setJB2(event.target.value);
                                  setCb24(event.target.checked);
                                  setCb22(false);
                                  setCb23(false);
                                  setCb21(false);
                                  setCb25(false);
                                  setCb26(false);
                                }}
                              />
                              <label htmlFor="j24">
                                <img src={B2Q4} />
                              </label>
                            </div>
                            <div className={cb25 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j25"
                                name="2"
                                value={5}
                                onChange={(event) => {
                                  setJB2(event.target.value);
                                  setCb25(event.target.checked);
                                  setCb22(false);
                                  setCb23(false);
                                  setCb24(false);
                                  setCb21(false);
                                  setCb26(false);
                                }}
                              />
                              <label htmlFor="j25">
                                <img src={B2Q5} />
                              </label>
                            </div>
                            <div className={cb26 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j26"
                                name="2"
                                value={6}
                                onChange={(event) => {
                                  setJB2(event.target.value);
                                  setCb26(event.target.checked);
                                  setCb22(false);
                                  setCb23(false);
                                  setCb24(false);
                                  setCb25(false);
                                  setCb21(false);
                                }}
                              />
                              <label htmlFor="j26">
                                <img src={B2Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "C" && (
                        <>
                          <img src={C2} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div className={cc21 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j21"
                                name="2"
                                value={1}
                                onChange={(event) => {
                                  setJC2(event.target.value);
                                  setCc21(event.target.checked);
                                  setCc22(false);
                                  setCc23(false);
                                  setCc24(false);
                                  setCc25(false);
                                  setCc26(false);
                                  setCc27(false);
                                  setCc28(false);
                                }}
                              />
                              <label htmlFor="j21">
                                <img src={C2Q1} />
                              </label>
                            </div>
                            <div className={cc22 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j22"
                                name="2"
                                value={2}
                                onChange={(event) => {
                                  setJC2(event.target.value);
                                  setCc22(event.target.checked);
                                  setCc21(false);
                                  setCc23(false);
                                  setCc24(false);
                                  setCc25(false);
                                  setCc26(false);
                                  setCc27(false);
                                  setCc28(false);
                                }}
                              />
                              <label htmlFor="j22">
                                <img src={C2Q2} />
                              </label>
                            </div>
                            <div className={cc23 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j23"
                                name="2"
                                value={3}
                                onChange={(event) => {
                                  setJC2(event.target.value);
                                  setCc23(event.target.checked);
                                  setCc21(false);
                                  setCc22(false);
                                  setCc24(false);
                                  setCc25(false);
                                  setCc26(false);
                                  setCc27(false);
                                  setCc28(false);
                                }}
                              />
                              <label htmlFor="j23">
                                <img src={C2Q3} />
                              </label>
                            </div>
                            <div className={cc24 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j24"
                                name="2"
                                value={4}
                                onChange={(event) => {
                                  setJC2(event.target.value);
                                  setCc24(event.target.checked);
                                  setCc21(false);
                                  setCc22(false);
                                  setCc23(false);
                                  setCc25(false);
                                  setCc26(false);
                                  setCc27(false);
                                  setCc28(false);
                                }}
                              />
                              <label htmlFor="j24">
                                <img src={C2Q4} />
                              </label>
                            </div>
                            <div className={cc25 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j25"
                                name="2"
                                value={5}
                                onChange={(event) => {
                                  setJC2(event.target.value);
                                  setCc25(event.target.checked);
                                  setCc21(false);
                                  setCc22(false);
                                  setCc23(false);
                                  setCc24(false);
                                  setCc26(false);
                                  setCc27(false);
                                  setCc28(false);
                                }}
                              />
                              <label htmlFor="j25">
                                <img src={C2Q5} />
                              </label>
                            </div>
                            <div className={cc26 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j26"
                                name="2"
                                value={6}
                                onChange={(event) => {
                                  setJC2(event.target.value);
                                  setCc26(event.target.checked);
                                  setCc21(false);
                                  setCc22(false);
                                  setCc23(false);
                                  setCc24(false);
                                  setCc25(false);
                                  setCc27(false);
                                  setCc28(false);
                                }}
                              />
                              <label htmlFor="j26">
                                <img src={C2Q6} />
                              </label>
                            </div>
                            <div className={cc27 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j27"
                                name="2"
                                value={7}
                                onChange={(event) => {
                                  setJC2(event.target.value);
                                  setCc27(event.target.checked);
                                  setCc21(false);
                                  setCc22(false);
                                  setCc23(false);
                                  setCc24(false);
                                  setCc25(false);
                                  setCc26(false);
                                  setCc28(false);
                                }}
                              />
                              <label htmlFor="j27">
                                <img src={C2Q7} />
                              </label>
                            </div>
                            <div className={cc28 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j28"
                                name="2"
                                value={8}
                                onChange={(event) => {
                                  setJC2(event.target.value);
                                  setCc28(event.target.checked);
                                  setCc21(false);
                                  setCc22(false);
                                  setCc23(false);
                                  setCc24(false);
                                  setCc25(false);
                                  setCc26(false);
                                  setCc27(false);
                                }}
                              />
                              <label htmlFor="j28">
                                <img src={C2Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "D" && (
                        <>
                          <img src={D2} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div className={cd21 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j21"
                                name="2"
                                value={1}
                                onChange={(event) => {
                                  setJD2(event.target.value);
                                  setCd21(event.target.checked);
                                  setCd22(false);
                                  setCd23(false);
                                  setCd24(false);
                                  setCd25(false);
                                  setCd26(false);
                                  setCd27(false);
                                  setCd28(false);
                                }}
                              />
                              <label htmlFor="j21">
                                <img src={D2Q1} />
                              </label>
                            </div>
                            <div className={cd22 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j22"
                                name="2"
                                value={2}
                                onChange={(event) => {
                                  setJD2(event.target.value);
                                  setCd22(event.target.checked);
                                  setCd21(false);
                                  setCd23(false);
                                  setCd24(false);
                                  setCd25(false);
                                  setCd26(false);
                                  setCd27(false);
                                  setCd28(false);
                                }}
                              />
                              <label htmlFor="j22">
                                <img src={D2Q2} />
                              </label>
                            </div>
                            <div className={cd23 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j23"
                                name="2"
                                value={3}
                                onChange={(event) => {
                                  setJD2(event.target.value);
                                  setCd23(event.target.checked);
                                  setCd21(false);
                                  setCd22(false);
                                  setCd24(false);
                                  setCd25(false);
                                  setCd26(false);
                                  setCd27(false);
                                  setCd28(false);
                                }}
                              />
                              <label htmlFor="j23">
                                <img src={D2Q3} />
                              </label>
                            </div>
                            <div className={cd24 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j24"
                                name="2"
                                value={4}
                                onChange={(event) => {
                                  setJD2(event.target.value);
                                  setCd24(event.target.checked);
                                  setCd21(false);
                                  setCd22(false);
                                  setCd23(false);
                                  setCd25(false);
                                  setCd26(false);
                                  setCd27(false);
                                  setCd28(false);
                                }}
                              />
                              <label htmlFor="j24">
                                <img src={D2Q4} />
                              </label>
                            </div>
                            <div className={cd25 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j25"
                                name="2"
                                value={5}
                                onChange={(event) => {
                                  setJD2(event.target.value);
                                  setCd25(event.target.checked);
                                  setCd21(false);
                                  setCd22(false);
                                  setCd23(false);
                                  setCd24(false);
                                  setCd26(false);
                                  setCd27(false);
                                  setCd28(false);
                                }}
                              />
                              <label htmlFor="j25">
                                <img src={D2Q5} />
                              </label>
                            </div>
                            <div className={cd26 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j26"
                                name="2"
                                value={6}
                                onChange={(event) => {
                                  setJD2(event.target.value);
                                  setCd26(event.target.checked);
                                  setCd21(false);
                                  setCd22(false);
                                  setCd23(false);
                                  setCd24(false);
                                  setCd25(false);
                                  setCd27(false);
                                  setCd28(false);
                                }}
                              />
                              <label htmlFor="j26">
                                <img src={D2Q6} />
                              </label>
                            </div>
                            <div className={cd27 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j27"
                                name="2"
                                value={7}
                                onChange={(event) => {
                                  setJD2(event.target.value);
                                  setCd27(event.target.checked);
                                  setCd21(false);
                                  setCd22(false);
                                  setCd23(false);
                                  setCd24(false);
                                  setCd25(false);
                                  setCd26(false);
                                  setCd28(false);
                                }}
                              />
                              <label htmlFor="j27">
                                <img src={D2Q7} />
                              </label>
                            </div>
                            <div className={cd28 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j28"
                                name="2"
                                value={8}
                                onChange={(event) => {
                                  setJD2(event.target.value);
                                  setCd28(event.target.checked);
                                  setCd21(false);
                                  setCd22(false);
                                  setCd23(false);
                                  setCd24(false);
                                  setCd25(false);
                                  setCd26(false);
                                  setCd27(false);
                                }}
                              />
                              <label htmlFor="j28">
                                <img src={D2Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "E" && (
                        <>
                          <img src={E2} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div className={ce21 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j21"
                                name="2"
                                value={1}
                                onChange={(event) => {
                                  setJE2(event.target.value);
                                  setCe21(event.target.checked);
                                  setCe22(false);
                                  setCe23(false);
                                  setCe24(false);
                                  setCe25(false);
                                  setCe26(false);
                                  setCe27(false);
                                  setCe28(false);
                                }}
                              />
                              <label htmlFor="j21">
                                <img src={E2Q1} />
                              </label>
                            </div>
                            <div className={ce22 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j22"
                                name="2"
                                value={2}
                                onChange={(event) => {
                                  setJE2(event.target.value);
                                  setCe22(event.target.checked);
                                  setCe21(false);
                                  setCe23(false);
                                  setCe24(false);
                                  setCe25(false);
                                  setCe26(false);
                                  setCe27(false);
                                  setCe28(false);
                                }}
                              />
                              <label htmlFor="j22">
                                <img src={E2Q2} />
                              </label>
                            </div>
                            <div className={ce23 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j23"
                                name="2"
                                value={3}
                                onChange={(event) => {
                                  setJE2(event.target.value);
                                  setCe23(event.target.checked);
                                  setCe21(false);
                                  setCe22(false);
                                  setCe24(false);
                                  setCe25(false);
                                  setCe26(false);
                                  setCe27(false);
                                  setCe28(false);
                                }}
                              />
                              <label htmlFor="j23">
                                <img src={E2Q3} />
                              </label>
                            </div>
                            <div className={ce24 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j24"
                                name="2"
                                value={4}
                                onChange={(event) => {
                                  setJE2(event.target.value);
                                  setCe24(event.target.checked);
                                  setCe21(false);
                                  setCe22(false);
                                  setCe23(false);
                                  setCe25(false);
                                  setCe26(false);
                                  setCe27(false);
                                  setCe28(false);
                                }}
                              />
                              <label htmlFor="j24">
                                <img src={E2Q4} />
                              </label>
                            </div>
                            <div className={ce25 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j25"
                                name="2"
                                value={5}
                                onChange={(event) => {
                                  setJE2(event.target.value);
                                  setCe25(event.target.checked);
                                  setCe21(false);
                                  setCe22(false);
                                  setCe23(false);
                                  setCe24(false);
                                  setCe26(false);
                                  setCe27(false);
                                  setCe28(false);
                                }}
                              />
                              <label htmlFor="j25">
                                <img src={E2Q5} />
                              </label>
                            </div>
                            <div className={ce26 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j26"
                                name="2"
                                value={6}
                                onChange={(event) => {
                                  setJE2(event.target.value);
                                  setCe26(event.target.checked);
                                  setCe21(false);
                                  setCe22(false);
                                  setCe23(false);
                                  setCe24(false);
                                  setCe25(false);
                                  setCe27(false);
                                  setCe28(false);
                                }}
                              />
                              <label htmlFor="j26">
                                <img src={E2Q6} />
                              </label>
                            </div>
                            <div className={ce27 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j27"
                                name="2"
                                value={7}
                                onChange={(event) => {
                                  setJE2(event.target.value);
                                  setCe27(event.target.checked);
                                  setCe21(false);
                                  setCe22(false);
                                  setCe23(false);
                                  setCe24(false);
                                  setCe25(false);
                                  setCe26(false);
                                  setCe28(false);
                                }}
                              />
                              <label htmlFor="j27">
                                <img src={E2Q7} />
                              </label>
                            </div>
                            <div className={ce28 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j28"
                                name="2"
                                value={8}
                                onChange={(event) => {
                                  setJE2(event.target.value);
                                  setCe28(event.target.checked);
                                  setCe21(false);
                                  setCe22(false);
                                  setCe23(false);
                                  setCe24(false);
                                  setCe25(false);
                                  setCe26(false);
                                  setCe27(false);
                                }}
                              />
                              <label htmlFor="j28">
                                <img src={E2Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {nomor % 12 === 3 && (
                    <>
                      {typeNow === "A" && (
                        <>
                          <img src={A3} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={ca31 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j31"
                                name="3"
                                value={1}
                                onChange={(event) => {
                                  setJA3(event.target.value);
                                  setCa31(event.target.checked);
                                  setCa32(false);
                                  setCa33(false);
                                  setCa34(false);
                                  setCa35(false);
                                  setCa36(false);
                                }}
                              />
                              <label htmlFor="j31">
                                <img src={A3Q1} />
                              </label>
                            </div>
                            <div className={ca32 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j32"
                                name="3"
                                value={2}
                                onChange={(event) => {
                                  setJA3(event.target.value);
                                  setCa32(event.target.checked);
                                  setCa31(false);
                                  setCa33(false);
                                  setCa34(false);
                                  setCa35(false);
                                  setCa36(false);
                                }}
                              />
                              <label htmlFor="j32">
                                <img src={A3Q2} />
                              </label>
                            </div>
                            <div className={ca33 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j33"
                                name="3"
                                value={3}
                                onChange={(event) => {
                                  setJA3(event.target.value);
                                  setCa33(event.target.checked);
                                  setCa32(false);
                                  setCa31(false);
                                  setCa34(false);
                                  setCa35(false);
                                  setCa36(false);
                                }}
                              />
                              <label htmlFor="j33">
                                <img src={A3Q3} />
                              </label>
                            </div>
                            <div className={ca34 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j34"
                                name="3"
                                value={4}
                                onChange={(event) => {
                                  setJA3(event.target.value);
                                  setCa34(event.target.checked);
                                  setCa32(false);
                                  setCa33(false);
                                  setCa31(false);
                                  setCa35(false);
                                  setCa36(false);
                                }}
                              />
                              <label htmlFor="j34">
                                <img src={A3Q4} />
                              </label>
                            </div>
                            <div className={ca35 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j35"
                                name="3"
                                value={5}
                                onChange={(event) => {
                                  setJA3(event.target.value);
                                  setCa35(event.target.checked);
                                  setCa32(false);
                                  setCa33(false);
                                  setCa34(false);
                                  setCa31(false);
                                  setCa36(false);
                                }}
                              />
                              <label htmlFor="j35">
                                <img src={A3Q5} />
                              </label>
                            </div>
                            <div className={ca36 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j36"
                                name="3"
                                value={6}
                                onChange={(event) => {
                                  setJA3(event.target.value);
                                  setCa36(event.target.checked);
                                  setCa32(false);
                                  setCa33(false);
                                  setCa34(false);
                                  setCa35(false);
                                  setCa31(false);
                                }}
                              />
                              <label htmlFor="j36">
                                <img src={A3Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "B" && (
                        <>
                          <img src={B3} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={cb31 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j31"
                                name="3"
                                value={1}
                                onChange={(event) => {
                                  setJB3(event.target.value);
                                  setCb31(event.target.checked);
                                  setCb32(false);
                                  setCb33(false);
                                  setCb34(false);
                                  setCb35(false);
                                  setCb36(false);
                                }}
                              />
                              <label htmlFor="j31">
                                <img src={B3Q1} />
                              </label>
                            </div>
                            <div className={cb32 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j32"
                                name="3"
                                value={2}
                                onChange={(event) => {
                                  setJB3(event.target.value);
                                  setCb32(event.target.checked);
                                  setCb31(false);
                                  setCb33(false);
                                  setCb34(false);
                                  setCb35(false);
                                  setCb36(false);
                                }}
                              />
                              <label htmlFor="j32">
                                <img src={B3Q2} />
                              </label>
                            </div>
                            <div className={cb33 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j33"
                                name="3"
                                value={3}
                                onChange={(event) => {
                                  setJB3(event.target.value);
                                  setCb33(event.target.checked);
                                  setCb32(false);
                                  setCb31(false);
                                  setCb34(false);
                                  setCb35(false);
                                  setCb36(false);
                                }}
                              />
                              <label htmlFor="j33">
                                <img src={B3Q3} />
                              </label>
                            </div>
                            <div className={cb34 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j34"
                                name="3"
                                value={4}
                                onChange={(event) => {
                                  setJB3(event.target.value);
                                  setCb34(event.target.checked);
                                  setCb32(false);
                                  setCb33(false);
                                  setCb31(false);
                                  setCb35(false);
                                  setCb36(false);
                                }}
                              />
                              <label htmlFor="j34">
                                <img src={B3Q4} />
                              </label>
                            </div>
                            <div className={cb35 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j35"
                                name="3"
                                value={5}
                                onChange={(event) => {
                                  setJB3(event.target.value);
                                  setCb35(event.target.checked);
                                  setCb32(false);
                                  setCb33(false);
                                  setCb34(false);
                                  setCb31(false);
                                  setCb36(false);
                                }}
                              />
                              <label htmlFor="j35">
                                <img src={B3Q5} />
                              </label>
                            </div>
                            <div className={cb36 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j36"
                                name="3"
                                value={6}
                                onChange={(event) => {
                                  setJB3(event.target.value);
                                  setCb36(event.target.checked);
                                  setCb32(false);
                                  setCb33(false);
                                  setCb34(false);
                                  setCb35(false);
                                  setCb31(false);
                                }}
                              />
                              <label htmlFor="j36">
                                <img src={B3Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "C" && (
                        <>
                          <img src={C3} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div className={cc31 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j31"
                                name="3"
                                value={1}
                                onChange={(event) => {
                                  setJC3(event.target.value);
                                  setCc31(event.target.checked);
                                  setCc32(false);
                                  setCc33(false);
                                  setCc34(false);
                                  setCc35(false);
                                  setCc36(false);
                                  setCc37(false);
                                  setCc38(false);
                                }}
                              />
                              <label htmlFor="j31">
                                <img src={C3Q1} />
                              </label>
                            </div>
                            <div className={cc32 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j32"
                                name="3"
                                value={2}
                                onChange={(event) => {
                                  setJC3(event.target.value);
                                  setCc32(event.target.checked);
                                  setCc31(false);
                                  setCc33(false);
                                  setCc34(false);
                                  setCc35(false);
                                  setCc36(false);
                                  setCc37(false);
                                  setCc38(false);
                                }}
                              />
                              <label htmlFor="j32">
                                <img src={C3Q2} />
                              </label>
                            </div>
                            <div className={cc33 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j33"
                                name="3"
                                value={3}
                                onChange={(event) => {
                                  setJC3(event.target.value);
                                  setCc33(event.target.checked);
                                  setCc31(false);
                                  setCc32(false);
                                  setCc34(false);
                                  setCc35(false);
                                  setCc36(false);
                                  setCc37(false);
                                  setCc38(false);
                                }}
                              />
                              <label htmlFor="j33">
                                <img src={C3Q3} />
                              </label>
                            </div>
                            <div className={cc34 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j34"
                                name="3"
                                value={4}
                                onChange={(event) => {
                                  setJC3(event.target.value);
                                  setCc34(event.target.checked);
                                  setCc31(false);
                                  setCc32(false);
                                  setCc33(false);
                                  setCc35(false);
                                  setCc36(false);
                                  setCc37(false);
                                  setCc38(false);
                                }}
                              />
                              <label htmlFor="j34">
                                <img src={C3Q4} />
                              </label>
                            </div>
                            <div className={cc35 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j35"
                                name="3"
                                value={5}
                                onChange={(event) => {
                                  setJC3(event.target.value);
                                  setCc35(event.target.checked);
                                  setCc31(false);
                                  setCc32(false);
                                  setCc33(false);
                                  setCc34(false);
                                  setCc36(false);
                                  setCc37(false);
                                  setCc38(false);
                                }}
                              />
                              <label htmlFor="j35">
                                <img src={C3Q5} />
                              </label>
                            </div>
                            <div className={cc36 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j36"
                                name="3"
                                value={6}
                                onChange={(event) => {
                                  setJC3(event.target.value);
                                  setCc36(event.target.checked);
                                  setCc31(false);
                                  setCc32(false);
                                  setCc33(false);
                                  setCc34(false);
                                  setCc35(false);
                                  setCc37(false);
                                  setCc38(false);
                                }}
                              />
                              <label htmlFor="j36">
                                <img src={C3Q6} />
                              </label>
                            </div>
                            <div className={cc37 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j37"
                                name="3"
                                value={7}
                                onChange={(event) => {
                                  setJC3(event.target.value);
                                  setCc37(event.target.checked);
                                  setCc31(false);
                                  setCc32(false);
                                  setCc33(false);
                                  setCc34(false);
                                  setCc35(false);
                                  setCc36(false);
                                  setCc38(false);
                                }}
                              />
                              <label htmlFor="j37">
                                <img src={C3Q7} />
                              </label>
                            </div>
                            <div className={cc38 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j38"
                                name="3"
                                value={8}
                                onChange={(event) => {
                                  setJC3(event.target.value);
                                  setCc38(event.target.checked);
                                  setCc31(false);
                                  setCc32(false);
                                  setCc33(false);
                                  setCc34(false);
                                  setCc35(false);
                                  setCc36(false);
                                  setCc37(false);
                                }}
                              />
                              <label htmlFor="j38">
                                <img src={C3Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "D" && (
                        <>
                          <img src={D3} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div className={cd31 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j31"
                                name="3"
                                value={1}
                                onChange={(event) => {
                                  setJD3(event.target.value);
                                  setCd31(event.target.checked);
                                  setCd32(false);
                                  setCd33(false);
                                  setCd34(false);
                                  setCd35(false);
                                  setCd36(false);
                                  setCd37(false);
                                  setCd38(false);
                                }}
                              />
                              <label htmlFor="j31">
                                <img src={D3Q1} />
                              </label>
                            </div>
                            <div className={cd32 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j32"
                                name="3"
                                value={2}
                                onChange={(event) => {
                                  setJD3(event.target.value);
                                  setCd32(event.target.checked);
                                  setCd31(false);
                                  setCd33(false);
                                  setCd34(false);
                                  setCd35(false);
                                  setCd36(false);
                                  setCd37(false);
                                  setCd38(false);
                                }}
                              />
                              <label htmlFor="j32">
                                <img src={D3Q2} />
                              </label>
                            </div>
                            <div className={cd33 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j33"
                                name="3"
                                value={3}
                                onChange={(event) => {
                                  setJD3(event.target.value);
                                  setCd33(event.target.checked);
                                  setCd31(false);
                                  setCd32(false);
                                  setCd34(false);
                                  setCd35(false);
                                  setCd36(false);
                                  setCd37(false);
                                  setCd38(false);
                                }}
                              />
                              <label htmlFor="j33">
                                <img src={D3Q3} />
                              </label>
                            </div>
                            <div className={cd34 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j34"
                                name="3"
                                value={4}
                                onChange={(event) => {
                                  setJD3(event.target.value);
                                  setCd34(event.target.checked);
                                  setCd31(false);
                                  setCd32(false);
                                  setCd33(false);
                                  setCd35(false);
                                  setCd36(false);
                                  setCd37(false);
                                  setCd38(false);
                                }}
                              />
                              <label htmlFor="j34">
                                <img src={D3Q4} />
                              </label>
                            </div>
                            <div className={cd35 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j35"
                                name="3"
                                value={5}
                                onChange={(event) => {
                                  setJD3(event.target.value);
                                  setCd35(event.target.checked);
                                  setCd31(false);
                                  setCd32(false);
                                  setCd33(false);
                                  setCd34(false);
                                  setCd36(false);
                                  setCd37(false);
                                  setCd38(false);
                                }}
                              />
                              <label htmlFor="j35">
                                <img src={D3Q5} />
                              </label>
                            </div>
                            <div className={cd36 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j36"
                                name="3"
                                value={6}
                                onChange={(event) => {
                                  setJD3(event.target.value);
                                  setCd36(event.target.checked);
                                  setCd31(false);
                                  setCd32(false);
                                  setCd33(false);
                                  setCd34(false);
                                  setCd35(false);
                                  setCd37(false);
                                  setCd38(false);
                                }}
                              />
                              <label htmlFor="j36">
                                <img src={D3Q6} />
                              </label>
                            </div>
                            <div className={cd37 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j37"
                                name="3"
                                value={7}
                                onChange={(event) => {
                                  setJD3(event.target.value);
                                  setCd37(event.target.checked);
                                  setCd31(false);
                                  setCd32(false);
                                  setCd33(false);
                                  setCd34(false);
                                  setCd35(false);
                                  setCd36(false);
                                  setCd38(false);
                                }}
                              />
                              <label htmlFor="j37">
                                <img src={D3Q7} />
                              </label>
                            </div>
                            <div className={cd38 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j38"
                                name="3"
                                value={8}
                                onChange={(event) => {
                                  setJD3(event.target.value);
                                  setCd38(event.target.checked);
                                  setCd31(false);
                                  setCd32(false);
                                  setCd33(false);
                                  setCd34(false);
                                  setCd35(false);
                                  setCd36(false);
                                  setCd37(false);
                                }}
                              />
                              <label htmlFor="j38">
                                <img src={D3Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "E" && (
                        <>
                          <img src={E3} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div className={ce31 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j31"
                                name="3"
                                value={1}
                                onChange={(event) => {
                                  setJE3(event.target.value);
                                  setCe31(event.target.checked);
                                  setCe32(false);
                                  setCe33(false);
                                  setCe34(false);
                                  setCe35(false);
                                  setCe36(false);
                                  setCe37(false);
                                  setCe38(false);
                                }}
                              />
                              <label htmlFor="j31">
                                <img src={E3Q1} />
                              </label>
                            </div>
                            <div className={ce32 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j32"
                                name="3"
                                value={2}
                                onChange={(event) => {
                                  setJE3(event.target.value);
                                  setCe32(event.target.checked);
                                  setCe31(false);
                                  setCe33(false);
                                  setCe34(false);
                                  setCe35(false);
                                  setCe36(false);
                                  setCe37(false);
                                  setCe38(false);
                                }}
                              />
                              <label htmlFor="j32">
                                <img src={E3Q2} />
                              </label>
                            </div>
                            <div className={ce33 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j33"
                                name="3"
                                value={3}
                                onChange={(event) => {
                                  setJE3(event.target.value);
                                  setCe33(event.target.checked);
                                  setCe31(false);
                                  setCe32(false);
                                  setCe34(false);
                                  setCe35(false);
                                  setCe36(false);
                                  setCe37(false);
                                  setCe38(false);
                                }}
                              />
                              <label htmlFor="j33">
                                <img src={E3Q3} />
                              </label>
                            </div>
                            <div className={ce34 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j34"
                                name="3"
                                value={4}
                                onChange={(event) => {
                                  setJE3(event.target.value);
                                  setCe34(event.target.checked);
                                  setCe31(false);
                                  setCe32(false);
                                  setCe33(false);
                                  setCe35(false);
                                  setCe36(false);
                                  setCe37(false);
                                  setCe38(false);
                                }}
                              />
                              <label htmlFor="j34">
                                <img src={E3Q4} />
                              </label>
                            </div>
                            <div className={ce35 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j35"
                                name="3"
                                value={5}
                                onChange={(event) => {
                                  setJE3(event.target.value);
                                  setCe35(event.target.checked);
                                  setCe31(false);
                                  setCe32(false);
                                  setCe33(false);
                                  setCe34(false);
                                  setCe36(false);
                                  setCe37(false);
                                  setCe38(false);
                                }}
                              />
                              <label htmlFor="j35">
                                <img src={E3Q5} />
                              </label>
                            </div>
                            <div className={ce36 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j36"
                                name="3"
                                value={6}
                                onChange={(event) => {
                                  setJE3(event.target.value);
                                  setCe36(event.target.checked);
                                  setCe31(false);
                                  setCe32(false);
                                  setCe33(false);
                                  setCe34(false);
                                  setCe35(false);
                                  setCe37(false);
                                  setCe38(false);
                                }}
                              />
                              <label htmlFor="j36">
                                <img src={E3Q6} />
                              </label>
                            </div>
                            <div className={ce37 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j37"
                                name="3"
                                value={7}
                                onChange={(event) => {
                                  setJE3(event.target.value);
                                  setCe37(event.target.checked);
                                  setCe31(false);
                                  setCe32(false);
                                  setCe33(false);
                                  setCe34(false);
                                  setCe35(false);
                                  setCe36(false);
                                  setCe38(false);
                                }}
                              />
                              <label htmlFor="j37">
                                <img src={E3Q7} />
                              </label>
                            </div>
                            <div className={ce38 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j38"
                                name="3"
                                value={8}
                                onChange={(event) => {
                                  setJE3(event.target.value);
                                  setCe38(event.target.checked);
                                  setCe31(false);
                                  setCe32(false);
                                  setCe33(false);
                                  setCe34(false);
                                  setCe35(false);
                                  setCe36(false);
                                  setCe37(false);
                                }}
                              />
                              <label htmlFor="j38">
                                <img src={E3Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {nomor % 12 === 4 && (
                    <>
                      {typeNow === "A" && (
                        <>
                          <img src={A4} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={ca41 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j41"
                                name="4"
                                value={1}
                                onChange={(event) => {
                                  setJA4(event.target.value);
                                  setCa41(event.target.checked);
                                  setCa42(false);
                                  setCa43(false);
                                  setCa44(false);
                                  setCa45(false);
                                  setCa46(false);
                                }}
                              />
                              <label htmlFor="j41">
                                <img src={A4Q1} />
                              </label>
                            </div>
                            <div className={ca42 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j42"
                                name="4"
                                value={2}
                                onChange={(event) => {
                                  setJA4(event.target.value);
                                  setCa42(event.target.checked);
                                  setCa41(false);
                                  setCa43(false);
                                  setCa44(false);
                                  setCa45(false);
                                  setCa46(false);
                                }}
                              />
                              <label htmlFor="j42">
                                <img src={A4Q2} />
                              </label>
                            </div>
                            <div className={ca43 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j43"
                                name="4"
                                value={3}
                                onChange={(event) => {
                                  setJA4(event.target.value);
                                  setCa43(event.target.checked);
                                  setCa42(false);
                                  setCa41(false);
                                  setCa44(false);
                                  setCa45(false);
                                  setCa46(false);
                                }}
                              />
                              <label htmlFor="j43">
                                <img src={A4Q3} />
                              </label>
                            </div>
                            <div className={ca44 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j44"
                                name="4"
                                value={4}
                                onChange={(event) => {
                                  setJA4(event.target.value);
                                  setCa44(event.target.checked);
                                  setCa42(false);
                                  setCa43(false);
                                  setCa41(false);
                                  setCa45(false);
                                  setCa46(false);
                                }}
                              />
                              <label htmlFor="j44">
                                <img src={A4Q4} />
                              </label>
                            </div>
                            <div className={ca45 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j45"
                                name="4"
                                value={5}
                                onChange={(event) => {
                                  setJA4(event.target.value);
                                  setCa45(event.target.checked);
                                  setCa42(false);
                                  setCa43(false);
                                  setCa44(false);
                                  setCa41(false);
                                  setCa46(false);
                                }}
                              />
                              <label htmlFor="j45">
                                <img src={A4Q5} />
                              </label>
                            </div>
                            <div className={ca46 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j46"
                                name="4"
                                value={6}
                                onChange={(event) => {
                                  setJA4(event.target.value);
                                  setCa46(event.target.checked);
                                  setCa42(false);
                                  setCa43(false);
                                  setCa44(false);
                                  setCa45(false);
                                  setCa41(false);
                                }}
                              />
                              <label htmlFor="j46">
                                <img src={A4Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "B" && (
                        <>
                          <img src={B4} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={cb41 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j41"
                                name="4"
                                value={1}
                                onChange={(event) => {
                                  setJB4(event.target.value);
                                  setCb41(event.target.checked);
                                  setCb42(false);
                                  setCb43(false);
                                  setCb44(false);
                                  setCb45(false);
                                  setCb46(false);
                                }}
                              />
                              <label htmlFor="j41">
                                <img src={B4Q1} />
                              </label>
                            </div>
                            <div className={cb42 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j42"
                                name="4"
                                value={2}
                                onChange={(event) => {
                                  setJB4(event.target.value);
                                  setCb42(event.target.checked);
                                  setCb41(false);
                                  setCb43(false);
                                  setCb44(false);
                                  setCb45(false);
                                  setCb46(false);
                                }}
                              />
                              <label htmlFor="j42">
                                <img src={B4Q2} />
                              </label>
                            </div>
                            <div className={cb43 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j43"
                                name="4"
                                value={3}
                                onChange={(event) => {
                                  setJB4(event.target.value);
                                  setCb43(event.target.checked);
                                  setCb42(false);
                                  setCb41(false);
                                  setCb44(false);
                                  setCb45(false);
                                  setCb46(false);
                                }}
                              />
                              <label htmlFor="j43">
                                <img src={B4Q3} />
                              </label>
                            </div>
                            <div className={cb44 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j44"
                                name="4"
                                value={4}
                                onChange={(event) => {
                                  setJB4(event.target.value);
                                  setCb44(event.target.checked);
                                  setCb42(false);
                                  setCb43(false);
                                  setCb41(false);
                                  setCb45(false);
                                  setCb46(false);
                                }}
                              />
                              <label htmlFor="j44">
                                <img src={B4Q4} />
                              </label>
                            </div>
                            <div className={cb45 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j45"
                                name="4"
                                value={5}
                                onChange={(event) => {
                                  setJB4(event.target.value);
                                  setCb45(event.target.checked);
                                  setCb42(false);
                                  setCb43(false);
                                  setCb44(false);
                                  setCb41(false);
                                  setCb46(false);
                                }}
                              />
                              <label htmlFor="j45">
                                <img src={B4Q5} />
                              </label>
                            </div>
                            <div className={cb46 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j46"
                                name="4"
                                value={6}
                                onChange={(event) => {
                                  setJB4(event.target.value);
                                  setCb46(event.target.checked);
                                  setCb42(false);
                                  setCb43(false);
                                  setCb44(false);
                                  setCb45(false);
                                  setCb41(false);
                                }}
                              />
                              <label htmlFor="j46">
                                <img src={B4Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "C" && (
                        <>
                          <img src={C4} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div className={cc41 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j41"
                                name="4"
                                value={1}
                                onChange={(event) => {
                                  setJC4(event.target.value);
                                  setCc41(event.target.checked);
                                  setCc42(false);
                                  setCc43(false);
                                  setCc44(false);
                                  setCc45(false);
                                  setCc46(false);
                                  setCc47(false);
                                  setCc48(false);
                                }}
                              />
                              <label htmlFor="j41">
                                <img src={C4Q1} />
                              </label>
                            </div>
                            <div className={cc42 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j42"
                                name="4"
                                value={2}
                                onChange={(event) => {
                                  setJC4(event.target.value);
                                  setCc42(event.target.checked);
                                  setCc41(false);
                                  setCc43(false);
                                  setCc44(false);
                                  setCc45(false);
                                  setCc46(false);
                                  setCc47(false);
                                  setCc48(false);
                                }}
                              />
                              <label htmlFor="j42">
                                <img src={C4Q2} />
                              </label>
                            </div>
                            <div className={cc43 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j43"
                                name="4"
                                value={3}
                                onChange={(event) => {
                                  setJC4(event.target.value);
                                  setCc43(event.target.checked);
                                  setCc41(false);
                                  setCc42(false);
                                  setCc44(false);
                                  setCc45(false);
                                  setCc46(false);
                                  setCc47(false);
                                  setCc48(false);
                                }}
                              />
                              <label htmlFor="j43">
                                <img src={C4Q3} />
                              </label>
                            </div>
                            <div className={cc44 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j44"
                                name="4"
                                value={4}
                                onChange={(event) => {
                                  setJC4(event.target.value);
                                  setCc44(event.target.checked);
                                  setCc41(false);
                                  setCc42(false);
                                  setCc43(false);
                                  setCc45(false);
                                  setCc46(false);
                                  setCc47(false);
                                  setCc48(false);
                                }}
                              />
                              <label htmlFor="j44">
                                <img src={C4Q4} />
                              </label>
                            </div>
                            <div className={cc45 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j45"
                                name="4"
                                value={5}
                                onChange={(event) => {
                                  setJC4(event.target.value);
                                  setCc45(event.target.checked);
                                  setCc41(false);
                                  setCc42(false);
                                  setCc43(false);
                                  setCc44(false);
                                  setCc46(false);
                                  setCc47(false);
                                  setCc48(false);
                                }}
                              />
                              <label htmlFor="j45">
                                <img src={C4Q5} />
                              </label>
                            </div>
                            <div className={cc46 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j46"
                                name="4"
                                value={6}
                                onChange={(event) => {
                                  setJC4(event.target.value);
                                  setCc46(event.target.checked);
                                  setCc41(false);
                                  setCc42(false);
                                  setCc43(false);
                                  setCc44(false);
                                  setCc45(false);
                                  setCc47(false);
                                  setCc48(false);
                                }}
                              />
                              <label htmlFor="j46">
                                <img src={C4Q6} />
                              </label>
                            </div>
                            <div className={cc47 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j47"
                                name="4"
                                value={7}
                                onChange={(event) => {
                                  setJC4(event.target.value);
                                  setCc47(event.target.checked);
                                  setCc41(false);
                                  setCc42(false);
                                  setCc43(false);
                                  setCc44(false);
                                  setCc45(false);
                                  setCc46(false);
                                  setCc48(false);
                                }}
                              />
                              <label htmlFor="j47">
                                <img src={C4Q7} />
                              </label>
                            </div>
                            <div className={cc48 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j48"
                                name="4"
                                value={8}
                                onChange={(event) => {
                                  setJC4(event.target.value);
                                  setCc48(event.target.checked);
                                  setCc41(false);
                                  setCc42(false);
                                  setCc43(false);
                                  setCc44(false);
                                  setCc45(false);
                                  setCc46(false);
                                  setCc47(false);
                                }}
                              />
                              <label htmlFor="j48">
                                <img src={C4Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "D" && (
                        <>
                          <img src={D4} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div className={cd41 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j41"
                                name="4"
                                value={1}
                                onChange={(event) => {
                                  setJD4(event.target.value);
                                  setCd41(event.target.checked);
                                  setCd42(false);
                                  setCd43(false);
                                  setCd44(false);
                                  setCd45(false);
                                  setCd46(false);
                                  setCd47(false);
                                  setCd48(false);
                                }}
                              />
                              <label htmlFor="j41">
                                <img src={D4Q1} />
                              </label>
                            </div>
                            <div className={cd42 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j42"
                                name="4"
                                value={2}
                                onChange={(event) => {
                                  setJD4(event.target.value);
                                  setCd42(event.target.checked);
                                  setCd41(false);
                                  setCd43(false);
                                  setCd44(false);
                                  setCd45(false);
                                  setCd46(false);
                                  setCd47(false);
                                  setCd48(false);
                                }}
                              />
                              <label htmlFor="j42">
                                <img src={D4Q2} />
                              </label>
                            </div>
                            <div className={cd43 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j43"
                                name="4"
                                value={3}
                                onChange={(event) => {
                                  setJD4(event.target.value);
                                  setCd43(event.target.checked);
                                  setCd41(false);
                                  setCd42(false);
                                  setCd44(false);
                                  setCd45(false);
                                  setCd46(false);
                                  setCd47(false);
                                  setCd48(false);
                                }}
                              />
                              <label htmlFor="j43">
                                <img src={D4Q3} />
                              </label>
                            </div>
                            <div className={cd44 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j44"
                                name="4"
                                value={4}
                                onChange={(event) => {
                                  setJD4(event.target.value);
                                  setCd44(event.target.checked);
                                  setCd41(false);
                                  setCd42(false);
                                  setCd43(false);
                                  setCd45(false);
                                  setCd46(false);
                                  setCd47(false);
                                  setCd48(false);
                                }}
                              />
                              <label htmlFor="j44">
                                <img src={D4Q4} />
                              </label>
                            </div>
                            <div className={cd45 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j45"
                                name="4"
                                value={5}
                                onChange={(event) => {
                                  setJD4(event.target.value);
                                  setCd45(event.target.checked);
                                  setCd41(false);
                                  setCd42(false);
                                  setCd43(false);
                                  setCd44(false);
                                  setCd46(false);
                                  setCd47(false);
                                  setCd48(false);
                                }}
                              />
                              <label htmlFor="j45">
                                <img src={D4Q5} />
                              </label>
                            </div>
                            <div className={cd46 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j46"
                                name="4"
                                value={6}
                                onChange={(event) => {
                                  setJD4(event.target.value);
                                  setCd46(event.target.checked);
                                  setCd41(false);
                                  setCd42(false);
                                  setCd43(false);
                                  setCd44(false);
                                  setCd45(false);
                                  setCd47(false);
                                  setCd48(false);
                                }}
                              />
                              <label htmlFor="j46">
                                <img src={D4Q6} />
                              </label>
                            </div>
                            <div className={cd47 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j47"
                                name="4"
                                value={7}
                                onChange={(event) => {
                                  setJD4(event.target.value);
                                  setCd47(event.target.checked);
                                  setCd41(false);
                                  setCd42(false);
                                  setCd43(false);
                                  setCd44(false);
                                  setCd45(false);
                                  setCd46(false);
                                  setCd48(false);
                                }}
                              />
                              <label htmlFor="j47">
                                <img src={D4Q7} />
                              </label>
                            </div>
                            <div className={cd48 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j48"
                                name="4"
                                value={8}
                                onChange={(event) => {
                                  setJD4(event.target.value);
                                  setCd48(event.target.checked);
                                  setCd41(false);
                                  setCd42(false);
                                  setCd43(false);
                                  setCd44(false);
                                  setCd45(false);
                                  setCd46(false);
                                  setCd47(false);
                                }}
                              />
                              <label htmlFor="j48">
                                <img src={D4Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "E" && (
                        <>
                          <img src={E4} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div className={ce41 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j41"
                                name="4"
                                value={1}
                                onChange={(event) => {
                                  setJE4(event.target.value);
                                  setCe41(event.target.checked);
                                  setCe42(false);
                                  setCe43(false);
                                  setCe44(false);
                                  setCe45(false);
                                  setCe46(false);
                                  setCe47(false);
                                  setCe48(false);
                                }}
                              />
                              <label htmlFor="j41">
                                <img src={E4Q1} />
                              </label>
                            </div>
                            <div className={ce42 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j42"
                                name="4"
                                value={2}
                                onChange={(event) => {
                                  setJE4(event.target.value);
                                  setCe42(event.target.checked);
                                  setCe41(false);
                                  setCe43(false);
                                  setCe44(false);
                                  setCe45(false);
                                  setCe46(false);
                                  setCe47(false);
                                  setCe48(false);
                                }}
                              />
                              <label htmlFor="j42">
                                <img src={E4Q2} />
                              </label>
                            </div>
                            <div className={ce43 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j43"
                                name="4"
                                value={3}
                                onChange={(event) => {
                                  setJE4(event.target.value);
                                  setCe43(event.target.checked);
                                  setCe41(false);
                                  setCe42(false);
                                  setCe44(false);
                                  setCe45(false);
                                  setCe46(false);
                                  setCe47(false);
                                  setCe48(false);
                                }}
                              />
                              <label htmlFor="j43">
                                <img src={E4Q3} />
                              </label>
                            </div>
                            <div className={ce44 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j44"
                                name="4"
                                value={4}
                                onChange={(event) => {
                                  setJE4(event.target.value);
                                  setCe44(event.target.checked);
                                  setCe41(false);
                                  setCe42(false);
                                  setCe43(false);
                                  setCe45(false);
                                  setCe46(false);
                                  setCe47(false);
                                  setCe48(false);
                                }}
                              />
                              <label htmlFor="j44">
                                <img src={E4Q4} />
                              </label>
                            </div>
                            <div className={ce45 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j45"
                                name="4"
                                value={5}
                                onChange={(event) => {
                                  setJE4(event.target.value);
                                  setCe45(event.target.checked);
                                  setCe41(false);
                                  setCe42(false);
                                  setCe43(false);
                                  setCe44(false);
                                  setCe46(false);
                                  setCe47(false);
                                  setCe48(false);
                                }}
                              />
                              <label htmlFor="j45">
                                <img src={E4Q5} />
                              </label>
                            </div>
                            <div className={ce46 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j46"
                                name="4"
                                value={6}
                                onChange={(event) => {
                                  setJE4(event.target.value);
                                  setCe46(event.target.checked);
                                  setCe41(false);
                                  setCe42(false);
                                  setCe43(false);
                                  setCe44(false);
                                  setCe45(false);
                                  setCe47(false);
                                  setCe48(false);
                                }}
                              />
                              <label htmlFor="j46">
                                <img src={E4Q6} />
                              </label>
                            </div>
                            <div className={ce47 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j47"
                                name="4"
                                value={7}
                                onChange={(event) => {
                                  setJE4(event.target.value);
                                  setCe47(event.target.checked);
                                  setCe41(false);
                                  setCe42(false);
                                  setCe43(false);
                                  setCe44(false);
                                  setCe45(false);
                                  setCe46(false);
                                  setCe48(false);
                                }}
                              />
                              <label htmlFor="j47">
                                <img src={E4Q7} />
                              </label>
                            </div>
                            <div className={ce48 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j48"
                                name="4"
                                value={8}
                                onChange={(event) => {
                                  setJE4(event.target.value);
                                  setCe48(event.target.checked);
                                  setCe41(false);
                                  setCe42(false);
                                  setCe43(false);
                                  setCe44(false);
                                  setCe45(false);
                                  setCe46(false);
                                  setCe47(false);
                                }}
                              />
                              <label htmlFor="j48">
                                <img src={E4Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {nomor % 12 === 5 && (
                    <>
                      {typeNow === "A" && (
                        <>
                          <img src={A5} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={ca51 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j51"
                                name="5"
                                value={1}
                                onChange={(event) => {
                                  setJA5(event.target.value);
                                  setCa51(event.target.checked);
                                  setCa52(false);
                                  setCa53(false);
                                  setCa54(false);
                                  setCa55(false);
                                  setCa56(false);
                                }}
                              />
                              <label htmlFor="j51">
                                <img src={A5Q1} />
                              </label>
                            </div>
                            <div className={ca52 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j52"
                                name="5"
                                value={2}
                                onChange={(event) => {
                                  setJA5(event.target.value);
                                  setCa52(event.target.checked);
                                  setCa51(false);
                                  setCa53(false);
                                  setCa54(false);
                                  setCa55(false);
                                  setCa56(false);
                                }}
                              />
                              <label htmlFor="j52">
                                <img src={A5Q2} />
                              </label>
                            </div>
                            <div className={ca53 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j53"
                                name="5"
                                value={3}
                                onChange={(event) => {
                                  setJA5(event.target.value);
                                  setCa53(event.target.checked);
                                  setCa52(false);
                                  setCa51(false);
                                  setCa54(false);
                                  setCa55(false);
                                  setCa56(false);
                                }}
                              />
                              <label htmlFor="j53">
                                <img src={A5Q3} />
                              </label>
                            </div>
                            <div className={ca54 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j54"
                                name="5"
                                value={4}
                                onChange={(event) => {
                                  setJA5(event.target.value);
                                  setCa54(event.target.checked);
                                  setCa52(false);
                                  setCa53(false);
                                  setCa51(false);
                                  setCa55(false);
                                  setCa56(false);
                                }}
                              />
                              <label htmlFor="j54">
                                <img src={A5Q4} />
                              </label>
                            </div>
                            <div className={ca55 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j55"
                                name="5"
                                value={5}
                                onChange={(event) => {
                                  setJA5(event.target.value);
                                  setCa55(event.target.checked);
                                  setCa52(false);
                                  setCa53(false);
                                  setCa54(false);
                                  setCa51(false);
                                  setCa56(false);
                                }}
                              />
                              <label htmlFor="j55">
                                <img src={A5Q5} />
                              </label>
                            </div>
                            <div className={ca56 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j56"
                                name="5"
                                value={6}
                                onChange={(event) => {
                                  setJA5(event.target.value);
                                  setCa56(event.target.checked);
                                  setCa52(false);
                                  setCa53(false);
                                  setCa54(false);
                                  setCa55(false);
                                  setCa51(false);
                                }}
                              />
                              <label htmlFor="j56">
                                <img src={A5Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "B" && (
                        <>
                          <img src={B5} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={cb51 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j51"
                                name="5"
                                value={1}
                                onChange={(event) => {
                                  setJB5(event.target.value);
                                  setCb51(event.target.checked);
                                  setCb52(false);
                                  setCb53(false);
                                  setCb54(false);
                                  setCb55(false);
                                  setCb56(false);
                                }}
                              />
                              <label htmlFor="j51">
                                <img src={B5Q1} />
                              </label>
                            </div>
                            <div className={cb52 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j52"
                                name="5"
                                value={2}
                                onChange={(event) => {
                                  setJB5(event.target.value);
                                  setCb52(event.target.checked);
                                  setCb51(false);
                                  setCb53(false);
                                  setCb54(false);
                                  setCb55(false);
                                  setCb56(false);
                                }}
                              />
                              <label htmlFor="j52">
                                <img src={B5Q2} />
                              </label>
                            </div>
                            <div className={cb53 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j53"
                                name="5"
                                value={3}
                                onChange={(event) => {
                                  setJB5(event.target.value);
                                  setCb53(event.target.checked);
                                  setCb52(false);
                                  setCb51(false);
                                  setCb54(false);
                                  setCb55(false);
                                  setCb56(false);
                                }}
                              />
                              <label htmlFor="j53">
                                <img src={B5Q3} />
                              </label>
                            </div>
                            <div className={cb54 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j54"
                                name="5"
                                value={4}
                                onChange={(event) => {
                                  setJB5(event.target.value);
                                  setCb54(event.target.checked);
                                  setCb52(false);
                                  setCb53(false);
                                  setCb51(false);
                                  setCb55(false);
                                  setCb56(false);
                                }}
                              />
                              <label htmlFor="j54">
                                <img src={B5Q4} />
                              </label>
                            </div>
                            <div className={cb55 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j55"
                                name="5"
                                value={5}
                                onChange={(event) => {
                                  setJB5(event.target.value);
                                  setCb55(event.target.checked);
                                  setCb52(false);
                                  setCb53(false);
                                  setCb54(false);
                                  setCb51(false);
                                  setCb56(false);
                                }}
                              />
                              <label htmlFor="j55">
                                <img src={B5Q5} />
                              </label>
                            </div>
                            <div className={cb56 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j56"
                                name="5"
                                value={6}
                                onChange={(event) => {
                                  setJB5(event.target.value);
                                  setCb56(event.target.checked);
                                  setCb52(false);
                                  setCb53(false);
                                  setCb54(false);
                                  setCb55(false);
                                  setCb51(false);
                                }}
                              />
                              <label htmlFor="j56">
                                <img src={B5Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "C" && (
                        <>
                          <img src={C5} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div className={cc51 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j51"
                                name="5"
                                value={1}
                                onChange={(event) => {
                                  setJC5(event.target.value);
                                  setCc51(event.target.checked);
                                  setCc52(false);
                                  setCc53(false);
                                  setCc54(false);
                                  setCc55(false);
                                  setCc56(false);
                                  setCc57(false);
                                  setCc58(false);
                                }}
                              />
                              <label htmlFor="j51">
                                <img src={C5Q1} />
                              </label>
                            </div>
                            <div className={cc52 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j52"
                                name="5"
                                value={2}
                                onChange={(event) => {
                                  setJC5(event.target.value);
                                  setCc52(event.target.checked);
                                  setCc51(false);
                                  setCc53(false);
                                  setCc54(false);
                                  setCc55(false);
                                  setCc56(false);
                                  setCc57(false);
                                  setCc58(false);
                                }}
                              />
                              <label htmlFor="j52">
                                <img src={C5Q2} />
                              </label>
                            </div>
                            <div className={cc53 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j53"
                                name="5"
                                value={3}
                                onChange={(event) => {
                                  setJC5(event.target.value);
                                  setCc53(event.target.checked);
                                  setCc51(false);
                                  setCc52(false);
                                  setCc54(false);
                                  setCc55(false);
                                  setCc56(false);
                                  setCc57(false);
                                  setCc58(false);
                                }}
                              />
                              <label htmlFor="j53">
                                <img src={C5Q3} />
                              </label>
                            </div>
                            <div className={cc54 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j54"
                                name="5"
                                value={4}
                                onChange={(event) => {
                                  setJC5(event.target.value);
                                  setCc54(event.target.checked);
                                  setCc51(false);
                                  setCc52(false);
                                  setCc53(false);
                                  setCc55(false);
                                  setCc56(false);
                                  setCc57(false);
                                  setCc58(false);
                                }}
                              />
                              <label htmlFor="j54">
                                <img src={C5Q4} />
                              </label>
                            </div>
                            <div className={cc55 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j55"
                                name="5"
                                value={5}
                                onChange={(event) => {
                                  setJC5(event.target.value);
                                  setCc55(event.target.checked);
                                  setCc51(false);
                                  setCc52(false);
                                  setCc53(false);
                                  setCc54(false);
                                  setCc56(false);
                                  setCc57(false);
                                  setCc58(false);
                                }}
                              />
                              <label htmlFor="j55">
                                <img src={C5Q5} />
                              </label>
                            </div>
                            <div className={cc56 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j56"
                                name="5"
                                value={6}
                                onChange={(event) => {
                                  setJC5(event.target.value);
                                  setCc56(event.target.checked);
                                  setCc51(false);
                                  setCc52(false);
                                  setCc53(false);
                                  setCc54(false);
                                  setCc55(false);
                                  setCc57(false);
                                  setCc58(false);
                                }}
                              />
                              <label htmlFor="j56">
                                <img src={C5Q6} />
                              </label>
                            </div>
                            <div className={cc57 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j57"
                                name="5"
                                value={7}
                                onChange={(event) => {
                                  setJC5(event.target.value);
                                  setCc57(event.target.checked);
                                  setCc51(false);
                                  setCc52(false);
                                  setCc53(false);
                                  setCc54(false);
                                  setCc55(false);
                                  setCc56(false);
                                  setCc58(false);
                                }}
                              />
                              <label htmlFor="j57">
                                <img src={C5Q7} />
                              </label>
                            </div>
                            <div className={cc58 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j58"
                                name="5"
                                value={8}
                                onChange={(event) => {
                                  setJC5(event.target.value);
                                  setCc58(event.target.checked);
                                  setCc51(false);
                                  setCc52(false);
                                  setCc53(false);
                                  setCc54(false);
                                  setCc55(false);
                                  setCc56(false);
                                  setCc57(false);
                                }}
                              />
                              <label htmlFor="j58">
                                <img src={C5Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "D" && (
                        <>
                          <img src={D5} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div className={cd51 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j51"
                                name="5"
                                value={1}
                                onChange={(event) => {
                                  setJD5(event.target.value);
                                  setCd51(event.target.checked);
                                  setCd52(false);
                                  setCd53(false);
                                  setCd54(false);
                                  setCd55(false);
                                  setCd56(false);
                                  setCd57(false);
                                  setCd58(false);
                                }}
                              />
                              <label htmlFor="j51">
                                <img src={D5Q1} />
                              </label>
                            </div>
                            <div className={cd52 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j52"
                                name="5"
                                value={2}
                                onChange={(event) => {
                                  setJD5(event.target.value);
                                  setCd52(event.target.checked);
                                  setCd51(false);
                                  setCd53(false);
                                  setCd54(false);
                                  setCd55(false);
                                  setCd56(false);
                                  setCd57(false);
                                  setCd58(false);
                                }}
                              />
                              <label htmlFor="j52">
                                <img src={D5Q2} />
                              </label>
                            </div>
                            <div className={cd53 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j53"
                                name="5"
                                value={3}
                                onChange={(event) => {
                                  setJD5(event.target.value);
                                  setCd53(event.target.checked);
                                  setCd51(false);
                                  setCd52(false);
                                  setCd54(false);
                                  setCd55(false);
                                  setCd56(false);
                                  setCd57(false);
                                  setCd58(false);
                                }}
                              />
                              <label htmlFor="j53">
                                <img src={D5Q3} />
                              </label>
                            </div>
                            <div className={cd54 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j54"
                                name="5"
                                value={4}
                                onChange={(event) => {
                                  setJD5(event.target.value);
                                  setCd54(event.target.checked);
                                  setCd51(false);
                                  setCd52(false);
                                  setCd53(false);
                                  setCd55(false);
                                  setCd56(false);
                                  setCd57(false);
                                  setCd58(false);
                                }}
                              />
                              <label htmlFor="j54">
                                <img src={D5Q4} />
                              </label>
                            </div>
                            <div className={cd55 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j55"
                                name="5"
                                value={5}
                                onChange={(event) => {
                                  setJD5(event.target.value);
                                  setCd55(event.target.checked);
                                  setCd51(false);
                                  setCd52(false);
                                  setCd53(false);
                                  setCd54(false);
                                  setCd56(false);
                                  setCd57(false);
                                  setCd58(false);
                                }}
                              />
                              <label htmlFor="j55">
                                <img src={D5Q5} />
                              </label>
                            </div>
                            <div className={cd56 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j56"
                                name="5"
                                value={6}
                                onChange={(event) => {
                                  setJD5(event.target.value);
                                  setCd56(event.target.checked);
                                  setCd51(false);
                                  setCd52(false);
                                  setCd53(false);
                                  setCd54(false);
                                  setCd55(false);
                                  setCd57(false);
                                  setCd58(false);
                                }}
                              />
                              <label htmlFor="j56">
                                <img src={D5Q6} />
                              </label>
                            </div>
                            <div className={cd57 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j57"
                                name="5"
                                value={7}
                                onChange={(event) => {
                                  setJD5(event.target.value);
                                  setCd57(event.target.checked);
                                  setCd51(false);
                                  setCd52(false);
                                  setCd53(false);
                                  setCd54(false);
                                  setCd55(false);
                                  setCd56(false);
                                  setCd58(false);
                                }}
                              />
                              <label htmlFor="j57">
                                <img src={D5Q7} />
                              </label>
                            </div>
                            <div className={cd58 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j58"
                                name="5"
                                value={8}
                                onChange={(event) => {
                                  setJD5(event.target.value);
                                  setCd58(event.target.checked);
                                  setCd51(false);
                                  setCd52(false);
                                  setCd53(false);
                                  setCd54(false);
                                  setCd55(false);
                                  setCd56(false);
                                  setCd57(false);
                                }}
                              />
                              <label htmlFor="j58">
                                <img src={D5Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "E" && (
                        <>
                          <img src={E5} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div className={ce51 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j51"
                                name="5"
                                value={1}
                                onChange={(event) => {
                                  setJE5(event.target.value);
                                  setCe51(event.target.checked);
                                  setCe52(false);
                                  setCe53(false);
                                  setCe54(false);
                                  setCe55(false);
                                  setCe56(false);
                                  setCe57(false);
                                  setCe58(false);
                                }}
                              />
                              <label htmlFor="j51">
                                <img src={E5Q1} />
                              </label>
                            </div>
                            <div className={ce52 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j52"
                                name="5"
                                value={2}
                                onChange={(event) => {
                                  setJE5(event.target.value);
                                  setCe52(event.target.checked);
                                  setCe51(false);
                                  setCe53(false);
                                  setCe54(false);
                                  setCe55(false);
                                  setCe56(false);
                                  setCe57(false);
                                  setCe58(false);
                                }}
                              />
                              <label htmlFor="j52">
                                <img src={E5Q2} />
                              </label>
                            </div>
                            <div className={ce53 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j53"
                                name="5"
                                value={3}
                                onChange={(event) => {
                                  setJE5(event.target.value);
                                  setCe53(event.target.checked);
                                  setCe51(false);
                                  setCe52(false);
                                  setCe54(false);
                                  setCe55(false);
                                  setCe56(false);
                                  setCe57(false);
                                  setCe58(false);
                                }}
                              />
                              <label htmlFor="j53">
                                <img src={E5Q3} />
                              </label>
                            </div>
                            <div className={ce54 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j54"
                                name="5"
                                value={4}
                                onChange={(event) => {
                                  setJE5(event.target.value);
                                  setCe54(event.target.checked);
                                  setCe51(false);
                                  setCe52(false);
                                  setCe53(false);
                                  setCe55(false);
                                  setCe56(false);
                                  setCe57(false);
                                  setCe58(false);
                                }}
                              />
                              <label htmlFor="j54">
                                <img src={E5Q4} />
                              </label>
                            </div>
                            <div className={ce55 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j55"
                                name="5"
                                value={5}
                                onChange={(event) => {
                                  setJE5(event.target.value);
                                  setCe55(event.target.checked);
                                  setCe51(false);
                                  setCe52(false);
                                  setCe53(false);
                                  setCe54(false);
                                  setCe56(false);
                                  setCe57(false);
                                  setCe58(false);
                                }}
                              />
                              <label htmlFor="j55">
                                <img src={E5Q5} />
                              </label>
                            </div>
                            <div className={ce56 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j56"
                                name="5"
                                value={6}
                                onChange={(event) => {
                                  setJE5(event.target.value);
                                  setCe56(event.target.checked);
                                  setCe51(false);
                                  setCe52(false);
                                  setCe53(false);
                                  setCe54(false);
                                  setCe55(false);
                                  setCe57(false);
                                  setCe58(false);
                                }}
                              />
                              <label htmlFor="j56">
                                <img src={E5Q6} />
                              </label>
                            </div>
                            <div className={ce57 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j57"
                                name="5"
                                value={7}
                                onChange={(event) => {
                                  setJE5(event.target.value);
                                  setCe57(event.target.checked);
                                  setCe51(false);
                                  setCe52(false);
                                  setCe53(false);
                                  setCe54(false);
                                  setCe55(false);
                                  setCe56(false);
                                  setCe58(false);
                                }}
                              />
                              <label htmlFor="j57">
                                <img src={E5Q7} />
                              </label>
                            </div>
                            <div className={ce58 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j58"
                                name="5"
                                value={8}
                                onChange={(event) => {
                                  setJE5(event.target.value);
                                  setCe58(event.target.checked);
                                  setCe51(false);
                                  setCe52(false);
                                  setCe53(false);
                                  setCe54(false);
                                  setCe55(false);
                                  setCe56(false);
                                  setCe57(false);
                                }}
                              />
                              <label htmlFor="j58">
                                <img src={E5Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {nomor % 12 === 6 && (
                    <>
                      {typeNow === "A" && (
                        <>
                          <img src={A6} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={ca61 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j61"
                                name="6"
                                value={1}
                                onChange={(event) => {
                                  setJA6(event.target.value);
                                  setCa61(event.target.checked);
                                  setCa62(false);
                                  setCa63(false);
                                  setCa64(false);
                                  setCa65(false);
                                  setCa66(false);
                                }}
                              />
                              <label htmlFor="j61">
                                <img src={A6Q1} />
                              </label>
                            </div>
                            <div className={ca62 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j62"
                                name="6"
                                value={2}
                                onChange={(event) => {
                                  setJA6(event.target.value);
                                  setCa62(event.target.checked);
                                  setCa61(false);
                                  setCa63(false);
                                  setCa64(false);
                                  setCa65(false);
                                  setCa66(false);
                                }}
                              />
                              <label htmlFor="j62">
                                <img src={A6Q2} />
                              </label>
                            </div>
                            <div className={ca63 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j63"
                                name="6"
                                value={3}
                                onChange={(event) => {
                                  setJA6(event.target.value);
                                  setCa63(event.target.checked);
                                  setCa62(false);
                                  setCa61(false);
                                  setCa64(false);
                                  setCa65(false);
                                  setCa66(false);
                                }}
                              />
                              <label htmlFor="j63">
                                <img src={A6Q3} />
                              </label>
                            </div>
                            <div className={ca64 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j64"
                                name="6"
                                value={4}
                                onChange={(event) => {
                                  setJA6(event.target.value);
                                  setCa64(event.target.checked);
                                  setCa62(false);
                                  setCa63(false);
                                  setCa61(false);
                                  setCa65(false);
                                  setCa66(false);
                                }}
                              />
                              <label htmlFor="j64">
                                <img src={A6Q4} />
                              </label>
                            </div>
                            <div className={ca65 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j65"
                                name="6"
                                value={5}
                                onChange={(event) => {
                                  setJA6(event.target.value);
                                  setCa65(event.target.checked);
                                  setCa62(false);
                                  setCa63(false);
                                  setCa64(false);
                                  setCa61(false);
                                  setCa66(false);
                                }}
                              />
                              <label htmlFor="j65">
                                <img src={A6Q5} />
                              </label>
                            </div>
                            <div className={ca66 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j66"
                                name="6"
                                value={6}
                                onChange={(event) => {
                                  setJA6(event.target.value);
                                  setCa66(event.target.checked);
                                  setCa62(false);
                                  setCa63(false);
                                  setCa64(false);
                                  setCa65(false);
                                  setCa61(false);
                                }}
                              />
                              <label htmlFor="j66">
                                <img src={A6Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "B" && (
                        <>
                          <img src={B6} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={cb61 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j61"
                                name="6"
                                value={1}
                                onChange={(event) => {
                                  setJB6(event.target.value);
                                  setCb61(event.target.checked);
                                  setCb62(false);
                                  setCb63(false);
                                  setCb64(false);
                                  setCb65(false);
                                  setCb66(false);
                                }}
                              />
                              <label htmlFor="j61">
                                <img src={B6Q1} />
                              </label>
                            </div>
                            <div className={cb62 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j62"
                                name="6"
                                value={2}
                                onChange={(event) => {
                                  setJB6(event.target.value);
                                  setCb62(event.target.checked);
                                  setCb61(false);
                                  setCb63(false);
                                  setCb64(false);
                                  setCb65(false);
                                  setCb66(false);
                                }}
                              />
                              <label htmlFor="j62">
                                <img src={B6Q2} />
                              </label>
                            </div>
                            <div className={cb63 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j63"
                                name="6"
                                value={3}
                                onChange={(event) => {
                                  setJB6(event.target.value);
                                  setCb63(event.target.checked);
                                  setCb62(false);
                                  setCb61(false);
                                  setCb64(false);
                                  setCb65(false);
                                  setCb66(false);
                                }}
                              />
                              <label htmlFor="j63">
                                <img src={B6Q3} />
                              </label>
                            </div>
                            <div className={cb64 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j64"
                                name="6"
                                value={4}
                                onChange={(event) => {
                                  setJB6(event.target.value);
                                  setCb64(event.target.checked);
                                  setCb62(false);
                                  setCb63(false);
                                  setCb61(false);
                                  setCb65(false);
                                  setCb66(false);
                                }}
                              />
                              <label htmlFor="j64">
                                <img src={B6Q4} />
                              </label>
                            </div>
                            <div className={cb65 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j65"
                                name="6"
                                value={5}
                                onChange={(event) => {
                                  setJB6(event.target.value);
                                  setCb65(event.target.checked);
                                  setCb62(false);
                                  setCb63(false);
                                  setCb64(false);
                                  setCb61(false);
                                  setCb66(false);
                                }}
                              />
                              <label htmlFor="j65">
                                <img src={B6Q5} />
                              </label>
                            </div>
                            <div className={cb66 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j66"
                                name="6"
                                value={6}
                                onChange={(event) => {
                                  setJB6(event.target.value);
                                  setCb66(event.target.checked);
                                  setCb62(false);
                                  setCb63(false);
                                  setCb64(false);
                                  setCb65(false);
                                  setCb61(false);
                                }}
                              />
                              <label htmlFor="j66">
                                <img src={B6Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "C" && (
                        <>
                          <img src={C6} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div className={cc61 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j61"
                                name="6"
                                value={1}
                                onChange={(event) => {
                                  setJC6(event.target.value);
                                  setCc61(event.target.checked);
                                  setCc62(false);
                                  setCc63(false);
                                  setCc64(false);
                                  setCc65(false);
                                  setCc66(false);
                                  setCc67(false);
                                  setCc68(false);
                                }}
                              />
                              <label htmlFor="j61">
                                <img src={C6Q1} />
                              </label>
                            </div>
                            <div className={cc62 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j62"
                                name="6"
                                value={2}
                                onChange={(event) => {
                                  setJC6(event.target.value);
                                  setCc62(event.target.checked);
                                  setCc61(false);
                                  setCc63(false);
                                  setCc64(false);
                                  setCc65(false);
                                  setCc66(false);
                                  setCc67(false);
                                  setCc68(false);
                                }}
                              />
                              <label htmlFor="j62">
                                <img src={C6Q2} />
                              </label>
                            </div>
                            <div className={cc63 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j63"
                                name="6"
                                value={3}
                                onChange={(event) => {
                                  setJC6(event.target.value);
                                  setCc63(event.target.checked);
                                  setCc61(false);
                                  setCc62(false);
                                  setCc64(false);
                                  setCc65(false);
                                  setCc66(false);
                                  setCc67(false);
                                  setCc68(false);
                                }}
                              />
                              <label htmlFor="j63">
                                <img src={C6Q3} />
                              </label>
                            </div>
                            <div className={cc64 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j64"
                                name="6"
                                value={4}
                                onChange={(event) => {
                                  setJC6(event.target.value);
                                  setCc64(event.target.checked);
                                  setCc61(false);
                                  setCc62(false);
                                  setCc63(false);
                                  setCc65(false);
                                  setCc66(false);
                                  setCc67(false);
                                  setCc68(false);
                                }}
                              />
                              <label htmlFor="j64">
                                <img src={C6Q4} />
                              </label>
                            </div>
                            <div className={cc65 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j65"
                                name="6"
                                value={5}
                                onChange={(event) => {
                                  setJC6(event.target.value);
                                  setCc65(event.target.checked);
                                  setCc61(false);
                                  setCc62(false);
                                  setCc63(false);
                                  setCc64(false);
                                  setCc66(false);
                                  setCc67(false);
                                  setCc68(false);
                                }}
                              />
                              <label htmlFor="j65">
                                <img src={C6Q5} />
                              </label>
                            </div>
                            <div className={cc66 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j66"
                                name="6"
                                value={6}
                                onChange={(event) => {
                                  setJC6(event.target.value);
                                  setCc66(event.target.checked);
                                  setCc61(false);
                                  setCc62(false);
                                  setCc63(false);
                                  setCc64(false);
                                  setCc65(false);
                                  setCc67(false);
                                  setCc68(false);
                                }}
                              />
                              <label htmlFor="j66">
                                <img src={C6Q6} />
                              </label>
                            </div>
                            <div className={cc67 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j67"
                                name="6"
                                value={7}
                                onChange={(event) => {
                                  setJC6(event.target.value);
                                  setCc67(event.target.checked);
                                  setCc61(false);
                                  setCc62(false);
                                  setCc63(false);
                                  setCc64(false);
                                  setCc65(false);
                                  setCc66(false);
                                  setCc68(false);
                                }}
                              />
                              <label htmlFor="j67">
                                <img src={C6Q7} />
                              </label>
                            </div>
                            <div className={cc68 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j68"
                                name="6"
                                value={8}
                                onChange={(event) => {
                                  setJC6(event.target.value);
                                  setCc68(event.target.checked);
                                  setCc61(false);
                                  setCc62(false);
                                  setCc63(false);
                                  setCc64(false);
                                  setCc65(false);
                                  setCc66(false);
                                  setCc67(false);
                                }}
                              />
                              <label htmlFor="j68">
                                <img src={C6Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "D" && (
                        <>
                          <img src={D6} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div className={cd61 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j61"
                                name="6"
                                value={1}
                                onChange={(event) => {
                                  setJD6(event.target.value);
                                  setCd61(event.target.checked);
                                  setCd62(false);
                                  setCd63(false);
                                  setCd64(false);
                                  setCd65(false);
                                  setCd66(false);
                                  setCd67(false);
                                  setCd68(false);
                                }}
                              />
                              <label htmlFor="j61">
                                <img src={D6Q1} />
                              </label>
                            </div>
                            <div className={cd62 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j62"
                                name="6"
                                value={2}
                                onChange={(event) => {
                                  setJD6(event.target.value);
                                  setCd62(event.target.checked);
                                  setCd61(false);
                                  setCd63(false);
                                  setCd64(false);
                                  setCd65(false);
                                  setCd66(false);
                                  setCd67(false);
                                  setCd68(false);
                                }}
                              />
                              <label htmlFor="j62">
                                <img src={D6Q2} />
                              </label>
                            </div>
                            <div className={cd63 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j63"
                                name="6"
                                value={3}
                                onChange={(event) => {
                                  setJD6(event.target.value);
                                  setCd63(event.target.checked);
                                  setCd61(false);
                                  setCd62(false);
                                  setCd64(false);
                                  setCd65(false);
                                  setCd66(false);
                                  setCd67(false);
                                  setCd68(false);
                                }}
                              />
                              <label htmlFor="j63">
                                <img src={D6Q3} />
                              </label>
                            </div>
                            <div className={cd64 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j64"
                                name="6"
                                value={4}
                                onChange={(event) => {
                                  setJD6(event.target.value);
                                  setCd64(event.target.checked);
                                  setCd61(false);
                                  setCd62(false);
                                  setCd63(false);
                                  setCd65(false);
                                  setCd66(false);
                                  setCd67(false);
                                  setCd68(false);
                                }}
                              />
                              <label htmlFor="j64">
                                <img src={D6Q4} />
                              </label>
                            </div>
                            <div className={cd65 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j65"
                                name="6"
                                value={5}
                                onChange={(event) => {
                                  setJD6(event.target.value);
                                  setCd65(event.target.checked);
                                  setCd61(false);
                                  setCd62(false);
                                  setCd63(false);
                                  setCd64(false);
                                  setCd66(false);
                                  setCd67(false);
                                  setCd68(false);
                                }}
                              />
                              <label htmlFor="j65">
                                <img src={D6Q5} />
                              </label>
                            </div>
                            <div className={cd66 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j66"
                                name="6"
                                value={6}
                                onChange={(event) => {
                                  setJD6(event.target.value);
                                  setCd66(event.target.checked);
                                  setCd61(false);
                                  setCd62(false);
                                  setCd63(false);
                                  setCd64(false);
                                  setCd65(false);
                                  setCd67(false);
                                  setCd68(false);
                                }}
                              />
                              <label htmlFor="j66">
                                <img src={D6Q6} />
                              </label>
                            </div>
                            <div className={cd67 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j67"
                                name="6"
                                value={7}
                                onChange={(event) => {
                                  setJD6(event.target.value);
                                  setCd67(event.target.checked);
                                  setCd61(false);
                                  setCd62(false);
                                  setCd63(false);
                                  setCd64(false);
                                  setCd65(false);
                                  setCd66(false);
                                  setCd68(false);
                                }}
                              />
                              <label htmlFor="j67">
                                <img src={D6Q7} />
                              </label>
                            </div>
                            <div className={cd68 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j68"
                                name="6"
                                value={8}
                                onChange={(event) => {
                                  setJD6(event.target.value);
                                  setCd68(event.target.checked);
                                  setCd61(false);
                                  setCd62(false);
                                  setCd63(false);
                                  setCd64(false);
                                  setCd65(false);
                                  setCd66(false);
                                  setCd67(false);
                                }}
                              />
                              <label htmlFor="j68">
                                <img src={D6Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "E" && (
                        <>
                          <img src={E6} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div className={ce61 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j61"
                                name="6"
                                value={1}
                                onChange={(event) => {
                                  setJE6(event.target.value);
                                  setCe61(event.target.checked);
                                  setCe62(false);
                                  setCe63(false);
                                  setCe64(false);
                                  setCe65(false);
                                  setCe66(false);
                                  setCe67(false);
                                  setCe68(false);
                                }}
                              />
                              <label htmlFor="j61">
                                <img src={E6Q1} />
                              </label>
                            </div>
                            <div className={ce62 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j62"
                                name="6"
                                value={2}
                                onChange={(event) => {
                                  setJE6(event.target.value);
                                  setCe62(event.target.checked);
                                  setCe61(false);
                                  setCe63(false);
                                  setCe64(false);
                                  setCe65(false);
                                  setCe66(false);
                                  setCe67(false);
                                  setCe68(false);
                                }}
                              />
                              <label htmlFor="j62">
                                <img src={E6Q2} />
                              </label>
                            </div>
                            <div className={ce63 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j63"
                                name="6"
                                value={3}
                                onChange={(event) => {
                                  setJE6(event.target.value);
                                  setCe63(event.target.checked);
                                  setCe61(false);
                                  setCe62(false);
                                  setCe64(false);
                                  setCe65(false);
                                  setCe66(false);
                                  setCe67(false);
                                  setCe68(false);
                                }}
                              />
                              <label htmlFor="j63">
                                <img src={E6Q3} />
                              </label>
                            </div>
                            <div className={ce64 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j64"
                                name="6"
                                value={4}
                                onChange={(event) => {
                                  setJE6(event.target.value);
                                  setCe64(event.target.checked);
                                  setCe61(false);
                                  setCe62(false);
                                  setCe63(false);
                                  setCe65(false);
                                  setCe66(false);
                                  setCe67(false);
                                  setCe68(false);
                                }}
                              />
                              <label htmlFor="j64">
                                <img src={E6Q4} />
                              </label>
                            </div>
                            <div className={ce65 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j65"
                                name="6"
                                value={5}
                                onChange={(event) => {
                                  setJE6(event.target.value);
                                  setCe65(event.target.checked);
                                  setCe61(false);
                                  setCe62(false);
                                  setCe63(false);
                                  setCe64(false);
                                  setCe66(false);
                                  setCe67(false);
                                  setCe68(false);
                                }}
                              />
                              <label htmlFor="j65">
                                <img src={E6Q5} />
                              </label>
                            </div>
                            <div className={ce66 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j66"
                                name="6"
                                value={6}
                                onChange={(event) => {
                                  setJE6(event.target.value);
                                  setCe66(event.target.checked);
                                  setCe61(false);
                                  setCe62(false);
                                  setCe63(false);
                                  setCe64(false);
                                  setCe65(false);
                                  setCe67(false);
                                  setCe68(false);
                                }}
                              />
                              <label htmlFor="j66">
                                <img src={E6Q6} />
                              </label>
                            </div>
                            <div className={ce67 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j67"
                                name="6"
                                value={7}
                                onChange={(event) => {
                                  setJE6(event.target.value);
                                  setCe67(event.target.checked);
                                  setCe61(false);
                                  setCe62(false);
                                  setCe63(false);
                                  setCe64(false);
                                  setCe65(false);
                                  setCe66(false);
                                  setCe68(false);
                                }}
                              />
                              <label htmlFor="j67">
                                <img src={E6Q7} />
                              </label>
                            </div>
                            <div className={ce68 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j68"
                                name="6"
                                value={8}
                                onChange={(event) => {
                                  setJE6(event.target.value);
                                  setCe68(event.target.checked);
                                  setCe61(false);
                                  setCe62(false);
                                  setCe63(false);
                                  setCe64(false);
                                  setCe65(false);
                                  setCe66(false);
                                  setCe67(false);
                                }}
                              />
                              <label htmlFor="j68">
                                <img src={E6Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {nomor % 12 === 7 && (
                    <>
                      {typeNow === "A" && (
                        <>
                          <img src={A7} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={ca71 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j71"
                                name="7"
                                value={1}
                                onChange={(event) => {
                                  setJA7(event.target.value);
                                  setCa71(event.target.checked);
                                  setCa72(false);
                                  setCa73(false);
                                  setCa74(false);
                                  setCa75(false);
                                  setCa76(false);
                                }}
                              />
                              <label htmlFor="j71">
                                <img src={A7Q1} />
                              </label>
                            </div>
                            <div className={ca72 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j72"
                                name="7"
                                value={2}
                                onChange={(event) => {
                                  setJA7(event.target.value);
                                  setCa72(event.target.checked);
                                  setCa71(false);
                                  setCa73(false);
                                  setCa74(false);
                                  setCa75(false);
                                  setCa76(false);
                                }}
                              />
                              <label htmlFor="j72">
                                <img src={A7Q2} />
                              </label>
                            </div>
                            <div className={ca73 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j73"
                                name="7"
                                value={3}
                                onChange={(event) => {
                                  setJA7(event.target.value);
                                  setCa73(event.target.checked);
                                  setCa72(false);
                                  setCa71(false);
                                  setCa74(false);
                                  setCa75(false);
                                  setCa76(false);
                                }}
                              />
                              <label htmlFor="j73">
                                <img src={A7Q3} />
                              </label>
                            </div>
                            <div className={ca74 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j74"
                                name="7"
                                value={4}
                                onChange={(event) => {
                                  setJA7(event.target.value);
                                  setCa74(event.target.checked);
                                  setCa72(false);
                                  setCa73(false);
                                  setCa71(false);
                                  setCa75(false);
                                  setCa76(false);
                                }}
                              />
                              <label htmlFor="j74">
                                <img src={A7Q4} />
                              </label>
                            </div>
                            <div className={ca75 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j75"
                                name="7"
                                value={5}
                                onChange={(event) => {
                                  setJA7(event.target.value);
                                  setCa75(event.target.checked);
                                  setCa72(false);
                                  setCa73(false);
                                  setCa74(false);
                                  setCa71(false);
                                  setCa76(false);
                                }}
                              />
                              <label htmlFor="j75">
                                <img src={A7Q5} />
                              </label>
                            </div>
                            <div className={ca76 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j76"
                                name="7"
                                value={6}
                                onChange={(event) => {
                                  setJA7(event.target.value);
                                  setCa76(event.target.checked);
                                  setCa72(false);
                                  setCa73(false);
                                  setCa74(false);
                                  setCa75(false);
                                  setCa71(false);
                                }}
                              />
                              <label htmlFor="j76">
                                <img src={A7Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "B" && (
                        <>
                          <img src={B7} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={cb71 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j71"
                                name="7"
                                value={1}
                                onChange={(event) => {
                                  setJB7(event.target.value);
                                  setCb71(event.target.checked);
                                  setCb72(false);
                                  setCb73(false);
                                  setCb74(false);
                                  setCb75(false);
                                  setCb76(false);
                                }}
                              />
                              <label htmlFor="j71">
                                <img src={B7Q1} />
                              </label>
                            </div>
                            <div className={cb72 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j72"
                                name="7"
                                value={2}
                                onChange={(event) => {
                                  setJB7(event.target.value);
                                  setCb72(event.target.checked);
                                  setCb71(false);
                                  setCb73(false);
                                  setCb74(false);
                                  setCb75(false);
                                  setCb76(false);
                                }}
                              />
                              <label htmlFor="j72">
                                <img src={B7Q2} />
                              </label>
                            </div>
                            <div className={cb73 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j73"
                                name="7"
                                value={3}
                                onChange={(event) => {
                                  setJB7(event.target.value);
                                  setCb73(event.target.checked);
                                  setCb72(false);
                                  setCb71(false);
                                  setCb74(false);
                                  setCb75(false);
                                  setCb76(false);
                                }}
                              />
                              <label htmlFor="j73">
                                <img src={B7Q3} />
                              </label>
                            </div>
                            <div className={cb74 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j74"
                                name="7"
                                value={4}
                                onChange={(event) => {
                                  setJB7(event.target.value);
                                  setCb74(event.target.checked);
                                  setCb72(false);
                                  setCb73(false);
                                  setCb71(false);
                                  setCb75(false);
                                  setCb76(false);
                                }}
                              />
                              <label htmlFor="j74">
                                <img src={B7Q4} />
                              </label>
                            </div>
                            <div className={cb75 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j75"
                                name="7"
                                value={5}
                                onChange={(event) => {
                                  setJB7(event.target.value);
                                  setCb75(event.target.checked);
                                  setCb72(false);
                                  setCb73(false);
                                  setCb74(false);
                                  setCb71(false);
                                  setCb76(false);
                                }}
                              />
                              <label htmlFor="j75">
                                <img src={B7Q5} />
                              </label>
                            </div>
                            <div className={cb76 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j76"
                                name="7"
                                value={6}
                                onChange={(event) => {
                                  setJB7(event.target.value);
                                  setCb76(event.target.checked);
                                  setCb72(false);
                                  setCb73(false);
                                  setCb74(false);
                                  setCb75(false);
                                  setCb71(false);
                                }}
                              />
                              <label htmlFor="j76">
                                <img src={B7Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "C" && (
                        <>
                          <img src={C7} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div className={cc71 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j71"
                                name="7"
                                value={1}
                                onChange={(event) => {
                                  setJC7(event.target.value);
                                  setCc71(event.target.checked);
                                  setCc72(false);
                                  setCc73(false);
                                  setCc74(false);
                                  setCc75(false);
                                  setCc76(false);
                                  setCc77(false);
                                  setCc78(false);
                                }}
                              />
                              <label htmlFor="j71">
                                <img src={C7Q1} />
                              </label>
                            </div>
                            <div className={cc72 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j72"
                                name="7"
                                value={2}
                                onChange={(event) => {
                                  setJC7(event.target.value);
                                  setCc72(event.target.checked);
                                  setCc71(false);
                                  setCc73(false);
                                  setCc74(false);
                                  setCc75(false);
                                  setCc76(false);
                                  setCc77(false);
                                  setCc78(false);
                                }}
                              />
                              <label htmlFor="j72">
                                <img src={C7Q2} />
                              </label>
                            </div>
                            <div className={cc73 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j73"
                                name="7"
                                value={3}
                                onChange={(event) => {
                                  setJC7(event.target.value);
                                  setCc73(event.target.checked);
                                  setCc71(false);
                                  setCc72(false);
                                  setCc74(false);
                                  setCc75(false);
                                  setCc76(false);
                                  setCc77(false);
                                  setCc78(false);
                                }}
                              />
                              <label htmlFor="j73">
                                <img src={C7Q3} />
                              </label>
                            </div>
                            <div className={cc74 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j74"
                                name="7"
                                value={4}
                                onChange={(event) => {
                                  setJC7(event.target.value);
                                  setCc74(event.target.checked);
                                  setCc71(false);
                                  setCc72(false);
                                  setCc73(false);
                                  setCc75(false);
                                  setCc76(false);
                                  setCc77(false);
                                  setCc78(false);
                                }}
                              />
                              <label htmlFor="j74">
                                <img src={C7Q4} />
                              </label>
                            </div>
                            <div className={cc75 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j75"
                                name="7"
                                value={5}
                                onChange={(event) => {
                                  setJC7(event.target.value);
                                  setCc75(event.target.checked);
                                  setCc71(false);
                                  setCc72(false);
                                  setCc73(false);
                                  setCc74(false);
                                  setCc76(false);
                                  setCc77(false);
                                  setCc78(false);
                                }}
                              />
                              <label htmlFor="j75">
                                <img src={C7Q5} />
                              </label>
                            </div>
                            <div className={cc76 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j76"
                                name="7"
                                value={6}
                                onChange={(event) => {
                                  setJC7(event.target.value);
                                  setCc76(event.target.checked);
                                  setCc71(false);
                                  setCc72(false);
                                  setCc73(false);
                                  setCc74(false);
                                  setCc75(false);
                                  setCc77(false);
                                  setCc78(false);
                                }}
                              />
                              <label htmlFor="j76">
                                <img src={C7Q6} />
                              </label>
                            </div>
                            <div className={cc77 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j77"
                                name="7"
                                value={7}
                                onChange={(event) => {
                                  setJC7(event.target.value);
                                  setCc77(event.target.checked);
                                  setCc71(false);
                                  setCc72(false);
                                  setCc73(false);
                                  setCc74(false);
                                  setCc75(false);
                                  setCc76(false);
                                  setCc78(false);
                                }}
                              />
                              <label htmlFor="j77">
                                <img src={C7Q7} />
                              </label>
                            </div>
                            <div className={cc78 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j78"
                                name="7"
                                value={8}
                                onChange={(event) => {
                                  setJC7(event.target.value);
                                  setCc78(event.target.checked);
                                  setCc71(false);
                                  setCc72(false);
                                  setCc73(false);
                                  setCc74(false);
                                  setCc75(false);
                                  setCc76(false);
                                  setCc77(false);
                                }}
                              />
                              <label htmlFor="j78">
                                <img src={C7Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "D" && (
                        <>
                          <img src={D7} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div className={cd71 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j71"
                                name="7"
                                value={1}
                                onChange={(event) => {
                                  setJD7(event.target.value);
                                  setCd71(event.target.checked);
                                  setCd72(false);
                                  setCd73(false);
                                  setCd74(false);
                                  setCd75(false);
                                  setCd76(false);
                                  setCd77(false);
                                  setCd78(false);
                                }}
                              />
                              <label htmlFor="j71">
                                <img src={D7Q1} />
                              </label>
                            </div>
                            <div className={cd72 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j72"
                                name="7"
                                value={2}
                                onChange={(event) => {
                                  setJD7(event.target.value);
                                  setCd72(event.target.checked);
                                  setCd71(false);
                                  setCd73(false);
                                  setCd74(false);
                                  setCd75(false);
                                  setCd76(false);
                                  setCd77(false);
                                  setCd78(false);
                                }}
                              />
                              <label htmlFor="j72">
                                <img src={D7Q2} />
                              </label>
                            </div>
                            <div className={cd73 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j73"
                                name="7"
                                value={3}
                                onChange={(event) => {
                                  setJD7(event.target.value);
                                  setCd73(event.target.checked);
                                  setCd71(false);
                                  setCd72(false);
                                  setCd74(false);
                                  setCd75(false);
                                  setCd76(false);
                                  setCd77(false);
                                  setCd78(false);
                                }}
                              />
                              <label htmlFor="j73">
                                <img src={D7Q3} />
                              </label>
                            </div>
                            <div className={cd74 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j74"
                                name="7"
                                value={4}
                                onChange={(event) => {
                                  setJD7(event.target.value);
                                  setCd74(event.target.checked);
                                  setCd71(false);
                                  setCd72(false);
                                  setCd73(false);
                                  setCd75(false);
                                  setCd76(false);
                                  setCd77(false);
                                  setCd78(false);
                                }}
                              />
                              <label htmlFor="j74">
                                <img src={D7Q4} />
                              </label>
                            </div>
                            <div className={cd75 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j75"
                                name="7"
                                value={5}
                                onChange={(event) => {
                                  setJD7(event.target.value);
                                  setCd75(event.target.checked);
                                  setCd71(false);
                                  setCd72(false);
                                  setCd73(false);
                                  setCd74(false);
                                  setCd76(false);
                                  setCd77(false);
                                  setCd78(false);
                                }}
                              />
                              <label htmlFor="j75">
                                <img src={D7Q5} />
                              </label>
                            </div>
                            <div className={cd76 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j76"
                                name="7"
                                value={6}
                                onChange={(event) => {
                                  setJD7(event.target.value);
                                  setCd76(event.target.checked);
                                  setCd71(false);
                                  setCd72(false);
                                  setCd73(false);
                                  setCd74(false);
                                  setCd75(false);
                                  setCd77(false);
                                  setCd78(false);
                                }}
                              />
                              <label htmlFor="j76">
                                <img src={D7Q6} />
                              </label>
                            </div>
                            <div className={cd77 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j77"
                                name="7"
                                value={7}
                                onChange={(event) => {
                                  setJD7(event.target.value);
                                  setCd77(event.target.checked);
                                  setCd71(false);
                                  setCd72(false);
                                  setCd73(false);
                                  setCd74(false);
                                  setCd75(false);
                                  setCd76(false);
                                  setCd78(false);
                                }}
                              />
                              <label htmlFor="j77">
                                <img src={D7Q7} />
                              </label>
                            </div>
                            <div className={cd78 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j78"
                                name="7"
                                value={8}
                                onChange={(event) => {
                                  setJD7(event.target.value);
                                  setCd78(event.target.checked);
                                  setCd71(false);
                                  setCd72(false);
                                  setCd73(false);
                                  setCd74(false);
                                  setCd75(false);
                                  setCd76(false);
                                  setCd77(false);
                                }}
                              />
                              <label htmlFor="j78">
                                <img src={D7Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "E" && (
                        <>
                          <img src={E7} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div className={ce71 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j71"
                                name="7"
                                value={1}
                                onChange={(event) => {
                                  setJE7(event.target.value);
                                  setCe71(event.target.checked);
                                  setCe72(false);
                                  setCe73(false);
                                  setCe74(false);
                                  setCe75(false);
                                  setCe76(false);
                                  setCe77(false);
                                  setCe78(false);
                                }}
                              />
                              <label htmlFor="j71">
                                <img src={E7Q1} />
                              </label>
                            </div>
                            <div className={ce72 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j72"
                                name="7"
                                value={2}
                                onChange={(event) => {
                                  setJE7(event.target.value);
                                  setCe72(event.target.checked);
                                  setCe71(false);
                                  setCe73(false);
                                  setCe74(false);
                                  setCe75(false);
                                  setCe76(false);
                                  setCe77(false);
                                  setCe78(false);
                                }}
                              />
                              <label htmlFor="j72">
                                <img src={E7Q2} />
                              </label>
                            </div>
                            <div className={ce73 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j73"
                                name="7"
                                value={3}
                                onChange={(event) => {
                                  setJE7(event.target.value);
                                  setCe73(event.target.checked);
                                  setCe71(false);
                                  setCe72(false);
                                  setCe74(false);
                                  setCe75(false);
                                  setCe76(false);
                                  setCe77(false);
                                  setCe78(false);
                                }}
                              />
                              <label htmlFor="j73">
                                <img src={E7Q3} />
                              </label>
                            </div>
                            <div className={ce74 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j74"
                                name="7"
                                value={4}
                                onChange={(event) => {
                                  setJE7(event.target.value);
                                  setCe74(event.target.checked);
                                  setCe71(false);
                                  setCe72(false);
                                  setCe73(false);
                                  setCe75(false);
                                  setCe76(false);
                                  setCe77(false);
                                  setCe78(false);
                                }}
                              />
                              <label htmlFor="j74">
                                <img src={E7Q4} />
                              </label>
                            </div>
                            <div className={ce75 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j75"
                                name="7"
                                value={5}
                                onChange={(event) => {
                                  setJE7(event.target.value);
                                  setCe75(event.target.checked);
                                  setCe71(false);
                                  setCe72(false);
                                  setCe73(false);
                                  setCe74(false);
                                  setCe76(false);
                                  setCe77(false);
                                  setCe78(false);
                                }}
                              />
                              <label htmlFor="j75">
                                <img src={E7Q5} />
                              </label>
                            </div>
                            <div className={ce76 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j76"
                                name="7"
                                value={6}
                                onChange={(event) => {
                                  setJE7(event.target.value);
                                  setCe76(event.target.checked);
                                  setCe71(false);
                                  setCe72(false);
                                  setCe73(false);
                                  setCe74(false);
                                  setCe75(false);
                                  setCe77(false);
                                  setCe78(false);
                                }}
                              />
                              <label htmlFor="j76">
                                <img src={E7Q6} />
                              </label>
                            </div>
                            <div className={ce77 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j77"
                                name="7"
                                value={7}
                                onChange={(event) => {
                                  setJE7(event.target.value);
                                  setCe77(event.target.checked);
                                  setCe71(false);
                                  setCe72(false);
                                  setCe73(false);
                                  setCe74(false);
                                  setCe75(false);
                                  setCe76(false);
                                  setCe78(false);
                                }}
                              />
                              <label htmlFor="j77">
                                <img src={E7Q7} />
                              </label>
                            </div>
                            <div className={ce78 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j78"
                                name="7"
                                value={8}
                                onChange={(event) => {
                                  setJE7(event.target.value);
                                  setCe78(event.target.checked);
                                  setCe71(false);
                                  setCe72(false);
                                  setCe73(false);
                                  setCe74(false);
                                  setCe75(false);
                                  setCe76(false);
                                  setCe77(false);
                                }}
                              />
                              <label htmlFor="j78">
                                <img src={E7Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {nomor % 12 === 8 && (
                    <>
                      {typeNow === "A" && (
                        <>
                          <img src={A8} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={ca81 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j81"
                                name="8"
                                value={1}
                                onChange={(event) => {
                                  setJA8(event.target.value);
                                  setCa81(event.target.checked);
                                  setCa82(false);
                                  setCa83(false);
                                  setCa84(false);
                                  setCa85(false);
                                  setCa86(false);
                                }}
                              />
                              <label htmlFor="j81">
                                <img src={A8Q1} />
                              </label>
                            </div>
                            <div className={ca82 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j82"
                                name="8"
                                value={2}
                                onChange={(event) => {
                                  setJA8(event.target.value);
                                  setCa82(event.target.checked);
                                  setCa81(false);
                                  setCa83(false);
                                  setCa84(false);
                                  setCa85(false);
                                  setCa86(false);
                                }}
                              />
                              <label htmlFor="j82">
                                <img src={A8Q2} />
                              </label>
                            </div>
                            <div className={ca83 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j83"
                                name="8"
                                value={3}
                                onChange={(event) => {
                                  setJA8(event.target.value);
                                  setCa83(event.target.checked);
                                  setCa82(false);
                                  setCa81(false);
                                  setCa84(false);
                                  setCa85(false);
                                  setCa86(false);
                                }}
                              />
                              <label htmlFor="j83">
                                <img src={A8Q3} />
                              </label>
                            </div>
                            <div className={ca84 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j84"
                                name="8"
                                value={4}
                                onChange={(event) => {
                                  setJA8(event.target.value);
                                  setCa84(event.target.checked);
                                  setCa82(false);
                                  setCa83(false);
                                  setCa81(false);
                                  setCa85(false);
                                  setCa86(false);
                                }}
                              />
                              <label htmlFor="j84">
                                <img src={A8Q4} />
                              </label>
                            </div>
                            <div className={ca85 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j85"
                                name="8"
                                value={5}
                                onChange={(event) => {
                                  setJA8(event.target.value);
                                  setCa85(event.target.checked);
                                  setCa82(false);
                                  setCa83(false);
                                  setCa84(false);
                                  setCa81(false);
                                  setCa86(false);
                                }}
                              />
                              <label htmlFor="j85">
                                <img src={A8Q5} />
                              </label>
                            </div>
                            <div className={ca86 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j86"
                                name="8"
                                value={6}
                                onChange={(event) => {
                                  setJA8(event.target.value);
                                  setCa86(event.target.checked);
                                  setCa82(false);
                                  setCa83(false);
                                  setCa84(false);
                                  setCa85(false);
                                  setCa81(false);
                                }}
                              />
                              <label htmlFor="j86">
                                <img src={A8Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "B" && (
                        <>
                          <img src={B8} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={cb81 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j81"
                                name="8"
                                value={1}
                                onChange={(event) => {
                                  setJB8(event.target.value);
                                  setCb81(event.target.checked);
                                  setCb82(false);
                                  setCb83(false);
                                  setCb84(false);
                                  setCb85(false);
                                  setCb86(false);
                                }}
                              />
                              <label htmlFor="j81">
                                <img src={B8Q1} />
                              </label>
                            </div>
                            <div className={cb82 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j82"
                                name="8"
                                value={2}
                                onChange={(event) => {
                                  setJB8(event.target.value);
                                  setCb82(event.target.checked);
                                  setCb81(false);
                                  setCb83(false);
                                  setCb84(false);
                                  setCb85(false);
                                  setCb86(false);
                                }}
                              />
                              <label htmlFor="j82">
                                <img src={B8Q2} />
                              </label>
                            </div>
                            <div className={cb83 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j83"
                                name="8"
                                value={3}
                                onChange={(event) => {
                                  setJB8(event.target.value);
                                  setCb83(event.target.checked);
                                  setCb82(false);
                                  setCb81(false);
                                  setCb84(false);
                                  setCb85(false);
                                  setCb86(false);
                                }}
                              />
                              <label htmlFor="j83">
                                <img src={B8Q3} />
                              </label>
                            </div>
                            <div className={cb84 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j84"
                                name="8"
                                value={4}
                                onChange={(event) => {
                                  setJB8(event.target.value);
                                  setCb84(event.target.checked);
                                  setCb82(false);
                                  setCb83(false);
                                  setCb81(false);
                                  setCb85(false);
                                  setCb86(false);
                                }}
                              />
                              <label htmlFor="j84">
                                <img src={B8Q4} />
                              </label>
                            </div>
                            <div className={cb85 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j85"
                                name="8"
                                value={5}
                                onChange={(event) => {
                                  setJB8(event.target.value);
                                  setCb85(event.target.checked);
                                  setCb82(false);
                                  setCb83(false);
                                  setCb84(false);
                                  setCb81(false);
                                  setCb86(false);
                                }}
                              />
                              <label htmlFor="j85">
                                <img src={B8Q5} />
                              </label>
                            </div>
                            <div className={cb86 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j86"
                                name="8"
                                value={6}
                                onChange={(event) => {
                                  setJB8(event.target.value);
                                  setCb86(event.target.checked);
                                  setCb82(false);
                                  setCb83(false);
                                  setCb84(false);
                                  setCb85(false);
                                  setCb81(false);
                                }}
                              />
                              <label htmlFor="j86">
                                <img src={B8Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "C" && (
                        <>
                          <img src={C8} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div className={cc81 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j81"
                                name="8"
                                value={1}
                                onChange={(event) => {
                                  setJC8(event.target.value);
                                  setCc81(event.target.checked);
                                  setCc82(false);
                                  setCc83(false);
                                  setCc84(false);
                                  setCc85(false);
                                  setCc86(false);
                                  setCc87(false);
                                  setCc88(false);
                                }}
                              />
                              <label htmlFor="j81">
                                <img src={C8Q1} />
                              </label>
                            </div>
                            <div className={cc82 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j82"
                                name="8"
                                value={2}
                                onChange={(event) => {
                                  setJC8(event.target.value);
                                  setCc82(event.target.checked);
                                  setCc81(false);
                                  setCc83(false);
                                  setCc84(false);
                                  setCc85(false);
                                  setCc86(false);
                                  setCc87(false);
                                  setCc88(false);
                                }}
                              />
                              <label htmlFor="j82">
                                <img src={C8Q2} />
                              </label>
                            </div>
                            <div className={cc83 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j83"
                                name="8"
                                value={3}
                                onChange={(event) => {
                                  setJC8(event.target.value);
                                  setCc83(event.target.checked);
                                  setCc81(false);
                                  setCc82(false);
                                  setCc84(false);
                                  setCc85(false);
                                  setCc86(false);
                                  setCc87(false);
                                  setCc88(false);
                                }}
                              />
                              <label htmlFor="j83">
                                <img src={C8Q3} />
                              </label>
                            </div>
                            <div className={cc84 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j84"
                                name="8"
                                value={4}
                                onChange={(event) => {
                                  setJC8(event.target.value);
                                  setCc84(event.target.checked);
                                  setCc81(false);
                                  setCc82(false);
                                  setCc83(false);
                                  setCc85(false);
                                  setCc86(false);
                                  setCc87(false);
                                  setCc88(false);
                                }}
                              />
                              <label htmlFor="j84">
                                <img src={C8Q4} />
                              </label>
                            </div>
                            <div className={cc85 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j85"
                                name="8"
                                value={5}
                                onChange={(event) => {
                                  setJC8(event.target.value);
                                  setCc85(event.target.checked);
                                  setCc81(false);
                                  setCc82(false);
                                  setCc83(false);
                                  setCc84(false);
                                  setCc86(false);
                                  setCc87(false);
                                  setCc88(false);
                                }}
                              />
                              <label htmlFor="j85">
                                <img src={C8Q5} />
                              </label>
                            </div>
                            <div className={cc86 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j86"
                                name="8"
                                value={6}
                                onChange={(event) => {
                                  setJC8(event.target.value);
                                  setCc86(event.target.checked);
                                  setCc81(false);
                                  setCc82(false);
                                  setCc83(false);
                                  setCc84(false);
                                  setCc85(false);
                                  setCc87(false);
                                  setCc88(false);
                                }}
                              />
                              <label htmlFor="j86">
                                <img src={C8Q6} />
                              </label>
                            </div>
                            <div className={cc87 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j87"
                                name="8"
                                value={7}
                                onChange={(event) => {
                                  setJC8(event.target.value);
                                  setCc87(event.target.checked);
                                  setCc81(false);
                                  setCc82(false);
                                  setCc83(false);
                                  setCc84(false);
                                  setCc85(false);
                                  setCc86(false);
                                  setCc88(false);
                                }}
                              />
                              <label htmlFor="j87">
                                <img src={C8Q7} />
                              </label>
                            </div>
                            <div className={cc88 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j88"
                                name="8"
                                value={8}
                                onChange={(event) => {
                                  setJC8(event.target.value);
                                  setCc88(event.target.checked);
                                  setCc81(false);
                                  setCc82(false);
                                  setCc83(false);
                                  setCc84(false);
                                  setCc85(false);
                                  setCc86(false);
                                  setCc87(false);
                                }}
                              />
                              <label htmlFor="j88">
                                <img src={C8Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "D" && (
                        <>
                          <img src={D8} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div className={cd81 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j81"
                                name="8"
                                value={1}
                                onChange={(event) => {
                                  setJD8(event.target.value);
                                  setCd81(event.target.checked);
                                  setCd82(false);
                                  setCd83(false);
                                  setCd84(false);
                                  setCd85(false);
                                  setCd86(false);
                                  setCd87(false);
                                  setCd88(false);
                                }}
                              />
                              <label htmlFor="j81">
                                <img src={D8Q1} />
                              </label>
                            </div>
                            <div className={cd82 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j82"
                                name="8"
                                value={2}
                                onChange={(event) => {
                                  setJD8(event.target.value);
                                  setCd82(event.target.checked);
                                  setCd81(false);
                                  setCd83(false);
                                  setCd84(false);
                                  setCd85(false);
                                  setCd86(false);
                                  setCd87(false);
                                  setCd88(false);
                                }}
                              />
                              <label htmlFor="j82">
                                <img src={D8Q2} />
                              </label>
                            </div>
                            <div className={cd83 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j83"
                                name="8"
                                value={3}
                                onChange={(event) => {
                                  setJD8(event.target.value);
                                  setCd83(event.target.checked);
                                  setCd81(false);
                                  setCd82(false);
                                  setCd84(false);
                                  setCd85(false);
                                  setCd86(false);
                                  setCd87(false);
                                  setCd88(false);
                                }}
                              />
                              <label htmlFor="j83">
                                <img src={D8Q3} />
                              </label>
                            </div>
                            <div className={cd84 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j84"
                                name="8"
                                value={4}
                                onChange={(event) => {
                                  setJD8(event.target.value);
                                  setCd84(event.target.checked);
                                  setCd81(false);
                                  setCd82(false);
                                  setCd83(false);
                                  setCd85(false);
                                  setCd86(false);
                                  setCd87(false);
                                  setCd88(false);
                                }}
                              />
                              <label htmlFor="j84">
                                <img src={D8Q4} />
                              </label>
                            </div>
                            <div className={cd85 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j85"
                                name="8"
                                value={5}
                                onChange={(event) => {
                                  setJD8(event.target.value);
                                  setCd85(event.target.checked);
                                  setCd81(false);
                                  setCd82(false);
                                  setCd83(false);
                                  setCd84(false);
                                  setCd86(false);
                                  setCd87(false);
                                  setCd88(false);
                                }}
                              />
                              <label htmlFor="j85">
                                <img src={D8Q5} />
                              </label>
                            </div>
                            <div className={cd86 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j86"
                                name="8"
                                value={6}
                                onChange={(event) => {
                                  setJD8(event.target.value);
                                  setCd86(event.target.checked);
                                  setCd81(false);
                                  setCd82(false);
                                  setCd83(false);
                                  setCd84(false);
                                  setCd85(false);
                                  setCd87(false);
                                  setCd88(false);
                                }}
                              />
                              <label htmlFor="j86">
                                <img src={D8Q6} />
                              </label>
                            </div>
                            <div className={cd87 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j87"
                                name="8"
                                value={7}
                                onChange={(event) => {
                                  setJD8(event.target.value);
                                  setCd87(event.target.checked);
                                  setCd81(false);
                                  setCd82(false);
                                  setCd83(false);
                                  setCd84(false);
                                  setCd85(false);
                                  setCd86(false);
                                  setCd88(false);
                                }}
                              />
                              <label htmlFor="j87">
                                <img src={D8Q7} />
                              </label>
                            </div>
                            <div className={cd88 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j88"
                                name="8"
                                value={8}
                                onChange={(event) => {
                                  setJD8(event.target.value);
                                  setCd88(event.target.checked);
                                  setCd81(false);
                                  setCd82(false);
                                  setCd83(false);
                                  setCd84(false);
                                  setCd85(false);
                                  setCd86(false);
                                  setCd87(false);
                                }}
                              />
                              <label htmlFor="j88">
                                <img src={D8Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "E" && (
                        <>
                          <img src={E8} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div className={ce81 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j81"
                                name="8"
                                value={1}
                                onChange={(event) => {
                                  setJE8(event.target.value);
                                  setCe81(event.target.checked);
                                  setCe82(false);
                                  setCe83(false);
                                  setCe84(false);
                                  setCe85(false);
                                  setCe86(false);
                                  setCe87(false);
                                  setCe88(false);
                                }}
                              />
                              <label htmlFor="j81">
                                <img src={E8Q1} />
                              </label>
                            </div>
                            <div className={ce82 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j82"
                                name="8"
                                value={2}
                                onChange={(event) => {
                                  setJE8(event.target.value);
                                  setCe82(event.target.checked);
                                  setCe81(false);
                                  setCe83(false);
                                  setCe84(false);
                                  setCe85(false);
                                  setCe86(false);
                                  setCe87(false);
                                  setCe88(false);
                                }}
                              />
                              <label htmlFor="j82">
                                <img src={E8Q2} />
                              </label>
                            </div>
                            <div className={ce83 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j83"
                                name="8"
                                value={3}
                                onChange={(event) => {
                                  setJE8(event.target.value);
                                  setCe83(event.target.checked);
                                  setCe81(false);
                                  setCe82(false);
                                  setCe84(false);
                                  setCe85(false);
                                  setCe86(false);
                                  setCe87(false);
                                  setCe88(false);
                                }}
                              />
                              <label htmlFor="j83">
                                <img src={E8Q3} />
                              </label>
                            </div>
                            <div className={ce84 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j84"
                                name="8"
                                value={4}
                                onChange={(event) => {
                                  setJE8(event.target.value);
                                  setCe84(event.target.checked);
                                  setCe81(false);
                                  setCe82(false);
                                  setCe83(false);
                                  setCe85(false);
                                  setCe86(false);
                                  setCe87(false);
                                  setCe88(false);
                                }}
                              />
                              <label htmlFor="j84">
                                <img src={E8Q4} />
                              </label>
                            </div>
                            <div className={ce85 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j85"
                                name="8"
                                value={5}
                                onChange={(event) => {
                                  setJE8(event.target.value);
                                  setCe85(event.target.checked);
                                  setCe81(false);
                                  setCe82(false);
                                  setCe83(false);
                                  setCe84(false);
                                  setCe86(false);
                                  setCe87(false);
                                  setCe88(false);
                                }}
                              />
                              <label htmlFor="j85">
                                <img src={E8Q5} />
                              </label>
                            </div>
                            <div className={ce86 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j86"
                                name="8"
                                value={6}
                                onChange={(event) => {
                                  setJE8(event.target.value);
                                  setCe86(event.target.checked);
                                  setCe81(false);
                                  setCe82(false);
                                  setCe83(false);
                                  setCe84(false);
                                  setCe85(false);
                                  setCe87(false);
                                  setCe88(false);
                                }}
                              />
                              <label htmlFor="j86">
                                <img src={E8Q6} />
                              </label>
                            </div>
                            <div className={ce87 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j87"
                                name="8"
                                value={7}
                                onChange={(event) => {
                                  setJE8(event.target.value);
                                  setCe87(event.target.checked);
                                  setCe81(false);
                                  setCe82(false);
                                  setCe83(false);
                                  setCe84(false);
                                  setCe85(false);
                                  setCe86(false);
                                  setCe88(false);
                                }}
                              />
                              <label htmlFor="j87">
                                <img src={E8Q7} />
                              </label>
                            </div>
                            <div className={ce88 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j88"
                                name="8"
                                value={8}
                                onChange={(event) => {
                                  setJE8(event.target.value);
                                  setCe88(event.target.checked);
                                  setCe81(false);
                                  setCe82(false);
                                  setCe83(false);
                                  setCe84(false);
                                  setCe85(false);
                                  setCe86(false);
                                  setCe87(false);
                                }}
                              />
                              <label htmlFor="j88">
                                <img src={E8Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {nomor % 12 === 9 && (
                    <>
                      {typeNow === "A" && (
                        <>
                          <img src={A9} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={ca91 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j91"
                                name="9"
                                value={1}
                                onChange={(event) => {
                                  setJA9(event.target.value);
                                  setCa91(event.target.checked);
                                  setCa92(false);
                                  setCa93(false);
                                  setCa94(false);
                                  setCa95(false);
                                  setCa96(false);
                                }}
                              />
                              <label htmlFor="j91">
                                <img src={A9Q1} />
                              </label>
                            </div>
                            <div className={ca92 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j92"
                                name="9"
                                value={2}
                                onChange={(event) => {
                                  setJA9(event.target.value);
                                  setCa92(event.target.checked);
                                  setCa91(false);
                                  setCa93(false);
                                  setCa94(false);
                                  setCa95(false);
                                  setCa96(false);
                                }}
                              />
                              <label htmlFor="j92">
                                <img src={A9Q2} />
                              </label>
                            </div>
                            <div className={ca93 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j93"
                                name="9"
                                value={3}
                                onChange={(event) => {
                                  setJA9(event.target.value);
                                  setCa93(event.target.checked);
                                  setCa92(false);
                                  setCa91(false);
                                  setCa94(false);
                                  setCa95(false);
                                  setCa96(false);
                                }}
                              />
                              <label htmlFor="j93">
                                <img src={A9Q3} />
                              </label>
                            </div>
                            <div className={ca94 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j94"
                                name="9"
                                value={4}
                                onChange={(event) => {
                                  setJA9(event.target.value);
                                  setCa94(event.target.checked);
                                  setCa92(false);
                                  setCa93(false);
                                  setCa91(false);
                                  setCa95(false);
                                  setCa96(false);
                                }}
                              />
                              <label htmlFor="j94">
                                <img src={A9Q4} />
                              </label>
                            </div>
                            <div className={ca95 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j95"
                                name="9"
                                value={5}
                                onChange={(event) => {
                                  setJA9(event.target.value);
                                  setCa95(event.target.checked);
                                  setCa92(false);
                                  setCa93(false);
                                  setCa94(false);
                                  setCa91(false);
                                  setCa96(false);
                                }}
                              />
                              <label htmlFor="j95">
                                <img src={A9Q5} />
                              </label>
                            </div>
                            <div className={ca96 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j96"
                                name="9"
                                value={6}
                                onChange={(event) => {
                                  setJA9(event.target.value);
                                  setCa96(event.target.checked);
                                  setCa92(false);
                                  setCa93(false);
                                  setCa94(false);
                                  setCa95(false);
                                  setCa91(false);
                                }}
                              />
                              <label htmlFor="j96">
                                <img src={A9Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "B" && (
                        <>
                          <img src={B9} className="fotoSoal" />

                          <div className="listJawaban">
                            <div className={cb91 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j91"
                                name="9"
                                value={1}
                                onChange={(event) => {
                                  setJB9(event.target.value);
                                  setCb91(event.target.checked);
                                  setCb92(false);
                                  setCb93(false);
                                  setCb94(false);
                                  setCb95(false);
                                  setCb96(false);
                                }}
                              />
                              <label htmlFor="j91">
                                <img src={B9Q1} />
                              </label>
                            </div>
                            <div className={cb92 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j92"
                                name="9"
                                value={2}
                                onChange={(event) => {
                                  setJB9(event.target.value);
                                  setCb92(event.target.checked);
                                  setCb91(false);
                                  setCb93(false);
                                  setCb94(false);
                                  setCb95(false);
                                  setCb96(false);
                                }}
                              />
                              <label htmlFor="j92">
                                <img src={B9Q2} />
                              </label>
                            </div>
                            <div className={cb93 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j93"
                                name="9"
                                value={3}
                                onChange={(event) => {
                                  setJB9(event.target.value);
                                  setCb93(event.target.checked);
                                  setCb92(false);
                                  setCb91(false);
                                  setCb94(false);
                                  setCb95(false);
                                  setCb96(false);
                                }}
                              />
                              <label htmlFor="j93">
                                <img src={B9Q3} />
                              </label>
                            </div>
                            <div className={cb94 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j94"
                                name="9"
                                value={4}
                                onChange={(event) => {
                                  setJB9(event.target.value);
                                  setCb94(event.target.checked);
                                  setCb92(false);
                                  setCb93(false);
                                  setCb91(false);
                                  setCb95(false);
                                  setCb96(false);
                                }}
                              />
                              <label htmlFor="j94">
                                <img src={B9Q4} />
                              </label>
                            </div>
                            <div className={cb95 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j95"
                                name="9"
                                value={5}
                                onChange={(event) => {
                                  setJB9(event.target.value);
                                  setCb95(event.target.checked);
                                  setCb92(false);
                                  setCb93(false);
                                  setCb94(false);
                                  setCb91(false);
                                  setCb96(false);
                                }}
                              />
                              <label htmlFor="j95">
                                <img src={B9Q5} />
                              </label>
                            </div>
                            <div className={cb96 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j96"
                                name="9"
                                value={6}
                                onChange={(event) => {
                                  setJB9(event.target.value);
                                  setCb96(event.target.checked);
                                  setCb92(false);
                                  setCb93(false);
                                  setCb94(false);
                                  setCb95(false);
                                  setCb91(false);
                                }}
                              />
                              <label htmlFor="j96">
                                <img src={B9Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "C" && (
                        <>
                          <img src={C9} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div className={cc91 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j91"
                                name="9"
                                value={1}
                                onChange={(event) => {
                                  setJC9(event.target.value);
                                  setCc91(event.target.checked);
                                  setCc92(false);
                                  setCc93(false);
                                  setCc94(false);
                                  setCc95(false);
                                  setCc96(false);
                                  setCc97(false);
                                  setCc98(false);
                                }}
                              />
                              <label htmlFor="j91">
                                <img src={C9Q1} />
                              </label>
                            </div>
                            <div className={cc92 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j92"
                                name="9"
                                value={2}
                                onChange={(event) => {
                                  setJC9(event.target.value);
                                  setCc92(event.target.checked);
                                  setCc91(false);
                                  setCc93(false);
                                  setCc94(false);
                                  setCc95(false);
                                  setCc96(false);
                                  setCc97(false);
                                  setCc98(false);
                                }}
                              />
                              <label htmlFor="j92">
                                <img src={C9Q2} />
                              </label>
                            </div>
                            <div className={cc93 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j93"
                                name="9"
                                value={3}
                                onChange={(event) => {
                                  setJC9(event.target.value);
                                  setCc93(event.target.checked);
                                  setCc91(false);
                                  setCc92(false);
                                  setCc94(false);
                                  setCc95(false);
                                  setCc96(false);
                                  setCc97(false);
                                  setCc98(false);
                                }}
                              />
                              <label htmlFor="j93">
                                <img src={C9Q3} />
                              </label>
                            </div>
                            <div className={cc94 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j94"
                                name="9"
                                value={4}
                                onChange={(event) => {
                                  setJC9(event.target.value);
                                  setCc94(event.target.checked);
                                  setCc91(false);
                                  setCc92(false);
                                  setCc93(false);
                                  setCc95(false);
                                  setCc96(false);
                                  setCc97(false);
                                  setCc98(false);
                                }}
                              />
                              <label htmlFor="j94">
                                <img src={C9Q4} />
                              </label>
                            </div>
                            <div className={cc95 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j95"
                                name="9"
                                value={5}
                                onChange={(event) => {
                                  setJC9(event.target.value);
                                  setCc95(event.target.checked);
                                  setCc91(false);
                                  setCc92(false);
                                  setCc93(false);
                                  setCc94(false);
                                  setCc96(false);
                                  setCc97(false);
                                  setCc98(false);
                                }}
                              />
                              <label htmlFor="j95">
                                <img src={C9Q5} />
                              </label>
                            </div>
                            <div className={cc96 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j96"
                                name="9"
                                value={6}
                                onChange={(event) => {
                                  setJC9(event.target.value);
                                  setCc96(event.target.checked);
                                  setCc91(false);
                                  setCc92(false);
                                  setCc93(false);
                                  setCc94(false);
                                  setCc95(false);
                                  setCc97(false);
                                  setCc98(false);
                                }}
                              />
                              <label htmlFor="j96">
                                <img src={C9Q6} />
                              </label>
                            </div>
                            <div className={cc97 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j97"
                                name="9"
                                value={7}
                                onChange={(event) => {
                                  setJC9(event.target.value);
                                  setCc97(event.target.checked);
                                  setCc91(false);
                                  setCc92(false);
                                  setCc93(false);
                                  setCc94(false);
                                  setCc95(false);
                                  setCc96(false);
                                  setCc98(false);
                                }}
                              />
                              <label htmlFor="j97">
                                <img src={C9Q7} />
                              </label>
                            </div>
                            <div className={cc98 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j98"
                                name="9"
                                value={8}
                                onChange={(event) => {
                                  setJC9(event.target.value);
                                  setCc98(event.target.checked);
                                  setCc91(false);
                                  setCc92(false);
                                  setCc93(false);
                                  setCc94(false);
                                  setCc95(false);
                                  setCc96(false);
                                  setCc97(false);
                                }}
                              />
                              <label htmlFor="j98">
                                <img src={C9Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "D" && (
                        <>
                          <img src={D9} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div className={cd91 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j91"
                                name="9"
                                value={1}
                                onChange={(event) => {
                                  setJD9(event.target.value);
                                  setCd91(event.target.checked);
                                  setCd92(false);
                                  setCd93(false);
                                  setCd94(false);
                                  setCd95(false);
                                  setCd96(false);
                                  setCd97(false);
                                  setCd98(false);
                                }}
                              />
                              <label htmlFor="j91">
                                <img src={D9Q1} />
                              </label>
                            </div>
                            <div className={cd92 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j92"
                                name="9"
                                value={2}
                                onChange={(event) => {
                                  setJD9(event.target.value);
                                  setCd92(event.target.checked);
                                  setCd91(false);
                                  setCd93(false);
                                  setCd94(false);
                                  setCd95(false);
                                  setCd96(false);
                                  setCd97(false);
                                  setCd98(false);
                                }}
                              />
                              <label htmlFor="j92">
                                <img src={D9Q2} />
                              </label>
                            </div>
                            <div className={cd93 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j93"
                                name="9"
                                value={3}
                                onChange={(event) => {
                                  setJD9(event.target.value);
                                  setCd93(event.target.checked);
                                  setCd91(false);
                                  setCd92(false);
                                  setCd94(false);
                                  setCd95(false);
                                  setCd96(false);
                                  setCd97(false);
                                  setCd98(false);
                                }}
                              />
                              <label htmlFor="j93">
                                <img src={D9Q3} />
                              </label>
                            </div>
                            <div className={cd94 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j94"
                                name="9"
                                value={4}
                                onChange={(event) => {
                                  setJD9(event.target.value);
                                  setCd94(event.target.checked);
                                  setCd91(false);
                                  setCd92(false);
                                  setCd93(false);
                                  setCd95(false);
                                  setCd96(false);
                                  setCd97(false);
                                  setCd98(false);
                                }}
                              />
                              <label htmlFor="j94">
                                <img src={D9Q4} />
                              </label>
                            </div>
                            <div className={cd95 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j95"
                                name="9"
                                value={5}
                                onChange={(event) => {
                                  setJD9(event.target.value);
                                  setCd95(event.target.checked);
                                  setCd91(false);
                                  setCd92(false);
                                  setCd93(false);
                                  setCd94(false);
                                  setCd96(false);
                                  setCd97(false);
                                  setCd98(false);
                                }}
                              />
                              <label htmlFor="j95">
                                <img src={D9Q5} />
                              </label>
                            </div>
                            <div className={cd96 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j96"
                                name="9"
                                value={6}
                                onChange={(event) => {
                                  setJD9(event.target.value);
                                  setCd96(event.target.checked);
                                  setCd91(false);
                                  setCd92(false);
                                  setCd93(false);
                                  setCd94(false);
                                  setCd95(false);
                                  setCd97(false);
                                  setCd98(false);
                                }}
                              />
                              <label htmlFor="j96">
                                <img src={D9Q6} />
                              </label>
                            </div>
                            <div className={cd97 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j97"
                                name="9"
                                value={7}
                                onChange={(event) => {
                                  setJD9(event.target.value);
                                  setCd97(event.target.checked);
                                  setCd91(false);
                                  setCd92(false);
                                  setCd93(false);
                                  setCd94(false);
                                  setCd95(false);
                                  setCd96(false);
                                  setCd98(false);
                                }}
                              />
                              <label htmlFor="j97">
                                <img src={D9Q7} />
                              </label>
                            </div>
                            <div className={cd98 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j98"
                                name="9"
                                value={8}
                                onChange={(event) => {
                                  setJD9(event.target.value);
                                  setCd98(event.target.checked);
                                  setCd91(false);
                                  setCd92(false);
                                  setCd93(false);
                                  setCd94(false);
                                  setCd95(false);
                                  setCd96(false);
                                  setCd97(false);
                                }}
                              />
                              <label htmlFor="j98">
                                <img src={D9Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "E" && (
                        <>
                          <img src={E9} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div className={ce91 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j91"
                                name="9"
                                value={1}
                                onChange={(event) => {
                                  setJE9(event.target.value);
                                  setCe91(event.target.checked);
                                  setCe92(false);
                                  setCe93(false);
                                  setCe94(false);
                                  setCe95(false);
                                  setCe96(false);
                                  setCe97(false);
                                  setCe98(false);
                                }}
                              />
                              <label htmlFor="j91">
                                <img src={E9Q1} />
                              </label>
                            </div>
                            <div className={ce92 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j92"
                                name="9"
                                value={2}
                                onChange={(event) => {
                                  setJE9(event.target.value);
                                  setCe92(event.target.checked);
                                  setCe91(false);
                                  setCe93(false);
                                  setCe94(false);
                                  setCe95(false);
                                  setCe96(false);
                                  setCe97(false);
                                  setCe98(false);
                                }}
                              />
                              <label htmlFor="j92">
                                <img src={E9Q2} />
                              </label>
                            </div>
                            <div className={ce93 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j93"
                                name="9"
                                value={3}
                                onChange={(event) => {
                                  setJE9(event.target.value);
                                  setCe93(event.target.checked);
                                  setCe91(false);
                                  setCe92(false);
                                  setCe94(false);
                                  setCe95(false);
                                  setCe96(false);
                                  setCe97(false);
                                  setCe98(false);
                                }}
                              />
                              <label htmlFor="j93">
                                <img src={E9Q3} />
                              </label>
                            </div>
                            <div className={ce94 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j94"
                                name="9"
                                value={4}
                                onChange={(event) => {
                                  setJE9(event.target.value);
                                  setCe94(event.target.checked);
                                  setCe91(false);
                                  setCe92(false);
                                  setCe93(false);
                                  setCe95(false);
                                  setCe96(false);
                                  setCe97(false);
                                  setCe98(false);
                                }}
                              />
                              <label htmlFor="j94">
                                <img src={E9Q4} />
                              </label>
                            </div>
                            <div className={ce95 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j95"
                                name="9"
                                value={5}
                                onChange={(event) => {
                                  setJE9(event.target.value);
                                  setCe95(event.target.checked);
                                  setCe91(false);
                                  setCe92(false);
                                  setCe93(false);
                                  setCe94(false);
                                  setCe96(false);
                                  setCe97(false);
                                  setCe98(false);
                                }}
                              />
                              <label htmlFor="j95">
                                <img src={E9Q5} />
                              </label>
                            </div>
                            <div className={ce96 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j96"
                                name="9"
                                value={6}
                                onChange={(event) => {
                                  setJE9(event.target.value);
                                  setCe96(event.target.checked);
                                  setCe91(false);
                                  setCe92(false);
                                  setCe93(false);
                                  setCe94(false);
                                  setCe95(false);
                                  setCe97(false);
                                  setCe98(false);
                                }}
                              />
                              <label htmlFor="j96">
                                <img src={E9Q6} />
                              </label>
                            </div>
                            <div className={ce97 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j97"
                                name="9"
                                value={7}
                                onChange={(event) => {
                                  setJE9(event.target.value);
                                  setCe97(event.target.checked);
                                  setCe91(false);
                                  setCe92(false);
                                  setCe93(false);
                                  setCe94(false);
                                  setCe95(false);
                                  setCe96(false);
                                  setCe98(false);
                                }}
                              />
                              <label htmlFor="j97">
                                <img src={E9Q7} />
                              </label>
                            </div>
                            <div className={ce98 ? "jawaban click" : "jawaban"}>
                              <input
                                type="radio"
                                id="j98"
                                name="9"
                                value={8}
                                onChange={(event) => {
                                  setJE9(event.target.value);
                                  setCe98(event.target.checked);
                                  setCe91(false);
                                  setCe92(false);
                                  setCe93(false);
                                  setCe94(false);
                                  setCe95(false);
                                  setCe96(false);
                                  setCe97(false);
                                }}
                              />
                              <label htmlFor="j98">
                                <img src={E9Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {nomor % 12 === 10 && (
                    <>
                      {typeNow === "A" && (
                        <>
                          <img src={A10} className="fotoSoal" />

                          <div className="listJawaban">
                            <div
                              className={ca101 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j101"
                                name="10"
                                value={1}
                                onChange={(event) => {
                                  setJA10(event.target.value);
                                  setCa101(event.target.checked);
                                  setCa102(false);
                                  setCa103(false);
                                  setCa104(false);
                                  setCa105(false);
                                  setCa106(false);
                                }}
                              />
                              <label htmlFor="j101">
                                <img src={A10Q1} />
                              </label>
                            </div>
                            <div
                              className={ca102 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j102"
                                name="10"
                                value={2}
                                onChange={(event) => {
                                  setJA10(event.target.value);
                                  setCa102(event.target.checked);
                                  setCa101(false);
                                  setCa103(false);
                                  setCa104(false);
                                  setCa105(false);
                                  setCa106(false);
                                }}
                              />
                              <label htmlFor="j102">
                                <img src={A10Q2} />
                              </label>
                            </div>
                            <div
                              className={ca103 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j103"
                                name="10"
                                value={3}
                                onChange={(event) => {
                                  setJA10(event.target.value);
                                  setCa103(event.target.checked);
                                  setCa102(false);
                                  setCa101(false);
                                  setCa104(false);
                                  setCa105(false);
                                  setCa106(false);
                                }}
                              />
                              <label htmlFor="j103">
                                <img src={A10Q3} />
                              </label>
                            </div>
                            <div
                              className={ca104 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j104"
                                name="10"
                                value={4}
                                onChange={(event) => {
                                  setJA10(event.target.value);
                                  setCa104(event.target.checked);
                                  setCa102(false);
                                  setCa103(false);
                                  setCa101(false);
                                  setCa105(false);
                                  setCa106(false);
                                }}
                              />
                              <label htmlFor="j104">
                                <img src={A10Q4} />
                              </label>
                            </div>
                            <div
                              className={ca105 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j105"
                                name="10"
                                value={5}
                                onChange={(event) => {
                                  setJA10(event.target.value);
                                  setCa105(event.target.checked);
                                  setCa102(false);
                                  setCa103(false);
                                  setCa104(false);
                                  setCa101(false);
                                  setCa106(false);
                                }}
                              />
                              <label htmlFor="j105">
                                <img src={A10Q5} />
                              </label>
                            </div>
                            <div
                              className={ca106 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j106"
                                name="10"
                                value={6}
                                onChange={(event) => {
                                  setJA10(event.target.value);
                                  setCa106(event.target.checked);
                                  setCa102(false);
                                  setCa103(false);
                                  setCa104(false);
                                  setCa105(false);
                                  setCa101(false);
                                }}
                              />
                              <label htmlFor="j106">
                                <img src={A10Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "B" && (
                        <>
                          <img src={B10} className="fotoSoal" />

                          <div className="listJawaban">
                            <div
                              className={cb101 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j101"
                                name="10"
                                value={1}
                                onChange={(event) => {
                                  setJB10(event.target.value);
                                  setCb101(event.target.checked);
                                  setCb102(false);
                                  setCb103(false);
                                  setCb104(false);
                                  setCb105(false);
                                  setCb106(false);
                                }}
                              />
                              <label htmlFor="j101">
                                <img src={B10Q1} />
                              </label>
                            </div>
                            <div
                              className={cb102 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j102"
                                name="10"
                                value={2}
                                onChange={(event) => {
                                  setJB10(event.target.value);
                                  setCb102(event.target.checked);
                                  setCb101(false);
                                  setCb103(false);
                                  setCb104(false);
                                  setCb105(false);
                                  setCb106(false);
                                }}
                              />
                              <label htmlFor="j102">
                                <img src={B10Q2} />
                              </label>
                            </div>
                            <div
                              className={cb103 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j103"
                                name="10"
                                value={3}
                                onChange={(event) => {
                                  setJB10(event.target.value);
                                  setCb103(event.target.checked);
                                  setCb102(false);
                                  setCb101(false);
                                  setCb104(false);
                                  setCb105(false);
                                  setCb106(false);
                                }}
                              />
                              <label htmlFor="j103">
                                <img src={B10Q3} />
                              </label>
                            </div>
                            <div
                              className={cb104 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j104"
                                name="10"
                                value={4}
                                onChange={(event) => {
                                  setJB10(event.target.value);
                                  setCb104(event.target.checked);
                                  setCb102(false);
                                  setCb103(false);
                                  setCb101(false);
                                  setCb105(false);
                                  setCb106(false);
                                }}
                              />
                              <label htmlFor="j104">
                                <img src={B10Q4} />
                              </label>
                            </div>
                            <div
                              className={cb105 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j105"
                                name="10"
                                value={5}
                                onChange={(event) => {
                                  setJB10(event.target.value);
                                  setCb105(event.target.checked);
                                  setCb102(false);
                                  setCb103(false);
                                  setCb104(false);
                                  setCb101(false);
                                  setCb106(false);
                                }}
                              />
                              <label htmlFor="j105">
                                <img src={B10Q5} />
                              </label>
                            </div>
                            <div
                              className={cb106 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j106"
                                name="10"
                                value={6}
                                onChange={(event) => {
                                  setJB10(event.target.value);
                                  setCb106(event.target.checked);
                                  setCb102(false);
                                  setCb103(false);
                                  setCb104(false);
                                  setCb105(false);
                                  setCb101(false);
                                }}
                              />
                              <label htmlFor="j106">
                                <img src={B10Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "C" && (
                        <>
                          <img src={C10} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div
                              className={cc101 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j101"
                                name="10"
                                value={1}
                                onChange={(event) => {
                                  setJC10(event.target.value);
                                  setCc101(event.target.checked);
                                  setCc102(false);
                                  setCc103(false);
                                  setCc104(false);
                                  setCc105(false);
                                  setCc106(false);
                                  setCc107(false);
                                  setCc108(false);
                                }}
                              />
                              <label htmlFor="j101">
                                <img src={C10Q1} />
                              </label>
                            </div>
                            <div
                              className={cc102 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j102"
                                name="10"
                                value={2}
                                onChange={(event) => {
                                  setJC10(event.target.value);
                                  setCc102(event.target.checked);
                                  setCc101(false);
                                  setCc103(false);
                                  setCc104(false);
                                  setCc105(false);
                                  setCc106(false);
                                  setCc107(false);
                                  setCc108(false);
                                }}
                              />
                              <label htmlFor="j102">
                                <img src={C10Q2} />
                              </label>
                            </div>
                            <div
                              className={cc103 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j103"
                                name="10"
                                value={3}
                                onChange={(event) => {
                                  setJC10(event.target.value);
                                  setCc103(event.target.checked);
                                  setCc101(false);
                                  setCc102(false);
                                  setCc104(false);
                                  setCc105(false);
                                  setCc106(false);
                                  setCc107(false);
                                  setCc108(false);
                                }}
                              />
                              <label htmlFor="j103">
                                <img src={C10Q3} />
                              </label>
                            </div>
                            <div
                              className={cc104 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j104"
                                name="10"
                                value={4}
                                onChange={(event) => {
                                  setJC10(event.target.value);
                                  setCc104(event.target.checked);
                                  setCc101(false);
                                  setCc102(false);
                                  setCc103(false);
                                  setCc105(false);
                                  setCc106(false);
                                  setCc107(false);
                                  setCc108(false);
                                }}
                              />
                              <label htmlFor="j104">
                                <img src={C10Q4} />
                              </label>
                            </div>
                            <div
                              className={cc105 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j105"
                                name="10"
                                value={5}
                                onChange={(event) => {
                                  setJC10(event.target.value);
                                  setCc105(event.target.checked);
                                  setCc101(false);
                                  setCc102(false);
                                  setCc103(false);
                                  setCc104(false);
                                  setCc105(false);
                                  setCc107(false);
                                  setCc108(false);
                                }}
                              />
                              <label htmlFor="j105">
                                <img src={C10Q5} />
                              </label>
                            </div>
                            <div
                              className={cc106 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j106"
                                name="10"
                                value={6}
                                onChange={(event) => {
                                  setJC10(event.target.value);
                                  setCc106(event.target.checked);
                                  setCc101(false);
                                  setCc102(false);
                                  setCc103(false);
                                  setCc104(false);
                                  setCc105(false);
                                  setCc107(false);
                                  setCc108(false);
                                }}
                              />
                              <label htmlFor="j106">
                                <img src={C10Q6} />
                              </label>
                            </div>
                            <div
                              className={cc107 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j107"
                                name="10"
                                value={7}
                                onChange={(event) => {
                                  setJC10(event.target.value);
                                  setCc107(event.target.checked);
                                  setCc101(false);
                                  setCc102(false);
                                  setCc103(false);
                                  setCc104(false);
                                  setCc105(false);
                                  setCc106(false);
                                  setCc108(false);
                                }}
                              />
                              <label htmlFor="j107">
                                <img src={C10Q7} />
                              </label>
                            </div>
                            <div
                              className={cc108 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j108"
                                name="10"
                                value={8}
                                onChange={(event) => {
                                  setJC10(event.target.value);
                                  setCc108(event.target.checked);
                                  setCc101(false);
                                  setCc102(false);
                                  setCc103(false);
                                  setCc104(false);
                                  setCc105(false);
                                  setCc106(false);
                                  setCc107(false);
                                }}
                              />
                              <label htmlFor="j108">
                                <img src={C10Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "D" && (
                        <>
                          <img src={D10} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div
                              className={cd101 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j101"
                                name="10"
                                value={1}
                                onChange={(event) => {
                                  setJD10(event.target.value);
                                  setCd101(event.target.checked);
                                  setCd102(false);
                                  setCd103(false);
                                  setCd104(false);
                                  setCd105(false);
                                  setCd106(false);
                                  setCd107(false);
                                  setCd108(false);
                                }}
                              />
                              <label htmlFor="j101">
                                <img src={D10Q1} />
                              </label>
                            </div>
                            <div
                              className={cd102 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j102"
                                name="10"
                                value={2}
                                onChange={(event) => {
                                  setJD10(event.target.value);
                                  setCd102(event.target.checked);
                                  setCd101(false);
                                  setCd103(false);
                                  setCd104(false);
                                  setCd105(false);
                                  setCd106(false);
                                  setCd107(false);
                                  setCd108(false);
                                }}
                              />
                              <label htmlFor="j102">
                                <img src={D10Q2} />
                              </label>
                            </div>
                            <div
                              className={cd103 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j103"
                                name="10"
                                value={3}
                                onChange={(event) => {
                                  setJD10(event.target.value);
                                  setCd103(event.target.checked);
                                  setCd101(false);
                                  setCd102(false);
                                  setCd104(false);
                                  setCd105(false);
                                  setCd106(false);
                                  setCd107(false);
                                  setCd108(false);
                                }}
                              />
                              <label htmlFor="j103">
                                <img src={D10Q3} />
                              </label>
                            </div>
                            <div
                              className={cd104 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j104"
                                name="10"
                                value={4}
                                onChange={(event) => {
                                  setJD10(event.target.value);
                                  setCd104(event.target.checked);
                                  setCd101(false);
                                  setCd102(false);
                                  setCd103(false);
                                  setCd105(false);
                                  setCd106(false);
                                  setCd107(false);
                                  setCd108(false);
                                }}
                              />
                              <label htmlFor="j104">
                                <img src={D10Q4} />
                              </label>
                            </div>
                            <div
                              className={cd105 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j105"
                                name="10"
                                value={5}
                                onChange={(event) => {
                                  setJD10(event.target.value);
                                  setCd105(event.target.checked);
                                  setCd101(false);
                                  setCd102(false);
                                  setCd103(false);
                                  setCd104(false);
                                  setCd106(false);
                                  setCd107(false);
                                  setCd108(false);
                                }}
                              />
                              <label htmlFor="j105">
                                <img src={D10Q5} />
                              </label>
                            </div>
                            <div
                              className={cd106 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j106"
                                name="10"
                                value={6}
                                onChange={(event) => {
                                  setJD10(event.target.value);
                                  setCd106(event.target.checked);
                                  setCd101(false);
                                  setCd102(false);
                                  setCd103(false);
                                  setCd104(false);
                                  setCd105(false);
                                  setCd107(false);
                                  setCd108(false);
                                }}
                              />
                              <label htmlFor="j106">
                                <img src={D10Q6} />
                              </label>
                            </div>
                            <div
                              className={cd107 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j107"
                                name="10"
                                value={7}
                                onChange={(event) => {
                                  setJD10(event.target.value);
                                  setCd107(event.target.checked);
                                  setCd101(false);
                                  setCd102(false);
                                  setCd103(false);
                                  setCd104(false);
                                  setCd105(false);
                                  setCd106(false);
                                  setCd108(false);
                                }}
                              />
                              <label htmlFor="j107">
                                <img src={D10Q7} />
                              </label>
                            </div>
                            <div
                              className={cd108 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j108"
                                name="10"
                                value={8}
                                onChange={(event) => {
                                  setJD10(event.target.value);
                                  setCd108(event.target.checked);
                                  setCd101(false);
                                  setCd102(false);
                                  setCd103(false);
                                  setCd104(false);
                                  setCd105(false);
                                  setCd106(false);
                                  setCd107(false);
                                }}
                              />
                              <label htmlFor="j108">
                                <img src={D10Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "E" && (
                        <>
                          <img src={E10} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div
                              className={ce101 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j101"
                                name="10"
                                value={1}
                                onChange={(event) => {
                                  setJE10(event.target.value);
                                  setCe101(event.target.checked);
                                  setCe102(false);
                                  setCe103(false);
                                  setCe104(false);
                                  setCe105(false);
                                  setCe106(false);
                                  setCe107(false);
                                  setCe108(false);
                                }}
                              />
                              <label htmlFor="j101">
                                <img src={E10Q1} />
                              </label>
                            </div>
                            <div
                              className={ce102 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j102"
                                name="10"
                                value={2}
                                onChange={(event) => {
                                  setJE10(event.target.value);
                                  setCe102(event.target.checked);
                                  setCe101(false);
                                  setCe103(false);
                                  setCe104(false);
                                  setCe105(false);
                                  setCe106(false);
                                  setCe107(false);
                                  setCe108(false);
                                }}
                              />
                              <label htmlFor="j102">
                                <img src={E10Q2} />
                              </label>
                            </div>
                            <div
                              className={ce103 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j103"
                                name="10"
                                value={3}
                                onChange={(event) => {
                                  setJE10(event.target.value);
                                  setCe103(event.target.checked);
                                  setCe101(false);
                                  setCe102(false);
                                  setCe104(false);
                                  setCe105(false);
                                  setCe106(false);
                                  setCe107(false);
                                  setCe108(false);
                                }}
                              />
                              <label htmlFor="j103">
                                <img src={E10Q3} />
                              </label>
                            </div>
                            <div
                              className={ce104 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j104"
                                name="10"
                                value={4}
                                onChange={(event) => {
                                  setJE10(event.target.value);
                                  setCe104(event.target.checked);
                                  setCe101(false);
                                  setCe102(false);
                                  setCe103(false);
                                  setCe105(false);
                                  setCe106(false);
                                  setCe107(false);
                                  setCe108(false);
                                }}
                              />
                              <label htmlFor="j104">
                                <img src={E10Q4} />
                              </label>
                            </div>
                            <div
                              className={ce105 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j105"
                                name="10"
                                value={5}
                                onChange={(event) => {
                                  setJE10(event.target.value);
                                  setCe105(event.target.checked);
                                  setCe101(false);
                                  setCe102(false);
                                  setCe103(false);
                                  setCe104(false);
                                  setCe106(false);
                                  setCe107(false);
                                  setCe108(false);
                                }}
                              />
                              <label htmlFor="j105">
                                <img src={E10Q5} />
                              </label>
                            </div>
                            <div
                              className={ce106 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j106"
                                name="10"
                                value={6}
                                onChange={(event) => {
                                  setJE10(event.target.value);
                                  setCe106(event.target.checked);
                                  setCe101(false);
                                  setCe102(false);
                                  setCe103(false);
                                  setCe104(false);
                                  setCe105(false);
                                  setCe107(false);
                                  setCe108(false);
                                }}
                              />
                              <label htmlFor="j106">
                                <img src={E10Q6} />
                              </label>
                            </div>
                            <div
                              className={ce107 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j107"
                                name="10"
                                value={7}
                                onChange={(event) => {
                                  setJE10(event.target.value);
                                  setCe107(event.target.checked);
                                  setCe101(false);
                                  setCe102(false);
                                  setCe103(false);
                                  setCe104(false);
                                  setCe105(false);
                                  setCe106(false);
                                  setCe108(false);
                                }}
                              />
                              <label htmlFor="j107">
                                <img src={E10Q7} />
                              </label>
                            </div>
                            <div
                              className={ce108 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j108"
                                name="10"
                                value={8}
                                onChange={(event) => {
                                  setJE10(event.target.value);
                                  setCe108(event.target.checked);
                                  setCe101(false);
                                  setCe102(false);
                                  setCe103(false);
                                  setCe104(false);
                                  setCe105(false);
                                  setCe106(false);
                                  setCe107(false);
                                }}
                              />
                              <label htmlFor="j108">
                                <img src={E10Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {nomor % 12 === 11 && (
                    <>
                      {typeNow === "A" && (
                        <>
                          <img src={A11} className="fotoSoal" />

                          <div className="listJawaban">
                            <div
                              className={ca111 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j111"
                                name="11"
                                value={1}
                                onChange={(event) => {
                                  setJA11(event.target.value);
                                  setCa111(event.target.checked);
                                  setCa112(false);
                                  setCa113(false);
                                  setCa114(false);
                                  setCa115(false);
                                  setCa116(false);
                                }}
                              />
                              <label htmlFor="j111">
                                <img src={A11Q1} />
                              </label>
                            </div>
                            <div
                              className={ca112 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j112"
                                name="11"
                                value={2}
                                onChange={(event) => {
                                  setJA11(event.target.value);
                                  setCa112(event.target.checked);
                                  setCa111(false);
                                  setCa113(false);
                                  setCa114(false);
                                  setCa115(false);
                                  setCa116(false);
                                }}
                              />
                              <label htmlFor="j112">
                                <img src={A11Q2} />
                              </label>
                            </div>
                            <div
                              className={ca113 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j113"
                                name="11"
                                value={3}
                                onChange={(event) => {
                                  setJA11(event.target.value);
                                  setCa113(event.target.checked);
                                  setCa112(false);
                                  setCa111(false);
                                  setCa114(false);
                                  setCa115(false);
                                  setCa116(false);
                                }}
                              />
                              <label htmlFor="j113">
                                <img src={A11Q3} />
                              </label>
                            </div>
                            <div
                              className={ca114 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j114"
                                name="11"
                                value={4}
                                onChange={(event) => {
                                  setJA11(event.target.value);
                                  setCa114(event.target.checked);
                                  setCa112(false);
                                  setCa113(false);
                                  setCa111(false);
                                  setCa115(false);
                                  setCa116(false);
                                }}
                              />
                              <label htmlFor="j114">
                                <img src={A11Q4} />
                              </label>
                            </div>
                            <div
                              className={ca115 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j115"
                                name="11"
                                value={5}
                                onChange={(event) => {
                                  setJA11(event.target.value);
                                  setCa115(event.target.checked);
                                  setCa112(false);
                                  setCa113(false);
                                  setCa114(false);
                                  setCa111(false);
                                  setCa116(false);
                                }}
                              />
                              <label htmlFor="j115">
                                <img src={A11Q5} />
                              </label>
                            </div>
                            <div
                              className={ca116 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j116"
                                name="11"
                                value={6}
                                onChange={(event) => {
                                  setJA11(event.target.value);
                                  setCa116(event.target.checked);
                                  setCa112(false);
                                  setCa113(false);
                                  setCa114(false);
                                  setCa115(false);
                                  setCa111(false);
                                }}
                              />
                              <label htmlFor="j116">
                                <img src={A11Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "B" && (
                        <>
                          <img src={B11} className="fotoSoal" />

                          <div className="listJawaban">
                            <div
                              className={cb111 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j111"
                                name="11"
                                value={1}
                                onChange={(event) => {
                                  setJB11(event.target.value);
                                  setCb111(event.target.checked);
                                  setCb112(false);
                                  setCb113(false);
                                  setCb114(false);
                                  setCb115(false);
                                  setCb116(false);
                                }}
                              />
                              <label htmlFor="j111">
                                <img src={B11Q1} />
                              </label>
                            </div>
                            <div
                              className={cb112 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j112"
                                name="11"
                                value={2}
                                onChange={(event) => {
                                  setJB11(event.target.value);
                                  setCb112(event.target.checked);
                                  setCb111(false);
                                  setCb113(false);
                                  setCb114(false);
                                  setCb115(false);
                                  setCb116(false);
                                }}
                              />
                              <label htmlFor="j112">
                                <img src={B11Q2} />
                              </label>
                            </div>
                            <div
                              className={cb113 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j113"
                                name="11"
                                value={3}
                                onChange={(event) => {
                                  setJB11(event.target.value);
                                  setCb113(event.target.checked);
                                  setCb112(false);
                                  setCb111(false);
                                  setCb114(false);
                                  setCb115(false);
                                  setCb116(false);
                                }}
                              />
                              <label htmlFor="j113">
                                <img src={B11Q3} />
                              </label>
                            </div>
                            <div
                              className={cb114 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j114"
                                name="11"
                                value={4}
                                onChange={(event) => {
                                  setJB11(event.target.value);
                                  setCb114(event.target.checked);
                                  setCb112(false);
                                  setCb113(false);
                                  setCb111(false);
                                  setCb115(false);
                                  setCb116(false);
                                }}
                              />
                              <label htmlFor="j114">
                                <img src={B11Q4} />
                              </label>
                            </div>
                            <div
                              className={cb115 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j115"
                                name="11"
                                value={5}
                                onChange={(event) => {
                                  setJB11(event.target.value);
                                  setCb115(event.target.checked);
                                  setCb112(false);
                                  setCb113(false);
                                  setCb114(false);
                                  setCb111(false);
                                  setCb116(false);
                                }}
                              />
                              <label htmlFor="j115">
                                <img src={B11Q5} />
                              </label>
                            </div>
                            <div
                              className={cb116 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j116"
                                name="11"
                                value={6}
                                onChange={(event) => {
                                  setJB11(event.target.value);
                                  setCb116(event.target.checked);
                                  setCb112(false);
                                  setCb113(false);
                                  setCb114(false);
                                  setCb115(false);
                                  setCb111(false);
                                }}
                              />
                              <label htmlFor="j116">
                                <img src={B11Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "C" && (
                        <>
                          <img src={C11} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div
                              className={cc111 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j111"
                                name="11"
                                value={1}
                                onChange={(event) => {
                                  setJC11(event.target.value);
                                  setCc111(event.target.checked);
                                  setCc112(false);
                                  setCc113(false);
                                  setCc114(false);
                                  setCc115(false);
                                  setCc116(false);
                                  setCc117(false);
                                  setCc118(false);
                                }}
                              />
                              <label htmlFor="j111">
                                <img src={C11Q1} />
                              </label>
                            </div>
                            <div
                              className={cc112 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j112"
                                name="11"
                                value={2}
                                onChange={(event) => {
                                  setJC11(event.target.value);
                                  setCc112(event.target.checked);
                                  setCc111(false);
                                  setCc113(false);
                                  setCc114(false);
                                  setCc115(false);
                                  setCc116(false);
                                  setCc117(false);
                                  setCc118(false);
                                }}
                              />
                              <label htmlFor="j112">
                                <img src={C11Q2} />
                              </label>
                            </div>
                            <div
                              className={cc113 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j113"
                                name="11"
                                value={3}
                                onChange={(event) => {
                                  setJC11(event.target.value);
                                  setCc113(event.target.checked);
                                  setCc111(false);
                                  setCc112(false);
                                  setCc114(false);
                                  setCc115(false);
                                  setCc116(false);
                                  setCc117(false);
                                  setCc118(false);
                                }}
                              />
                              <label htmlFor="j113">
                                <img src={C11Q3} />
                              </label>
                            </div>
                            <div
                              className={cc114 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j114"
                                name="11"
                                value={4}
                                onChange={(event) => {
                                  setJC11(event.target.value);
                                  setCc114(event.target.checked);
                                  setCc111(false);
                                  setCc112(false);
                                  setCc113(false);
                                  setCc115(false);
                                  setCc116(false);
                                  setCc117(false);
                                  setCc118(false);
                                }}
                              />
                              <label htmlFor="j114">
                                <img src={C11Q4} />
                              </label>
                            </div>
                            <div
                              className={cc115 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j115"
                                name="11"
                                value={5}
                                onChange={(event) => {
                                  setJC11(event.target.value);
                                  setCc115(event.target.checked);
                                  setCc111(false);
                                  setCc112(false);
                                  setCc113(false);
                                  setCc114(false);
                                  setCc116(false);
                                  setCc117(false);
                                  setCc118(false);
                                }}
                              />
                              <label htmlFor="j115">
                                <img src={C11Q5} />
                              </label>
                            </div>
                            <div
                              className={cc116 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j116"
                                name="11"
                                value={6}
                                onChange={(event) => {
                                  setJC11(event.target.value);
                                  setCc116(event.target.checked);
                                  setCc111(false);
                                  setCc112(false);
                                  setCc113(false);
                                  setCc114(false);
                                  setCc115(false);
                                  setCc117(false);
                                  setCc118(false);
                                }}
                              />
                              <label htmlFor="j116">
                                <img src={C11Q6} />
                              </label>
                            </div>
                            <div
                              className={cc117 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j117"
                                name="11"
                                value={7}
                                onChange={(event) => {
                                  setJC11(event.target.value);
                                  setCc117(event.target.checked);
                                  setCc111(false);
                                  setCc112(false);
                                  setCc113(false);
                                  setCc114(false);
                                  setCc115(false);
                                  setCc116(false);
                                  setCc118(false);
                                }}
                              />
                              <label htmlFor="j117">
                                <img src={C11Q7} />
                              </label>
                            </div>
                            <div
                              className={cc118 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j118"
                                name="11"
                                value={8}
                                onChange={(event) => {
                                  setJC11(event.target.value);
                                  setCc118(event.target.checked);
                                  setCc111(false);
                                  setCc112(false);
                                  setCc113(false);
                                  setCc114(false);
                                  setCc115(false);
                                  setCc116(false);
                                  setCc117(false);
                                }}
                              />
                              <label htmlFor="j118">
                                <img src={C11Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "D" && (
                        <>
                          <img src={D11} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div
                              className={cd111 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j111"
                                name="11"
                                value={1}
                                onChange={(event) => {
                                  setJD11(event.target.value);
                                  setCd111(event.target.checked);
                                  setCd112(false);
                                  setCd113(false);
                                  setCd114(false);
                                  setCd115(false);
                                  setCd116(false);
                                  setCd117(false);
                                  setCd118(false);
                                }}
                              />
                              <label htmlFor="j111">
                                <img src={D11Q1} />
                              </label>
                            </div>
                            <div
                              className={cd112 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j112"
                                name="11"
                                value={2}
                                onChange={(event) => {
                                  setJD11(event.target.value);
                                  setCd112(event.target.checked);
                                  setCd111(false);
                                  setCd113(false);
                                  setCd114(false);
                                  setCd115(false);
                                  setCd116(false);
                                  setCd117(false);
                                  setCd118(false);
                                }}
                              />
                              <label htmlFor="j112">
                                <img src={D11Q2} />
                              </label>
                            </div>
                            <div
                              className={cd113 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j113"
                                name="11"
                                value={3}
                                onChange={(event) => {
                                  setJD11(event.target.value);
                                  setCd113(event.target.checked);
                                  setCd111(false);
                                  setCd112(false);
                                  setCd114(false);
                                  setCd115(false);
                                  setCd116(false);
                                  setCd117(false);
                                  setCd118(false);
                                }}
                              />
                              <label htmlFor="j113">
                                <img src={D11Q3} />
                              </label>
                            </div>
                            <div
                              className={cd114 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j114"
                                name="11"
                                value={4}
                                onChange={(event) => {
                                  setJD11(event.target.value);
                                  setCd114(event.target.checked);
                                  setCd111(false);
                                  setCd112(false);
                                  setCd113(false);
                                  setCd115(false);
                                  setCd116(false);
                                  setCd117(false);
                                  setCd118(false);
                                }}
                              />
                              <label htmlFor="j114">
                                <img src={D11Q4} />
                              </label>
                            </div>
                            <div
                              className={cd115 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j115"
                                name="11"
                                value={5}
                                onChange={(event) => {
                                  setJD11(event.target.value);
                                  setCd115(event.target.checked);
                                  setCd111(false);
                                  setCd112(false);
                                  setCd113(false);
                                  setCd114(false);
                                  setCd116(false);
                                  setCd117(false);
                                  setCd118(false);
                                }}
                              />
                              <label htmlFor="j115">
                                <img src={D11Q5} />
                              </label>
                            </div>
                            <div
                              className={cd116 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j116"
                                name="11"
                                value={6}
                                onChange={(event) => {
                                  setJD11(event.target.value);
                                  setCd116(event.target.checked);
                                  setCd111(false);
                                  setCd112(false);
                                  setCd113(false);
                                  setCd114(false);
                                  setCd115(false);
                                  setCd117(false);
                                  setCd118(false);
                                }}
                              />
                              <label htmlFor="j116">
                                <img src={D11Q6} />
                              </label>
                            </div>
                            <div
                              className={cd117 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j117"
                                name="11"
                                value={7}
                                onChange={(event) => {
                                  setJD11(event.target.value);
                                  setCd117(event.target.checked);
                                  setCd111(false);
                                  setCd112(false);
                                  setCd113(false);
                                  setCd114(false);
                                  setCd115(false);
                                  setCd116(false);
                                  setCd118(false);
                                }}
                              />
                              <label htmlFor="j117">
                                <img src={D11Q7} />
                              </label>
                            </div>
                            <div
                              className={cd118 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j118"
                                name="11"
                                value={8}
                                onChange={(event) => {
                                  setJD11(event.target.value);
                                  setCd118(event.target.checked);
                                  setCd111(false);
                                  setCd112(false);
                                  setCd113(false);
                                  setCd114(false);
                                  setCd115(false);
                                  setCd116(false);
                                  setCd117(false);
                                }}
                              />
                              <label htmlFor="j118">
                                <img src={D11Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "E" && (
                        <>
                          <img src={E11} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div
                              className={ce111 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j111"
                                name="11"
                                value={1}
                                onChange={(event) => {
                                  setJE11(event.target.value);
                                  setCe111(event.target.checked);
                                  setCe112(false);
                                  setCe113(false);
                                  setCe114(false);
                                  setCe115(false);
                                  setCe116(false);
                                  setCe117(false);
                                  setCe118(false);
                                }}
                              />
                              <label htmlFor="j111">
                                <img src={E11Q1} />
                              </label>
                            </div>
                            <div
                              className={ce112 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j112"
                                name="11"
                                value={2}
                                onChange={(event) => {
                                  setJE11(event.target.value);
                                  setCe12(event.target.checked);
                                  setCe111(false);
                                  setCe113(false);
                                  setCe114(false);
                                  setCe115(false);
                                  setCe116(false);
                                  setCe117(false);
                                  setCe118(false);
                                }}
                              />
                              <label htmlFor="j112">
                                <img src={E11Q2} />
                              </label>
                            </div>
                            <div
                              className={ce113 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j113"
                                name="11"
                                value={3}
                                onChange={(event) => {
                                  setJE11(event.target.value);
                                  setCe113(event.target.checked);
                                  setCe111(false);
                                  setCe112(false);
                                  setCe114(false);
                                  setCe115(false);
                                  setCe116(false);
                                  setCe117(false);
                                  setCe118(false);
                                }}
                              />
                              <label htmlFor="j113">
                                <img src={E11Q3} />
                              </label>
                            </div>
                            <div
                              className={ce114 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j114"
                                name="11"
                                value={4}
                                onChange={(event) => {
                                  setJE11(event.target.value);
                                  setCe114(event.target.checked);
                                  setCe111(false);
                                  setCe112(false);
                                  setCe113(false);
                                  setCe115(false);
                                  setCe116(false);
                                  setCe117(false);
                                  setCe118(false);
                                }}
                              />
                              <label htmlFor="j114">
                                <img src={E11Q4} />
                              </label>
                            </div>
                            <div
                              className={ce115 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j115"
                                name="11"
                                value={5}
                                onChange={(event) => {
                                  setJE11(event.target.value);
                                  setCe115(event.target.checked);
                                  setCe111(false);
                                  setCe112(false);
                                  setCe113(false);
                                  setCe114(false);
                                  setCe116(false);
                                  setCe117(false);
                                  setCe118(false);
                                }}
                              />
                              <label htmlFor="j115">
                                <img src={E11Q5} />
                              </label>
                            </div>
                            <div
                              className={ce116 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j116"
                                name="11"
                                value={6}
                                onChange={(event) => {
                                  setJE11(event.target.value);
                                  setCe116(event.target.checked);
                                  setCe111(false);
                                  setCe112(false);
                                  setCe113(false);
                                  setCe114(false);
                                  setCe115(false);
                                  setCe117(false);
                                  setCe118(false);
                                }}
                              />
                              <label htmlFor="j116">
                                <img src={E11Q6} />
                              </label>
                            </div>
                            <div
                              className={ce117 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j117"
                                name="11"
                                value={7}
                                onChange={(event) => {
                                  setJE11(event.target.value);
                                  setCe117(event.target.checked);
                                  setCe111(false);
                                  setCe112(false);
                                  setCe113(false);
                                  setCe114(false);
                                  setCe115(false);
                                  setCe116(false);
                                  setCe118(false);
                                }}
                              />
                              <label htmlFor="j117">
                                <img src={E11Q7} />
                              </label>
                            </div>
                            <div
                              className={ce118 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j118"
                                name="11"
                                value={8}
                                onChange={(event) => {
                                  setJE11(event.target.value);
                                  setCe118(event.target.checked);
                                  setCe111(false);
                                  setCe112(false);
                                  setCe113(false);
                                  setCe114(false);
                                  setCe115(false);
                                  setCe116(false);
                                  setCe117(false);
                                }}
                              />
                              <label htmlFor="j118">
                                <img src={E11Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {nomor % 12 === 0 && (
                    <>
                      {typeNow === "A" && (
                        <>
                          <img src={A12} className="fotoSoal" />

                          <div className="listJawaban">
                            <div
                              className={ca121 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j121"
                                name="12"
                                value={1}
                                onChange={(event) => {
                                  setJA12(event.target.value);
                                  setCa121(event.target.checked);
                                  setCa122(false);
                                  setCa123(false);
                                  setCa124(false);
                                  setCa125(false);
                                  setCa126(false);
                                }}
                              />
                              <label htmlFor="j121">
                                <img src={A12Q1} />
                              </label>
                            </div>
                            <div
                              className={ca122 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j122"
                                name="12"
                                value={2}
                                onChange={(event) => {
                                  setJA12(event.target.value);
                                  setCa122(event.target.checked);
                                  setCa121(false);
                                  setCa123(false);
                                  setCa124(false);
                                  setCa125(false);
                                  setCa126(false);
                                }}
                              />
                              <label htmlFor="j122">
                                <img src={A12Q2} />
                              </label>
                            </div>
                            <div
                              className={ca123 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j123"
                                name="12"
                                value={3}
                                onChange={(event) => {
                                  setJA12(event.target.value);
                                  setCa123(event.target.checked);
                                  setCa122(false);
                                  setCa121(false);
                                  setCa124(false);
                                  setCa125(false);
                                  setCa126(false);
                                }}
                              />
                              <label htmlFor="j123">
                                <img src={A12Q3} />
                              </label>
                            </div>
                            <div
                              className={ca124 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j124"
                                name="12"
                                value={4}
                                onChange={(event) => {
                                  setJA12(event.target.value);
                                  setCa124(event.target.checked);
                                  setCa122(false);
                                  setCa123(false);
                                  setCa121(false);
                                  setCa125(false);
                                  setCa126(false);
                                }}
                              />
                              <label htmlFor="j124">
                                <img src={A12Q4} />
                              </label>
                            </div>
                            <div
                              className={ca125 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j125"
                                name="12"
                                value={5}
                                onChange={(event) => {
                                  setJA12(event.target.value);
                                  setCa125(event.target.checked);
                                  setCa122(false);
                                  setCa123(false);
                                  setCa124(false);
                                  setCa121(false);
                                  setCa126(false);
                                }}
                              />
                              <label htmlFor="j125">
                                <img src={A12Q5} />
                              </label>
                            </div>
                            <div
                              className={ca126 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j126"
                                name="12"
                                value={6}
                                onChange={(event) => {
                                  setJA12(event.target.value);
                                  setCa126(event.target.checked);
                                  setCa122(false);
                                  setCa123(false);
                                  setCa124(false);
                                  setCa125(false);
                                  setCa121(false);
                                }}
                              />
                              <label htmlFor="j126">
                                <img src={A12Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "B" && (
                        <>
                          <img src={B12} className="fotoSoal" />

                          <div className="listJawaban">
                            <div
                              className={cb121 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j121"
                                name="12"
                                value={1}
                                onChange={(event) => {
                                  setJB12(event.target.value);
                                  setCb121(event.target.checked);
                                  setCb122(false);
                                  setCb123(false);
                                  setCb124(false);
                                  setCb125(false);
                                  setCb126(false);
                                }}
                              />
                              <label htmlFor="j121">
                                <img src={B12Q1} />
                              </label>
                            </div>
                            <div
                              className={cb122 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j122"
                                name="12"
                                value={2}
                                onChange={(event) => {
                                  setJB12(event.target.value);
                                  setCb122(event.target.checked);
                                  setCb121(false);
                                  setCb123(false);
                                  setCb124(false);
                                  setCb125(false);
                                  setCb126(false);
                                }}
                              />
                              <label htmlFor="j122">
                                <img src={B12Q2} />
                              </label>
                            </div>
                            <div
                              className={cb123 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j123"
                                name="12"
                                value={3}
                                onChange={(event) => {
                                  setJB12(event.target.value);
                                  setCb123(event.target.checked);
                                  setCb122(false);
                                  setCb121(false);
                                  setCb124(false);
                                  setCb125(false);
                                  setCb126(false);
                                }}
                              />
                              <label htmlFor="j123">
                                <img src={B12Q3} />
                              </label>
                            </div>
                            <div
                              className={cb124 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j124"
                                name="12"
                                value={4}
                                onChange={(event) => {
                                  setJB12(event.target.value);
                                  setCb124(event.target.checked);
                                  setCb122(false);
                                  setCb123(false);
                                  setCb121(false);
                                  setCb125(false);
                                  setCb126(false);
                                }}
                              />
                              <label htmlFor="j124">
                                <img src={B12Q4} />
                              </label>
                            </div>
                            <div
                              className={cb125 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j125"
                                name="12"
                                value={5}
                                onChange={(event) => {
                                  setJB12(event.target.value);
                                  setCb125(event.target.checked);
                                  setCb122(false);
                                  setCb123(false);
                                  setCb124(false);
                                  setCb121(false);
                                  setCb126(false);
                                }}
                              />
                              <label htmlFor="j125">
                                <img src={B12Q5} />
                              </label>
                            </div>
                            <div
                              className={cb126 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j126"
                                name="12"
                                value={6}
                                onChange={(event) => {
                                  setJB12(event.target.value);
                                  setCb126(event.target.checked);
                                  setCb122(false);
                                  setCb123(false);
                                  setCb124(false);
                                  setCb125(false);
                                  setCb121(false);
                                }}
                              />
                              <label htmlFor="j126">
                                <img src={B12Q6} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "C" && (
                        <>
                          <img src={C12} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div
                              className={cc121 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j121"
                                name="12"
                                value={1}
                                onChange={(event) => {
                                  setJC12(event.target.value);
                                  setCc121(event.target.checked);
                                  setCc122(false);
                                  setCc123(false);
                                  setCc124(false);
                                  setCc125(false);
                                  setCc126(false);
                                  setCc127(false);
                                  setCc128(false);
                                }}
                              />
                              <label htmlFor="j121">
                                <img src={C12Q1} />
                              </label>
                            </div>
                            <div
                              className={cc122 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j122"
                                name="12"
                                value={2}
                                onChange={(event) => {
                                  setJC12(event.target.value);
                                  setCc122(event.target.checked);
                                  setCc121(false);
                                  setCc123(false);
                                  setCc124(false);
                                  setCc125(false);
                                  setCc126(false);
                                  setCc127(false);
                                  setCc128(false);
                                }}
                              />
                              <label htmlFor="j122">
                                <img src={C12Q2} />
                              </label>
                            </div>
                            <div
                              className={cc123 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j123"
                                name="12"
                                value={3}
                                onChange={(event) => {
                                  setJC12(event.target.value);
                                  setCc123(event.target.checked);
                                  setCc121(false);
                                  setCc122(false);
                                  setCc124(false);
                                  setCc125(false);
                                  setCc126(false);
                                  setCc127(false);
                                  setCc128(false);
                                }}
                              />
                              <label htmlFor="j123">
                                <img src={C12Q3} />
                              </label>
                            </div>
                            <div
                              className={cc124 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j124"
                                name="12"
                                value={4}
                                onChange={(event) => {
                                  setJC12(event.target.value);
                                  setCc124(event.target.checked);
                                  setCc121(false);
                                  setCc122(false);
                                  setCc123(false);
                                  setCc125(false);
                                  setCc126(false);
                                  setCc127(false);
                                  setCc128(false);
                                }}
                              />
                              <label htmlFor="j124">
                                <img src={C12Q4} />
                              </label>
                            </div>
                            <div
                              className={cc125 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j125"
                                name="12"
                                value={5}
                                onChange={(event) => {
                                  setJC12(event.target.value);
                                  setCc125(event.target.checked);
                                  setCc121(false);
                                  setCc122(false);
                                  setCc123(false);
                                  setCc124(false);
                                  setCc126(false);
                                  setCc127(false);
                                  setCc128(false);
                                }}
                              />
                              <label htmlFor="j125">
                                <img src={C12Q5} />
                              </label>
                            </div>
                            <div
                              className={cc126 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j126"
                                name="12"
                                value={6}
                                onChange={(event) => {
                                  setJC12(event.target.value);
                                  setCc126(event.target.checked);
                                  setCc121(false);
                                  setCc122(false);
                                  setCc123(false);
                                  setCc124(false);
                                  setCc125(false);
                                  setCc127(false);
                                  setCc128(false);
                                }}
                              />
                              <label htmlFor="j126">
                                <img src={C12Q6} />
                              </label>
                            </div>
                            <div
                              className={cc127 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j127"
                                name="12"
                                value={7}
                                onChange={(event) => {
                                  setJC12(event.target.value);
                                  setCc127(event.target.checked);
                                  setCc121(false);
                                  setCc122(false);
                                  setCc123(false);
                                  setCc124(false);
                                  setCc125(false);
                                  setCc126(false);
                                  setCc128(false);
                                }}
                              />
                              <label htmlFor="j127">
                                <img src={C12Q7} />
                              </label>
                            </div>
                            <div
                              className={cc128 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j128"
                                name="12"
                                value={8}
                                onChange={(event) => {
                                  setJC12(event.target.value);
                                  setCc128(event.target.checked);
                                  setCc121(false);
                                  setCc122(false);
                                  setCc123(false);
                                  setCc124(false);
                                  setCc125(false);
                                  setCc126(false);
                                  setCc127(false);
                                }}
                              />
                              <label htmlFor="j128">
                                <img src={C12Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "D" && (
                        <>
                          <img src={D12} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div
                              className={cd121 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j121"
                                name="12"
                                value={1}
                                onChange={(event) => {
                                  setJD12(event.target.value);
                                  setCd121(event.target.checked);
                                  setCd122(false);
                                  setCd123(false);
                                  setCd124(false);
                                  setCd125(false);
                                  setCd126(false);
                                  setCd127(false);
                                  setCd128(false);
                                }}
                              />
                              <label htmlFor="j121">
                                <img src={D12Q1} />
                              </label>
                            </div>
                            <div
                              className={cd122 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j122"
                                name="12"
                                value={2}
                                onChange={(event) => {
                                  setJD12(event.target.value);
                                  setCd122(event.target.checked);
                                  setCd121(false);
                                  setCd123(false);
                                  setCd124(false);
                                  setCd125(false);
                                  setCd126(false);
                                  setCd127(false);
                                  setCd128(false);
                                }}
                              />
                              <label htmlFor="j122">
                                <img src={D12Q2} />
                              </label>
                            </div>
                            <div
                              className={cd123 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j123"
                                name="12"
                                value={3}
                                onChange={(event) => {
                                  setJD12(event.target.value);
                                  setCd123(event.target.checked);
                                  setCd121(false);
                                  setCd122(false);
                                  setCd124(false);
                                  setCd125(false);
                                  setCd126(false);
                                  setCd127(false);
                                  setCd128(false);
                                }}
                              />
                              <label htmlFor="j123">
                                <img src={D12Q3} />
                              </label>
                            </div>
                            <div
                              className={cd124 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j124"
                                name="12"
                                value={4}
                                onChange={(event) => {
                                  setJD12(event.target.value);
                                  setCd124(event.target.checked);
                                  setCd121(false);
                                  setCd122(false);
                                  setCd123(false);
                                  setCd125(false);
                                  setCd126(false);
                                  setCd127(false);
                                  setCd128(false);
                                }}
                              />
                              <label htmlFor="j124">
                                <img src={D12Q4} />
                              </label>
                            </div>
                            <div
                              className={cd125 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j125"
                                name="12"
                                value={5}
                                onChange={(event) => {
                                  setJD12(event.target.value);
                                  setCd125(event.target.checked);
                                  setCd121(false);
                                  setCd122(false);
                                  setCd123(false);
                                  setCd124(false);
                                  setCd126(false);
                                  setCd127(false);
                                  setCd128(false);
                                }}
                              />
                              <label htmlFor="j125">
                                <img src={D12Q5} />
                              </label>
                            </div>
                            <div
                              className={cd126 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j126"
                                name="12"
                                value={6}
                                onChange={(event) => {
                                  setJD12(event.target.value);
                                  setCd126(event.target.checked);
                                  setCd121(false);
                                  setCd122(false);
                                  setCd123(false);
                                  setCd124(false);
                                  setCd125(false);
                                  setCd127(false);
                                  setCd128(false);
                                }}
                              />
                              <label htmlFor="j126">
                                <img src={D12Q6} />
                              </label>
                            </div>
                            <div
                              className={cd127 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j127"
                                name="12"
                                value={7}
                                onChange={(event) => {
                                  setJD12(event.target.value);
                                  setCd127(event.target.checked);
                                  setCd121(false);
                                  setCd122(false);
                                  setCd123(false);
                                  setCd124(false);
                                  setCd125(false);
                                  setCd126(false);
                                  setCd128(false);
                                }}
                              />
                              <label htmlFor="j127">
                                <img src={D12Q7} />
                              </label>
                            </div>
                            <div
                              className={cd128 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j128"
                                name="12"
                                value={8}
                                onChange={(event) => {
                                  setJD12(event.target.value);
                                  setCd128(event.target.checked);
                                  setCd121(false);
                                  setCd122(false);
                                  setCd123(false);
                                  setCd124(false);
                                  setCd125(false);
                                  setCd126(false);
                                  setCd127(false);
                                }}
                              />
                              <label htmlFor="j128">
                                <img src={D12Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}

                      {typeNow === "E" && (
                        <>
                          <img src={E12} className="fotoSoal" />

                          <div className="listJawaban8">
                            <div
                              className={ce121 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j121"
                                name="12"
                                value={1}
                                onChange={(event) => {
                                  setJE12(event.target.value);
                                  setCe121(event.target.checked);
                                  setCe122(false);
                                  setCe123(false);
                                  setCe124(false);
                                  setCe125(false);
                                  setCe126(false);
                                  setCe127(false);
                                  setCe128(false);
                                }}
                              />
                              <label htmlFor="j121">
                                <img src={E12Q1} />
                              </label>
                            </div>
                            <div
                              className={ce122 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j122"
                                name="12"
                                value={2}
                                onChange={(event) => {
                                  setJE12(event.target.value);
                                  setCe122(event.target.checked);
                                  setCe121(false);
                                  setCe123(false);
                                  setCe124(false);
                                  setCe125(false);
                                  setCe126(false);
                                  setCe127(false);
                                  setCe128(false);
                                }}
                              />
                              <label htmlFor="j122">
                                <img src={E12Q2} />
                              </label>
                            </div>
                            <div
                              className={ce123 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j123"
                                name="12"
                                value={3}
                                onChange={(event) => {
                                  setJE12(event.target.value);
                                  setCe123(event.target.checked);
                                  setCe121(false);
                                  setCe122(false);
                                  setCe124(false);
                                  setCe125(false);
                                  setCe126(false);
                                  setCe127(false);
                                  setCe128(false);
                                }}
                              />
                              <label htmlFor="j123">
                                <img src={E12Q3} />
                              </label>
                            </div>
                            <div
                              className={ce124 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j124"
                                name="12"
                                value={4}
                                onChange={(event) => {
                                  setJE12(event.target.value);
                                  setCe124(event.target.checked);
                                  setCe121(false);
                                  setCe122(false);
                                  setCe123(false);
                                  setCe125(false);
                                  setCe126(false);
                                  setCe127(false);
                                  setCe128(false);
                                }}
                              />
                              <label htmlFor="j124">
                                <img src={E12Q4} />
                              </label>
                            </div>
                            <div
                              className={ce125 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j125"
                                name="12"
                                value={5}
                                onChange={(event) => {
                                  setJE12(event.target.value);
                                  setCe125(event.target.checked);
                                  setCe121(false);
                                  setCe122(false);
                                  setCe123(false);
                                  setCe124(false);
                                  setCe126(false);
                                  setCe127(false);
                                  setCe128(false);
                                }}
                              />
                              <label htmlFor="j125">
                                <img src={E12Q5} />
                              </label>
                            </div>
                            <div
                              className={ce126 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j126"
                                name="12"
                                value={6}
                                onChange={(event) => {
                                  setJE12(event.target.value);
                                  setCe126(event.target.checked);
                                  setCe121(false);
                                  setCe122(false);
                                  setCe123(false);
                                  setCe124(false);
                                  setCe125(false);
                                  setCe127(false);
                                  setCe128(false);
                                }}
                              />
                              <label htmlFor="j126">
                                <img src={E12Q6} />
                              </label>
                            </div>
                            <div
                              className={ce127 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j127"
                                name="12"
                                value={7}
                                onChange={(event) => {
                                  setJE12(event.target.value);
                                  setCe127(event.target.checked);
                                  setCe121(false);
                                  setCe122(false);
                                  setCe123(false);
                                  setCe124(false);
                                  setCe125(false);
                                  setCe126(false);
                                  setCe128(false);
                                }}
                              />
                              <label htmlFor="j127">
                                <img src={E12Q7} />
                              </label>
                            </div>
                            <div
                              className={ce128 ? "jawaban click" : "jawaban"}
                            >
                              <input
                                type="radio"
                                id="j128"
                                name="12"
                                value={8}
                                onChange={(event) => {
                                  setJE12(event.target.value);
                                  setCe18(event.target.checked);
                                  setCe121(false);
                                  setCe122(false);
                                  setCe123(false);
                                  setCe124(false);
                                  setCe125(false);
                                  setCe126(false);
                                  setCe127(false);
                                }}
                              />
                              <label htmlFor="j128">
                                <img src={E12Q8} />
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  <div className="bottom">
                    <div className="grup">
                      {nomor % 12 == 1 ? (
                        <>
                          {typeNow === "A" && (
                            <>
                              <Link
                                to="#"
                                className={"buttonTest bola " + sembunyi}
                                disabled={nomor == 1 ? true : false}
                                onClick={() => {
                                  setNomor(nomor - 1);
                                  setTypeNow("A");
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M10.295 19.7155C10.3886 19.8077 10.4994 19.8806 10.6211 19.93C10.7429 19.9793 10.8731 20.0042 11.0045 20.0033C11.1358 20.0023 11.2657 19.9754 11.3867 19.9243C11.5076 19.8731 11.6173 19.7986 11.7095 19.705C11.8017 19.6115 11.8746 19.5007 11.9239 19.379C11.9733 19.2572 11.9982 19.127 11.9972 18.9956C11.9963 18.8643 11.9694 18.7344 11.9183 18.6134C11.8671 18.4925 11.7926 18.3827 11.699 18.2906L6.32903 13.0006H19.999C20.2642 13.0006 20.5186 12.8952 20.7061 12.7077C20.8937 12.5201 20.999 12.2658 20.999 12.0006C20.999 11.7353 20.8937 11.481 20.7061 11.2934C20.5186 11.1059 20.2642 11.0006 19.999 11.0006H6.33603L11.7 5.71355C11.884 5.52633 11.987 5.2743 11.9867 5.01183C11.9865 4.74936 11.8831 4.49751 11.6988 4.31061C11.5145 4.12371 11.2642 4.01675 11.0017 4.01281C10.7393 4.00887 10.4858 4.10827 10.296 4.28955L3.37203 11.1116C3.25411 11.2278 3.16048 11.3664 3.09657 11.5192C3.03267 11.672 2.99976 11.8359 2.99976 12.0016C2.99976 12.1672 3.03267 12.3311 3.09657 12.4839C3.16048 12.6367 3.25411 12.7753 3.37203 12.8916L10.296 19.7146L10.295 19.7155Z"
                                    fill="#FCFCFC"
                                  />
                                </svg>{" "}
                                <span className="disapearText">Sebelumnya</span>
                              </Link>
                            </>
                          )}
                          {typeNow === "B" && (
                            <>
                              <Link
                                to="#"
                                className={"buttonTest bola " + sembunyi}
                                disabled={nomor == 1 ? true : false}
                                onClick={() => {
                                  setNomor(nomor - 1);
                                  setTypeNow("A");
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M10.295 19.7155C10.3886 19.8077 10.4994 19.8806 10.6211 19.93C10.7429 19.9793 10.8731 20.0042 11.0045 20.0033C11.1358 20.0023 11.2657 19.9754 11.3867 19.9243C11.5076 19.8731 11.6173 19.7986 11.7095 19.705C11.8017 19.6115 11.8746 19.5007 11.9239 19.379C11.9733 19.2572 11.9982 19.127 11.9972 18.9956C11.9963 18.8643 11.9694 18.7344 11.9183 18.6134C11.8671 18.4925 11.7926 18.3827 11.699 18.2906L6.32903 13.0006H19.999C20.2642 13.0006 20.5186 12.8952 20.7061 12.7077C20.8937 12.5201 20.999 12.2658 20.999 12.0006C20.999 11.7353 20.8937 11.481 20.7061 11.2934C20.5186 11.1059 20.2642 11.0006 19.999 11.0006H6.33603L11.7 5.71355C11.884 5.52633 11.987 5.2743 11.9867 5.01183C11.9865 4.74936 11.8831 4.49751 11.6988 4.31061C11.5145 4.12371 11.2642 4.01675 11.0017 4.01281C10.7393 4.00887 10.4858 4.10827 10.296 4.28955L3.37203 11.1116C3.25411 11.2278 3.16048 11.3664 3.09657 11.5192C3.03267 11.672 2.99976 11.8359 2.99976 12.0016C2.99976 12.1672 3.03267 12.3311 3.09657 12.4839C3.16048 12.6367 3.25411 12.7753 3.37203 12.8916L10.296 19.7146L10.295 19.7155Z"
                                    fill="#FCFCFC"
                                  />
                                </svg>{" "}
                                <span className="disapearText">Sebelumnya</span>
                              </Link>
                            </>
                          )}
                          {typeNow === "C" && (
                            <>
                              <Link
                                to="#"
                                className={"buttonTest bola " + sembunyi}
                                disabled={nomor == 1 ? true : false}
                                onClick={() => {
                                  setNomor(nomor - 1);
                                  setTypeNow("B");
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M10.295 19.7155C10.3886 19.8077 10.4994 19.8806 10.6211 19.93C10.7429 19.9793 10.8731 20.0042 11.0045 20.0033C11.1358 20.0023 11.2657 19.9754 11.3867 19.9243C11.5076 19.8731 11.6173 19.7986 11.7095 19.705C11.8017 19.6115 11.8746 19.5007 11.9239 19.379C11.9733 19.2572 11.9982 19.127 11.9972 18.9956C11.9963 18.8643 11.9694 18.7344 11.9183 18.6134C11.8671 18.4925 11.7926 18.3827 11.699 18.2906L6.32903 13.0006H19.999C20.2642 13.0006 20.5186 12.8952 20.7061 12.7077C20.8937 12.5201 20.999 12.2658 20.999 12.0006C20.999 11.7353 20.8937 11.481 20.7061 11.2934C20.5186 11.1059 20.2642 11.0006 19.999 11.0006H6.33603L11.7 5.71355C11.884 5.52633 11.987 5.2743 11.9867 5.01183C11.9865 4.74936 11.8831 4.49751 11.6988 4.31061C11.5145 4.12371 11.2642 4.01675 11.0017 4.01281C10.7393 4.00887 10.4858 4.10827 10.296 4.28955L3.37203 11.1116C3.25411 11.2278 3.16048 11.3664 3.09657 11.5192C3.03267 11.672 2.99976 11.8359 2.99976 12.0016C2.99976 12.1672 3.03267 12.3311 3.09657 12.4839C3.16048 12.6367 3.25411 12.7753 3.37203 12.8916L10.296 19.7146L10.295 19.7155Z"
                                    fill="#FCFCFC"
                                  />
                                </svg>{" "}
                                <span className="disapearText">Sebelumnya</span>
                              </Link>
                            </>
                          )}
                          {typeNow === "D" && (
                            <>
                              <Link
                                to="#"
                                className={"buttonTest bola " + sembunyi}
                                disabled={nomor == 1 ? true : false}
                                onClick={() => {
                                  setNomor(nomor - 1);
                                  setTypeNow("C");
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M10.295 19.7155C10.3886 19.8077 10.4994 19.8806 10.6211 19.93C10.7429 19.9793 10.8731 20.0042 11.0045 20.0033C11.1358 20.0023 11.2657 19.9754 11.3867 19.9243C11.5076 19.8731 11.6173 19.7986 11.7095 19.705C11.8017 19.6115 11.8746 19.5007 11.9239 19.379C11.9733 19.2572 11.9982 19.127 11.9972 18.9956C11.9963 18.8643 11.9694 18.7344 11.9183 18.6134C11.8671 18.4925 11.7926 18.3827 11.699 18.2906L6.32903 13.0006H19.999C20.2642 13.0006 20.5186 12.8952 20.7061 12.7077C20.8937 12.5201 20.999 12.2658 20.999 12.0006C20.999 11.7353 20.8937 11.481 20.7061 11.2934C20.5186 11.1059 20.2642 11.0006 19.999 11.0006H6.33603L11.7 5.71355C11.884 5.52633 11.987 5.2743 11.9867 5.01183C11.9865 4.74936 11.8831 4.49751 11.6988 4.31061C11.5145 4.12371 11.2642 4.01675 11.0017 4.01281C10.7393 4.00887 10.4858 4.10827 10.296 4.28955L3.37203 11.1116C3.25411 11.2278 3.16048 11.3664 3.09657 11.5192C3.03267 11.672 2.99976 11.8359 2.99976 12.0016C2.99976 12.1672 3.03267 12.3311 3.09657 12.4839C3.16048 12.6367 3.25411 12.7753 3.37203 12.8916L10.296 19.7146L10.295 19.7155Z"
                                    fill="#FCFCFC"
                                  />
                                </svg>{" "}
                                <span className="disapearText">Sebelumnya</span>
                              </Link>
                            </>
                          )}
                          {typeNow === "E" && (
                            <>
                              <Link
                                to="#"
                                className={"buttonTest bola " + sembunyi}
                                disabled={nomor == 1 ? true : false}
                                onClick={() => {
                                  setNomor(nomor - 1);
                                  setTypeNow("D");
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M10.295 19.7155C10.3886 19.8077 10.4994 19.8806 10.6211 19.93C10.7429 19.9793 10.8731 20.0042 11.0045 20.0033C11.1358 20.0023 11.2657 19.9754 11.3867 19.9243C11.5076 19.8731 11.6173 19.7986 11.7095 19.705C11.8017 19.6115 11.8746 19.5007 11.9239 19.379C11.9733 19.2572 11.9982 19.127 11.9972 18.9956C11.9963 18.8643 11.9694 18.7344 11.9183 18.6134C11.8671 18.4925 11.7926 18.3827 11.699 18.2906L6.32903 13.0006H19.999C20.2642 13.0006 20.5186 12.8952 20.7061 12.7077C20.8937 12.5201 20.999 12.2658 20.999 12.0006C20.999 11.7353 20.8937 11.481 20.7061 11.2934C20.5186 11.1059 20.2642 11.0006 19.999 11.0006H6.33603L11.7 5.71355C11.884 5.52633 11.987 5.2743 11.9867 5.01183C11.9865 4.74936 11.8831 4.49751 11.6988 4.31061C11.5145 4.12371 11.2642 4.01675 11.0017 4.01281C10.7393 4.00887 10.4858 4.10827 10.296 4.28955L3.37203 11.1116C3.25411 11.2278 3.16048 11.3664 3.09657 11.5192C3.03267 11.672 2.99976 11.8359 2.99976 12.0016C2.99976 12.1672 3.03267 12.3311 3.09657 12.4839C3.16048 12.6367 3.25411 12.7753 3.37203 12.8916L10.296 19.7146L10.295 19.7155Z"
                                    fill="#FCFCFC"
                                  />
                                </svg>{" "}
                                <span className="disapearText">Sebelumnya</span>
                              </Link>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Link
                            to="#"
                            className={"buttonTest bola " + sembunyi}
                            disabled={nomor == 1 ? true : false}
                            onClick={() => {
                              setNomor(nomor - 1);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M10.295 19.7155C10.3886 19.8077 10.4994 19.8806 10.6211 19.93C10.7429 19.9793 10.8731 20.0042 11.0045 20.0033C11.1358 20.0023 11.2657 19.9754 11.3867 19.9243C11.5076 19.8731 11.6173 19.7986 11.7095 19.705C11.8017 19.6115 11.8746 19.5007 11.9239 19.379C11.9733 19.2572 11.9982 19.127 11.9972 18.9956C11.9963 18.8643 11.9694 18.7344 11.9183 18.6134C11.8671 18.4925 11.7926 18.3827 11.699 18.2906L6.32903 13.0006H19.999C20.2642 13.0006 20.5186 12.8952 20.7061 12.7077C20.8937 12.5201 20.999 12.2658 20.999 12.0006C20.999 11.7353 20.8937 11.481 20.7061 11.2934C20.5186 11.1059 20.2642 11.0006 19.999 11.0006H6.33603L11.7 5.71355C11.884 5.52633 11.987 5.2743 11.9867 5.01183C11.9865 4.74936 11.8831 4.49751 11.6988 4.31061C11.5145 4.12371 11.2642 4.01675 11.0017 4.01281C10.7393 4.00887 10.4858 4.10827 10.296 4.28955L3.37203 11.1116C3.25411 11.2278 3.16048 11.3664 3.09657 11.5192C3.03267 11.672 2.99976 11.8359 2.99976 12.0016C2.99976 12.1672 3.03267 12.3311 3.09657 12.4839C3.16048 12.6367 3.25411 12.7753 3.37203 12.8916L10.296 19.7146L10.295 19.7155Z"
                                fill="#FCFCFC"
                              />
                            </svg>{" "}
                            <span className="disapearText">Sebelumnya</span>
                          </Link>
                        </>
                      )}
                      {nomor % 12 !== 0 ? (
                        <>
                          <Link
                            to="#"
                            className="buttonTest bola"
                            onClick={() => {
                              setNomor(nomor + 1);
                            }}
                          >
                            <span className="disapearText">Selanjutnya</span>{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M13.704 4.28428C13.6116 4.18739 13.5006 4.11006 13.3777 4.05687C13.2548 4.00368 13.1224 3.97571 12.9885 3.97464C12.8546 3.97357 12.7218 3.9994 12.5981 4.05062C12.4743 4.10183 12.3622 4.17738 12.2682 4.27277C12.1742 4.36817 12.1003 4.48148 12.0509 4.60597C12.0016 4.73046 11.9777 4.86359 11.9808 4.99748C11.9838 5.13137 12.0138 5.26327 12.0688 5.38537C12.1238 5.50748 12.2027 5.61728 12.301 5.70828L17.67 11.0003H4C3.73478 11.0003 3.48043 11.1056 3.29289 11.2932C3.10536 11.4807 3 11.7351 3 12.0003C3 12.2655 3.10536 12.5199 3.29289 12.7074C3.48043 12.8949 3.73478 13.0003 4 13.0003H17.665L12.3 18.2853C12.1206 18.4733 12.0214 18.7238 12.0235 18.9836C12.0256 19.2435 12.1288 19.4923 12.3112 19.6774C12.4935 19.8626 12.7408 19.9694 13.0006 19.9753C13.2604 19.9813 13.5123 19.8859 13.703 19.7093L20.628 12.8873C20.7459 12.771 20.8396 12.6324 20.9035 12.4796C20.9674 12.3269 21.0003 12.1629 21.0003 11.9973C21.0003 11.8317 20.9674 11.6677 20.9035 11.5149C20.8396 11.3621 20.7459 11.2236 20.628 11.1073L13.703 4.28428H13.704Z"
                                fill="#FCFCFC"
                              />
                            </svg>
                          </Link>
                        </>
                      ) : (
                        <>
                          {/* <Link className='buttonTest bola' onClick={() => {setNomor(1); setHalaman("selesai"); hitMe()}}>
                                                            <span className='disapearText'>Selanjutnya</span> 
                                                            {" "}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path d="M13.704 4.28428C13.6116 4.18739 13.5006 4.11006 13.3777 4.05687C13.2548 4.00368 13.1224 3.97571 12.9885 3.97464C12.8546 3.97357 12.7218 3.9994 12.5981 4.05062C12.4743 4.10183 12.3622 4.17738 12.2682 4.27277C12.1742 4.36817 12.1003 4.48148 12.0509 4.60597C12.0016 4.73046 11.9777 4.86359 11.9808 4.99748C11.9838 5.13137 12.0138 5.26327 12.0688 5.38537C12.1238 5.50748 12.2027 5.61728 12.301 5.70828L17.67 11.0003H4C3.73478 11.0003 3.48043 11.1056 3.29289 11.2932C3.10536 11.4807 3 11.7351 3 12.0003C3 12.2655 3.10536 12.5199 3.29289 12.7074C3.48043 12.8949 3.73478 13.0003 4 13.0003H17.665L12.3 18.2853C12.1206 18.4733 12.0214 18.7238 12.0235 18.9836C12.0256 19.2435 12.1288 19.4923 12.3112 19.6774C12.4935 19.8626 12.7408 19.9694 13.0006 19.9753C13.2604 19.9813 13.5123 19.8859 13.703 19.7093L20.628 12.8873C20.7459 12.771 20.8396 12.6324 20.9035 12.4796C20.9674 12.3269 21.0003 12.1629 21.0003 11.9973C21.0003 11.8317 20.9674 11.6677 20.9035 11.5149C20.8396 11.3621 20.7459 11.2236 20.628 11.1073L13.703 4.28428H13.704Z" fill="#FCFCFC"/>
                                                            </svg>
                                                        </Link> */}
                          {typeNow === "A" && (
                            <>
                              <Link
                                to="#"
                                className="buttonTest bola"
                                onClick={() => {
                                  setNomor(nomor + 1);
                                  setTypeNow("B");
                                }}
                              >
                                <span className="disapearText">
                                  Selanjutnya
                                </span>{" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M13.704 4.28428C13.6116 4.18739 13.5006 4.11006 13.3777 4.05687C13.2548 4.00368 13.1224 3.97571 12.9885 3.97464C12.8546 3.97357 12.7218 3.9994 12.5981 4.05062C12.4743 4.10183 12.3622 4.17738 12.2682 4.27277C12.1742 4.36817 12.1003 4.48148 12.0509 4.60597C12.0016 4.73046 11.9777 4.86359 11.9808 4.99748C11.9838 5.13137 12.0138 5.26327 12.0688 5.38537C12.1238 5.50748 12.2027 5.61728 12.301 5.70828L17.67 11.0003H4C3.73478 11.0003 3.48043 11.1056 3.29289 11.2932C3.10536 11.4807 3 11.7351 3 12.0003C3 12.2655 3.10536 12.5199 3.29289 12.7074C3.48043 12.8949 3.73478 13.0003 4 13.0003H17.665L12.3 18.2853C12.1206 18.4733 12.0214 18.7238 12.0235 18.9836C12.0256 19.2435 12.1288 19.4923 12.3112 19.6774C12.4935 19.8626 12.7408 19.9694 13.0006 19.9753C13.2604 19.9813 13.5123 19.8859 13.703 19.7093L20.628 12.8873C20.7459 12.771 20.8396 12.6324 20.9035 12.4796C20.9674 12.3269 21.0003 12.1629 21.0003 11.9973C21.0003 11.8317 20.9674 11.6677 20.9035 11.5149C20.8396 11.3621 20.7459 11.2236 20.628 11.1073L13.703 4.28428H13.704Z"
                                    fill="#FCFCFC"
                                  />
                                </svg>
                              </Link>
                            </>
                          )}
                          {typeNow === "B" && (
                            <>
                              <Link
                                to="#"
                                className="buttonTest bola"
                                onClick={() => {
                                  setNomor(nomor + 1);
                                  setTypeNow("C");
                                }}
                              >
                                <span className="disapearText">
                                  Selanjutnya
                                </span>{" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M13.704 4.28428C13.6116 4.18739 13.5006 4.11006 13.3777 4.05687C13.2548 4.00368 13.1224 3.97571 12.9885 3.97464C12.8546 3.97357 12.7218 3.9994 12.5981 4.05062C12.4743 4.10183 12.3622 4.17738 12.2682 4.27277C12.1742 4.36817 12.1003 4.48148 12.0509 4.60597C12.0016 4.73046 11.9777 4.86359 11.9808 4.99748C11.9838 5.13137 12.0138 5.26327 12.0688 5.38537C12.1238 5.50748 12.2027 5.61728 12.301 5.70828L17.67 11.0003H4C3.73478 11.0003 3.48043 11.1056 3.29289 11.2932C3.10536 11.4807 3 11.7351 3 12.0003C3 12.2655 3.10536 12.5199 3.29289 12.7074C3.48043 12.8949 3.73478 13.0003 4 13.0003H17.665L12.3 18.2853C12.1206 18.4733 12.0214 18.7238 12.0235 18.9836C12.0256 19.2435 12.1288 19.4923 12.3112 19.6774C12.4935 19.8626 12.7408 19.9694 13.0006 19.9753C13.2604 19.9813 13.5123 19.8859 13.703 19.7093L20.628 12.8873C20.7459 12.771 20.8396 12.6324 20.9035 12.4796C20.9674 12.3269 21.0003 12.1629 21.0003 11.9973C21.0003 11.8317 20.9674 11.6677 20.9035 11.5149C20.8396 11.3621 20.7459 11.2236 20.628 11.1073L13.703 4.28428H13.704Z"
                                    fill="#FCFCFC"
                                  />
                                </svg>
                              </Link>
                            </>
                          )}
                          {typeNow === "C" && (
                            <>
                              <Link
                                to="#"
                                className="buttonTest bola"
                                onClick={() => {
                                  setNomor(nomor + 1);
                                  setTypeNow("D");
                                }}
                              >
                                <span className="disapearText">
                                  Selanjutnya
                                </span>{" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M13.704 4.28428C13.6116 4.18739 13.5006 4.11006 13.3777 4.05687C13.2548 4.00368 13.1224 3.97571 12.9885 3.97464C12.8546 3.97357 12.7218 3.9994 12.5981 4.05062C12.4743 4.10183 12.3622 4.17738 12.2682 4.27277C12.1742 4.36817 12.1003 4.48148 12.0509 4.60597C12.0016 4.73046 11.9777 4.86359 11.9808 4.99748C11.9838 5.13137 12.0138 5.26327 12.0688 5.38537C12.1238 5.50748 12.2027 5.61728 12.301 5.70828L17.67 11.0003H4C3.73478 11.0003 3.48043 11.1056 3.29289 11.2932C3.10536 11.4807 3 11.7351 3 12.0003C3 12.2655 3.10536 12.5199 3.29289 12.7074C3.48043 12.8949 3.73478 13.0003 4 13.0003H17.665L12.3 18.2853C12.1206 18.4733 12.0214 18.7238 12.0235 18.9836C12.0256 19.2435 12.1288 19.4923 12.3112 19.6774C12.4935 19.8626 12.7408 19.9694 13.0006 19.9753C13.2604 19.9813 13.5123 19.8859 13.703 19.7093L20.628 12.8873C20.7459 12.771 20.8396 12.6324 20.9035 12.4796C20.9674 12.3269 21.0003 12.1629 21.0003 11.9973C21.0003 11.8317 20.9674 11.6677 20.9035 11.5149C20.8396 11.3621 20.7459 11.2236 20.628 11.1073L13.703 4.28428H13.704Z"
                                    fill="#FCFCFC"
                                  />
                                </svg>
                              </Link>
                            </>
                          )}
                          {typeNow === "D" && (
                            <>
                              <Link
                                to="#"
                                className="buttonTest bola"
                                onClick={() => {
                                  setNomor(nomor + 1);
                                  setTypeNow("E");
                                }}
                              >
                                <span className="disapearText">
                                  Selanjutnya
                                </span>{" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M13.704 4.28428C13.6116 4.18739 13.5006 4.11006 13.3777 4.05687C13.2548 4.00368 13.1224 3.97571 12.9885 3.97464C12.8546 3.97357 12.7218 3.9994 12.5981 4.05062C12.4743 4.10183 12.3622 4.17738 12.2682 4.27277C12.1742 4.36817 12.1003 4.48148 12.0509 4.60597C12.0016 4.73046 11.9777 4.86359 11.9808 4.99748C11.9838 5.13137 12.0138 5.26327 12.0688 5.38537C12.1238 5.50748 12.2027 5.61728 12.301 5.70828L17.67 11.0003H4C3.73478 11.0003 3.48043 11.1056 3.29289 11.2932C3.10536 11.4807 3 11.7351 3 12.0003C3 12.2655 3.10536 12.5199 3.29289 12.7074C3.48043 12.8949 3.73478 13.0003 4 13.0003H17.665L12.3 18.2853C12.1206 18.4733 12.0214 18.7238 12.0235 18.9836C12.0256 19.2435 12.1288 19.4923 12.3112 19.6774C12.4935 19.8626 12.7408 19.9694 13.0006 19.9753C13.2604 19.9813 13.5123 19.8859 13.703 19.7093L20.628 12.8873C20.7459 12.771 20.8396 12.6324 20.9035 12.4796C20.9674 12.3269 21.0003 12.1629 21.0003 11.9973C21.0003 11.8317 20.9674 11.6677 20.9035 11.5149C20.8396 11.3621 20.7459 11.2236 20.628 11.1073L13.703 4.28428H13.704Z"
                                    fill="#FCFCFC"
                                  />
                                </svg>
                              </Link>
                            </>
                          )}
                          {typeNow === "E" && (
                            <>
                              <Link
                                to="#"
                                className="buttonTest bola"
                                onClick={() => {
                                  setNomor(1);
                                  setHalaman("selesai");
                                  hitMe();
                                }}
                              >
                                <span className="disapearText">
                                  Selanjutnya
                                </span>{" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M13.704 4.28428C13.6116 4.18739 13.5006 4.11006 13.3777 4.05687C13.2548 4.00368 13.1224 3.97571 12.9885 3.97464C12.8546 3.97357 12.7218 3.9994 12.5981 4.05062C12.4743 4.10183 12.3622 4.17738 12.2682 4.27277C12.1742 4.36817 12.1003 4.48148 12.0509 4.60597C12.0016 4.73046 11.9777 4.86359 11.9808 4.99748C11.9838 5.13137 12.0138 5.26327 12.0688 5.38537C12.1238 5.50748 12.2027 5.61728 12.301 5.70828L17.67 11.0003H4C3.73478 11.0003 3.48043 11.1056 3.29289 11.2932C3.10536 11.4807 3 11.7351 3 12.0003C3 12.2655 3.10536 12.5199 3.29289 12.7074C3.48043 12.8949 3.73478 13.0003 4 13.0003H17.665L12.3 18.2853C12.1206 18.4733 12.0214 18.7238 12.0235 18.9836C12.0256 19.2435 12.1288 19.4923 12.3112 19.6774C12.4935 19.8626 12.7408 19.9694 13.0006 19.9753C13.2604 19.9813 13.5123 19.8859 13.703 19.7093L20.628 12.8873C20.7459 12.771 20.8396 12.6324 20.9035 12.4796C20.9674 12.3269 21.0003 12.1629 21.0003 11.9973C21.0003 11.8317 20.9674 11.6677 20.9035 11.5149C20.8396 11.3621 20.7459 11.2236 20.628 11.1073L13.703 4.28428H13.704Z"
                                    fill="#FCFCFC"
                                  />
                                </svg>
                              </Link>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            {halaman === "selesai" && (
              <>
                <div className="testBerakhir">
                  <p>
                    Semua soal udah selesai, <br />
                    yuk cek hasilnya ke halaman report!
                  </p>
                  <img src={end} />
                  <Link
                    className="buttonTest end"
                    to={{ pathname: "/complementary" }}
                  >
                    Kembali
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SoalTrialNugi;
